import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Table,
  Typography,
} from "antd";
import React, { useContext, useEffect, useRef } from "react";
import { CourseContext } from "../../../../context/CourseContext";
import { useState } from "react";
import TimeFormatter from "../../../../utils/timeFormatter";
import { AdminConfirmationModal } from "../../../../component-library";
import axios from "axios";
import config from "../../../../config";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import "./antdAssignedUsers.scss";
import AntdEmailActionModal from "../../../../component-library/emailActionModal/AntdEmailActionModal";
import AntdUpdateExpectedDateModal from "./updateExpectedDateModal/AntdUpdateExpectedDateModal";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import snakeCase from "lodash/snakeCase";
import { flatten } from "lodash";

const crumbs = [
  {
    title: (
      <a className="common-breadcrumbs-color" href="/Admin">
        Admin Dashboard
      </a>
    ),
  },
  {
    title: (
      <a className="common-breadcrumbs-color" href="/Admin/e-learning/courses">
        E-Learning Courses
      </a>
    ),
  },
  { title: "Assigned Users" },
];

const dueDateMenu = [
  { name: "On Time", value: "onTime", color: "#00a0f0" },
  { name: "Overdue", value: "overdue", color: "red" },
];

const filterMenu = [
  { name: "Yet to start", value: "yetToStart", color: "red" },
  { name: "In Progress", value: "inProgress", color: "orange" },
  { name: "Completed", value: "completed", color: "green" },
];

function AntdAssignedUsers(props) {
  const { userDateFormat } = useContext(CourseContext);

  const [courseId] = useState(props.match.params.courseId);
  const [courseData, setCourseData] = useState("");
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [showSubFilters, setShowSubFilters] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alertType, setAlertType] = useState("");
  const [selectedParentFilter, setSelectedParentFilter] = useState("onTime");
  const [selectedChildFilter, setSelectedChildFilter] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [to, setTo] = useState("");
  // const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [csvFileName, setCsvFileName] = useState("Yet to start users list");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showDeleteConfimation, setShowDeleteConfirmation] = useState(false);
  const [showUpdateExpectedModal, setShowUpdateExpectedModal] = useState(false);
  const dataTableRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [parentFilter, setParentFilter] = useState("onTime");
  const [subFilter, setSubFilter] = useState();
  const employeeStatus = ["Active", "Inactive"];

  const filterMenu = [
    { name: "Yet to start", value: "yetToStart", color: "red" },
    { name: "In Progress", value: "inProgress", color: "orange" },
    { name: "Completed", value: "completed", color: "green" },
  ];

  const sessionUsersColumns = [
    {
      dataIndex: "empId",
      title: "EMP ID".toUpperCase(),
      sorter: (a, b) => {
        return a.empId - b.empId;
      },
      sort: true,
      headerStyle: {
        width: "4rem",
      },
    },
    // {
    //   dataIndex: "name",
    //   title: "Name".toUpperCase(),
    // sorter: (a, b) => {
    //     return a.empId.localeCompare(b.empId);
    //   },
    //   sort: true,
    //   hidden: true,
    //   csvExport: false,
    // },

    {
      dataIndex: "name",
      title: "Name & Email".toUpperCase(),
      sorter: (a, b) => {
        return a.name?.localeCompare(b.name);
      },
      sort: true,
      headerStyle: {
        width: "10rem",
      },
      csvText: "Name",
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.name}`}</strong>
            </div>
            <div>{`${row.primaryEmail}`}</div>
          </>
        );
      },
    },
    // {
    //   dataIndex: "primaryEmail",
    //   title: "Email".toUpperCase(),
    // sorter: (a, b) => {
    //     return a.empId.localeCompare(b.empId);
    //   },
    //   sort: true,
    //   hidden: true,
    // },
    {
      dataIndex: "postTitle",
      title: "Role".toUpperCase(),
      sorter: (a, b) => {
        return a.postTitle?.localeCompare(b.postTitle);
      },
      sort: true,
      headerStyle: {
        width: "6rem",
      },
    },
    {
      dataIndex: "mainWorkingCountryName",
      title: "Country".toUpperCase(),
      sorter: (a, b) => {
        return a.mainWorkingCountryName?.localeCompare(
          b.mainWorkingCountryName
        );
      },
      sort: true,
      headerStyle: {
        width: "5rem",
      },
    },
    {
      dataIndex: "currentDptName",
      title: "Department".toUpperCase(),
      sorter: (a, b) => {
        return a.currentDptName?.localeCompare(b.currentDptName);
      },
      sort: true,
      headerStyle: {
        width: "10rem",
      },
    },
    {
      dataIndex: "courseStatus",
      title: "Completion Status".toUpperCase(),
      sorter: (a, b) => {
        return a.courseStatus?.localeCompare(b.courseStatus);
      },
      sort: true,
      style: (value, row) => {
        switch (value?.toLowerCase()) {
          case "completed":
            return {
              color: "green",
            };
          case "started":
            return {
              color: "orange",
            };
          case "assigned":
            return {
              color: "#65229a",
            };
          default:
            return {
              color: "grey",
            };
        }
      },
      render: (cellContent, row) => {
        switch (cellContent?.toLowerCase()) {
          case "completed":
            return "Completed";
          case "started":
            return "In Progress";
          case "assigned":
            return "Yet to start";
          default:
            return "";
        }
      },
      csvrender: (cell, row) => {
        switch (cell?.toLowerCase()) {
          case "completed":
            return "Completed";
          case "started":
            return "In Progress";
          case "assigned":
            return "Yet to start";
          default:
            return "";
        }
      },
      headerStyle: {
        width: "4.5rem",
      },
    },
    {
      dataIndex: "expectedCompletionDate",
      title: "Expected Completion Date".toUpperCase(),
      sorter: (a, b) => {
        return a.expectedCompletionDate - b.expectedCompletionDate;
      },
      sort: true,
      headerStyle: {
        width: "6rem",
      },
      render: (cellContent) => {
        return TimeFormatter.FormatDate(cellContent, null, userDateFormat);
      },
      csvrender: (cellContent) => {
        return TimeFormatter.FormatDate(cellContent, null, userDateFormat);
      },
    },
    {
      dataIndex: "status",
      title: "Employee Status".toUpperCase(),
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.status).startsWith(String(value)),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      sorter: (a, b) => {
        return a.status?.localeCompare(b.status);
      },
      sort: true,
      headerStyle: {
        width: "5rem",
      },
    },
  ];
  const [tableColumns, setTableColumns] = useState(sessionUsersColumns);

  useEffect(() => {
    setTableColumns(sessionUsersColumns);
  }, [userDateFormat]);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line
  }, []);

  const getCourseData = () => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}`)
      .then((response) => {
        setCourseData(response.data.data.course);
        getUsersAssignedForCourse(null, "onTime");
        setSelectedParentFilter("onTime");
        setSelectedChildFilter(null);
        setSelectedUsers([]);
        // dataTableRef.current.selectionContext.selected = [];
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const getUsersAssignedForCourse = (filterBy, dueDate) => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}/user`, {
        params: {
          courseStatus: "assigned",
          filterBy: filterBy,
          dueDate: dueDate,
        },
      })
      .then((response) => {
        const formattedListData = response.data.data.map((data) => ({
          ...data,
          key: data.empId,
        }));
        setListData(formattedListData);
        setTableData(formattedListData);
        setPageCount(formattedListData.length);
        if (dueDate === "overdue") {
          // setCc(
          //   [...new Set( response.data.data.map((obj) => {
          //     return obj.managerPrimaryEmail;
          //   }))]
          // );
          setBcc(
            response.data.data.map((obj) => {
              return obj.primaryEmail;
            })
          );
          setSubject(`${courseData.name}  - course completion overdue notice`);

          const body = `<p>Hello, <br/> <br/>
          Kindly treat this email as the reminder to complete the course <strong> ${courseData.name} </strong> on GROW platform.
          <br/> <br/>This course needs to be completed by today without fail. <br/> <br/>
          Regards,<br/>
          L&D team </p>`;
          setContent(body);
        }
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const onNestedFilterChange = (parentValue, childValue) => {
    let dueDate = parentValue;
    let filterBy = childValue;
    const dueDateCsvString = dueDateMenu.find((el) => el.value === parentValue);
    const filterCsvString = filterMenu.find((el) => el.value === childValue);
    setSelectedParentFilter(parentValue);
    setSelectedChildFilter(childValue);
    setCsvFileName(
      `${dueDateCsvString?.name || ""} ${
        filterCsvString?.name || ""
      } users list`
    );
    getUsersAssignedForCourse(filterBy, dueDate);
    setSelectedUsers([]);
    // dataTableRef.current.selectionContext.selected = [];
  };
  const renderTitle = () => {
    return (
      <div style={{ width: "20rem" }}>
        <Typography
          style={{
            fontFamily: "RakutenRoundSemiBold",
            fontSize: "2.5rem",
          }}
        >
          Assigned Users
          <span
            style={{
              fontSize: "0.9rem",
              fontFamily: "RakutenRoundRegular",
            }}
          >
            ({pageCount})
          </span>
        </Typography>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "37rem",
        }}
      >
        <div>
          {dueDateMenu.map((menuItem) => {
            return (
              <span
                style={{
                  color:
                    menuItem.value === selectedParentFilter
                      ? menuItem.color
                      : "black",
                  padding: "1rem",
                  cursor: "pointer",
                  // borderBottom: showSubFilters && `2px solid ${menuItem.color}`,
                }}
                onClick={() => {
                  setSelectedUsers([]);
                  setSelectedRowKeys([]);

                  if (!showSubFilters || menuItem.value !== parentFilter) {
                    setShowSubFilters(true);
                    onNestedFilterChange(menuItem.value, "yetToStart");
                    setParentFilter(menuItem.value);
                    setSelectedUsers([]);
                    setSubFilter("yetToStart");
                  }
                }}
              >
                {menuItem.name}
              </span>
            );
          })}
        </div>
        <div style={{ marginTop: "1rem" }}>
          {showSubFilters && renderSubFilters()}
        </div>
      </div>
    );
  };

  const renderSubFilters = () => {
    return filterMenu.map((menuItem) => {
      return (
        <span
          style={{
            color: subFilter === menuItem.value ? menuItem.color : "",
            margin: "1rem",
            cursor: "pointer",
          }}
          className={menuItem.value + "-color-change"}
          onClick={() => {
            setSelectedUsers([]);
            setSelectedRowKeys([]);
            if (menuItem.value === "yetToStart") {
              onNestedFilterChange(parentFilter, "yetToStart");
              setSubFilter("yetToStart");
            } else if (menuItem.value === "inProgress") {
              onNestedFilterChange(parentFilter, "inProgress");
              setSubFilter("inProgress");
            } else {
              onNestedFilterChange(parentFilter, "completed");
              setSubFilter("completed");
            }
            setSelectedUsers([]);
          }}
        >
          {menuItem.name}
        </span>
      );
    });
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      expectedCompletionDate: data.expectedCompletionDate
        ? dayjs.unix(data.expectedCompletionDate).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      viewedDate: data.viewedDate
        ? dayjs.unix(data.viewedDate).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      completedDate: data.completedDate
        ? dayjs.unix(data.completedDate).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
    }));
  };

  const renderDownloadButton = () => {
    const filename = subFilter
      ? `${parentFilter}_${snakeCase(subFilter)}`
      : parentFilter;

    const columns = tableColumns.map((column) => {
      if (column.dataIndex === "name") {
        return [
          {
            key: column.dataIndex,
            label: "Name",
          },
          {
            key: "primaryEmail",
            label: "Email",
          },
        ];
      } else {
        return {
          key: column.dataIndex,
          label: column.title,
        };
      }
    });

    return (
      <CSVLink
        headers={flatten(columns)}
        data={getDataToDownload(listData)}
        filename={filename}
        extension=".csv"
      >
        <div
          style={{
            fontSize: 20,
            background: "white",
            padding: ".425rem .7rem",
            borderRadius: "50%",
            marginRight: "1rem",
            cursor: listData.length < 1 ? "not-allowed" : "pointer",
          }}
        >
          <DownloadOutlined />
        </div>
      </CSVLink>
    );
  };

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const renderSearchInput = () => {
    return (
      <Input
        placeholder="Search"
        suffix={<SearchOutlined style={{ fontSize: "16px" }} />}
        style={{ width: "15rem", padding: ".725rem", fontSize: "16px" }}
        onChange={(e) => handleSearch(e.target.value)}
      />
    );
  };

  const renderDeleteButton = () => {
    return (
      <Button
        className="antd-assigned-user-delete-button"
        disabled={selectedUsers.length < 1}
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => setShowDeleteConfirmation(true)}
      >
        DELETE
      </Button>
    );
  };

  const renderReminderButton = () => {
    return (
      <Button
        className="antd-assigned-user-delete-button"
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={!showSubFilters || listData.length < 1}
        onClick={() => setShowEmailModal(true)}
      >
        REMINDER
      </Button>
    );
  };

  const renderUpdateButton = () => {
    return (
      <Button
        className="antd-assigned-user-delete-button"
        type="button"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        disabled={!listData.length > 0 || selectedUsers.length < 1}
        onClick={() => setShowUpdateExpectedModal(true)}
      >
        UPDATE
      </Button>
    );
  };

  const renderMenu = () => {
    return (
      <div className={"elCompleted-header"} style={{ paddingBottom: "2rem" }}>
        <Row>
          <Col lg={5}>{renderTitle()}</Col>
          <Col lg={10}>{renderFilters()}</Col>
          <Col lg={1}>{renderDownloadButton()}</Col>
          <Col lg={5}>{renderSearchInput()}</Col>
          <Col lg={3}>
            {parentFilter === "onTime"
              ? subFilter
                ? renderDeleteButton()
                : renderUpdateButton()
              : null}

            {parentFilter === "overdue"
              ? subFilter !== "completed" && selectedUsers.length === 0
                ? renderReminderButton()
                : renderDeleteButton()
              : null}
          </Col>
        </Row>
      </div>
    );
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedUsers(selectedRows);
    },
  };

  const renderTableContent = () => {
    return (
      <Table
        columns={tableColumns}
        dataSource={listData}
        rowSelection={rowSelection}
        pagination={{
          position: ["bottomCenter"],
          showQuickJumper: true,
          showSizeChanger: true,
        }}
      />
    );
  };
  const handleClose = () => {
    setShowDeleteConfirmation(false);
  };

  const onDeleteRequest = () => {
    const deletedUsers = selectedUsers.map((user) => user.empId);

    const type =
      selectedChildFilter === "yetToStart"
        ? "assigned"
        : selectedChildFilter === "inProgress"
        ? "started"
        : "completed";
    axios
      .delete(
        `${config.endPoint.elearning}/course/${courseId}/user/deleteUsersFromCourse`,
        { data: { userList: deletedUsers, type: type } }
      )
      .then((res) => {
        setAlert(
          `Selected Users deleted successfully from ${
            filterMenu.find((item) => item.value === selectedChildFilter).name
          }!`
        );
        setShowAlert(true);
        setAlertType("success");
        handleClose();
        onNestedFilterChange(selectedParentFilter, selectedChildFilter);
        setSelectedUsers([]);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleClose();
        }
      });
  };

  const modalContent = (
    <>
      <Row>
        <Col className={""}>
          <p>
            Are you sure you want to delete following users from{" "}
            {
              filterMenu.find((item) => item.value === selectedChildFilter)
                ?.name
            }
            ?
          </p>
          <ul>
            {selectedUsers.map((user) => {
              return <li>{user.primaryEmail}</li>;
            })}
          </ul>
        </Col>
      </Row>
    </>
  );

  const onSendEmailHandler = () => {
    const obj = {
      to,
      mailBody: content,
      subject,
      // cc,
      bcc,
    };
    axios
      .post(`${config.endPoint.elearning}/course/sendReminderEmail`, obj)
      .then((response) => {
        setShowEmailModal(false);
        setAlert("Email sent!");
        setShowAlert(true);
        setAlertType("success");
      })
      .catch(function (error) {
        setShowEmailModal(false);
        setAlert("Failed to send Email!");
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const handleEmailModalClose = () => {
    setShowEmailModal(false);
  };

  return (
    <div
      style={{
        marginTop: "7rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          className={"admin-alert"}
          onCloseAlert={() => setAlert("")}
        />
      )}
      <Breadcrumb items={crumbs} className={"elCompleted-crumbs"} />
      {renderMenu()}
      <div className={"elCompleted-table"}>{renderTableContent()}</div>
      {showEmailModal && (
        // <EmailActionModal
        <AntdEmailActionModal
          to={to}
          setTo={setTo}
          validateDlm={true}
          // cc={cc} TODO: Fix after requirements are clear
          bcc={bcc}
          subject={subject}
          content={content}
          showEmailModal={showEmailModal}
          sendEmailHandler={onSendEmailHandler}
          modalCloseHandler={handleEmailModalClose}
          // ccHandler={setCc} TODO: Fix after requirements are clear
          bccHandler={setBcc}
          subjectHandler={setSubject}
          contentHandler={setContent}
          showCalendar={false}
        />
      )}
      {showUpdateExpectedModal ? (
        <AntdUpdateExpectedDateModal
          showExpectedDateModal={showUpdateExpectedModal}
          handleCloseExpectedDateModal={() => setShowUpdateExpectedModal(false)}
          courseData={courseData}
          users={listData.filter((user) => user.courseStatus !== "completed")}
          setShowAlert={setShowAlert}
          setAlert={setAlert}
          setAlertType={setAlertType}
          callbackCompletedSession={getCourseData}
          setSelectedUsers={setSelectedUsers}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      ) : (
        <></>
      )}
      {showDeleteConfimation ? (
        <AdminConfirmationModal
          toggle={true}
          title="Delete "
          titleBold={
            filterMenu.find((item) => item.value === selectedChildFilter)?.name
          }
          show={showDeleteConfimation}
          leftButton="YES"
          rightButton="NO"
          handleClose={handleClose}
          rightButtonClick={handleClose}
          leftButtonClick={onDeleteRequest}
          modalContent={modalContent}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AntdAssignedUsers;

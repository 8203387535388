import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import config from "../../config";
import {
  deleteOrphanedImages,
  checkForAddedImages,
} from "../../utils/textEditor";
import Alerts from "../alert/Alert";

const TextEditor = forwardRef((props, ref) => {
  //TODO: Delete images which are removed from editor from s3 storage

  //eslint-disable-next-line
  const [initialValue, setInitialValue] = useState(props.value);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageArray, setImageArray] = useState([]);
  useEffect(() => {
    window.addEventListener("popstate", () => {
      if (imageArray.length !== 0)
        deleteOrphanedImages(imageArray, initialValue);
    });
    return () => window.removeEventListener("popstate", deleteOrphanedImages);
  }, [imageArray, initialValue]);

  useEffect(() => {
    if (props.value) {
      const newImageArray = checkForAddedImages(imageArray, props.value);
      setImageArray(newImageArray);
    }
    //eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "");
    };
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("unload", () => {
      if (imageArray.length !== 0)
        deleteOrphanedImages(imageArray, initialValue);
    });
    return () => {
      window.removeEventListener("unload", deleteOrphanedImages);
    };
  }, [imageArray, initialValue]);

  const getSignedURL = async (filename, fileType) => {
    try {
      const response = await axios.post(
        `${config.endPoint.commonService}/utils/get-signed-url`,
        {
          fileName: filename,
          fileType: fileType,
        }
      );
      return response;
    } catch (err) {
      setErrorMessage("Something went wrong. Please try again");
    }
  };

  const uploadFile = async (file) => {
    try {
      const body = new FormData();
      body.append("file", file);
      const response = await axios.post(
        `${config.endPoint.commonService}/utils/file-upload`,
        body
      );
      return response;
    } catch (err) {
      setErrorMessage("Upload failed. Please try again");
    }
  };
  const callback = (callback, value, meta) => {
    if (meta.filetype === "image") {
      var input = document.getElementById("my-file");
      input.click();
      input.onchange = async function () {
        var file = input.files[0];
        const response = await uploadFile(file);
        input.value = "";
        if (response) {
          const url = await getSignedURL(
            response?.data?.key,
            response?.data?.fileType
          );
          setImageArray((prevState) => {
            return [...prevState, response?.data?.key];
          });
          callback(url.data, {
            alt: response?.data?.key,
            id: response?.data?.key,
          });
        }
      };
    }
  };

  const processUpdatedContent = async (value) => {
    props.getText(value);
  };

  useImperativeHandle(
    ref,
    () => ({
      deleteOrphanedImages: () => {
        const response = deleteOrphanedImages(imageArray, props.value);
        return response;
      },
    }),
    [imageArray, props.value]
  );
  return (
    <>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        value={props.value || ""}
        onEditorChange={processUpdatedContent}
        init={{
          height: 251,
          menubar: false,
          branding: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
            "paste",
            "fontselect",
          ],
          paste_as_text: true,
          paste_text_sticky: true,
          paste_text_sticky_default: true,
          automatic_uploads: false,
          content_css: "./content_css.css",
          body_class: "editor-class",
          toolbar: [
            { name: "history", items: ["undo", "redo"] },
            { name: "styles", items: ["blocks", "fontfamily"] },

            {
              name: "formatting",
              items: ["bold", "italic", "underline", "forecolor"],
            },
            {
              name: "alignment",
              items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
            },
            {
              name: "indentation",
              items: ["bullist", "numlist", "outdent", "indent"],
            },
            {
              name: "miscelleneous",
              items: ["removeformat", "help", "image", "table", "media"],
            },
          ],
          toolbar_sticky: true,
          toolbar_mode: "sliding",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          file_picker_callback: callback,
          file_picker_types: "image",
        }}
      />
      <input
        style={{ display: "none" }}
        accept="image/*"
        id="my-file"
        type="file"
      />
      {errorMessage && (
        <Alerts
          type="error"
          message={errorMessage}
          onCloseAlert={() => setErrorMessage("")}
        />
      )}
    </>
  );
});
export default TextEditor;

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useContext,useCallback } from "react";
import { customFilter } from "react-bootstrap-table2-filter";
import CsvDownloader from "react-csv-downloader";
import { PlusCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Tabs,
  Table,
  Breadcrumb,
  Button,
  Input,
  Typography,
  Alert,
  Spin,
} from "antd";
import TimeFormatter from "../../../utils/timeFormatter";
import {
  DownloadOutlined,
  UserAddOutlined,
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import private_icon from "../../../assets/icons/private_icon.svg";
import privateIcon from "../../../assets/icons/private_icon.svg";
import style from "./courseList.scss";
import CourseListRoundProgressBar from "./CourseListRoundProgressBar";
import ImportUserHelper from "../../../component-library/importAddUserModal/ImportUserHelper";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import Select from "react-select";
import { debounce } from "lodash";
import { CourseContext } from "../../../context/CourseContext";
import config from "../../../config";
import { serializeDataResponse } from "../../../serializer/dataSerializer";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { UserContext } from "../../../context/UserContext";
import PrivateCourseIcon from "../../../components/common/privateCourseIcon";

export default function CourseList() {
  const history = useHistory();
  const location = useLocation();
  const { userDateFormat } = useContext(CourseContext);
  const [searchText, setSearchText] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [activeData, setActiveData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [courseProviderlist, setCourseProviderlist] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState(null);
  const [bottom, setBottom] = useState("bottomCenter");
  const [subCourseProviderList, setSubCourseProviderList] = useState([]);
  const [selectedSubCourseProvider, setSelectedSubCourseProvider] =
    useState(null);
  const [selectedFilter, setSelectedFilter] = useState("1");
  const { userConfig } = useContext(UserContext);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { TabPane } = Tabs;

  const crumbs = [
    {
      title: (
        <a href="/Admin" style={{ color: userConfig?.primary }}>
          Admin Dashboard
        </a>
      ),
    },
    {
      title: "Instructor-led Courses",
    },
  ];

  const tableSearchData = ({
    dataIndex,
    placeHolder,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${placeHolder}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
        style={{
          width: "10rem",
          fontFamily: "RakutenRoundRegular",
          marginBottom: 8,
          display: "block",
        }}
      />
      <button
        type="button"
        style={{
          width: "5.5rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
      >
        Search
      </button>
      <button
        type="button"
        style={{
          width: "4rem",
          marginLeft: "0.2rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          clearFilters();
          setSearchText("");
          confirm();
        }}
      >
        Reset
      </button>
    </div>
  );
  const activeCourseColumns = [
    {
      dataIndex: "private",
      title: <PrivateCourseIcon color2={userConfig.secondary} />,
      width: 60,
      csvExport: false,
      onFilter: (value, record) =>
        value === "Private" ? record.private : !record.private,
      filters: [
        { text: "public courses", value: "Public" },
        { text: "private courses", value: "Private" },
      ],
      render: (cell, row) => {
        if (row.private) {
          return (
            <Button
              type="button"
              alt="private course"
              className={"courseList-numberButton"}
            >
              <PrivateCourseIcon color2={userConfig.secondary} />
            </Button>
          );
        }
        return null;
      },
      filter: customFilter(),
      // fixed: 'left',
    },
    {
      dataIndex: "identity_id",
      title: "Course ID".toUpperCase(),
      sorter: (a, b) => {
        return a.identity_id.localeCompare(b.identity_id);
      },
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "identity_id",
          placeHolder: "Course ID",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.identity_id)
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      width: 110,
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Course Name".toUpperCase(),
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "name",
          placeHolder: "Course Name",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.name)
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      width: 140,
      render: (cell, row) => {
        if (row.private) {
          return (
            <Link
              to={`/course-details/${row.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <span
                style={{
                  color: userConfig.secondary,
                  width: "15rem",
                  overflowWrap: "break-word",
                }}
                title={cell}
              >
                {decodeHtmlEntities(cell)}
              </span>
            </Link>
          );
        }
        return (
          <Link
            to={`/course-details/${row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: userConfig.primary }} title={cell}>
              {decodeHtmlEntities(cell)}
            </span>
          </Link>
        );
      },
    },
    {
      dataIndex: "category.name",
      title: "Category".toUpperCase(),
      sorter: (a, b) => {
        return a.category.name.localeCompare(b.category.name);
      },
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "categoryName",
          placeHolder: "Category",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.category.name)
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      width: 120,
      render: (cell, row) => (
        <>
          <div>
            <strong>{`${row.category.name}`}</strong>
          </div>
        </>
      ),
    },
    {
      dataIndex: "subcategory[name]",
      title: "Sub Category".toUpperCase(),
      sorter: (a, b) => {
        return a.subcategory.name.localeCompare(b.subcategory.name);
      },
      sort: true,
      width: 120,
      render: (cell, row) => (
        <>
          <div>
            <strong>{`${row.subcategory.name}`}</strong>
          </div>
        </>
      ),
    },
    {
      dataIndex: "batch_data",
      title: "Enrolled Users".toUpperCase(),
      sorter: (a, b) => {
        return (
          a.batch_data.total_available_batches -
          b.batch_data.total_available_batches
        );
      },
      sort: false,
      csvExport: false,
      width: 120,
      render: (cell, row) => {
        if (row.batch_data.total_available_batches > 0) {
          let next2Batches = row.batch_data.upcoming_two_batches;
          let batches = next2Batches.map((batch) => {
            return (
              <div style={{ width: "4rem", cursor: "pointer" }}>
                <CourseListRoundProgressBar
                  key={batch.batch_id}
                  currentCount={batch.occupied}
                  maxCount={batch.batch_size}
                  isPrivate={batch.batch_private ? true : false}
                  isPublicSession={batch?.batch_private ? false : true}
                  clickHandler={(event) => {
                    openSessionUserList(batch.batch_id);
                  }}
                />
              </div>
            );
          });
          return batches;
        }
      },
    },
    {
      dataIndex: "waitinglist_count",
      title: "Waitlist".toUpperCase(),
      ellipsis: true,
      sorter: (a, b) => {
        return a.waitinglist_count - b.waitinglist_count;
      },
      sort: true,
      width: 100,
      render: (cell, row) => {
        return (
          <div style={{ width: "4rem", cursor: "pointer" }}>
            <CourseListRoundProgressBar
              isWaitlist={true}
              maxCount={cell}
              clickHandler={(event) => {
                openWaitlistUserList(row.id);
              }}
            />
          </div>
        );
      },
    },
    {
      dataIndex: "updated_timestamp",
      title: "Last Update".toUpperCase(),
      sorter: (a, b) => {
        return a.updated_timestamp - b.updated_timestamp;
      },
      sort: true,
      width: 120,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "id",
      isDummyField: true,
      title: "Edit Course".toUpperCase(),
      sort: false,
      headerAlign: "center",
      csvExport: false,
      width: 80,
      align: "center",
      render: (cell, row) => {
        return (
          <Button
            type="button"
            icon={<EditOutlined />}
            alt="edit button"
            onClick={(event) => {
              openEditCourse(row, row.id);
            }}
            className={"courseList-numberButton"}
          />
        );
      },
    },
    {
      dataIndex: "batch_data.total_available_batches",
      title: "Manage Sessions".toUpperCase(),
      sorter: (a, b) => {
        return (
          a.batch_data.total_available_batches -
          b.batch_data.total_available_batches
        );
      },
      width: 100,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      render: (cell, row) => {
        if (row.batch_data.total_available_batches) {
          return (
            <Button
              type="button"
              style={{
                borderColor: "black",
                borderWidth: "3px",
                borderRadius: "1px",
                fontFamily: "RakutenRoundRegular",
                width: "0.8rem",
              }}
              count={row.batch_data.total_available_batches}
              onClick={(event) => {
                openSessionList(row.id);
              }}
            >
              <span
                style={{
                  position: "relative",
                  right: "0.2rem",
                  bottom: "0.1rem",
                }}
              >
                {row.batch_data.total_available_batches}
              </span>
            </Button>
          );
        } else {
          return (
            <>
              <Button
                type="button"
                icon={
                  <PlusSquareOutlined
                    style={{ fontSize: "2rem", color: "#393a3a" }}
                  />
                }
                alt="add users button"
                onClick={(event) => {
                  openSessionList(row.id);
                }}
                className={"courseList-numberButton"}
              />
            </>
          );
        }
      },
    },
    {
      dataIndex: "add_user",
      title: "Add User".toUpperCase(),
      width: 80,
      render: (cell, row) => {
        return (
          <Button
            type="button"
            icon={<UserAddOutlined />}
            alt="add users button"
            onClick={(event) => {
              openAddUserModal(row.id);
            }}
            className={"courseList-numberButton"}
          />
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "updated_timestamp",
      text: "",
      order: "desc",
    },
  ];

  const archivedCourseColumns = activeCourseColumns.slice(0, 10);

  const [tableColumns, settableColumns] = useState(activeCourseColumns);

  useEffect(() => {
    settableColumns(activeCourseColumns);
  }, [userDateFormat]);

  const onPageFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const page = "course";

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAddUserModal(false);
  }

  const openAddUserModal = (row) => {
    setCurrentRow(row); //courseId
    setShowAddUserModal(true);
  };

  const openSessionList = (courseId) => {
    history.push(`/Admin/SessionList/${courseId}`);
  };

  const openSessionUserList = (sessionId) => {
    history.push(`/Admin/enrolled/${sessionId}`);
  };

  const openWaitlistUserList = (courseId) => {
    history.push(`/Admin/waitingList/${courseId}`);
  };

  const handleCourseProvider = (value) => {
    setSelectedCourseProvider(value);
  };

  const handleSubCourseProvider = (value) => {
    setSelectedSubCourseProvider(value);
  };

  useEffect(() => {
    if (selectedFilter === "1") {
      // load active course data
      getAllActiveCourses();
    } else {
      // load archived course data
      getAllArchivedCourse();
    }
    // eslint-disable-next-line
  }, [selectedCourseProvider, selectedSubCourseProvider, selectedFilter]);

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    axios
      .get(dataEndpoint)
      .then((response) => {
        const list = [{ label: "All", value: "all" }];
        serializeDataResponse(response)?.courseProviders.map((item) =>
          list.push({ label: item.value, value: item.id })
        );
        setCourseProviderlist(list);
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message || "Something went wrong.");
        setShowAlert(true);
        setAlertType("error");
      });
  }, []);

  useEffect(() => {
    if (selectedCourseProvider && selectedCourseProvider.value !== "all") {
      const subCourseProviderEndpoint = `${config.endPoint.commonService}/subCourseProvider`;
      axios
        .get(
          `${subCourseProviderEndpoint}?courseProviderId=${selectedCourseProvider.value}`
        )
        .then((response) => {
          const list = [{ label: "All", value: "all" }];
          response.data.data.map((item) =>
            list.push({ label: item.name, value: item.id })
          );
          setSubCourseProviderList(list);
          if (
            selectedSubCourseProvider &&
            !list.includes(selectedSubCourseProvider)
          ) {
            setSelectedSubCourseProvider(null);
          }
        })
        .catch((error) => {
          setAlert(error?.response?.data?.message || "Something went wrong.");
          setShowAlert(true);
          setAlertType("error");
        });
    } else {
      setSubCourseProviderList([]);
      setSelectedSubCourseProvider(null);
    }
    // eslint-disable-next-line
  }, [selectedCourseProvider]);

  const getAllActiveCourses = (page=1, pageSize=10) => {
    settableColumns(activeCourseColumns);
    setShowLoader(true);
    setIsActive(true);
    let params = { is_global: 1,page_size:pageSize, page_number:page  };
    if(searchText){
      params = {...params,courseName:searchText}
    }
    if (selectedCourseProvider?.value !== "all") {
      params = { ...params, courseProvider: selectedCourseProvider?.value };
    }
    if (selectedSubCourseProvider?.value !== "all") {
      params = {
        ...params,
        subCourseProvider: selectedSubCourseProvider?.value,
      };
    }
    axios
      .get(`${config.endPoint.instructorLedNew}/course`, {
        params: params,
      })
      .then((response) => {
        setListData(response.data.data);
        setTableData(response.data.data);
        setActiveData(response.data.data);
        setShowLoader(false);
        setCsvFileName("Active Courses");
        setPageCount(response.data.data.length);
        setPagination({
          current: page,
          pageSize,
          total: response.data.total,
        });
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const getAllArchivedCourse = (page=1, pageSize=10) => {
    settableColumns(archivedCourseColumns);
    setShowLoader(true);
    setIsActive(false);
    let params = { archived: 1, is_global: 1,page_size:pageSize, page_number:page };
    if(searchText){
      params = {...params,courseName:searchText}
    }
    if (selectedCourseProvider?.value !== "all") {
      params = { ...params, courseProvider: selectedCourseProvider?.value };
    }
    if (selectedSubCourseProvider?.value !== "all") {
      params = {
        ...params,
        subCourseProvider: selectedSubCourseProvider?.value,
      };
    }
    axios
      .get(`${config.endPoint.instructorLedNew}/course/`, { params: params })
      .then((response) => {
        setListData(response.data.data);
        setTableData(response.data.data);
        setActiveData(response.data.data);
        setShowLoader(false);
        setCsvFileName("Archived");
        setPageCount(response.data.data.length);
        setPagination({
          current: page,
          pageSize,
          total: response.data.total,
        });
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const onAddNewCourse = () => {
    history.push("/Admin/AddEditCourse");
  };

  const openEditCourse = (courseDetails, courseId) => {
    history.push(`/Admin/AddEditCourse?courseId=${courseId}`);
  };

  const toggleFilter = () => {
    return (
      <Tabs onChange={(e) => onPageFilterChange(e)} className={"course-tabs"}>
        <TabPane tab="Active" key="1"></TabPane>
        <TabPane tab="Archived" key="2"></TabPane>
      </Tabs>
    );
  };

  const unwantedColumns = [
    "private",
    "batch_data",
    "add_user",
    "id",
    "batch_data.total_available_batches",
  ];

  const filteredColumns = tableColumns.filter(
    (header) => !unwantedColumns.includes(header.dataIndex)
  );

  const CSVColumns = filteredColumns.map((header, index) => ({
    key: header.dataIndex,
    label: header.title,
  }));

  const handleSearch = (searchText) => {
    // const filteredData = tableData.filter((item) => {
    //   const { name, category, subcategory, identity_id } = item;
    //   const curatedFieldValues = `${identity_id} ${name} ${category?.name} ${subcategory?.name}`;

    //   return curatedFieldValues
    //     .toLowerCase()
    //     .includes(searchText.toLowerCase());
    // });
    // if (searchText === "") {
    //   setListData(tableData);
    // } else {
    //   setListData(filteredData);
    // }
    debouncedSearch(searchText);
  };

  useEffect(() => {
    setShowLoader(true);
    getAllActiveCourses(pagination.current, pagination.pageSize);
  }, [searchText]);

  const handleTableChange = (pagination) => {
    setShowLoader(true);
    getAllActiveCourses(pagination.current, pagination.pageSize);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
    }, 500),
    []
  );

  const PublicPrivateLegend = (
    <>
      <Row className={"courseList-legendContainer"}>
        <Col lg={12}>
          <Row>
            <Col lg={9}>
              <div className={"courseList-publicIcon"}></div>
            </Col>
            <Col lg={6} style={{ fontFamily: "RakutenRoundRegular" }}>
              Public
            </Col>
          </Row>
        </Col>
        <Col lg={11}>
          <Row>
            <Col lg={10}>
              {/* <img src={PrivateCourseIcon} alt="private" /> */}
              <PrivateCourseIcon color2={userConfig.secondary} />
            </Col>
            <Col lg={6} style={{ fontFamily: "RakutenRoundRegular" }}>
              Private
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

  const getDataToDownload = (listData) => {
    const getEnrolledUsersCount = (data) => {
      if (data.batch_data.total_available_batches > 0) {
        let total = 0;
        data?.batch_data?.upcoming_two_batches?.forEach((batch) => {
          total += batch?.occupied;
        });
        return total;
      } else {
        return 0;
      }
    };

    return listData?.map((data) => ({
      ...data,
      updated_timestamp: dayjs
        .unix(data.updated_timestamp)
        .format("DD/MM/YYYY HH:mm:ss"),
      batch_data: getEnrolledUsersCount(data),
    }));
  };

  const tableHeader = (
    <div className={"courseList-header"}>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col lg={5}>
          <Typography
            style={{
              fontFamily: "RakutenRoundSemiBold",
              fontSize: "2.3rem",
              lineHeight: "normal",
            }}
          >
            Instructor-led Courses{" "}
            <span
              style={{ fontSize: "0.9rem", fontFamily: "RakutenRoundRegular" }}
            >
              ({showLoader ? <Spin /> : pagination.total})
            </span>
          </Typography>
        </Col>
        <Col lg={3}>{PublicPrivateLegend}</Col>
        <Col lg={6}>{toggleFilter()}</Col>
        <Col lg={1}></Col>
        <Col lg={2}>
          <CSVLink
            filename={csvFileName}
            extension=".csv"
            wrapColumnChar=""
            headers={CSVColumns}
            data={getDataToDownload(listData)}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "1.5rem",
              }}
            />
          </CSVLink>
        </Col>
        <Col lg={3}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              width: " 11rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
        <Col lg={1}></Col>
        <Col lg={3}>
          <Button
            type="button"
            className={"courseList-addNewCourse"}
            icon={<PlusCircleOutlined />}
            onClick={onAddNewCourse}
          >
            ADD COURSE
          </Button>
        </Col>
      </Row>
      <div style={{ width: "auto" }}>
        <Typography className={"sessionAddEdit-label"}>Filter By</Typography>
        <Row gutter={20}>
          <Col lg={4} style={{ zIndex: 998 }}>
            <Select
              options={courseProviderlist}
              value={selectedCourseProvider}
              onChange={(value) => {
                handleCourseProvider(value);
              }}
              placeholder="Course Provider"
              classNamePrefix={`innerclass`}
            />
          </Col>
          <Col lg={4} style={{ zIndex: 998 }}>
            <Select
              options={subCourseProviderList}
              value={selectedSubCourseProvider}
              onChange={(value) => {
                handleSubCourseProvider(value);
              }}
              placeholder="Sub Course Provider"
              classNamePrefix={`innerclass`}
            />
          </Col>
        </Row>
      </div>
    </div>
  );

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setShowAlert(true);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const rowClassName = (record) =>
    record.batch_data.total_available_batches === 0 ? "table-row-dark" : 0;

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
            onCloseAlert={() => setAlert("")}
          />
        )}
        <Breadcrumb items={crumbs} className={"courseList-crumbs"} />
        {tableHeader}
        {
          <div className={"courseList-table"}>
            <Table
              scroll={{
                x: 1300,
              }}
              rowKey="id"
              rowClassName={rowClassName}
              loading={showLoader}
              dataSource={[...listData].sort(
                (a, b) => b.created_timestamp - a.created_timestamp
              )}
              columns={tableColumns}
              defaultSorted={defaultSorted}
              pagination={{
                ...pagination,
                position: ['bottomCenter'],
              }}
              onChange={handleTableChange}
            />
          </div>
        }
        {(showAddUserModal || showErrorModal) && (
          <ImportUserHelper
            showAddUserModal={showAddUserModal}
            showErrorModal={showErrorModal}
            setShowAlert={setShowAlert}
            setShowErrorModal={setShowErrorModal}
            handleCloseAddUserModal={handleCloseAddUserModal}
            handleCloseErrorModal={handleCloseErrorModal}
            page={page}
            courseId={currentRow}
            setAlert={setAlert}
            setAlertType={setAlertType}
            updatePage={getAllActiveCourses}
            openErrorCloseAddUser={openErrorCloseAddUser}
          />
        )}
      </div>
    </>
  );
}

import React, { useState, createContext, useContext, useEffect } from "react";
// import { KeycloakContext } from "./KeycloakContext";
import { fetchUserDetails } from "../api/users/users";
import config from "../config";
import { LoginContext } from "./LoginContext";
import axios from "axios";

const UserContext = createContext();

function UserContextProvider(props) {
  // const { keycloakInitialized, tokenData } = useContext(KeycloakContext);
  const { tokenData } = useContext(LoginContext);

  const [user, setUser] = useState({
    id: null,
    name: null,
    roles: null,
    email: null,
    country: null,
    config: {
      primary: "#00a0f0",
      secondary: "#00a0f0",
      tertiary: "#00a0f0",
      showQuotation: true,
      quotationTitle:
        "When empowerment is your goal, the best possible business results follow",
      quotationAuthor: `Hiroshi "Mickey" Mikitani`,
      quotationAuthorDesignation: "CEO and Founder Rakuten",
    },
  });

  useEffect(() => {
    if (tokenData) {
      fetchUserRoles();
    }
  }, [tokenData]);

  const fetchUserRoles = async () => {
    fetchUserDetails().then(async (response) => {
      const { id, firstName, lastName, roleIds, email, country, tenantID } =
        response;

      const configResponse = await axios.get(
        `${config.endPoint.configManager}/api/tenants/${tenantID}?attributes=frontend`
      );

      const quotation = configResponse.data.data.frontendConfig.quotation;
      let frontendConfig;

      if (configResponse.data.data.frontendConfig.colors) {
        frontendConfig = configResponse.data.data.frontendConfig.colors;
      } else {
        frontendConfig = user.config;
      }

      if (configResponse.data.data.frontendConfig.quotation) {
        frontendConfig = {
          ...frontendConfig,
          quotationTitle: quotation?.title,
          quotationAuthor: quotation?.author,
          quotationAuthorDesignation: quotation?.designation,
          showQuotation: quotation?.showQuotation,
        };
      } else {
        frontendConfig = {
          ...frontendConfig,
          quotationTitle: user.config.quotationTitle,
          quotationAuthor: user.config.quotationAuthor,
          quotationAuthorDesignation: user.config.quotationAuthorDesignation,
          showQuotation: user.config.showQuotation,
        };
      }

      if (
        configResponse.data.data.frontendConfig?.learningPlatforms?.length > 0
      ) {
        frontendConfig = {
          ...frontendConfig,
          learningPlatforms:
            configResponse.data.data.frontendConfig?.learningPlatforms,
        };
      }

      const root = document.documentElement;
      root.style.setProperty("--primary", frontendConfig?.primary);
      root.style.setProperty("--secondary", frontendConfig?.secondary);
      root.style.setProperty("--tertiary", frontendConfig?.tertiary);
      root.style.setProperty(
        "--secondary-with-opacity",
        frontendConfig?.secondary + 30
      );
      root.style.setProperty(
        "--primary-with-opacity",
        frontendConfig?.primary + 30
      );

      frontendConfig = {
        ...frontendConfig,
          hrmConfig: configResponse.data.data?.hrmConfig
      }
      setUser({
        ...user,
        id,
        name: `${firstName} ${lastName}`,
        roles: mapUserRoleOnApp(roleIds),
        email,
        country,
        tenantID,
        config: frontendConfig,
      });
    });
  };

  const mapUserRoleOnApp = (roleIds) => {
    let result = [];
    const { superAdmin, ILAdmin, ELAdmin, manager, user } =
      config.userRoleNames;
    if (roleIds.includes(99)) {
      result = [superAdmin, ILAdmin, ELAdmin, user];
    } else if (checkSubArrayInArray(roleIds, [3, 2])) {
      result = [ILAdmin, ELAdmin, user];
    } else if (checkSubArrayInArray(roleIds, [2])) {
      result = [ILAdmin, user];
    } else if (checkSubArrayInArray(roleIds, [3])) {
      result = [ELAdmin, user];
    } else {
      result = [user];
    }
    if (roleIds.includes(4)) {
      result.push(manager);
    }
    return result;
  };

  const checkSubArrayInArray = (arr, subArr) => {
    return subArr.every((sub) => arr.includes(sub));
  };

  const hasRole = (roles) => {
    return roles.some((role) => user.roles?.includes(role));
  };

  return (
    user &&
    user.id &&
    user.name &&
    user.roles &&
    user.country &&
    user.email && (
      <UserContext.Provider
        value={{
          user,
          userId: user.id,
          userName: user.name,
          userRoles: user.roles,
          userCountry: user.country,
          userEmail: user.email,
          userConfig: user.config,
          setUser,
          hasRole,
          tenantID: user.tenantID,
        }}
      >
        {props.children}
      </UserContext.Provider>
    )
  );
}

export { UserContext, UserContextProvider };

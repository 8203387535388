import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import config from "../../../config";

export default function AntdUserCourseInsights(userId) {
  const [listData, setListData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [courseType, setCourseType] = useState("IL");
  const [ilData, setIlData] = useState([]);
  const [elData, setElData] = useState([]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");

  const courseDetailsColumn = [
    {
      dataIndex: "courseId",
      title: "Course ID",
      sorter: (a, b) => a?.courseId - b?.courseId,
      width: 60,
    },
    {
      dataIndex: "course.name",
      title: "Course Name",
      sorter: (a, b) => a?.course?.name?.localeCompare(b?.course?.name),
      width: 90,
      ellipsis: true,
      render: (cell, row) => {
        return (
          <Link
            to={`/course-details/${row.courseId}`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{
                color:
                  row.type == "IL"
                    ? row.course.private == true
                      ? "#F59600"
                      : "#00A0F0"
                    : row.course.private == true
                    ? "#F59600"
                    : "#333333",
              }}
              title={cell}
            >
              {row.course.name}
            </span>
          </Link>
        );
      },
    },
    {
      dataIndex: "type",
      title: "Type",
      sorter: (a, b) => a?.type.localeCompare(b?.type),
      width: 80,
    },
    {
      dataIndex: "course.category.categoryName",
      title: "Category",
      sorter: (a, b) =>
        a?.course?.category?.categoryName?.localeCompare(
          b?.course?.category?.categoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <span>{row?.course?.category?.categoryName || '-'}</span>;
      },
    },
    {
      dataIndex: "course.sub_category.subCategoryName",
      title: "Sub-Category",
      sorter: (a, b) =>
        a?.course?.sub_category?.subCategoryName?.localeCompare(
          b?.course?.sub_category?.subCategoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <span>{row?.course?.sub_category?.subCategoryName || '-'}</span>;
      },
    },
    {
      dataIndex: "isRecommended",
      title: "Is recommended?",

      render: (cell, row) => {
        return <>{cell ? <>YES</> : <>NO</>}</>;
      },
      filterValue: (cell, row) => {
        return cell ? "YES" : "NO";
      },
      sorter: (a, b) => a?.isRecommended - b?.isRecommended,
      width: 80,
    },
    {
      dataIndex: "courseStatus",
      title: "Completed Status",
      sorter: (a, b) => a?.courseStatus?.localeCompare(b?.courseStatus),
      width: 80,
      render: (cell, row) => {
        return (
          <span
            style={{
              color:
                row?.courseStatus?.toLowerCase() == "completed"
                  ? "#00B900"
                  : "#BF0000",
              fontFamily: "RakutenRoundSemiBold",
            }}
          >
            {row?.courseStatus}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getILCourse();
    getELCourse();
  }, []);

  useEffect(() => {
    setListData([...ilData, ...elData]);
  }, [ilData, elData]);

  const getILCourse = () => {
    setCourseType("IL");
    setShowLoader(true);
    axios
      .get(
        `${config.endPoint.instructorLed}/manager/${userId.stats}/allCourses`
      )
      .then((response) => {
        const ILdata = response.data.data.map((item) => ({
          ...item,
          type: "IL",
        }));
        setIlData(ILdata);
        setPageCount(response.data.data.length);
        setShowLoader(false);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const getELCourse = () => {
    setCourseType("EL");
    setShowLoader(true);
    axios
      .get(`${config.endPoint.elearning}/user/${userId.stats}/allCourses`)
      .then((response) => {
        const ELdata = response.data.data.map((item) => ({
          ...item,
          course: item.elearning_course,
          type: "EL",
        }));
        setElData(ELdata);
        setPageCount(response.data.data.length);
        setShowLoader(false);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const rowClassName = (record) =>
    record.type === "EL"
      ? record.course.private === true
        ? "table-row-private"
        : "table-row-elPublic"
      : record.course.private === true
      ? "table-row-private"
      : "table-row-ilPublic";

  return (
    <>
      <Table
        className="inner-table-content"
        rowKey="id"
        loading={showLoader}
        rowClassName={rowClassName}
        columns={courseDetailsColumn}
        dataSource={listData}
        pagination={{
          showSizeChanger: false,
        }}
      />
    </>
  );
}

import React, { useEffect, useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { Quotation } from "../../../../component-library";
import style from "../learningPathDetail/learningPathDetail.module.css";
import { UserContext } from "../../../../context/UserContext";
import CourseDescriptionBanner from "./CourseDescriptionBanner";
import PathCourses from "./PathCourses";
import CourseAdditionalDetails from "./CourseAdditionalDetails";
import "./antdLearningPathDetail.css";
import { getLearningPathDetails } from "../../../../api/learningPath/learningPath";
import { Layout } from "antd";
import PageFooter from "../../common/PageFooter";

const publicCourseBackgroundImage = "../../../../assets/images/public.svg";
const privateCourseBackgroundImage = "../../../../assets/images/private.svg";

export default function LearningPathDetail(props) {
  const [pathId, setPathId] = useState(props.match.params.pathId);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pathDetail, setPathDetail] = useState({});

  const [statusMessage, setStatusMessage] = useState();
  const { userId } = useContext(UserContext);

  useEffect(() => {
    document.title = `${
      pathDetail?.title ? pathDetail.title : "Learning Path Information"
    } | LMS`;
  });

  useEffect(() => {
    getPathDetails();
  }, [pathId]);

  useEffect(() => {
    setPathId(props.match.params.pathId);
  }, [props.match.params.pathId]);

  const getPathDetails = () => {
    getLearningPathDetails(pathId)
      // move to serializer
      .then(({ data }) => {
        if (data?.success) {
          const { data: result = {} } = data;
          setPathDetail(result);
          if (result?.learningpathStatus !== "active") {
            setStatusMessage(
              <p>
                This Path is currently not <strong>Active</strong>
              </p>
            );
          }
          if (result?.countries.indexOf(result.userCountry) < 0) {
            setStatusMessage(
              <p>
                The LearningPath is currently <b>NOT AVAILABLE</b> in your
                region.
              </p>
            );
          }
        }
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message || "Something went wrong");
        setAlertType("danger");
      });
  };

  const courseDescriptionBannerClass = pathDetail?.isPublic
    ? "public-course-banner"
    : "private-course-banner";

  return (
    <>
      <Container className={`${style.alertContainer} ${style.innerContainer}`}>
        {/* <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        /> */}
      </Container>

      <div className={courseDescriptionBannerClass}>
        <CourseDescriptionBanner
          pathDetail={pathDetail}
          userId={userId}
          setAlert={setAlert}
          setAlertType={setAlertType}
        />
      </div>

      <div className="path-courses">
        <PathCourses pathDetail={pathDetail} />
      </div>

      <div className="course-additional-details-container">
        <CourseAdditionalDetails
          course={{
            objectives: pathDetail?.objectives,
            targetedAudience: pathDetail?.targetedAudience,
            prerequisites: pathDetail?.prerequisites,
          }}
          overrideHeadingStyle={{ fontSize: 32 }}
        />
      </div>

      {/**
       * Below components should be changed to antd
       */}

      <Layout className={"il-details-publicContentContainer"} fluid>
        <Layout className={"il-details-innerContainer"}>
          <div className={"il-details-bannerContainer"}>
            <div className={"il-details-bannerContent"}>
              <div className={"il-details-bannerLabel"}>Step 1.</div>
              <div className={"il-details-bannerDescription"}>
                Explore and Enroll to the course
              </div>
            </div>
            <div className={"il-details-bannerPublicHorizontalLine"}></div>
            <div className={"il-details-bannerContent"}>
              <div className={"il-details-bannerLabel"}>Step 2.</div>
              <div className={"il-details-bannerDescription"}>
                Understand Course Objectives and Goals
              </div>
            </div>
            <div className={"il-details-bannerPublicHorizontalLine"}></div>
            <div className={"il-details-bannerContent"}>
              <div className={"il-details-bannerLabel"}>Step 3.</div>
              <div className={"il-details-bannerDescription"}>
                Learn and success your path
              </div>
            </div>
          </div>
        </Layout>
      </Layout>

      <div
        style={{
          backgroundColor: "white",
          paddingTop: "0.00625rem",
          display: "flex",
          justifyContent: "center",
          paddingTop: "5%",
        }}
      >
        {/* <Container bsPrefix={`container ${style.container}`}> */}
        <div className={`${style.secondContainer} ${style.pageWidth}`}>
          <Quotation />
        </div>
        {/* </Container> */}
      </div>
      {window.innerWidth < 786 && <PageFooter />}
    </>
  );
}

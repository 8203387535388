import { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
import { Row, Col, Typography } from "antd";

import LearningPathCard from "../../../../component-library/antDesignLearningPathCard/LearningPathCard";
import { LearningBySkills, Quotation } from "../../../../component-library";
import InfiniteScroll from "react-infinite-scroll-component";
import TabbedFilter from "../../../../component-library/antDesignTabbedFilter/antDesignTabbedFilter";
import HeroBanner from "../../../../component-library/antDesignHeroBanner/antDesignHeroBanner";
import { CourseContext } from "../../../../context/CourseContext";
import config from "../../../../config";
import "./antdLearningPathListing.css";
import PageFooter from "../../common/PageFooter";

///////////move this to config//////////////////
const GET_LEARNING_PATHS_API_ENDPOINT =
  config.endPoint.commonService + "/learningpath";

const isDesktop = window.innerWidth > 768;
const LearningPathListing = (props) => {
  // all states and constants
  const [learningPathList, setLearninPathList] = useState([]);
  const [learningPathPageNumber, setLearningPathPageNumber] = useState(1);
  const [learningPathTotalCount, setLearningPathTotalCount] = useState(0);
  const [hasMoreLearningPath, setHasMoreLearningPath] = useState(false);
  const [selectedCatagory, setselectedCatagory] = useState(
    props?.location?.state?.selectedCatagory || 0
  );

  const { parentCatagoryList } = useContext(CourseContext);

  const fetchLearningPaths = useCallback(() => {
    setHasMoreLearningPath(true);
    const config = {
      headers: { isadminpage: false },
      params: {
        learningPathStatus: "active",
        // pageNumber: learningPathPageNumber,
        // pageSize: 20,
        //isPublicOnly: true,
      },
    };
    if (String(selectedCatagory) !== "0") {
      config.params.parentCategoryId = selectedCatagory;
    }
    axios
      .get(GET_LEARNING_PATHS_API_ENDPOINT, config)
      .then((response) => {
        const data = response?.data?.data;
        const size = response?.data?.size;
        setLearningPathTotalCount(size);
        setLearninPathList((currentLearningPaths) => [
          ...currentLearningPaths,
          ...data,
        ]);
        setLearningPathTotalCount(size);
        if (data?.length === 0) {
          setHasMoreLearningPath(false);
        }
      })
      .catch((err) => {
        //need to handle error with notifications
      });
  }, [learningPathPageNumber, selectedCatagory]);

  const fetchMoreLearningpath = () => {
    setLearningPathPageNumber(() => learningPathPageNumber + 1);
  };

  const handleCatagoryChange = (key) => {
    setLearninPathList([]);
    setLearningPathPageNumber(1);
    setselectedCatagory(key);
  };

  useEffect(() => {
    fetchLearningPaths();
  }, [fetchLearningPaths]);

  const crumbs = [
    {
      title: (
        <span className="breadcrumb-title">
          <a href="/home">Home</a>
        </span>
      ),
    },
    { title: <span className="breadcrumb-title">Learning Path</span> },
  ];
  const { learningPath } = config;

  const renderCourseCountText = () => {
    return (
      <div>
        <Typography.Text style={{ fontFamily: "RakutenRoundRegular" }}>
          {`Showing ${learningPathTotalCount} total results for learning path`}
        </Typography.Text>
      </div>
    );
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroBanner
        crumbs={crumbs}
        heroBanner={learningPath.heroBanner}
        title={learningPath.title}
        description={learningPath.description}
      />

      <div className="learningPath-container">
        <div className="course-filter bg-white">
          <div
            className="content"
            style={{ marginLeft: isDesktop ? "" : "1rem" }}
          >
            {renderCourseCountText()}
            <TabbedFilter
              handleCatagoryChange={handleCatagoryChange}
              catagories={parentCatagoryList}
              defaultTab={selectedCatagory}
              tabBarGutter={isDesktop ? 92 : 50}
            ></TabbedFilter>
          </div>
        </div>
        <div className="learning-path-list bg-grey">
          <div className="content" id="scrollableDiv">
            {/* //TODO -- need to use className instead of inline css */}
            <InfiniteScroll
              dataLength={learningPathTotalCount}
              next={fetchMoreLearningpath}
              hasMore={hasMoreLearningPath}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                overflow: "hidden",
                width: "100%",
              }}
              scrollThreshold={0.4}
              scrollableTarget="scrollableDiv"
            >
              {isDesktop ? (
                <Row gutter={[40, 24]} className="infinite-scroll-row">
                  {learningPathList?.length ? (
                    learningPathList.map((currentLearningPath) => {
                      return (
                        <Col
                          span={12}
                          key={`learning-path-${currentLearningPath.id}`}
                        >
                          <LearningPathCard
                            currentLearningPath={currentLearningPath}
                            color="#FFFFFF"
                          ></LearningPathCard>
                        </Col>
                      );
                    })
                  ) : (
                    <div>no learning paths available </div>
                  )}
                </Row>
              ) : (
                <div>
                  {learningPathList?.length ? (
                    learningPathList.map((currentLearningPath) => {
                      return (
                        <div
                          className="lp-card-listing-page"
                          key={`learning-path-${currentLearningPath.id}`}
                        >
                          <LearningPathCard
                            currentLearningPath={currentLearningPath}
                            color="#FFFFFF"
                          ></LearningPathCard>
                        </div>
                      );
                    })
                  ) : (
                    <div>no learning paths available </div>
                  )}
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
        <div className="learning-skills bg-white">
          <div className="content">
            <LearningBySkills
              changeSuperCatergory={(id) => setselectedCatagory(id)}
              isFromHome={false}
            ></LearningBySkills>
          </div>
        </div>
        <div className="quotation bg-white">
          <div className="content">
            <Quotation />
          </div>
        </div>
      </div>
      {window.innerWidth < 786 && <PageFooter />}
    </div>
  );
};

export default LearningPathListing;

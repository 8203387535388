import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import {
  AdminPageTitle,
  BreadCrumb,
  TopContainer,
  AdminDataTable,
  AdminConfirmationModal,
  Alert,
  InlineIconButton,
} from "../../../component-library";
import { Row, Col } from "react-bootstrap";
import TimeFormatter from "../../../utils/timeFormatter";
import style from "./approvalList.module.css";
import acceptedImage from "../../../assets/icons/enroll.svg";
import declineImage from "../../../assets/icons/decline-enroll.svg";
import { CourseContext } from "../../../context/CourseContext";

export default function ApprovalList() {
  const { userDateFormat } = useContext(CourseContext);
  const [approvalPageData, setApprovalPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const dataTableRef = useRef();
  const [showEnrollConfimation, setShowEnrollConfirmation] = useState(false);
  const [rowForPendingUser, setRowForPendingUser] = useState({});
  const [declineUser, setDeclineUser] = useState(false);

  const approvalListColumns = [
    {
      dataField: "userId",
      text: "EMP ID",
      sort: true,
      headerStyle: {
        width: "6rem",
        height: "4rem",
        paddingLeft: "1rem",
      },
    },
    {
      dataField: "userName",
      text: "Name & Email",
      sort: true,
      headerStyle: {
        width: "15rem",
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataField: "userEmail",
      text: "",
      headerStyle: {
        width: "0rem",
        padding: "0rem",
      },
      formatter: (cell, row) => {
        return <>{}</>;
      },
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      headerStyle: {
        width: "10rem",
      },
    },
    {
      dataField: "country",
      text: "country",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataField: "courseId",
      text: "Course ID & Name",
      sort: true,
      headerStyle: {
        width: "12rem",
      },
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.courseId}`}</strong>
            </div>
            <div>{`${row.courseName}`}</div>
          </>
        );
      },
    },
    {
      dataField: "batchStartTime",
      text: "Session Start",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataField: "batchEndTime",
      text: "Session End",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataField: "enrolmentDate",
      text: "Enrolment Date",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataField: "userEmail",
      text: "Action",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "8rem",
      },
      formatter: (cell, row) => {
        return (
          <>
            <span>
              <InlineIconButton
                iconURL={acceptedImage}
                alt="enroll button"
                clickHandler={(event) => {
                  setRowForPendingUser(row);
                  setShowEnrollConfirmation(true);
                  setDeclineUser(false);
                }}
              />
            </span>
            <span>
              <InlineIconButton
                iconURL={declineImage}
                alt="decline button"
                imageStyle={style.declinedButton}
                clickHandler={(event) => {
                  setRowForPendingUser(row);
                  setShowEnrollConfirmation(true);
                  setDeclineUser(true);
                }}
              />
            </span>
          </>
        );
      },
    },
  ];

  const crumbs = [
    {
      title: "Home",
      to: "/",
    },
    { title: "My Approvals" },
  ];

  const downloadButtonStyle = {
    position: "relative",
    top: "-8rem",
    left: "60rem",
  };

  const tableSearch = {
    position: "relative",
    top: "-5.25rem",
    left: "65rem",
    width: "17rem",
  };

  const defaultSorted = [
    {
      dataField: "timeStamp",
      order: "asc",
    },
  ];

  const [tableColumns, setTableColumns] = useState(approvalListColumns);

  useEffect(() => {
    setTableColumns(approvalListColumns);
  }, [userDateFormat]);

  useEffect(() => {
    getApprovalData();
    // eslint-disable-next-line
  }, []);

  const getApprovalData = () => {
    axios
      .get("approvals")
      .then((response) => {
        setApprovalPageData(response.data.data);
        setPageCount(response.data.data.length);
        setTableColumns(approvalListColumns);
      })
      .catch((error) => {
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
        setAlertType("danger");
      });
  };

  const handleCloseEnrollConfirmation = () => {
    setShowEnrollConfirmation(false);
  };

  const handleEnrollUserAccepted = () => {
    axios
      .post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "approve",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      })
      .then((res) => {
        setAlert(
          `${rowForPendingUser.userName}'s enrollment request accepted for course ${rowForPendingUser.courseName}!`
        );
        setAlertType("success");
        setShowEnrollConfirmation(false);
        getApprovalData();
      })
      .catch((error) => {
        setShowEnrollConfirmation(false);
        try {
          setAlert(error?.response?.data?.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const handleRejectUserNextSession = () => {
    axios
      .post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "nextSession",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      })
      .then((res) => {
        setAlert(
          `${rowForPendingUser.userName}'s enrollment request rejected and requested for next session for course ${rowForPendingUser.courseName}!`
        );
        setAlertType("success");
        setShowEnrollConfirmation(false);
        getApprovalData();
      })
      .catch((error) => {
        setShowEnrollConfirmation(false);
        try {
          setAlert(error?.response?.data?.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const handleRejectUserRequest = () => {
    axios
      .post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "removeCourse",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      })
      .then((res) => {
        setAlert(
          `${rowForPendingUser.userName}'s enrollment request rejected and removed from course ${rowForPendingUser.courseName}!`
        );
        setAlertType("success");
        setShowEnrollConfirmation(false);
        getApprovalData();
      })
      .catch((error) => {
        setShowEnrollConfirmation(false);
        try {
          setAlert(error?.response?.data?.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const tableHeader = (
    <>
      <Row>
        <Col lg="3">
          <AdminPageTitle pageTitle="My Approval" pageCount={pageCount} />
        </Col>
      </Row>
    </>
  );

  const acceptUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Approve </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>{" "}
      ?
    </h3>
  );

  const declineUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Reject </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>{" "}
      ?
    </h3>
  );

  const acceptUserModalFooter = (
    <p style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <br></br>* Once approved user will get a confirmed seat in the session.
      For any changes to enrollment you will have to reach out to the L&D team.
    </p>
  );

  return (
    <>
      <TopContainer>
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <BreadCrumb crumbs={crumbs} />
        <AdminDataTable
          ref={dataTableRef}
          otherElements={tableHeader}
          keyField="userEmail"
          data={approvalPageData}
          columns={tableColumns}
          searchBarStyle={tableSearch}
          downloadButtonStyle={downloadButtonStyle}
          defaultSorted={defaultSorted}
          exportCSV={{
            fileName: `my_approval.csv`,
          }}
        />
      </TopContainer>
      {showEnrollConfimation && (
        <AdminConfirmationModal
          toggle={true}
          show={showEnrollConfimation}
          leftButton={declineUser ? "REQUEST FOR NEXT SESSION" : "YES"}
          rightButton={declineUser ? "REJECT ENROLLMENT REQUEST" : "NO"}
          handleClose={handleCloseEnrollConfirmation}
          rightButtonClick={
            declineUser
              ? handleRejectUserRequest
              : handleCloseEnrollConfirmation
          }
          leftButtonClick={
            declineUser ? handleRejectUserNextSession : handleEnrollUserAccepted
          }
          title={declineUser ? declineUserModal : acceptUserModal}
          modalFooterContent={declineUser ? "" : acceptUserModalFooter}
        />
      )}
    </>
  );
}

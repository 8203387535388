export const subUpperText =
  "Effortlessly train your employees, partners, and customers with enjoyable learning experiences using our LMS.";

export const upperTextOne = "Your All-in-One Training Solution!";

export const upperTextTwo = "Unleash Your Potential";

export const middleText =
  "Hassle-Free Admin Management of All Learning Activities";

export const middleSubText =
  "Our LMS provides a comprehensive, user-friendly, and tailored solution for organizations, trainers, and employees.";

export const imageText =
  "Watch our LMS Product Transformative Learning Experience Overview";

export const textArray = [
  {
    headlineText: "INTERACTIVE CONTENT",
    normalText: "Supports Video, Quizzes, And Interactive Modules.",
  },
  {
    headlineText: "INTEGRATION",
    normalText: "Seamlessly Integrates With Existing HR And Development Tools.",
  },
  {
    headlineText: "CUSTOMIZABLE LEARNING PATHS",
    normalText: "Tailored To Individual Learning Needs.",
  },
  {
    headlineText: "FREE COURSES",
    normalText:
      "Customized Mandatory Courses Like Compliance And posh Are Included For Free On The Platform.",
  },
  {
    headlineText: "MOBILE ACCESSIBILITY",
    normalText: "Learn Anytime, Anywhere On Any Device.",
  },
  {
    headlineText: "ANALYTICS & REPORTING",
    normalText: "Track Progress, Completion Rates, And Performance.",
  },
  {
    headlineText: "BUILT TO SCALE",
    normalText: "Scalable And Customizable For Any Organization Size.",
  },
  {
    headlineText: "SaaS",
    normalText: "A Cloud-Based, User-friendly Learning Solution.",
  },
];

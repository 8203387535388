import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Carousel } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";
import style from "./heroBanner.module.css";
import "./heroBanner.css";
import { Link } from "react-router-dom";
import TimeFormatter from "../../utils/timeFormatter";
import config from "../../config";

function HeroBanner({ upliftBannerText }) {
  const [promotionalBanners, setPromotionalBanners] = useState([]);
  const [nonPromotionalBanner, setNonPromotionalBanner] = useState(null);

  useEffect(() => {
    axios
      .get(`${config.endPoint.commonService}/promotionalBanners`)
      .then((res) => {
        if (res.data && res.data.data["non-promotional"]) {
          setNonPromotionalBanner(res.data.data["non-promotional"]);
        } else if (res.data && res.data.data.promotional) {
          console.log()
          let banner = res.data.data.promotional.filter((promotionalBanner) => {
            return (
              promotionalBanner.endDate >
                TimeFormatter.getCurrentTimeStamp() &&
              promotionalBanner.startDate <=
                TimeFormatter.getCurrentTimeStamp()
            );
          });

          setPromotionalBanners(banner);
        }
      })
      .catch(function (error) {
        //ToDo error handling
        console.log(error);
      });
  }, []);

  const scrollDown = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth",
    });
  };
  const nextImg = (
    <span aria-hidden="true" className="custom-control-next-icon" />
  );
  const prevImg = (
    <span aria-hidden="true" className="custom-control-prev-icon" />
  );

  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Carousel
        id="heroBannerSlide"
        indicators={false}
        controls={promotionalBanners && promotionalBanners.length > 1}
        nextIcon={nextImg}
        prevIcon={prevImg}
      >
        {promotionalBanners &&
          promotionalBanners.map((banner) => {
            return (
              <Carousel.Item interval={5000}>
                <a
                  href={banner.redirectionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={banner.imageUrl}
                    alt="Hero banner"
                    style={{ width: "100%", height: "100%" }}
                  />
                </a>
                <Carousel.Caption className={style.scrollText}>
                  <Link to="" onClick={scrollDown}>
                    <div className="scrollDownArrowPromotional bounce"></div>
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
      </Carousel>
      {nonPromotionalBanner ? (
        <Carousel indicators={false} controls={false}>
          <Carousel.Item>
            {upliftBannerText(nonPromotionalBanner.message)}
            <ReactPlayer
              url={nonPromotionalBanner.videoUrl}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
            <Carousel.Caption
              className={style.scrollText}
              style={{ color: "#FFFFFF" }}
            >
              <Link to="" onClick={scrollDown}>
                <div className="scrollDownArrowNonPromotional bounce"></div>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      ) : (
        <br />
      )}
    </Container>
  );
}

export default HeroBanner;

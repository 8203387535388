import { Button, Dropdown } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { range, padStart } from "lodash";
import { useEffect, useState } from "react";

export const DurationRangePicker = ({
  onChange,
  showSelectedDuration,
  defaultDuration,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [duration, setDuration] = useState({ hours: null, minutes: null });

  useEffect(() => {
    if (defaultDuration && typeof defaultDuration === "string") {
      const [hours, minutes] = defaultDuration.split(":");
      setDuration({
        hours,
        minutes,
      });
    }
  }, [defaultDuration]);

  const hourItems = range(0, 100).map((hourItem) =>
    padStart(String(hourItem), 2, "0")
  );

  const minuteItems = range(0, 60).map((minuteItem) =>
    padStart(String(minuteItem), 2, "0")
  );

  const handleDurationChange = (timeframe, value) => {
    const hours = duration.hours ? duration.hours : "00";
    const minutes = duration.minutes ? duration.minutes : "00";
    setDuration({
      hours,
      minutes,
      [timeframe]: value,
    });
  };

  const renderDurationLabel = () => {
    const hasDuration = duration.hours || duration.minutes;
    return hasDuration
      ? `${duration.hours}:${duration.minutes}`
      : "Select Duration";
  };

  return (
    <div>
      <Dropdown
        overlayStyle={{ zIndex: 900 }}
        dropdownRender={() => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    height: "10rem",
                    overflow: "scroll",
                    width: "5rem",
                    padding: "1rem",
                  }}
                >
                  {hourItems.map((e, i) => (
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor:
                          duration.hours === e ? "#00a0f0" : "white",
                        color: duration.hours === e ? "white" : "black",
                      }}
                      key={i}
                      onClick={() => {
                        handleDurationChange("hours", e);
                      }}
                    >
                      {e}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    height: "10rem",
                    overflow: "scroll",
                    width: "5rem",
                    padding: "1rem",
                  }}
                >
                  {minuteItems.map((e, i) => (
                    <div
                      key={i}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor:
                          duration.minutes === e ? "#00a0f0" : "white",
                        color: duration.minutes === e ? "white" : "black",
                      }}
                      onClick={() => {
                        handleDurationChange("minutes", e);
                      }}
                    >
                      {e}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                onClick={() => {
                  setOpenDropdown(false);
                  if (duration.hours || duration.minutes) {
                    onChange(`${duration.hours}:${duration.minutes}`);
                  }
                }}
                style={{
                  backgroundColor: "#00a0f0",
                  borderRadius: "0.25rem",
                  fontFamily: "RakutenRoundSemiBold",
                  fontSize: "1rem",
                  color: "#ffffff",
                  padding: "0.7rem 1.5rem",
                  border: "none",
                  textTransform: "uppercase",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Set
              </Button>
            </div>
          );
        }}
        trigger={["click"]}
        open={openDropdown}
      >
        <div
          onClick={(e) => {
            setOpenDropdown(true);
          }}
          style={{
            border: "1px solid lightgray",
            padding: ".6rem",
            width: "10rem",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "4px",
            fontFamily: "RakutenRoundRegular",
          }}
        >
          {renderDurationLabel()}
          <ClockCircleOutlined />
        </div>
      </Dropdown>
      {showSelectedDuration &&
        duration.hours &&
        duration.minutes &&
        !openDropdown && (
          <p style={{ fontFamily: "RakutenRoundRegular" }}>
            Duration: {duration.hours} Hours {duration.minutes} Minutes
          </p>
        )}
    </div>
  );
};

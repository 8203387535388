import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import style from "./skillTile.module.css";

function SkillTileBody({ image, title }) {
  return (
    <Card
      className={style.skillTile}
      style={{ borderRadius: "0.5rem" }}
      cover={
        <img src={image} style={{ borderRadius: "0.5rem", opacity: "0.9" }} />
      }
    >
      <span className={style.centered}>
        {(title && title.toUpperCase()) || ""}
      </span>
    </Card>
  );
}

export default function SkillTile({ image, title, id, index, isFromHome }) {
  return (
    <div
      className={style.skillTileBody}
      style={{
        marginRight: index === 3 ? "0" : "0.48875rem",
      }}
    >
      {isFromHome ? (
        <Link to={`/Classroom_training/${id}/all`}>
          <SkillTileBody image={image} title={title} />
        </Link>
      ) : (
        <div>
          <SkillTileBody image={image} title={title} />
        </div>
      )}
    </div>
  );
}

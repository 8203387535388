import React from "react";
import { Row, Col, Tooltip } from "antd";
import styles from "./adminPageSubTitle.module.css";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";

function AdminPageSubTitle({
  courseId,
  courseName,
  sessionId,
  sessionName,
  sessionTime,
  timeZone,
  otherStyle,
}) {
  return (
    <>
      <Row className={otherStyle} style={{ width: "86rem" }}>
        <Col>
          <div className={styles.pageSubTitle}>
            {(courseId && (
              <>
                <span className={styles.courseId}> {courseId} - </span>
                <Tooltip title={courseName}>
                  <span>{truncate(courseName, 30)}</span>
                </Tooltip>
                {sessionId && (
                  <>
                    <span className={styles.sessionId}> / {sessionId} - </span>
                    <span className={styles.sessionName}>{sessionName}</span>
                    {sessionTime && (
                      <span className={styles.sessionName}>{sessionTime}</span>
                    )}
                  </>
                )}
                {timeZone && (
                  <>
                    (Time Zone :{" "}
                    <span className={styles.sessionId}>{timeZone}</span>)
                  </>
                )}
              </>
            )) || <>&nbsp;</>}
          </div>
        </Col>
      </Row>
    </>
  );
}

AdminPageSubTitle.propTypes = {
  courseId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  sessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sessionName: PropTypes.string,
  timeZone: PropTypes.string,
  otherStyle: PropTypes.string,
};

export default AdminPageSubTitle;

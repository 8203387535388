import moment from "moment";

const offset = moment().utcOffset() / 60;
// Function to append options to timezone select
var tzStrings = [
  {
    label: "(GMT+01:00) Paris, Madrid and Berlin - Winter Time",
    str: "Europe/Paris",
    value: 1,
  },
  {
    label: "(GMT+02:00) Paris, Madrid and Berlin - Summer Time",
    str: "Europe/Paris",
    value: 2,
  },
  {
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai",
    str: "Asia/Calcutta",
    value: 5.5,
  },
  {
    label: "(GMT+08:00) China, Singapore, Taiwan ",
    str: "China, Singapore, Taiwan",
    value: 8,
  },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", str: "Asia/Tokyo", value: 9 },
];

let TimeFormaters = {
  FormatDate(ts, offset = null, dateFormat = null) {
    if (offset) {
      offset = parseFloat(offset);
      return moment
        .utc(ts * 1000)
        .utcOffset(offset)
        .format(dateFormat || "MMM DD, YYYY");
    } else {
      return moment.utc(ts * 1000).format(dateFormat || "MMM DD, YYYY");
    }
  },

  FormatTime(ts, offset = null) {
    if (offset) {
      offset = parseFloat(offset);
      return moment
        .utc(ts * 1000)
        .utcOffset(offset)
        .format("hh:mm A");
    } else {
      return moment.utc(ts * 1000).format("hh:mm A");
    }
  },

  getUtcTimeStamp(date, time, offset = null, format = null) {
    if (offset) {
      offset = parseFloat(offset);
      return (
        moment(date + " " + time, format ? format : "YYYY-MM-DD, hh:mm A")
          .utcOffset(offset, true)
          .seconds(0)
          .milliseconds(0)
          .utc()
          .valueOf() / 1000
      );
    } else {
      // gets local tz and returns utc
      return (
        moment(date + " " + time, format ? format : "YYYY-MM-DD, hh:mm A")
          .seconds(0)
          .milliseconds(0)
          .utc()
          .valueOf() / 1000
      );
    }
  },

  getCurrentTimeStamp(offset = null) {
    if (offset) {
      offset = parseFloat(offset);
      return (
        moment()
          .utcOffset(offset, true)
          .seconds(0)
          .milliseconds(0)
          .utc()
          .valueOf() / 1000
      );
    } else {
      // gets local tz and returns utc
      return moment().seconds(0).milliseconds(0).utc().valueOf() / 1000;
    }
  },

  HTMLDateInputFormat(ts, offset = null) {
    if (!ts) {
      return null;
    }
    if (offset) {
      offset = parseFloat(offset);
      return moment
        .utc(ts * 1000)
        .utcOffset(offset)
        .format("YYYY-MM-DD");
    } else {
      return moment.utc(ts * 1000).format("YYYY-MM-DD");
    }
  },

  HTMLTimeInputFormat(ts, offset = null) {
    if (offset) {
      offset = parseFloat(offset);
      return moment
        .utc(ts * 1000)
        .utcOffset(offset)
        .format("HH:mm");
    } else {
      return moment.utc(ts * 1000).format("HH:mm");
    }
  },

  wickedTimePickerFormat(ts, offset = null) {
    let time;
    if (offset) {
      offset = parseFloat(offset);
      time = moment
        .utc(ts * 1000)
        .utcOffset(offset)
        .format("hh:mm A");
    } else {
      time = moment.utc(ts * 1000).format("hh:mm A");
    }
    return time;
  },

  get_date_time_obj(d, t) {
    var _d = d.split("-");
    var _t = t.split(":");
    return new Date(_d[0], _d[1] - 1, _d[2], _t[0], _t[1]);
  },

  //TODO - New function written needs to be removed
  getTimeZoneByOffset(offset) {
    let str = ["", ""];
    tzStrings.forEach((ele) => {
      if (ele.value == offset) {
        str[0] = ele.str;
        return;
      }
    });
    return str;
  },

  getTimeZoneFromOffset(offset) {
    return tzStrings.find((item) => item.value === parseFloat(offset))?.str;
  },

  loadTimeZoneList(select_id, offset = null) {
    let $select = select_id;
    $select.text("");
    let options = "";

    if (!offset) {
      // convert offset minutes to hours in base 10
      offset = moment().utcOffset() / 60;
    }

    tzStrings.forEach((tz) => {
      let selected = offset === tz.value ? "selected" : "";
      options += `<option value=${tz.value} ${selected}> ${tz.label} </option> `;
    });
    $select.append(options);
  },

  validateEmail(sEmail) {
    var filter = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (filter.test(sEmail)) {
      return true;
    } else {
      return false;
    }
  },

  validateRakutenEmail(sEmail) {
    var filter = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (filter.test(sEmail) && sEmail.indexOf("@rakuten.com") > 0) {
      return true;
    } else {
      return false;
    }
  },

  updateCountrySelect(select_id, selected_country = "") {
    let country = [
      { t: "Japan", v: "Japan" },
      { t: "India", v: "India" },
      { t: "China", v: "China" },
      { t: "Singapore", v: "Singapore" },
      { t: "Taiwan", v: "Taiwan" },
    ];

    let $select = select_id;
    $select.text("");
    let options = "";

    country.forEach((c) => {
      let selected = selected_country === c.v ? "selected" : "";
      options += `<option value=${c.v} ${selected}> ${c.t} </option> `;
    });
    $select.append(options);
  },

  updateLanguageSelect(select_id, selected_language = "") {
    let language = [
      { t: "Japanese", v: "Japanese" },
      { t: "English", v: "English" },
    ];

    let $select = select_id;
    $select.text("");
    let options = "";

    language.forEach((c) => {
      let selected = selected_language === c.v ? "selected" : "";
      options += `<option value=${c.v} ${selected}> ${c.t} </option> `;
    });
    $select.append(options);
  },

  getTimeZone(offset) {
    switch (offset) {
      case "5.5":
        return "IST";
      case "8.0":
        return "SGT / CST";
      case "9.0":
        return "JST";
      default:
        return "IST";
    }
  },

  FormatTableTime(timestamp, tz_offset = null, dateFormat = null) {
    if (!timestamp) return "-";
    let formattedTIme;
    if (tz_offset) {
      tz_offset = parseFloat(tz_offset);
      return moment
        .utc(timestamp * 1000)
        .utcOffset(tz_offset)
        .format(dateFormat ? `${dateFormat} hh:mm A` : "MMM DD, YYYY, hh:mm A");
    } else {
      formattedTIme = moment
        .utc(timestamp * 1000)
        .utcOffset(offset)
        .format(dateFormat ? `${dateFormat} hh:mm A` : "MMM DD, YYYY, hh:mm A");
    }
    return formattedTIme;
  },

  getLongDateTime(timestamp) {
    return moment(timestamp * 1000).format("LLLL");
  },

  getWeekdayWithMonth(timestamp) {
    // e.g. Monday, 17th April
    return moment(timestamp * 1000).format("dddd, Do MMMM ");
  },

  getLocalTime(ts) {
    return moment(ts * 1000).format("hh:mm A");
  },

  utcToLocal(timestamp) {
    return moment(timestamp).local();
  },

  formatDateTime(timestamp, dateFormat = null) {
    return moment(timestamp).format(
      dateFormat ? `${dateFormat} hh:mm A` : "MMM DD, YYYY, hh:mm A"
    );
  },

  hrsMinsToHrs(timeStr) {
    let [hrs, min] = timeStr.split(":");
    hrs = (hrs && parseInt(hrs)) || 0;
    min = (min && parseInt(min)) || 0;
    timeStr = hrs + min / 60;
    return parseFloat(parseFloat(timeStr).toFixed(2));
  },

  decimalHrsToHrsMin(decimalHrs, type = "min") {
    if (decimalHrs) {
      decimalHrs = parseFloat(decimalHrs);
      let hours = Math.floor(decimalHrs);
      let minutes = Math.round((decimalHrs - hours) * 60);
      if (type === "detailed") {
        if (!hours && minutes) {
          return `${minutes} Mins`;
        }
        if (hours && !minutes) {
          return `${hours} Hrs`;
        }
        return `${hours} Hrs ${minutes} Mins`;
      }
      if (type === "min") {
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        return `${hours}:${minutes}`;
      }
    }
  },

  timeZoneList: tzStrings,
};

export default TimeFormaters;

import React, { useState } from "react";
import { Modal, Col, Image } from "react-bootstrap";
import style from "./sessionList.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FileUploader,
  PrimaryButton,
  Validation,
} from "../../../component-library";
import privateIcon from "./../../../assets/icons/private_icon.svg";
import publicIcon from "./../../../assets/icons/public_icon.svg";
import axios from "axios";
import UtilityFunctions from "../../../utils/utility";
import { CSVLink } from "react-csv";

export default function AttendanceModel({
  sessionId,
  sessionName,
  enrolledUsers,
  sessionSize,
  setAlert,
  setAlertType,
  modalCloseHandler,
  courseId,
  isPrivate,
  showAttendaceModel,
  callbackCompletedSession,
}) {
  const [attendanceFile, setAttendaceFile] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const disable = !!errorMessage.attendanceFile;
  const submitHandler = () => {
    if (sessionId) {
      const formData = new FormData();
      formData.append("attendance_file", attendanceFile);

      const errors = {};
      if (!attendanceFile) {
        errors.attendanceFile = "Please select the attendance file. ";
      }

      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      const endPoint = `/import-past-data?course_id=${courseId}&session_id=${sessionId}`;
      if (!errors || !Object.keys(errors).length) {
        axios
          .post(endPoint, formData)
          .then((response) => {
            const filename = "bulk-upload-attendance.csv";
            UtilityFunctions.downloadCSVFile(response, filename);
            callbackCompletedSession();
            setAlertType("success");
            setAlert("File uploaded successfully");
          })
          .catch((error) => {
            setAlert(error?.response?.data?.message);
            setAlertType("danger");
          });
        modalCloseHandler();
      }
    }
  };

  function disclaimerMessage() {
    return (
      <div className={style.disclaimerMessage}>
        *Users specified in the uploaded file will we added to this session and
        their attendance will be marked as per the data provided in the file
      </div>
    );
  }

  const headers = [
    { label: "Valid Rakuten Email-ID's only", key: "email" },
    { label: "Attendance Status", key: "status" },
  ];

  const data = [
    {
      email: "abc@rakuten.com",
      status: "Completed",
    },
    {
      email: "xyz@rakuten.com",
      status: "Completed",
    },
  ];

  const csvReport = {
	data: data,
	headers: headers,
	filename: "attendance.csv",
  };

  const hint = (
    <div>
      Hint : Drag and Drop here to upload - &nbsp;
      <CSVLink {...csvReport}>Spreadsheet</CSVLink> sample
    </div>
  );

  return (
    <>
      <Modal
        animation={false}
        contentClassName={style.modal}
        centered
        show={showAttendaceModel}
        onHide={modalCloseHandler}
      >
        <Modal.Header
          className={style.header}
          closeButton
          onClick={modalCloseHandler}
        >
          <Col>
            <Modal.Title className={style.title}>
              <span>
                <b> Update </b> Enrolled Users data for a
                <b> Completed Session</b>
              </span>
            </Modal.Title>
          </Col>
        </Modal.Header>
        <Modal.Body>
          <div className={style.functionalBox}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Image src={(isPrivate && privateIcon) || publicIcon} alt="" />
              &nbsp;
              <b>{sessionId}</b> - <b>{sessionName}</b>
              {`- (Enrolled user: ${enrolledUsers || 0}/${sessionSize || 0})`}
            </div>
            <div className={style.importExcel}>
              <p>Import an Excel/CSV file</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.563rem",
              }}
            >
              <FileUploader
                fName=""
                upliftFileDetails={({ fileData, error }) => {
                  if (error) {
                    setErrorMessage({ attendanceFile: error });
                  } else {
                    setErrorMessage({ attendanceFile: "" });
                    setAttendaceFile(fileData);
                  }
                }}
                hint={hint}
              />
            </div>
            {(errorMessage.attendanceFile && (
              <Validation
                message={errorMessage.attendanceFile}
                st={{ display: "flex", justifyContent: "center", marginTop: 0 }}
              />
            )) ||
              ""}
          </div>
          {disclaimerMessage()}
          <div className={style.footerButton}>
            <span className={style.cancelButton}>
              <PrimaryButton
                label="SUBMIT"
                handleClick={submitHandler}
                isDisabled={disable}
              />
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

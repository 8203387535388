import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Layout, Breadcrumb, Tooltip, Typography } from "antd";
import { ReactHeight } from "react-height";
import courseProviderIcon from "../../../assets/icons/courseProviderIcon.svg";
import courseIcon from "../../../assets/icons/InstructorLedCourse.svg";
import {
  AdminConfirmationModal,
  Alert,
  IlCourseContent,
  PrimaryButton,
  PrimaryOutlineButton,
  Quotation,
} from "../../../component-library";
import PrimaryFilledOutlineButton from "../../../component-library/primaryFilledOutlineButton/PrimaryFilledOutlineButton";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import TimeFormaters from "../../../utils/timeFormatter";
// import style from "./courseDetail.module.css";

import CourseDetailScheduleAccordian from "./courseDetailScheduleAccordian/CourseDetailScheduleAccordian";
import { modifyImageTags } from "../../../utils/textEditor";
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";
import { useLocation } from "react-router-dom";
import { CourseContext } from "../../../context/CourseContext";
import "./courseDetail.scss";
import { FaRegBookmark } from "react-icons/fa6";
import { FaBookmark } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CourseDetailsSVGBackground from "../../../components/common/CourseDetailsSvgBackground";

// import ELearningPlatform from "../home/eLearningPlatform/ELearningPlatform";

function CourseDetail(props) {
  const { userDateFormat } = useContext(CourseContext);
  const offset = moment().utcOffset() / 60;
  const [show, setShow] = useState(false);
  const [courseId, setCourseId] = useState(props.match.params.course_id);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [courseDetail, setCourseDetail] = useState({});
  const [sessionDetail, setSessionDetail] = useState([]);
  const [enrolmentStatus, setEnrolmentStatus] = useState("");
  const [courseImage, setCourseImage] = useState("");
  const [scheduleToShow, setScheduleToShow] = useState(3);
  const [scheduleIsExpanded, setScheduleIsExpanded] = useState(false);
  const [scheduleViewLabel, setScheduleViewLabel] = useState("VIEW MORE");
  const [buttonClass, setButtonClass] = useState("");
  const [buttonDetail, setButtonDetail] = useState({});
  const [showUnenrolOptions, setShowUnenrolOptions] = useState(false);
  const [heightDiv, setHeightDiv] = useState(200);
  const [isFavourite, setIsFavourite] = useState(false);
  const location = useLocation();
  const [learningPathId, setLearningPathId] = useState(null);
  const courseTitle = decodeHtmlEntities(courseDetail.course_name);
  const { userId, userConfig } = useContext(UserContext);
  const isMobile = window.innerWidth < 786;

  const crumbs = [
    {
      title: <a href="/">Home</a>,
    },
    {
      title: <a href="/Classroom_training/all/all">Instructor Led</a>,
    },
    {
      title: (
        <Tooltip
          placement="bottomLeft"
          color="#9c9c9c"
          overlayStyle={{
            fontFamily: "RakutenRoundRegular",
            maxWidth: "12rem",
          }}
          title={
            courseDetail && courseDetail.course_name
              ? courseTitle
              : `Course Detail`
          }
        >
          {courseDetail && courseDetail.course_name
            ? courseTitle.length > 19
              ? courseTitle.substring(0, 19) + "..."
              : courseTitle
            : `Course Detail`}
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    document.title =
      courseDetail && courseDetail.course_name
        ? `${courseTitle} | LMS`
        : `Course Information | LMS`;
  });

  useEffect(() => {
    getCourseDetails();
    // eslint-disable-next-line
  }, [courseId]);

  useEffect(() => {
    getBatchDetails();
    // eslint-disable-next-line
  }, [courseDetail]);

  useEffect(() => {
    setCourseId(props.match.params.course_id);
  }, [props.match.params.course_id]);

  useEffect(() => {
    if (location?.state?.learningPath) {
      setLearningPathId(location?.state?.learningPath);
    }
  }, [location]);

  const getCourseDetails = async () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/course/${courseId}`)
      .then(async (response) => {
        if (response.data.success) {
          let data = await modifyImageTags({
            description: response?.data?.data?.description,
            targeted_audience: response?.data?.data?.targeted_audience,
            objectives: response?.data?.data?.objectives,
            prerequisites: response?.data?.data?.prerequisites,
            recommendations: response?.data?.data?.recommendations,
            topics: response?.data?.data?.topics,
          });
          setCourseDetail({ ...response.data.data, ...data });
          const imageURL = response.data.data.image
            ? response.data.data.image
            : `${process.env.PUBLIC_URL}/default.png`;
          setCourseImage(imageURL);
          setEnrolmentStatus(response?.data?.data?.user_data?.status);
          setIsFavourite(response.data.data.is_favourite);
        }
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Error while updating user role");
        } finally {
          setAlertType("danger");
        }
      });
  };

  const getBatchDetails = () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/batch/`, {
        params: { course_id: courseId, active: 1, need_schedule: true },
      })
      .then((response) => {
        if (response.data.success) {
          setSessionDetail(response.data.data.reverse());
          updateEnrollmentButton(response.data.data, courseDetail);
        }
      })
      .catch((error) => {
        try {
          // setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Error while updating user role in session");
        } finally {
          setAlertType("danger");
        }
      });
  };
  const updateEnrollmentButton = (sessionDetailObj, courseDetailObj) => {
    let buttonInfo = {};
    let sessionDetailForStatus, sessionStartDate, sessionEndDate;
    if (courseDetailObj.private) {
      setButtonClass(
        `${"il-details-primaryButton"} ${"il-details-privateButton"}`
      );
    } else {
      setButtonClass(
        `${"il-details-primaryButton"} ${"il-details-publicButton"}`
      );
    }
    if (
      courseDetailObj &&
      courseDetailObj.user_data &&
      sessionDetailObj.length
    ) {
      sessionDetailForStatus = sessionDetailObj.filter((currentSession) => {
        return currentSession.id === courseDetailObj.user_data.batch_id;
      })[0];
      if (sessionDetailForStatus) {
        sessionStartDate =
          sessionDetailForStatus &&
          TimeFormaters.FormatDate(
            sessionDetailForStatus.start_time,
            offset,
            userDateFormat
          );
        sessionEndDate =
          sessionDetailForStatus &&
          TimeFormaters.FormatDate(
            sessionDetailForStatus.end_time,
            offset,
            userDateFormat
          );
      }
    }
    buttonInfo.disabled = false;
    switch (
      courseDetailObj &&
      courseDetailObj.user_data &&
      courseDetailObj.user_data.status_id
    ) {
      case 0:
        if (courseDetail.private) {
          buttonInfo.label = "Interested";
        } else buttonInfo.label = "Enroll";
        buttonInfo.statusText = (
          <>
            <div style={{ marginTop: "5px" }}>
              <span className={"il-details-statusTextBold"}>
                {courseDetail?.current_enrolled || 0}
              </span>
              <span
                style={{
                  fontFamily: "RakutenRoundRegular",
                  marginLeft: "5px",
                  marginRight: "0.5rem",
                }}
              >
                users enrolled
              </span>
              <span className={"statusTextBold"}>
                {courseDetail?.waitlisted_users || 0}
              </span>
              <span
                style={{
                  fontFamily: "RakutenRoundRegular",
                  marginRight: "0.5rem",
                }}
              >
                {" "}
                users waitlisted{" "}
              </span>
              <span className={"statusTextBold"}>
                {courseDetail?.completed_users || 0}
              </span>
              <span style={{ fontFamily: "RakutenRoundRegular" }}>
                {" "}
                users completed the course
              </span>
            </div>
          </>
        );
        break;
      case 1:
        buttonInfo.label = "Unenroll";
        setButtonClass(
          `${"il-details-primaryButton"} ${"il-details-unenrollButton"}`
        );
        // Do not remove the commented code below. It is WIP.
        // if (courseDetail.private) {
        //  buttonInfo.disabled = true;
        // }
        try {
          if (!sessionDetailForStatus) {
            buttonInfo.statusText = "";
          } else if (!sessionDetailObj.length) {
            buttonInfo.disabled = false;
            buttonInfo.statusText = "";
          } else {
            buttonInfo.statusText = (
              <>
                You are{" "}
                <span className={"il-details-statusTextBold"}>ENROLLED</span> to{" "}
                <span className={"il-details-statusTextBold"}>
                  {sessionDetailForStatus.batch_name}
                </span>{" "}
                starts{" "}
                <span className={"il-details-statusTextBold"}>
                  {sessionStartDate}
                </span>{" "}
                and ends{" "}
                <span className={"il-details-statusTextBold"}>
                  {sessionEndDate}
                </span>
              </>
            );
          }
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
          setAlertType("danger");
        }
        break;
      case 2:
        buttonInfo.label = "Not Interested ";
        buttonInfo.statusText = (
          <>
            You are on{" "}
            <span className={"il-details-statusTextBold"}>
              WAITLIST #{courseDetailObj.user_data.waitlist_position}
            </span>
          </>
        );
        break;
      case 3:
        if (courseDetail.private) {
          buttonInfo.label = "Interested";
        } else buttonInfo.label = "Enroll";
        buttonInfo.statusText = (
          <>
            Our records indicate your attendance status is{" "}
            <span className={"il-details-statusTextBold"}>PENDING</span> for
            this course
          </>
        );
        break;
      case 4:
        if (courseDetail.private) {
          buttonInfo.label = "Interested";
        } else buttonInfo.label = "Enroll";
        buttonInfo.statusText = (
          <>
            Our records indicated you have successfully
            <span className={"il-details-statusTextBold"}> COMPLETED</span> this
            course
          </>
        );
        break;
      case 5:
        if (courseDetail.private) {
          buttonInfo.label = "Not Interested";
          buttonInfo.statusText = (
            <>
              Your
              <span className={"il-details-statusTextBold"}> INTEREST</span> for
              this course is captured
            </>
          );
        } else {
          buttonInfo.label = "Enroll";
          buttonInfo.statusText = (
            <>
              Your
              <span className={"il-details-statusTextBold"}> INTEREST</span> for
              this course is captured, you can enroll now
            </>
          );
        }
        break;
      case 6:
        buttonInfo.label = "Pending Approval";
        setButtonClass(`${"il-details-unenrollButton"}`);
        buttonInfo.disabled = true;
        try {
          const batchID = courseDetailObj.user_data.batch_id;
          const pendingEnrolledSession = sessionDetailObj.find(
            (elementInArray) => elementInArray.id === batchID
          );
          sessionStartDate = TimeFormaters.FormatDate(
            pendingEnrolledSession.start_time,
            null,
            userDateFormat
          );
          sessionEndDate = TimeFormaters.FormatDate(
            pendingEnrolledSession.end_time,
            null,
            userDateFormat
          );
          buttonInfo.statusText = (
            <div style={{ fontFamily: "RakutenRoundRegular" }}>
              Your enrollment to session{" "}
              <span className={"il-details-statusTextBold"}>
                {sessionDetailObj[0].batch_id}
              </span>{" "}
              which starts on{" "}
              <span className={"il-details-statusTextBold"}>
                {sessionStartDate}
              </span>{" "}
              and ends on{" "}
              <span className={"il-details-statusTextBold"}>
                {sessionEndDate}
              </span>{" "}
              is <span className={"il-details-statusTextBold"}>PENDING</span>{" "}
              approval.
            </div>
          );
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
          setAlertType("danger");
        }
        break;
      default:
        buttonInfo.label = "Enroll";
        break;
    }
    setButtonDetail(buttonInfo);
  };

  const changeEnrolment = (event) => {
    const action = event.target.innerText;
    // common actions related tp UNENROLL for both public and private courses
    if (action === "UNENROLL") {
      if (!courseDetail.sessions.length) {
        setAlert(
          "Unable to unenroll, Attendance is not recorded. Please contact admin"
        );
        setAlertType("danger");
        return;
      } else {
        const enrolledSessionId = courseDetail.user_data.batch_id;
        const enrolledSession = sessionDetail.filter((currentSession) => {
          return currentSession.id === enrolledSessionId;
        })[0];
        if (!enrolledSession) {
          setAlert(
            "Unable to unenroll, Attendance is not recorded. Please contact admin"
          );
          setAlertType("danger");
          return;
        }
      }
      if (
        courseDetail.course_type === "Webcast or Webinars" ||
        courseDetail.course_type === "Tech Talks" ||
        courseDetail.course_type === "Conferences"
      ) {
        submitEnrolment("unenrol", true);
        return;
      }
    }
    if (courseDetail && courseDetail.private) {
      // actions performed for private course
      if (action === "INTERESTED") {
        submitInterest(true);
      } else if (action === "NOT INTERESTED") {
        // there are 2 scenarios here:
        if (courseDetail.user_data && courseDetail.user_data.status_id === 2) {
          // 1. user is in waitlist and is not interested.
          submitEnrolment("unenrol", true);
        } else {
          // 2. user is not interested anymore after expressing interest
          submitInterest(false);
        }
      } else if (action === "UNENROLL") {
        setShowUnenrolOptions(true);
      }
    } else {
      // actions performed for public course
      if (action === "ENROLL") {
        submitEnrolment("enrol", false);
      } else if (action === "NOT INTERESTED") {
        submitEnrolment("unenrol", true);
      } else {
        // action = "UNENROLL"
        setShowUnenrolOptions(true);
      }
    }
  };

  const submitEnrolment = (type = "enrol", notInterested = false) => {
    let URL;
    if (type === "enrol") {
      URL = `/enrollment/enroll`;
    } else {
      URL = "/enrollment/unenroll";
    }
    const payload = {
      course_id: Number(courseId),
      not_interested: notInterested,
      reason: "",
      learningpath_id: learningPathId,
    };
    axios
      .post(URL, payload)
      .then((response) => {
        setAlert(response.data.message);
        setAlertType("success");
        handleUnenrolClose();
        getCourseDetails();
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleUnenrolClose();
        }
      });
  };

  const submitInterest = (interested) => {
    let method;
    if (interested) {
      method = "POST";
    } else {
      method = "DELETE";
    }
    axios({
      method: method,
      url: "/enrollment/course-interest",
      data: {
        course_id: Number(courseId),
        learningPathId: learningPathId || undefined,
      },
    })
      .then((response) => {
        setAlert(response.data.message);
        setAlertType("success");
        getCourseDetails();
        handleUnenrolClose();
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
          handleUnenrolClose();
        }
      });
  };

  const handleUnenrolClose = () => {
    setShowUnenrolOptions(false);
  };

  const modalFooterContent = (
    <div className={"modalFooterContent"}>
      * If the course session will start next 48hrs or course sessions scheduled
      on the weekends then you have to reach L&D Team for changing your enroll
      request.
    </div>
  );

  const showToggleSchedule = () => {
    if (scheduleViewLabel === "VIEW LESS") {
      setScheduleIsExpanded(false);
      setScheduleToShow(3);
      setScheduleViewLabel("VIEW MORE");
    } else {
      setScheduleIsExpanded(true);
      setScheduleToShow(sessionDetail.length);
      setScheduleViewLabel("VIEW LESS");
    }
  };

  const addFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: "IL",
          entityId: courseId,
        },
      ])
      .catch((error) => {
        setAlert(
          error?.response?.data || "Something went wrong, please try again"
        );
        setAlertType("warning");
      });
  };
  const deleteFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=IL&courseId=${courseId}`;
    await axios.delete(endPoint).catch((error) => {
      setAlert(
        error?.response?.data || "Something went wrong, please try again"
      );
      setAlertType("warning");
    });
  };
  const changeStatus = () => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, Number(courseId));
    } else {
      setIsFavourite(true);
      addFavourite(userId, Number(courseId));
    }
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Layout
        className={`${"il-details-alertContainer"} ${"il-details-innerContainer"}`}
      >
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
      </Layout>

      {Object.keys(courseDetail).length > 0 ? (
        <Layout fluid="true" className={"il-detail-firstLayout"}>
          <Layout
            className={
              courseDetail.private
                ? `${"il-details-privateContainer"}`
                : `${"il-details-publicContainer"}`
            }
            // style={{ backgroundColor: "red" }}
            fluid="true"
          >
            {
              <CourseDetailsSVGBackground
                color1={
                  courseDetail.private
                    ? userConfig?.secondary
                    : userConfig?.primary
                }
              >
                <Layout className={"il-details-innerContainer"}>
                  <Row>
                    <Breadcrumb
                      items={crumbs}
                      className="il-course-detail-breadcrumb"
                    />
                  </Row>

                  <Row className={"il-details-rowSize"}>
                    <Col sm={24} lg={13}>
                      <Row>
                        <span>
                          <img
                            src={courseIcon}
                            alt="courseIcon"
                            className={"il-details-courseIcon"}
                          />
                        </span>
                        <span className={"il-details-coursePageLabel"}>
                          Course
                        </span>
                      </Row>
                      <Row>
                        <Tooltip
                          title={courseTitle}
                          style={{ marginTop: "2rem" }}
                          placement="topLeft"
                          color="#9c9c9c"
                          overlayStyle={{
                            fontFamily: "RakutenRoundRegular",
                            maxWidth: "20rem",
                            marginBottom: "2rem",
                          }}
                        >
                          <Typography className={"il-details-courseName"}>
                            {courseTitle}
                          </Typography>
                        </Tooltip>
                      </Row>
                      <Row>
                        <Tooltip
                          placement="topLeft"
                          color="#9c9c9c"
                          overlayStyle={{
                            fontFamily: "RakutenRoundRegular",
                            maxWidth: "12rem",
                          }}
                          title={courseDetail.language}
                        >
                          <span className={"il-details-courseTimeLanguage"}>
                            {TimeFormaters.decimalHrsToHrsMin(
                              courseDetail.duration,
                              "detailed"
                            )}{" "}
                            / {courseDetail.language}
                          </span>
                        </Tooltip>
                        <span className={"il-details-courseProvider"}>
                          <span>
                            <img
                              src={courseProviderIcon}
                              alt="course provider icon"
                              className={"il-details-courseProvider-icon"}
                            />
                          </span>
                          {window.innerWidth < 786 &&
                          (courseDetail.courseProvider?.name?.length > 19 ||
                            courseDetail.course_provider?.length > 19) ? (
                            <Tooltip
                              placement="topLeft"
                              color="#9c9c9c"
                              overlayStyle={{
                                fontFamily: "RakutenRoundRegular",
                                maxWidth: "12rem",
                              }}
                              title={
                                courseDetail.courseProvider
                                  ? courseDetail.courseProvider?.name
                                  : courseDetail.course_provider
                              }
                            >
                              <span
                                className={"il-details-courseProvider-name"}
                              >
                                {courseDetail.courseProvider
                                  ? courseDetail.courseProvider?.name.substring(
                                      0,
                                      19
                                    ) + "..."
                                  : courseDetail.course_provider.substring(
                                      0,
                                      19
                                    ) + "..."}
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                courseDetail.courseProvider
                                  ? courseDetail.courseProvider?.name
                                  : courseDetail.course_provider
                              }
                            >
                              <span
                                className={"il-details-courseProvider-name"}
                              >
                                {courseDetail.courseProvider
                                  ? courseDetail.courseProvider?.name.substring(
                                      0,
                                      20
                                    ) + "..."
                                  : courseDetail.course_provider.substring(
                                      0,
                                      19
                                    ) + "..."}
                              </span>
                            </Tooltip>
                          )}
                        </span>
                      </Row>

                      <div className="il-details-enrollContainer">
                        <Row
                          className={"il-details-buttonContainer"}
                          gutter={[32]}
                        >
                          <Col
                            xs={17}
                            lg={7}
                            className={"il-detail-outlineButton"}
                          >
                            {buttonDetail.label && (
                              <PrimaryButton
                                label={buttonDetail.label}
                                otherStyle={buttonClass}
                                isDisabled={buttonDetail.disabled}
                                handleClick={(event) => changeEnrolment(event)}
                                isPrivate={courseDetail.private}
                              />
                            )}
                          </Col>
                          <Col
                            xs={4}
                            lg={14}
                            className={"il-detail-outlineButton"}
                          >
                            <PrimaryFilledOutlineButton
                              opacity={true}
                              icon={
                                !isFavourite ? (
                                  <FaRegBookmark
                                    color={userConfig?.primary}
                                    size={20}
                                    style={{ marginLeft: 8 }}
                                  />
                                ) : (
                                  <FaBookmark
                                    color={userConfig?.primary}
                                    size={20}
                                    style={{ marginLeft: 8 }}
                                  />
                                )
                              }
                              label={
                                !isFavourite ? "ADD BOOKMARK" : "BOOKMARKED"
                              }
                              handleClick={() => changeStatus()}
                              iconAsImage={false}
                            />
                          </Col>

                          <Row className={"il-details-statusContainer"}>
                            <Col>
                              <label className={"il-details-enrollStatus"}>
                                {buttonDetail.statusText}
                              </label>
                            </Col>
                          </Row>
                        </Row>
                      </div>
                    </Col>

                    <Col style={{ height: "auto" }} sm={24}>
                      <Row>
                        <img
                          className={"il-details-courseImage"}
                          src={courseImage}
                          alt="course"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Layout>
              </CourseDetailsSVGBackground>
            }
          </Layout>
          <Layout>
            <Layout className={"il-details-innerContainer"} fluid>
              <Row className={"il-details-accordion"}>
                <Col sm={24} lg={24} className={"il-details-noteText"}>
                  *Date & Time is set to automatically adjust to your PC's local
                  date & time
                </Col>
              </Row>
              <div className={"il-details-description"}>
                <Row
                  style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: isMobile ? "column-reverse" : "row",
                  }}
                >
                  <Col className={"il-details-description1"} lg={12}>
                    <Row>
                      <div className={"il-details-detailLabel"}>
                        Course Description
                      </div>
                    </Row>
                    {courseDetail.description && (
                      <Row>
                        <Col>
                          <Row className={"il-details-contentPadding"}>
                            {show ? (
                              <div className={"il-details-showMore"}>
                                <ReactHeight
                                  onHeightReady={(height) =>
                                    setHeightDiv(height)
                                  }
                                >
                                  <div
                                    style={{
                                      fontfamily: "RakutenRoundRegular",
                                    }}
                                  >
                                    {parse(courseDetail.description)}
                                  </div>
                                </ReactHeight>
                              </div>
                            ) : (
                              <div className={"il-details-showLess"}>
                                <ReactHeight
                                  onHeightReady={(height) =>
                                    setHeightDiv(height)
                                  }
                                >
                                  <div
                                    style={{
                                      fontfamily: "RakutenRoundRegular",
                                    }}
                                  >
                                    {parse(courseDetail.description)}
                                  </div>
                                </ReactHeight>
                              </div>
                            )}
                          </Row>
                          <Row>
                            {heightDiv >= 160 && (
                              <button
                                className={"il-details-descriptionButton"}
                                onClick={() => setShow(!show)}
                              >
                                {show ? (
                                  <>
                                    SHOW LESS <IoIosArrowUp size={20} />
                                    {/* <img
                                      src={chevron}
                                      className={`${"il-details-chevronIcon"} ${"il-details-iconRotate"}`}
                                      alt="SHOW LESS"
                                    /> */}
                                  </>
                                ) : (
                                  <>
                                    READ MORE <IoIosArrowDown size={20} />
                                  </>
                                )}
                              </button>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}
                    {courseDetail.training_material && (
                      <Row>
                        <a
                          href={courseDetail.training_material}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PrimaryOutlineButton label="Course Materials" />
                        </a>
                      </Row>
                    )}
                  </Col>
                  <Row style={{ height: isMobile ? "" : "0rem" }}>
                    <Col className={"il-details-accordionContent"} sm={24}>
                      <Row className={"il-details-courseImageBelowText"}>
                        <span>CLASSROOM SCHEDULE</span>
                      </Row>
                      <Row className={"il-details-courseImageBelowPanel"}>
                        <CourseDetailScheduleAccordian
                          sessionCount={scheduleToShow}
                          sessionData={sessionDetail}
                          courseData={courseDetail}
                          courseEnrolmentStatus={enrolmentStatus}
                        />
                      </Row>
                      {sessionDetail.length > 0 && sessionDetail.length > 3 && (
                        <Row className={"il-details-ssessionExpand"}>
                          <span
                            className={"il-details-scheduleToggleText"}
                            onClick={showToggleSchedule}
                          >
                            {scheduleViewLabel}
                            {scheduleIsExpanded ? (
                              <IoIosArrowUp
                                style={{ marginLeft: 8, marginTop: 4 }}
                              />
                            ) : (
                              <IoIosArrowDown
                                style={{ marginLeft: 8, marginTop: 4 }}
                              />
                            )}
                            {/* <img
                              src={chevron}
                              alt="view sessions"
                              className={
                                scheduleIsExpanded
                                  ? `${"il-details-chevronIcon"} ${"il-details-iconRotate"}`
                                  : `${"il-details-chevronIcon"}`
                              }
                            /> */}
                          </span>
                        </Row>
                      )}
                      {sessionDetail.length < 1 && (
                        <Row className={"il-details-noSessionExpand"}>
                          * There is no session currently available for this
                          course. They will appear once created by the LMS admin
                          team.
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Row>
              </div>
            </Layout>
          </Layout>

          <Layout
            className={"il-details-otherContentContainer"}
            style={{ backgroundColor: "white", marginTop: "10px" }}
            fluid
          >
            <Layout className={"il-details-innerContainer"}>
              <div className={"il-details-courseObjectives"}>
                <IlCourseContent
                  objectives={courseDetail.objectives}
                  topics={courseDetail.topics}
                  targeted_audience={courseDetail.targeted_audience}
                  prerequisites={courseDetail.prerequisites}
                  recommendations={courseDetail.recommendations}
                />
              </div>
            </Layout>
          </Layout>
          <Layout fluid className={"filler"}></Layout>
          {courseDetail.private && (
            <Layout className={"il-details-privateContentContainer"} fluid>
              {/* <CourseDetailBackground /> */}
              <Layout className={"il-details-innerContainer"}>
                <div className={"il-details-bannerContainer"}>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 1.</div>
                    <div className={"il-details-bannerDescription"}>
                      Explore and Express your interest
                    </div>
                  </div>
                  <div
                    className={"il-details-bannerPrivateHorizontalLine"}
                  ></div>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 2.</div>
                    <div className={"il-details-bannerDescription"}>
                      Get confirmation through mail
                    </div>
                  </div>
                  <div
                    className={"il-details-bannerPrivateHorizontalLine"}
                  ></div>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 3.</div>
                    <div className={"il-details-bannerDescription"}>
                      Attend the course and learn
                    </div>
                  </div>
                </div>
              </Layout>
            </Layout>
          )}
          {!courseDetail.private && (
            <Layout className={"il-details-publicContentContainer"} fluid>
              <Layout className={"il-details-innerContainer"}>
                <div className={"il-details-bannerContainer"}>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 1.</div>
                    <div className={"il-details-bannerDescription"}>
                      Explore and Enroll to the course
                    </div>
                  </div>
                  <div
                    className={"il-details-bannerPublicHorizontalLine"}
                  ></div>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 2.</div>
                    <div className={"il-details-bannerDescription"}>
                      Understand Course Objectives and Goals
                    </div>
                  </div>
                  <div
                    className={"il-details-bannerPublicHorizontalLine"}
                  ></div>
                  <div className={"il-details-bannerContent"}>
                    <div className={"il-details-bannerLabel"}>Step 3.</div>
                    <div className={"il-details-bannerDescription"}>
                      Learn and success your path
                    </div>
                  </div>
                </div>
              </Layout>
            </Layout>
          )}
          {/* </Layout> */}
        </Layout>
      ) : (
        <Layout fluid="true" className={"il-details-breadCrumbStyle"}>
          <Layout className={"il-details-innerContainer"}>
            <Row>
              <Breadcrumb items={crumbs} className="course-detail-breadcrumb" />
            </Row>
          </Layout>
        </Layout>
      )}
      {showUnenrolOptions && (
        <AdminConfirmationModal
          toggle={true}
          titleBold="Are you sure you want to?"
          show={showUnenrolOptions}
          leftButton="change to next available session"
          rightButton="Unenroll from a Course"
          handleClose={handleUnenrolClose}
          rightButtonClick={() => submitEnrolment("unenrol", true)}
          leftButtonClick={() => submitEnrolment("unenrol", false)}
          modalFooterContent={modalFooterContent}
          showOR={true}
        />
      )}
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "0.00625rem",
        }}
      >
        <Layout
          style={{
            paddingLeft: isMobile ? "0rem" : "0rem",
            paddingTop: isMobile ? "2rem" : "110px",
          }}
          bsPrefix={`container ${"container"}`}
        >
          <div className={"il-secondContainer"}>
            <Quotation />
            <pageFooter />
          </div>
          <div className={"il-details-secondContainer"}>
            {/* <pageFooter /> */}
          </div>
        </Layout>
      </div>
    </div>
  );
}

export default CourseDetail;

import { Button } from "antd";
import * as style from "./primaryButton.module.css";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";

const AntDesignPrimaryButton = ({
  text = "",
  size = "large",
  onClick,
  isDisabled,
  icon,
}) => {
  const { userConfig } = useContext(UserContext);

  return (
    <Button
      type="primary"
      icon={icon}
      size={size}
      onClick={onClick}
      disabled={isDisabled}
      className={`${style.primaryButton} ${style.width100}`}
      style={{ background: userConfig.primary }}
    >
      <span>{text}</span>
    </Button>
  );
};

export default AntDesignPrimaryButton;

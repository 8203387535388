const inProgressIcon = ({
  circleColor = "#D1D1D1",
  color = "#1F1F1F",
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        transform="matrix(0 1 1 0 0.5 0)"
        stroke={circleColor}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <mask id="path-2-inside-1_2951_1502" fill="white">
        <path d="M6.34329 1.31575C6.1777 0.802537 5.62401 0.51538 5.13477 0.742224C4.10757 1.21851 3.18964 1.90911 2.44484 2.77171C1.48655 3.88155 0.851728 5.23342 0.609716 6.67961C0.367705 8.12581 0.527817 9.6107 1.07256 10.9721C1.6173 12.3334 2.52572 13.5189 3.69857 14.3989C4.87142 15.279 6.26358 15.8197 7.72295 15.9622C9.18232 16.1046 10.6528 15.8432 11.9736 15.2065C13.2945 14.5698 14.415 13.5824 15.2127 12.352C15.8326 11.3958 16.2391 10.3214 16.4092 9.20197C16.4902 8.66883 16.0597 8.21749 15.5207 8.20198C14.9816 8.18648 14.54 8.61391 14.4381 9.14345C14.2914 9.90482 13.9992 10.634 13.5741 11.2897C12.9711 12.2197 12.1241 12.9661 11.1257 13.4474C10.1273 13.9286 9.01576 14.1262 7.91263 14.0186C6.8095 13.9109 5.75718 13.5021 4.87063 12.8369C3.98408 12.1717 3.29741 11.2756 2.88565 10.2466C2.47388 9.21752 2.35285 8.0951 2.53578 7.00193C2.71872 5.90876 3.19858 4.88689 3.92294 4.04797C4.43363 3.45651 5.05201 2.97202 5.74204 2.61839C6.22195 2.37245 6.50888 1.82896 6.34329 1.31575Z" />
      </mask>
      <path
        d="M6.34329 1.31575C6.1777 0.802537 5.62401 0.51538 5.13477 0.742224C4.10757 1.21851 3.18964 1.90911 2.44484 2.77171C1.48655 3.88155 0.851728 5.23342 0.609716 6.67961C0.367705 8.12581 0.527817 9.6107 1.07256 10.9721C1.6173 12.3334 2.52572 13.5189 3.69857 14.3989C4.87142 15.279 6.26358 15.8197 7.72295 15.9622C9.18232 16.1046 10.6528 15.8432 11.9736 15.2065C13.2945 14.5698 14.415 13.5824 15.2127 12.352C15.8326 11.3958 16.2391 10.3214 16.4092 9.20197C16.4902 8.66883 16.0597 8.21749 15.5207 8.20198C14.9816 8.18648 14.54 8.61391 14.4381 9.14345C14.2914 9.90482 13.9992 10.634 13.5741 11.2897C12.9711 12.2197 12.1241 12.9661 11.1257 13.4474C10.1273 13.9286 9.01576 14.1262 7.91263 14.0186C6.8095 13.9109 5.75718 13.5021 4.87063 12.8369C3.98408 12.1717 3.29741 11.2756 2.88565 10.2466C2.47388 9.21752 2.35285 8.0951 2.53578 7.00193C2.71872 5.90876 3.19858 4.88689 3.92294 4.04797C4.43363 3.45651 5.05201 2.97202 5.74204 2.61839C6.22195 2.37245 6.50888 1.82896 6.34329 1.31575Z"
        stroke={color}
        strokeWidth="4"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_2951_1502)"
      />
    </svg>
  );
};

export default inProgressIcon;

import { Row, Image, Typography } from "antd";
import config from "../../config";
import "./quotation.scss";
import { ReactComponent as QuoteIcon } from "../../assets/icons/quote.svg";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

export default function Quotation(props) {
  const { userConfig } = useContext(UserContext);
  const { primary } = userConfig;

  return (
    userConfig.showQuotation && (
      <div style={{ marginBottom: "120px" }}>
        <Row className="mobile-quotation">
          <QuoteIcon fill={primary} stroke={primary} />
        </Row>
        <Row
          style={{ display: "flex", justifyContent: "center" }}
          className="quotation-row"
        >
          <Typography className={"quote-title"} style={{ width: "43.75rem" }}>
            {userConfig?.quotationTitle}
          </Typography>
        </Row>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1.25rem",
          }}
          className="author-container"
        >
          <div className="quote-author">
            <Typography>
              <b>{userConfig?.quotationAuthor}</b>
            </Typography>

            <Typography>
              &nbsp;{userConfig?.quotationAuthorDesignation}
            </Typography>
          </div>
        </Row>
      </div>
    )
  );
}

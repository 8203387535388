import React from "react";
import { Col, Row } from "antd";
import "./ilCourseContent.scss";
//  import "./ilCourseContent.css";

function IlCourseContent({
  objectives,
  topics,
  targeted_audience,
  prerequisites,
  recommendations,
}) {
  return (
    <Row>
      <Col sm={24} lg={14}>
        <Row>
          <div className={"detailLabel"}>Objectives</div>
        </Row>
        <Row>
          <div
            className={"contentPadding"}
            style={{
              fontFamily: "RakutenRoundRegular",
            }}
            dangerouslySetInnerHTML={{
              __html: objectives,
            }}
          />
        </Row>
        <Row>
          <div className={"detailLabel"}>Topics</div>
        </Row>
        <Row>
          <div
            className={"contentPadding"}
            //className="topic-content"
            style={{
              fontFamily: "RakutenRoundRegular",
            }}
            dangerouslySetInnerHTML={{
              __html: topics,
            }}
          />
        </Row>
      </Col>
      <Col sm={24} lg={10}>
        <Row>
          <div className={"detailLabel"}>Targeted Audience</div>
        </Row>
        <Row>
          <div
            className={"contentPadding"}
            style={{
              fontFamily: "RakutenRoundRegular",
            }}
            dangerouslySetInnerHTML={{
              __html: targeted_audience,
            }}
          />
        </Row>
        <Row>
          <div className={"detailLabel"}>Prerequisites</div>
        </Row>
        <Row>
          <div
            className={"contentPadding"}
            dangerouslySetInnerHTML={{
              __html: prerequisites,
            }}
          />
        </Row>
        <Row>
          <div className={"detailLabel"}>Additional Resources</div>
        </Row>
        <Row>
          <div
            className={"contentPadding"}
            style={{
              fontFamily: "RakutenRoundRegular",
            }}
            dangerouslySetInnerHTML={{
              __html: recommendations,
            }}
          />
        </Row>
      </Col>
    </Row>
  );
}

export default IlCourseContent;

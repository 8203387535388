export const BACKGROUND_COLOR_IL = "#E5F5FD";
export const BACKGROUND_COLOR_EL = "#F7F7F7";
export const BACKGROUND_COLOR_PRIVATE = "#FEF4E5";

export const PRIVATE_HIGHLIGHT_COLOR = "#F59600";
export const PUBLIC_HIGHLIGHT_COLOR = "#00A0F0";

export const IL = "IL";
export const EL = "EL";

export const START_LABEL = "START";
export const UNKNOWN_LABEL = "UNKNOWN";
export const STARTED = "started";
export const STARTED_LABEL = "RESUME";

export const COMPLETED = "completed";
export const COMPLETED_LABEL = "COMPLETED";

export const WAITLIST = "waitlist";
export const WAITLIST_LABEL = "WAITLISTED";

export const PENDING = "pending";
export const PENDING_LABEL = "PENDING";

export const ENROLLED = "enrolled";
export const ENROLLED_LABEL = "ENROLLED";

export const APPROVAL_PENDING = "approvalPending";
export const APPROVAL_PENDING_LABEL = "APPROVAL PENDING";

export const ENROLL_LABEL = "ENROLL";

export const INTEREST_LABEL = "INTERESTED";
export const INTERESTED_LABEL = "INTERESTED";

export const NOT_INTERESTED_LABEL = "NOT INTERESTED";
export const INTERESTED = "interested";
export const NOT_INTERESTED = "not_interested";

export const READ_MORE = "READ MORE";
export const READ_LESS = "READ LESS";

export const ENGLISH = "ENGLISH";

export const TOPICS = "Topics";
export const RECOMMENDATIONS = "Recommendations";
export const ADDITIONAL_RESOURCES = "Additional Resources";

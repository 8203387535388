export const serializeDataResponse = (response) => {
  const {
    languages = [],
    proficiency = [],
    courseProviders = [],
  } = response.data.data;

  const serializedLanguages = languages.map((language) => {
    return {
      ...language,
      title: language.name,
    };
  });

  const serializedCourseProviders = courseProviders.map((provider) => {
    return {
      ...provider,
      value: provider?.name,
    };
  });

  return {
    ...response.data.data,
    languages: serializedLanguages,
    proficiency,
    courseProviders: serializedCourseProviders,
  };
};

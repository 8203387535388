import React, { useContext } from "react";
//import { Row } from "react-bootstrap";
import { Row } from "antd";
import { SkillTile } from "../";
import config from "../../config";
// import style from "./learningBySkills.module.css";
import "./learningBySkills.scss";
import { CourseContext } from "../../context/CourseContext";

export default function LearningBySkills({ changeSuperCatergory, isFromHome }) {
  const {
    learningBySkills: { skills, title },
  } = config;
  const isMobile = window.innerWidth < 786;
  const { parentCatagoryList } = useContext(CourseContext);
  const formattedCategoryList = parentCatagoryList.slice(1);

  return (
    <div className="learning-skills-container parent">
      <div className="learning-skills-title">{title}</div>
      <div
        justify="center"
        style={{
          // marginLeft: "0.0625rem",
          height: "23rem",
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "center",
        }}
        className="child"
      >
        {skills.map((current, index) => {
          const currentCatagory = formattedCategoryList[index];
          return (
            <div
              className="inner-child"
              key={`learningBySkills ${index}`}
              onClick={() => {
                window.scrollTo(0, 0);
                if (
                  changeSuperCatergory &&
                  typeof changeSuperCatergory === "function"
                )
                  changeSuperCatergory(current.id);
              }}
            >
              <SkillTile
                image={current.image}
                title={currentCatagory?.label}
                id={currentCatagory?.value}
                index={index}
                isFromHome={isFromHome}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

import style from "./ImageAndTagComponent.module.css";
import React, { useState, useEffect } from "react";
import edit from "./../../assets/icons/edit.svg";
import PropTypes from "prop-types";
import { Tag, Typography } from "antd";

function ImageAndTagComponent({
  imageSrc,
  imgName,
  handleClick,
  tags,
  otherStyle,
}) {
  const [tagItems, setTagItems] = useState([]);

  useEffect(() => {
    for (let index = 0; index < tags.length; index++) {
      const localTagItem = (
        <span
          className={style.tagItem}
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {tags[index]}
        </span>
      );

      setTagItems((tagItems) => [...tagItems, localTagItem]);
    }
  }, [tags, imgName]);

  return (
    <>
      <div style={otherStyle} className={style.basicStyle}>
        <span className={style.editButton}>
          <img
            name={imgName}
            onClick={handleClick}
            src={edit}
            alt=""
            className={style.editButtonImage}
          />
        </span>
        <img src={imageSrc} className={style.mainImage} alt="" />
        <Tag
          style={{
            height: tagItems.length > 0 ? "3.625rem" : "0rem",
            overflow: "hidden",
            marginTop: tagItems.length > 0 ? "0.7625rem" : "0rem",
            display: "flex",
            flexWrap: "wrap",
            border: "none",
            alignItems: "center",
            background: "none",
          }}
        >
          {tagItems}
        </Tag>
      </div>
    </>
  );
}

ImageAndTagComponent.propTypes = {
  imageSrc: PropTypes.string,
  imgName: PropTypes.string,
  handleClick: PropTypes.func,
  tags: PropTypes.array,
  otherStyle: PropTypes.object,
};

export default ImageAndTagComponent;

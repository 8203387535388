import React, { useEffect, useRef } from "react"
import { Table } from "antd"

import { getDayHoursEvents, sizeEventBox, MIN_BOX_SIZE } from "./utils"
import moment from "moment"

const BOX_POSITION_OFFSET = 26
const SCROLL_TO_ROW = 19
const TURQUOISE = "#36CFC9"
const ALL_DAY_ROW = 0

const EventBlock = ({ event, index, hour, events, onEventClick }) => {
  const getEventDay = moment(new Date(event.endTime)).day()

  const fitHourToDate = moment(new Date(hour)).day(getEventDay)

  const boxStyle = event.allDay
    ? { boxSize: MIN_BOX_SIZE, boxPosition: index * BOX_POSITION_OFFSET }
    : sizeEventBox(event, fitHourToDate)
  const boxLeftPosition = event.allDay ? 0 : BOX_POSITION_OFFSET * index

  return (
    <div
      style={{
        display:
          !event.allDay &&
            moment.duration(moment(new Date(event.endTime)).diff(fitHourToDate)).minutes() === 0
            ? "none"
            : "block",
        height: boxStyle.boxSize + "%",
        width: event.allDay ? 80 + "%" : 80 / events + "%",
        position: "absolute",
        top: boxStyle.boxPosition + "%",
        left: boxLeftPosition + "%",
        borderColor: "white",
        borderStyle: "solid",
        borderWidth: "0.01rem",
        borderRadius: "5px",
        backgroundColor: event.backgroundColor
          ? event.backgroundColor
          : TURQUOISE,
        zIndex: 1
      }}
      onClick={onEventClick ? () => onEventClick(event) : undefined}
      key={index}
    >
      <p style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>
        {event.title}
      </p>
    </div>
  )
}

function Calendar({ weekDates, getDayEvents, onEventClick, weekends }) {
  const rowRef = useRef(null)
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current?.scrollIntoView()
    }
  }, [rowRef])

  const dayList = weekends
    ? [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]
    : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

  const dayColumns = dayList.map((day, counter) => {
    const columnDate = moment(new Date(weekDates.startDate)).add({
      days: 1 + counter
    })
    const title = <p style={{ whiteSpace: 'nowrap' }}>{moment(columnDate).format('ddd')} <br></br>{moment(columnDate).format('DD')}</p>
    return {
      title: title,
      dataIndex: day,
      key: day,
      width: 2,
      render: function (events, row) {
        if (events && events.length > 0 && events instanceof Array) {
          const eventsBlock = events.map(function (event, index) {
            return (
              <EventBlock
                key={event.eventId}
                event={event}
                index={index}
                hour={row.hourObject}
                events={events.length}
                onEventClick={onEventClick}
              />
            )
          })

          return {
            props: {
              style: { position: "relative", padding: "0" }
            },
            children: <>{eventsBlock}</>
          }
        }
        return undefined
      }
    }
  })
  const hourColumn = {
    title: "",
    dataIndex: "hour",
    key: "hour",
    width: 3,
    height: '12px',
    render: (hour, { }, id) => {
      return {
        props: {
          style: { height: "80px", width: "80px", padding: '2px' }
        },
        children:
          SCROLL_TO_ROW === 1 ? (
            <div ref={rowRef}>{hour}</div>
          ) : (
            <div>{hour}</div>
          )
      }
    }
  }
  const tableColumns = [hourColumn, ...dayColumns]

  return (
    <div>
      <Table
        rowKey={record => record.id}
        dataSource={getDayHoursEvents(weekDates, getDayEvents)}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        showHeader={true}
        onRow={(_, rowIndex) => {
          if (rowIndex === ALL_DAY_ROW) {
            return {
              style: {
                backgroundColor: "white",
                boxShadow: "rgba(0, 0, 0, 0.05) -1px 4px 4px ",
                zIndex: 1,
                top: 0
              }
            }
          }
          return {}
        }}
        scroll={{
          y: 1000
        }}
      />
    </div>
  )
}

export default Calendar

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import style from "./attendanceDetailList.module.css";
import "./AttendanceDetailList.scss";
import axios from "axios";
import UtilityFunctions from "../../../utils/utility";
import { isEqual, sortBy } from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import {
  AntDesignCustomSelect,
  AntDesignTable,
  AntDesignSearchInput,
  AntDesignDownloadIconButton,
  AntDesignModal,
  AdminPageSubTitle,
} from "../../../component-library";
import { Row, Col } from "antd";
import TimeFormatter from "../../../utils/timeFormatter";
import blueTick from "../../../assets/icons/blue_tick.svg";
import moment from "moment";
import AttendanceUpdateModal from "./AttendanceUpdateModal";
import config from "../../../config";
import { CourseContext } from "../../../context/CourseContext";
import _ from "lodash";
import { Breadcrumb, Alert, Button, Input } from "antd";
import { FaCheck } from "react-icons/fa";
import { UserContext } from "../../../context/UserContext";

const totalWidth = 1320;

function AttendanceDetailList(props) {
  const { userDateFormat } = useContext(CourseContext);
  const offset = moment().utcOffset() / 60;
  const currentTime = TimeFormatter.getCurrentTimeStamp(offset);
  const [searchText, setSearchText] = useState([]);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [courseId] = useState(props.match.params.course_id);
  const [sessionId] = useState(props.match.params.batch_id);
  const [batchName, setBatchName] = useState("");
  const [listData, setListData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [attendanceColumns, setAttendanceColumns] = useState([]);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [courseData, setCourseData] = useState("");
  const [sessionTimeTag, setSessionTimeTag] = useState("");
  const [sessionTimeZoneTag, setSessionTimeZoneTag] = useState("");
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [modules, setModules] = useState({});
  const [showAttendanceUpdateModal, setShowAttendanceUpdateModal] =
    useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const { userConfig } = useContext(UserContext);

  const statusOptions = useMemo(
    () => [
      {
        value: 3,
        label: "Completed",
        id: "completed",
        style: { color: "#47B881" },
      },
      {
        value: 0,
        label: "Partial",
        id: "partial",
        style: { color: "#F59600" },
      },
      {
        value: 1,
        label: "Excused",
        id: "excused",
        style: { color: "#7D00BE" },
      },
      {
        value: 2,
        label: "No Show",
        id: "no-show",
        style: { color: "#FF0000" },
      },
    ],
    []
  );

  const attendancePercentageCalculator = (value) => {
    switch (value) {
      case 0:
        return 0.5;
      case 1:
        return 1;
      case 2:
        return 0;
      case 3:
        return 1;
      default:
        return 0;
    }
  };

  const findSelectOption = (option) => {
    return statusOptions.find((current) => {
      return current.value === option;
    });
  };

  const [initialModuleStatus, setInitialModuleStatus] = useState([]);
  const [initialAttendanceStatus, setInitialAttendanceStatus] = useState([]);
  const [showPageChangeWarningModal, setShowPageChangeWarningModal] =
    useState(false);
  const [warningIgnoreFunction, setWarningIgnoreFunction] = useState(null);
  const [sortByColumn, setSortByColumn] = useState("empId");
  const [sortOrder, setSortOrder] = useState("asc");
  const [columnCount, setColumnCount] = useState(3);
  const [searchString, setSearchString] = useState(null);

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
    },
    {
      title: (
        <a href="/Admin/Attendance" className="common-breadcrumbs-color">
          Attendance
        </a>
      ),
    },
    { title: "Session Attendance" },
  ];

  const customStyleFinalStatus = useMemo(() => {
    return {
      option: (provided, state) => {
        return {
          ...provided,
          color: state.data.color,
        };
      },
      control: (base, state) => {
        const selectedColor = state.getValue()[0]
          ? state.getValue()[0].color
          : "#ffffff";
        return {
          ...base,
          background: selectedColor,
          color: "#ffffff",
          border: "none",
        };
      },
      singleValue: (styles, { data }) => {
        return { ...styles, color: "#ffffff" };
      },
      menu: (provided, state) => ({
        ...provided,
        zIndex: 999,
      }),
    };
  }, []);

  const customOptionStyle = useMemo(() => {
    return {
      option: (provided, state) => {
        return {
          ...provided,
          color: state.data.color,
        };
      },
      singleValue: (styles, { data }) => {
        return { ...styles, color: data.color };
      },
      control: (base, state) => {
        const selectedColor = state.getValue()[0]
          ? state.getValue()[0].color
          : "#ffffff";
        return {
          ...base,
          background: "#ffffff",
          color: selectedColor,
        };
      },
      menu: (provided, state) => ({
        ...provided,
        zIndex: 999,
      }),
    };
  }, []);

  const getCompleteAttendanceData = useCallback(
    (data) => {
      const moduleStatusList = [],
        finalStatusList = [];
      (data !== undefined && data !== null ? data : listData).forEach(
        (data) => {
          finalStatusList.push({
            user_id: data.employeeId,
            status:
              statusOptions.find((el) => el.value === data.attendanceStatus)
                ?.label || "Completed",
          });
          data.schedule &&
            data.schedule.forEach((schedule) => {
              moduleStatusList.push({
                attendance_id: schedule.attendanceId,
                attendance: schedule.attendance,
              });
            });
        }
      );
      return [moduleStatusList, finalStatusList];
    },
    [listData, statusOptions]
  );

  const getAttendanceListData = useCallback(
    (signal) => {
      let listData = [];
      setShowLoader(true);
      axios
        .get(
          `${config.endPoint.instructorLedNew}/attendance/${courseId}/${sessionId}`,
          {
            params: {
              searchString: searchString?.trim(),
              pageNumber: pageNumber,
              pageLimit: pageSize,
              orderBy: sortByColumn,
              order: sortOrder,
            },
            signal: signal,
          }
        )
        .then((response) => {
          listData = response.data.data.enrolledUsers;
          setShowLoader(false);
          setListData(listData);
          setTotal(response.data.data.total);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
        .catch((error) => {
          if (error?.code !== "ERR_CANCELED") {
            try {
              setAlert(error.response.data.message);
            } catch (e) {
              setAlert("Something went wrong, Please try again.");
            } finally {
              setShowAlert(true);
              setAlertType("error");
            }
          }
        })
        .finally(() => {
          const [moduleData, attendanceData] =
            getCompleteAttendanceData(listData);
          setInitialModuleStatus(moduleData);
          setInitialAttendanceStatus(attendanceData);
        });
    },
    [
      courseId,
      pageNumber,
      pageSize,
      searchString?.trim(),
      sessionId,
      sortByColumn,
      sortOrder,
    ]
  );

  useEffect(() => {
    getSessionData();
    getAttendanceListData();
    // eslint-disable-next-line
  }, [userDateFormat]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAttendanceListData(signal);
    return () => {
      controller.abort();
    };
  }, [
    pageNumber,
    pageSize,
    sortByColumn,
    sortOrder,
    searchString?.trim(),
    getAttendanceListData,
  ]);

  const getSessionData = () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/batch`, { params: { batch_id: sessionId, need_schedule: true } })
      .then((response) => {
        setBatchName(response.data.data.batch_name);
        setCourseData(response.data.data.course);
        setSessionTimeTag(`
					${TimeFormatter.FormatTableTime(
            response.data.data.start_time,
            null,
            userDateFormat
          )}
						to 
						${TimeFormatter.FormatTableTime(
              response.data.data.end_time,
              null,
              userDateFormat
            )}`);
        setSessionTimeZoneTag(
          TimeFormatter.getTimeZoneFromOffset(response.data.data.tz_offset)
        );
        setUpdateButtonStyle(response.data.data.schedule);
        setModules(
          response.data.data.schedule.map(({ id, start_date }) => {
            return {
              id,
              start_date,
            };
          })
        );
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
        }
      });
  };
  const setUpdateButtonStyle = (moduleData) => {
    const moduleStartDate = moduleData.length && moduleData[0].start_date;

    if (moduleData.length) {
      if (moduleStartDate && currentTime >= moduleStartDate) {
        setDisableUpdate(false);
      }
    } else {
      setDisableUpdate(true);
    }
  };

  const setFinalStatusAsCompleted = () => {
    setListData((currentData) => {
      const modifiedListData = [...currentData];
      modifiedListData.forEach((data) => {
        data.attendanceStatus = 3;
      });
      return modifiedListData;
    });
  };

  const tableSearchData = ({
    dataIndex,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
        style={{
          width: "10rem",
          fontFamily: "RakutenRoundRegular",
          marginBottom: 8,
          display: "block",
        }}
      />
      <button
        type="button"
        style={{
          width: "5.5rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          setDisable(false);
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
      >
        Search
      </button>
      <button
        type="button"
        style={{
          width: "4rem",
          marginLeft: "0.2rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          setDisable(false);
          clearFilters();
          setSearchText("");
          confirm();
        }}
      >
        Reset
      </button>
    </div>
  );

  useEffect(() => {
    const columns = [
      {
        dataIndex: "employeeId",
        title: "EMP ID",
        width: totalWidth / columnCount,
        fixed: "left",
        sorter: true,
        key: "empId",
        filterDropdown: (props) =>
          tableSearchData({ ...props, dataIndex: "employeeId" }),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          return String(record.employeeId).includes(String(value));
        },
        filters: listData.map((data) => {
          return {
            text: data.employeeId,
            value: data.employeeId,
          };
        }),
      },
      {
        dataIndex: "employeeName",
        title: "NAME",
        sort: true,
        width: totalWidth / columnCount,
        fixed: "left",
        sorter: true,
        key: "empName",
        filterSearch: true,
        render: (value, record) => {
          return (
            <>
              {value}{" "}
              <span className={style.dBlock}>
                <small>{record.employeeEmail}</small>
              </span>
            </>
          );
        },
        filterDropdown: (props) =>
          tableSearchData({ ...props, dataIndex: "employeeName" }),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) => {
          return record.employeeName
            .toLowerCase()
            .includes(value.toLowerCase());
        },
      },
    ];
    if (listData.length > 0) {
      const modules = [];
      listData[0].schedule
        .sort(
          (date1, date2) =>
            new Date(date1.scheduleStart) - new Date(date2.scheduleStart)
        )
        .map((user, index) => {
          modules.push(
            TimeFormatter.FormatTableTime(
              user.scheduleStart,
              null,
              userDateFormat
            )
          );
          return columns.push({
            dataIndex: ["schedule"],
            title: `MODULE ${index + 1} - ${TimeFormatter.FormatTableTime(
              user.scheduleStart,
              null,
              userDateFormat
            )}`,
            width: totalWidth / columnCount,
            render: (cell, row) => {
              const finalSchedule = cell.find(
                (sc) => sc.scheduleId === user.scheduleId
              );
              const selectedOption = findSelectOption(finalSchedule.attendance);
              const excusedCount = cell.filter(
                (item) => item.attendance === 1
              ).length;
              const currentStatusOptions = _.cloneDeep(statusOptions);
              if (excusedCount > 0 && selectedOption.id !== "excused") {
                currentStatusOptions[2].disabled = true;
                currentStatusOptions[2].title =
                  "Only one Excused permitted in a session";
              }
              return (
                <div className="attendance-details-list">
                  <AntDesignCustomSelect
                    className={`moduleId${finalSchedule.attendanceId} moduleStatus`}
                    selected={selectedOption}
                    options={currentStatusOptions}
                    styles={customOptionStyle}
                    handleDropdown={changeAttendance}
                    dropDownData={finalSchedule.attendanceId}
                    placeholder={"Set Status to"}
                    employeeId={row.employeeId}
                    type={"Module Status"}
                    key={finalSchedule.attendanceId}
                    selectCustomStyle={`attendance-details-list-${selectedOption.id}`}
                  />
                </div>
              );
            },
          });
        });
      const totalColumns = 3 + modules.length;
      setColumnCount(totalColumns > 6 ? 6 : totalColumns);
    }
    columns.push({
      dataIndex: "attendanceStatus",
      title: "FINAL STATUS",
      width: totalWidth / columnCount,
      fixed: "right",
      render: (cell, row) => {
        const selectedOption = findSelectOption(cell);
        return (
          <>
            <AntDesignCustomSelect
              className={`finalId${row.employeeId} finalStatus`}
              selected={selectedOption}
              options={statusOptions}
              styles={customStyleFinalStatus}
              handleDropdown={changeAttendance}
              employeeId={row.employeeId}
              dropDownData={row.employeeId}
              type={"Final Status"}
              key={row.employeeId}
              placeholder={"Set Status to"}
              selectCustomStyle={`attendance-details-list-final-${selectedOption.id}`}
            />
          </>
        );
      },
    });
    setAttendanceColumns(columns);
  }, [
    listData,
    filteredInfo,
    columnCount,
    statusOptions,
    customOptionStyle,
    customStyleFinalStatus,
    userDateFormat,
  ]);

  const changeAttendance = (value, type, employeeId, attendanceId) => {
    setListData((currentData) => {
      return currentData.map((data) => {
        if (data.employeeId !== employeeId) {
          return data;
        }
        if (type === "Module Status") {
          data.schedule = data.schedule.map((schedule) => {
            if (schedule.attendanceId === attendanceId) {
              schedule.attendance = value;
            }
            return schedule;
          });
          const attendanceArray = data.schedule.map((item) => item.attendance);
          const filteredAttendanceArray = attendanceArray.filter(
            (value, index, arr) => value !== 1 || arr.indexOf(value) === index
          );
          const finalPercentage =
            filteredAttendanceArray.reduce(
              (acc, value) => acc + attendancePercentageCalculator(value),
              0
            ) / attendanceArray.length;
          if (finalPercentage >= 0.75) {
            data.attendanceStatus = 3;
          } else if (finalPercentage >= 0.2) {
            data.attendanceStatus = 0;
          } else {
            data.attendanceStatus = 2;
          }
        } else if (type === "Final Status") {
          data.attendanceStatus = value;
        }
        return data;
      });
    });
  };

  const exportAttendanceData = (row) => {
    axios
      .get(`attendance/export/${courseId}/${sessionId}`)
      .then((response) => {
        const fileName =
          courseData && courseData.identity_id
            ? `${courseData.identity_id}_${sessionId}_attendance_export.csv`
            : `attendance_export.csv`;
        UtilityFunctions.downloadCSVFile(response, fileName);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
        }
      });
  };

  const openAttendanceUpdateModal = () => {
    setShowAttendanceUpdateModal(true);
  };

  const handleSearch = (text) => {
    const [moduleStatusList, finalStatusList] = getCompleteAttendanceData();
    const statusNotChanged =
      isEqual(
        sortBy(moduleStatusList, (o) => o.attendance_id),
        sortBy(initialModuleStatus, (o) => o.attendance_id)
      ) &&
      isEqual(
        sortBy(finalStatusList, (o) => o.user_id),
        sortBy(initialAttendanceStatus, (o) => o.user_id)
      );
    if (!statusNotChanged) {
      setShowPageChangeWarningModal(true);
      setWarningIgnoreFunction((prev) => {
        return !prev ? ["searchString", text] : prev;
      });
      return;
    }
    setSearchString(text);
    setPageNumber(1);
    setFilteredInfo({});
  };

  const headerComponent = (
    <>
      <Row className={"attendanceDetail-header"}>
        <Col lg={10} md={12}>
          <div className="attendanceDetail-pageTitle">Attendance</div>
        </Col>
        <Col lg={14} md={12}>
          <Row className={"attendanceDetail-search"}>
            <Col className={style.contentEnd}>
              <div className={`${style.inlineBlock} ${style.downloadIcon}`}>
                <AntDesignDownloadIconButton
                  size={"large"}
                  onClick={exportAttendanceData}
                  isDisabled={disableUpdate || listData.length === 0}
                />
              </div>
              <div className={`${style.inlineBlock} ${style.searchInput}`}>
                <AntDesignSearchInput
                  onSearch={handleSearch}
                  value={searchString}
                />
              </div>
              <div className={`${style.inlineBlock} ${style.uploadButton}`}>
                <Button
                  type="button"
                  onClick={openAttendanceUpdateModal}
                  disabled={disableUpdate || listData.length === 0}
                  className={"attendanceDetail-fileButton"}
                >
                  UPLOAD FILE
                </Button>
              </div>
              <div
                className={`${style.inlineBlock} ${style.finalStatusButton}`}
              >
                <Button
                  type="button"
                  onClick={setFinalStatusAsCompleted}
                  className={"attendanceDetail-fileButton"}
                  disabled={disableUpdate || listData.length === 0 || disable}
                >
                  <span className={style.iconStyle}>
                    {/* <img src={blueTick} alt="icon" /> */}
                    <FaCheck
                      color={userConfig.primary}
                      style={{ marginRight: 8 }}
                    />
                  </span>
                  Set final status all to completed
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={"attendanceDetail-subtitle"}>
        <AdminPageSubTitle
          courseId={courseData && courseData.identity_id}
          courseName={courseData && courseData.course_name}
          sessionId={sessionId}
          sessionTime={sessionTimeTag}
          timeZone={sessionTimeZoneTag}
          otherStyle={"attendanceDetail-subTitle"}
        />
      </div>
    </>
  );

  const handleClose = () => setShowUpdateConfirmation(false);

  const updateAttendance = () => {
    setShowUpdateConfirmation(true);
  };

  const updateAttendanceSubmit = () => {
    const payload = {};
    payload.course_id = Number(courseId);
    payload.batch_id = Number(sessionId);

    const [moduleStatusList, finalStatusList] = getCompleteAttendanceData();

    payload.data = moduleStatusList;
    payload.status = finalStatusList;
    axios
      .put("attendance/edit", payload)
      .then((res) => {
        setAlert("Attendance updated Successfully!");
        setShowAlert(true);
        setAlertType("success");
        handleClose();
        getAttendanceListData();
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
          handleClose();
        }
      });
  };

  const onPageChange = (pageNumber) => {
    const [moduleStatusList, finalStatusList] = getCompleteAttendanceData();
    const statusNotChanged =
      isEqual(
        sortBy(moduleStatusList, (o) => o.attendance_id),
        sortBy(initialModuleStatus, (o) => o.attendance_id)
      ) &&
      isEqual(
        sortBy(finalStatusList, (o) => o.user_id),
        sortBy(initialAttendanceStatus, (o) => o.user_id)
      );
    if (!statusNotChanged) {
      setShowPageChangeWarningModal(false);
      setWarningIgnoreFunction((prev) => {
        return !prev ? ["pageNum", pageNumber || 1] : prev;
      });
      return;
    }
    setPageNumber(pageNumber || 1);
  };

  const onPageSizeChange = (_, pageSize) => {
    const [moduleStatusList, finalStatusList] = getCompleteAttendanceData();
    const statusNotChanged =
      isEqual(
        sortBy(moduleStatusList, (o) => o.attendance_id),
        sortBy(initialModuleStatus, (o) => o.attendance_id)
      ) &&
      isEqual(
        sortBy(finalStatusList, (o) => o.user_id),
        sortBy(initialAttendanceStatus, (o) => o.user_id)
      );
    if (!statusNotChanged) {
      setShowPageChangeWarningModal(true);
      setWarningIgnoreFunction((prev) => {
        return !prev ? ["pageSize", pageSize] : prev;
      });
      return;
    }
    setPageSize(pageSize);
  };

  const closeAttendanceUpdateModal = () => {
    setShowAttendanceUpdateModal(false);
  };

  const executeIgnoreWarningFunction = () => {
    const [functionName, args] = warningIgnoreFunction;
    switch (functionName) {
      case "pageNum":
        setPageNumber(args);
        break;
      case "pageSize":
        setPageSize(args);
        break;
      case "searchString":
        setSearchString(args);
        setPageNumber(1);
        setFilteredInfo({});
        break;
      default:
        break;
    }
    setWarningIgnoreFunction(null);
    closePageChangeWarningModal();
  };

  const closePageChangeWarningModal = () => {
    setShowPageChangeWarningModal(false);
    setWarningIgnoreFunction((prev) => {
      if (prev) {
        const [functionName] = warningIgnoreFunction;
        if (functionName === "searchString") {
          const originalSearchQuery = searchString;
          setSearchString((originalSearchQuery || "") + " ");
        }
      }
      return null;
    });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    if (extra?.currentDataSource.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    const { action } = extra;
    const { columnKey, order } = sorter;
    if (order) {
      setFilteredInfo({});
      setSortByColumn(columnKey);
      setSortOrder(order === "ascend" ? "asc" : "desc");
    } else {
      setFilteredInfo({});
      setSortByColumn("empId");
      setSortOrder("asc");
    }
    if (action === "paginate") {
      setFilteredInfo({});
    } else if (action === "filter") {
      setPageNumber(pagination.defaultCurrent);
      setFilteredInfo(filters);
    }
  };
  return (
    <>
      <div
        style={{
          marginTop: "5rem",
          marginLeft: "3.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            onCloseAlert={() => setAlert("")}
            className={"admin-alert"}
          />
        )}
        <Breadcrumb items={crumbs} className={"AttendanceDetail-crumbs"} />
        {headerComponent}
        {attendanceColumns.length > 0 && (
          <div className={"attendanceDetail-table"}>
            <AntDesignTable
              data={listData}
              rowKey={(record) => record.employeeId}
              loading={showLoader}
              columns={attendanceColumns}
              onChange={handleTableChange}
              pagination={{
                total: total,
                defaultCurrent: pageNumber,
                current: pageNumber,
                pageSize: pageSize,
                showQuickJumper: true,
                showSizeChanger: true,
                onChange: onPageChange,
                onShowSizeChange: onPageSizeChange,
                showTotal: (total) => `Total ${total} items`,
              }}
            />
          </div>
        )}
        <div style={{ width: "85rem" }}>
          <Col lg={23} className={style.btnContainer}>
            <div style={{ marginBottom: "2rem", marginTop: "1rem" }}>
              <Button
                type="button"
                onClick={updateAttendance}
                disabled={disableUpdate || listData.length === 0}
                className={"attendanceDetail-updateButton"}
              >
                Update
              </Button>
            </div>
          </Col>
        </div>
        {showUpdateConfirmation && (
          <AntDesignModal
            title="Are you sure you want to "
            titleBold="Update Attendance?"
            show={showUpdateConfirmation}
            okButtonText="YES"
            canceButtonText="NO"
            onCancel={handleClose}
            onOk={updateAttendanceSubmit}
            hideCancelButton={false}
          />
        )}
        {showAttendanceUpdateModal && (
          <AttendanceUpdateModal
            setAlert={setAlert}
            setAlertType={setAlertType}
            courseId={courseId}
            sessionId={sessionId}
            courseName={courseData.course_name}
            sessionName={batchName}
            modules={modules}
            showUpdateAttendanceModel={showAttendanceUpdateModal}
            modalCloseHandler={closeAttendanceUpdateModal}
            successCallback={getAttendanceListData}
          />
        )}
        {showPageChangeWarningModal && (
          <AntDesignModal
            title="There are unsaved changes. Are you sure you want to "
            titleBold="Change Page?"
            show={showPageChangeWarningModal}
            okButtonText="YES"
            canceButtonText="NO"
            onCancel={closePageChangeWarningModal}
            onOk={executeIgnoreWarningFunction}
            hideCancelButton={false}
          />
        )}
      </div>
    </>
  );
}

export default AttendanceDetailList;

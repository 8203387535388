import React from "react";

const CourseDetailsSVGBackground = (props) => {
  const { color1 = "#6C00A5", color2 = "#FFFEFF" } = props;

  const svgString = `
    <svg width="1920" height="545" viewBox="0 0 1920 545" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1920" height="545" fill="${color1}" fill-opacity="0.1"/>
      <mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="545">
        <rect width="1920" height="545" fill="${color1}"/>
      </mask>
      <g mask="url(#mask0)">
        <path d="M1031.61 -590.374L827.308 -495.107L752.753 -654.991L676.105 -619.25L615.337 67.321L28.5019 -317.268L-329 -150.562L296.917 1191.72L654.419 1025.02L274.574 210.436L855.382 583.094L917.182 -91.3063L918.042 -100.413L1301.98 722.938L1378.63 687.197L1304.07 527.313L1508.37 432.046L1582.93 591.93L1659.57 556.188L1033.7 -786L957.053 -750.258L1031.61 -590.374ZM1472.72 355.583L1268.42 450.85L1193.86 290.966L1398.16 195.699L1472.72 355.583ZM1362.46 119.142L1158.16 214.409L1083.61 54.5244L1287.91 -40.7424L1362.46 119.142ZM1252.21 -117.3L1047.91 -22.0332L973.351 -181.917L1177.65 -277.184L1252.21 -117.3ZM1141.95 -353.742L937.652 -258.475L863.096 -418.359L1067.4 -513.626L1141.95 -353.742Z" fill="${color2}" fill-opacity="0.25"/>
        <g filter="url(#filter0)">
          <circle cx="1352.5" cy="537.5" r="342.5" fill="${color1}" fill-opacity="0.4"/>
        </g>
      </g>
      <defs>
        <filter id="filter0" x="810" y="-5" width="1085" height="1085" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </svg>
  `;

  const encodedSVG = encodeURIComponent(svgString.trim())
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  const dataUri = `url("data:image/svg+xml,${encodedSVG}")`;

  return (
    <div
      style={{
        width: "100%",
        height: "34rem",
        backgroundImage: dataUri,
        backgroundSize: "cover",
      }}
    >
      {props.children}
    </div>
  );
};

export default CourseDetailsSVGBackground;

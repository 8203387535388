import { useEffect, useContext } from "react";
import "./App.css";
import AppRouter from "./auth/AppRouter";
import axios from "axios";
import { UserContextProvider } from "./context/UserContext";
import { CourseContextProvider } from "./context/CourseContext";
import { LoadingContext } from "./pages/LoadingContext";
import { LoginContextProvider } from "./context/LoginContext";
import ErrorBoundary from "./component-library/ErrorBoundary";
import { CategoryContextProvider } from "./context/CategoryContext";

function App() {
  const { setPageLoader } = useContext(LoadingContext);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        if (
          !config.url.includes("/course/list") &&
          !config.url.includes("/course")
        ) {
          setPageLoader(true);
        }
        return config;
      },
      (error) => {
        setPageLoader(false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        setPageLoader(false);
        return response;
      },
      (error) => {
        setPageLoader(false);
        return Promise.reject(error);
      }
    );
  });

  return (
    <LoginContextProvider>
      <UserContextProvider>
        <CourseContextProvider>
          <CategoryContextProvider>
            <ErrorBoundary>
              <AppRouter />
            </ErrorBoundary>
          </CategoryContextProvider>
        </CourseContextProvider>
      </UserContextProvider>
    </LoginContextProvider>
  );
}

export default App;

import { Row, Typography, Col } from "antd";
import "./learningPathAddEdit.css";

const renderLeftPane = (Children) => {
  return <div>{Children}</div>;
};

const renderRightPane = (Children) => {
  return <div>{Children}</div>;
};

const renderFooter = (Children) => {
  const [child1, child2] = Children?.props?.children || [];

  return (
    <Row span={24} style={{ width: "100%" }}>
      <Col span={12}>{child1}</Col>
      <Col span={12}>{child2}</Col>
    </Row>
  );
};

const LearningPathBodyContents = (props) => {
  const { leftpane, rightpane, footer } = props;

  return (
    <Col span={24}>
      <Row>
        <Col span={12}>{renderLeftPane(leftpane)}</Col>
        <Col span={12}>{renderRightPane(rightpane)}</Col>
      </Row>
      <Row>{renderFooter(footer)}</Row>
    </Col>
  );
};
export default LearningPathBodyContents;

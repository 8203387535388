import React, { useEffect, useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import style from "./customSelectAttendance.module.css";

const CustomSelectAttendance = (props) => {
	const {
		options,
		handleDropdown,
		selected,
		styles,
		disable,
		placeholder,
		otherStyle,
		dropDownData,
		className,
		isMulti,
		components,
		langStyle,
		type, 
		employeeId
	} = props;
	const [selectedOption, setSelectedOption] = useState(selected);

	const handleChange = (current) => {
		setSelectedOption(current);
		handleDropdown(current, type, employeeId, dropDownData);	
	};
	const classList = langStyle ? `${langStyle} ${style.innerclass}` : style.innerclass;
	useEffect(() => {
		setSelectedOption(selected);
	}, [selected]);
	return (
		<span className={`${className} ${otherStyle}`}>
			<Select
				isDisabled={disable}
				placeholder={placeholder}
				value={selectedOption}
				onChange={handleChange}
				options={options}
				styles={{ ...styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
				className={classList}
				dropDownData={dropDownData}
				isMulti={isMulti}
				components={components}
				menuPortalTarget={document.body}
			/>
		</span>
	);
};

CustomSelectAttendance.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			lable: PropTypes.element.string,
			value: PropTypes.element.string,
		})
	),
	handleDropdown: PropTypes.func,
	placeholder: PropTypes.any,
};
export default CustomSelectAttendance;

import {
  SearchOutlined,
  CloseOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import menu from "../../components/common/img/menu.png";
import growIcon from "../../assets/icons/RLMS_LOGO.png";
import "./mobileNavbar.scss";
import { Collapse, Dropdown } from "antd";
import { useContext, useState } from "react";
import UserSearchBar from "../userSearchBar/UserSearchBar";
import Bookmark from "../antDesignBookmark/Bookmark";
import { LoginContext } from "../../context/LoginContext";

const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const { Panel } = Collapse;

export const MobileNavbar = ({ categories }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [subMenu, setSubMenu] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const { logout } = useContext(LoginContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        // marginBottom: 18,
        background: "white",
      }}
    >
      <div
        style={{ margin: "1rem", marginTop: "1.5rem" }}
        onClick={() => setShowMenu(true)}
      >
        <img className="px-0" src={menu} alt="img" />
      </div>

      {showMenu && !subMenu && (
        <div
          style={{
            width: "100%",
            height: "50rem",
            background: "white",
            position: "absolute",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={() => setShowMenu(false)}
              style={{ margin: "1rem", marginTop: "1.6rem" }}
            >
              <CloseOutlined style={{ fontSize: 24 }} />
            </div>
            <div
              style={{ margin: "1rem", marginLeft: "5rem", marginTop: ".8rem" }}
            >
              <a href="/">
                <img
                  className="mobile-grow-logo"
                  src={growIcon}
                  alt="Grow Logo"
                />
              </a>
            </div>
          </div>
          <div
            className="mobile-nav"
            style={{
              position: "relative",
              zIndex: 999999,
              height: "100%",
              backgroundColor: "white",
            }}
          >
            <Collapse
              accordion
              bordered={false}
              expandIconPosition="end"
              onChange={() => {}}
            >
              <Panel
                header="Instructor-Led"
                style={{ fontFamily: "RakutenRoundRegular", fontWeight: "600" }}
                key="1"
              >
                <div>
                  <a href="/Classroom_training/all/all">
                    <label className="allCourses">ALL COURSES</label>
                  </a>
                </div>
                <div>
                  {categories.map((category) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: "1rem",
                          paddingLeft: "1.5rem",
                          paddingBottom: 0,
                        }}
                        onClick={() => {
                          setSubMenu(category);
                          setCourseType("Classroom_training");
                        }}
                      >
                        <p>{category?.parentName}</p>
                        <RightOutlined />
                      </div>
                    );
                  })}
                </div>
              </Panel>
            </Collapse>
            <Collapse
              accordion
              bordered={false}
              expandIconPosition="end"
              onChange={() => {}}
            >
              <Panel
                style={{ fontFamily: "RakutenRoundRegular", fontWeight: "600" }}
                header="E-Learning"
                key="2"
              >
                <div>
                  <a href="/e-learning-courses-list/all/all">
                    <label className="allCourses">ALL COURSES</label>
                  </a>
                </div>
                <div>
                  {categories.map((category) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: "1rem",
                          paddingLeft: "1.5rem",
                          paddingBottom: 0,
                        }}
                        onClick={() => {
                          setSubMenu(category);
                          setCourseType("e-learning-courses-list");
                        }}
                      >
                        <p>{category?.parentName}</p>
                        <RightOutlined />
                      </div>
                    );
                  })}
                </div>
              </Panel>
            </Collapse>
            <Collapse
              accordion
              bordered={false}
              expandIconPosition="end"
              onChange={() => {}}
            >
              <div>
                <a href="/LearningPath">
                  <label
                    style={{ margin: 0, padding: "1rem" }}
                    className="custom-anchor-tag"
                  >
                    Learning Path
                  </label>
                </a>
              </div>
            </Collapse>
            <Collapse
              accordion
              bordered={false}
              expandIconPosition="end"
              onChange={() => {}}
            >
              <div>
                <a href="/Calendar">
                  <label
                    style={{ margin: 0, padding: "1rem" }}
                    className="custom-anchor-tag"
                  >
                    Calendar
                  </label>
                </a>
              </div>
            </Collapse>
            <Collapse
              accordion
              bordered={false}
              expandIconPosition="end"
              onChange={() => {}}
            >
              <Panel
                header="My Account"
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontWeight: "600",
                }}
                key="2"
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <a href="/myLearnings">
                      <label
                        style={{ margin: 0, padding: "1rem" }}
                        className="custom-anchor-tag"
                      >
                        My Learnings
                      </label>
                    </a>
                  </div>
                  <div>
                    <a href="/myLearnings">
                      <label
                        style={{ margin: 0, padding: "1rem" }}
                        className="custom-anchor-tag"
                      >
                        Bookmarks
                      </label>
                    </a>
                  </div>

                  <div>
                    <a href="/Profile">
                      <label
                        style={{ margin: 0, padding: "1rem" }}
                        className="custom-anchor-tag"
                      >
                        My Profile
                      </label>
                    </a>
                  </div>

                  <div>
                    <a href="" onClick={logout}>
                      <label
                        style={{ margin: 0, padding: "1rem" }}
                        className="custom-anchor-tag"
                      >
                        Logout
                      </label>
                    </a>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      )}
      {showMenu && subMenu && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "white",
            position: "absolute",
            zIndex: 9999999,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div onClick={() => setShowMenu(false)} style={{ margin: "1rem" }}>
              <CloseOutlined style={{ fontSize: 24 }} />
            </div>
            <div style={{ margin: "1rem", marginLeft: "5rem" }}>
              <a href="/">
                <img
                  className="mobile-grow-logo"
                  src={growIcon}
                  alt="Grow Logo"
                />
              </a>
            </div>
          </div>
          <div
            style={{ padding: "1rem", background: "lightgray" }}
            onClick={() => setSubMenu(null)}
          >
            <LeftOutlined />
            <span
              className="custom-anchor-tag"
              style={{ marginLeft: "1rem", fontSize: ".9rem" }}
            >
              {subMenu?.parentName}
            </span>
          </div>
          {subMenu?.childs?.map((item) => {
            const url = `/${courseType}/${subMenu?.parentId}/${item.id}`;

            return (
              <div>
                <a href={url}>
                  <label
                    style={{ margin: "1rem", marginLeft: "2rem" }}
                    className="custom-anchor-tag"
                  >
                    {item?.name}
                  </label>
                </a>
              </div>
            );
          })}
        </div>
      )}
      <div style={{ margin: "1rem", marginTop: ".7rem" }}>
        <a href="/">
          <img className="mobile-grow-logo" src={growIcon} alt="Grow Logo" />
        </a>
      </div>
      <div
        style={{ margin: "1rem", marginTop: "1.3rem" }}
        onClick={() => {
          setShowSearch(true);
        }}
      >
        <SearchOutlined style={{ fontSize: 28 }} />
      </div>
      {showSearch && (
        <div
          style={{
            width: "100%",
            height: "50rem",
            background: "white",
            position: "absolute",
            display: "flex",
            justifyContent: "space-around",
            marginTop: "1rem",
          }}
          className="hello"
        >
          <UserSearchBar
            id="searchBar"
            // hasImage={homePageCheck ? (navbar ? false : true) : true}
          />
          <CloseOutlined
            style={{ marginTop: ".8rem", display: "block" }}
            onClick={() => setShowSearch(false)}
          />
        </div>
      )}
    </div>
  );
};

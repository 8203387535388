const reportUtility = () => {
	const embedChart = (loadPage) => {
		const script = document.createElement("script");
		script.src = "https://www.gstatic.com/charts/loader.js";
		script.async = true;

		document.body.appendChild(script);
		script.onload = () => {
			try {
				window.google.charts.load("current", { packages: ["corechart"] });
				loadPage();
			} catch (e) {
				// do nothing here
			}
		};
	};
	
	return {
		embedChart,
	};
};
export default reportUtility;
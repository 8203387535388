import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { Row, Col, Typography } from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TimeFormaters from "../../../utils/timeFormatter";
import {
  validateSessionDetails,
  ValidateStartDate,
  ValidateEndDate,
  ValidateStartTime,
  ValidateEndTime,
} from "./pastSessionAddEdit.lib.js";
import lockedIcon from "./../../../assets/icons/locked.svg";
import unlockedIcon from "./../../../assets/icons/openLock.svg";
import moment from "moment";
import UtilityFunctions from "../../../utils/utility";
import style from "./pastSessionAddEdit.module.css";
import PastSessionDetailModal from "./PastSessionDetailModal";
import { SlLockOpen, SlLock } from "react-icons/sl";

import {
  BreadCrumb,
  TopContainer,
  AdminPageTitle,
  Toggle,
  CustomSelect,
  Label,
  CustomToggle,
  Validation,
  NumberCounter,
  DateTimePicker,
  Alert,
  AdminPageSubTitle,
  PrimaryButton,
  ScrollToTop,
  FileUploader,
} from "../../../component-library";
import { CSVLink } from "react-csv";
import { CourseContext } from "../../../context/CourseContext";
import config from "../../../config";
import { serializeDataResponse } from "../../../serializer/dataSerializer";

const defaultTimezoneIndex = TimeFormaters.timeZoneList.indexOf(
  TimeFormaters.timeZoneList.filter(
    (d) => d.value === new Date().getTimezoneOffset() / -60
  )[0]
);

const tday = moment(new Date().toISOString()).format("YYYY-MM-DD");
const today = TimeFormaters.getUtcTimeStamp(tday);

export default function PastSessionAdd(props) {
  const { courseId } = props.match.params;
  const { userDateFormat } = useContext(CourseContext);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const sessionId = query.get("sessionId");

  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    {
      title: "Courses",
      to: "/Admin/Courses",
    },
    {
      title: "Manage Sessions",
      to: `/Admin/SessionList/${courseId}`,
    },
    {
      title: "Add Past Session",
    },
  ];

  let redirect;

  const [publicPrivate, setPublicPrivate] = useState(null);
  const [country, setCountry] = useState("");
  const [selectedCountiesForCourse, setSelectedCountriesForCourse] = useState(
    []
  );
  const [oldSelectedCountriesForCourse, setOldSelectedCountriesForCourse] =
    useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [timeZone, setTimeZone] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [courseIdentityId, setCourseIdentityId] = useState("");
  const [minUser, setMinUser] = useState(0);
  const [maxUser, setMaxUser] = useState(0);
  const [sessionName, setSessionName] = useState("");
  const [locked, setLocked] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [unformattedCountries, setUnformattedList] = useState([]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [enableDateTime, setEnableDateTime] = useState(null);
  const [oldStartDate, setOldStartDate] = useState(null);
  const [oldEndDate, setOldEndDate] = useState(null);
  const [attendanceFile, setAttendaceFile] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const showPastSessionDetailsPopup = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    let errors = {};

    if (!sessionId) {
      errors = validateSessionDetails(sessionObj);
    }

    if (errors) {
      setErrorMessage({ ...errorMessage, ...errors });
    }

    if (!errors || !Object.keys(errors).length) {
      setShow(true);
    }
  };

  const leftButtonClick = (e) => {
    handleClose(e);
    submitHandler(e);
  };

  const rightButtonClick = () => {
    handleClose();
  };

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    axios.get(dataEndpoint).then((response) => {
      const { countries } = serializeDataResponse(response);
      setUnformattedList(countries);
    });
  }, []);

  useEffect(() => {
    if (courseId) {
      const endPoint = `/course/${courseId}`;
      axios.get(endPoint).then((response) => {
        const {
          data: { data },
        } = response;
        const result = data;
        const countryArray = result.countries;
        if (unformattedCountries && unformattedCountries.length) {
          const formattedSelectedCountries = countryArray
            .map((current) => {
              return unformattedCountries.find((curr) => curr.code === current);
            })
            .map((curr) => {
              return { value: curr.code, label: curr.country };
            });
          const newFormattedCountries = [
            { value: "NULL", label: "All Countries of Course" },
            ...formattedSelectedCountries,
          ];
          setSelectedCountriesForCourse(() => {
            return (publicPrivate && newFormattedCountries) || [];
          });
          setOldSelectedCountriesForCourse(newFormattedCountries);
        }
        setCourseIdentityId(result.identity_id);
        setCourseTitle(result.course_name);
      });
    }
  }, [courseId, unformattedCountries, publicPrivate]);

  useEffect(() => {
    // get selected countries . in case of edit.
    if (courseId && sessionId) {
      const endPoint = `${config.endPoint.instructorLedNew}/batch/?course_id=${courseId}&batch_id=${sessionId}&need_schedule=true`;
      axios.get(endPoint).then((response) => {
        const {
          data: { data: result },
        } = response;
        setSessionName(result.batch_name);
        setMinUser(result.min_size);
        setMaxUser(result.size);
        setPublicPrivate(result.private);
        const previousTimezoneIndex = TimeFormaters.timeZoneList.indexOf(
          TimeFormaters.timeZoneList.filter(
            (d) => d.value === parseFloat(result.tz_offset)
          )[0]
        );
        setTimeZone(TimeFormaters.timeZoneList[previousTimezoneIndex]);

        setStartDate(
          TimeFormaters.HTMLDateInputFormat(result.start_time, result.tz_offset)
        );
        setOldStartDate(
          TimeFormaters.HTMLDateInputFormat(result.start_time, result.tz_offset)
        );
        setStartTime(
          TimeFormaters.FormatTime(result.start_time, result.tz_offset)
        );

        setEndDate(
          TimeFormaters.HTMLDateInputFormat(result.end_time, result.tz_offset)
        );
        setOldEndDate(
          TimeFormaters.HTMLDateInputFormat(result.end_time, result.tz_offset)
        );

        setEndTime(TimeFormaters.FormatTime(result.end_time, result.tz_offset));

        setLocked(result.is_locked);
      });
    } else {
      setStartTime("8:30 AM");
      setEndTime("5:30 PM");
      setStartDate(tday);
      setEndDate(tday);
      setTimeZone(
        TimeFormaters.timeZoneList[defaultTimezoneIndex] ||
          TimeFormaters.timeZoneList[0]
      );
    }
    // eslint-disable-next-line
  }, [courseId]);

  useEffect(() => {
    return clearTimeout(redirect);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(startDate, endDate, startTime, endTime);
    setErrorMessage({
      ...errorMessage,
      ...ValidateStartDate(startDate, endDate),
      ...ValidateEndDate(endDate, startDate),
      ...ValidateStartTime(startDate, endDate, startTime, endTime),
      ...ValidateEndTime(startDate, endDate, startTime, endTime),
    });
    // eslint-disable-next-line
  }, [startDate, endDate, startTime, endTime]);

  const submitHelper = (formData, endPoint, message) => {
    axios
      .post(endPoint, formData)
      .then((response) => {
        const filename = "bulk-upload-attendance.csv";
        UtilityFunctions.downloadCSVFile(response, filename);
        history.push({
          pathname: `/Admin/SessionList/${courseId}`,
          state: {
            completed: true,
            alertType: "success",
            alertMessage: response?.data?.message || message,
          },
        });
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message);
        setAlertType("danger");
      });
  };

  const sessionObj = {
    session_name: sessionName,
    private: publicPrivate || false,
    country: (country && country.value) || "NULL",
    language: "NA",
    start_time: TimeFormaters.getUtcTimeStamp(
      startDate,
      startTime,
      timeZone.value
    ),
    end_time: TimeFormaters.getUtcTimeStamp(endDate, endTime, timeZone.value),
    max_users: maxUser,
    min_users: minUser,
    tz_offset: timeZone.value,
    attendance_file: attendanceFile,
  };

  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    const formData = new FormData();
    if (sessionId) {
      const endPoint = `/import-past-data?course_id=${courseId}&session_id=${sessionId}`;
      const message = "File uploaded successfully";
      formData.append("attendance_file", attendanceFile);
      submitHelper(formData, endPoint, message);
    } else {
      const endPoint = `/import-past-data?course_id=${courseId}`;
      const message = "Past session created successfully.";
      for (const key in sessionObj) {
        formData.append(key, sessionObj[key]);
      }
      submitHelper(formData, endPoint, message);
    }
  };

  const toggle = (value) => {
    if (!value) {
      setSelectedCountry(selectedCountiesForCourse[0]);
      setCountry("");
    } else {
      setSelectedCountriesForCourse(() => oldSelectedCountriesForCourse);
    }
    setPublicPrivate((prev) => !prev);
  };

  const handleCountry = (country) => {
    if (country) {
      setCountry(country);
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      if (!publicPrivate) {
        const error = { country: `Please select atleast one Country.` };
        setErrorMessage({ ...errorMessage, ...error });
      }
    }
  };

  const handleSessionName = (sessionName) => {
    setSessionName(sessionName);
    if (!sessionName) {
      const error = { sessionName: `Please enter Session Name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else setSessionName(sessionName);
    if (sessionName && sessionName.length < 2) {
      const error = {
        sessionName: `Session Name should not be less than 2 characters`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { sessionName: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleMinUser = (minUser) => {
    setMinUser(minUser);
    if (!minUser) {
      const error = { minUser: `Please select the minimum users` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (minUser < 3) {
      const error = { minUser: `Number of user cannot be less than 3` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { minUser: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleMaxUser = (maxUser) => {
    setMaxUser(maxUser);
    if (!maxUser) {
      const error = { maxUser: `Please select the maximum user` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (maxUser < 3) {
      const error = { maxUser: `Number of user cannot be less than 3` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { maxUser: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleTimeZone = (timeZone) => {
    setTimeZone(timeZone);
    if (!timeZone) {
      const error = { timeZone: `Please select the Time Zone` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { timeZone: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
    if (startDate > endDate) {
      setEndDate(startDate);
    }
  };

  const handleStartTime = (value) => {
    setStartTime(value);
  };

  const handleEndDate = (endDate) => {
    setEndDate(endDate);
  };

  const handleEndTime = (endTime) => {
    setEndTime(endTime);
  };

  const oldStartDateUTC = TimeFormaters.getUtcTimeStamp(oldStartDate);
  const oldEndDateUTC = TimeFormaters.getUtcTimeStamp(oldEndDate);

  useEffect(() => {
    if (oldStartDateUTC && oldEndDateUTC) {
      if (today <= oldStartDateUTC) {
        setEnableDateTime(true);
      } else {
        setEnableDateTime(false);
      }
    }
  }, [oldStartDateUTC, oldEndDateUTC]);

  const minDateValue = "1990-01-01";
  const pastDateTimestamp = TimeFormaters.getCurrentTimeStamp() - 24 * 60 * 60;
  const maxDateValue = TimeFormaters.HTMLDateInputFormat(pastDateTimestamp);

  const modalContent = (
    <>
      <Row>
        <Col>
          <p>
            <b>Session Name :</b> {sessionName}
          </p>

          <p>
            <b>Session Start Date &amp; time :</b>{" "}
            {TimeFormaters.FormatTableTime(
              sessionObj.start_time,
              null,
              userDateFormat
            )}
          </p>
          <p>
            <b>Session End Date &amp; time :</b>{" "}
            {TimeFormaters.FormatTableTime(
              sessionObj.end_time,
              null,
              userDateFormat
            )}
          </p>
        </Col>
      </Row>
    </>
  );

  const headers = [{ label: "Email", key: "email" }];

  const data = [
    {
      email: "abc@rakuten.com",
    },
    {
      email: "xyz@rakuten.com",
    },
  ];

  const sampleCsv = {
    data: data,
    headers: headers,
    filename: "bulk_upload.csv",
  };

  const hint = (
    <div>
      Hint : Drag and Drop here to upload - &nbsp;
      <CSVLink {...sampleCsv}>Spreadsheet</CSVLink> sample
    </div>
  );

  return (
    <div className="AntdCourseAddEdit-container">
      <ScrollToTop />
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
      />

      <BreadCrumb crumbs={crumbs} />
      <Form onSubmit={submitHandler}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title className="AntdAddsession-page-title">
            {(sessionId && `Edit Session - ${sessionId}`) || "Add Session"}
          </Typography.Title>

          <Row>
            {sessionId && (
              <CustomToggle
                isImage={false}
                toggleTitle={["Open Enrollment", "Enrollment Closed"]}
                value={!locked}
                icons={[SlLockOpen, SlLock]}
                onChange={(value) => {
                  setLocked((prev) => !prev);
                }}
              />
            )}
            <Col lg={12}>
              {!sessionId && ( //for course create page
                <Toggle
                  firstLabelName="PUBLIC"
                  secondLabelName="PRIVATE"
                  selected={publicPrivate}
                  toggle={toggle}
                />
              )}
              {sessionId &&
                publicPrivate !== null && ( //for course edit page
                  <Toggle
                    firstLabelName="PUBLIC"
                    secondLabelName="PRIVATE"
                    selected={publicPrivate}
                    toggle={toggle}
                  />
                )}
            </Col>
          </Row>
        </div>
        <AdminPageSubTitle
          courseId={courseIdentityId}
          courseName={courseTitle}
        />
        <Row gutter={[24]} fluid="true" style={{ margin: 0, width: "89rem" }}>
          <Col lg={7}>
            <Label title="Session Name" required />
            <Form.Control
              className={style.textField}
              type="text"
              placeholder="Enter the Session name"
              value={sessionName}
              onChange={(e) => {
                handleSessionName(e.target.value);
              }}
            />
            {(errorMessage.sessionName && (
              <Validation message={errorMessage.sessionName} />
            )) ||
              ""}
          </Col>
          <Col lg={7} bsPrefix={`col ${style.topColumn}`}>
            <Label title="Session Country" required />
            <CustomSelect
              disable={!publicPrivate}
              selected={selectedCountry}
              options={selectedCountiesForCourse}
              placeholder="All Countries of Course"
              handleDropdown={(value) => {
                handleCountry(value);
              }}
            />
            {(errorMessage.country && (
              <Validation message={errorMessage.country} />
            )) ||
              ""}
          </Col>
          <Row style={{ width: "26.5625rem", margin: 0 }}>
            <Col
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "1.4375rem",
              }}
            >
              <Label title="Min Users" required />
              <NumberCounter
                selected={minUser}
                changeHandler={(value) => {
                  handleMinUser(value);
                }}
              />
              {(errorMessage.minUser && (
                <Validation
                  message={errorMessage.minUser}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>

            <Col
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Label title="Max Users" required />
              <NumberCounter
                selected={maxUser}
                changeHandler={(value) => {
                  handleMaxUser(value);
                }}
              />

              {(errorMessage.maxUser && (
                <Validation
                  message={errorMessage.maxUser}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>
          </Row>
        </Row>
        <Row fluid="true" gutter={[24]} style={{ margin: 0 }}>
          <Col
            lg={11}
            style={{
              maxWidth: "25rem",
              // marginRight: "0.5rem"
            }}
          >
            <Label title="Time Zone" required />
            <CustomSelect
              selected={
                timeZone
                  ? timeZone
                  : TimeFormaters.timeZoneList[defaultTimezoneIndex] ||
                    TimeFormaters.timeZoneList[0]
              }
              options={TimeFormaters.timeZoneList}
              handleDropdown={(value) => {
                handleTimeZone(value);
              }}
            />
            {(errorMessage.timeZone && (
              <Validation message={errorMessage.timeZone} />
            )) ||
              ""}
          </Col>
          <Row
            gutter={[4]}
            style={{
              width: "26.5625rem",
              margin: 0,
              marginRight: "1.5rem",
            }}
          >
            <Col
              lg={11}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                // marginRight: "1.4375rem",
              }}
            >
              <Label title="Start Date" required />
              {/* in case of add  */}
              {enableDateTime === null && (
                <DateTimePicker
                  minValue={minDateValue}
                  maxValue={maxDateValue}
                  value={startDate}
                  onChange={(current) => {
                    handleStartDate(current);
                  }}
                />
              )}
              {/* in case of edit  */}
              {enableDateTime !== null && (
                <DateTimePicker
                  minValue={minDateValue}
                  maxValue={maxDateValue}
                  value={startDate}
                  onChange={(current) => {
                    handleStartDate(current);
                  }}
                />
              )}
              {(errorMessage.startDate && (
                <Validation
                  message={errorMessage.startDate}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>
            <Col
              lg={12}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Label title="Start Time" required />
              {enableDateTime === null && (
                <DateTimePicker
                  value={startTime}
                  pickTime={true}
                  onChange={(current) => {
                    handleStartTime(current);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <DateTimePicker
                  value={startTime}
                  pickTime={true}
                  onChange={(current) => {
                    handleStartTime(current);
                  }}
                />
              )}
              {(errorMessage.startTime && (
                <Validation
                  message={errorMessage.startTime}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row gutter={[4]} style={{ width: "26.5625rem", margin: 0 }}>
            <Col
              lg={12}
              style={{
                marginLeft: "0.8rem",
                marginLeft: 0,
                width: "12.5625rem",
              }}
            >
              <Label title="End Date" required />
              {enableDateTime === null && (
                <DateTimePicker
                  minValue={startDate}
                  maxValue={maxDateValue}
                  value={endDate}
                  onChange={(endDate) => {
                    handleEndDate(endDate);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <DateTimePicker
                  minValue={startDate}
                  maxValue={maxDateValue}
                  value={endDate}
                  onChange={(endDate) => {
                    handleEndDate(endDate);
                  }}
                />
              )}
              {(errorMessage.endDate && (
                <Validation
                  message={errorMessage.endDate}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>
            <Col
              lg={12}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Label title="End Time" required />
              {enableDateTime === null && (
                <DateTimePicker
                  value={endTime}
                  pickTime={true}
                  onChange={(endTime) => {
                    handleEndTime(endTime);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <DateTimePicker
                  value={endTime}
                  pickTime={true}
                  onChange={(endTime) => {
                    handleEndTime(endTime);
                  }}
                />
              )}
              {(errorMessage.endTime && (
                <Validation
                  message={errorMessage.endTime}
                  st={{
                    marginLeft: "0.3125rem",
                    marginTop: "0.25rem",
                    marginBottom: "0",
                  }}
                />
              )) ||
                ""}
            </Col>
          </Row>
        </Row>
        <Row>
          <FileUploader
            otherStyle={{ marginLeft: "16px" }}
            fName=""
            upliftFileDetails={({ fileData, error }) => {
              if (error) {
                console.log({ error });
              }
              setAttendaceFile(fileData);
            }}
            hint={hint}
          />
        </Row>

        <Row style={{ marginTop: "4.15625rem", maxWidth: "25rem" }}>
          <Col>
            <PrimaryButton
              customStyle={style.primryButton}
              label={(sessionId && "Update") || "Submit"}
              handleClick={showPastSessionDetailsPopup}
            />
          </Col>
        </Row>
      </Form>
      {show && (
        <PastSessionDetailModal
          show={show}
          handleClose={handleClose}
          toggle={toggle}
          titleBold="Confirm"
          title="- Add Past Session Details"
          leftButton="Yes"
          rightButton="No"
          leftButtonClick={leftButtonClick}
          rightButtonClick={rightButtonClick}
          modalContent={modalContent}
        />
      )}
    </div>
  );
}

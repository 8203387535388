import React, { useCallback, useEffect } from "react";
import { notification } from "antd";

import { Capitalize } from "../../utils/stringFormatter";

export default function AntDesignNotifcation({
  alert,
  alertType,
  setAlert,
  setAlertType,
}) {
  const [api, contextHolder] = notification.useNotification();

  const notificationClosed = useCallback(() => {
    setAlert(null);
    setAlertType(null);
  }, [setAlert, setAlertType]);

  const openNotification = useCallback(() => {
    if (alert && alertType) {
      api.destroy()
      api.open({
        message: (alertType && Capitalize(alertType)) || "Info",
        description: alert,
        duration: 10,
        type: alertType,
        onClose: notificationClosed,
      });
    }
  }, [alert, alertType, api, notificationClosed]);

  useEffect(() => {
    openNotification();
  }, [openNotification]);

  return <>{contextHolder}</>;
}

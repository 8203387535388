import { useContext } from "react";
import style from "./courseDetail.module.css";
import { useHistory } from "react-router-dom";
import { CourseContext } from "../../../context/CourseContext";
import TimeFormaters from "../../../utils/timeFormatter";
function IlCourseMoreDetails({ data, name }) {
  const { userDateFormat } = useContext(CourseContext);
  const history = useHistory();

  const openSessionList = (userId, courseId, batchId) => {
    history.push(
      `/Manager/SessionList/${batchId}?userId=${userId}&name=${name}`
    );
  };

  return (
    <table>
      <thead className={style.headerColor}>
        <tr>
          <th className={`col-lg-4`}>ENROLLMENT DATE & TIME</th>
          {data?.courseStatus === "Waiting List" ? null : (
            <th className={`col-lg-5`}>SESSION INFO</th>
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div>
              {TimeFormaters.FormatTableTime(
                new Date(data?.enrollmentDate).getTime() / 1000,
                null,
                userDateFormat
              )}
            </div>
          </td>
          {data?.courseStatus === "Waiting List" ? null : (
            <td>
              <div
                className={style.sessionInfo}
                onClick={() => {
                  openSessionList(data?.userId, data?.courseId, data?.batchId);
                }}
              >
                View Session Info
              </div>
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}

export default IlCourseMoreDetails;

import React, { useEffect, useState } from "react";
import { Select, Space } from "antd";
import PropTypes from "prop-types";

export default function AntDesignCustomSelect(props) {
  const {
    options,
    handleDropdown,
    selected,
    disable,
    placeholder,
    type,
    employeeId,
    dropDownData,
    className,
    selectCustomStyle,
  } = props;
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleChange = (current) => {
    setSelectedOption(current);
    handleDropdown(current, type, employeeId, dropDownData);
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
      className={className}
    >
      <Select
        disabled={disable}
        defaultValue={selectedOption}
        value={selectedOption}
        placeholder={placeholder || "Select..."}
        onChange={handleChange}
        options={options}
        classNamePrefix={
          (props.customStyle && props.customStyle) || `innerclass`
        }
        className={selectCustomStyle}
        size={"large"}
        style={{
          width: "100%",
        }}
      />
    </Space>
  );
}

AntDesignCustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lable: PropTypes.element.string,
      value: PropTypes.element.string,
    })
  ),
  handleDropdown: PropTypes.func,
  placeholder: PropTypes.any,
  customStyle: PropTypes.string,
};

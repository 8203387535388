import React, { useState, useEffect } from "react";
import SwapUsersModal from "./SwapUsersModal";
import AdminConfirmationModal from "../adminConfirmationModal/AdminConfirmationModal";
import axios from "axios";
import config from "../../config";
function SwapUsersHelper({
  sessionPrivate,
  setAlert,
  setAlertType,
  handleCloseConfirmationModalSwap,
  handleCloseSwapMoveUser,
  setShowSwapUsersModal,
  setshowConfirmationSwapMoveModal,
  updatePage,
  setShowAlert,
  showSwapUsersModal,
  showConfirmationSwapMoveModal,
  courseId,
  batchId,
  batchSize,
  batchName,
  approvalRequired,
  setOpenSwapMoveHelper,
}) {
  const [waitlistUsersData, setWaitlistedUsers] = useState([]);
  const [enrolledUsersData, setEnrolledUsersData] = useState([]);
  const [pendingUsersData, setPendingUsersData] = useState([]);
  const [swap, setSwap] = useState(false);
  const modalTitleSwap = "Do you want to Swap or Move users in Public Session?";
  const swapButton = "SWAP";
  const moveButton = "MOVE";
  const [boldTitle, setBoldTitle] = useState("Move");
  const [waitlistBool, setWaitlistBool] = useState(false);
  const [enrolledBool, setEnrolledBool] = useState(false);
  // const [pendingBool, setPendingBool] = useState(false);
  function fetchWaitlistUsers() {
    axios
      .get(`${config.endPoint.instructorLedNew}/admin/view`, {
        params: { course_id: courseId, status_type: "waitlist" },
      })
      .then((res) => {
        setWaitlistedUsers(res.data.data);
        setWaitlistBool(true);
      })
      .catch(function (error) {
        if (error && error.response.data.message) {
          setAlert(error.response.data.message);
        } else {
          setAlert("Something went wrong, Please try again.");
        }
        setShowAlert(true)
        setAlertType("error");
      });
  }

  function fetchEnrolledUsers() {
    axios
      .get(`${config.endPoint.instructorLedNew}/admin/view`, {
        params: { session_id: batchId, status_type: "enrolled" },
      })
      .then((res) => {
        setEnrolledUsersData(res.data.data);
        setEnrolledBool(true);
      })
      .catch(function (error) {
        if (error && error.response.data.message) {
          setAlert(error.response.data.message);
        } else {
          setAlert("Something went wrong, Please try again.");
        }
        setShowAlert(true)
        setAlertType("error");
      });
  }

  function fetchPendingUsers() {
    if (approvalRequired) {
      axios
        .get(`${config.endPoint.instructorLedNew}/admin/view`, {
          params: { session_id: batchId, status_type: "approvalPending" },
        })
        .then((res) => {
          if (res.data.data !== undefined) setPendingUsersData(res.data.data);
          else setPendingUsersData([]);
          // setPendingBool(true);
        })
        .catch(function (error) {
          if (error && error.response.data.message) {
            setAlert(error.response.data.message);
          } else {
            setAlert("Something went wrong, Please try again.");
          }
          setShowAlert(true)
          setAlertType("error");
        });
    }
  }

  function initialFunction() {
    if (
      !sessionPrivate &&
      waitlistUsersData &&
      waitlistUsersData.length === 0
    ) {
      setAlert("No users in Waitlist to Swap or Move");
      setShowAlert(true)
      setAlertType("error");
      setOpenSwapMoveHelper(false);
      return;
    } else if (
      sessionPrivate &&
      enrolledUsersData &&
      enrolledUsersData.length === 0 &&
      waitlistUsersData &&
      waitlistUsersData.length === 0
    ) {
      setAlert("No users in Waitlist or Enrolled to Swap or Move");
      setShowAlert(true)
      setAlertType("error");
      setOpenSwapMoveHelper(false);
      return;
    } else {
      if (!sessionPrivate) {
        setshowConfirmationSwapMoveModal(true);
        return;
      } else {
        setShowSwapUsersModal(true);
        return;
      }
    }
  }

  useEffect(() => {
    fetchEnrolledUsers();
    fetchWaitlistUsers();
    if (approvalRequired) fetchPendingUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (waitlistBool && enrolledBool) {
      initialFunction();
    }
    // eslint-disable-next-line
  }, [waitlistBool, enrolledBool]);

  const swapButtonClick = () => {
    setSwap(true);
    setBoldTitle("Swap");
    if (enrolledUsersData.length + pendingUsersData.length === 0) {
      setAlert("No users Enrolled to Swap");
      setShowAlert(true)
      setAlertType("error");
      handleCloseConfirmationModalSwap();
      setOpenSwapMoveHelper(false);
    } else if (waitlistUsersData.length === 0) {
      setAlert("No users in Waitlist to Swap");
      setShowAlert(true)
      setAlertType("error");
      handleCloseConfirmationModalSwap();
      setOpenSwapMoveHelper(false);
    } else {
      setshowConfirmationSwapMoveModal(false);
      setShowSwapUsersModal(true);
    }
  };
  const moveButtonClick = () => {
    setSwap(false);
    setBoldTitle("Move");
    if (!sessionPrivate && waitlistUsersData.length === 0) {
      setAlert("No users in Waitlist to Move");
      setShowAlert(true)
      setAlertType("error");
      handleCloseConfirmationModalSwap();
      setOpenSwapMoveHelper(false);
    } else {
      setShowSwapUsersModal(true);
      setshowConfirmationSwapMoveModal(false);
    }
  };

  const switchModal = () => {
    if (swap) {
      setBoldTitle("Move");
      setSwap(false);
      setShowSwapUsersModal(false);
      setShowSwapUsersModal(true);
    } else {
      setBoldTitle("Swap");
      setSwap(true);
      setShowSwapUsersModal(false);
      setShowSwapUsersModal(true);
    }
  };

  return (
    <>
      {showSwapUsersModal && (
        <SwapUsersModal
          swap={swap}
          switchModal={switchModal}
          showSwapUsersModal={showSwapUsersModal}
          handleCloseSwapMoveUser={handleCloseSwapMoveUser}
          boldTitle={boldTitle}
          courseId={courseId}
          batchId={batchId}
          privateId={sessionPrivate}
          updatePage={updatePage}
          setAlert={setAlert}
          setAlertType={setAlertType}
          batchSize={batchSize}
          batchName={batchName}
          approvalRequired={approvalRequired}
          setOpenSwapMoveHelper={setOpenSwapMoveHelper}
        />
      )}

      {showConfirmationSwapMoveModal && (
        <AdminConfirmationModal
          show={showConfirmationSwapMoveModal}
          handleClose={handleCloseConfirmationModalSwap}
          toggle={false}
          title={modalTitleSwap}
          leftButton={swapButton}
          rightButton={moveButton}
          leftButtonClick={swapButtonClick}
          rightButtonClick={moveButtonClick}
        />
      )}
    </>
  );
}

export default SwapUsersHelper;

import React from "react";
import style from "./TopContainer.module.css";

import { Container } from "react-bootstrap";

export default function TopContainer(props) {
  return (
    <Container
      bsPrefix={`container ${style.container}`}
      style={{ marginTop: "0rem" }}
    >
      {props.children}
    </Container>
  );
}

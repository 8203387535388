import React from "react";
import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import "./adminConfirmationModal.scss";

function AdminConfirmationModal({
  toggle,
  title,
  titleBold,
  show,
  handleClose,
  leftButton,
  rightButton,
  leftButtonClick,
  rightButtonClick,
  modalContent,
  modalFooterContent,
  showOR = false,
}) {
  return (
    <>
      <Modal
        animation={false}
        contentClassName="modal"
        centered
        open={show}
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <div className="modal-titleBold">{title}{" "}{titleBold}</div>
        {modalContent && <div className="modalContent">{modalContent}</div>}
        <span>
          <span className="leftButton">
            <Button
              type="button"
              onClick={leftButtonClick}
              className="buttonStyle"
            >
              {leftButton}
            </Button>
          </span>
          {showOR && <span className="orLabel">OR</span>}
          <span>
            {/* && isSuperAdmin */}
            {!toggle && (
              <Button
                type="button"
                onClick={rightButtonClick}
                className="buttonStyle"
              >
                {rightButton}
              </Button>
            )}
            {toggle && (
              <Button
                type="button"
                onClick={rightButtonClick}
                className="secondaryButtonStyle"
              >
                {rightButton}
              </Button>
            )}
          </span>
        </span>
        {modalFooterContent && (
          <div style={{ marginTop: "0.7rem" }}>{modalFooterContent}</div>
        )}
      </Modal>
    </>
  );
}

AdminConfirmationModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.bool,
  title: PropTypes.string,
  titleBold: PropTypes.string,
  leftButton: PropTypes.string,
  rightButton: PropTypes.string,
  handleClose: PropTypes.func,
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
  modalFooterContent: PropTypes.any,
};

export default AdminConfirmationModal;

const validateData = (data, bannerId, resetImgFlag) => {
  const error = {};
  let flag = true;
  if (!data) {
    flag = false;
  }
  if (!data.start_date_time) {
    error.startDate = `Please select start date`;
    flag = false;
  } else {
    error.startDate = "";
  }
  if (!data.end_date_time) {
    error.endDate = `Please select end date`;
    flag = false;
  } else {
    error.endDate = "";
  }
  if (!data.priority) {
    error.priority = `Please select priority`;
    flag = false;
  } else {
    error.priority = "";
  }
  if (
    (!bannerId && !data.image_content) ||
    (bannerId && !data.image_content && resetImgFlag)
  ) {
    error.image = `Please select image`;
    flag = false;
  } else {
    error.image = "";
  }
  if (!data.redirection_url) {
    error.url = `Please enter reference url`;
    flag = false;
  } else if (data.redirection_url && !validURL(data.redirection_url)) {
    error.url = `Please enter valid url starting with http or https`;
    flag = false;
  } else {
    error.url = "";
  }

  if (
    !data?.country ||
    (data.country && !Array.isArray(data.country)) ||
    (data.country && Array.isArray(data.country) && !data.country.length)
  ) {
    error.country = "Please select at least one country from Region Selector";
    flag = false;
  } else {
    error.country = "";
  }
  return { flag: flag, error: error };
};

const validURL = (str) => {
  var pattern = new RegExp(`^(http|https):\/\/[^ "]+$`);
  return !!pattern.test(str);
};

const validateImage = (file) => {
  return new Promise((resolve, reject) => {
    let flag = false;
    if (file) {
      let img = document.createElement("img");
      img.onload = function () {
        if (this.width === 1920 || this.height === 1080) {
          flag = true;
        }
        resolve(flag);
      };
      let reader = new FileReader();
      reader.onloadend = function (ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL(file);
    }
  });
};
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("No image selected");
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
const getBase64String = (base64) => {
  return base64.split(",")[1];
};
const priorityMap = (priority) => {
  switch (priority) {
    case "HIGH":
      return { label: "High", value: "HIGH" };
    case "LOW":
      return { label: "Low", value: "LOW" };
    case "MEDIUM":
      return { label: "Medium", value: "MEDIUM" };
    default:
      return "";
  }
};
export { validateData, validateImage, getBase64, getBase64String, priorityMap };

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import style from "./courseList.scss";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { UserContext } from "../../../context/UserContext";

function CourseListRoundProgressBar({
  currentCount,
  maxCount,
  isPrivate = false,
  isWaitlist = false,
  isElearning = false,
  isAssigned = false,
  isPublicSession = false,
  clickHandler,
  otherStyle,
}) {
  const [progressPercent, setprogressPercent] = useState(0);
  const { userConfig } = useContext(UserContext);
  const { primary, secondary } = userConfig;

  useEffect(() => {
    let progress = (currentCount / maxCount) * 100;
    setprogressPercent(progress);
    if (isElearning || isAssigned) {
      setprogressPercent(100);
    }
  }, [currentCount, maxCount, isElearning, isAssigned]);

  let backgroundColor = secondary;

  if (isPrivate) {
    backgroundColor = secondary;
  }
  if (isAssigned || isPublicSession) {
    backgroundColor = primary;
  }

  return (
    <>
      {!isWaitlist ? (
        <>
          <div
            onClick={clickHandler}
            className={`${style.circleProgressBar} ${otherStyle}`}
          >
            <CircularProgressbarWithChildren
              value={progressPercent}
              styles={buildStyles({
                textColor: backgroundColor,
                pathColor: backgroundColor,
                trailColor: "#eee",
              })}
            >
              {!isElearning && !isAssigned ? (
                <div>
                  <span className={style.currentCount}>{currentCount}</span>/
                  {maxCount}
                </div>
              ) : (
                <div>{maxCount}</div>
              )}
            </CircularProgressbarWithChildren>
          </div>
        </>
      ) : (
        <>
          <div
            onClick={clickHandler}
            className={`${style.circleProgressBar} ${otherStyle}`}
          >
            <CircularProgressbarWithChildren value={progressPercent}>
              <div>{maxCount}</div>
            </CircularProgressbarWithChildren>
          </div>
        </>
      )}
    </>
  );
}

CourseListRoundProgressBar.propTypes = {
  currentCount: PropTypes.number,
  maxCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isPrivate: PropTypes.bool,
  isWaitlist: PropTypes.bool,
  clickHandler: PropTypes.func,
  otherStyle: PropTypes.any,
};

export default CourseListRoundProgressBar;

import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import styles from "./adminSearchBar.module.css";
import PropTypes from "prop-types";

function AdminSearchBar(props, { searchHandler, otherStyle }) {
	let input;
	let onSearch = () => {
		if (props.searchHandler) {
			props.searchHandler(input.value);
		} else props.onSearch(input.value);
	};
	return (
		<>
			<InputGroup style={props.otherStyle}>
				<FormControl
					className={styles.searchInput}
					type="text"
					placeholder="Search"
					onChange={e => onSearch(e)}
					ref={n => (input = n)}
				/>
				<InputGroup.Append>
					<div className={styles.searchIcon}></div>
				</InputGroup.Append>
			</InputGroup>
		</>
	);
}

AdminSearchBar.propType = {
	searchHandler: PropTypes.func,
	otherStyle: PropTypes.object,
};

export default AdminSearchBar;

import React, { useEffect, useState } from "react";

const ImageWithCancel = ({ imageUrl, className, height, styles }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const loadImage = () => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        setImageSrc(imageUrl);
      };

      img.onerror = (e) => {
        setError("Image failed to load");
      };

      signal.addEventListener("abort", () => {
        img.src = "";
      });
    };

    loadImage();

    return () => {
      controller.abort();
    };
  }, [imageUrl]);

  if (error) return <div>{error}</div>;

  return (
    imageSrc && (
      <img
        src={imageSrc}
        alt="Loaded content"
        className={className}
        height={height}
        style={styles}
        loading="lazy"
      />
    )
  );
};

export default ImageWithCancel;

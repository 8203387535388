import React, { useState, useEffect } from "react";
import style from "./FillingFastSession.module.css";
import TimeFormatter from "./../../utils/timeFormatter";
import { Link } from "react-router-dom";

function FillingFastSession({ session, userConfig }) {
  const { primary } = userConfig;
  const [currentSession, setCurrentSession] = useState([]);

  useEffect(() => {
    setCurrentSession(session);
  }, [session]);

  return (
    <Link
      className={style.itemContainer}
      to={`course-details/${session.course_id}`}
    >
      <div className={style.rightContent}>
        <div className={style.date} style={{ color: primary }}>
          {TimeFormatter.getWeekdayWithMonth(session.start_time)}
        </div>
        <div className={style.courseName}>{currentSession.course_name}</div>
        <div className={style.sessionStartTime}>
          Session starts at {TimeFormatter.getLocalTime(session.start_time)}
        </div>
      </div>
      <div className={style.circle} style={{ borderColor: primary }}>
        <div className={style.remainingSeats} style={{ color: primary }}>
          {session.size - session.counter}
        </div>
        <div className={style.seatsLeft}>Seats left</div>
      </div>
    </Link>
  );
}

export default FillingFastSession;

import { InlineIconButton } from "../../../component-library";
import TimeFormatter from "../../../utils/timeFormatter";
import acceptedImage from "../../../assets/icons/enroll.svg";
import declineImage from "../../../assets/icons/decline-enroll.svg";
import style from "./myApproval.module.css";
import { useContext } from "react";
import { CourseContext } from "../../../context/CourseContext";

export default function GetTableMetaData(actionHandler, userDateFormat) {
  const tableMeta = [
    {
      dataIndex: "userId",
      title: "EMP ID",
      width: 110,
      ellipsis: "true",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        return a?.userId?.localeCompare(b?.userId);
      },
    },
    {
      dataIndex: "userName",
      title: "NAME & EMAIL",
      width: 130,
      sorter: (a, b) => a?.userName.localeCompare(b?.userName),
      csvText: "NAME",
      sortDirections: ["ascend", "descend"],
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "role",
      title: "ROLE",
      width: 110,
      sorter: (a, b) => a?.role.localeCompare(b?.role),
      sortDirections: ["ascend", "descend"],
    },
    {
      dataIndex: "country",
      title: "COUNTRY",
      width: 110,
      sorter: (a, b) => a?.country.localeCompare(b?.country),
      sortDirections: ["ascend", "descend"],
    },
    {
      dataIndex: "courseId",
      title: "Course ID & NAME",
      width: 160,
      sorter: (a, b) => a?.courseName.localeCompare(b?.courseName),
      sortDirections: ["ascend", "descend"],

      csvText: "COURSE ID",
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.courseId}`}</strong>
            </div>
            <div>{`${row.courseName}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "batchStartTime",
      title: "SESSION START",
      width: 140,
      sorter: (a, b) => a?.batchStartTime - b?.batchStartTime,
      sortDirections: ["ascend", "descend"],

      csvFormatter: (cell, row) =>
        TimeFormatter.FormatTableTime(cell, null, userDateFormat),
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "batchEndTime",
      title: "SESSION END",
      width: 140,
      sorter: (a, b) => a?.batchEndTime - b?.batchEndTime,
      sortDirections: ["ascend", "descend"],

      csvFormatter: (cell, row) =>
        TimeFormatter.FormatTableTime(cell, null, userDateFormat),
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "enrolmentDate",
      title: "ENROLMENT DATE",
      width: 160,
      sorter: (a, b) => a?.enrolmentDate - b?.enrolmentDate,
      sortDirections: ["ascend", "descend"],
      csvFormatter: (cell, row) =>
        TimeFormatter.FormatTableTime(cell, null, userDateFormat),
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "",
      title: "ACTION",
      headerAlign: "left",
      align: "left",
      width: 150,
      csvExport: false,
      render: (cell, row) => {
        return (
          <>
            <span>
              <InlineIconButton
                iconURL={acceptedImage}
                alt="enroll button"
                clickHandler={(event) => actionHandler(row, "enroll")}
              />
            </span>
            <span>
              <InlineIconButton
                iconURL={declineImage}
                alt="decline button"
                imageStyle={style.declinedButton}
                clickHandler={(event) => actionHandler(row, "reject")}
              />
            </span>
          </>
        );
      },
    },
  ];
  return [tableMeta, tableMeta.slice(0, tableMeta.length - 1)];
}

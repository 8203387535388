import { useContext, useEffect, useState } from "react";
import { Col, Collapse, Progress, Row, Tooltip, Typography } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import startCase from "lodash/startCase";

import IN from "../../../../assets/icons/IN.svg";
import JP from "../../../../assets/icons/JP.svg";
import { ReactComponent as LockIcon } from "../../../../assets/icons/lock.svg";

import ClassroomScheduleDetails from "./ClassroomScheduleDetails";
import {
  BACKGROUND_COLOR_IL,
  BACKGROUND_COLOR_PRIVATE,
} from "../../../../constants";
import "./classroomSchedules.css";
import { getEventTime } from "../../../../utils/classroomSchedules";

import countryData from "../../../../utils/country_list.json";
import { UserContext } from "../../../../context/UserContext";
const { Panel } = Collapse;

const ClassroomSchedules = ({ course, sessionDetails: rawSessionDetails }) => {
  const [showAllSchedules, setShowAllSchedules] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(rawSessionDetails);
  const [countryList] = useState(countryData.data);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    if (showAllSchedules) {
      setSessionDetails(rawSessionDetails);
    } else {
      const updatedSessionDetails = rawSessionDetails.slice(0, 3);
      setSessionDetails(updatedSessionDetails);
    }
  }, [showAllSchedules, rawSessionDetails]);

  const getCountryImage = (countryCode = "NULL") => {
    const countryObj = countryList.countries[countryCode];
    return countryObj ? countryObj.imageURL : "";
  };

  const getCountryFlag = (country) => {
    switch (country) {
      case "IND":
        return IN;
      case "JPN":
        return JP;
      default:
        return `${process.env.PUBLIC_URL}/${getCountryImage()}`;
    }
  };

  const renderScheduleItem = (schedule) => {
    const { private: isPrivate } = schedule;
    const backgroundColor = isPrivate
      ? userConfig.secondary + "20"
      : userConfig.primary + "20";

    const renderAccordionHeader = (schedule) => {
      const {
        batch_name,
        start_time,
        end_time,
        size: totalSize,
        counter: filledSize,
      } = schedule;

      const { country } = course?.batch_data || {};
      const isSessionLocked = course?.batch_data?.is_locked;
      const countryFlag = getCountryFlag(country);
      const startTime = getEventTime(start_time);
      const endTime = getEventTime(end_time);

      return (
        <Row className="flex-center">
          <Col span={4}>
            <img
              src={countryFlag}
              alt="country_flag"
              style={{ width: "3rem" }}
            />
          </Col>
          <Col span={isSessionLocked ? 15 : 16}>
            <Row>
              <Tooltip
                placement="left"
                color="#9c9c9c"
                title={startCase(batch_name)}
                overlayStyle={{
                  fontFamily: "RakutenRoundRegular",
                  maxWidth: "12rem",
                }}
              >
                <Typography className="lp-accSessionName">
                  {startCase(batch_name)}
                </Typography>
              </Tooltip>
            </Row>
            <Row span={4}>
              <Typography className="classroom-accordion-text">
                {startTime} - {endTime}
              </Typography>
            </Row>
          </Col>
          <Col span={4} className="seats-container">
            <Row className="flex-center">
              <Typography>
                {filledSize}/{totalSize}
              </Typography>
            </Row>
            <Row style={{ marginLeft: 8 }}>
              <Progress
                percent={(filledSize / totalSize) * 100}
                showInfo={false}
              />
            </Row>
          </Col>
          {isSessionLocked && (
            <Col span={1} className="locked-session">
              <span>
                <LockIcon />
              </span>
            </Col>
          )}
        </Row>
      );
    };

    return (
      <Collapse
        accordion
        bordered={false}
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 270 : 90} />
        )}
        style={{ background: backgroundColor }}
        className="classroom-collapse"
      >
        <Panel header={renderAccordionHeader(schedule)} key="4">
          <ClassroomScheduleDetails schedule={schedule} />
        </Panel>
      </Collapse>
    );
  };

  const renderFooter = (sessionDetails = []) => {
    return (
      <div>
        {sessionDetails.length > 2 &&
          (showAllSchedules ? (
            <div className="flex-center">
              <Button
                data-testid="view-less"
                onClick={() => setShowAllSchedules(!showAllSchedules)}
                variant="ghost"
                // TODO: Remove inline css
                style={{ color: "#00A0F0", boxShadow: "none" }}
              >
                View Less
              </Button>
              <UpOutlined className="upoutlined" />
            </div>
          ) : (
            <div className="flex-center">
              <Button
                data-testid="view-more"
                onClick={() => setShowAllSchedules(!showAllSchedules)}
                variant="ghost"
                // TODO: Remove inline css
                style={{ color: "#00A0F0", boxShadow: "none" }}
              >
                View More
              </Button>
              <DownOutlined className="down-outlined" />
            </div>
          ))}
      </div>
    );
  };

  const renderTitle = () => (
    <div className="flex-center">
      <Typography className="classroom-schedule">Classroom Schedule</Typography>
    </div>
  );

  const renderClassroomSchedules = (schedules = []) =>
    schedules?.map((schedule) => renderScheduleItem(schedule));

  return (
    sessionDetails.length > 0 && (
      <div id={course?.id}>
        {renderTitle()}
        {renderClassroomSchedules(sessionDetails)}
        {renderFooter(sessionDetails)}
      </div>
    )
  );
};

export default ClassroomSchedules;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import error from "../../assets/icons/error.svg";
import success from "../../assets/icons/success.svg";
import warning from "../../assets/icons/warning_icon.svg";

import redLine from "../../assets/icons/redAlertLine.svg";
import greenLine from "../../assets/icons/greenAlertLine.svg";
import orangeLine from "../../assets/icons/orangeAlertLine.svg";
import "./style.css";

export default function Alerts({ message, type, onCloseAlert }) {
  const [show, setShow] = useState(true);
  const [msg, setMsg] = useState("");

  const line =
    (type === "success" && greenLine) ||
    (type === "warning" && orangeLine) ||
    redLine;

  const icon =
    (type === "success" && success) || (type === "warning" && warning) || error;

  useEffect(() => {
    setShow(true);
    setMsg((Array.isArray(message) && message.join("")) || message);
    let timer;
    if (type === "success") {
      timer = setTimeout(() => {
        // After 5 seconds set the show value to false
        setShow(false);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [message, type]);

  return (
    (show && message && (
      <Alert
        variant={type}
        dismissible
        onClose={() => {
          setShow(false);
          onCloseAlert();
        }}
      >
        <span>
          <img src={line} alt="" />
        </span>
        <span style={{ marginRight: "0.5rem", marginLeft: "0.5rem" }}>
          <img src={icon} alt="" />
        </span>
        {msg}
      </Alert>
    )) ||
    ""
  );
}

Alerts.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  onCloseAlert: PropTypes.func,
};

import React from "react";
import * as style from "./antDesignTable.scss";
import { Table } from "antd";
import toggleIcon from "./../../assets/icons/toggle_down_icon.svg";
import toggleBlueIcon from "./../../assets/icons/toggle_down_icon_blue.svg";

const AntDesignTable = ({
  data,
  columns,
  rowKey,
  rowClassName,
  onChange,
  pagination,
  horizontalScroll,
  handleSelection,
  isExpandable,
  loading,
  expandedRowContent,
  fullWidth = true,
}) => {
  return (
    <Table
      className={"attendanceDetail-table"}
      loading={loading}
      rowKey={rowKey}
      rowClassName={rowClassName}
      columns={isExpandable ? [...columns, Table.EXPAND_COLUMN] : columns}
      expandRowByClick={true}
      dataSource={data}
      onChange={onChange}
      rowSelection={handleSelection}
      pagination={
        pagination ? { ...pagination, position: ["bottomCenter"] } : false
      }
      scroll={
        fullWidth || horizontalScroll
          ? {
              x: horizontalScroll ? 1500 : 1320,
            }
          : {}
      }
      expandable={
        isExpandable
          ? {
              expandIcon: ({ expanded, onExpand, record }) => (
                <img
                  className={style.cursorPointer}
                  style={expanded ? { transform: "rotate(180deg)" } : null}
                  src={expanded ? toggleBlueIcon : toggleIcon}
                  alt={expanded ? "close" : "open"}
                  onClick={(e) => onExpand(record, e)}
                />
              ),

              expandedRowRender: expandedRowContent,
              rowExpandable: (record) => record.name !== "Not Expandable",
            }
          : {}
      }
    />
  );
};

export default AntDesignTable;

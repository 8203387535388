export const serializeParentCategory = (response) => {
  const serializedResponse = response.data.data.map((category) => {
    return {
      ...category,
      id: category.id,
      parent_category_acronym: category.parentCategoryAcronym,
      parent_category_name: category.parentCategoryName,
    };
  });

  return serializedResponse;
};

import React, { useContext } from "react";
import { Tabs } from "antd";
import { ReactComponent as Tick } from "../../assets/icons/blue_tick.svg";
import "./CourseFilter.scss";
import { UserContext } from "../../context/UserContext";
import { FaCheck } from "react-icons/fa";

function CourseFilter({ filterItems, filter, handleFilter }) {
  const { userConfig } = useContext(UserContext);
  const { primary } = userConfig;

  return (
    <Tabs
      tabPosition="none"
      size="small"
      items={filterItems.map((item, i) => {
        return {
          label: (
            <div
              onClick={() => handleFilter(item.value)}
              key={`filter-${item.value}`}
            >
              <div className={"filterItem"}>
                {item.value === filter && (
                  <span className={"blueTick"}>
                    <FaCheck color={userConfig.primary} />
                  </span>
                )}
                <span className={`${item.value === filter && "blue"}`}>
                  {item.label}
                </span>
              </div>
            </div>
          ),
          key: i,
        };
      })}
    />
  );
}

export default CourseFilter;

import React from "react";
import { Button, Modal, Typography } from "antd";
import PropTypes from "prop-types";
import "./SessionAddEdit.scss";

function SessionAddEditConfirmationModal({
  toggle,
  title,
  titleBold,
  show,
  handleClose,
  leftButton,
  rightButton,
  leftButtonClick,
  rightButtonClick,
  modalContent,
  modalFooterContent,
  showOR = false,
}) {
  return (
    <>
      <Modal
        title={title + titleBold}
        animation={false}
        contentClassName={"modal"}
        centered
        open={show}
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        {modalContent && <div className={"modalContent"}>{modalContent}</div>}
        <span>
          <span className={"leftButton"}>
            <Button
              type="button"
              onClick={leftButtonClick}
              className={"sessionAddEdit-buttonStyle"}
            >
              {leftButton}
            </Button>
          </span>
          {showOR && <span className={"orLabel"}>OR</span>}
          <span>
            {/* && isSuperAdmin */}
            {!toggle && (
              <Button
                type="button"
                onClick={rightButtonClick}
                className={"sessionAddEdit-rightButtonStyle"}
              >
                {rightButton}
              </Button>
            )}
            {toggle && (
              <Button
                type="button"
                onClick={rightButtonClick}
                className={"sessionAddEdit-rightButtonStyle"}
              >
                {rightButton}
              </Button>
            )}
          </span>
        </span>
        {modalFooterContent && (
          <div style={{ marginTop: "0.7rem" }}>{modalFooterContent}</div>
        )}
      </Modal>
    </>
  );
}

SessionAddEditConfirmationModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.bool,
  title: PropTypes.string,
  titleBold: PropTypes.string,
  leftButton: PropTypes.string,
  rightButton: PropTypes.string,
  handleClose: PropTypes.func,
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
  modalFooterContent: PropTypes.any,
};

export default SessionAddEditConfirmationModal;

import React, { useState } from "react";
import style from "./ImageComponent.module.css";
import whiteTick from "./../../assets/icons/white_tick.svg";
import { Button, Image } from "react-bootstrap";
import PropTypes from "prop-types";

function ImageComponent({
    source,
    handleClick,
    imgName
}) {
    const [isHovered, setHover] = useState(false);

    return (
        <div className={style.imageControl}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <>
                <Image
                    className={style.thumbnail}
                    src={source}
                />
                {isHovered && (
                    <>
                        <img
                            className={style.whiteTick}
                            src={whiteTick}
                            alt="tick icon"
                        />
                        <Button
                            className={style.selectImageButton}
                            variant="primary"
                            onClick={handleClick}
                            name={imgName}
                        >
                            ADD
                        </Button>
                        <div className={style.grayShade}></div>
                    </>
                )}
            </>

        </div>
    );
}

ImageComponent.propTypes = {
    source: PropTypes.string,
    handleClick: PropTypes.func,
    imgName: PropTypes.string
};

export default ImageComponent;
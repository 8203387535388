import PropTypes from "prop-types";
import { useState, useRef, useEffect, useCallback } from "react";
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import addCalendar from "./../../assets/icons/add_calender_invite.svg";
import noCalendar from "./../../assets/icons/no_calender_invite.svg";
import sendIcon from "./../../assets/icons/send_email.svg";
import {
  CustomToggle,
  Label,
  PrimaryButton,
  TextEditor,
  Validation,
} from "./../../component-library";
import "./emailActionModal.css";
import style from "./emailActionModal.module.css";
import "./emailActionModal.css";
import AdminPrimaryButton from "../adminPrimaryButton/AdminPrimaryButton";

function EmailActionModal(props) {
  const {
    to,
    cc,
    bcc,
    subject,
    note,
    content,
    calendarInviteAdded,
    showEmailModal,
    sendEmailHandler,
    modalCloseHandler,
    ccHandler,
    bccHandler,
    calendarInviteAddedHandler,
    subjectHandler,
    contentHandler,
    showCalendar,
    setTo,
    validateDlm,
  } = props;
  const [errorMessage, setErrorMessage] = useState({});
  const textEditorRef = useRef(null);

  const isValidEmailAddress = useCallback((email) => {
    let flag = true;
    if (!email) {
      return flag;
    } else {
      if (!Array.isArray(email)) {
        return email?.trim()?.match(/^[^@]+@[^@]+\.[^@]{2,4}$/);
      } else {
        email.map((e) => (!isValidEmailAddress(e) ? (flag = false) : ""));
        return flag;
      }
    }
  }, []);

  const isValidContent = useCallback((text) => {
    const content = text.replace(/<[^>]*>?/gm, "");
    return content === "" ? false : true;
  }, []);

  const validateEmailDetails = useCallback(() => {
    const errors = {};
    if (!to) {
      errors.to = `Receivers email ids can not be empty`;
    } else if (validateDlm) {
      const toFields = to?.split(",");

      toFields.forEach((toField) => {
        if (!toField.includes("@mail.rakuten.com") && toField) {
          errors.to = `Please enter a valid DLM`;
        }
      });
    }

    if (!isValidEmailAddress(cc)) {
      errors.cc = `Please provide valid email address`;
    }
    if (!isValidEmailAddress(bcc)) {
      errors.bcc = `Please provide valid email address`;
    }
    if (!subject) {
      errors.subject = `Please provide subject`;
    }
    if (!isValidContent(content)) {
      errors.content = `Please provide content`;
    }
    return errors;
  }, [to, cc, bcc, subject, content, isValidEmailAddress, isValidContent]);

  useEffect(() => {
    const error = validateEmailDetails();
    setErrorMessage(error);
  }, [validateEmailDetails]);

  const ccChangeHandler = (text) => {
    const emailArr = text.split(",");
    ccHandler(emailArr);
  };

  const bccChangeHandler = (text) => {
    const emailArr = text.split(",");
    bccHandler(emailArr);
  };

  const subjectChangeHandler = (text) => {
    subjectHandler(text);
    if (!text) {
      const error = { subject: `Please provide subject` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subject: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const contentChangeHandler = (text) => {
    contentHandler(text);
    if (!isValidContent(text)) {
      const error = { content: `Please provide content` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { content: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const sendEmail = async () => {
    const errors = validateEmailDetails();
    await textEditorRef.current.deleteOrphanedImages();
    if (Object.keys(errors).length > 0) {
      setErrorMessage({ ...errorMessage, ...errors });
    } else {
      sendEmailHandler();
    }
  };

  return (
    <>
      <Modal
        animation={false}
        centered
        show={showEmailModal}
        onHide={modalCloseHandler}
        contentClassName={style.modal}
      >
        <Row>
          <Col>
            <Modal.Header closeButton>
              <Col lg={3}>
                <Modal.Title className={style.mailToUserTitle}>
                  Mail to user
                </Modal.Title>
              </Col>
              {showCalendar && (
                <Col lg={{ offset: 5 }}>
                  <CustomToggle
                    toggleTitle={[
                      "Calendar invite added",
                      "Click to add calendar invite",
                    ]}
                    value={calendarInviteAdded}
                    customToggleCss={style.custom_toggle}
                    iconBoxCss={style.icon_box}
                    toggleTitleCss={style.toggle_title}
                    icons={[addCalendar, noCalendar]}
                    onChange={(value) => {
                      calendarInviteAddedHandler(value);
                    }}
                  />
                </Col>
              )}
            </Modal.Header>
          </Col>
        </Row>
        <Modal.Body>
          <Row>
            <Col style={{ marginLeft: "1rem" }}>
              <Label title="To" required />
              <FormControl
                type="text"
                placeholder="receivers email ids"
                value={to}
                onChange={(e) => setTo(e.target.value.toLowerCase())}
                className={style.textFields}
              />
              {(errorMessage.to && (
                <Validation
                  message={errorMessage.to}
                  style={{ marginTop: " 0.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginLeft: "1rem" }}>
              <Label title="CC" />
              <FormControl
                type="text"
                placeholder="CC email ids"
                value={cc}
                onChange={(e) => {
                  ccChangeHandler(e.target.value);
                }}
                className={style.textFields}
              />
              {(errorMessage.cc && (
                <Validation
                  message={errorMessage.cc}
                  style={{ marginTop: " 0.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginLeft: "1rem" }}>
              <Label title="BCC" />
              <FormControl
                type="text"
                placeholder="BCC email ids"
                value={bcc}
                onChange={(e) => {
                  bccChangeHandler(e.target.value);
                }}
                className={style.textFields}
              />
              {(errorMessage.bcc && (
                <Validation
                  message={errorMessage.bcc}
                  style={{ marginTop: " 0.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginLeft: "1rem" }}>
              <Label title="Subject" required />
              <FormControl
                type="text"
                placeholder="subject"
                value={subject}
                onChange={(e) => {
                  subjectChangeHandler(e.target.value);
                }}
                className={style.textFields}
              />
              {(errorMessage.subject && (
                <Validation
                  message={errorMessage.subject}
                  style={{ marginTop: " 0.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginLeft: "1rem" }}>
              <div
                dangerouslySetInnerHTML={{ __html: note }}
                className={style.note}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col style={{ marginLeft: "1rem", marginBottom: "1.875rem" }}>
              <Label title="Content" required />
              <TextEditor
                customClass={style.quillEmail}
                value={content}
                ref={textEditorRef}
                getText={(text) => {
                  contentChangeHandler(text);
                }}
              />
              {(errorMessage.content && (
                <Validation
                  message={errorMessage.content}
                  style={{ marginTop: " 3.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col>
              <Col lg={{ offset: 7 }}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    modalCloseHandler();
                    setTo("");
                  }}
                  className={style.cancelButton}
                >
                  CANCEL
                </Button>
              </Col>
            </Col>
            <Col>
              <AdminPrimaryButton
                customStyle={style.submitButton}
                icon={sendIcon}
                label="SEND"
                handleClick={() => {
                  sendEmail();
                  setTo("");
                }}
                isDisabled={
                  calendarInviteAdded && note === "No modules available"
                    ? true
                    : false
                }
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

EmailActionModal.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bcc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subject: PropTypes.string,
  note: PropTypes.string,
  content: PropTypes.string,
  calendarInviteAdded: PropTypes.bool,
  showEmailModal: PropTypes.bool,
  sendEmailHandler: PropTypes.func,
  modalCloseHandler: PropTypes.func,
  ccHandler: PropTypes.func,
  calendarInviteAddedHandler: PropTypes.func,
  subjectHandler: PropTypes.func,
  contentHandler: PropTypes.func,
};

export default EmailActionModal;

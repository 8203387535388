import React, { useState, useEffect, useContext } from "react";
import "./categoryTile.scss";
import { ReactComponent as Trash } from "../../assets/icons/delete_outline.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import AdminConfirmationModal from "../adminConfirmationModal/AdminConfirmationModal";
import AntdEditCategoryModal from "../../pages/admin/categoryManagement/editCategoryModal/AntdEditCategoryModal";
import AntdEditSubCategoryModal from "../../pages/admin/categoryManagement/editSubCategoryModal/AntdEditSubCategoryModal";
import axios from "axios";
import PropTypes from "prop-types";
import { UserContext } from "../../context/UserContext";
import { Button, Tag, Tooltip } from "antd";
import truncate from "lodash/truncate";
import config from "../../config";

function CategoryTile({
  database,
  parentCategory,
  dataElement,
  idx,
  changeDataBase,
  setAlertType,
  setAlert,
  fetchData,
}) {
  const [data, setData] = useState(dataElement);
  const [dataId] = useState(dataElement.id);
  const [showModal, setShowModal] = useState(false);
  const [category] = useState(dataElement.name);
  const [parentId, setParentId] = useState(null);
  const [subcategories, setSubcategories] = useState(
    data.subCategories.map((category) => category.name)
  );
  const [parentCategoryTile] = useState(data.parent);
  const [modalTitle] = useState();
  const [modalTitleBold, setModalTitleBold] = useState(null);
  const [leftButton, setLeftButton] = useState(null);
  const [rightButton, setRightButton] = useState(null);
  const [toggleEditDelete, setToggleEditDelete] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showCategory, setShowCategory] = useState(null);
  const handleCloseCategory = () => setShowCategory(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const handleCloseSubCategory = () => setShowSubCategory(false);
  const [catAcronym, setCatAcronym] = useState(null);
  const [toggle, setToggle] = useState(false);
  const { hasRole } = useContext(UserContext);
  const isSuperAdmin = hasRole(["superAdmin"]);
  async function fetchCategoryData() {
    const result = await axios.get(
      `${config.endPoint.commonService}/category/${data.id}`
    );
    const { data: responseData } = result || {};
    const { data: response } = responseData || {};
    const { acronym, parentId: pId } = response || {};
    setParentId(pId);
    setCatAcronym(acronym);
  }
  useEffect(() => {
    fetchCategoryData();
    // ToDo fix eslint issue
  }, []);

  const editButton = () => {
    if (isSuperAdmin) {
      setShow(true);
      setModalTitleBold("Do You Want To Edit ? ");
      setToggleEditDelete(1);
      setRightButton("SUB CATEGORY");
      setLeftButton("CATEGORY");
      setToggle(false);
    } else {
      setShowSubCategory(true);
    }
  };

  const deleteButton = (name) => {
    if (data.is_used) {
      setAlert(
        "Cannot Delete Category, Category is associated with an Active Course"
      );
      setAlertType("warning");
    } else {
      setShow(true);
      setToggleEditDelete(2);
      setModalTitleBold(`Delete Category ${name} ?`);
      setLeftButton("YES");
      setRightButton("NO");
      setToggle(true);
    }
  };

  const leftButtonClick = (index) => {
    handleClose();
    if (parseInt(toggleEditDelete) === 1) {
      setShowCategory(true);
    } else if (parseInt(toggleEditDelete) === 2) {
      const id = database[idx].id;
      const url = `${config.endPoint.commonService}/category/${id}`;
      axios
        .delete(url)
        .then((res) => {
          setAlert(res.data.message);
          setAlertType("success");
          fetchData();
        })
        .catch(function (error) {
          if (error) {
            setAlert(error.response.data.message);
          } else {
            setAlert("Unable to delete Category!");
          }
          setAlertType("error");
        });
    }
  };

  const rightButtonClick = () => {
    handleClose();
    if (toggleEditDelete === 1) {
      setShowSubCategory(true);
    }
  };
  const switchCategorySubCategory = () => {
    setShowCategory(false);
    setShowSubCategory(true);
  };
  const switchSubCategoryCategory = () => {
    setShowSubCategory(false);
    setShowCategory(true);
  };

  const updateCard = (tempCat, parent, acronym, tempParentCatId) => {
    const json_data = {
      id: dataId,
      categoryName: tempCat,
      categoryAcronym: acronym,
      parentCategoryId: Number(tempParentCatId),
    };

    const patchUrl = `${config.endPoint.commonService}/category`;
    axios
      .patch(patchUrl, json_data)
      .then((res) => {
        setAlert(res.data.message);
        setAlertType("success");
        setData({ ...data, name: tempCat, parent, acronym });
        changeDataBase(data, idx);
        fetchData();
      })

      .catch(function (error) {
        setAlert(error.response.data.message);
        setAlertType("error");
      });
  };

  const updateSubCategory = (finalTags) => {
    const tags = [];
    finalTags.map((tag, index) => {
      return (tags[index] = tag);
    });

    const json_data = {
      id: dataId,
      categoryName: category,
      categoryAcronym: catAcronym,
      parentCategoryId: parentId,
      subCategories: tags,
    };
    const patchEndpoint = `${config.endPoint.commonService}/category`;
    axios
      .patch(patchEndpoint, json_data)
      .then((res) => {
        setAlert(res.data.message);
        setAlertType("success");
        setSubcategories(finalTags);
        fetchData();
      })
      .catch(function (error) {
        try {
          setAlert(error.response.data.message);
        } catch {
          setAlert(error.message);
        }

        setAlertType("error");
      });
  };

  const renderCategoryCard = () => {
    return (
      <div className="category-card-style" style={{ background: "white" }}>
        <div className="category-card-innerstyle">
          <div>
            <p>{truncate(data.name, 15)}</p>
          </div>
          <div className="category-card-buttons">
            <Button
              variant="flat"
              className="category-tile-button "
              style={{ padding: ".3rem" }}
              size="sm"
              onClick={() => editButton()}
            >
              <EditIcon className="pencilIcon" />
            </Button>
            {isSuperAdmin && (
              <Button
                variant="flat"
                className="category-tile-button "
                style={{ marginLeft: ".5rem" }}
                size="sm"
                onClick={() => deleteButton(data.name)}
              >
                <Trash className="trashIcon" />
              </Button>
            )}
            {show && (
              <AdminConfirmationModal
                show={show}
                handleClose={handleClose}
                toggle={toggle}
                title={modalTitle}
                titleBold={modalTitleBold}
                leftButton={leftButton}
                rightButton={rightButton}
                leftButtonClick={leftButtonClick}
                rightButtonClick={rightButtonClick}
              />
            )}

            {showCategory && (
              <AntdEditCategoryModal
                show={showCategory}
                showModal={showCategory}
                parentId={parentId}
                isUsed={data.is_used}
                category={data.name}
                parentCategory={parentCategory}
                acronym={catAcronym}
                handleClose={handleCloseCategory}
                switchModal={switchCategorySubCategory}
                updateCard={updateCard}
                parent={data.parent}
                fetchCategoryData={fetchCategoryData}
                fetchData={fetchData}
              />
            )}
            {showSubCategory && (
              <AntdEditSubCategoryModal
                show={showSubCategory}
                showModal={showSubCategory}
                category={data.name}
                acronym={catAcronym}
                parent={parentCategoryTile}
                propTags={subcategories}
                handleClose={handleCloseSubCategory}
                switchModal={switchSubCategoryCategory}
                updateSubCategory={updateSubCategory}
              />
            )}
          </div>
        </div>
        <div className="category_name"> {data.parentCategoryName}</div>
        <div>
          {subcategories.map((category) => {
            return (
              <Tag
                style={{
                  margin: "0.2rem",
                  marginBottom: "0.5rem",
                  fontFamily: "RakutenRoundRegular",
                }}
              >
                {category.length < 20 ? (
                  category
                ) : (
                  <Tooltip title={category}>{category.slice(0, 20)}...</Tooltip>
                )}
              </Tag>
            );
          })}
        </div>
      </div>
    );
  };

  return renderCategoryCard();
}

CategoryTile.propTypes = {
  idx: PropTypes.number,
  database: PropTypes.object,
  dataElement: PropTypes.object,
  parentCategory: PropTypes.object,
  setDatabase: PropTypes.func,
  changeDataBase: PropTypes.func,
};

export default CategoryTile;

import React from "react";
import { Tabs } from "antd";

import "./antDesignTabbedFilter.scss";

function TabbedFilter({
  children,
  catagories,
  handleCatagoryChange,
  defaultTab = 0,
  size = "large",
  tabBarGutter = 92,
}) {
  const tabItems = catagories.map((category) => {
    return {
      key: `${category.value}`,
      label: <div className="tab-label">{category.label}</div>,
      children: children,
    };
  });

  return (
    <Tabs
      className="tabbed-filter-container"
      tabBarGutter={tabBarGutter}
      activeKey={`${defaultTab}`}
      items={tabItems}
      onTabClick={(key) => handleCatagoryChange(key)}
      size={size}
    />
  );
}
export default TabbedFilter;

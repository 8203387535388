import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import style from "./inlineNumberButton.module.css";

function InlineNumberButton({ count, clickHandler }) {
	const [symbol, setsymbol] = useState("+");
	useEffect(() => {
		if (count) {
			setsymbol(count);
		}
	}, [count]);

	return (
		<>
			<button className={style.buttonStyle} onClick={clickHandler}>
				{symbol}
			</button>
		</>
	);
}

InlineNumberButton.propTypes = {
	count: PropTypes.number,
	clickHandler: PropTypes.func,
};

export default InlineNumberButton;

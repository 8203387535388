import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,
  Spin,
  Button,
  Alert,
  Breadcrumb,
  Table,
  Input,
} from "antd";
import addIcon from "../../../assets/icons/add_icon.svg";
import config from "../../../config";
import { CSVLink } from "react-csv";
import RecommendationDetailModal from "./RecommendationDetailModal";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { InlineIconButton } from "../../../component-library";
import "./AntdViewYourRecommendation.scss";

const crumbs = [
  {
    title: (
      <a href="/home" className="common-breadcrumbs-color">
        Home
      </a>
    ),
  },
  {
    title: (
      <a href="/Manager" className="common-breadcrumbs-color">
        Manager Dashboard
      </a>
    ),
  },
  {
    title: "My Recommendation",
  },
];

const tableSearch = {
  top: "-4.75rem",
  left: "56.5rem",
  width: "15rem",
};
const downloadButtonStyle = {
  position: "relative",
  top: "-7.5rem",
  left: "52.5rem",
};

const addNewButton = {
  marginLeft: "9rem",
};

export default function MyRecommendations() {
  const history = useHistory();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [showAlert, setShowAlert] = useState("");
  const [showRecommendationModal, setShowRecommendationModal] = useState(false);
  const handleClose = () => setShowRecommendationModal(false);
  const [recommendationDetail, setRecommendationDetail] = useState([]);
  const searchFormatted = true;
  const [tableData, setTableData] = useState([]);
  const [bottom, setBottom] = useState("bottomCenter");

  const activeCourseColumns = [
    {
      dataIndex: "courseId",
      title: "Course Id",
      width: 120,
      sorter: (a, b) => a?.courseId - b?.courseId,
    },
    {
      dataIndex: "courseDetails.name",
      title: "Course",
      width: 120,
      sorter: (a, b) =>
        a?.courseDetails?.name?.localeCompare(b?.courseDetails?.name),
      render: (_, row) => <span>{row?.courseDetails?.name}</span>,
    },
    {
      dataIndex: "courseType",
      title: "Course Type",
      width: 120,
      sorter: (a, b) => a?.courseType?.localeCompare(b?.courseType),
    },
    {
      dataIndex: "recommendedToDetails",
      title: "Recommended To",
      width: 120,
      sorter: (a, b) => a?.recommendedToDetails - b?.recommendedToDetails,
      render: (cell, row) => {
        const users = row?.recommendedToDetails
          .map(
            (val) =>
              `${val.userDetails?.firstName} ${val.userDetails?.lastName}`
          )
          .join(", ");

        return (
          <>
            <div
              title={`${row.recommendedToDetails.firstName} ${row.recommendedToDetails.lastName}`}
            >
              <strong style={{ fontFamily: "RakutenRoundSemiBold" }}>
                {`${
                  users && users.length > 60
                    ? users.substring(0, 60) + "..."
                    : users
                }`}
              </strong>
            </div>
          </>
        );
      },
      csvFormatter: (cell, row) => {
        return row.recommendedToDetails
          .map((val) => val.userDetails.name.toString())
          .join(", ");
      },
    },
    {
      dataIndex: "courseDetails.id",
      title: "More Details",
      width: 120,
      csvExport: false,
      headerAlign: "center",
      align: "center",
      render: (_, row) => {
        return (
          <InlineIconButton
            iconURL={addIcon}
            alt="edit button"
            clickHandler={(event) => {
              openMoreDetails(row, row.id);
            }}
          />
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataIndex: "courseDetails.name",
      title: "",
      order: "asc",
    },
  ];
  const activeRowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#ffffff" };
    return style;
  };

  const onAddNewRecommendation = () => {
    history.push("/Manager/ViewYourRecommendation/SelectCourses");
  };

  const openMoreDetails = (data, id) => {
    setRecommendationDetail(data);
    setShowRecommendationModal(true);
  };

  const rightButtonClick = () => {
    setShowRecommendationModal(false);
  };

  useEffect(() => {
    getMyRecommendations();
  }, []);

  const getMyRecommendations = () => {
    axios
      .get(
        `${config.endPoint.commonService}/recommendation/getRecommendationsByaUser`
      )
      .then((response) => {
        const newlistData = response?.data?.data?.map((row) => {
          const recommendedUsers = row.recommendedToDetails
            .map((val) => val?.userDetails?.name)
            .join(", ");

          return { ...row, recommendedUsers: recommendedUsers };
        });
        setListData(newlistData);
        setTableData(newlistData);
        setPageCount(response?.data?.data?.length);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
        setAlertType("error");
      });
  };
  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) => {
      return item?.courseDetails?.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const CSVColumns = activeCourseColumns.map((header, index) => ({
    key: header.dataIndex,
    label: header.title,
  }));
  const formatCsvData = (listData) => {
    return listData.map((data) => {
      const { recommendedToDetails } = data;
      return {
        ...data,
        recommendedToDetails: recommendedToDetails
          .map(
            ({ userDetails }) =>
              `${userDetails?.firstName} ${userDetails?.lastName}`
          )
          .join(", "),
      };
    });
  };

  const tableHeader = (
    <div className="recommendation-header">
      <div className="recommendation-header1">
        <Row>
          <Col lg={8} xs={8}>
            <Typography className="my-Recommendations-pagetitle">
              My Recommendations
              <span
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "RakutenRoundRegular",
                }}
              >
                ({showLoader ? <Spin /> : pageCount})
              </span>
            </Typography>
          </Col>
          <Col lg={6}></Col>
          <Col lg={2} style={{ paddingLeft: "5rem" }}>
            <CSVLink
              filename="Your_Recommendations"
              extension=".csv"
              wrapColumnChar=""
              headers={CSVColumns}
              data={formatCsvData(listData)}
            >
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadOutlined />}
                size="large"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              />
            </CSVLink>
          </Col>
          <Col lg={4} style={{ paddingLeft: "2rem" }}>
            <Input
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
              suffix={<SearchOutlined />}
              className={"notificationSearch"}
              style={{
                marginTop: "1rem",
                width: " 10rem",
                fontSize: "1rem",
                fontFamily: "RakutenRoundRegular",
                borderRadius: "4px",
              }}
            />
          </Col>
          <Col lg="2" style={{ paddingTop: "1rem" }}>
            <Button
              className="recommend-addnewbutton common-button-background-color"
              type="button"
              icon={<PlusCircleOutlined />}
              onClick={onAddNewRecommendation}
            >
              Add New
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setShowAlert(true);
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  return (
    <div className="recommendation-container">
      <Row className="justify-content-center">
        {showAlert && (
          <Col>
            <Alert
              message={alert}
              type={alertType}
              onCloseAlert={() => setAlert("")}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col style={{ paddingBottom: "2rem" }}>
          <Breadcrumb className="recommendations-crumbs" items={crumbs} />
          {tableHeader}
        </Col>
      </Row>

      <Row className="recommendation-table">
        <Col>
          <Table
            rowKey="courseId"
            loading={showLoader}
            columns={activeCourseColumns}
            dataSource={listData}
            pagination={{
              position: [bottom],
              showQuickJumper: true,
            }}
          />
        </Col>
      </Row>
      {showRecommendationModal && (
        <RecommendationDetailModal
          show={showRecommendationModal}
          handleClose={handleClose}
          leftButton="Cancel"
          rightButton="OK"
          rightButtonClick={rightButtonClick}
          recommendationDetail={recommendationDetail}
        />
      )}
    </div>
  );
}

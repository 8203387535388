import React from "react";

const SelectedCourseIcon = ({
  fillColor = "#00A0F0",
  strokeColor = "#00A0F0",
  pathFillColor = "white",
  width = "44px",
  height = "44px",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22"
      cy="22"
      r="20"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="2.5"
    />
    <mask
      id="mask0"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="11"
      y="15"
      width="22"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15H33V30H11V15Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7851 17.2293L20.1036 29.5815C19.8171 29.8605 19.4419 30 19.0667 30C18.6914 30 18.3161 29.8605 18.0297 29.5815L11.2149 22.9436C10.9284 22.6647 10.9284 22.2124 11.2149 21.9334L12.2517 20.9235C12.5382 20.6446 13.0024 20.6446 13.2889 20.9235L19.0667 26.5513L30.7111 15.2092C30.9976 14.9303 31.4618 14.9303 31.7483 15.2092L32.7851 16.2191C33.0716 16.4981 33.0716 16.9504 32.7851 17.2293Z"
        fill={pathFillColor}
      />
    </g>
  </svg>
);

export default SelectedCourseIcon;

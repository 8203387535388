import React, { useState, useEffect, useCallback, useContext } from "react";
import style from "./attendanceHome.scss";
import axios from "axios";
import AttendanceHomeRangePicker from "./AttendanceHomeRangePicker";
import { DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Alert, Breadcrumb, Button, Table } from "antd";
import downloadIcon from "../../../assets/icons/download_icon.svg";
import UtilityFunctions from "../../../utils/utility";
import config from "../../../config";
import TimeFormatter from "./../../../utils/timeFormatter";
import { useHistory } from "react-router-dom";
import userTickIcon from "../../../assets/icons/user_tick_icon.svg";
import { CourseContext } from "../../../context/CourseContext";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { Link } from "react-router-dom";
import AttendanceHomeSearchInput from "./AttendanceHomeSearchInput";

function AttendanceHome() {
  const { userDateFormat } = useContext(CourseContext);
  const [listData, setListData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortByColumn, setSortByColumn] = useState("startTime");
  const [sortOrder, setSortOrder] = useState("desc");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [bottom, setBottom] = useState("bottomCenter");
  const history = useHistory();

  const attendanceHomeColumns = [
    {
      dataIndex: "courseId",
      title: "COURSE ID",
      width: "120px",
      sorter: true,
      key: "courseId",
      align: "center",
    },
    {
      dataIndex: "courseName",
      title: "COURSE NAME",
      sort: true,
      width: "182px",
      sorter: true,
      textWrap: "word-break",
      ellipsis: true,
      key: "courseName",
      filterSearch: true,
      filteredValue: filteredInfo.courseName || null,
      onFilter: (value, record) => record.courseName === value,
      filters: [...new Set(listData.map((data) => data.courseName))].map(
        (name) => {
          return {
            text: decodeHtmlEntities(name),
            value: name,
          };
        }
      ),
      render: (cell, record) => {
        console.log(record, "....}");
        return (
          <Link
            to={`/course-details/${record.courseId}`}
            style={{ color: "red" }}
            target="_blank"
            className={`${
              record.isPrivate ? "privateSession" : "publicSession"
            } ant-table-ellipsis--2`}
          >
            {decodeHtmlEntities(cell)}
          </Link>
        );
      },
    },
    {
      dataIndex: "batchId",
      title: "SESSION ID",
      sort: true,
      width: 100,
      sorter: true,
      key: "sessionId",
      align: "center",
    },
    {
      dataIndex: "batchName",
      title: "SESSION NAME",
      textWrap: "word-break",
      ellipsis: true,
      sort: true,
      width: "150px",
      sorter: true,
      key: "sessionName",
      filterSearch: true,
      filteredValue: filteredInfo.sessionName || null,
      onFilter: (value, record) => record.batchName === value,
      filters: listData.map((data) => {
        return {
          text: decodeHtmlEntities(data.batchName),
          value: data.batchName,
        };
      }),
      render: (cell, record) => (
        <span
          className={`${
            record.isPrivate ? style.privateSession : style.publicSession
          } ant-table-ellipsis--2`}
        >
          {decodeHtmlEntities(cell)}
        </span>
      ),
    },
    {
      dataIndex: "startTime",
      title: "START DATE AND TIME",
      sort: true,
      width: "130px",
      sorter: true,
      key: "startTime",
      render: (cell) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
    },
    {
      dataIndex: "endTime",
      title: "END DATE AND TIME",
      sort: true,
      width: "130px",
      sorter: true,
      key: "endTime",
      render: (cell) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
    },
    {
      title: "ATTENDANCE",
      width: "115px",
      align: "center",
      render: (value, record) => (
        <Button
          type="button"
          onClick={() => {
            openAttendanceDetailList(record.courseId, record.batchId);
          }}
        >
          <img src={userTickIcon} />
        </Button>
      ),
    },
    {
      title: "DOWNLOAD",
      width: "115px",
      align: "center",
      render: (value, record) => (
        <Button
          type="button"
          onClick={() => {
            exportAttendanceData(
              record.courseId,
              record.batchId,
              record.identityId
            );
          }}
        >
          <img src={downloadIcon} />
        </Button>
      ),
    },
  ];

  const openAttendanceDetailList = (courseId, batchId) => {
    history.push(`/Admin/Attendance/${courseId}/${batchId}`);
  };

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
    },
    {
      title: "Attendance",
    },
  ];

  const getAttendanceHomeData = useCallback(
    (signal) => {
      setShowLoader(true);
      axios
        .get(`${config.endPoint.instructorLedNew}/session/`, {
          params: {
            searchString: searchString,
            pageNumber: pageNumber,
            pageLimit: pageSize,
            orderBy: sortByColumn,
            order: sortOrder,
            startTime: startTime,
            endTime: endTime,
          },
          signal: signal,
        })
        .then((response) => {
          setListData(response.data.data.sessions);
          setShowLoader(false);
          setTotal(response.data.data.total);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
        .catch((error) => {
          // if (error?.code !== "ERR_CANCELED") {
          //   setAlert(
          //     error.response.data.message ||
          //       "Something went wrong, Please try again."
          //   );
          //   setAlertType("danger");
          // }
        });
    },
    [
      endTime,
      pageNumber,
      pageSize,
      searchString,
      sortByColumn,
      sortOrder,
      startTime,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAttendanceHomeData(signal);
    return () => {
      controller.abort();
    };
  }, [
    searchString,
    pageNumber,
    pageSize,
    sortOrder,
    sortByColumn,
    startTime,
    endTime,
    getAttendanceHomeData,
  ]);

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber || 1);
  };

  const onPageSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { action } = extra;
    const { columnKey, order } = sorter;
    if (order) {
      setSortByColumn(columnKey);
      setSortOrder(order === "ascend" ? "asc" : "desc");
      setFilteredInfo({});
    } else {
      setSortByColumn("startTime");
      setSortOrder("desc");
      setFilteredInfo({});
    }
    if (action === "paginate") {
      setFilteredInfo({});
    } else if (action === "filter") {
      setPageNumber(pagination.defaultCurrent);
      setFilteredInfo(filters);
    }
  };

  const handleDateRangeChange = (startTime, endTime) => {
    setStartTime(startTime);
    setEndTime(endTime);
    setPageNumber(1);
    setFilteredInfo({});
  };

  const handleSearch = (text) => {
    setPageNumber(1);
    setFilteredInfo({});
    setSearchString(text);
  };

  const exportAttendanceData = (courseId, batchId, identityId) => {
    axios
      .get(`attendance/export/${courseId}/${batchId}`)
      .then((response) => {
        const fileName = `${courseId}_${batchId}_attendance_export.csv`;
        UtilityFunctions.downloadCSVFile(response, fileName);
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const exportMultipleAttendanceData = () => {
    axios
      .get(
        `attendance/exportMultipleSession?${UtilityFunctions.arrayToQueryString(
          selectedRowKeys,
          "batchIds"
        )}`,
        {
          headers: {
            "Content-Type":
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const fileName = `attendance_export.xlsx`;
        UtilityFunctions.downloadXLSXFile(response, fileName);
      })
      .catch((error) => {
        setAlert(
          JSON.parse(new TextDecoder().decode(error?.response?.data))
            ?.message || "Something went wrong, Please try again."
        );
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const headerComponent = (
    <Row className={"attendance-header"} gutter={24}>
      <Col lg={5}>
        <div className="attendance-pageTitle">Attendance</div>
      </Col>
      <Col lg={3}>
        <div>
          {selectedRowKeys.length ? (
            <Button
              type="button"
              icon={<DownloadOutlined />}
              disabled={selectedRowKeys && selectedRowKeys.length > 10}
              onClick={exportMultipleAttendanceData}
              className={"attendance-buttonContainer"}
            >
              Download
            </Button>
          ) : null}
        </div>
      </Col>
      <Col lg={4}></Col>
      <Col lg={8}>
        <Row gutter={24}>
          <Col lg={6}>
            <div className={"attendance-rangeLabel"}>Sessions Start Date:</div>
          </Col>
          <Col lg={18}>
            <AttendanceHomeRangePicker
              placeholder={["From", "To"]}
              minValue={9999}
              onChange={handleDateRangeChange}
              showPresets={true}
            />
          </Col>
        </Row>
      </Col>
      <Col lg={4}>
        <AttendanceHomeSearchInput
          onSearch={handleSearch}
          placeHolder={"Search"}
        />
      </Col>
    </Row>
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          margin: "3.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
            style={{ marginTop: "2rem", marginBottom: "0rem" }}
          />
        )}
        <Breadcrumb items={crumbs} className={"attendance-crumbs"} />
        {headerComponent}
        <div className={"attendance-table"}>
          {
            <Table
              dataSource={listData}
              rowKey={(record) => record.batchId}
              columns={attendanceHomeColumns}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              horizontalScroll={false}
              loading={showLoader}
              pagination={{
                position: [bottom],
                showSizeChanger: false,
                total: total,
                defaultCurrent: pageNumber,
                current: pageNumber,
                pageSize: pageSize,
                onChange: onPageChange,
                onShowSizeChange: onPageSizeChange,
                showTotal: (total) => `Total ${total} items`,
              }}
            />
          }
        </div>
      </div>
    </>
  );
}

export default AttendanceHome;

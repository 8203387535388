const assignedIcon = ({ color = "black", width = 18, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2951_944)">
        <path
          d="M5.5229 7.76533C3.38344 7.76147 1.64217 6.02175 1.64062 3.88769C1.64062 2.85159 2.04424 1.8758 2.78033 1.14048C3.51643 0.405161 4.49299 0 5.53064 0C6.57447 0.000773208 7.5518 0.4098 8.2879 1.15053C9.02167 1.88895 9.42219 2.86474 9.41678 3.89852C9.40518 6.03102 7.66237 7.76533 5.53064 7.76533H5.5229ZM5.52832 1.18765C4.04221 1.18765 2.83059 2.39385 2.82673 3.87609C2.82518 4.5975 3.10663 5.27792 3.62081 5.79288C4.1319 6.30551 4.80769 6.58774 5.52368 6.58774H5.53605C7.02215 6.58078 8.23068 5.36684 8.22913 3.88151C8.22836 2.39695 7.01752 1.18842 5.53064 1.18687H5.52832V1.18765Z"
          fill={color}
        />
        <path
          d="M0.576069 13.5332C0.245136 13.5231 -0.00306424 13.2618 2.85886e-05 12.9254C0.00776067 11.9891 0.411375 11.1896 1.2333 10.4813C2.04207 9.78386 3.06116 9.34623 4.34778 9.14365C4.70577 9.0872 5.08001 9.05859 5.45888 9.05859H5.52074C6.96741 9.06478 8.18289 9.38953 9.23755 10.0506C10.0889 10.5841 10.6363 11.2359 10.9115 12.0432C11.009 12.33 11.0569 12.6285 11.0546 12.9316C11.0515 13.2648 10.8118 13.5177 10.4832 13.5332C10.4747 13.5332 10.4654 13.5332 10.4569 13.5332C10.1523 13.5332 9.89555 13.2842 9.88318 12.978C9.85921 12.3594 9.59013 11.8391 9.06048 11.386C8.41022 10.83 7.60685 10.4836 6.60323 10.3274C6.21894 10.2679 5.83852 10.237 5.47434 10.237C4.50474 10.237 3.58772 10.4519 2.74878 10.8772C2.37069 11.0689 2.04207 11.3102 1.773 11.5955C1.38948 12.0014 1.19309 12.4499 1.17144 12.9679C1.15829 13.285 0.904682 13.5332 0.594626 13.5332H0.576842H0.576069Z"
          fill={color}
        />
        <path
          d="M12.1112 9.42524C11.9705 9.42524 11.8352 9.36957 11.7292 9.26828C11.2004 8.75951 10.6746 8.22754 10.1666 7.71181L10.0135 7.5564C9.91916 7.46052 9.87276 7.33294 9.87508 7.17752C9.88359 6.91231 9.99416 6.74066 10.223 6.64092C10.3135 6.60148 10.4009 6.58138 10.4828 6.58138C10.6235 6.58138 10.7565 6.64092 10.8787 6.75767C11.2251 7.08938 11.5297 7.3948 11.8112 7.69094C11.8731 7.75588 11.9705 7.84248 12.1097 7.84248C12.2604 7.84248 12.3625 7.73887 12.412 7.68939C13.3336 6.75999 14.1672 5.9257 14.9597 5.14012C15.0888 5.01177 15.252 4.94141 15.419 4.94141C15.6757 4.94141 15.8906 5.10533 15.9664 5.35894C16.0337 5.58394 15.9888 5.77724 15.8342 5.93266C14.778 6.99505 13.6437 8.13244 12.5071 9.25746C12.3981 9.36493 12.2573 9.42447 12.1112 9.42447V9.42524Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2951_944">
          <rect width="16" height="13.5327" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default assignedIcon;

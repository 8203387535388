import React from "react";
import style from "./learningPathTile.module.css";
import { Link } from "react-router-dom";

function LearningPathTile(props) {
  return (
    <div style={props.style} className={style.container} key={props.index}>
      <Link to={props.path}>
        <div className={style.card}>{props.children}</div>
      </Link>
    </div>
  );
}

export default LearningPathTile;

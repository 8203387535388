import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import style from "./lineProgressBarCourseDetail.module.css";
import lockIcon from "../../assets/icons/lock_icon.svg";
import { UserContext } from "../../context/UserContext";
import { truncate } from "lodash";

function LineProgressBarCourseDetail({
  isPrivate = false,
  isLocked = false,
  clickHandler,
  sessionObj,
}) {
  const { userConfig } = useContext(UserContext);
  const [progressPercent, setprogressPercent] = useState(0);
  const currentValue = sessionObj.counter || 0;
  const sessionSize = sessionObj.size || 0;
  useEffect(() => {
    const progress = (currentValue / sessionSize) * 100;
    setprogressPercent(progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionObj]);

  let lineStyle = {
    backgroundColor: userConfig.primary,
  };

  if (isPrivate) {
    lineStyle = {
      backgroundColor: userConfig.secondary,
    };
  }
  lineStyle.width = `${progressPercent}%`;
  let x = 123234232;
  return (
    <>
      <div className={style.container}>
        <div
          className={style.outerContainer}
          style={
            sessionSize === 0 ? { cursor: "unset" } : { cursor: "pointer" }
          }
          onClick={clickHandler}
        >
          <div className={style.countValue}>
            <span className={style.currentCount}>{currentValue}/</span>
            <span>
              {String(sessionSize).length > 3
                ? `${sessionSize.toString().slice(0, 3)}...`
                : sessionSize}
            </span>
          </div>
          <div className={style.baseLine}>
            <div className={style.progressLine} style={lineStyle}></div>
          </div>
        </div>
        {isLocked && (
          <span className={style.iconStyle}>
            <img src={lockIcon} alt="lock" />
          </span>
        )}
      </div>
    </>
  );
}

LineProgressBarCourseDetail.propTypes = {
  currentCount: PropTypes.number,
  maxCount: PropTypes.number,
  isPrivate: PropTypes.bool,
  isLocked: PropTypes.bool,
  clickHandler: PropTypes.func,
};

export default LineProgressBarCourseDetail;

import axios from "axios";

async function axiosGet(endPoint) {
  try {
    const { data: response } = await axios.get(endPoint);
    const { data } = response || {};
    return data || [];
  } catch (error) {
    console.log({ error });
    return [];
  }
}

export { axiosGet };

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "antd";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { ReactComponent as Cross } from "../../assets/icons/x.svg";
import PropTypes from "prop-types";
import style from "./subcategoryTags.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Input } from "antd";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

function SubcategoryTags({
  disabled = false,
  initialTags = [],
  setFinalTags,
  placeholder = "",
  customSubCategoryBoxStyle = {},
  customAddSubCategoryStyle = {},
  customPlusButtonStyle = {},
  submitDisable = () => null,
}) {
  const [currentTags, setCurrentTags] = useState(initialTags); //existing tags array + new ones
  const [tempSubcategory, setTempSubcategory] = useState(""); //one subcategory: evey word added, a string

  useEffect(() => {
    setCurrentTags(initialTags);
  }, [initialTags]);

  const onAddButtonClick = () => {
    if (tempSubcategory && tempSubcategory.trim().length) {
      addSubcategory(tempSubcategory.trim());
      submitDisable();
    }
    setTempSubcategory("");
  };

  const addSubcategory = (tag) => {
    if (tag && tag.length) {
      const index = currentTags.length;

      const tagObject = { id: index, name: tag, is_used: false };

      setCurrentTags([...currentTags, tagObject]);
    }
  };

  useEffect(() => {
    currentTags && setFinalTags(currentTags);
    // eslint-disable-next-line
  }, [currentTags]);

  const deleteInitialTags = (id) => {
    if (!disabled) {
      const remainingTags = currentTags.filter((current) => id !== current.id);
      setCurrentTags(remainingTags);
      submitDisable();
    }
  };

  return (
    <div>
      <div
        className={style.cardSubcategoryInner}
        style={customSubCategoryBoxStyle}
      >
        {currentTags &&
          currentTags.map((item, i) => {
            return (
              <span className={style.subcategoryLabel} key={i}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ marginLeft: "24px", marginRight: "16px" }}>
                    {item.name}
                  </div>
                  <CloseOutlined onClick={() => deleteInitialTags(item.id)} />
                </Row>
              </span>
            );
          })}
      </div>
      <div className={style.addSubcategory} style={customAddSubCategoryStyle}>
        <Row
          className={style.row}
          style={{
            ...customPlusButtonStyle,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col lg={10} className={style.inputArea}>
            <Input
              placeholder={placeholder}
              disabled={disabled}
              bordered={false}
              value={tempSubcategory}
              onChange={(e) => {
                setTempSubcategory(e.target.value);
              }}
              className={style.form}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  onAddButtonClick();
                }
              }}
            />
          </Col>
          <Col
            className={style.plusButton}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PlusOutlined style={{ fontSize: 16 }} onClick={onAddButtonClick} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
SubcategoryTags.propTypes = {
  initialTags: PropTypes.any,
  finalTags: PropTypes.any,
  setFinalTags: PropTypes.func,
  customSubCategoryBoxStyle: PropTypes.object,
  customAddSubCategoryStyle: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubcategoryTags;

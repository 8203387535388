import PropTypes from "prop-types";
import SwitchSelector from "react-switch-selector";
import style from "./toggle.module.css";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
export default function Toggle(props) {
  const { userConfig } = useContext(UserContext);

  const { primary } = userConfig;
  const { firstLabelName, secondLabelName, toggle, customStyle, selected } =
    props;

  const options = [
    {
      label: firstLabelName,
      value: false,
      selectedFontColor: "white",
      selectedBackgroundColor: primary,
      fontSize: 16,
    },
    {
      label: secondLabelName,
      value: true,
      selectedFontColor: "white",
      selectedBackgroundColor: "#F59600",
      fontSize: 16,
    },
  ];

  const onChange = (newValue) => {
    toggle(newValue);
  };
  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === selected
  );

  return (
    <div className={style.outer} style={customStyle}>
      <SwitchSelector
        className="react-switch-selector-option"
        wrapperBorderRadius="6"
        optionBorderRadius="5"
        onChange={onChange}
        options={options}
        initialSelectedIndex={initialSelectedIndex}
        backgroundColor={"#EBEBEB"}
        fontColor={"#828282"}
        fontSize="1rem"
        selectedFontColor={"white"}
      />
    </div>
  );
}

Toggle.propTypes = {
  firstLabelName: PropTypes.string,
  firstLabelValue: PropTypes.bool,
  secondLabelName: PropTypes.string,
  secondLabeValue: PropTypes.bool,
  toggle: PropTypes.func,
  customStyle: PropTypes.object,
  selected: PropTypes.bool,
};

export const serializeCategoryResponse = (response) => {
  const serializedResponse = response?.data?.data?.map((category) => {
    return {
      ...category,
      id: category.id,
      category_acronym: category.acronym,
      name: category.name,
      parent_id: category.parentId,
      is_used: category.isUsed,
      subcategories: category?.subCategories,
      parent: category?.parentCategoryName,
    };
  });
  return serializedResponse;
};

export const serializeCategoryById = (response) => {
  const category = response.data.data;

  return {
    ...category,
    id: category.id,
    category_acronym: category.acronym,
    name: category.name,
    parent_id: category.parentId,
    is_used: category.isUsed,
    subcategories: category?.subCategories,
    parent: category?.parent,
  };
};

export const serializeSubCategories = (response) => {
  const subCategories = response.data.data;

  return subCategories.map((subCategory) => {
    return {
      id: subCategory.id,
      name: subCategory.name,
      category_id: subCategory.categoryId,
      category_name: subCategory.categoryName,
    };
  });
};

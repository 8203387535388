import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CustomSelect } from "../";
import config from "../../config";
import style from "./searchFilter.module.css";
import { serializeDataResponse } from "../../serializer/dataSerializer";

function formatLanguageList(data) {
  return data.map((current) => {
    return { label: current.title, value: current.title, id: current.id };
  });
}

function formatProficiencyList(data) {
  return data.map((current) => {
    return { label: current.title, value: current.title, id: current.id };
  });
}

const { sortBy: sortByFilters } = config.instructorLed;

export default function SearchFilter({ upliftFilters, filters }) {
  const [language, setLanguage] = useState([]);
  const [courseType, setCourseType] = useState([]);
  const [proficiency, setProficiency] = useState([]);

  const [selectedCourseType, setSelectedCourseType] = useState({
    label: "All",
    value: "all",
  });
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedProficiency, setSelectedProficiency] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [currentFilter, setCurrentFilter] = useState({});

  useEffect(() => {
    setCourseType([
      {
        label: "All",
        value: "all",
      },
      {
        label: "INSTRUCTOR LED",
        value: "instructor-led",
      },
      {
        label: "E-LEARNING",
        value: "e-learning",
      },
      {
        label: "LEARHING PATH",
        value: "learning-path",
      },
    ]);
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    const languageEndpoint = `${dataEndpoint}?type=languages`;
    axios.get(languageEndpoint).then((response) => {
      const formatedLanguageList = formatLanguageList(
        serializeDataResponse(response)?.languages
      );
      setLanguage(() => [
        {
          label: config.defaultLanguage.label,
          value: config.defaultLanguage.value,
        },
        ...formatedLanguageList,
      ]);
    });
    const proficiencyEndpoint = `${dataEndpoint}?type=proficiency`;
    axios.get(proficiencyEndpoint).then((response) => {
      const formatedProficiencyList = formatProficiencyList(
        serializeDataResponse(response)?.proficiency
      );
      setProficiency(() => [
        {
          label: config.defaultProficiency.label,
          value: config.defaultProficiency.value,
        },
        ...formatedProficiencyList,
      ]);
    });
  }, []);

  useEffect(() => {
    setCurrentFilter(filters);
  }, [filters]);

  const handleLanguage = (currentLanguage) => {
    setSelectedLanguage(currentLanguage);
  };

  const handleCourseType = (currentCourseType) => {
    setSelectedCourseType(currentCourseType);
    setSelectedLanguage([]);
    setSelectedProficiency([]);
  };

  const handleProficiency = (currentProficiency) => {
    setSelectedProficiency(currentProficiency);
  };

  const handleSortBy = (currentSortBy) => {
    setSelectedSortBy(currentSortBy);
  };

  const handleFilters = ({
    courseTypeID,
    languageId,
    proficiencyId,
    sortBy,
  }) => {
    if (courseTypeID) {
      const [value] = courseType.filter((current) => {
        return current.value.toString() === courseTypeID.toString();
      });
      setSelectedCourseType(value);
    }

    if (languageId) {
      const [value] = language.filter((current) => {
        return current.value === languageId;
      });
      setSelectedLanguage(value);
    }

    if (proficiencyId) {
      const [value] = proficiency.filter((current) => {
        return current.value === proficiencyId;
      });
      setSelectedProficiency(value);
    }

    if (sortBy) {
      const [value] = sortByFilters.filter((current) => {
        return current.value === sortBy;
      });
      setSelectedSortBy(value);
    } else {
      setSelectedSortBy(config.instructorLed.sortBy[0]);
    }
  };

  //set filters coming from filters
  useEffect(() => {
    if (
      currentFilter &&
      courseType &&
      courseType.length &&
      language &&
      language.length &&
      proficiency &&
      proficiency.length
    ) {
      handleFilters(currentFilter);
    }
    // eslint-disable-next-line
  }, [currentFilter, courseType, language, proficiency]);

  useEffect(() => {
    if (
      (selectedCourseType && selectedCourseType.label) ||
      (selectedSortBy && selectedSortBy.value) ||
      (selectedLanguage && selectedLanguage.value) ||
      (selectedProficiency && selectedProficiency.value)
    ) {
      const obj = {};
      if (selectedCourseType && selectedCourseType.value)
        obj.courseType = selectedCourseType.value;
      if (selectedSortBy && selectedSortBy.value)
        obj.sortBy = selectedSortBy.value;
      if (selectedLanguage && selectedLanguage.value)
        obj.languageId = selectedLanguage.value;
      if (selectedProficiency && selectedProficiency.value)
        obj.proficiencyId = selectedProficiency.value;
      upliftFilters(obj);
    }
    // eslint-disable-next-line
  }, [
    selectedCourseType,
    selectedSortBy,
    selectedLanguage,
    selectedProficiency,
  ]);

  const inlineStyleSelect = {
    control: (base) => ({
      ...base,
      borderColor: "#000",
      borderRadius: "2px",
      height: "2.5rem",
      fontSize: ".8125rem",
    }),
  };

  return (
    <>
      <Row className={style.searchFilterOuter}>
        <Col lg={2} className={style.titleStyle}>
          Filter By Course Type
        </Col>
        <Col lg={8}></Col>
        <Col lg={2} className={style.titleStyle}>
          Sort By
        </Col>
      </Row>
      <Row className={style.searchFilterInner}>
        <Col lg={2}>
          <div className={style.styleSelectBox}>
            <CustomSelect
              options={courseType}
              placeholder="COURSE TYPE"
              selected={selectedCourseType}
              handleDropdown={(selectedCourseType) =>
                handleCourseType(selectedCourseType)
              }
              inlineStyle={inlineStyleSelect}
              customStyle={style.optionsStyle}
            />
          </div>
        </Col>
        <Col lg={2}>
          <CustomSelect
            options={language}
            placeholder="LANGUAGE"
            selected={selectedLanguage}
            handleDropdown={(selectedLanguage) =>
              handleLanguage(selectedLanguage)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
            disable={
              selectedCourseType.value === "learning-path" ||
              selectedCourseType.value === "all"
            }
          />
        </Col>
        <Col lg={2} style={{ color: "#000" }}>
          <CustomSelect
            options={proficiency}
            placeholder="PROFICIENCY"
            selected={selectedProficiency}
            handleDropdown={(selectedProficiency) =>
              handleProficiency(selectedProficiency)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
            disable={
              selectedCourseType.value === "e-learning" ||
              selectedCourseType.value === "learning-path" ||
              selectedCourseType.value === "all"
            }
          />
        </Col>
        <Col lg={2}></Col>
        <Col lg={2}></Col>
        <Col lg={2} style={{ color: "#000" }}>
          <CustomSelect
            options={sortByFilters}
            selected={selectedSortBy}
            handleDropdown={(selectedSortBy) => handleSortBy(selectedSortBy)}
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
        </Col>
      </Row>
    </>
  );
}

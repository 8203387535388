import React, { useState } from "react";

import UtilityFunctions from "../../utils/utility";
import axios from "axios";
import ImportAddUserModal from "./ImportAddUserModal";
import AdminConfirmationModal from "../../component-library/adminConfirmationModal/AdminConfirmationModal";

function ImportUserHelper({
  showAddUserModal,
  handleCloseAddUserModal,
  page,
  setShowAlert = () => {},
  courseId,
  sessionId,
  sessionName,
  isSessionPrivate,
  sessionSize,
  enrolledUsers,
  setAlert,
  setAlertType,
  updatePage,
  openErrorCloseAddUser,
  showErrorModal,
  handleCloseErrorModal,
  learningPathData,
  showDatePicker,
}) {
  const errorTitle = "There are errors in some email, do you want to ";
  const errorTitleBold = "continue?";
  const yesButton = "YES";
  const noButton = "NO";

  const [fileUploaded, setFileUploaded] = useState(null);
  const [emailIds, setEmailIds] = useState(null);
  const hiddenFileInput = React.useRef(null);

  const [responseSessionId, setResponseSessionId] = useState(); //set in onSubmit api call

  const [sessionData, setSessionData] = useState(null);
  function yesButtonClick() {
    let url = "";
    let id = 0;
    if (page === "session") {
      url = "admin/bulk/batch/continue";
      id = Number(sessionId);
    } else if (page === "course") {
      url = "admin/bulk/course/continue";
      id = Number(courseId);
    }
    const yesData = {
      id: id,
      session_id: responseSessionId,
      continue: true,
    };
    axios
      .post(url, yesData)
      .then((res) => {
        const filename = "bulk-upload.csv";
        UtilityFunctions.downloadCSVFile(res, filename);
        setFileUploaded(null);
        updatePage();
        setShowAlert(true);
        setAlert(
          "Upload successful, please check the CSV file for status of the users"
        );
        setAlertType("success");
      })
      .catch((error) => {
        if (error && error.message) {
          setAlert(error.message);
        } else {
          setAlert("Unable to upload Email ids");
        }
        setAlertType("warning");
        setShowAlert(true);
      });
    handleCloseErrorModal();
  }

  function noButtonClick() {
    let url = "";
    let id = 0;
    if (page === "session") {
      url = "admin/bulk/batch/continue";
      id = Number(sessionId);
    } else if (page === "course") {
      url = "admin/bulk/course/continue";
      id = Number(courseId);
    }
    const noData = {
      id: Number(id),
      session_id: responseSessionId,
      continue: false,
    };
    axios
      .post(url, noData)
      .then((res) => {
        const filename = "bulk-upload.csv";
        UtilityFunctions.downloadCSVFile(res, filename);
        setFileUploaded(null);
        updatePage();
        setAlert(
          "Upload successful, please check the CSV file for status of the users"
        );
        setAlertType("success");
      })
      .catch((error) => {
        if (error && error.message) {
          setAlert(error.message);
        } else {
          setAlert("Unable to upload Email ids");
        }
        setAlertType("warning");
      });
    handleCloseErrorModal();
  }

  return (
    <>
      {showAddUserModal && (
        <ImportAddUserModal
          showAddUserModal={showAddUserModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          setSessionData={setSessionData}
          setEmailIds={setEmailIds}
          sessionData={sessionData}
          page={page}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
          hiddenFileInput={hiddenFileInput}
          emailIds={emailIds}
          courseId={courseId}
          sessionId={sessionId}
          sessionName={sessionName}
          isSessionPrivate={isSessionPrivate}
          sessionSize={sessionSize}
          enrolledUsers={enrolledUsers}
          setResponseSessionId={setResponseSessionId}
          setShowAlert={setShowAlert}
          setAlert={setAlert}
          setAlertType={setAlertType}
          updatePage={updatePage}
          openErrorCloseAddUser={openErrorCloseAddUser}
          learningPathData={learningPathData}
          showDatePicker={showDatePicker}
        />
      )}

      {showErrorModal && (
        <AdminConfirmationModal
          show={showErrorModal}
          handleClose={handleCloseErrorModal}
          toggle={true}
          title={errorTitle}
          titleBold={errorTitleBold}
          leftButton={yesButton}
          rightButton={noButton}
          leftButtonClick={yesButtonClick}
          rightButtonClick={noButtonClick}
        />
      )}
    </>
  );
}
export default ImportUserHelper;

import React from "react";

import PropTypes from "prop-types";
import style from "./inlineIconButton.module.css";

function InlineIconButton({
  iconURL,
  alt,
  clickHandler,
  otherStyle,
  imageStyle,
}) {
  return (
    <>
      <button
        className={`${style.buttonStyle} ${otherStyle}`}
        onClick={clickHandler}
      >
        <img src={iconURL} alt={alt} className={imageStyle} />
      </button>
    </>
  );
}

InlineIconButton.propTypes = {
  iconURL: PropTypes.string,
  alt: PropTypes.string,
  clickHandler: PropTypes.func,
  otherStyle: PropTypes.string,
  imageStyle: PropTypes.string,
};

export default InlineIconButton;

import { Col } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import PropTypes from "prop-types";

import style from "./teamReport.module.css";

const AttendanceStats = ({ attendence, attendencePercentage }) => {
  return (
    <div className={style.tileContainer}>
      <div className={style.tileLabel}>ATTENDENCE (MEMBERS WITH 100%)</div>
      <div className={style.tileValue}>{attendence || "--"}</div>
      {attendencePercentage && (
        <div className="d-flex flex-row align-items-center">
          <ProgressBar
            variant={"success"}
            now={attendencePercentage}
            className={style.progressBarStyle}
          />
          <span className={style.progressBarContent}>
            {attendencePercentage}%
          </span>
        </div>
      )}
    </div>
  );
};

AttendanceStats.propTypes = {
  attendence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  attendencePercentage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default AttendanceStats;

import { useContext, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ilIcon from "../../../assets/icons/InstructorLedCourse.svg";
import elIcon from "../../../assets/icons/myLearningElIcon.svg";
import lpIcon from "../../../assets/icons/learning_path.svg";
import BookmarkedIcon from "../../../components/common/icons/bookmarkedIcon";
import axios from "axios";
import "./myLearningsNew.scss";
import config from "../../../config";
import TimeFormaters from "../../../utils/timeFormatter";
import { UserContext } from "../../../context/UserContext";

const MyLearningsCourseCard = ({
  courseDetail,
  bookmark,
  setAlert,
  setAlertType,
  setShowAlert,
  icon,
  filterName,
}) => {
  const { userId, userConfig } = useContext(UserContext);

  const {
    duration,
    image,
    isPrivate,
    language,
    name,
    type,
    waitListCount,
    completionTimestamp,
    expectedCompletionDate,
    sessionStartTime,
    sessionEndTime,
    sessionTimeOffset,
    ilCourseCount,
    elCourseCount,
    id,
    isFavorite,
    isFavourite,
  } = courseDetail;

  const [isBookmarked, setIsBookmarked] = useState(isFavourite || isFavorite);

  const courseIcon = (type) => {
    switch (type) {
      case "EL":
        return elIcon;
      case "LP":
        return lpIcon;
      default:
        return ilIcon;
    }
  };

  const courseLink = () => {
    switch (type) {
      case "EL":
        return `e-learning-courses/${id}`;
      case "LP":
        return `learning-paths/${id} `;
      default:
        return `course-details/${id} `;
    }
  };

  const handleBookmarkStatus = (event) => {
    event.preventDefault();

    const LEARNING_PATH_FAVORITE_API_ENDPOINT =
      config.endPoint.commonService + "/favourite";

    if (!isBookmarked) {
      axios
        .post(LEARNING_PATH_FAVORITE_API_ENDPOINT, [
          {
            userId: userId,
            entityType: type,
            entityId: id,
          },
        ])
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmarked(true);
          }
        })
        .catch((error) => {
          setShowAlert(true);
          setAlert(
            error?.response?.data || "Something went wrong, please try again"
          );
          setAlertType("error");
        });
    } else {
      axios
        .delete(LEARNING_PATH_FAVORITE_API_ENDPOINT, {
          params: {
            userId: userId,
            courseType: type,
            courseId: id,
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmarked(false);
          }
          bookmark();
        })
        .catch((error) => {
          setShowAlert(true);
          setAlert(
            error?.response?.data || "Something went wrong, please try again"
          );
          setAlertType("warning");
        });
    }
  };

  return (
    <Link
      className={"myLearnings-courseTile"}
      to={courseLink()}
      target={`_blank`}
    >
      <div className={"myLearnings-subheading"}>
        <span>
          <img
            className={"myLearnings-subheadingImage"}
            src={courseIcon(type)}
            alt="Intructor Led"
          />
          <span className={"myLearnings-subheadingText"}>COURSE</span>
        </span>
        <span style={{ display: "flex" }}>
          {filterName !== "completed" && (
            <div onClick={handleBookmarkStatus}>
              {isBookmarked ? (
                <BookmarkedIcon
                  height={23}
                  width={23}
                  fillColor={
                    !isPrivate ? userConfig.primary : userConfig.secondary
                  }
                  color={!isPrivate ? userConfig.primary : userConfig.secondary}
                />
              ) : (
                <BookmarkedIcon
                  height={23}
                  width={23}
                  fillColor={!isPrivate ? "#ffffff" : "#ffffff"}
                  color={!isPrivate ? userConfig.primary : userConfig.secondary}
                />
              )}
            </div>
          )}
          {filterName !== "bookmarked" && (
            <span className={"my-learnings-card-icon"}>{icon}</span>
          )}
        </span>
      </div>
      <div className={"myLearnings-courseName"}>{name}</div>
      {type == "IL" && waitListCount ? (
        <div className={"myLearnings-waitlist"}>
          Your Waitlist # - {waitListCount}
        </div>
      ) : (
        sessionStartTime && (
          <div className={"myLearnings-sessionStart"}>
            {TimeFormaters.FormatDate(
              new Date(sessionStartTime),
              sessionTimeOffset
            )}
            {" - "}
            {TimeFormaters.FormatDate(
              new Date(sessionEndTime),
              sessionTimeOffset
            )}
          </div>
        )
      )}
      {type == "EL" && expectedCompletionDate && (
        <div className={"myLearnings-deadline"}>
          Completion Deadline :
          <div className={"myLearnings-deadlineDate"}>
            {TimeFormaters.FormatDate(
              new Date(expectedCompletionDate),
              sessionTimeOffset
            )}
          </div>
        </div>
      )}
      {type == "LP" && (
        <div className={"myLearnings-deadline"}>
          <span className={"myLearnings-lp-ilcount"}>{ilCourseCount}</span>IL
          Courses
          <span className={"myLearnings-lp-elcount"}>{elCourseCount}</span>EL
          Courses
        </div>
      )}
      {completionTimestamp && (
        <div className={"myLearnings-completed"}>
          Completed On :
          <div className={"myLearnings-deadlineDate"}>
            {TimeFormaters.FormatDate(
              new Date(completionTimestamp),
              sessionTimeOffset
            )}
          </div>
        </div>
      )}
      {completionTimestamp == null && (
        <div className={"myLearnings-duration"}>
          {TimeFormaters.decimalHrsToHrsMin(duration, "detailed")}
          {language &&
            `/${typeof language === "string" ? language : language.name} `}
        </div>
      )}
      <div
        className={"myLearnings-courseImage"}
        style={{
          backgroundImage: `url(${
            image || `${process.env.PUBLIC_URL}/default.png`
          })`,
        }}
      ></div>
    </Link>
  );
};

export default MyLearningsCourseCard;

import React, { useState, useEffect, useContext } from "react";
import AddCircle from "../../../assets/icons/plus_circle.svg";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import "./AntdCategoryManagement.scss";
import { Alert, Breadcrumb, Typography } from "antd";
import { Button, Col, Row } from "antd";
import { CategoryTile } from "../../../component-library";
import AntdNewCategoryModal from "./newCategoryModal/AntdNewCategoryModal";
import config from "../../../config";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="common-breadcrumbs-color">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  { title: "Category" },
];

function CategoryManagement() {
  const [database, setDatabase] = useState([]); // add data after api call
  const [gotData, setGotData] = useState(false);
  const [parentCategory, setParentCategory] = useState([]);
  const [gotParentCategory, checkParentCategory] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tags] = useState([]);
  const { hasRole } = useContext(UserContext);
  const isSuperAdmin = hasRole(["superAdmin"]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");

  const changeDataBase = (newData, idx) => {
    const newArr = [...database];
    newArr[idx] = newData;
    setDatabase(newArr);
  };

  const fetchParentCategories = () => {
    axios
      .get(`${config.endPoint.commonService}/parentCategory`)
      .then((res) => {
        const tempParent =
          res?.data?.data.map((value) => {
            return {
              id: value.id,
              parent_category_name: value.parentCategoryName,
            };
          }) || [];

        setParentCategory(tempParent);
        checkParentCategory(true);
      })
      .catch((error) => {
        checkParentCategory(false);
        setAlert("Something went wrong, Please try again.");
        setAlertType("error");
      });
  };
  function fetchData() {
    setGotData(false);
    axios
      .get(`${config.endPoint.commonService}/category`,{
        params:{
          skipTableJoin: 1
        }
      })
      .then((res) => {
        setDatabase(res.data.data);
        setGotData(true);
      })

      .catch(function (error) {
        setAlert("Something went wrong, Please try again.");
        setAlertType("error");
      });
  }

  useEffect(() => {
    //get the data of all categories
    fetchData();
    fetchParentCategories();
  }, []);

  let len = database.length;

  return (
    <div className="Antdcategory-management-container">
      {alert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
      )}
      <Breadcrumb
        style={{ fontFamily: "RakutenRoundRegular" }}
        items={crumbs}
      />
      <Typography className="categorManagement-page-title" pageCount={len}>
        Category
      </Typography>
      <Typography className="categorManagement-pageCount" pageCount={len}>
        ({len})
      </Typography>
      <div>
        <Col className={"newButton"}>
          {(isSuperAdmin && (
            <Button
              type="primary"
              className="newCategoryButton"
              onClick={() => setShowModal(true)}
            >
              <img
                src={AddCircle}
                alt="country_flag"
                style={{ padding: "0.25em", marginRight: "0.25rem" }}
              />
              NEW CATEGORY
            </Button>
          )) ||
            ""}
        </Col>
        {gotParentCategory && showModal && (
          <AntdNewCategoryModal
            tags={tags}
            showModal={showModal}
            setShowModal={setShowModal}
            setDatabase={setDatabase}
            database={database}
            parentCategory={parentCategory}
            fetchData={fetchData}
            setAlert={setAlert}
            setAlertType={setAlertType}
          />
        )}
      </div>
      <div className="category-card-column">
        {gotData &&
          database.map((data, index) => (
            <CategoryTile
              dataElement={data}
              idx={index}
              database={database}
              changeDataBase={changeDataBase}
              parentCategory={parentCategory}
              setDatabase={setDatabase}
              setAlert={setAlert}
              setAlertType={setAlertType}
              fetchData={fetchData}
            />
          ))}
      </div>
    </div>
  );
}

export default CategoryManagement;

import React from "react";
import { Row } from "react-bootstrap";

export default function Validation({ message, style, st }) {
  const marginTop = (style && style.marginTop) || "-2.188rem";
  const sty = {
    marginLeft: "0.375rem",
    marginTop: marginTop,
    fontSize: "small",
    marginBottom: "1.063rem",
    color: "#FF0000",
    ...st,
  };
  return <Row style={sty}>{message}</Row>;
}

import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import config from "../../../config";
import { CSVLink } from "react-csv";
import {
  Alert,
  Breadcrumb,
  Col,
  Input,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import toggleIcon from "./../../../assets/icons/toggle_down_icon.svg";
import toggleBlueIcon from "./../../../assets/icons/toggle_down_icon_blue.svg";
import "./Myteam.scss";
import { UserContext } from "../../../context/UserContext";
import AntdUserCourseInsights from "./AntdUserCourseInsights";

const crumbs = [
  {
    title: (
      <a href="/home" className="common-breadcrumbs-color">
        Home
      </a>
    ),
  },
  {
    title: (
      <a href="/Manager" className="common-breadcrumbs-color">
        Manager Dashboard
      </a>
    ),
  },
  {
    title: "My Team",
  },
];

const tableSearch = {
  top: "-4.75rem",
  left: "69rem",
  width: "12.5rem",
};
const downloadButtonStyle = {
  position: "relative",
  top: "-7.5rem",
  left: "63rem",
};

export default function MyTeam() {
  const history = useHistory();
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [allCourseStats, setAllCourseStats] = useState({});
  const [userId, setUserId] = useState("");
  const [ILCourseStats, setILCourseStats] = useState({});
  const [ELCourseStats, setELCourseStats] = useState({});

  const [bottom, setBottom] = useState("bottomCenter");
  const [tableData, setTableData] = useState([]);
  const isMobile = window.innerWidth < 786;
  const { userConfig } = useContext(UserContext);

  const activeCourseColumns = [
    {
      dataIndex: "id",
      title: "EMP ID",
      sorter: (a, b) => a.id - b.id,
      width: 90,
    },
    {
      dataIndex: "firstName",
      title: "NAME",
      sorter: (a, b) => a?.firstName?.localeCompare(b?.firstName),
      width: 130,
      render: (cell, row) => {
        return <Typography>{`${row.firstName} ${row.lastName}`}</Typography>;
      },
    },
    {
      dataIndex: "designationTitle",
      title: "ROLE",
      sorter: (a, b) => a?.designationTitle?.localeCompare(b?.designationTitle),
      width: 500,
      render: (cell, row) => {
        return <Typography>{`${row.designationTitle}`}</Typography>;
      },
    },
    {
      dataIndex: "country",
      title: "COUNTRY",
      sorter: (a, b) => a?.country?.localeCompare(b?.country),
      width: 300,
    },
    {
      dataIndex: "Department",
      title: "DEPARTMENT",
      sorter: (a, b) => a?.Department?.name.localeCompare(b?.Department?.name),
      width: 400,
      render: (cell, row) => {
        return <div>{row?.Department?.name}</div>;
      },
    },
    {
      dataIndex: "primaryEmail",
      title: "EMAIL",
      sorter: (a, b) => a?.primaryEmail?.localeCompare(b?.primaryEmail),
      width: 150,
    },
    // {
    //   dataIndex: "",
    //   title: "Actions",
    //   width: 200,
    //   headerAlign: "left",
    //   csvExport: false,
    //   align: "left",
    //   render: (cell, row) => {
    //     return (
    //       <div
    //         style={{ color: userConfig.secondary, cursor: "pointer" }}
    //         onClick={() => openCourseDetails(row.id, row.firstName)}
    //       >
    //         View Course Details
    //       </div>
    //     );
    //   },
    // },
  ];
  const defaultSorted = [
    {
      dataField: "firstName",
      text: "",
      order: "asc",
    },
  ];

  const activeRowStyle = (row, rowIndex) => {
    const style = { backgroundColor: "#ffffff" };
    return style;
  };
  const openCourseDetails = (userId, firstName) => {
    history.push(
      `/Manager/MyTeam/CourseDetails/${userId}?firstName=${firstName}`
    );
  };

  useEffect(() => {
    getAllTeamMembers();
  }, []);

  useEffect(() => {
    getUserCourseInsight();
  }, [userId]);

  const getUserCourseInsight = async () => {
    // TODO check if this API call is needed here
    // try {
    //   let { data } = await axios(
    //     `${config.endPoint.commonService}/stats/courseStats/${userId}`
    //   );
    //   let { overallStats, elearningCourseStats, ilCourseStats } =
    //     data?.data || {};
    //   setAllCourseStats(overallStats);
    //   setILCourseStats(ilCourseStats);
    //   setELCourseStats(elearningCourseStats);
    // } catch (error) {
    //   try {
    //     setAlert && setAlert(error?.response?.data?.message);
    //   } catch (e) {
    //     setAlert && setAlert("Something went wrong, Please try again.");
    //   } finally {
    //     setAlertType && setAlertType("error");
    //   }
    // }
  };

  const getAllTeamMembers = () => {
    setShowLoader(true);
    axios
      .get(`${config.endPoint.userService}/api/user/getMyTeam`)
      .then((response) => {
        setShowLoader(false);
        setListData(response?.data?.data);
        setTableData(response?.data?.data);
        setPageCount(response?.data?.data?.length);
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );

        setAlertType("error");
      });
  };

  const CSVColumns = activeCourseColumns.map((header, index) => ({
    key: header.dataIndex,
    label: header.title,
  }));

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const formatListData = (userList) => {
    return userList.map((user) => {
      return { ...user, Department: user?.Department?.name };
    });
  };

  const tableHeader = (
    <div className="myteam-header">
      <Row style={{ alignItems: "center" }}>
        <Col lg={10}>
          <Typography className="myteam-pageTitle">
            My Team Members
            <span
              style={{
                fontSize: "0.9rem",
                fontFamily: "RakutenRoundRegular",
              }}
            >
              ({showLoader ? <Spin /> : pageCount})
            </span>
          </Typography>
        </Col>
        <Col lg={9}></Col>
        {/* <Col lg={4}></Col> */}

        <Col lg={2} style={{ paddingLeft: isMobile ? "4rem" : "3rem" }}>
          <CSVLink
            headers={CSVColumns}
            data={formatListData(listData)}
            filename="My_Team"
            extension=".csv"
            disabled={listData.length < 1}
          >
            <div
              style={{
                fontSize: 20,
                background: "white",
                padding: ".425rem .7rem",
                borderRadius: "50%",
                marginRight: "1rem",
                cursor: listData.length < 1 ? "not-allowed" : "pointer",
              }}
              disabled={listData.length < 1}
            >
              <DownloadOutlined />
            </div>
          </CSVLink>
        </Col>
        <Col lg={2} style={{ marginTop: "-1.5rem" }}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"myteamSearch"}
          />
        </Col>
      </Row>
    </div>
  );

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setPageLoading(false);

        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  //   const renderDownloadButton = () => {
  //     const columns = Object.keys(listData[0] || {}).map((key) => ({
  //       key: key,
  //       label: key,
  //     }));

  //     return (
  //       <CSVLink
  //         headers={columns}
  //         data={listData}
  //         filename="My_Team"
  //         extension=".csv"
  //         disabled={listData.length < 1}
  //       >
  //         <div
  //           style={{
  //             fontSize: 20,
  //             background: "white",
  //             padding: ".425rem .7rem",
  //             borderRadius: "50%",
  //             marginRight: "1rem",
  //             cursor: listData.length < 1 ? "not-allowed" : "pointer",
  //           }}
  //           disabled={listData.length < 1}
  //         >
  //           <DownloadOutlined />
  //         </div>
  //       </CSVLink>
  //     );
  //   };
  const expandRow = {
    expandIcon: ({ expanded, onExpand, record }) => (
      <img
        style={
          expanded
            ? { transform: "rotate(180deg)", cursor: "pointer" }
            : { cursor: "pointer" }
        }
        src={expanded ? toggleBlueIcon : toggleIcon}
        alt={expanded ? "close" : "open"}
        onClick={(e) => onExpand(record, e)}
      />
    ),

    expandedRowRender: (record) => {
      return <AntdUserCourseInsights stats={record.id} />;
    },
  };

  return (
    <div className="myteam-container">
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
        className={"myteam-alert"}
      />
      <Breadcrumb className={"myteam-crumbs"} items={crumbs} />

      {tableHeader}
      <div className={"myteam-table"}>
        <Table
          rowKey="id"
          loading={showLoader}
          data={listData}
          rowStyle={activeRowStyle}
          columns={activeCourseColumns}
          dataSource={listData}
          expandable={{
            ...expandRow,
            expandIconColumnIndex: activeCourseColumns.length,
          }}
          pagination={{
            position: [bottom],
            showQuickJumper: true,
          }}
        />
      </div>
    </div>
  );
}

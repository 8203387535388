import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Table,
  Spin,
  Typography,
  Tabs,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import axios from "axios";
import config from "../../../config";
import GetTableMetaData from "./AntdtableMetaData";
import { CourseContext } from "../../../context/CourseContext";
import MyApprovalConfirmationModal from "./MyApprovalConfirmationModal";
import {
  DownloadOutlined,
  SearchOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { UserContext } from "../../../context/UserContext";
import dayjs from "dayjs";

export default function MyApprovals() {
  const { userDateFormat } = useContext(CourseContext);
  const history = useHistory();
  const location = useLocation();
  const dataTableRef = useRef();
  const [tableData, setTableData] = useState([]);
  const [approvalType, setApprovalType] = useState("1");
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showEnrollConfimation, setShowEnrollConfirmation] = useState(false);
  const [rowForPendingUser, setRowForPendingUser] = useState({});
  const [declineUser, setDeclineUser] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tableColumns, setTableColumns] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const { TabPane } = Tabs;
  const { userConfig } = useContext(UserContext);
  const isMobile = window.innerWidth < 786;

  const [listData, setListData] = useState([]);
  const [bottom, setBottom] = useState("bottomCenter");

  const filterMenu = [
    { name: `Pending Approval`, value: "1" },
    { name: "Approved", value: "2" },
    { name: "Rejected", value: "3" },
  ];

  const crumbs = [
    {
      title: (
        <a href="/home" style={{ color: userConfig.primary }}>
          Home
        </a>
      ),
    },
    {
      title: (
        <a href="/manager" style={{ color: userConfig.primary }}>
          Manager Dashboard
        </a>
      ),
    },
    {
      title: "My Approval",
    },
  ];
  const downloadButtonStyle = {
    position: "relative",
    top: "-8rem",
    left: "60rem",
  };

  const tableSearchStyle = {
    position: "relative",
    top: "-5.25rem",
    left: "65rem",
    width: "17rem",
  };

  const defaultSorted = [
    {
      dataIndex: "timeStamp",
      order: "asc",
    },
  ];

  const actionHandler = (data, type = "enroll") => {
    setRowForPendingUser(data);
    setShowEnrollConfirmation(true);
    if (type === "enroll") setDeclineUser(false);
    if (type === "reject") setDeclineUser(true);
  };

  const [tableColMeta, nonPendingTableColMeta] = GetTableMetaData(
    actionHandler,
    userDateFormat
  );

  useEffect(() => {
    getApprovalData(approvalType);
    // eslint-disable-next-line
  }, [approvalType]);

  useEffect(() => {
    if (approvalType === "1") {
      setTableColumns(tableColMeta);
    } else {
      setTableColumns(nonPendingTableColMeta);
    }
    // eslint-disable-next-line
  }, [approvalType]);

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const getApprovalData = async (type) => {
    setShowLoader(false);
    setTableData([]);
    setListData([]);
    setPageCount(0);
    let url = "";
    if (type === "1") url = `${config.endPoint.instructorLedNew}/approvals`;
    if (type === "2")
      url = `${config.endPoint.instructorLedNew}/approvals?status=Approved`;
    if (type === "3")
      url = `${config.endPoint.instructorLedNew}/approvals?status=Rejected`;
    try {
      let { data } = await axios.get(url);

      let tabledata = data?.data || [];
      tabledata = tabledata.map((val, idx) => {
        return {
          ...val,
          id: `${type}-${val.userId}-${val.courseId}-${idx}`,
          key: `${type}-${val.userId}-${val.courseId}-${idx}`,
        };
      });

      setTableData(tabledata);
      setListData(tabledata);
      setPageCount(tabledata.length);
      setApprovalType(type);
    } catch (error) {
      setAlert(
        error?.response?.data?.message ||
          "Something went wrong, Please try again."
      );
      setAlertType("error");
    }
  };

  const onPageFilterChange = (val) => {
    setTableData([]);
    setListData([]);
    getApprovalData(val);
  };

  const toggleFilter = () => {
    const filters = [
      { name: "Pending Approval", key: "1" },
      { name: "Approved", key: "2" },
      { name: "Rejected", key: "3" },
    ];
    return filters.map((filter) => {
      return filter.key === approvalType ? (
        <span
          style={{
            color: userConfig.primary,
            margin: 8,
            cursor: "pointer",
            fontFamily: "RakutenRoundRegular",
            fontSize: "0.9rem",
          }}
        >
          <CheckOutlined
            style={{ fontSize: "0.9rem", marginRight: "0.1rem" }}
          />
          {filter.name}
        </span>
      ) : (
        <span
          onClick={() => setApprovalType(filter.key)}
          style={{
            margin: 8,
            cursor: "pointer",
            fontFamily: "RakutenRoundRegular",
            fontSize: "0.9rem",
          }}
        >
          {filter.name}
        </span>
      );
    });
  };

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );

    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const getFileName = (approvalType) => {
    if (approvalType === "1") return "my_pending_approvals.csv";
    if (approvalType === "2") return "my_approved_approvals.csv";
    if (approvalType === "3") return "my_rejected_approvals.csv";
  };

  const handleCloseEnrollConfirmation = () => {
    setShowEnrollConfirmation(false);
  };

  const handleEnrollUserAccepted = async () => {
    try {
      await axios.post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "approve",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      });
      setAlert(
        `${rowForPendingUser.userName}'s enrollment request accepted for course ${rowForPendingUser.courseName}!`
      );
      setAlertType("success");
      setShowEnrollConfirmation(false);
      getApprovalData(approvalType);
    } catch (error) {
      setShowEnrollConfirmation(false);
      try {
        setAlert(error?.response?.data?.message);
      } catch (e) {
        setAlert("Something went wrong, Please try again.");
      } finally {
        setAlertType("error");
        handleCloseEnrollConfirmation();
      }
    }
  };

  const handleRejectUserNextSession = async () => {
    try {
      await axios.post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "nextSession",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      });
      setAlert(
        `${rowForPendingUser.userName}'s enrollment request rejected and requested for next session for course ${rowForPendingUser.courseName}!`
      );
      setAlertType("success");
      setShowEnrollConfirmation(false);
      getApprovalData(approvalType);
    } catch (error) {
      setShowEnrollConfirmation(false);
      try {
        setAlert(error?.response?.data?.message);
      } catch (e) {
        setAlert("Something went wrong, Please try again.");
      } finally {
        setAlertType("error");
        handleCloseEnrollConfirmation();
      }
    }
  };

  const handleRejectUserRequest = async () => {
    try {
      await axios.post(`approvals/course/${rowForPendingUser.courseId}`, {
        action: "removeCourse",
        user_id: rowForPendingUser.userId,
        session_id: Number(rowForPendingUser.batchId),
      });
      setAlert(
        `${rowForPendingUser.userName}'s enrollment request rejected and removed from course ${rowForPendingUser.courseName}!`
      );
      setAlertType("success");
      setShowEnrollConfirmation(false);
      getApprovalData(approvalType);
    } catch (error) {
      setShowEnrollConfirmation(false);
      try {
        setAlert(error?.response?.data?.message);
      } catch (e) {
        setAlert("Something went wrong, Please try again.");
      } finally {
        setAlertType("error");
        handleCloseEnrollConfirmation();
      }
    }
  };

  const CSVColumns = tableColMeta.map((header, index) => ({
    key: header.dataIndex,
    label: header.title,
  }));

  const getDataToDownload = (listData) => {
    return listData.map((data) => {
      return {
        ...data,
        batchStartTime: dayjs(data.batchStartTime).format("DD/MM/YYYY hh:mm A"),
        batchEndTime: dayjs(data.batchEndTime).format("DD/MM/YYYY hh:mm A"),
        enrolmentDate: dayjs(data.enrolmentDate).format("DD/MM/YYYY hh:mm A"),
      };
    });
  };

  const tableHeader = (
    <Row className="align-items-center">
      <Col lg={5} xs={4}>
        <Typography className="Myapproval-pageTitle">
          My Approval
          <span
            style={{
              fontSize: "0.9rem",
              fontFamily: "RakutenRoundRegular",
            }}
          >
            ({showLoader ? <Spin /> : pageCount})
          </span>
        </Typography>
      </Col>

      {!isMobile && <Col lg={8} xs={8}></Col>}
      <Col lg={6}>{toggleFilter()}</Col>

      <Col lg={1} xs={1}>
        <CSVLink
          filename={getFileName(approvalType)}
          extension=".csv"
          wrapColumnChar=""
          headers={CSVColumns}
          data={getDataToDownload(listData)}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<DownloadOutlined />}
            size="large"
            style={{
              backgroundColor: "white",
              color: "black",
              fontSize: "1.5rem",
              marginTop: "1rem",
            }}
          />
        </CSVLink>
      </Col>
      <Col lg={4} xs={4}>
        <Input
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="search"
          suffix={<SearchOutlined />}
          className={"notificationSearch"}
          style={{
            marginLeft: "1rem",
            marginTop: "1rem",
            width: " 10rem",
            fontSize: "1rem",
            fontFamily: "RakutenRoundRegular",
            borderRadius: "4px",
          }}
        />
      </Col>
    </Row>
  );

  const acceptUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Approve </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>
      ?
    </h3>
  );

  const declineUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Reject </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>
      ?
    </h3>
  );

  const acceptUserModalFooter = (
    <p style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <br></br>* Once approved user will get a confirmed seat in the session.
      For any changes to enrollment you will have to reach out to the L&D team.
    </p>
  );

  return (
    <div className="Myapproval-container1">
      <div className="Myapproval-container">
        <Row className="justify-content-center">
          <Col>
            <Alert
              message={alert}
              type={alertType}
              className={"myapproval-alert"}
              onCloseAlert={() => setAlert("")}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <Breadcrumb items={crumbs} />
            {tableHeader}
            <div className={"myapproval-Table"}>
              <Table
                rowKey="id"
                loading={showLoader}
                columns={tableColumns}
                dataSource={listData}
                pagination={{
                  position: [bottom],
                  showQuickJumper: true,
                }}
              />
            </div>
            {showEnrollConfimation && (
              <MyApprovalConfirmationModal
                toggle={true}
                title={declineUser ? declineUserModal : acceptUserModal}
                show={showEnrollConfimation}
                leftButton={declineUser ? "REQUEST FOR NEXT SESSION" : "YES"}
                rightButton={declineUser ? "REJECT ENROLLMENT REQUEST" : "NO"}
                handleClose={handleCloseEnrollConfirmation}
                rightButtonClick={
                  declineUser
                    ? handleRejectUserRequest
                    : handleCloseEnrollConfirmation
                }
                leftButtonClick={
                  declineUser
                    ? handleRejectUserNextSession
                    : handleEnrollUserAccepted
                }
                modalFooterContent={declineUser ? "" : acceptUserModalFooter}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import TimeFormaters from "../../utils/timeFormatter";
import style from "./upcomingCoursesTile.module.css";
import InstructorLedCourse from "./../../assets/icons/InstructorLedCourse.svg";
import courseProviderIcon from "./../../assets/icons/courseProviderIcon.svg";
import { decodeHtmlEntities } from "./../../utils/commonUtility";
import BookmarkIcon from "./../../assets/icons/bookmark_icon_black.png";
import BlackBookmarkIcon from "./../../assets/icons/bookmark_icon_black_filled.png";
import { UserContext } from "../../context/UserContext";
import config from "./../../config";
import axios from "axios";
import { CourseContext } from "../../context/CourseContext";

function UpcomingCoursesTile(props) {
  const {
    courseId,
    courseName,
    startDate,
    endDate,
    duration,
    language,
    provider,
    image,
  } = props;
  const [startEndDates, setStartEndDates] = useState("");
  const { userId } = useContext(UserContext);
  const { userDateFormat } = useContext(CourseContext);
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    if (startDate && endDate) {
      if (startDate === "Coming Soon" || endDate === "Coming Soon") {
        setStartEndDates("Coming Soon");
      } else {
        const sDate = TimeFormaters.FormatDate(startDate, null, userDateFormat);
        const eDate = TimeFormaters.FormatDate(endDate, null, userDateFormat);
        if (sDate === eDate) {
          setStartEndDates(sDate);
        } else {
          setStartEndDates(`${sDate} - ${eDate}`);
        }
      }
    }
    // eslint-disable-next-line
  }, [userDateFormat]);

  const addFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: "IL",
          entityId: courseId,
        },
      ])
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteFavourite = async (userId, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=IL&courseId=${courseId}`;
    await axios.delete(endPoint).catch((error) => {
      console.log(error);
    });
  };
  const changeStatus = () => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, courseId);
    } else {
      setIsFavourite(true);
      addFavourite(userId, courseId);
    }
  };

  return (
    <>
      <div className={style.tile}>
        <Link to={`/course-details/${courseId}`}>
          <div>
            <img
              className={style.subheadingImage}
              src={InstructorLedCourse}
              alt="Intructor Led"
            />
            <span className={style.subheadingText}>COURSE</span>
            <span
              onClick={(e) => {
                e.preventDefault();
                changeStatus();
              }}
              className={style.heartImage}
            >
              <img
                src={isFavourite ? BlackBookmarkIcon : BookmarkIcon}
                alt={isFavourite ? "Bookmarked" : "Not Bookmarked"}
                className={style.favouriteIcon}
              />
            </span>
          </div>
          <Row>
            <Col>
              <span className={style.cardTitle}>
                {decodeHtmlEntities(courseName)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className={style.content}>
                {startEndDates}
                <br />
                {duration &&
                  TimeFormaters.decimalHrsToHrsMin(duration, "detailed")}{" "}
                / {language}
              </div>
              <div className={style.provider}>
                <img
                  className={style.courseProviderIcon}
                  src={courseProviderIcon}
                  alt="provider"
                />
                <span title={provider}>{provider}</span>
              </div>
            </Col>
            <Col lg={6}>
              <div
                className={style.tileImage}
                style={{
                  backgroundImage: `url(${image})`,
                }}
              ></div>
            </Col>
          </Row>
        </Link>
      </div>
    </>
  );
}

UpcomingCoursesTile.propTypes = {
  courseId: PropTypes.number.isRequired,
  courseName: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  endDate: PropTypes.string.isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  language: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default UpcomingCoursesTile;

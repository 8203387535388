import { useEffect, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "antd";

import SliderWithPaginationProgressBar from "../../../../component-library/SliderWithPaginationProgressBar/SliderWithPaginationProgressBar";
import LearningPathCard from "../../../../component-library/antDesignLearningPathCard/LearningPathCard";
import TabbedFilter from "../../../../component-library/antDesignTabbedFilter/antDesignTabbedFilter";
import { CourseContext } from "../../../../context/CourseContext";
import "./antDesignLearningPathHomeComponent.css";
import config from "../../../../config";
import { chain } from "lodash";

function LearningPathHomeComponent({ userConfig }) {
  const { primary } = userConfig;
  const [learningPathList, setLearninPathList] = useState([]);
  const [selectedCatagory, setselectedCatagory] = useState(0);

  const { parentCatagoryList } = useContext(CourseContext);

  const fetchLearningPaths = useCallback(() => {
    const GET_LEARNING_PATHS_API_ENDPOINT =
      config.endPoint.commonService + "/learningpath";
    const axiosConfig = {
      headers: { isadminpage: false },
      params: {
        learningPathStatus: "active",
        pageNumber: 1,
        pageSize: 12,
        parentCategoryId: selectedCatagory || null,
      },
    };

    // TODO Move API call to api file
    axios
      .get(GET_LEARNING_PATHS_API_ENDPOINT, axiosConfig)
      .then((response) => {
        const data = response?.data?.data;
        setLearninPathList(() => data);
      })
      .catch((err) => {
        //need to handle error with notifications
      });
  }, [selectedCatagory]);

  useEffect(() => {
    fetchLearningPaths();
  }, [fetchLearningPaths]);

  const learningPaths = learningPathList?.map((current) => (
    <Col span={12}>
      <LearningPathCard
        key={`learning-path-${current.id}`}
        currentLearningPath={current}
        color="#f7f7f7;"
      ></LearningPathCard>
    </Col>
  ));

  const learningPathListForMobile = chain(learningPathList)
    .slice(0, 6)
    .chunk(2)
    .value();

  return (
    <>
      <Row justify="space-between" className="learningpath-home-heading-row">
        <Col>
          <span className={"learningpath-home-heading"}>Learning Paths</span>
        </Col>
        <Col>
          <Link
            className={"learningpath-page-link"}
            to={{
              pathname: "/LearningPath",
              state: { selectedCatagory: Number(selectedCatagory) },
            }}
          >
            <span style={{ color: primary }}>View All</span>
          </Link>
        </Col>
      </Row>
      <Row gutter={[24, 40]}>
        <Col span={24}>
          <TabbedFilter
            handleCatagoryChange={(key) => setselectedCatagory(key)}
            catagories={parentCatagoryList}
            defaultTab={selectedCatagory}
            tabBarGutter={window.innerWidth > 768 ? 92 : 40}
          ></TabbedFilter>
        </Col>
      </Row>
      <Row>
        {learningPathList.length > 0 ? (
          window.innerWidth > 768 ? (
            <SliderWithPaginationProgressBar
              itemCount={learningPathList.length || 0}
              filter={selectedCatagory}
            >
              {learningPaths}
            </SliderWithPaginationProgressBar>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "22rem",
                overflow: "hidden",
                overflowX: "scroll",
              }}
            >
              {learningPathListForMobile?.map((current) => (
                <div
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                >
                  {current[0] && (
                    <div>
                      <LearningPathCard
                        key={`learning-path-${current[0].id}`}
                        currentLearningPath={current[0]}
                        color="#f7f7f7;"
                      ></LearningPathCard>
                    </div>
                  )}
                  {current[1] && (
                    <div style={{ marginLeft: 8 }}>
                      <LearningPathCard
                        key={`learning-path-${current[1].id}`}
                        currentLearningPath={current[1]}
                        color="#f7f7f7;"
                      ></LearningPathCard>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )
        ) : (
          <div style={{ paddingTop: 24, paddingBottom: 24 }}>
            <span
              style={{ fontSize: "1.25rem", fontFamily: "RakutenRoundRegular" }}
            >
              No Learning paths found
            </span>
          </div>
        )}
      </Row>
    </>
  );
}

export default LearningPathHomeComponent;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./primaryButton.scss";

function PrimaryButton({
  label,
  handleClick,
  icon,
  otherStyle,
  isDisabled,
  isPrivate,
}) {
  return (
    <>
      {/* disabled property when added is always true. Hence it has to be conditionally added to the button */}
      {isDisabled && (
        <Button
          type="button"
          bsPrefix={`${"primaryButton"} ${otherStyle}`}
          onClick={handleClick}
          className={
            isPrivate ? `${"primaryPrivateButton"} ${otherStyle}` : `${"primaryPublicButton"} ${otherStyle}`
          }
          disabled
        >
          {icon && (
            <>
              <span className={"iconStyle"}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}{" "}
      {!isDisabled && (
        <Button
          bsPrefix={`${"primaryButton"} ${otherStyle}`}
          type="button"
          onClick={handleClick}
          className={
            isPrivate ? `${"primaryPrivateButton"} ${otherStyle}` : `${"primaryPublicButton"} ${otherStyle}`
          }
        >
          {icon && (
            <>
              <span className={"iconStyle"}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}
    </>
  );
}

PrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  otherStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

export default PrimaryButton;

import AddCompany from "./AddCompany";
import AddDepartment from "./AddDepartment";
import AddEmployee from "./AddEmployee";

export const getModalConfig = (
  tabItem,
  setIsModalOpen,
  updateTableData,
  isModalOpen
) => {
  let modalConfig;
  switch (tabItem) {
    case "emp":
      modalConfig = {
        title: "Add Employee",
        body: (
          <AddEmployee
            setIsModalOpen={setIsModalOpen}
            updateTableData={updateTableData}
            isModalOpen={isModalOpen}
          />
        ),
      };
      break;

    case "department":
      modalConfig = {
        title: "Add Department",
        body: (
          <AddDepartment
            setIsModalOpen={setIsModalOpen}
            updateTableData={updateTableData}
          />
        ),
      };
      break;

    case "companyList":
      modalConfig = {
        title: "Add Company",
        body: (
          <AddCompany
            setIsModalOpen={setIsModalOpen}
            updateTableData={updateTableData}
          />
        ),
      };
      break;

    default:
      break;
  }

  return modalConfig;
};

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Breadcrumb,
  Button,
  Input,
  Table,
  Tabs,
  Alert,
  Spin,
  Modal,
  Tooltip,
  AutoComplete,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  RightCircleOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import toggleIcon from "../../../assets/icons/toggle_down_icon.svg";
import { CourseContext } from "../../../context/CourseContext";
import { AdminPageSubTitle } from "../../../component-library";
import WaitlistConfirmationModal from "./WaitlistConfirmationModal";
import ImportUserHelper from "../../../component-library/importAddUserModal/ImportUserHelper";
import config from "../../../config";
import TimeFormatter from "../../../utils/timeFormatter";
import CountCard from "./CountCard";
import CourseDetailTable from "./CourseDetailTable";
import "./waitlistUsersList.scss";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { flatten, uniqBy } from "lodash";
import TextArea from "antd/es/input/TextArea";
import { upperFirst } from "lodash";

function WaitlistUsersList(props) {
  const { userDateFormat } = useContext(CourseContext);
  const [courseId] = useState(props.match.params.course_id);
  const [courseData, setCourseData] = useState("");
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [modalLabel, setModalLabel] = useState("Confirmed");
  const [showDeleteConfimation, setShowDeleteConfirmation] = useState(false);
  const [showEnrolModal, setshowEnrolModal] = useState(false);
  const [enrolUserData, setEnrolUserData] = useState({});
  const [enrolPayload, setEnrolPayload] = useState({});
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [userCourseHistory, setUserCourseHistory] = useState("");
  const [bottom, setBottom] = useState("bottomCenter");
  const [csvFileName, setCsvFileName] = useState("");
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [currentNotes, setCurrentNotes] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  const [notesSuggestions, setNotesSuggestions] = useState([]);
  const { TabPane } = Tabs;
  const employeeStatus = ["Active", "Inactive"];

  const tableSearchData = ({
    dataIndex,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          confirm();
        }}
        style={{
          width: "10rem",
          fontFamily: "RakutenRoundRegular",
          marginBottom: 8,
          display: "block",
        }}
      />
      <button
        type="button"
        style={{
          width: "5.5rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          confirm();
        }}
      >
        Search
      </button>
      <button
        type="button"
        style={{
          width: "4rem",
          marginLeft: "0.2rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          clearFilters();
          confirm();
        }}
      >
        Reset
      </button>
    </div>
  );

  const waitlistUsersColumns = [
    {
      dataIndex: "userId",
      title: "EMP ID".toUpperCase(),
      sort: true,
      width: 110,
      sorter: (a, b) => a.userId - b.userId,
      render: (cell, row) => {
        return (
          <>
            <div style={{ marginLeft: "0.5rem" }}>{`${row.userId}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "userName",
      title: "Name & Email".toUpperCase(),
      csvText: "Name",
      filterSearch: true,
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "userName" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const userName = String(record.userName).toLowerCase();
        const userEmail = String(record.userEmail).toLowerCase();
        const filterValue = String(value).toLowerCase();
        return (
          userName.includes(filterValue) || userEmail.includes(filterValue)
        );
      },
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      width: 130,
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "userEmail",
      title: "Email".toUpperCase(),
      sorter: (a, b) => a.userEmail.localeCompare(b.userEmail),
      width: 150,
      hidden: true,
    },
    {
      dataIndex: "role",
      title: "Role".toUpperCase(),
      sorter: (a, b) => a.role?.localeCompare(b.role),
      width: 120,
      sort: true,
    },
    {
      dataIndex: "country",
      title: "country".toUpperCase(),
      sort: true,
      width: 80,
      sorter: (a, b) => a.country.localeCompare(b.country),
    },
    {
      dataIndex: "department",
      title: "Department".toUpperCase(),
      width: 120,
      sort: true,
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      dataIndex: "timeStamp",
      title: "Enrolment Date".toUpperCase(),
      sort: true,
      sorter: (a, b) => a.timeStamp - b.timeStamp,
      width: 120,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "notes",
      title: "Notes".toUpperCase(),
      sorter: (a, b) => a.notes.localeCompare(b.notes),
      sort: true,
      width: 120,
      render: (notes, record) => notesComponent(notes, record),
    },
    {
      dataIndex: "status",
      title: "Employment Status".toUpperCase(),
      fixed: "right",
      filterSearch: true,
      onFilter: (value, record) =>
        upperFirst(String(record.status)).startsWith(upperFirst(String(value))),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sort: true,
      width: 120,
      render: (cell, row) => {
        return (
          <>
            <div>{upperFirst(`${row.status}`)}</div>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const interestedUsersColumns = [
    {
      dataIndex: "userId",
      title: "EMP ID".toUpperCase(),
      sorter: (a, b) => a.userId - b.userId,
      sort: true,
      headerStyle: {
        width: "8rem",
        height: "4rem",
        paddingLeft: "1rem",
      },
      render: (cell, row) => {
        return (
          <>
            <div style={{ marginLeft: "0.5rem" }}>{`${row.userId}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "userName",
      title: "Name & Email".toUpperCase(),
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sort: true,
      headerStyle: {
        width: "15rem",
      },
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "country",
      title: "country".toUpperCase(),
      sorter: (a, b) => a.country.localeCompare(b.country),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataIndex: "department",
      title: "Department".toUpperCase(),
      sorter: (a, b) => a.department.localeCompare(b.department),
      sort: true,
      headerStyle: {
        width: "13rem",
      },
    },
    {
      dataIndex: "userEmail",
      title: "Email".toUpperCase(),
      sorter: (a, b) => a.userEmail.localeCompare(b.userEmail),
      headerStyle: {
        width: "0rem",
        padding: "0rem",
      },
      render: (cell, row) => {
        return <>{}</>;
      },
    },
    {
      dataIndex: "timeStamp",
      title: "Enrolment Date".toUpperCase(),
      sorter: (a, b) => a.timeStamp - b.timeStamp,
      sort: true,
      headerStyle: {
        width: "12rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Employment Status".toUpperCase(),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sort: true,
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.status).startsWith(String(value)),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      align: "center",
      headerStyle: {
        width: "13rem",
      },
      render: (cell, row) => {
        return (
          <>
            <div style={{ marginLeft: "-2rem" }}>{`${row.status}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "dataField",
      title: "Action".toUpperCase(),
      sort: false,
      csvExport: false,
      align: "left",
      render: (cell, row) => {
        return (
          <>
            <span>
              <Button
                type="button"
                className={"waitlist-enrollButton"}
                icon={<RightCircleOutlined />}
                onClick={(event) => {
                  openEnrolConfirmationModal(row);
                }}
              >
                Enroll
              </Button>
            </span>
          </>
        );
      },
    },
  ];

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
    },
    {
      title: (
        <a href="/Admin/Courses" className="common-breadcrumbs-color">
          Instructor-led Courses
        </a>
      ),
    },
    {
      title: "Waitlist",
    },
  ];

  const [tableColumns, settableColumns] = useState(waitlistUsersColumns);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line
  }, [userDateFormat]);

  const getCourseData = () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/course/${courseId}`)
      .then((response) => {
        setCourseData(response.data.data);

        getWaitlistUsersData(response.data.data.identity_id);
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const getWaitlistUsersData = (identityId) => {
    settableColumns(waitlistUsersColumns);
    setIsActive(true);
    setCsvFileName(identityId + "_confirmed_users");
    setShowLoader(true);
    axios
      .get(`admin/view?course_id=${courseId}&status_type=waitlist`, {
        baseURL: process.env.REACT_APP_INSTRUCTOR_LED_API_URL,
      })
      .then((response) => {
        setListData(response.data.data);
        const suggestions = response.data.data
          .map((item) => item.notes)
          .filter(Boolean);
        setNotesSuggestions(suggestions);
        setShowLoader(false);
        setTableData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
        }
      });
  };

  const getInterestedUsersData = () => {
    settableColumns(interestedUsersColumns);
    setCsvFileName(courseData?.identity_id + "_interested_users");
    setIsActive(true);
    setShowLoader(true);
    axios
      .get(`/admin/view?course_id=${courseId}&status_type=interested`, {
        baseURL: process.env.REACT_APP_INSTRUCTOR_LED_API_URL,
      })
      .then((response) => {
        setListData(response.data.data);
        setTableData(response.data.data);
        setShowLoader(false);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
        }
      });
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const page = "course";

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAddUserModal(false);
  }

  const onAddUsers = () => {
    setShowAddUserModal(true);
  };
  // end of code for user import modal

  const onDeleteUsers = () => {
    if (isActive) {
      deleteWaitlistUsers(selectedUsers);
      setModalLabel("Confirmed");
    } else {
      deleteInterestedUsers(selectedUsers);
      setModalLabel("Interested");
    }
  };

  const onDeleteRequest = () => {
    const deletedUsers = selectedUsers.map((user) => user.userEmail);
    if (isActive) {
      axios
        .post("admin/bulk/unenroll/course/", {
          id: Number(courseId),
          email_list: deletedUsers,
        })
        .then((res) => {
          setAlert("Selected Users deleted successfully from the waitlist!");
          setAlertType("success");
          setShowAlert(true);
          handleClose();
          onPageFilterChange("1");
          setSelectedUsers([]);
        })
        .catch((error) => {
          try {
            setAlert(error.response.data.message);
          } catch (e) {
            setAlert("Something went wrong, Please try again.");
          } finally {
            setShowAlert(true);
            setAlertType("error");
            handleClose();
          }
        });
    } else {
      // TODO: make API call for deleting intersted users. API currently unavailable
    }
  };

  const deleteWaitlistUsers = (selectedUsers) => {
    if (selectedUsers.length > 0) {
      setShowDeleteConfirmation(true);
    } else {
      setAlert("Please select users who have to be deleted from waitlist");
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const deleteInterestedUsers = () => {};

  const onPageFilterChange = (value) => {
    setListData([]);
    switch (value) {
      case "1":
        setModalLabel("Confirmed");
        setCsvFileName(courseData?.identity_id + "_confirmed_users");
        getWaitlistUsersData(courseData?.identity_id);
        break;
      case "2":
        setModalLabel("Interested");
        setCsvFileName(courseData?.identity_id + "_interested_users");
        getInterestedUsersData();
        break;
      default:
        setModalLabel("Confirmed");
        setCsvFileName(courseData?.identity_id + "_confirmed_users");
        getWaitlistUsersData(courseData?.identity_id);
        break;
    }
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
  };

  const handleEnrolClose = () => {
    setSelectedUsers([]);
    setshowEnrolModal(false);
    setEnrolUserData({});
    setEnrolPayload({});
    // dataTableRef.current.selectionContext.selected = [];
    // dataTableRef.current.selectionContext.handleAllRowsSelect(null, true);
  };

  const openEnrolConfirmationModal = (row) => {
    const payload = {
      course_id: Number(courseId),
      user_id: row.userId,
    };
    setEnrolUserData(row);
    setEnrolPayload(payload);
    setshowEnrolModal(true);
  };

  const onEnrolInterestSubmit = () => {
    axios
      .post("admin/enroll-interested", enrolPayload)
      .then((res) => {
        setAlert("Users successfully enrolled to the upcoming session!");
        setAlertType("success");
        setShowAlert(true);
        handleEnrolClose();
        onPageFilterChange("1");
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          onPageFilterChange("2");
          handleEnrolClose();
        }
      });
  };

  const handleNotesClick = (notes, record) => {
    setCurrentNotes(notes);
    setCurrentRecord(record);
    setShowNotesModal(true);
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      updated_timestamp: dayjs
        .unix(data.updated_timestamp)
        .format("DD/MM/YYYY HH:mm:ss"),
      timeStamp: dayjs.unix(data.timeStamp).format("DD/MM/YYYY HH:mm:ss"),
      name: data.userName,
      email: data.userEmail,
    }));
  };

  const notesComponent = (notes, record) => {
    return notes && notes !== "" ? (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <Tooltip
          title={`Last Updated on ${TimeFormatter.FormatTableTime(
            record.notesUpdateTimestamp,
            null,
            userDateFormat
          )}`}
        >
          <p
            className={"tooltipContent"}
            onClick={() => handleNotesClick(notes, record)}
          >
            {notes}
          </p>
        </Tooltip>
      </div>
    ) : (
      <div style={{ display: "flex" }}>
        <Tooltip
          title={
            record.notesUpdateTimestamp
              ? `Last Updated on ${TimeFormatter.FormatTableTime(
                  record.notesUpdateTimestamp,
                  null,
                  userDateFormat
                )}`
              : null
          }
        >
          <Button
            className={"tooltipContent"}
            type="ghost"
            onClick={() => handleNotesClick(notes, record)}
          >
            -
          </Button>
        </Tooltip>
      </div>
    );
  };

  const handleNotesSubmit = () => {
    if (currentNotes.trim() === currentRecord.notes.trim()) {
      setShowNotesModal(false);
      return;
    }
    if (currentNotes.length > 8000) {
      setShowNotesModal(false);
      setAlert("Notes Length Exceeded!");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    let body = currentRecord;
    body.notes = currentNotes.trim();
    body.notesUpdateTimestamp = Math.floor(Date.now() / 1000);
    axios
      .put(`${process.env.REACT_APP_INSTRUCTOR_LED_API_URL}/admin/update`, body)
      .then(() => {
        setNotesSuggestions([...notesSuggestions, currentNotes.trim()]);
        setShowNotesModal(false);
        setAlert("Notes Updated Successfully!");
        setAlertType("success");
        setShowAlert(true);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setShowAlert(true);
          setAlertType("error");
        }
      });
  };

  const notesModal = (
    <Modal
      title="Notes"
      open={showNotesModal}
      onOk={handleNotesSubmit}
      onCancel={() => setShowNotesModal(false)}
      okText="Submit"
    >
      <AutoComplete
        value={currentNotes}
        onChange={(value) => setCurrentNotes(value)}
        style={{ width: "100%", minHeight: "150px", border: "none" }}
        dataSource={currentNotes ? notesSuggestions : []}
        placeholder="Type here..."
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
        <TextArea
          style={{ width: "100%", minHeight: "150px", border: "none" }}
          value={currentNotes}
          onChange={(e) => setCurrentNotes(e.target.value)}
          placeholder="Type here..."
        />
      </AutoComplete>
    </Modal>
  );

  const modalContent = (
    <>
      <Row>
        <Col className={"modalContentStyle"}>
          <p>
            Are you sure you want to delete following users from the{" "}
            {modalLabel} waitlist?
          </p>
          <ul>
            {uniqBy(selectedUsers, "userEmail")?.map((user) => {
              return <li>{user.userEmail}</li>;
            })}
          </ul>
        </Col>
      </Row>
    </>
  );

  const modalEnrolContent = (
    <>
      <Row>
        <Col className={"modalContentStyle"}>
          <p>
            Are you sure you want to enrol the following interested user to the
            course {courseData.course_name}?
          </p>
          <ul>
            {enrolUserData && enrolUserData.userEmail && (
              <li>{enrolUserData.userEmail}</li>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(e) => {
          setSelectedUsers([]);
          onPageFilterChange(e);
          setSelectedRowKeys([]);
        }}
        className={"waitlist-tabs"}
      >
        <TabPane tab="Confirmed" key="1"></TabPane>
        <TabPane tab="Interested" key="2"></TabPane>
      </Tabs>
    );
  };
  // const CSVColumns = tableColumns.map((header, index) => ({
  //   key: header.dataIndex,
  //   label: header.title,
  // }));
  const CSVColumns = tableColumns.map((header, index) => {
    if (header.dataIndex === "userName") {
      return [
        {
          key: "name",
          label: "Name",
        },
        { key: "email", label: "Email" },
      ];
    }
    return {
      key: header.dataIndex,
      label: header.title,
    };
  });

  // TimeFormatter.FormatTableTime(cell, null, userDateFormat)
  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const tableHeader = (
    <>
      <Row className={"waitingList-tableHeader"}>
        <Col lg={6}>
          <div className="waitingList-pageTitle">
            Waitlist{" "}
            <span
              style={{ fontSize: "0.9rem", fontFamily: "RakutenRoundRegular" }}
            >
              ({showLoader ? <Spin /> : pageCount})
            </span>
          </div>
        </Col>
        <Col lg={4}>{toggleFilter()}</Col>
        <Col lg={1}></Col>
        <Col lg={3}>
          {selectedUsers.length ? (
            <Button
              type="button"
              className={"waitList-deleteButton"}
              onClick={onDeleteUsers}
              disabled={selectedUsers.length === 0}
            >
              Delete
            </Button>
          ) : null}
        </Col>
        <Col lg={1}></Col>
        <Col lg={2}>
          <CSVLink
            filename={csvFileName}
            extension=".csv"
            wrapColumnChar=""
            headers={flatten(CSVColumns)}
            data={getDataToDownload(listData)}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "1.5rem",
              }}
            />
          </CSVLink>
        </Col>
        <Col lg={3}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              width: " 9rem",
              fontSize: "1.1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
        <Col lg={4}>
          <Button
            type="button"
            icon={<PlusCircleOutlined />}
            className={"waitList-addNewCourse"}
            onClick={onAddUsers}
          >
            ADD USERS
          </Button>
        </Col>
      </Row>
      <Row className={"waitlist-subTitle"}>
        <AdminPageSubTitle
          courseId={courseData && courseData.identity_id}
          courseName={courseData && courseData.course_name}
          otherStyle={"subTitle"}
        />
      </Row>
    </>
  );

  const selectRow = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedUsers([...selectedUsers, row]);
      } else {
        const unSelectedUser = selectedUsers.filter(
          (user) => user.userEmail !== row.userEmail
        );
        setSelectedUsers(unSelectedUser);
      }
    },

    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        setSelectedUsers([...selectedUsers, ...rows]);
      } else {
        const updateUsersList = selectedUsers.filter((user) => {
          return (
            rows.findIndex((row) => row.userEmail === user.userEmail) === -1
          );
        });
        setSelectedUsers([]);
      }
    },
  };

  const fetchAllCourseDetailByUserId = async (userId) => {
    const {
      data: { data: instructorLedCourseDetails },
    } = await axios.get(`/user-history/${userId}?status=completed,pending`);
    const {
      data: { data: elearningCourseDetails },
    } = await axios.get(
      `${config.endPoint.elearning}/history/${userId}?status=completed,started,assigned`
    );
    return {
      instructorLedCourseDetails,
      elearningCourseDetails,
    };
  };

  const expandRow = {
    expandedRowRender: () => (
      <>
        {(userCourseHistory &&
          userCourseHistory.instructorLedCourseDetails.courseDetails.length +
            userCourseHistory.elearningCourseDetails.courseDetails.length >
            0 && (
            <Row>
              <Col className={"expandRowContainer"}>
                <CountCard
                  title="TOTAL LEARNING HOURS"
                  count={
                    (userCourseHistory?.instructorLedCourseDetails
                      ?.totalDuration || 0) +
                    (userCourseHistory?.elearningCourseDetails?.statusCount
                      ?.totalDuration || 0)
                  }
                  titleColor="#000000"
                  countColor="#000000"
                />
                <CountCard
                  title="TOTAL COURSE COMPLETED"
                  count={
                    (userCourseHistory?.instructorLedCourseDetails
                      ?.totalCompleted || 0) +
                    (userCourseHistory?.elearningCourseDetails?.statusCount
                      ?.completed || 0)
                  }
                  titleColor="#37B900"
                  countColor="#37B900"
                />
                <CountCard
                  title="TOTAL COURSE NOT COMPLETED"
                  count={
                    (userCourseHistory?.instructorLedCourseDetails
                      ?.totalPending || 0) +
                    (userCourseHistory?.elearningCourseDetails?.statusCount
                      ?.started || 0)
                  }
                  titleColor="#BF0000"
                  countColor="#BF0000"
                />
              </Col>
              <Col>
                {(userCourseHistory && (
                  <CourseDetailTable data={userCourseHistory} />
                )) ||
                  ""}
              </Col>
            </Row>
          )) || (
          <Row>
            <Col className={"message"}>User has not taken a course yet</Col>
          </Row>
        )}
      </>
    ),
    onExpand: async (row) => {
      const userId = row.userId;
      const data = await fetchAllCourseDetailByUserId(userId);
      setUserCourseHistory(data);
    },
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return null;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <img className={"toggleExpandButton"} src={toggleIcon} alt="close" />
        );
      }
      return <img src={toggleIcon} alt="open" />;
    },
  };

  return (
    <>
      {notesModal}
      <div
        style={{
          marginTop: "5rem",
          marginLeft: "3.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            onCloseAlert={() => setAlert("")}
            className={"admin-alert"}
          />
        )}
        <Breadcrumb className={"Waitlist-crumbs"} items={crumbs} />
        {tableHeader}
        <div className="waitingList-Table">
          {
            <Table
              scroll={{
                x: 1300,
              }}
              loading={showLoader}
              rowKey="userEmail"
              dataSource={listData}
              columns={tableColumns}
              rowSelection={selectRow}
              expandable={expandRow}
              pagination={{
                position: [bottom],
                showQuickJumper: true,
              }}
            />
          }
        </div>
      </div>
      {showDeleteConfimation && (
        <WaitlistConfirmationModal
          toggle={true}
          title="Delete"
          titleBold="Course Users"
          show={showDeleteConfimation}
          leftButton="YES"
          rightButton="NO"
          handleClose={handleClose}
          rightButtonClick={handleClose}
          leftButtonClick={onDeleteRequest}
          modalContent={modalContent}
        />
      )}

      {(showAddUserModal || showErrorModal) && (
        <ImportUserHelper
          showAddUserModal={showAddUserModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          handleCloseErrorModal={handleCloseErrorModal}
          page={page}
          courseId={courseId}
          setAlert={setAlert}
          setShowAlert={setShowAlert}
          setAlertType={setAlertType}
          updatePage={getCourseData}
          openErrorCloseAddUser={openErrorCloseAddUser}
        />
      )}
      {showEnrolModal && (
        <WaitlistConfirmationModal
          toggle={true}
          title="Enroll"
          titleBold="Interested Users"
          show={showEnrolModal}
          leftButton="YES"
          rightButton="NO"
          handleClose={handleEnrolClose}
          rightButtonClick={handleEnrolClose}
          leftButtonClick={onEnrolInterestSubmit}
          modalContent={modalEnrolContent}
        />
      )}
    </>
  );
}

export default WaitlistUsersList;

import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router";
import PendingApprovalModal from "../../../component-library/pendingApprovalModal/PendingApprovalModal";
import {
  HeroBanner,
  LearningBySkills,
  Quotation,
} from "./../../../component-library";
import CoursesInProgress from "./coursesInProgress/CoursesInProgress";
import ElearningHomeComponent from "./elearningHomeComponent/ElearningHomeComponent";
import ELearningPlatform from "./eLearningPlatform/ELearningPlatform";
import FillingFast from "./fillingFast/FillingFast";
import style from "./Home.module.css";
import InstructorLedHomePage from "./instructorLedHomePage/InstructorLedHomePage";
import LearningPathHomeComponent from "./learningPathHomeComponent/antDesignLearningPathHomeComponent";
import { Layout } from "antd";
import "./Home.scss";
import PageFooter from "../common/PageFooter";
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";

function Home() {
  const [pageLoading, setPageLoading] = useState(true);
  const [courseData, setcourseData] = useState([]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [nonPromotionalBannerText, setNonPromotionalBannerText] = useState("");
  const [approvalCount, setApprovalCount] = useState(0);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const handleClose = () => setShowApprovalModal(false);
  const { userConfig } = useContext(UserContext);

  const history = useHistory();

  const leftButtonClick = () => {
    handleClose();
  };
  const rightButtonClick = () => {
    setShowApprovalModal(!showApprovalModal);
    handleClose();
    history.push({
      pathname: `/my-approval`,
    });
  };
  async function fetchCourseData() {
    try {
      const result = await axios.get(`${config.endPoint.instructorLedNew}/course/user?c_status=Enrolled`);
      if (result.data.success) {
        setPageLoading(false);
        result.data.data.forEach((course) => {
          if (
            course.batch_data.batch_start !== "Coming Soon" &&
            courseData.length < 2
          ) {
            const tempData = {};
            tempData.id = course.id;
            tempData.courseName = course.name;
            tempData.sessionStart = course.batch_data.batch_start;
            tempData.image = course.image;
            setcourseData([...courseData, tempData]);
          }
        });
      }
    } catch (error) {
      setAlert(`${error}`);
      setAlertType("danger");
    }
  }

  useEffect(() => {
    if (pageLoading) {
      // To avoid duplicate network call
      fetchCourseData();
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const pendingApprovals = () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/approvals`)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setShowApprovalModal(true);
          setApprovalCount(response?.data?.data?.length);
        }
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  useEffect(() => {
    pendingApprovals();
  }, []);

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroBanner
        upliftBannerText={(bannerText) =>
          setNonPromotionalBannerText(bannerText)
        }
      />

      <div style={{ backgroundColor: "#f7f7f7" }}>
        <Layout>
          {nonPromotionalBannerText && (
            <div
              className={style.nonPromotionalBannerTextLine}
              style={{ display: "none" }} // This should be fixed later
            >
              <div className={style.lineOverText}></div>
              {nonPromotionalBannerText}
            </div>
          )}

          <div
            className="enrollment-course-container"
            style={{ paddingBottom: "1rem" }}
          >
            <div className="coursesInProgress">
              <CoursesInProgress
                courseList={courseData}
                userConfig={userConfig}
              />
            </div>

            <div className={"fillingFast"}>
              <FillingFast
                setAlert={setAlert}
                setAlertType={setAlertType}
                userConfig={userConfig}
              />
            </div>
          </div>

          <div className="instructor-led-container">
            <InstructorLedHomePage
              setAlert={setAlert}
              setAlertType={setAlertType}
              userConfig={userConfig}
            />
          </div>

          <div className="elearning-container">
            <ElearningHomeComponent
              setAlert={setAlert}
              setAlertType={setAlertType}
              userConfig={userConfig}
            />
          </div>

          <div className="lp-container">
            <LearningPathHomeComponent
              setAlert={setAlert}
              setAlertType={setAlertType}
              userConfig={userConfig}
            />
          </div>
          <div className={"home-secondContainer"}>
            <LearningBySkills isFromHome={true} />
            <ELearningPlatform />
            <Quotation />
          </div>

          {window.innerWidth < 786 && <PageFooter />}
        </Layout>
      </div>
      {window.innerWidth > 786 && showApprovalModal && (
        <PendingApprovalModal
          show={showApprovalModal}
          handleClose={handleClose}
          leftButton="Cancel"
          leftButtonClick={leftButtonClick}
          rightButton="My Approval"
          rightButtonClick={rightButtonClick}
          approvalCount={approvalCount}
        />
      )}
    </div>
  );
}

export default Home;

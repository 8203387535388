import PropTypes from "prop-types";
import useDocumentTitle from "./DocumentTitle";
import { Route } from "react-router-dom";

const UserRoute = ({ title, ...rest }) => {
  useDocumentTitle(title);
  return <Route {...rest} />;
};

UserRoute.propTypes = {
  title: PropTypes.string,
};

export default UserRoute;

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Slider from "react-slick";
import {
  CourseTile,
  PaginationProgressBar,
} from "../../../../component-library";
import config from "../../../../config";
import ViewAllCourseTile from "../viewAllCourseTile/ViewAllCourseTile";
import "./../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./../../../../../node_modules/slick-carousel/slick/slick.css";

import "./instructorLed.scss";

function InstructorLedHomePage({ setAlert, setAlertType, userConfig }) {
  const { primary } = userConfig;
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesPerRow = window.innerWidth >= 1440 ? 4 : 3;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    slidesPerRow,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  const itemsPerPage = settings.rows * settings.slidesPerRow;

  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  const [gotCalendarData, setGotCalendarData] = useState(false);
  const [batchCalendarData, setBatchCalendarData] = useState([]);
  const pageSize = config.instructorLedHomePage.pageSize;
  const pageNumber = config.instructorLedHomePage.pageNumber;

  function fetchBatchCalendarData(currentCategory = null) {
    let params = {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
      },
    };
    if (currentCategory) {
      params = {
        params: { ...params.params, category_id: currentCategory.value },
      };
    }
    axios
      .get(`${config.endPoint.instructorLedNew}/course/list`, params)
      .then((res) => {
        setBatchCalendarData(res.data.data);
        setGotCalendarData(true);
      })
      .catch((error) => {
        setAlert(
          error.data.response || "Something went wrong, please try again"
        );
        setAlertType("danger");
      });
  }

  useEffect(() => {
    fetchBatchCalendarData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={"header-row"}>
        <span className={"heading"}>Instructor-Led Courses</span>
        <span className={"column"}>
          <a href="/Classroom_training/all/all">
            <label
              className=""
              style={{
                marginRight: "0.7rem",
                fontFamily: "RakutenRoundRegular",
                fontSize: "14px",
                lineHeight: "24px",
                color: primary,
                cursor: "pointer",
              }}
            >
              VIEW ALL
            </label>
          </a>
        </span>
      </div>
      {window.innerWidth > 768 ? (
        <Row>
          <Slider
            {...settings}
            ref={sliderRef}
            className={`courseslick  ${"slider"}`}
          >
            {(gotCalendarData &&
              batchCalendarData &&
              batchCalendarData.length &&
              batchCalendarData.map((data, index) => {
                return (
                  <span
                    key={`instructorLed ${index}`}
                    // className="card slider-card"
                    className="elearning-card"
                  >
                    <CourseTile
                      dataElement={data}
                      index={index}
                      coursePath={`/course-details/${data.id}`}
                      reload={fetchBatchCalendarData}
                    />
                  </span>
                );
              })) ||
              ""}
            <span
              style={{
                display: "inline-block",
                position: "absolute !important",
              }}
              className="last-slider"
            >
              <ViewAllCourseTile />
            </span>
          </Slider>
          <div className={"footer"}>
            {batchCalendarData && batchCalendarData.length > 0 && (
              <>
                <PaginationProgressBar
                  currentPage={currentSlide}
                  maxPages={Math.ceil(
                    (batchCalendarData.length + 1) / itemsPerPage
                  )}
                  maxWidth={4.3125}
                  prevHandler={gotoPrev}
                  nextHandler={gotoNext}
                  prevDisabled={currentSlide === 0}
                  nextDisabled={
                    currentSlide ===
                    Math.ceil((batchCalendarData.length + 1) / itemsPerPage) - 1
                  }
                  primary={userConfig.primary}
                />
              </>
            )}
          </div>
        </Row>
      ) : (
        <div className="il-courses-mobile">
          {batchCalendarData.map((data, index) => {
            return (
              <span key={`instructorLed ${index}`} className="mobile-card">
                <CourseTile
                  dataElement={data}
                  index={index}
                  coursePath={`/course-details/${data.id}`}
                  reload={fetchBatchCalendarData}
                />
              </span>
            );
          })}
          <span className={"card"}>
            <ViewAllCourseTile />
          </span>
        </div>
      )}
    </>
  );
}

export default InstructorLedHomePage;

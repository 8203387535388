import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  DatePicker,
  Input,
  Select,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
import { uniqBy } from "lodash";
import { getUserList } from "./employeeManagementApis";
import { UserContext } from "../../../context/UserContext";
import { FileUploader } from "../../../component-library";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { writeFileXLSX, utils } from "xlsx";

function AddEmployee({ setIsModalOpen, updateTableData, isModalOpen }) {
  const [employeeDetails, setEmployeeDetails] = useState({
    firstName: null,
    lastName: null,
    empID: null,
    email: null,
    country: null,
    company: null,
    dept: null,
    office: null,
    manager: null,
    empStatus: null,
    designationTitle: null,
    joinedDate: null,
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [departmentApiData, setDepartmentApiData] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const { userConfig } = useContext(UserContext);
  const [bulkData, setBulkData] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setEmployeeDetails({
      firstName: null,
      lastName: null,
      empID: null,
      email: null,
      country: null,
      company: null,
      dept: null,
      office: null,
      manager: {},
      empStatus: null,
      designationTitle: null,
      joinedDate: null,
    });
  }, [isModalOpen]);

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;

    axios
      .get(dataEndpoint)
      .then((res) => {
        const { data } = res.data;
        const { countries } = data;

        if (countries.length > 0) {
          setCountryOptions(countries);
        }

        getUserList().then((newUserList) => {
          setUserList(newUserList.data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (employeeDetails.empStatus === "2")
      setEmployeeDetails({ ...employeeDetails, joinedDate: null });
  }, [employeeDetails.empStatus]);

  useEffect(() => {
    const endpoint = `${config.endPoint.userService}/api/departments?pageSize=1000`;

    axios
      .get(endpoint)
      .then((res) => {
        const { data } = res.data;
        setDepartmentApiData(data);

        const departmentOptions = data.map(({ name, id }) => ({
          value: name,
          label: name,
          deptId: id,
        }));

        setDeptOptions(departmentOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const endpoint = `${config.endPoint.userService}/api/companies?pageSize=1000`;

    axios
      .get(endpoint)
      .then((res) => {
        const { data } = res.data;

        const companyOptions = data.map(({ name, id }) => ({
          value: name,
          label: name,
          companyID: id,
        }));

        setCompanyOptions(uniqBy(companyOptions, "value"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filteredDeptOptions = departmentApiData?.filter(
      (deptData) => deptData.company === employeeDetails.company
    );
    const mappedDeptOptions = filteredDeptOptions.map(({ name, id }) => ({
      value: name,
      label: name,
      deptId: id,
    }));

    setDeptOptions(mappedDeptOptions);
  }, [employeeDetails.company]);

  const handleInputChange = (key, value) => {
    setEmployeeDetails({ ...employeeDetails, [key]: value });
  };

  const renderInpuField = (label, key, placeholder) => {
    return (
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={placeholder}
          value={employeeDetails[key]}
          onChange={(e) => handleInputChange(key, e.target.value)}
          style={{ borderRadius: 0 }}
        />
      </div>
    );
  };

  const renderSelectField = (
    label,
    key,
    defaultValue,
    options,
    disabled = false
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          defaultValue={defaultValue}
          placeholder="Select..."
          disabled={disabled}
          style={{
            width: 120,
            borderRadius: 0,
          }}
          onChange={(value) => {
            let updatedEmployeeDetails = { ...employeeDetails, [key]: value };
            if (key === "company") {
              updatedEmployeeDetails["dept"] = null;
            }
            setEmployeeDetails(updatedEmployeeDetails);
          }}
          value={employeeDetails[key]}
          options={options}
        />
      </div>
    );
  };

  const getCountryOptions = () =>
    countryOptions.map((countryData) => ({
      value: countryData?.code,
      label: countryData?.country,
    }));

  const filterManagerSuggestion = (text) => {
    const tempUserList = [...userlist];

    const filteredUserList = tempUserList.filter(({ email = "" }) => {
      return email.toLowerCase().includes(text.toLowerCase());
    });

    const mappedManagerOptions = filteredUserList.map((user) => ({
      ...user,
      value: user.email,
      key: user.email,
    }));

    setManagerOptions(mappedManagerOptions);
  };

  const onAddSingleUser = async () => {
    const filteredDeptOptions = deptOptions.find((dep) => {
      return dep.value === employeeDetails.dept;
    });

    const filteredCompanyOptions = companyOptions.find((company) => {
      return company.value === employeeDetails.company;
    });

    const dataPayload = {
      empID: employeeDetails.empID,
      firstName: employeeDetails.firstName,
      lastName: employeeDetails.lastName,
      email: employeeDetails.email,
      country: employeeDetails.country,
      designationTitle: employeeDetails.designationTitle,
      officeLocationName: employeeDetails.office,
      joiningDate: employeeDetails.joinedDate?.dateStr,
      empStatus: employeeDetails.empStatus,
      managerID: employeeDetails.manager.id,
      departmentID: filteredDeptOptions.deptId,
      companyID: filteredCompanyOptions.companyID,
    };

    const endpoint = `${config.endPoint.userService}/api/user`;
    const addUserResponse = await axios.post(endpoint, dataPayload);

    if (addUserResponse.data.status) {
      // Add Notification
      openNotification("Employee Added Successfully");
      updateTableData();
    } else {
      // Add failure notification
    }
    setEmployeeDetails({
      firstName: null,
      lastName: null,
      empID: null,
      email: null,
      country: null,
      company: null,
      dept: null,
      office: null,
      manager: null,
      empStatus: null,
      designationTitle: null,
      joinedDate: null,
    });
    setIsModalOpen(false);
  };

  const onAddBulkUser = async () => {
    const formData = new FormData();
    formData.append("excelFile", bulkData);
    if (!bulkData) {
      // show notificaiton message
    } else {
      const bulkUploadEndpoint = `${config.endPoint.userService}/api/user/excel/upload`;
      axios
        .post(bulkUploadEndpoint, formData)
        .then((response) => {
          // handle success res
          if (response?.status) {
            openNotification("Users bulk upload successful");
            updateTableData();
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          // TODO: add notification
        });
    }
  };

  const isUserInputValid = () => {
    const {
      firstName,
      lastName,
      empID,
      email,
      empStatus,
      country,
      company,
      dept,
      office,
      manager,
      designationTitle,
      joinedDate,
    } = employeeDetails;

    return (
      firstName &&
      lastName &&
      empID &&
      email &&
      empStatus &&
      country &&
      company &&
      dept &&
      office &&
      manager &&
      manager?.name &&
      designationTitle &&
      joinedDate
    );
  };

  const disabledDate = (current) => {
    // employeeDetails.empStatus === "2"
    // Can not select days before today and today
    if (employeeDetails.empStatus === "2")
      return current && current < dayjs().endOf("day");

    return false;
  };

  const headerAssignUser = [
    { label: "Emp_ID", key: "empID" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email Id", key: "emailId" },
    { label: "Country", key: "country" },
    { label: "Company", key: "company" },
    { label: "Department", key: "department" },
    { label: "Office", key: "office" },
    { label: "Manager Email", key: "managerID" },
    { label: "Emp Type", key: "empType" },
    { label: "Emp status", key: "empStats" },
    { label: "EMP Role", key: "empRole" },
    { label: "Joined Date", key: "joinedDate" },
  ];

  const csvData = {
    data: [
      {
        Emp_ID: "1",
        "First Name": "Sachin",
        "Last Name": "Jaiswal",
        "Email Id": "da@raku.com",
        Country: "INDIA",
        Company: "company 2",
        Department: "sac",
        Office: "India",
        "Manager Email": "1",
        "Emp Type": "full time",
        "Emp status": "active",
        "EMP Role": "1",
        "Joined Date": "12 April 2024",
      },
    ],
    headers: headerAssignUser,
    filename: "bulk-upload.csv",
  };

  const openNotification = (notificationMessage) => {
    api.info({
      message: (
        <strong style={{ color: "black" }}>{notificationMessage}</strong>
      ),
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {contextHolder}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid lightgray",
            paddingRight: 8,
          }}
        >
          {renderInpuField("First Name", "firstName", "Enter first name...")}
          {renderInpuField("Last Name", "lastName", "Enter last name...")}
          {renderInpuField("Employee ID", "empID", "Enter Employee Id...")}
          {renderInpuField("Email ID", "email", "Enter Employee email Id...")}
          {renderSelectField("Country", "country", "JPN", getCountryOptions())}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "33%" }}>
              {renderSelectField(
                "Company",
                "company",
                undefined,
                companyOptions
              )}
            </div>
            <div style={{ width: "33%" }}>
              {renderSelectField(
                "Department",
                "dept",
                undefined,
                deptOptions,
                !employeeDetails.company
              )}
            </div>
            {renderInpuField("Office", "office", "Enter Office Address...")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 8,
              width: "90%",
            }}
          >
            <span style={{ fontSize: 12, color: "gray" }}>
              Manager
              <span style={{ color: "red" }}>*</span>
            </span>
            <AutoComplete
              options={uniqBy(managerOptions, "email")}
              style={{
                width: "100%",
              }}
              onSelect={(data, row) => {
                setEmployeeDetails({ ...employeeDetails, manager: row });
              }}
              onSearch={(text) => filterManagerSuggestion(text)}
              filterOption={(text, option) => {
                return option.email.toLowerCase().includes(text.toLowerCase());
              }}
              placeholder="Enter Manager Email.."
              value={employeeDetails?.manager?.value}
              onChange={(data, row) => {
                setEmployeeDetails({
                  ...employeeDetails,
                  manager: data,
                });
              }}
              onBlur={() => {
                if (!employeeDetails?.manager?.value)
                  setEmployeeDetails({
                    ...employeeDetails,
                    manager: {
                      value: "",
                      label: "",
                      key: "",
                    },
                  });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "90%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <span style={{ fontSize: 12, color: "gray" }}>
                Emp Status
                <span style={{ color: "red" }}>*</span>
              </span>
              <Select
                placeholder="Emp Status..."
                style={{
                  width: 120,
                }}
                onChange={(value) =>
                  setEmployeeDetails({ ...employeeDetails, empStatus: value })
                }
                options={[
                  {
                    value: "2",
                    label: "Pre Active",
                  },
                  {
                    value: "1",
                    label: "Active",
                  },
                  {
                    value: "0",
                    label: "Inactive",
                  },
                ]}
                value={employeeDetails.empStatus}
              />
            </div>

            {renderInpuField(
              "Emp Role",
              "designationTitle",
              "Enter Employee Designation..."
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 8,
              }}
            >
              <span style={{ fontSize: 12, color: "gray" }}>
                Hire Date
                <span style={{ color: "red" }}>*</span>
              </span>
              <DatePicker
                disabledDate={disabledDate}
                value={employeeDetails?.joinedDate?.dateObj}
                onChange={(date, dateStr) => {
                  setEmployeeDetails({
                    ...employeeDetails,
                    joinedDate: { dateObj: date, dateStr },
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <p>Import an Excel/CSV file</p>
            </div>
            <FileUploader
              fName=""
              upliftFileDetails={({ fileData, error }) => {
                if (error) {
                  console.log(error);
                } else {
                  setBulkData(fileData);
                }
              }}
              customStyle={{
                height: "200px",
              }}
              hint={
                <div>
                  <span>
                    For bulk edit, download the list from Employee table and
                    modify the data. Then upload here!
                  </span>
                  <p>
                    For bulk upload, please{" "}
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        let ws = utils.json_to_sheet(csvData.data);
                        let wb = utils.book_new();
                        utils.book_append_sheet(wb, ws, "user_list");
                        writeFileXLSX(wb, `user_list.xlsx`);
                      }}
                    >
                      download
                    </span>{" "}
                    excelsheet file
                  </p>
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button
          style={{ margin: 16, borderRadius: 0 }}
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ margin: 16, borderRadius: 0 }}
          type="primary"
          onClick={bulkData ? onAddBulkUser : onAddSingleUser}
          className="em-tab-button"
          disabled={bulkData ? false : !isUserInputValid()}
        >
          Add User
        </Button>
      </div>
    </div>
  );
}

export default AddEmployee;

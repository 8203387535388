import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./AdminPrimaryButton.scss";

function AdminPrimaryButton({
  label,
  handleClick,
  icon,
  otherStyle,
  isDisabled,
  isPrivate,
  customStyle,
}) {
  return (
    <>
      {/* disabled property when added is always true. Hence it has to be conditionally added to the button */}
      {isDisabled && (
        <Button
          type="button"
          bsPrefix={`${"adminPrimaryButton"} ${otherStyle}`}
          onClick={handleClick}
          className={
            isPrivate
              ? `${"adminPrimaryPrivateButton"}`
              : ` ${"adminPrimaryPublicButton"} ${customStyle}`
          }
          disabled
        >
          {icon && (
            <>
              <span className={"adminIconStyle"}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}{" "}
      {!isDisabled && (
        <Button
          bsPrefix={`${"adminPrimaryButton"} ${otherStyle}`}
          type="button"
          onClick={handleClick}
          className={
            isPrivate
              ? `${"adminPrimaryPrivateButton"}`
              : `${"adminPrimaryPublicButton"}`
          }
        >
          {icon && (
            <>
              <span className={"adminIconStyle"}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}
    </>
  );
}

AdminPrimaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  otherStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  isPrivate: PropTypes.bool,
};

export default AdminPrimaryButton;

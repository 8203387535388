import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import style from "./fileUploader.module.css";
import AntDesignPrimaryButton from "../antDesignButton/PrimaryButton/primaryButton";

const FileUploader = React.forwardRef(({
  fName,
  upliftFileDetails,
  customStyle,
  onlyCSV = false,
  hint,
  showFileName = true
}, ref) => {
  const [fileName, setFileName] = useState("");
  const inputFile = useRef(null);
  const [clearForm, setClearForm] = useState(false);

  useEffect(() => {
    if (fName) setFileName(fName);
  }, [fName]);

  useImperativeHandle(ref, () => ({
    clearFile() {
      clearFile()
    }
  }))

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const clearFile = () => {
    document.getElementById("file").value = "";
    setClearForm(false);
    upliftFileDetails({});
  }
  
  async function handleUpload(e) {
    e.preventDefault();
    const fileData =
      (e?.target?.files && e.target.files.length && e.target.files[0]) ||
      (e?.dataTransfer?.files &&
        e.dataTransfer.files.length &&
        e?.dataTransfer?.files[0]);

    if (fileData) {
      const fileExtension = fileData.name.split(".").pop();
      if (onlyCSV && fileExtension !== "csv") {
        upliftFileDetails({ error: "Only csv file uploads allowed!" });
      } else if (!["xlsx", "csv"].includes(fileExtension)) {
        upliftFileDetails({ error: "Only csv and xlsx file uploads allowed!" });
      } else {
        setFileName(fileData.name);
        upliftFileDetails({ fileData });
      }
    }
    setClearForm(true);
  }

  return (
    <div
      className={style.outerBox}
      style={customStyle}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      onDrop={handleUpload}
    >
      <div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          className={style.inputButton}
          onChange={handleUpload}
          hidden={true}
          accept={onlyCSV ? ".csv" : ".xlsx , .csv"}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AntDesignPrimaryButton
            className={style.primryButton}
            onClick={onButtonClick}
            text={"BROWSE"}
          >
          </AntDesignPrimaryButton>
        </div>

        <div>
          <p className={style.hint}>{hint}</p>
          <div>
            {clearForm && showFileName && (
              <p className={style.fileName}>
                {fileName}
                <span
                  style={{ cursor: "pointer", color: "black" }}
                  onClick={clearFile}
                >
                  &nbsp;&nbsp; x
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
})

FileUploader.propTypes = {
  customStyle: PropTypes.any,
  fName: PropTypes.string,
  upliftFileDetails: PropTypes.func,
  onlyCSV: PropTypes.bool,
  hint: PropTypes.any,
};

export default FileUploader

import axios from "axios";
import config from "../../config";

export const getsessionDetails = async (courseId) => {
  const sessionDetailsEndpoint = `${config.endPoint.instructorLedNew}/batch/?course_id=${courseId}&active=1&need_schedule=true`;
  const rawSessionDetails = await axios.get(sessionDetailsEndpoint);
  const { data: sessionDetails } = rawSessionDetails?.data;

  return sessionDetails;
};

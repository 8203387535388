import React, { createContext, useEffect, useState } from "react";
import { getCookie, setCookie } from "../utils/cookies";
import axios from "axios";
import LandingPage from "../pages/landingPage/LandingPage.jsx";

const LoginContext = createContext();

const LoginContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    const refreshToken = getCookie("refreshToken");
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };
    // axios.defaults.withCredentials = true;

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  }, []);

  function delete_cookie(name) {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  const handleLogout = async () => {
    await axios.post(process.env.REACT_APP_AUTH_SERVICE_URL + "/auth/logout");
    delete_cookie("accessToken");
    delete_cookie("refreshToken");
    delete_cookie("connect.sid");
    setAccessToken(null);
    setRefreshToken(null);
    //window.history.replace("/home");
    window.history.replaceState({}, "", "/home");
    window.location.href = "/home";
  };

  return (
    <LoginContext.Provider
      value={{
        tokenData: accessToken,
        logout: handleLogout,
      }}
    >
      {accessToken && refreshToken ? children : <LandingPage />}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginContextProvider };

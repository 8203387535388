import { Col, Row, Typography } from "antd";
import DOMPurify from "dompurify";
import "./courseAdditionalDetails.css";

const CourseAdditionalDetails = ({
  course = {},
  overrideHeadingStyle = {},
  overrideParentContainer = "",
}) => {
  const {
    objectives,
    targetedAudience,
    prerequisites,
    topics,
    recommendations,
  } = course;
  const parentContainerClass = `course-additional-details ${overrideParentContainer}`;
  const isMobile = window.innerWidth < 786;

  const renderObjectives = () => {
    return (
      <Col>
        <Typography
          style={{ ...overrideHeadingStyle }}
          className="course-details-heading"
        >
          Objectives
        </Typography>
        {objectives ? (
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(objectives) }}
          />
        ) : (
          "N/A"
        )}
      </Col>
    );
  };

  const renderTargetedAudience = () => {
    return (
      <Col>
        <Typography
          style={{
            ...overrideHeadingStyle,
          }}
          className="course-details-heading"
        >
          Targeted Audience
        </Typography>
        {targetedAudience ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(targetedAudience),
            }}
          />
        ) : (
          "N/A"
        )}
      </Col>
    );
  };

  const renderPrerequisites = () => {
    return (
      <Col>
        <Typography
          style={{
            ...overrideHeadingStyle,
          }}
          className="course-details-heading"
        >
          Prerequisites
        </Typography>
        {prerequisites ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(prerequisites),
            }}
          />
        ) : (
          "N/A"
        )}
      </Col>
    );
  };

  return (
    <Row className={parentContainerClass}>
      <Col
        span={isMobile ? 24 : 12}
        className="course-details-child-container-lp"
      >
        {renderObjectives()}
      </Col>
      <Col
        span={isMobile ? 24 : 12}
        className="course-details-child-container-lp"
      >
        <Row span={isMobile ? 24 : 12} className="course-details-audience">
          {renderTargetedAudience()}
        </Row>
        <Row span={isMobile ? 24 : 12}>{renderPrerequisites()}</Row>
      </Col>
    </Row>
  );
};

export default CourseAdditionalDetails;

import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { Row } from "antd";
import config from "../../../config";
import { Breadcrumb, Alert } from "antd";
import TimeFormatter from "../../../utils/timeFormatter";
import UtilityFunctions from "../../../utils/utility";
import "./reportsfilter.scss";
import ReportsFilter from "./AntdReportFilters";
import CourseStats from "./CourseStats";
import CategoryStats from "./CategoryStats";

const crumbs = [
  {
    title: (
      <a href="/home" className="common-breadcrumbs-color">
        Home
      </a>
    ),
    to: "/home",
  },
  {
    title: (
      <a href="/Manager" className="common-breadcrumbs-color">
        Manager Dashboard
      </a>
    ),
    to: "/Manager",
  },
  {
    title: "Team Reports",
  },
];

const getStatsData = async (type, startDate, endDate, region) => {
  let url = "";
  let urlParams = {
    startDate: startDate && moment(startDate + " 00:00").unix(),
    endDate: endDate && moment(endDate + " 23:59").unix(),
    region: region?.label === "All region" ? "" : region,
  };
  if (type === "teamStats")
    url = `${config.endPoint.commonService}/team/teamStats`;
  if (type === "ELCatStats")
    url = `${config.endPoint.elearning}/stats/getCategoryReport`;
  if (type === "ILCatStats")
    url = `${config.endPoint.instructorLedNew}/admin/manager/getCategoryReport`;
  try {
    let { data } = await axios.get(url, { params: urlParams });
    return data?.data || {};
  } catch (error) {
    throw error;
  }
};

const TeamReport = () => {
  const [region, setRegion] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    moment().add(1, "M").format("DD-MM-YYYY")
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [ILStats, setILStats] = useState({});
  const [ELStats, setELStats] = useState({});
  const [overAllStats, setOverAllStats] = useState({});
  const [ELCatData, setELCatData] = useState();
  const [ILCatData, setILCatData] = useState();

  useEffect(() => {
    setInitalData();
  }, []);

  const setInitalData = () => {
    setRegion({ label: "All region", value: "" });

    const currentDateTimestamp = TimeFormatter.getCurrentTimeStamp();
    const currentEndDate =
      TimeFormatter.HTMLDateInputFormat(currentDateTimestamp);
    const currentStartDate = TimeFormatter.HTMLDateInputFormat(
      currentDateTimestamp - 30 * 24 * 60 * 60
    );
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);

    getStatsData("teamStats", currentStartDate, currentEndDate, "")
      .then((res) => {
        let { ilStats, elearningStats, overallStats } = res;
        setILStats(ilStats);
        setELStats(elearningStats);
        setOverAllStats(overallStats);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });

    getStatsData("ELCatStats", currentStartDate, currentEndDate, "")
      .then((res) => {
        setELCatData(res);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });

    getStatsData("ILCatStats", currentStartDate, currentEndDate, "")
      .then((res) => {
        setILCatData(res);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });
  };

  const regionChangeHandler = (reg) => {
    setRegion(reg);
  };

  const startDateChangeHandler = (date) => {
    setStartDate(date);
  };

  const endDateChangeHandler = (date) => {
    setEndDate(date);
  };

  const applyHandler = () => {
    if (!startDate) {
      setShowAlert(true);
      setAlertType("error");
      setAlert("Start Date Not selected");
      return;
    }

    if (!endDate) {
      setShowAlert(true);
      setAlertType("error");
      setAlert("End Date Not Selected");
      return;
    }

    if (moment(startDate).isAfter(moment(endDate))) {
      setShowAlert(true);
      setAlertType("error");
      setAlert("Start Date can't be greater than End Date");
      return;
    }

    if (!region) {
      setAlertType("error");
      setAlert("Region(s) not selected");
      return;
    }

    setAlert("");
    setAlertType("");

    getStatsData("teamStats", startDate, endDate, region.value)
      .then((res) => {
        let { ilStats, elearningStats, overallStats } = res;
        setILStats(ilStats);
        setELStats(elearningStats);
        setOverAllStats(overallStats);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });

    getStatsData("ELCatStats", startDate, endDate, region)
      .then((res) => {
        setELCatData(res);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });

    getStatsData("ILCatStats", startDate, endDate, region)
      .then((res) => {
        setILCatData(res);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(error?.response?.data?.message || "some thing went wrong");
      });
  };

  const resetHandler = () => {
    setInitalData();
  };

  const downloadReport = async () => {
    let urlParams = {
      startDate: startDate && moment(startDate + " 00:00").unix(),
      endDate: endDate && moment(endDate + " 23:59").unix(),
      region: region.value,
    };
    const endPoint = `${config.endPoint.commonService}/team/teamStatsForDownload`;
    axios
      .get(endPoint, { params: urlParams })
      .then((response) => {
        UtilityFunctions.downloadCSVFile(
          response,
          `my_team_reports_${startDate}_${endDate}_${region?.label || ""}.csv`
        );
      })
      .catch((error) => {
        try {
          setShowAlert(true);
          setAlert(error.response.data.message);
        } catch (e) {
          setShowAlert(true);
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const alertCloseHandler = () => {
    setAlert("");
  };

  return (
    <div className="myteamreport">
      <div className="myteamreport-container">
        {showAlert && (
          <Alert
            message={alert}
            type={alertType}
            onCloseAlert={alertCloseHandler}
          />
        )}
        <Breadcrumb className="myteam-breadcrumbs" items={crumbs} />
        <ReportsFilter
          startDate={startDate}
          endDate={endDate}
          region={region}
          onStartDateChange={startDateChangeHandler}
          onEndDateChange={endDateChangeHandler}
          onRegionChange={regionChangeHandler}
          onApply={applyHandler}
          onReset={resetHandler}
          onDownloadReport={downloadReport}
        />
        <Row className="myreporttile-container">
          <CourseStats
            overAllStats={overAllStats}
            ILStats={ILStats}
            ELStats={ELStats}
          />
        </Row>
        <Row className="teamreports-categoryStats">
          <CategoryStats ELData={ELCatData} ILData={ILCatData} />
        </Row>
      </div>
    </div>
  );
};

export default TeamReport;

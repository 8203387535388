import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Breadcrumb, Typography } from "antd";
import { Alert, Button, Select, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import ProgressBar from "react-bootstrap/ProgressBar";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import ReportUtility from "./reportUtility";
import UtilityFunctions from "../../../utils/utility";
import TimeFormaters from "../../../utils/timeFormatter";
import config from "../../../config";
import ReportTile from "./ReportTile/ReportTile";
import { Chart } from "react-google-charts";
import "./AntdReport.scss";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="common-breadcrumbs-color">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  {
    title: "Dashboard",
  },
];

dayjs.extend(customParseFormat);

export default function Report() {
  const [size, setSize] = useState("large");
  const reportUtilityObj = ReportUtility();
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [reportCountData, setReportCountData] = useState([]);
  const [trainerData, setTrainerData] = useState("");
  const [categoryData, setCategoryData] = useState(["Category", "Value"]);
  const [regionWiseData, setRegionWiseData] = useState([]);
  const [attendanceData, setAttendanceData] = useState("");
  const [totalCategoryCourseCount, setTotalCategoryCourseCount] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [region, setRegion] = useState({ label: "All region", value: "" });
  const [showCategoryChart, setShowCategoryChart] = useState(true);
  const [showRegionChart, setShowRegionChart] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [dateString, setDateString] = useState({ startDate: "", endDate: "" });

  const [reportParam, setReportParam] = useState({});

  const getAttendanceReport = () => {
    return axios
      .get(`${config.endPoint.instructorLedNew}/reports/attendanceReport`, {
        params: reportParam,
      })
      .then((response) => {
        setAttendanceData(response?.data?.data);
      })

      .catch((error) => {
        console.log("err", error);
      });
  };
  const getCourseReport = () => {
    return axios
      .get(`${config.endPoint.instructorLedNew}/reports/courseReport`, {
        params: reportParam,
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const getTrainerReport = () => {
    return axios
      .get(`${config.endPoint.instructorLedNew}/reports/trainerReport`, {
        params: reportParam,
      })
      .then((response) => {
        setTrainerData(response.data.data);
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const getLearnersReport = () => {
    return axios
      .get(`${config.endPoint.instructorLedNew}/reports/learnerReport`, {
        params: reportParam,
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const getReportTilesData = () => {
    return axios
      .all([getCourseReport(), getLearnersReport()])
      .then(function (response) {
        let responseArray = [];
        let courseReport = response[0].data.data;
        let learnersReport = response[1].data.data;
        responseArray.push({
          label: "Courses",
          value: courseReport.courseCount,
        });
        responseArray.push({
          label: "Sessions",
          value: courseReport.sessionCount,
        });
        responseArray.push({
          label: "Enrolled",
          value: courseReport.enrolledUsersCount,
        });
        responseArray.push({
          label: "Unique Learners",
          value: learnersReport.uniqueLearners,
        });
        responseArray.push({
          label: "Scheduled Learners",
          value: learnersReport.scheduledLearners,
        });
        responseArray.push({
          label: "Courses Archived",
          value: courseReport.archivedCourseCount,
        });
        responseArray.push({
          label: "Sessions Active",
          value: courseReport.activeSessionCount,
        });
        responseArray.push({
          label: "Waitlist",
          value: courseReport.waitlistedUsersCount,
        });

        setReportCountData(responseArray);
      })
      .catch((error) => {
        setAlert("Something went wrong, Please try again.");
        setAlertType("error");
      })
      .finally(() => {
        getCategoryData();
      });
  };
  const getCategoryData = () => {
    let arr = [["Category", "Value"]];
    setShowCategoryChart(false);
    axios
      .get(`${config.endPoint.instructorLedNew}/reports/categoryReport`, {
        params: reportParam,
      })
      .then((response) => {
        const result = response.data.data.categoryData;
        result.forEach((c) => {
          arr.push([c.categoryName, c.categoryCourseCount]);
        });
        setTotalCategoryCourseCount(
          response.data.data.totalCategoryCourseCount
        );
        setCategoryData(arr);
        setShowCategoryChart(true);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error.message ||
            error.response.data.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const loadPage = () => {
    getReportTilesData();
  };
  const pieoptions = {
    pieHole: 0.5,
    showLables: "true",
    elements: {
      center: {
        text: "90%",
        color: "#FF6384",
        fontStyle: "Arial",
        sidePadding: 20,
      },
    },
  };

  const getRegionReports = () => {
    return axios
      .get(`${config.endPoint.instructorLedNew}/reports/regionReport`, {
        params: reportParam,
      })
      .then((response) => {
        createRegionReportData(response?.data?.data);
      })
      .catch((error) => {
        throw error;
      });
  };

  const prepareCountryArr = (data) => {
    const countryArr = ["Country"];
    for (const region in data) {
      for (const country in data[region]) {
        if (!countryArr.includes(country)) {
          countryArr.push(country);
        }
      }
    }
    return countryArr;
  };

  const prepareDataArr = (data, countries, type) => {
    const dataArr = [];
    if (type === "courseCreated") {
      dataArr.push("Courses");
    } else if (type === "sessionCreated") {
      dataArr.push("Sessions");
    } else if (type === "enrolledUsers") {
      dataArr.push("Enrolled");
    }
    const countryArr = [...countries];
    countryArr.shift();
    countryArr.map((country) => {
      if (data[country] && data[country][type]) {
        dataArr.push(data[country][type]);
      } else {
        dataArr.push(0);
      }
      return country;
    });
    return dataArr;
  };

  const createRegionReportData = (data) => {
    setShowRegionChart(false);
    const countryArr = prepareCountryArr(data);
    const courseArr = prepareDataArr(
      data?.courseCreatedForRegion,
      countryArr,
      "courseCreated"
    );
    const sessionArr = prepareDataArr(
      data?.sessionCreatedForRegion,
      countryArr,
      "sessionCreated"
    );
    const enrolledArr = prepareDataArr(
      data?.userEnrolledForRegion,
      countryArr,
      "enrolledUsers"
    );
    const region = [countryArr, courseArr, sessionArr, enrolledArr];
    setRegionWiseData(region);
    setShowRegionChart(true);
  };
  const regionWisepOptions = {
    title: "Region wise course/ session/ enrollment data",
    chartArea: { width: "75%" },
    isStacked: true,
    legend: { position: "top", maxLines: 3 },
    hAxis: {
      minValue: 0,
    },
    vAxis: {
      gridlines: {
        color: "transparent",
      },
      textPosition: "none",
    },
    series: {
      0: { color: "#00CEC9" },
      1: { color: "#74B9FF" },
      2: { color: "#A29BFE" },
    },
  };

  const exportReportData = () => {
    const currentTime = TimeFormaters.getCurrentTimeStamp();
    const startTimeStamp = startDate
      ? TimeFormaters.getUtcTimeStamp(startDate)
      : null;
    const endTimeStamp = endDate
      ? TimeFormaters.getUtcTimeStamp(endDate)
      : null;
    let startDateTime = startTimeStamp
      ? startTimeStamp
      : currentTime - 30 * 24 * 60 * 60;
    let endDateTime = endTimeStamp ? endTimeStamp : currentTime;
    axios
      .get(
        `${config.endPoint.instructorLedNew}/user/userHistory/${startDateTime}/${endDateTime}`
      )
      .then((response) => {
        const fileName = "IL_Course_Report.csv";
        UtilityFunctions.downloadCSVFile(response, fileName);
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setAlertType("error");
      });
  };
  const currentDateTimestamp = TimeFormaters.getCurrentTimeStamp();
  const currentEndDate =
    TimeFormaters.HTMLDateInputFormat(currentDateTimestamp);
  const currentStartDate = TimeFormaters.HTMLDateInputFormat(
    currentDateTimestamp - 30 * 24 * 60 * 60
  );

  useEffect(() => {
    const endPoint = `${config.endPoint.commonService}/data`;
    axios.get(endPoint).then((response) => {
      const countryOptions = [];
      response.data.data.countries.map((country, index) => {
        return (countryOptions[index] = {
          value: country.code,
          label: country.country,
        });
      });

      setCountryList([{ label: "All region", value: "" }, ...countryOptions]);
    });
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
    setDateString({ startDate: currentStartDate, endDate: currentEndDate });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTrainerReport();
    getAttendanceReport();
    reportUtilityObj.embedChart(loadPage);
    getRegionReports();
    // eslint-disable-next-line
  }, [reportParam]);

  const resetHandler = () => {
    setShowCategoryChart(false);
    setShowRegionChart(false);
    setReportParam({});

    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
    setRegion({ label: "All region", value: "" });
  };

  const handleStartDate = (startDate) => {
    if (endDate && startDate > endDate) {
      setErrorMessage({
        startDate: "Start Date cannot be greater than End Date",
      });
    } else {
      setErrorMessage({ startDate: "" });
    }
    setStartDate(startDate);
  };

  const handleEndDate = (endDate) => {
    if (startDate && endDate < startDate) {
      setErrorMessage({
        endDate: "End Date cannot be less than Start Date",
      });
    } else {
      setErrorMessage({ endDate: "" });
    }
    setEndDate(endDate);
  };

  const submitHandler = () => {
    if (!startDate) {
      setErrorMessage({ startDate: "Please select start date" });
    } else if (!endDate) {
      setErrorMessage({ endDate: "Please select end date" });
    } else {
      const startTimeStamp = dateString.startDate
        ? TimeFormaters.getUtcTimeStamp(dateString.startDate + " 00:00")
        : null;
      const endTimeStamp = dateString.endDate
        ? TimeFormaters.getUtcTimeStamp(dateString.endDate + " 23:59")
        : null;
      const regionVal = region?.value;
      setShowCategoryChart(false);
      setShowRegionChart(false);

      setReportParam({
        startDate: startTimeStamp,
        endDate: endTimeStamp,
        region: regionVal,
      });
    }
  };
  const minDateValue = "1990-01-01";
  const dateFormat = "DD/MM/YYYY";

  return (
    <div className="AntdILReports-container">
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
      />
      <div>
        <Breadcrumb
          style={{ fontFamily: "RakutenRoundRegular" }}
          items={crumbs}
        />
        <Typography className="ILReports-page-title">
          Instructor Led Course Reports
        </Typography>
      </div>
      <div className="AntdReport-Schedule-Container">
        <div>
          <div className="scheduleTime">Schedule Report</div>
        </div>
        <div>
          <DatePicker
            // format={dateFormat}
            allowClear={false}
            minValue={minDateValue}
            allowInput={true}
            clickOpens={true}
            value={dayjs(startDate)}
            onChange={(current, startDateString) => {
              setDateString({ ...dateString, startDate: startDateString });
              handleStartDate(current);
            }}
            style={{ height: "3rem" }}
            className="datePickerReport"
          />
          <div style={{ marginTop: "2.6rem" }}>
            {(errorMessage.startDate && (
              <div className="errorMessageDatePicker">
                {errorMessage.startDate}
              </div>
            )) ||
              ""}
          </div>
        </div>
        <div>
          <DatePicker
            // format={dateFormat}
            allowClear={false}
            minValue={minDateValue}
            allowInput={true}
            clickOpens={true}
            value={dayjs(endDate)}
            onChange={(current, endDateString) => {
              setDateString({ ...dateString, endDate: endDateString });
              handleEndDate(current);
            }}
            style={{ height: "3rem" }}
            className="datePickerReport"
          />
          <div style={{ marginTop: "2.6rem" }}>
            {(errorMessage.endDate && (
              <div className="errorMessageDatePicker">
                {errorMessage.endDate}
              </div>
            )) ||
              ""}
          </div>
        </div>
        <div style={{ width: "12rem" }}>
          <Select
            className="selectRegionReport"
            showSearch
            options={countryList}
            value={region}
            placeholder="All Region"
            onChange={setRegion}
          />
        </div>
        <div>
          <Button
            type="primary"
            className="reportApplyButton"
            onClick={submitHandler}
          >
            Apply
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            className="reportResetButton"
            onClick={resetHandler}
          >
            Reset
          </Button>
        </div>
        <div>
          <Button
            className="downloadCSVButton"
            type="primary"
            shape="circle"
            onClick={exportReportData}
            icon={<DownloadOutlined />}
            size="large"
          />
        </div>
      </div>
      <Row className="reportsTile">
        {reportCountData.map((currentData, i) => {
          return <ReportTile {...currentData} key={i} />;
        })}
      </Row>
      <Row>
        <Col lg={12} md="6" sm="12" className="pieChart">
          <div className="box">
            <p className="chartLabel"> ENROLLMENTS PER CATEGORY</p>
            <div className="chartContainer">
              {showCategoryChart && (
                <Chart
                  chartType="PieChart"
                  width="100%"
                  height="400px"
                  data={categoryData}
                  options={pieoptions}
                />
              )}
              <div className="donutWrapper">
                <div className="donutCenterText">
                  {" "}
                  {totalCategoryCourseCount}{" "}
                </div>
                <div className="donutCenterTextEntries"> Entries </div>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={12} md="6" sm="12" className="pieChart">
          <div className="box">
            <p className="chartLabel"> REGIONAL WISE CREATED</p>
            <div className="chartContainer">
              {regionWiseData &&
              regionWiseData[0] &&
              regionWiseData[0].length > 1 &&
              showRegionChart ? (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={regionWiseData}
                  options={regionWisepOptions}
                />
              ) : (
                <div className="donutWrapper">
                  <div className="donutCenterTextEntries">
                    {" "}
                    0 <br />
                    Entries
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]} className="marginTop3Reports">
        <Col lg={6}>
          <div className="tileContainer">
            <div className="tileLabel">100% attendance</div>
            <div className="attendanceText">
              {attendanceData?.attendanceReport?.sessionCountWithFullAttendance}
              <br />
              <Row>
                <ProgressBar
                  variant={"success"}
                  now={
                    attendanceData?.attendanceReport
                      ?.percentageSessionWithFullAttendance
                  }
                  className="progressBarStyle"
                />
                <span className="progressBarContent">
                  {
                    attendanceData?.attendanceReport
                      ?.percentageSessionWithFullAttendance
                  }
                  %{" "}
                </span>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="tileContainer">
            <div className="tileLabel">100% Enrolled Sessions</div>
            <div className="attendanceText">
              {attendanceData?.sessionReport?.completelyFullSessions}
              <br />
              <Row>
                <ProgressBar
                  variant={"success"}
                  now={attendanceData?.sessionReport?.percentageFullSession}
                  className="progressBarStyle"
                />
                <span className="progressBarContent">
                  {attendanceData?.sessionReport?.percentageFullSession}%{" "}
                </span>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <Row gutter={[16, 16]}>
            <Col lg={12}>
              <div className="tileContainer">
                <div className="tileLabel">Internal Trainers</div>
                <div className="tileValue">
                  {trainerData.internalTrainerCount}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="tileContainer">
                <div className="tileLabel">External Trainers</div>
                <div className="tileValue">
                  {trainerData.externalTrainerCount}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

import config from "../config";
import axios from "axios";

export const stringTOHTMLParser = (string) => {
  const parser = new DOMParser();
  const parsedHTML = parser.parseFromString(string, "text/html");
  return parsedHTML;
};

function getImageNameFromSignedURL(imageSRC) {
  const pattern = /([^\/?#]+)\.(?:jpg|jpeg|gif|png)/i;
  const match = imageSRC.match(pattern);
  if(match){
    return match[0];
  }
  return imageSRC.split('/')[5].split('?')[0];    //using this so that it is back compatiable if url is not the gcp url.
}

export const extractTagsFromHTML = (html, tag) => {
  return html.querySelectorAll(tag) || [];
};
export const fetchSignedURLS = async (element) => {
  const images = extractTagsFromHTML(element, "img");
  if (images.length === 0) return element;
  for (let i = 0; i < images.length; i++) {
    images[i].src = await getSignedURL( getImageNameFromSignedURL(images[i].src), config.courseImageBucket);
  }
  return element;
};

export const getSignedURL = async (filename, bucketName) => {
  const response = await axios.post(
    `${config.endPoint.commonService}/utils/get-signed-url`,
    {
      fileName: filename,
      bucketName: bucketName,
      fileType : "description"    //TODO: hardcoded for now, need to make it generic later
    }
  );
  return response.data;
};

export const modifyImageTags = async (fields) => {
  let modifiedFields = {};
  for (let key of Object.keys(fields)) {
    let value = null;
    if(fields[key]){
      value = stringTOHTMLParser(fields[key]);
      value = await fetchSignedURLS(value);
      value = value.documentElement.getElementsByTagName("body")[0].innerHTML;
    }else{
      value = ""
    }
    modifiedFields[key] = value
  }
  return modifiedFields;
};

const deleteImages = async (bucketName, fileNames) => {
  try {
    const response = await axios.post(
      `${config.endPoint.commonService}/utils/delete-objects`,
      { bucketName, objects: fileNames }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const checkForAddedImages = (imageArray, newValue) => {
  const parsedNewString = stringTOHTMLParser(newValue);
  const currentStringImageTags = extractTagsFromHTML(parsedNewString, "img");
  if (imageArray.length === 0)
    return Array.from(currentStringImageTags).map((image) => getImageNameFromSignedURL(image.src));
  for (let currentImage of currentStringImageTags) {
    let currentImageExists = false;
    for (let previousImage of imageArray) {
      if (previousImage === getImageNameFromSignedURL(currentImage.src)) currentImageExists = true;
    }
    if (!currentImageExists) {
      imageArray.push(getImageNameFromSignedURL(currentImage.src));
    }
  }
  return imageArray;
};

export const deleteOrphanedImages = async (imageArray, finalValue) => {
  const parsedFinalString = stringTOHTMLParser(finalValue);
  const finalImageTags = extractTagsFromHTML(parsedFinalString, "img");
  for (let finalImage of finalImageTags) {
    let index = imageArray.findIndex((image) => image ===  getImageNameFromSignedURL(finalImage.src));
    if (index > -1) imageArray.splice(index, 1);
  }
  let response;
  if (imageArray.length !== 0)
    response = await deleteImages(config.courseImageBucket, imageArray);
  return response;
};

export const htmlToText = (html) => {
  let temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent;
}

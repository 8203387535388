import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
import style from "./PastSessionDetailModal.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PrimaryButton from "../../../component-library/primaryButton/PrimaryButton";
import SecondaryButton from "../../../component-library/secondaryButton/SecondaryButton";

function PastSessionDetailModal({
  toggle,
  title,
  titleBold,
  show,
  handleClose,
  leftButton,
  rightButton,
  leftButtonClick,
  rightButtonClick,
  modalContent,
  modalFooterContent,
  showOR = false,
}) {
  return (
    <Modal
      animation={false}
      contentClassName={style.modal}
      centered
      open={show}
      onCancel={handleClose}
      footer={false}
    >
      <span className={style.titleBold}>{titleBold}</span>
      <span className={style.title}>{title}</span>

      {modalContent && (
        <div className={style.modalContent}>{modalContent}</div>
      )}
      <span>
        <span className={style.leftButton}>
          <PrimaryButton
            label={leftButton}
            handleClick={leftButtonClick}
            otherStyle={style.buttonStyle}
          />
        </span>
        {showOR && <span className={style.orLabel}>OR</span>}
        <span>
          {/* && isSuperAdmin */}
          {!toggle && (
            <PrimaryButton
              label={rightButton}
              handleClick={rightButtonClick}
              otherStyle={style.buttonStyle}
            />
          )}
          {toggle && (
            <SecondaryButton
              label={rightButton}
              handleClick={rightButtonClick}
              otherStyle={style.buttonStyle}
            />
          )}
        </span>
      </span>
      {modalFooterContent && <div>{modalFooterContent}</div>}

    </Modal>
  );
}

PastSessionDetailModal.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.bool,
  title: PropTypes.string,
  titleBold: PropTypes.string,
  leftButton: PropTypes.string,
  rightButton: PropTypes.string,
  handleClose: PropTypes.func,
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
  modalFooterContent: PropTypes.any,
};

export default PastSessionDetailModal;

import React from "react";
import {Row,Col} from "antd";
import PropTypes from "prop-types";
import styles from "./adminPageTitle.module.css";

// props: title, count
function AdminPageTitle({ pageTitle, pageCount = 0, style={}, extraMargin = true, overrideFont = false }) {
	return (
		<>
			<Row>
				<Col>
					<div className={`${styles.pageTitle} ${extraMargin ? styles.extraMargin : null}  ${overrideFont ? styles.antOverriddenFont : null}`}  style={{...style}}
					>
						{pageTitle}
						{pageCount > 0 && (
							<span className={styles.pageCount}>({pageCount})</span>
						)}
					</div>
				</Col>
			</Row>
		</>
	);
}

AdminPageTitle.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	pageCount: PropTypes.number,
};

export default AdminPageTitle;

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Slider from "react-slick";
import { PaginationProgressBar } from "../../../../component-library";
import config from "../../../../config";
import ElearningCourseTile from "../elearningCourseTile/ElearningCourseTile";
import ViewAllCourseTile from "../viewAllCourseTile/ViewAllCourseTile";
import "./../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./../../../../../node_modules/slick-carousel/slick/slick.css";
import "./elearning.scss";

function ElearningHomeComponent({ setAlert, setAlertType, userConfig }) {
  const { primary } = userConfig;

  const slidesPerRow = window.innerWidth >= 1440 ? 4 : 3;

  const [currentSlide, setCurrentSlide] = useState(0);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 1,
    slidesPerRow,
    afterChange: (current) => setCurrentSlide(current),
  };

  const itemsPerPage = settings.rows * settings.slidesPerRow;

  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  const [gotCoursesData, setGotCoursesData] = useState(false);
  const [coursesData, setCoursesData] = useState([]);
  const pageSize = config.elearningHomeComponent.pageSize;
  const pageNumber = config.elearningHomeComponent.pageNumber;

  function fetchElearningCourseList(currentCategory = null) {
    let params = {
      params: {
        pageNumber: pageNumber,
        pageSize: pageSize,
        courseStatus: "active",
      },
    };
    if (currentCategory) {
      params = {
        params: { ...params.params, categoryId: currentCategory.value },
      };
    }
    axios
      .get(`${config.endPoint.elearning}/course`, params)
      .then((res) => {
        setCoursesData(res.data.data);
        setGotCoursesData(true);
      })
      .catch((error) => {
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
        setAlertType("danger");
      });
  }

  useEffect(() => {
    fetchElearningCourseList();
    //ToDo Fix eslint issue
  }, []);

  return (
    <div className="el-home-parent-container">
      <div className={"el-home-row elearning-home"}>
        <span className={"el-home-heading"}>E-Learning Courses</span>

        <span className={"el-home-col"}>
          <a href="/e-learning-courses-list/all/all">
            <label
              className="el-home-col"
              style={{ color: primary, cursor: "pointer" }}
            >
              VIEW ALL
            </label>
          </a>
        </span>
      </div>
      {window.innerWidth > 768 ? (
        <Row>
          <Slider
            {...settings}
            ref={sliderRef}
            className={`courseslick  ${"slider"}`}
          >
            {(gotCoursesData &&
              coursesData.length &&
              coursesData.map((data, index) => {
                return (
                  <span className="elearning-card" key={index}>
                    <ElearningCourseTile
                      dataElement={data}
                      index={index}
                      coursePath={`/e-learning-courses/${data.id}`}
                    />
                  </span>
                );
              })) ||
              ""}
            <span className={"elearning-card"}>
              <ViewAllCourseTile path="/e-learning-courses-list/all/all" />
            </span>
          </Slider>
          <div className={"footer"}>
            {coursesData.length > 0 && (
              <>
                <PaginationProgressBar
                  currentPage={currentSlide}
                  maxPages={Math.ceil((coursesData.length + 1) / itemsPerPage)}
                  maxWidth={4.3125}
                  prevHandler={gotoPrev}
                  nextHandler={gotoNext}
                  prevDisabled={currentSlide === 0}
                  nextDisabled={
                    currentSlide ===
                    Math.ceil((coursesData.length + 1) / itemsPerPage) - 1
                  }
                  primary={primary}
                />
              </>
            )}
          </div>
        </Row>
      ) : (
        <div className="elearning-course-list">
          {coursesData.map((data, index) => {
            return (
              <span className="mobile-elearning-card" key={index}>
                <ElearningCourseTile
                  dataElement={data}
                  index={index}
                  coursePath={`/e-learning-courses/${data.id}`}
                />
              </span>
            );
          })}
          <span className={"mobile-elearning-card"}>
            <ViewAllCourseTile path="/e-learning-courses-list/all/all" />
          </span>
        </div>
      )}
    </div>
  );
}

export default ElearningHomeComponent;

import axios from "axios";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { CourseContext } from "../../../context/CourseContext";
import "./AntdAdminPromotionalBanner.scss";
import uploadImage from "../../../assets/icons/upload_image.svg";
import TimeFormatter from "../../../utils/timeFormatter";
import { formatCountryList } from "../courseAddEdit/addEdit.lib";
import {
  CustomMultiSelect,
  CustomSelect,
  DateTimePicker,
  Label,
  ScrollToTop,
} from "../../../component-library";
import {
  validateData,
  validateImage,
  getBase64,
  getBase64String,
  priorityMap,
} from "./bannerAddEdit.lib";
import {
  Row,
  Col,
  Form,
  Alert,
  Breadcrumb,
  Typography,
  Button,
  Input,
} from "antd";
import moment from "moment";
import config from "../../../config";

export default function BannerAddEdit() {
  const { countryList } = useContext(CourseContext);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const bannerId = query.get("bannerId");
  const crumbs = [
    {
      title: (
        <a href="/Admin" className="promotional-banner-crumbs">
          Admin Dashboard
        </a>
      ),
      to: "/Admin",
    },
    {
      title: (
        <a href="/promotionalBanner" className="promotional-banner-crumbs">
          Promotional Banner
        </a>
      ),
      to: "/promotionalBanner",
    },
    {
      title:
        (bannerId && "Edit Promotional Banner") || "Add Promotional Banner",
    },
  ];
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const inputFile = useRef(null);
  const [actualFile, setactualFile] = useState(null)
  const [file, setFile] = useState(null);
  const [base64String, setBase64String] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [priority, setPriority] = useState("");
  const [referenceURL, setReferenceURL] = useState("");
  const [resetImgFlag, setResetImgFlag] = useState(false);
  const [formattedCountriesData, setFormattedCountriesData] = useState({});
  const [selectedCountries, setSelectedCountries] = useState([]);

  const onButtonClick = () => {
    inputFile.current.click();
  };
  async function handleUpload(e) {
    resetImg();
    const error = {};
    if (e.target.files && e.target.files.length > 0) {
      let validateImg = await validateImage(e.target.files[0]);
      if (!validateImg) {
        error.image = "Please upload image with resolution of 1920 x 1080";
      } else {
        error.image = "";
        setactualFile(e.target.files[0])
        setFile(URL.createObjectURL(e.target.files[0]));
        const base64 = await getBase64(e.target.files[0]);
        setBase64String(getBase64String(base64));
      }
    } else {
      error.image = `Please select image.`;
    }
    setErrorMessage({ ...errorMessage, ...error });
  }
  const handlePriority = (priority) => {
    setErrorMessage({ ...errorMessage, priority: null });
    setPriority(priority.value);
  };

  const resetImg = () => {
    setactualFile(null)
    setFile(null);
    setBase64String(null);
    if (bannerId) {
      setResetImgFlag(true);
    }
  };

  function convertToUTF(date) {
    return parseInt(Date.parse(date) / 1000);
  }

  const isValidDate = (startDate, endDate) => {
    startDate = moment.unix(startDate).format("YYYYMMDD");
    endDate = moment.unix(endDate).format("YYYYMMDD");

    return endDate >= startDate;
  };

  const handleStartDate = (startDateTime) => {
    const startDate = convertToUTF(startDateTime);
    setStartDate(startDate);

    if (!startDateTime) {
      const error = { startDate: `Please select start date` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      if (endDate && !isValidDate(startDate, endDate)) {
        setErrorMessage({
          startDate: "Start Date cannot be greater than End Date",
        });
      } else {
        setAlertType(null);
        setAlert(null);

        const error = { startDate: "", endDate: "" };
        setErrorMessage({ ...errorMessage, ...error });
      }
    }
  };

  const handleEndDate = (endDateTime) => {
    const endDate = convertToUTF(endDateTime);
    setEndDate(endDate);

    if (!endDateTime) {
      const error = { endDate: `Please select end date` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      if (startDate && !isValidDate(startDate, endDate)) {
        setErrorMessage({
          endDate: "End Date cannot be less than Start Date",
        });
      } else {
        setAlertType(null);
        setAlert(null);

        const error = { endDate: "", startDate: "" };
        setErrorMessage({ ...errorMessage, ...error });
      }
    }
  };

  const handleCountry = (countryData) => {
    setSelectedCountries(countryData);
    if (!countryData || countryData.length === 0) {
      const error = { country: `Please select atleast one Country.` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const inactiveDateTime =
    TimeFormatter.getCurrentTimeStamp() - 30 * 24 * 60 * 60;

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("file", actualFile)
    formData.append('startDateTime', startDate)
    formData.append('endDateTime', endDate + 86399)
    formData.append('redirectURL', referenceURL)
    formData.append('priority', priority)
    // TODO remove data after checking edit
    const data = {
      start_date_time: startDate,
      end_date_time: endDate + 86399,
      redirection_url: referenceURL,
      priority: priority,
    };

    if (selectedCountries && selectedCountries.length) {
      data.country = selectedCountries
        .map((cur) => cur.id)
        .filter((item) => item !== "*");
      formData.append('country', selectedCountries
      .map((cur) => cur.id)
      .filter((item) => item !== "*") )
    }

    if ((bannerId && resetImgFlag) || !bannerId) {
      data.image_content = base64String;
    }
    const validate = validateData(data, bannerId, resetImgFlag);
    setErrorMessage({ ...errorMessage, ...validate.error });
    if (!validate.flag) {
      return false;
    }
    if (bannerId) {
      console.log(bannerId, 'bannnerId')
      axios
        .patch(`${config.endPoint.commonService}/promotionalBanners/${bannerId}`, formData)
        .then((res) => {
          history.push({
            pathname: "/promotionalBanner",
            state: {
              alertType: "success",
              alertMessage: res.data.message,
            },
          });
        })
        .catch((error) => {
          setAlertType("error");
          setAlert(error?.response?.data?.message || "Something went wrong.");
        });
    } else {
      axios
        .post(`${config.endPoint.commonService}/promotionalBanners/`, formData)
        .then((res) => {
          history.push({
            pathname: "/promotionalBanner",
            state: {
              alertType: "success",
              alertMessage: res.data.message,
            },
          });
        })
        .catch((error) => {
          setAlertType("error");
          setAlert(error?.response?.data?.message || "Something went wrong.");
        });
    }
  };

  const editBannerData = async (data) => {
    const {
      startDate = "",
      endDate = "",
      imageUrl = "",
      priority = "",
      redirectionUrl = "",
      countries = [],
    } = data;
    
    const tes = '';
    setStartDate(startDate);
    setEndDate(endDate);
    // switch(priority){
    //   case 0: tes = 'LOW'; break;
    //   case 1: tes = 'MEDUIM'; break;
    //   case 2: tes = 'HIGH'; break;

    // }
    setPriority(priority);
    setReferenceURL(redirectionUrl);
    setFile(imageUrl);
    setactualFile(imageUrl)

    if (countryList && countryList.length) {
      const formattedSelectedCountries = countries
        .map((current) => {
          return countryList.find((curr) => curr.code === current);
        })
        .map((curr) => {
          return { id: curr.code, value: curr.country };
        });
      setSelectedCountries(formattedSelectedCountries);
    }
  };

  useEffect(() => {
    if (bannerId && Object.keys(formattedCountriesData).length) {
      const end = `${config.endPoint.commonService}/promotionalBanners/${bannerId}`
      // const endPoint = `/hero-banner/promotional?banner_id=${bannerId}`;
      axios
        .get(end)
        .then((response) => {
          if (!response.data.success) {
            setAlertType("error");

            setAlert(response.data.message);
            const timer = setTimeout(() => {
              history.push("/Admin/promotionalBanner");
            }, 10000);

            return () => clearTimeout(timer);
          }
          const {
            data: { data },
          } = response;
          editBannerData(data);
        })
        .catch((error) => {
          setAlert(
            (error && error.response && error.response.message) ||
              "Something went wrong."
          );
          setAlertType("error");
        });
    }
    // eslint-disable-next-line
  }, [bannerId, formattedCountriesData]);

  useEffect(() => {
    countryList && setFormattedCountriesData(formatCountryList(countryList));
  }, [countryList]);

  const validFields =
    !errorMessage.image &&
    !errorMessage.startDate &&
    !errorMessage.endDate &&
    !errorMessage.priority &&
    !errorMessage.country &&
    !errorMessage.url;

  return (
    <div className="antd-banneraddedit-container">
      <ScrollToTop />
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
      />
      <Breadcrumb items={crumbs} />
      <div style={{ marginTop: "2rem" }}>
        <Typography.Title>
          {bannerId ? "Edit Promotional Banner" : "Add Promotional Banner"}
        </Typography.Title>
        <div>
          <Form onSubmit={submitHandler}>
            <div style={{ width: "95%" }}>
              <Row className="banneraddedit-tessst">
                <Col className="banneraddedit-formImageAdd">
                  <Col>
                    {!file && (
                      <>
                        <Col className="banneraddedit-biggerSvgBox">
                          <img
                            src={uploadImage}
                            alt=""
                            className="banneraddedit-biggerSvg"
                            onClick={onButtonClick}
                            id="uploadImg"
                          />
                          <input
                            type="file"
                            id="file"
                            accept=".jpg, .jpeg, .png"
                            ref={inputFile}
                            className="inputButton"
                            onChange={handleUpload}
                          />
                          <p className="banneraddedit-imageText">
                            Select Image to upload
                          </p>
                        </Col>
                      </>
                    )}

                    {file && (
                      <img
                        src={file}
                        alt={bannerId}
                        className="banneraddedit-image"
                      />
                    )}

                    <p className="banneraddedit-imageGuideline">
                      Please upload png, jpg or jpeg image with 1920 x 1080
                      resolution
                    </p>
                    {(errorMessage.image && (
                      <div
                        className="banneraddedit-errorMessage"
                        style={{
                          marginLeft: "0rem",
                          marginTop: "3rem",
                          marginBottom: "-3rem",
                        }}
                      >
                        {errorMessage.image}
                      </div>
                    )) ||
                      ""}
                  </Col>
                </Col>
                <Col>
                  <div className="addbanneraddedit-dashboard">
                    <div>
                      <Form.Item>
                        <Label title=" Start Date" required />
                        <DateTimePicker
                          allowInput={true}
                          clickOpens={true}
                          value={TimeFormatter.HTMLDateInputFormat(startDate)}
                          minValue={TimeFormatter.HTMLDateInputFormat(
                            inactiveDateTime
                          )}
                          onChange={(val) => {
                            handleStartDate(val);
                          }}
                        />
                        {(errorMessage.startDate && (
                          <div
                            className="banneraddedit-errorMessage"
                            style={{ marginTop: ".5rem" }}
                          >
                            {errorMessage.startDate}
                          </div>
                        )) ||
                          ""}
                      </Form.Item>
                    </div>
                    <div className="addbanneraddedit-column-space">
                      <Form.Item>
                        <Label title="End Date" required />
                        <DateTimePicker
                          allowInput={true}
                          clickOpens={true}
                          value={TimeFormatter.HTMLDateInputFormat(endDate)}
                          minValue={TimeFormatter.HTMLDateInputFormat(
                            inactiveDateTime
                          )}
                          onChange={(val) => {
                            handleEndDate(val);
                          }}
                        />
                        {(errorMessage.endDate && (
                          <div
                            className="banneraddedit-errorMessage"
                            style={{ marginTop: ".5rem" }}
                          >
                            {errorMessage.endDate}
                          </div>
                        )) ||
                          ""}
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item>
                        <Label title="Priority" required />
                        <CustomSelect
                          options={[
                            { label: "Low", value: "LOW" },
                            { label: "Medium", value: "MEDIUM" },
                            { label: "High", value: "HIGH" },
                          ]}
                          selected={priorityMap(priority)}
                          handleDropdown={(value) => handlePriority(value)}
                        />
                        {(errorMessage.priority && (
                          <div
                            className="banneraddedit-errorMessage"
                            style={{ marginTop: "-2rem" }}
                          >
                            {errorMessage.priority}
                          </div>
                        )) ||
                          ""}
                      </Form.Item>
                    </div>
                    <div className="addbanneraddedit-column-space">
                      <Form.Item>
                        <Label title="Region" required />
                        <CustomMultiSelect
                          options={formattedCountriesData}
                          handleCustomChange={handleCountry}
                          selected={selectedCountries}
                        />
                        {(errorMessage.country && (
                          <div className="banneraddedit-errorMessage-region">
                            {errorMessage.country}
                          </div>
                        )) ||
                          ""}
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item>
                        <Label title="Reference URL" required />
                        <Input
                          value={referenceURL}
                          type="text"
                          placeholder="Enter the reference URL"
                          onChange={(e) => {
                            setErrorMessage({ ...errorMessage, url: null });
                            setReferenceURL(e.target.value);
                          }}
                        />

                        {(errorMessage.url && (
                          <div
                            className="banneraddedit-errorMessage"
                            style={{ marginTop: ".5rem" }}
                          >
                            {errorMessage.url}
                          </div>
                        )) ||
                          ""}
                      </Form.Item>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="banneraddededit-button-container">
              <>
                <Col>
                  <Button
                    type="button"
                    className="banneraddedit-resbutton"
                    onClick={resetImg}
                  >
                    Reset Image
                  </Button>
                </Col>
                <Col style={{ marginLeft: "2rem" }}>
                  <Button
                    type="button"
                    className="banneraddedit-subButton"
                    onClick={submitHandler}
                    // disabled={!validFields}
                  >
                    {bannerId ? "Update" : "Submit"}
                  </Button>
                </Col>
              </>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

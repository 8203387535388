import React from "react";
import Form from "react-bootstrap/Form";
import style from "./label.module.css";
import PropTypes from "prop-types";

export default function Label({ title, required }) {
  return (
    <Form.Label bsPrefix={`form-label ${style.label}`}>
      {title} {(required && <span style={{ color: "#FF0000" }}>*</span>) || ""}
    </Form.Label>
  );
}

Label.propTypes = {
  title: PropTypes.string,
};

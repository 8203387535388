import React, { useEffect, useState } from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as bookmark_black } from "../../assets/icons/bookmark_black.svg";
import { ReactComponent as bookmark_blue } from "../../assets/icons/bookmark_blue.svg";
import { ReactComponent as bookmark_white } from "../../assets/icons/bookmark_white.svg";
import { ReactComponent as bookmarked_white } from "../../assets/icons/bookmarked_white.svg";
import { ReactComponent as bookmarked_blue } from "../../assets/icons/bookmarked_blue.svg";

function Bookmark({ isBookmarked, color }) {
  //TODO
  const [isFav, setIsFav] = useState(isBookmarked);

  useEffect(() => {
    setIsFav(isBookmarked);
  }, [isBookmarked]);

  if (isFav) {
    if (color === "white") {
      return (
        <div>
          <Icon style={{ fontSize: "24px" }} component={bookmarked_white} />
        </div>
      );
    } else if (color === "black") {
      return (
        <div>
          <Icon style={{ fontSize: "24px" }} component={bookmark_black} />
        </div>
      );
    }
  } else {
    if (color === "white") {
      return (
        <div>
          <Icon style={{ fontSize: "24px" }} component={bookmarked_white} />
        </div>
      );
    } else if (color === "black") {
      return (
        <div>
          <Icon style={{ fontSize: "24px" }} component={bookmark_white} />
        </div>
      );
    }
  }
}

export default Bookmark;

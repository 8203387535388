import React from "react";
import { Col } from "antd";
import "./reportTile.scss";

const ReportTile = (props) => {
  return (
    <>
      <Col>
        <div className="tileContainer">
          <div className="tileLabel">{props.label}</div>
          <div className="tileValue">{props.value}</div>
        </div>
      </Col>
    </>
  );
};
export default ReportTile;

import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { ReactComponent as Restriction } from "../../assets/icons/private_icon.svg";
import ListBoxSwap from "../listBoxSwap/ListBoxSwap";
import PrimaryButton from "../primaryButton/PrimaryButton";
import PrimaryOutlineButton from "../primaryOutlineButton/PrimaryOutlineButton";
import style from "./swapUsersModal.module.css";
import config from "../../config";

function SwapUsersModal({
  swap,
  showSwapUsersModal,
  boldTitle,
  courseId,
  handleCloseSwapMoveUser,
  batchId,
  updatePage,
  switchModal,
  setAlert,
  setAlertType,
  privateId,
  batchSize,
  batchName,
  setOpenSwapMoveHelper,
}) {
  const [listBoxUsed, setListBoxUsed] = useState(false);
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [waitlistUsers, setWaitlistedUsers] = useState([]);
  const [initialEnrolledUserNo, setInitialEnrolledUserNo] = useState(0);
  const [tempEnrolledUsers, setTempEnrolledUsers] = useState([]);
  const [tempWaitlistUsers, setTempWaitlistUsers] = useState([]);
  let disclaimer = "";

  async function getEnrolledUsers(status) {
    const result = await axios
      .get(`${config.endPoint.instructorLedNew}/admin/view?session_id=${batchId}&status_type=${status}`)
      .catch(function (error) {
        if (error && error.response.data.message) {
          setAlert(error.response.data.message);
        } else {
          setAlert("Something went wrong, Please try again.");
        }
        setAlertType("danger");
        handleCloseSwapMoveUser();
      });
    if (result === undefined) return [];
    else return result.data.data;
  }

  const fetchEnrolledUsers = async () => {
    const enrolledUsers = await getEnrolledUsers("enrolled");
    const approvalPendingUsers = await getEnrolledUsers("approvalPending");

    const modifiedEnrolledUsers = enrolledUsers.map((current) => {
      return {
        value: current.userEmail,
        label: `◉ ${" "} ${current.userEmail} (${current.country}) `,
      };
    });
    const modifiedApprovalPendingUsers = approvalPendingUsers.map((current) => {
      return {
        value: current.userEmail,
        label: `◎ ${" "} ${current.userEmail} (${current.country}) `,
      };
    });
    const listBoxEnrolledData = [
      ...modifiedEnrolledUsers,
      ...modifiedApprovalPendingUsers,
    ];
    setEnrolledUsers(listBoxEnrolledData);
    setTempEnrolledUsers(listBoxEnrolledData);
    setInitialEnrolledUserNo(listBoxEnrolledData.length);
  };

  const fetchWaitlistUsers = async () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/admin/view`, {
        params: { course_id: courseId, status_type: "waitlist" },
      })
      .then((res) => {
        const waitlistResponse = res.data.data;
        const listBoxWaitlistData = [];
        waitlistResponse.forEach((element, index) => {
          listBoxWaitlistData[index] = {
            value: `${waitlistResponse[index]["userEmail"]}`,
            label: `${waitlistResponse[index]["userEmail"]} (${waitlistResponse[index]["country"]})`,
          };
        });
        setTempWaitlistUsers(listBoxWaitlistData);
        setWaitlistedUsers(listBoxWaitlistData);
      })
      .catch(function (error) {
        if (error && error.response.data.message) {
          setAlert(error.response.data.message);
        } else {
          setAlert("Something went wrong, Please try again.");
        }
        setAlertType("danger");
        handleCloseSwapMoveUser();
      });
  };

  const onSubmit = () => {
    if (listBoxUsed && !disclaimer) {
      const newEnrolledUsers = waitlistUsers.filter((user) => {
        return tempWaitlistUsers.indexOf(user.value) === -1;
      });
      const newWaitlistUsers = enrolledUsers.filter((user) => {
        return tempEnrolledUsers.indexOf(user.value) === -1;
      });

      const batchEmailList = newEnrolledUsers.map((current) => current.value);
      const waitlistEmailList = newWaitlistUsers.map(
        (current) => current.value
      );
      const postData = {
        course_id: courseId,
        batch_id: batchId,
        batch_email_list: waitlistEmailList,
        waitlist_email_list: batchEmailList,
      };
      axios
        .post("admin/bulk/swap/", postData)
        .then((res) => {
          setAlert(res.data.message);
          setAlertType("success");
          updatePage();
        })
        .catch((error) => {
          if (error && error.response.data.message) {
            setAlert(error.response.data.message);
          } else {
            setAlert("Unable to Update Waitlisted and Enrolled Users");
          }

          setAlertType("danger");
        });
      setOpenSwapMoveHelper(false);
      handleCloseSwapMoveUser();
    }
  };

  let PrimaryLabel = "";
  if (swap) {
    PrimaryLabel = "CHANGE TO MOVE USERS";
  } else {
    PrimaryLabel = "CHANGE TO SWAP USERS";
  }
  useEffect(() => {
    fetchWaitlistUsers();
    fetchEnrolledUsers();
    if (!swap && !privateId) {
      document
        .getElementsByClassName("rdl-move-left")[0]
        .style.setProperty("display", "none");
    } else if (swap || (!swap && privateId)) {
      document
        .getElementsByClassName("rdl-move-left")[0]
        .style.setProperty("display", "flex");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap]);
  let disabled = true;
  function isDisabled() {
    if (tempEnrolledUsers.length > batchSize) {
      disclaimer = "Number of enrolled users exceed the size of the batch";
      disabled = true;
      return true;
    }
    if (swap && tempEnrolledUsers.length !== initialEnrolledUserNo) {
      disclaimer =
        "Please swap equal number of users from waitlist and enrolled users list";

      disabled = true;
      return true;
    } else {
      disabled = false;
      disclaimer = "";
      return false;
    }
  }

  const titleDynamic = () => {
    return (
      <div>
        <div className={style.textBox}>
          <span>
            {privateId ? <Restriction /> : ""}
            <span className={style.boldSubtitle}> {batchId} </span> -{" "}
            {batchName} (Enrolled user:{" "}
            <span className={style.boldSubtitle}>
              {tempEnrolledUsers.length}/{batchSize}
            </span>
            )
          </span>
        </div>
      </div>
    );
  };
  const swapTitle = `users between waitlist and session`;

  return (
    <>
      <Modal
        animation={false}
        contentClassName={style.modal}
        centered
        open={showSwapUsersModal}
        onCancel={handleCloseSwapMoveUser}
        width={1100}
        footer={false}
      >
        <span>
          <div className={style.title}>
            <span className={style.boldTitle}>{boldTitle}</span> {swapTitle}
          </div>
        </span>
        <span className={style.switchModal}>
          {!privateId && (
            <Button
              onClick={switchModal}
              className={style.changeButton}
            >{PrimaryLabel}</Button>
          )}
        </span>
        <div className={style.functionalBox}>
          {titleDynamic()}
          <Row className={style.innerBox}>
            <Col className={style.leftTitle} lg={11}> WAITLISTED USERS</Col>
            <Col>
              <span className={style.rightTitle}>ENROLLED USERS</span>
            </Col>
            <ListBoxSwap
              enrolledUsers={enrolledUsers}
              waitlistUsers={waitlistUsers}
              setTempEnrolledUsers={setTempEnrolledUsers}
              setTempWaitlistUsers={setTempWaitlistUsers}
              swap={swap}
              tempEnrolledUsers={tempEnrolledUsers}
              setListBoxUsed={setListBoxUsed}
              privateId={privateId}
            />
          </Row>
        </div>
        <div>
          <span className={style.infoUser1}>◉ Confirmed</span>
          <span className={style.infoUser2}>◎ Pending Approval</span>
        </div>
        {isDisabled() && <div className={style.alertText}>{disclaimer}</div>}{" "}
        <div className={style.footerButton}>
          <span className={style.cancelButton}>
            <PrimaryButton
              label="SUBMIT"
              handleClick={onSubmit}
              disabled={disabled}
            />
          </span>
        </div>
      </Modal>
    </>
  );
}

export default SwapUsersModal;

import spinner from "../../assets/icons/spinner.gif";
import PropTypes from "prop-types";
import style from "./pageLoader.module.css";
import "./style.css";

function PageLoader({ active, children }) {
  return (
    <>
      {active && (
        <div className={style.loaderContainer}>
          <div className={style.loader}>
            <img alt="spinner" src={spinner} className={style.spinner} />
          </div>
        </div>
      )}
    </>
  );
}

PageLoader.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

export default PageLoader;

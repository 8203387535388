import { ReactComponent as InstructorLedCourse } from "../../../assets/icons/InstructorLedCourse.svg";
import { ReactComponent as ElearningCourse } from "../../../assets/icons/elearningVideo.svg";
import { ReactComponent as LearningPath } from "../../../assets/icons/learning_path.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";

import bmIcon from "../../../assets/icons/bookmark_icon_black.png";
import { Typography } from "antd";

export default function PageFooter() {
  return (
    <div
      style={{
        background: "white",
        position: "fixed",
        bottom: 0,
        zIndex: 1000000,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "23.5rem",
          paddingTop: ".5rem",
        }}
      >
        <a href="/Classroom_training/all/all">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <InstructorLedCourse style={{ width: 20, height: 24 }} />
            <Typography style={{ fontSize: ".5rem" }}>
              Instructor Led
            </Typography>
          </div>
        </a>
        <a href="/e-learning-courses-list/all/all">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <ElearningCourse style={{ width: 20, height: 24 }} />
            <Typography style={{ fontSize: ".5rem" }}>eLearning</Typography>
          </div>
        </a>
        <a href="/LearningPath">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <LearningPath style={{ width: 20, height: 24 }} />
            <Typography style={{ fontSize: ".5rem" }}>Learning Path</Typography>
          </div>
        </a>
        <a href="/myLearnings">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              className="px-0"
              src={bmIcon}
              alt="img"
              width={16}
              height={20}
            />
            <Typography style={{ fontSize: ".5rem" }}>Bookmarks</Typography>
          </div>
        </a>
        <a href="/Calendar">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Calendar />
            <Typography style={{ fontSize: ".5rem" }}>Calendar</Typography>
          </div>
        </a>
      </div>
    </div>
  );
}

import { Breadcrumb, Button, Col, Input, Row, Table, Typography } from "antd";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { Alert } from "antd";
import {
  AdminPageTitle,
  InlineIconButton,
  RoundProgressBar,
} from "../../../../component-library";
import { useHistory } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import { CourseContext } from "../../../../context/CourseContext";
import axios from "axios";
import config from "../../../../config";
import editIcon from "../../../../assets/icons/edit_icon.svg";
import addUser from "../../../../assets/icons/add_user.svg";
import plusCircle from "../../../../assets/icons/plus_circle.svg";
import uploadZipIcon from "../../../../assets/icons/uploadZipIcon.svg";
import { serializeDataResponse } from "../../../../serializer/dataSerializer";
import TimeFormatter from "../../../../utils/timeFormatter";
import { Tabs } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./antdElearningCourseList.scss";
import AssignUserModal from "./AssignUserModal";
import AntdAssignUserModal from "./AntdAssignUserModal";
import { CSVLink } from "react-csv";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { UserContext } from "../../../../context/UserContext";
import { startCase, debounce } from "lodash";

const renderCourseFilter = (selectedFilter, setSelectedFilter) => {
  const filterMenu = [
    // { name: "Pending", value: "3" },
    { name: "Active", value: "1" },
    { name: "Archived", value: "2" },
  ];

  return filterMenu.map((filter) => {
    return filter.value === selectedFilter ? (
      <span
        className="el-filter-style"
        style={{
          // color: "#00a0f0",
          // borderBottom: "2px solid #00a0f0",
          margin: 8,
          cursor: "pointer",
        }}
      >
        {filter.name}
      </span>
    ) : (
      <span
        className=""
        onClick={() => setSelectedFilter(filter.value)}
        style={{ margin: 8, cursor: "pointer" }}
      >
        {filter.name}
      </span>
    );
  });
};

function AntdElearningCourseList() {
  const history = useHistory();
  const location = useLocation();
  const { userDateFormat } = useContext(CourseContext);
  const [searchText, setSearchText] = useState([]);
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [courseProviderlist, setCourseProviderlist] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState(null);
  const [subCourseProviderList, setSubCourseProviderList] = useState([]);
  const [selectedSubCourseProvider, setSelectedSubCourseProvider] =
    useState(null);
  const [selectedFilter, setSelectedFilter] = useState("1");
  const { userConfig } = useContext(UserContext);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const crumbs = [
    {
      title: (
        <a href="/Admin" style={{ color: userConfig?.primary }}>
          Admin Dashboard
        </a>
      ),
    },
    { title: "E-Learning Courses" },
  ];

  const tableSearchData = ({
    dataIndex,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
        style={{
          width: "10rem",
          fontFamily: "RakutenRoundRegular",
          marginBottom: 8,
          display: "block",
        }}
      />
      <button
        type="button"
        style={{
          width: "5.5rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
      >
        Search
      </button>
      <button
        type="button"
        style={{
          width: "4rem",
          marginLeft: "0.2rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          clearFilters();
          setSearchText("");
          confirm();
        }}
      >
        Reset
      </button>
    </div>
  );

  const activeCourseColumns = [
    {
      key: "identityId",
      dataIndex: "identityId",
      title: "Course ID".toUpperCase(),
      sorter: (a, b) => {
        return a.identityId.localeCompare(b.identityId);
      },
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "identityId" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.identityId)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      width: 110,
    },
    {
      key: "name",
      width: 160,
      dataIndex: "name",
      title: "Course Name".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "name" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.name)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      render: (cell, row) => {
        return (
          <Link
            to={`/e-learning-courses/${row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: userConfig.primary }} title={cell}>
              {cell}
            </span>
          </Link>
        );
      },
    },
    {
      key: "category.categoryName",
      width: 112,
      dataIndex: "category.categoryName",
      title: "Category".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a["category.categoryName"].localeCompare(
          b["category.categoryName"]
        );
      },
    },
    {
      key: "sub_category.subCategoryName",
      width: 100,
      dataIndex: "sub_category.subCategoryName",
      title: "Sub Category".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a["sub_category.subCategoryName"].localeCompare(
          b["sub_category.subCategoryName"]
        );
      },
    },
    {
      key: "usersCompleted",
      width: 110,
      dataIndex: "usersCompleted",
      title: "Completed".toUpperCase(),
      sort: true,
      csvExport: true,
      sorter: (a, b) => a.usersCompleted - b.usersCompleted,
      render: (cell, row) => {
        return (
          <RoundProgressBar
            maxCount={cell}
            isElearning={true}
            clickHandler={(event) => {
              openCompletedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersStarted",
      width: 100,
      dataIndex: "usersStarted",
      title: "In Progress".toUpperCase(),
      sort: true,
      sorter: (a, b) => a.usersStarted - b.usersStarted,
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isWaitlist={true}
            maxCount={cell}
            clickHandler={(event) => {
              openIncompleteUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersAssigned",
      width: 120,
      dataIndex: "usersAssigned",
      title: "Assigned".toUpperCase(),
      ellipsis: true,
      sort: true,
      sorter: (a, b) => a.usersAssigned - b.usersAssigned,
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isAssigned={true}
            maxCount={cell}
            clickHandler={(event) => {
              openAssignedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "updatedTimestamp",
      width: 100,
      dataIndex: "updatedTimestamp",
      title: "Last Update".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a.updatedTimestamp - b.updatedTimestamp;
      },

      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      key: "courseProviderDetails.CourseProviderName",
      width: 130,
      dataIndex: "courseProviderDetails.CourseProviderName",
      title: "Created by".toUpperCase(),
      sort: true,
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "courseProvider",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record?.courseProviderDetails?.CourseProviderName)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sorter: (a, b) => {
        return a?.courseProvider.localeCompare(b?.courseProvider);
      },
      render: (cell, row) => {
        return (
          <span>
            {cell || startCase(row?.courseProvider) || row?.course_provider}
          </span>
        );
      },
    },
    {
      key: "id",
      width: 100,
      dataIndex: "id",
      //   title: true.toUpperCase(),
      title: "Edit Course".toUpperCase(),
      sort: false,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              openEditCourse(row, row.id);
            }}
          />
        );
      },
    },
    {
      key: "addUsers",
      width: 75,
      dataIndex: "addUsers",
      //   title: true.toUpperCase(),
      title: "Add Users".toUpperCase(),
      sort: false,
      //   headerAlign: "center",
      //   csvExport: false,
      //   headerStyle: {
      //   },
      //   align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={addUser}
            alt="add button"
            clickHandler={(event) => {
              openAddUserModal(row, row.id);
            }}
          />
        );
      },
    },
    {
      key: "id",
      width: 100,
      dataIndex: "id",
      title: "Update Scorm ZIP".toUpperCase(),
      isDummyField: true,
      sort: false,
      headerAlign: "center",
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={uploadZipIcon}
            alt="update scorm button"
            clickHandler={(event) => {
              openFileUploadModal(row.id, true);
            }}
          />
        );
      },
    },
  ];
  const [tableColumns, settableColumns] = useState(activeCourseColumns);

  const pendingCourseColumns = [
    {
      key: "identityId",
      dataIndex: "identityId",
      title: "Course ID".toUpperCase(),
      sort: true,
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "identityId" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.identityId)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sorter: (a, b) => {
        return a.identityId.localeCompare(b.identityId);
      },
      headerStyle: {
        width: "6rem",
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Course Name".toUpperCase(),
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "name" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.name)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sort: true,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      headerStyle: {
        width: "8rem",
      },
      render: (cell, row) => {
        return (
          <Link
            to={`/e-learning-courses/${row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: "#00A0F0" }} title={cell}>
              {cell}
            </span>
          </Link>
        );
      },
    },
    {
      key: "category.categoryName",
      dataIndex: "category.categoryName",
      title: "Category".toUpperCase(),
      sorter: (a, b) => {
        return a["category.categoryName"].localeCompare(
          b["category.categoryName"]
        );
      },
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      key: "sub_category.subCategoryName",
      dataIndex: "sub_category.subCategoryName",
      sorter: (a, b) => {
        return (
          a["sub_category.subCategoryName"] ||
          "".localeCompare(b["sub_category.subCategoryName"] || "")
        );
      },
      title: "Sub Category".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      key: "usersCompleted",
      dataIndex: "usersCompleted",
      title: "Completed".toUpperCase(),
      sorter: (a, b) => a.usersCompleted - b.usersCompleted,
      headerStyle: {
        width: "0rem",
        padding: "0rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            maxCount={cell}
            isElearning={true}
            clickHandler={(event) => {
              openCompletedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersStarted",
      sorter: (a, b) => a.usersStarted - b.usersStarted,
      dataIndex: "usersStarted",
      title: "In Progress".toUpperCase(),
      headerStyle: {
        width: "0rem",
        padding: "0rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isWaitlist={true}
            maxCount={cell}
            clickHandler={(event) => {
              openIncompleteUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersAssigned",
      dataIndex: "usersAssigned",
      sorter: (a, b) => a.usersAssigned - b.usersAssigned,
      title: "Assigned".toUpperCase(),
      headerStyle: {
        width: "0rem",
        padding: "0rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isAssigned={true}
            maxCount={cell}
            clickHandler={(event) => {
              openAssignedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "updatedTimestamp",
      dataIndex: "updatedTimestamp",
      title: "Last Update".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a.updatedTimestamp - b.updatedTimestamp;
      },
      headerStyle: {
        width: "5.5rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      key: "courseProviderDetails.CourseProviderName",
      dataIndex: "courseProviderDetails.CourseProviderName",
      title: "Created by".toUpperCase(),
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "courseProvider",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.courseProvider)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sort: true,
      headerStyle: {
        width: "4.5rem",
      },
      render: (cell, row) => cell || row.courseProvider,
    },
    {
      key: "id",
      dataIndex: "id",
      title: true,
      text: "Edit Course",
      sort: false,
      headerAlign: "center",
      csvExport: false,
      headerStyle: {
        width: "4.5rem",
      },
      align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              openEditCourse(row, row.id);
            }}
          />
        );
      },
    },
    {
      key: "id",
      dataIndex: "id",
      title: "Upload Scorm ZIP".toUpperCase(),
      isDummyField: true,
      sort: false,
      headerAlign: "center",
      headerStyle: {
        width: "4.5rem",
      },
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={uploadZipIcon}
            alt="upload scorm button"
            clickHandler={(event) => {
              openFileUploadModal(row.id, true);
            }}
          />
        );
      },
    },
  ];

  const archivedCourseColumns = [
    {
      key: "identityId",
      dataIndex: "identityId",
      title: "Course ID".toUpperCase(),
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "identityId" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.identityId)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sort: true,
      headerStyle: {
        width: "6rem",
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "Course Name".toUpperCase(),
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "name" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.name)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sort: true,
      headerStyle: {
        width: "8rem",
      },
      render: (cell, row) => {
        return (
          <Link
            to={`/e-learning-courses/${row.id}`}
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ color: userConfig.primary }} title={cell}>
              {cell}
            </span>
          </Link>
        );
      },
    },
    {
      key: "category.categoryName",
      dataIndex: "category.categoryName",
      title: "Category".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      key: "sub_category.subCategoryName",
      dataIndex: "sub_category.subCategoryName",
      title: "Sub Category".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      key: "usersCompleted",
      dataIndex: "usersCompleted",
      title: "Completed".toUpperCase(),
      sorter: (a, b) => a.usersCompleted - b.usersCompleted,
      sort: true,
      headerStyle: {
        width: "6rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            maxCount={cell}
            isElearning={true}
            clickHandler={(event) => {
              openCompletedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersStarted",
      dataIndex: "usersStarted",
      sorter: (a, b) => a.usersStarted - b.usersStarted,
      title: "Viewed".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "5rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isWaitlist={true}
            maxCount={cell}
            clickHandler={(event) => {
              openIncompleteUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "usersAssigned",
      dataIndex: "usersAssigned",
      sorter: (a, b) => a.usersAssigned - b.usersAssigned,
      title: "Assigned".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "5rem",
      },
      render: (cell, row) => {
        return (
          <RoundProgressBar
            isAssigned={true}
            maxCount={cell}
            clickHandler={(event) => {
              openAssignedUserList(row.id);
            }}
          />
        );
      },
    },
    {
      key: "updatedTimestamp",
      dataIndex: "updatedTimestamp",
      title: "Last Update".toUpperCase(),
      sort: true,
      sorter: (a, b) => {
        return a.updatedTimestamp - b.updatedTimestamp;
      },
      headerStyle: {
        width: "5.5rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      key: "courseProviderDetails.CourseProviderName",
      dataIndex: "courseProviderDetails.CourseProviderName",
      title: "Created by".toUpperCase(),
      filterDropdown: (props) =>
        tableSearchData({
          ...props,
          dataIndex: "courseProvider",
        }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.courseProvider)
          .toUpperCase()
          .includes(String(value).toUpperCase());
      },
      sort: true,
      headerStyle: {
        width: "4.5rem",
      },
      render: (cell, row) => cell || row.courseProvider,
    },
    {
      key: "id",
      dataIndex: "id",
      //   title: true.toUpperCase(),
      title: "Edit Course".toUpperCase(),
      sort: false,
      headerAlign: "center",
      csvExport: false,
      headerStyle: {
        width: "4.5rem",
      },
      align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              openEditCourse(row, row.id);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    axios
      .get(dataEndpoint)
      .then((response) => {
        const list = [{ label: "All", value: "all" }];
        serializeDataResponse(response)?.courseProviders.map((item) =>
          list.push({ label: item.value, value: item.id })
        );
        setCourseProviderlist(list);
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setShowLoader(true);
    getCourses(selectedFilter == '1' ? "active" : "archived",pagination.current, pagination.pageSize);
  }, [searchText]);

  useEffect(() => {
    if (selectedCourseProvider && selectedCourseProvider.value !== "all") {
      axios
        .get(
          `/sub-courseprovider/?courseProviderId=${selectedCourseProvider.value}`
        )
        .then((response) => {
          const list = [{ label: "All", value: "all" }];
          response.data.data.map((item) =>
            list.push({ label: item.name, value: item.id })
          );
          setSubCourseProviderList(list);
          if (
            selectedSubCourseProvider &&
            !list.includes(selectedSubCourseProvider)
          ) {
            setSelectedSubCourseProvider(null);
          }
        })
        .catch((error) => {
          setAlertType("error");
          setShowAlert(true);
          setAlert(
            error?.response?.data?.message ||
              "Something went wrong, Please try again."
          );
        });
    } else {
      setSubCourseProviderList([]);
      setSelectedSubCourseProvider(null);
    }
    // eslint-disable-next-line
  }, [selectedCourseProvider]);

  useEffect(() => {
    setTableData([]);
    setShowLoader(true);
    switch (selectedFilter) {
      case "3":
        getCourses("pending");
        settableColumns(pendingCourseColumns);
        break;
      case "2":
        setShowLoader(false);
        getCourses("archived");
        settableColumns(archivedCourseColumns);
        break;
      case "1":
        getCourses();
        settableColumns(activeCourseColumns);
        break;
      default:
        getCourses();
        settableColumns(activeCourseColumns);
        break;
    }
    // eslint-disable-next-line
  }, [selectedCourseProvider, selectedSubCourseProvider, selectedFilter]);

  const getCourses = (status = "active",page=1, pageSize=10) => {
    let params = { courseStatus: status, pageSize:pageSize, pageNumber:page  };
    if(searchText){
      params = {...params,courseName:searchText}
    }
    if (selectedCourseProvider?.value !== "all") {
      params = { ...params, courseProviderId: selectedCourseProvider?.value};
    }
    if (selectedSubCourseProvider?.value !== "all") {
      params = {
        ...params,
        subCourseProviderId: selectedSubCourseProvider?.value,
      };
    }
    axios
      .get(`${config.endPoint.elearning}/course`, {
        params: params,
      })
      .then((response) => {
        setListData(response.data.data);
        setTableData(response.data.data);
        setShowLoader(false);
        setPageCount(response.data.data.length);
        setPagination({
          current: page,
          pageSize,
          total: response.data.total,
        });
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const onAddNewCourse = () => {
    history.push("/Admin/e-learning/AddEditCourse");
  };
  const openEditCourse = (courseDetails, courseId) => {
    history.push(`/Admin/e-learning/AddEditCourse?courseId=${courseId}`);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAssignUserModal(false);
  }

  const onPageFilterChange = (radioValue, target) => {
    setSelectedFilter(target);
  };

  const openAddUserModal = (row) => {
    setCurrentRow(row); //courseId
    setShowAssignUserModal(true);
  };

  //   const openCompletedUserList = (courseId) => {
  //     history.push(`/Admin/e-learning/CompletedUsers/${courseId}`);
  //   };

  const openIncompleteUserList = (courseId) => {
    history.push(`/Admin/e-learning/IncompletedUsers/${courseId}`);
  };

  const openAssignedUserList = (courseId) => {
    history.push(`/Admin/e-learning/AssignedUsers/${courseId}`);
  };

  const handleCourseProvider = (value) => {
    setSelectedCourseProvider(value);
  };

  const handleSubCourseProvider = (value) => {
    setSelectedSubCourseProvider(value);
  };

  const openFileUploadModal = (row, isEdit) => {
    history.push({
      pathname: `/Admin/e-learning/CourseFileUpload/${row}`,
      state: {
        editMode: isEdit,
      },
    });
  };

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setShowAlert(true);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const openCompletedUserList = (courseId) => {
    history.push(`/Admin/e-learning/CompletedUsers/${courseId}`);
  };

  const renderMenuBar = () => {
    return (
      <div className={"elCompleted-header"} style={{ paddingBottom: "2rem" }}>
        <Row>
          <Col lg={8}>
            <Typography
              style={{
                fontFamily: "RakutenRoundSemiBold",
                fontSize: "2.5rem",
              }}
            >
              E-Learning Courses{" "}
              <span
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "RakutenRoundRegular",
                }}
              >
                ({pagination.total})
              </span>
            </Typography>
          </Col>
          <Col lg={7}>
            <div
              style={{
                width: "17rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {renderCourseFilter(selectedFilter, setSelectedFilter)}
            </div>
          </Col>
          <Col lg={1}>{renderDownloadButton()}</Col>
          <Col lg={5}>{renderSearchInput()}</Col>
          <Col lg={3}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={onAddNewCourse}
              className="primary-button-learning-course-list"
            >
              New Course
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const handleSearch = (searchText) => {
    // const filteredData = listData.filter((item) =>
    //   Object.values(item).some((value) =>
    //     String(value).toLowerCase().includes(searchText.toLowerCase())
    //   )
    // );

    // if (searchText === "") {
    //   setTableData(listData);
    // } else {
    //   setTableData(filteredData);
    // }
    debouncedSearch(searchText);
  };

  const renderSearchInput = () => {
    return (
      <Input
        placeholder="Search"
        suffix={<SearchOutlined style={{ fontSize: "16px" }} />}
        style={{ width: "14rem", padding: ".6rem", fontSize: "16px" }}
        onChange={(e) => handleSearch(e.target.value)}
      />
    );
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      createdTimestamp: dayjs
        .unix(data.createdTimestamp)
        .format("DD/MM/YYYY HH:mm:ss"),
      updatedTimestamp: dayjs
        .unix(data.updatedTimestamp)
        .format("DD/MM/YYYY HH:mm:ss"),
    }));
  };
  const unwantedColumns = ["addUsers"];

  const filteredColumns = tableColumns.filter(
    (header) => !unwantedColumns.includes(header.dataIndex)
  );

  const renderDownloadButton = () => {
    const CSVColumns = filteredColumns.map((header, index) => {
      if (header.title === "CREATED BY") {
        return {
          key: "courseProvider",
          label: header.title,
        };
      }

      return {
        key: header.dataIndex,
        label: header.title,
      };
    });

    return (
      <CSVLink
        headers={CSVColumns}
        data={getDataToDownload(listData)}
        filename="E-Learning Courses"
        extension=".csv"
        disabled={listData.length < 1}
      >
        <div
          style={{
            fontSize: 20,
            background: "white",
            padding: ".425rem .7rem",
            borderRadius: "50%",
            marginRight: "1rem",
            cursor: listData.length < 1 ? "not-allowed" : "pointer",
          }}
          disabled={listData.length < 1}
        >
          <DownloadOutlined />
        </div>
      </CSVLink>
    );
  };

  const handleTableChange = (pagination) => {
    setShowLoader(true);
    getCourses(selectedFilter == '1' ? "active" : "archived",pagination.current, pagination.pageSize);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
    }, 500),
    []
  );

  return (
    <div
      style={{
        marginTop: "7rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          className={"admin-alert"}
          onCloseAlert={() => setAlert("")}
        />
      )}
      <Breadcrumb items={crumbs} className={"elCompleted-crumbs"} />
      {renderMenuBar()}
      <div className={"elCompleted-table"}>
        <Table
          scroll={{
            x: 1300,
          }}
          columns={tableColumns}
          dataSource={tableData}
          loading={showLoader}
          pagination={{
            ...pagination,
            position: ['bottomCenter'],
          }}
          onChange={handleTableChange}
        />
      </div>
      {(showAssignUserModal || showErrorModal) && (
        <AntdAssignUserModal
          showAssignUserModal={showAssignUserModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          handleCloseAssignUserModal={() => setShowAssignUserModal(false)}
          handleCloseErrorModal={() => setShowAssignUserModal(false)}
          courseId={currentRow}
          setAlert={setAlert}
          setAlertType={setAlertType}
          setShowAlert={setShowAlert}
          updatePage={getCourses}
          openErrorCloseAddUser={openErrorCloseAddUser}
          callbackCompletedSession={() => {
            getCourses();
          }}
        />
      )}
    </div>
  );
}

export default AntdElearningCourseList;

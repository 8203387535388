import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import "./adminToggleFilter.scss";
import { FaCheck } from "react-icons/fa";

function AdminToggleFilter({
  buttonState,
  clickHandler,
  otherStyle,
  defaultFilter = 0,
}) {
  const [buttonData] = useState(buttonState);
  const [radioValue, setRadioValue] = useState(
    buttonState[defaultFilter].value
  );

  let filterSelected = (e) => {
    setRadioValue(e.currentTarget.value);
    clickHandler(radioValue, e.target.value);
  };
  useEffect(() => {
    setRadioValue(buttonState[defaultFilter].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter]);

  return (
    <>
      <ButtonGroup toggle className={`${otherStyle}`}>
        {buttonData.map((radio, idx) => {
          console.log(radio);
          return (
            <>
              {radioValue === radio.value && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FaCheck
                    className="admin-toggle-filter-icon-color"
                    size={14}
                  />
                </span>
              )}
              <ToggleButton
                key={idx}
                type="radio"
                variant="link"
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => filterSelected(e)}
              >
                {radio.name}
              </ToggleButton>
            </>
          );
        })}
      </ButtonGroup>
    </>
  );
}

AdminToggleFilter.propType = {
  buttonState: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  clickHandler: PropTypes.func.isRequired,
};

export default AdminToggleFilter;

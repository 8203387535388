import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import style from "./lineProgressBar.module.css";
import lockIcon from "../../assets/icons/lock_icon.svg";
import { UserContext } from "../../context/UserContext";

function LineProgressBar({
  currentCount,
  minCount,
  maxCount,
  isPrivate = false,
  isLocked = false,
  clickHandler,
}) {
  const [progressPercent, setprogressPercent] = useState(0);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    let progress = (currentCount / maxCount) * 100;
    setprogressPercent(progress);
  }, [currentCount, maxCount]);

  let lineStyle = {
    backgroundColor: userConfig.primary,
  };

  if (isPrivate) {
    lineStyle = {
      backgroundColor: userConfig.secondary,
    };
  }
  lineStyle.width = `${progressPercent}%`;

  return (
    <>
      <div className={style.container}>
        <div className={style.outerContainer} onClick={clickHandler}>
          <div className={style.countValue}>
            <span className={style.currentCount}>{currentCount} / </span>
            {maxCount}
          </div>
          <div className={style.baseLine}>
            <div className={style.progressLine} style={lineStyle}></div>
          </div>
          <div className={style.minCountValue}>Min {minCount}</div>
        </div>
        {isLocked && (
          <span className={style.iconStyle}>
            <img src={lockIcon} alt="lock" />
          </span>
        )}
      </div>
    </>
  );
}

LineProgressBar.propTypes = {
  currentCount: PropTypes.number,
  minCount: PropTypes.number,
  maxCount: PropTypes.number,
  isPrivate: PropTypes.bool,
  isLocked: PropTypes.bool,
  clickHandler: PropTypes.func,
};

export default LineProgressBar;

/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import style from "../CourseDetail/courseDetail.module.css";
import addIcon from "../../../../src/assets/icons/add_icon.svg";
import minusIcon from "../../../../src/assets/icons/minusIcon.svg";
import IlCourseMoreDetails from "./AntdIlCourseMoreDetails";
import ElCourseMoreDetails from "./AntdElCourseMoreDetails";
import UserCourseInsights from "./AntdUserCourseInsights";
import { useEffect } from "react";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { CSVLink } from "react-csv";
import config from "../../../config";
import { Capitalize } from "../../../utils/stringFormatter";
import "./coursedetail.scss";
import {
  Breadcrumb,
  Tabs,
  Alert,
  Col,
  Row,
  Typography,
  Spin,
  Table,
  Input,
  Button,
} from "antd";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";

function CourseDetails() {
  const [listData, setListData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [courseType, setCourseType] = useState("IL");
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const { userId } = useParams();
  const location = useLocation();
  const firstName = new URLSearchParams(useLocation().search).get("firstName");
  const history = useHistory();
  const { TabPane } = Tabs;
  const [bottom, setBottom] = useState("bottomCenter");
  const [tableData, setTableData] = useState([]);
  const { userConfig } = useContext(UserContext);

  const IlCourseDetailsColumn = [
    {
      dataIndex: "courseId",
      title: "Course ID",
      sorter: (a, b) => a.courseId - b.courseId,
      sortDirections: ["ascend", "descend"],
      width: 200,
      render: (cell, row) => {
        return row.course.identity_id;
      },
    },
    {
      dataIndex: "course.name",
      title: "Course Name",
      classes: style.wrapText,
      sorter: (a, b) => a.course?.name?.localeCompare(b?.course.name),
      width: 80,
      render: (cell, row) => {
        return (
          <Link
            to={`/course-details/${row.courseId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Typography style={{ color: userConfig.primary }} title={cell}>
              {/* {decodeHtmlEntities(cell)} */}
              {row.course.name}
            </Typography>
          </Link>
        );
      },
    },
    {
      dataIndex: "course.category.categoryName",
      title: "Category",
      sorter: (a, b) =>
        a.course?.category?.categoryName?.localeCompare(
          b.course?.category?.categoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <span>{row.course?.category?.categoryName}</span>;
      },
    },
    {
      dataIndex: "course.sub_category.subCategoryName",
      title: "Sub-Category",
      sorter: (a, b) =>
        a.course?.sub_category?.subCategoryName?.localeCompare(
          b.course?.sub_category?.subCategoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <span>{row.course?.sub_category?.subCategoryName}</span>;
      },
    },
    {
      dataIndex: "isRecommended",
      title: "Is Recommended?",

      render: (cell, row) => {
        return <>{cell ? <>YES</> : <>NO</>}</>;
      },
      filterValue: (cell, row) => {
        return cell ? "YES" : "NO";
      },
      sorter: (a, b) => a.isRecommended - b.isRecommended,
      width: 80,
    },
    {
      dataIndex: "courseStatus",
      title: "User Status",
      sorter: (a, b) => a.courseStatus - b.courseStatus,
      width: 80,
    },
  ];

  const ElCourseDetailColumn = [
    {
      dataIndex: "elearning_course.identityId",
      title: "Course ID",
      sorter: (a, b) => a.createdTimestamp - b.createdTimestamp,
      width: 80,
      render: (cell, row) => {
        return row.elearning_course?.identityId;
      },
    },
    {
      dataIndex: "elearning_course.name",
      title: "Course Name",
      classes: style.wrapText,
      sorter: (a, b) =>
        a?.elearning_course?.name?.localeCompare(b?.elearning_course?.name),
      width: 80,
      render: (cell, row) => {
        return (
          <Link
            to={`/e-learning-courses/${row.courseId}`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              style={{ color: userConfig.primary }}
              title={row?.elearning_course?.name}
            >
              {decodeHtmlEntities(row?.elearning_course?.name)}
            </span>
          </Link>
        );
      },
    },
    {
      dataIndex: "elearning_course.category.categoryName",
      title: "Category",
      sorter: (a, b) =>
        a?.elearning_course?.category?.categoryName.localeCompare(
          b?.elearning_course?.category?.categoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <p> {row?.elearning_course?.category?.categoryName}</p>;
      },
    },
    {
      dataIndex: "elearning_course.sub_category.subCategoryName",
      title: "Sub-Category",
      sorter: (a, b) =>
        a?.elearning_course?.sub_category?.subCategoryName?.localeCompare(
          b?.elearning_course?.sub_category?.subCategoryName
        ),
      width: 80,
      render: (cell, row) => {
        return <p> {row?.elearning_course?.sub_category?.subCategoryName}</p>;
      },
    },
    {
      dataIndex: "isRecommended",
      title: "Is recommended?",

      render: (cell, row) => {
        return <>{cell ? <>YES</> : <>NO</>}</>;
      },
      filterValue: (cell, row) => {
        return cell ? "YES" : "NO";
      },
      sorter: (a, b) =>
        String(a?.isRecommended)?.localeCompare(String(b?.isRecommended)),
      width: 80,
    },
    {
      dataIndex: "courseStatus",
      title: "User Status",

      render: (cell, row) => {
        return <>{`${Capitalize(row?.courseStatus || "")}`}</>;
      },
      sorter: (a, b) =>
        a?.elearning_course?.courseStatus?.localeCompare(
          b?.elearning_course?.courseStatus
        ),
      width: 80,
      render: (cell, row) => {
        return <p> {row?.elearning_course?.courseStatus}</p>;
      },
    },
  ];
  const filterMenu = [
    { name: "Instructor-Led", value: "1" },
    { name: "E-Learning", value: "2" },
  ];

  const onPageFilterChange = (radioValue, targetValue) => {
    setListData([]);
    setPageCount(0);
    switch (radioValue) {
      case "1":
        getILCourse();
        break;
      case "2":
        getELCourse();
        break;
      default:
        getILCourse();
        break;
    }
  };

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(activeKey, e) => onPageFilterChange(activeKey, e)}
        className={"coursedetails-tabs"}
      >
        <TabPane tab="Instructor-Led" key="1"></TabPane>
        <TabPane tab="E-Learning" key="2"></TabPane>
      </Tabs>
    );
  };

  const crumbs = [
    {
      title: (
        <a href="/Manager" className="antd-course-detail-crumb">
          Manager Dashboard
        </a>
      ),
      to: "/Manager",
    },
    {
      title: (
        <a href="/Manager/MyTeam" className="antd-course-detail-crumb">
          My Team
        </a>
      ),
      to: "/Manager",
    },
    {
      title: "Course Details",
    },
  ];
  const getILCourse = () => {
    setTableColumns(IlCourseDetailsColumn);
    setCourseType("IL");
    setShowLoader(true);
    axios
      .get(`${config.endPoint.instructorLed}/manager/${userId}/allCourses`)
      .then((response) => {
        setListData(response.data.data);
        setPageCount(response.data.data.length);
        setTableData(response.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const getELCourse = () => {
    setTableColumns(ElCourseDetailColumn);
    setCourseType("EL");
    setShowLoader(true);
    axios
      .get(`${config.endPoint.elearning}/user/${userId}/allCourses`)
      .then((response) => {
        setListData(response.data.data);
        setPageCount(response.data.data.length);
        setTableData(response.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  useEffect(() => {
    getILCourse();
  }, []);

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
  }, [pageLoading]);

  const tableSearch = {
    position: "relative",
    top: "-4.25rem",
    left: "69rem",
    width: "12.5rem",
  };
  const downloadButtonStyle = {
    position: "relative",
    top: "-7rem",
    left: "64rem",
    visibility: "hidden",
  };
  const defaultSorted = [
    courseType === "IL"
      ? {
          dataField: "courseId",
          order: "asc",
        }
      : {
          dataField: "elearning_course.identityId",
          order: "asc",
        },
  ];
  const [tableColumns, setTableColumns] = useState(IlCourseDetailsColumn);

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };
  const tableHeader = (
    <>
      <Row style={{ width: "92%" }}>
        <Col lg={9}>
          <Typography className="coursedetails-pageTitle">
            Course Details - {firstName}
            <span
              style={{
                fontSize: "0.9rem",
                fontFamily: "RakutenRoundRegular",
              }}
            >
              ({showLoader ? <Spin /> : pageCount})
            </span>
          </Typography>
        </Col>

        <Col lg={8} xs={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {toggleFilter()}
          </div>
        </Col>
        <Col lg={1} xs={2} style={{ marginLeft: "7rem" }}>
          <CSVLink
            // filename={csvFileName}
            extension=".csv"
            wrapColumnChar=""
            data={listData}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                marginLeft: "-3rem",
                backgroundColor: "white",
                color: "black",
                fontSize: "1.5rem",
                marginTop: ".5rem",
              }}
            />
          </CSVLink>
        </Col>
        <Col lg={3} xs={12}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              marginTop: ".5rem",
              width: " 10rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
      </Row>
    </>
  );

  const expandRow = {
    renderer: (row) => (
      <>
        {courseType === "IL" ? (
          <Row>
            <Col>
              <IlCourseMoreDetails data={row} name={firstName} />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <ElCourseMoreDetails data={row} />
            </Col>
          </Row>
        )}
      </>
    ),
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return <h6 className={style.moreDetailHeader}>MORE DETAILS</h6>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <img
            className={style.toggleExpandButton}
            src={minusIcon}
            alt="close"
            width="20rem"
          />
        );
      }
      return (
        <img src={addIcon} alt="open" className={style.toggleExpandButton} />
      );
    },
  };

  return (
    <>
      <div className="coursedetail-container">
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <Breadcrumb className="coursedetail-breadcrumb" items={crumbs} />

        <UserCourseInsights
          courseType={courseType}
          userId={userId}
          setAlert={setAlert}
          setAlertType={setAlertType}
        />
        {tableHeader}
        <div className="coursedetail-table">
          {
            <Table
              rowKey="id"
              loading={showLoader}
              dataSource={listData}
              columns={tableColumns}
              expandable={expandRow}
              pagination={{
                position: [bottom],
                showQuickJumper: true,
              }}
            />
          }
        </div>
      </div>
    </>
  );
}

export default CourseDetails;

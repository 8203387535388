import React from "react";

const AddCourseIcon = ({
  strokeColor = "#00A0F0",
  fillColor = "#00A0F0",
  width = "44px",
  height = "43px",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 13 Copy</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Grow_Admin_images-list"
        transform="translate(-887.000000, -519.000000)"
      >
        <g id="Group-13-Copy" transform="translate(889.000000, 521.000000)">
          <ellipse
            id="Oval-Copy"
            stroke={strokeColor}
            strokeWidth="2.5"
            cx="20"
            cy="19.5"
            rx="20"
            ry="19.5"
          ></ellipse>
          <path
            d="M28.4168275,17.916679 L22.0834694,17.916679 L22.0834694,11.583321 C22.0834694,10.7093141 21.3757139,10 20.5001484,10 C19.624583,10 18.9168275,10.7093141 18.9168275,11.583321 L18.9168275,17.916679 L12.5834694,17.916679 C11.7077555,17.916679 11,18.6259932 11,19.5 C11,20.3740068 11.7077555,21.083321 12.583321,21.083321 L18.916679,21.083321 L18.916679,27.416679 C18.916679,28.2906859 19.6244346,29 20.5,29 C21.3755654,29 22.083321,28.2906859 22.083321,27.416679 L22.083321,21.083321 L28.416679,21.083321 C29.2922445,21.083321 30,20.3740068 30,19.5 C30,18.6259932 29.2923929,17.916679 28.4168275,17.916679 Z"
            id="Path-Copy"
            fill={fillColor}
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default AddCourseIcon;

import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import config from "../../../config";
import { Breadcrumb, Button, Col, Form, Row, Select, Typography } from "antd";
import "./AntduserReport.scss";
import { AntDesignRangePicker, Label } from "../../../component-library";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="common-breadcrumbs-color">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  {
    title: "Report",
  },
];

const courseTypeList = [
  {
    label: "E-learning Course",
    value: 1,
    access: [config.userRoleNames.ELAdmin, config.userRoleNames.superAdmin],
  },
  {
    label: "Instructor Led Course",
    value: 2,
    access: [config.userRoleNames.ILAdmin, config.userRoleNames.superAdmin],
  },
  {
    label: "Full Training Report",
    value: 3,
    access: [config.userRoleNames.ILAdmin, config.userRoleNames.superAdmin],
  },
];

function convertToUTF(date) {
  return parseInt(Date.parse(date) / 1000);
}

export default function UserReport(props) {
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [courseType, setCourseType] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const { userRoles } = useContext(UserContext);
  const [dropDownOptions, setDropdownOptions] = useState(courseTypeList);

  useEffect(() => {
    const filteredCourseTypeList = courseTypeList.filter((course) => {
      if (course.access.some((item) => userRoles.includes(item))) {
        return course;
      } else {
        return null;
      }
    });
    setDropdownOptions(filteredCourseTypeList);
  }, [userRoles]);

  useEffect(() => {
    let url = "";
    switch (courseType) {
      case 1:
        url = `${config.endPoint.elearning}/history/${startDate}/${endDate}`;
        break;
      case 2:
        url = `${config.endPoint.instructorLedNew}/user/userHistory/${startDate}/${endDate}`;
        break;
      case 3:
        url = `${config.endPoint.instructorLed}/reports/trainingReport?startDate=${startDate}&endDate=${endDate}`;
        break;
      default:
        url = `${config.endPoint.elearning}/history/${startDate}/${endDate}`;
        break;
    }
    setEndPoint(url);
  }, [startDate, endDate, courseType]);

  const handleDateRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleCourseType = (courseType) => {
    setCourseType(courseType);
  };

  useEffect(() => {
    const errors = {};

    if (
      courseType === 3 &&
      moment(endDate * 1000).diff(moment(startDate * 1000), "days") > 90
    ) {
      errors.range =
        "Full training report cannot be generated for more than 90 days";
    }

    setErrorMessage(errors);
  }, [courseType, startDate, endDate]);

  const submitHandler = () => {
    const errors = {};

    if (!courseType) {
      errors.courseType = "Please select Course Type.";
    }
    if (!startDate || !endDate) {
      errors.range = "Please select start date and end date";
    }

    if (
      courseType === 3 &&
      moment(endDate * 1000).diff(moment(startDate * 1000), "days") > 90
    ) {
      errors.range =
        "Full training report cannot be generated for more than 90 days";
    }

    setErrorMessage(errors);

    if (!Object.keys(errors).length) {
      axios({
        url: endPoint,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${
            courseTypeList.find((c) => c.value === courseType).label
          }-${startDate}-${endDate}.csv`
        );
        document.body.appendChild(link);
        link.click();
      });
    }
  };
  return (
    <div className="userReport-container">
      <Breadcrumb
        className="breadcumb"
        style={{ fontFamily: "RakutenRoundRegular" }}
        items={crumbs}
      />
      <Typography className="userReport-page-title">Admin Report</Typography>
      <Form>
        <Row>
          <Form.Item>
            <Row>
              <Label title="Report Type" required />
            </Row>
            <Row>
              <Select
                className="reportSelect"
                placeholder="Select..."
                options={dropDownOptions}
                onChange={handleCourseType}
              />
            </Row>

            {(errorMessage.courseType && (
              <div className="errorMessageReportValidation">
                {errorMessage.courseType}
              </div>
            )) ||
              ""}
          </Form.Item>
          <Form.Item style={{ marginLeft: "2rem" }}>
            <Label title="Date" required />
            <AntDesignRangePicker
              minValue={9999}
              maxValue={new Date()}
              onChange={handleDateRangeChange}
            />
            <div style={{ marginTop: "2.6rem" }}>
              {(errorMessage.range && (
                <div className="errorMessageReportValidation">
                  {errorMessage.range}
                </div>
              )) ||
                ""}
            </div>
          </Form.Item>
        </Row>
        <Row>
          <Col>
            <Button
              className="clicktoDownloadButton"
              onClick={submitHandler}
              type="button"
              linkHoverBg="black"
              disabled={
                !startDate ||
                !endDate ||
                !courseType ||
                Object.keys(errorMessage).length > 0
              }
            >
              Click to download
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

import { Button } from "antd";
import './downloadIconButton.css'
import * as style from './downloadIconButton.module.css'
import { DownloadOutlined } from "@ant-design/icons";

const AntDesignDownloadIconButton = ({ size, onClick, isDisabled }) => {
  return (
    <Button
      type="circle"
      icon={<DownloadOutlined />}
      size={size || "large"}
      onClick={onClick}
      disabled={isDisabled}
      className={style.whiteBg}
    >
    </Button>
  );
};

export default AntDesignDownloadIconButton;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import "./adminNestedToggleFilter.css";

function AdminNestedToggleFilter({
  parentMenu,
  childMenu,
  clickHandler,
  otherStyle,
  defaultFilter = 0,
}) {
  const [parentMenuData] = useState(parentMenu);
  const [childMenuData] = useState(childMenu);
  const [parentRadioValue, setParentRadioValue] = useState(null);
  const [childRadioValue, setChildRadioValue] = useState(null);
  const [showParentToggle, setShowParentToggle] = useState(true);
  const [showChildToggle, setShowChildToggle] = useState(false);

  useEffect(() => {
    setParentRadioValue(parentMenu[defaultFilter].value);
    setChildRadioValue(null);
    // ToDo fix eslint issue
  }, []);

  const parentFilterChanged = (e) => {
    setParentRadioValue(e.currentTarget.value);
    setChildRadioValue(childMenu[defaultFilter].value);
    setShowParentToggle(false);
    setShowChildToggle(true);
    clickHandler(e.currentTarget.value, childMenu[defaultFilter].value);
  };
  const childFilterChanged = (e) => {
    setChildRadioValue(e.currentTarget.value);
    clickHandler(parentRadioValue, e.currentTarget.value);
  };

  const resetChildFilter = () => {
    setChildRadioValue(null);
    setShowParentToggle(true);
    setShowChildToggle(false);
    clickHandler(parentRadioValue, null);
  };

  return (
    <>
      {childRadioValue && (
        <ButtonGroup toggle className={`${otherStyle}`}>
          <ToggleButton
            onClick={resetChildFilter}
            type="radio"
            variant="link"
            checked={true}
            style={{ height: "fit-content" }}
          >
            {
              parentMenu[
                parentMenu.findIndex((el) => el.value === parentRadioValue)
              ].name
            }
          </ToggleButton>
        </ButtonGroup>
      )}
      {showParentToggle && (
        <ButtonGroup toggle className={`${otherStyle}`}>
          {parentMenuData.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="checkbox"
              variant="link"
              name="checkbox"
              value={radio.value}
              bsPrefix="btn btn-link parent-toggle-btn"
              checked={parentRadioValue === radio.value}
              onChange={(e) => parentFilterChanged(e)}
              style={{
                color: radio.color ? radio.color : null,
                height: "fit-content",
              }}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
      {showChildToggle && (
        <ButtonGroup toggle className={`${otherStyle}`}>
          {childMenuData.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="link"
              name="radio"
              value={radio.value}
              bsPrefix="btn child-toggle-btn"
              checked={childRadioValue === radio.value}
              onChange={(e) => childFilterChanged(e)}
              style={{
                color:
                  radio.color && childRadioValue === radio.value
                    ? radio.color
                    : null,
                height: "fit-content",
              }}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      )}
    </>
  );
}

AdminNestedToggleFilter.propType = {
  parentMenu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  childMenu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  clickHandler: PropTypes.func.isRequired,
};

export default AdminNestedToggleFilter;

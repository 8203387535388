export const getEventTime = (unixTimestamp, embedTime) => {
  // This function is used to convert unix timestamp to readable datetime format
  // Input: 1685502000, Output: May 31, 2023

  const date = new Date(unixTimestamp * 1000); // Convert to milliseconds

  let options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  if (embedTime) {
    options = {
      ...options,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }

  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate;
};

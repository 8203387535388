import React, { useContext, useState } from "react";
import { CourseContext } from "../../../../../context/CourseContext";
import moment from "moment";
import TimeFormaters from "../../../../../utils/timeFormatter";
import config from "../../../../../config";
import axios from "axios";
import { Button, Modal } from "antd";
import { DateTimePicker } from "../../../../../component-library";
import "./antdExpectedDateModal.scss";

function AntdUpdateExpectedDateModal({
  showExpectedDateModal,
  courseData,
  users,
  setShowAlert,
  setAlert,
  setAlertType,
  callbackCompletedSession,
  handleCloseExpectedDateModal,
  setSelectedUsers,
  setSelectedRowKeys,
}) {
  const { userDateFormat } = useContext(CourseContext);
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const dateAfterOneMonth = moment().add(1, "month").format("YYYY-MM-DD");
  const [expectedCompletion, setExpectedCompletion] = useState(
    TimeFormaters.getUtcTimeStamp(dateAfterOneMonth)
  );

  const handleExpectedCompletion = (value) => {
    setExpectedCompletion(TimeFormaters.getUtcTimeStamp(value));
  };

  const submitHandler = () => {
    const endPoint = `${config.endPoint.elearning}/course/updateExpectedCompletion/${courseData.id}`;

    axios
      .patch(endPoint, {
        expectedCompletion:
          expectedCompletion + config.expectedCompletionTimestamp.halfDay,
      })
      .then((response) => {
        callbackCompletedSession();
        handleCloseExpectedDateModal();
        setAlertType("success");
        setShowAlert(true);
        setAlert("Successfully updated expected completion date for the users");
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message);
        setShowAlert(true);
        setAlertType("error");
        handleCloseExpectedDateModal();
      })
      .finally(() => {
        setSelectedUsers([]);
        setSelectedRowKeys([]);
      });
  };

  return (
    <div>
      <Modal
        open={showExpectedDateModal}
        onCancel={handleCloseExpectedDateModal}
        title="Update expected completion date"
        footer={null}
        width="60rem"
      >
        <div style={{ backgroundColor: "#f7f7f7", padding: "2rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            &nbsp;
            <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
              {courseData.identityId}
            </b>
            &nbsp; - &nbsp;
            <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
              {courseData.name} &nbsp;
            </b>
            <br />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", margin: "2rem" }}
          >
            <div style={{ width: "50%" }}>
              {users.length ? (
                <p>Following users will be affected:</p>
              ) : (
                <p>
                  There are no users to update the expected completion date.
                </p>
              )}
              {/* <div className={style.usersList}> */}
              <div>
                <ol>
                  {users.map((user) => {
                    return (
                      <li>{`${user.primaryEmail} - (${TimeFormaters.FormatDate(
                        user.expectedCompletionDate,
                        null,
                        userDateFormat
                      )})`}</li>
                    );
                  })}
                </ol>
              </div>
            </div>
            <div>
              <span>New Expected Completion Date</span>
              <DateTimePicker
                minValue={tomorrow}
                value={dateAfterOneMonth}
                allowInput={true}
                clickOpens={true}
                onChange={(expectedCompletion) => {
                  handleExpectedCompletion(expectedCompletion);
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={submitHandler}
            //   disabled={!expectedCompletion || !users.length}
            className="antd-expected-date-modal"
            type="button"
          >
            Update Expected Completion
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AntdUpdateExpectedDateModal;

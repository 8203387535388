import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AdminPageTitle,
  AntDesignBreadCrumb,
  AntDesignContainer,
} from "../../../component-library";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import "./learningHistory.scss";
import { SearchOutlined } from "@ant-design/icons";

import toggleIcon from "./../../../assets/icons/toggle_down_icon.svg";
import toggleBlueIcon from "./../../../assets/icons/toggle_down_icon_blue.svg";

import {
  crumbs,
  getDynamicFilterList,
  getUserCourseListColumns,
} from "./learnerHistoryUtils";
import truncate from "lodash/truncate";
import {
  getDepartments,
  getEmployeeCourseDetails,
  getUserList,
} from "../../../api/learnerHistory";
import { CourseContext } from "../../../context/CourseContext";

const sortOrders = ["asc", "desc", "default"];

function LearnerHistory() {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchType, setSearchType] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [search, setSearch] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const [sort, setSort] = useState("default");
  const [userSize, setUserSize] = useState(null);
  const [userList, setUserList] = useState([]);
  const [globalSearchString, setGlobalSearchString] = useState("");
  const [userCourseDetails, setUserCourseDetails] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departments, setDepartments] = useState([]);
  const { countryList } = useContext(CourseContext);

  const searchInput = useRef(null);
  const userCourseNameInput = useRef(null);

  const sanitizeSearchObj = () => {
    let searchObj;
    let { country,...restSearch } = search;
    if(country?.length){
      let countriesArr = country?.split(',');
      let countryCode = countryList.filter(countryObj => countriesArr.includes(countryObj.country)).map(item => item.code).join(',');
      searchObj = {...restSearch,country:countryCode}
    }else{
      searchObj = search;
    }
    return searchObj;
  }

  useEffect(() => {
    let searchObj = sanitizeSearchObj();
    async function getUserListData() {
      const { userList, userSize } = await getUserList({
        pageNumber,
        pageSize,
        search:searchObj,
        sort,
        sortBy,
      });
      setUserList(userList);
      setUserSize(userSize);
    }

    getUserListData();
  }, [pageNumber, pageSize, search, sort, sortBy]);

  useEffect(() => {
    async function getDepartmentList() {
      const departments = await getDepartments();

      // ignoring department with value "null"
      const updatedDepartments = departments.filter(
        (departmentName) => departmentName !== null
      );
      setDepartments(updatedDepartments);
    }

    getDepartmentList();
  }, []);

  useEffect(() => {
    setSearch(globalSearchString);
  }, [globalSearchString]);

  useEffect(() => {
    async function getUserCourseDetails() {
      if (selectedEmployee) {
        const courseDetails = await getEmployeeCourseDetails(selectedEmployee);
        const updatedCourseDetails = courseDetails.map((course) => {
          const { categoryName, subCategoryName } = course;
          if (categoryName === null || subCategoryName === null) {
            return {
              ...course,
              categoryName: "",
              subCategoryName: "",
            };
          }
          return course;
        });

        setUserCourseDetails(updatedCourseDetails);
      }
    }

    getUserCourseDetails();
  }, [selectedEmployee]);

  const getColumnSearchProps = (dataIndex, name, email, id) => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        return (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => {
                setSearch({
                  ...search,
                  [dataIndex]: selectedKeys[0],
                });

                if (!selectedKeys || !selectedKeys?.length > 0) {
                  clearFilters && clearFilters();
                }
                close();
              }}
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm();
                  setSearch({
                    ...search,
                    [dataIndex]: selectedKeys[0],
                  });
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 85,
                }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  setSearch({});
                  setSearchString(null);
                  setSearchType(null);
                  setSelectedKeys([]);
                  clearFilters && clearFilters();
                }}
                size="small"
                style={{
                  width: 75,
                }}
                disabled={selectedKeys[0] === undefined}
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered) => {
        return (
          <SearchOutlined
            style={{
              color:
                search[dataIndex] && search[dataIndex]?.length > 0
                  ? "#1677ff"
                  : "#9C9C9C",
            }}
          />
        );
      },
      onFilter: (value, record) => {
        if (dataIndex === "department" || dataIndex === "country") {
          return record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
        return true;
      },
      filtered: search[dataIndex]?.length > 0,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text, record) => {
        let classname;
        if (id === "courseName") {
          classname = `record-title ${
            record.type === "EL"
              ? " el-course-name"
              : record.isPrivate
              ? "il-private-course-name"
              : " il-course-name"
          }`;
        } else {
          classname = `record-title`;
        }

        const recordName =
          id === "courseName"
            ? truncate(record[name], { length: 40 })
            : record[name];

        return (
          <div>
            <Typography className={classname}>{recordName}</Typography>
            {record[email] && <Typography>{record[email]}</Typography>}
          </div>
        );
      },
    };
  };

  const countryFilters = getDynamicFilterList("country", countryList);
  const departmentFilters = getDynamicFilterList("department", userList);
  const courseTypeFilter = getDynamicFilterList("type", userCourseDetails);
  const statusFilter = getDynamicFilterList("status", userCourseDetails);
  const categoryFilter = getDynamicFilterList(
    "categoryName",
    userCourseDetails
  );
  const subCategoryFilter = getDynamicFilterList(
    "subCategoryName",
    userCourseDetails
  );

  const getUpdatedSort = (sortByFilter) => {
    let updatedSort;
    if (sortBy === sortByFilter) {
      const sortIndex = sortOrders.findIndex((item) => item === sort);
      updatedSort = sortOrders[sortIndex === 2 ? 0 : sortIndex + 1];
    } else {
      updatedSort = sortOrders[0];
    }

    return updatedSort;
  };

  const columns = [
    {
      title: "EMP ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sorter: () => {
        const sortIndex = sortOrders.findIndex((item) => item === sort);
        const updatedSort = sortOrders[sortIndex === 2 ? 0 : sortIndex + 1];

        setSort(updatedSort);
        setSortBy("id");
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("id", "id"),
    },
    {
      title: "EMPLOYEE NAME",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, record) => {
        return (
          <div>
            <span className="record-title">{record.name} </span>
            <span>{record.email} </span>
          </div>
        );
      },
      sorter: () => {
        const updatedSort = getUpdatedSort("name");
        setSort(updatedSort);
        setSortBy("name");
      },
      filterSearch: true,
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("name", "name", "email"),
    },
    {
      title: "DEPARTMENT",
      dataIndex: "department",
      key: "department",
      sorter: () => {
        const updatedSort = getUpdatedSort("department");
        setSort(updatedSort);
        setSortBy("department");
      },
      sortDirections: ["ascend", "descend"],
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        let filteredDepartments = [...departments];
        if (globalSearchString.length > 0 && selectedDepartments.length > 0) {
          setSelectedDepartments([]);
        }
        if (selectedKeys.length > 0) {
          const searchedString = selectedKeys[0];

          filteredDepartments = filteredDepartments?.filter((department) => {
            return department
              ?.toLowerCase()
              ?.includes(searchedString?.toLowerCase());
          });
        }

        return (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <div style={{ height: "200px", overflow: "scroll" }}>
              <Input
                ref={searchInput}
                placeholder={`Search department`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                style={{
                  marginBottom: 8,
                  display: "block",
                }}
              />
              {filteredDepartments?.map((departmentName) => {
                return (
                  <div style={{ marginBottom: 8 }}>
                    <Checkbox
                      onChange={() => {
                        setGlobalSearchString("");
                        let updatedSelectedDepartments = [
                          ...selectedDepartments,
                        ];

                        if (
                          updatedSelectedDepartments.includes(departmentName)
                        ) {
                          updatedSelectedDepartments =
                            updatedSelectedDepartments.filter(
                              (department) => department !== departmentName
                            );
                        } else {
                          updatedSelectedDepartments = [
                            ...updatedSelectedDepartments,
                            departmentName,
                          ];
                        }
                        setSelectedDepartments(updatedSelectedDepartments);
                      }}
                      key={departmentName}
                      checked={selectedDepartments.includes(departmentName)}
                    >
                      {departmentName}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
            <Row
              gutter={[12, 12]}
              style={{ paddingTop: 4, borderTop: "1px solid #EBEBEB" }}
            >
              <Col span={12}>
                <Button
                  size="small"
                  type="text"
                  style={{ width: "100%" }}
                  disabled={selectedDepartments.length < 1}
                  onClick={() => {
                    setSelectedDepartments([]);
                    setSelectedKeys([]);
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="small"
                  type="primary"
                  style={{ width: "100%", background: "#1AAAF2" }}
                  onClick={() => {
                    setSearch({
                      ...search,
                      department: selectedDepartments.toString(),
                    });
                    setSelectedKeys([]);
                    close();
                  }}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      filters: departmentFilters,
      filtered: selectedDepartments.length > 0,
      filterSearch: true,
      onFilter: (value, record) => {
        if (globalSearchString.length >= 0 && searchType === "name") {
          return true;
        }

        return record?.country?.indexOf(value) === 0;
      },
      width: "10%",
      render: (text, record) => (
        <span>{record?.Department?.name || "N/A"} </span>
      ),
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "designationTitle",
      sorter: () => {
        const updatedSort = getUpdatedSort("role");
        setSort(updatedSort);
        setSortBy("role");
      },
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <Typography>{record?.designation || "N/A"} </Typography>
      ),
      width: "20%",
    },
    {
      title: "REPORTING MANAGER",
      dataIndex: "managerName",
      key: "manager",
      sorter: () => {
        const updatedSort = getUpdatedSort("managerName");
        setSort(updatedSort);
        setSortBy("managerName");
      },
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("managerName", "managerName", "managerEmail"),
      width: "20%",
      render: (text, record) => (
        <Typography>{record.manager.managerName || "N/A"} </Typography>
      ),
    },
    {
      title: "COUNTRY",
      dataIndex: "country",
      width: "15%",
      key: "country",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        let filteredCountryList = [...countryList];
        if (globalSearchString.length > 0 && selectedCountries.length > 0) {
          setSelectedCountries([]);
        }
        if (selectedKeys.length > 0) {
          const searchedString = selectedKeys[0];

          filteredCountryList = filteredCountryList.filter(({ country }) =>
            country.toLowerCase().includes(searchedString.toLowerCase())
          );
        }

        return (
          <div
            style={{
              padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <div style={{ height: "200px", overflow: "scroll" }}>
              <Input
                ref={searchInput}
                placeholder={`Search country`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                style={{
                  marginBottom: 8,
                  display: "block",
                }}
              />
              {filteredCountryList.map(({ country }) => {
                return (
                  <div style={{ marginBottom: 8 }}>
                    <Checkbox
                      onChange={() => {
                        setGlobalSearchString("");
                        let updatedSelectedCountries = [...selectedCountries];

                        if (updatedSelectedCountries.includes(country)) {
                          updatedSelectedCountries =
                            updatedSelectedCountries.filter(
                              (countryName) => country !== countryName
                            );
                        } else {
                          updatedSelectedCountries = [
                            ...updatedSelectedCountries,
                            country,
                          ];
                        }
                        setSelectedCountries(updatedSelectedCountries);
                      }}
                      key={country}
                      checked={selectedCountries.includes(country)}
                    >
                      {country}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
            <Row
              gutter={[12, 12]}
              style={{ paddingTop: 4, borderTop: "1px solid #EBEBEB" }}
            >
              <Col span={12}>
                <Button
                  size="small"
                  type="text"
                  style={{ width: "100%" }}
                  disabled={selectedCountries.length < 1}
                  onClick={() => {
                    setSelectedCountries([]);
                    setSelectedKeys([]);
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="small"
                  type="primary"
                  style={{ width: "100%", background: "#1AAAF2" }}
                  onClick={() => {
                    setSearch({
                      ...search,
                      country: selectedCountries.toString(),
                    });
                    setSelectedKeys([]);
                    close();
                  }}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      filters: countryFilters,
      sorter: () => {
        const updatedSort = getUpdatedSort("country");
        setSort(updatedSort);
        setSortBy("country");
      },
      sortDirections: ["ascend", "descend"],
      filterSearch: true,
      filtered: selectedCountries.length > 0,
      onFilter: (value, record) => {
        if (globalSearchString.length >= 0 && searchType === "name") {
          return true;
        }

        return record?.country?.indexOf(value) === 0;
      },
      render: (text, record) => <span>{record.country || "N/A"} </span>,
    },
  ];

  const renderBreadCrumbs = () => (
    <Row style={{ marginBottom: "1rem" }}>
      <AntDesignBreadCrumb crumbs={crumbs} />
    </Row>
  );

  const renderTitle = () => (
    <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={[12, 12]}>
      <Col span={20}>
        <AdminPageTitle
          pageTitle="Learner History"
          pageCount={userSize}
          extraMargin={false}
          overrideFont={true}
        />
      </Col>
      <Col span={4} className="learner-search">
        <Input
          placeholder="Search"
          type="text"
          value={globalSearchString}
          onChange={(event) => {
            setPageNumber(1);
            setGlobalSearchString(event.target.value);
            setSearchString(event.target.value);
            setSearchType("name");
          }}
          suffix={<SearchOutlined />}
          className="global-search"
        />
      </Col>
    </Row>
  );

  const renderTableContent = () => (
    <Row style={{ marginBottom: "1rem" }}>
      <Table
        className="custom-expand-icon"
        dataSource={[...userList]}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          if (filters?.country?.length > 0) {
            setGlobalSearchString("");
            setSearchType("country");
            setSearchString(filters?.country?.toString());
          }

          if (sorter?.order === undefined) {
            setSort(undefined);
            setSortBy("name");
          }
        }}
        filtered={true}
        onFilter={(value, record) => {}}
        pagination={{
          position: ["bottomCenter"],
          total: userSize,
          defaultCurrent: pageNumber,
          current: pageNumber,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: (newPageNumber) => setPageNumber(newPageNumber || 1),
          onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
          showTotal: (total) => `Total ${userSize} items`,
        }}
        expandIconColumnIndex={7}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <img
              className="expand-icon"
              style={expanded ? { transform: "rotate(180deg)" } : null}
              src={expanded ? toggleBlueIcon : toggleIcon}
              alt={expanded ? "close" : "open"}
              onClick={(e) => onExpand(record, e)}
            />
          ),

          expandedRowRender: (record) => {
            const { id } = record;
            setSelectedEmployee(id);

            const userCourseListColumns = getUserCourseListColumns(
              courseTypeFilter,
              categoryFilter,
              subCategoryFilter,
              statusFilter,
              getColumnSearchProps,
              userCourseNameInput
            );

            return (
              <Table
                className="inner-table-content"
                columns={userCourseListColumns}
                dataSource={userCourseDetails}
                rowClassName={(record) =>
                  record.type === "EL"
                    ? "table-row-EL"
                    : record.isPrivate
                    ? "table-row-private-IL"
                    : "table-row-IL"
                }
                pagination={{
                  position: ["bottomCenter"],
                }}
              />
            );
          },
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
      />
    </Row>
  );

  return (
    <AntDesignContainer>
      <Col>
        {renderBreadCrumbs()}
        {renderTitle()}
        {renderTableContent()}
      </Col>
    </AntDesignContainer>
  );
}

export default LearnerHistory;

import { AutoComplete, Button, DatePicker, Input, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getUserList } from "./employeeManagementApis";
import axios from "axios";
import config from "../../../config";
import { uniqBy } from "lodash";
import { UserContext } from "../../../context/UserContext";
import dayjs from "dayjs";

const RenderEmployeeEditForm = ({
  modifiedRowContent,
  setModifiedRowContent,
  setShowEditModal,
  updateTableData,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [departmentApiData, setDepartmentApiData] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;

    axios
      .get(dataEndpoint)
      .then((res) => {
        const { data } = res.data;
        const { countries } = data;

        if (countries.length > 0) {
          setCountryOptions(countries);
        }

        getUserList().then((newUserList) => {
          setUserList(newUserList.data);
          const user = getManagerDataFromEmail(
            modifiedRowContent?.managerEmail,
            newUserList.data
          );

          setModifiedRowContent({
            ...modifiedRowContent,
            manager: user,
            firstName: modifiedRowContent?.name.split(" ")[0],
            lastName: modifiedRowContent?.name.split(" ")[1],
            joinedDate: {
              dateObj: dayjs(modifiedRowContent?.joinedDate),
            },
            officeLocationName: modifiedRowContent?.officeLocation,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const endpoint = `${config.endPoint.userService}/api/departments`;

    axios
      .get(endpoint)
      .then((res) => {
        const { data } = res.data;
        setDepartmentApiData(data);

        const companyOptions = data.map(({ company, companyID }) => ({
          value: company,
          label: company,
          companyID,
        }));

        const departmentOptions = data.map(({ name, id }) => ({
          value: name,
          label: name,
          deptId: id,
        }));

        setCompanyOptions(uniqBy(companyOptions, "value"));
        setDeptOptions(departmentOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filteredDeptOptions = departmentApiData?.filter(
      (deptData) => deptData.company === modifiedRowContent.company
    );
    const mappedDeptOptions = filteredDeptOptions.map(({ name, id }) => ({
      value: name,
      label: name,
      deptId: id,
    }));

    setDeptOptions(mappedDeptOptions);
  }, [modifiedRowContent.company]);

  const getManagerDataFromEmail = (email, newUserList) => {
    const managerData = newUserList.find((user) => user.email === email);

    return managerData;
  };

  const handleInputChange = (key, value) => {
    if (key === "officeLocation") {
      setModifiedRowContent({
        ...modifiedRowContent,
        officeLocationName: value,
      });
    }
    setModifiedRowContent({ ...modifiedRowContent, [key]: value });
  };

  const renderInpuField = (label, key, placeholder) => {
    return (
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={placeholder}
          value={modifiedRowContent[key]}
          onChange={(e) => handleInputChange(key, e.target.value)}
          style={{ borderRadius: 0 }}
        />
      </div>
    );
  };

  const renderSelectField = (
    label,
    key,
    defaultValue,
    options,
    disabled = false
  ) => {
    const validOptions = options.filter((option) => option.value);

    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          defaultValue={defaultValue}
          placeholder="Select..."
          disabled={disabled}
          style={{
            width: 120,
            borderRadius: 0,
          }}
          onChange={(value, row) => {
            let updatedEmployeeDetails = {
              ...modifiedRowContent,
              [key]: value,
            };

            if (key === "company") {
              updatedEmployeeDetails["department"] = null;
            }

            setModifiedRowContent(updatedEmployeeDetails);
          }}
          value={modifiedRowContent[key]}
          options={validOptions}
        />
      </div>
    );
  };

  const filterManagerSuggestion = (text) => {
    const tempUserList = [...userlist];

    const filteredUserList = tempUserList.filter(({ email = "" }) => {
      return email.toLowerCase().includes(text.toLowerCase());
    });

    const mappedManagerOptions = filteredUserList.map((user) => ({
      ...user,
      value: user.email,
    }));
    setManagerOptions(mappedManagerOptions);
  };

  const getCountryOptions = () =>
    countryOptions.map((countryData) => ({
      value: countryData?.code,
      label: countryData?.country,
    }));

  const mappedEmpStatus =
    modifiedRowContent.empStatus === "active"
      ? "1"
      : modifiedRowContent.empStatus === "inactive"
      ? "0"
      : "2";

  const filteredDeptOptions = deptOptions.find((dep) => {
    return dep.value === modifiedRowContent?.department;
  });

  const filteredCompanyOptions = companyOptions.find((company) => {
    return company.value === modifiedRowContent.company;
  });

  const disabledDate = (current) => {
    // Can not select days before today and today
    if (modifiedRowContent.empStatus === "preactive")
      return current && current < dayjs().endOf("day");

    return false;
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {renderInpuField("First Name", "firstName", "Enter first name...")}
        {renderInpuField("Last Name", "lastName", "Enter last name...")}
        {renderInpuField("Emp Mail ID", "email", "Enter Employee Mail Id...")}
        {renderSelectField(
          "Country",
          "country",
          modifiedRowContent.country,
          getCountryOptions()
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "90%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "33%" }}>
          {renderSelectField(
            "Company",
            "company",
            modifiedRowContent.company,
            companyOptions
          )}
        </div>
        <div style={{ width: "33%" }}>
          {renderSelectField(
            "Department",
            "department",
            modifiedRowContent.department,
            deptOptions,
            !modifiedRowContent.company
          )}
        </div>
        {renderInpuField("Office", "officeLocation", "Enter Office Address...")}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
          width: "90%",
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          Manager
          <span style={{ color: "red" }}>*</span>
        </span>
        <AutoComplete
          options={uniqBy(managerOptions, "email")}
          style={{
            width: "100%",
          }}
          onSelect={(data, row) => {
            setModifiedRowContent({ ...modifiedRowContent, manager: row });
          }}
          onSearch={(text) => filterManagerSuggestion(text)}
          placeholder="Enter Manager Email.."
          value={modifiedRowContent?.manager?.email}
          filterOption={(text, option) => {
            return option.email.toLowerCase().includes(text.toLowerCase());
          }}
          onChange={(data, row) => {
            setModifiedRowContent({
              ...modifiedRowContent,
              manager: { ...modifiedRowContent?.manager, email: data },
            });
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          Emp Status
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          placeholder="Emp Status..."
          style={{
            width: 120,
          }}
          onChange={(value) =>
            setModifiedRowContent({ ...modifiedRowContent, empStatus: value })
          }
          options={[
            {
              value: "preactive",
              label: "Pre Active",
            },
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
          ]}
          value={modifiedRowContent?.empStatus}
        />
      </div>

      {renderInpuField(
        "Emp Role",
        "designation",
        "Enter Employee Designation..."
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
          width: "50%",
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          Hire Date
          <span style={{ color: "red" }}>*</span>
        </span>
        <DatePicker
          disabledDate={disabledDate}
          value={modifiedRowContent?.joinedDate?.dateObj}
          onChange={(date, dateStr) => {
            setModifiedRowContent({
              ...modifiedRowContent,
              joinedDate: { dateObj: date, dateStr },
            });
          }}
        />
      </div>

      <div style={{ marginTop: 8, display: "flex", justifyContent: "end" }}>
        <Button
          style={{ margin: 8, borderRadius: 0 }}
          onClick={() => setShowEditModal(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          style={{ margin: 8, borderRadius: 0, background: userConfig.primary }}
          onClick={async () => {
            const endpoint = `${config.endPoint.userService}/api/user/${modifiedRowContent.id}`;

            const updatedPayload = {
              empID: modifiedRowContent.id,
              firstName: modifiedRowContent.firstName,
              lastName: modifiedRowContent.lastName,
              email: modifiedRowContent.email,
              country: modifiedRowContent.country,
              designationTitle: modifiedRowContent.designation,
              officeLocationName: modifiedRowContent.officeLocationName,
              officeLocationName: modifiedRowContent.officeLocation,
              empStatus: mappedEmpStatus,
              managerID: modifiedRowContent.manager.id,
              departmentID: filteredDeptOptions.deptId,
              companyID: filteredCompanyOptions.companyID,
              joinedDate: modifiedRowContent?.joinedDate?.dateStr,
            };

            const updateResponse = await axios.patch(endpoint, updatedPayload);
            if (updateResponse.status) {
              updateTableData();
            }
            setShowEditModal(false);
          }}
          disabled={
            !modifiedRowContent.id ||
            !modifiedRowContent.firstName ||
            !modifiedRowContent.lastName ||
            !modifiedRowContent.email ||
            !modifiedRowContent.country ||
            !modifiedRowContent.designation ||
            !modifiedRowContent.officeLocationName ||
            !mappedEmpStatus ||
            !modifiedRowContent?.manager?.id ||
            !filteredDeptOptions?.deptId ||
            !filteredCompanyOptions?.companyID
          }
        >
          Update
        </Button>
      </div>
    </div>
  );
};

const RenderCompanyEditForm = ({
  modifiedRowContent,
  setModifiedRowContent,
  setShowEditModal,
  updateTableData,
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;

    axios
      .get(dataEndpoint)
      .then((res) => {
        const { data } = res.data;
        const { countries } = data;

        if (countries.length > 0) {
          setCountryOptions(countries);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getCountryOptions = () =>
    countryOptions.map((countryData) => ({
      value: countryData?.code,
      label: countryData?.country,
    }));

  const renderSelectField = (
    label,
    key,
    defaultValue,
    options,
    disabled = false
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          defaultValue={defaultValue}
          placeholder="Select..."
          disabled={disabled}
          style={{
            width: 120,
            borderRadius: 0,
          }}
          onChange={(value) => {
            let updatedDetails = {
              ...modifiedRowContent,
              [key]: value,
            };

            setModifiedRowContent(updatedDetails);
          }}
          value={modifiedRowContent[key]}
          options={options}
        />
      </div>
    );
  };
  return (
    <div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          Company Name
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={"Enter Company Name..."}
          value={modifiedRowContent?.name}
          onChange={(e) =>
            setModifiedRowContent({
              ...modifiedRowContent,
              name: e.target.value,
            })
          }
          style={{ borderRadius: 0 }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: 12, color: "gray", marginTop: 8 }}>
          Company Status
          <span style={{ color: "red" }}>*</span>
        </span>

        <Select
          placeholder="Select Company Status..."
          style={{
            width: "50%",
          }}
          className="add-dept-select"
          onChange={(value) =>
            setModifiedRowContent({ ...modifiedRowContent, status: value })
          }
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
          ]}
          value={modifiedRowContent.status}
        />
      </div>
      {renderSelectField(
        "Country",
        "country",
        modifiedRowContent.country,
        getCountryOptions()
      )}
      <div style={{ marginTop: 8, display: "flex", justifyContent: "end" }}>
        <Button
          style={{ margin: 8, borderRadius: 0 }}
          onClick={() => setShowEditModal(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          style={{
            margin: 8,
            borderRadius: 0,
            background: userConfig.primary,
          }}
          onClick={async () => {
            const endpoint = `${config.endPoint.userService}/api/companies/${modifiedRowContent.id}`;

            const updatedPayload = {
              name: modifiedRowContent.name,
              statusCode: modifiedRowContent.status === "active" ? "1" : "0",
              country: modifiedRowContent.country,
              companyID: modifiedRowContent.id,
              id: modifiedRowContent.id,
            };

            const updateResponse = await axios.patch(endpoint, updatedPayload);
            if (updateResponse.status) {
              updateTableData();
            }
            setShowEditModal(false);
          }}
          disabled={
            !modifiedRowContent.name ||
            !modifiedRowContent.status ||
            !modifiedRowContent.country
          }
        >
          Update
        </Button>
      </div>
    </div>
  );
};

const RenderDepartmentEditForm = ({
  modifiedRowContent,
  setModifiedRowContent,
  setShowEditModal,
  updateTableData,
}) => {
  const [managerOptions, setManagerOptions] = useState([]);
  const { userConfig } = useContext(UserContext);
  const [userlist, setUserList] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    getUserList().then((newUserList) => {
      setUserList(newUserList.data);
    });
  }, []);

  useEffect(() => {
    const endpoint = `${config.endPoint.userService}/api/companies`;

    axios
      .get(endpoint)
      .then((res) => {
        const { data } = res.data;

        const companyOptions = data.map(({ name, id }) => ({
          value: id,
          label: name,
          companyID: id,
        }));

        setCompanyOptions(uniqBy(companyOptions, "value"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const status = modifiedRowContent.status;
    setModifiedRowContent({
      ...modifiedRowContent,
      deptStatus: getDepartmentStatus(status),
    });
  }, []);

  const filterManagerSuggestion = (text) => {
    const tempUserList = [...userlist];

    const filteredUserList = tempUserList.filter(({ email = "" }) => {
      return email.toLowerCase().includes(text.toLowerCase());
    });

    const mappedManagerOptions = filteredUserList.map((user) => ({
      ...user,
      value: user?.email,
    }));
    setManagerOptions(mappedManagerOptions);
  };

  const getDeptHeadId = (email) => {
    const user = userlist.find((user) => user.email === email);
    return user.id;
  };

  const getDepartmentStatus = (deptStatus) => {
    return deptStatus.toLowerCase() === "active" ? "1" : "0";
  };

  return (
    <div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          Dept Name
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={"Enter Dept Name..."}
          value={modifiedRowContent?.name}
          onChange={(e) =>
            setModifiedRowContent({
              ...modifiedRowContent,
              name: e.target.value,
            })
          }
          style={{ borderRadius: 0 }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: 12, color: "gray", marginTop: 8 }}>
          Dept Status
          <span style={{ color: "red" }}>*</span>
        </span>

        <Select
          placeholder="Select Dept Status..."
          style={{
            width: "50%",
          }}
          className="add-dept-select"
          onChange={(value) =>
            setModifiedRowContent({
              ...modifiedRowContent,
              deptStatus: value,
            })
          }
          options={[
            {
              value: "1",
              label: "Active",
            },
            {
              value: "0",
              label: "Inactive",
            },
          ]}
          value={modifiedRowContent.deptStatus}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          Dept Head Email
          <span style={{ color: "red" }}>*</span>
        </span>
        <AutoComplete
          options={uniqBy(managerOptions, "email")}
          style={{
            width: "50%",
            borderRadius: 0,
          }}
          filterOption={(text, option) => {
            return option.email.toLowerCase().includes(text.toLowerCase());
          }}
          onSelect={(data, row) => {
            setModifiedRowContent({
              ...modifiedRowContent,
              departmentheadEmail: row.email,
            });
          }}
          value={modifiedRowContent.departmentheadEmail}
          onChange={(data) => {
            setModifiedRowContent({
              ...modifiedRowContent,
              departmentheadEmail: data,
            });
          }}
          onSearch={(text) => filterManagerSuggestion(text)}
          placeholder="Enter Dept Head Email.."
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: 12, color: "gray", marginTop: 8 }}>
          Company Name
          <span style={{ color: "red" }}>*</span>
        </span>

        <Select
          placeholder="Select Company..."
          style={{
            width: "50%",
          }}
          className="add-dept-select"
          onChange={(value) =>
            setModifiedRowContent({
              ...modifiedRowContent,
              companyID: value,
            })
          }
          options={companyOptions}
          value={modifiedRowContent.companyID}
        />
      </div>
      <div style={{ marginTop: 8, display: "flex", justifyContent: "end" }}>
        <Button
          style={{ margin: 8, borderRadius: 0 }}
          onClick={() => setShowEditModal(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          style={{ margin: 8, borderRadius: 0, background: userConfig.primary }}
          onClick={async () => {
            const endpoint = `${config.endPoint.userService}/api/departments/${modifiedRowContent.id}`;
            const updatedPayload = {
              statusCode: modifiedRowContent?.deptStatus,
              name: modifiedRowContent.name,
              departmentheadId: getDeptHeadId(
                modifiedRowContent.departmentheadEmail
              ),
              companyID: modifiedRowContent.companyID,
            };

            const updateResponse = await axios.patch(endpoint, updatedPayload);
            if (updateResponse.status) {
              updateTableData();
            }
            setShowEditModal(false);
          }}
          disabled={
            !modifiedRowContent?.deptStatus ||
            !modifiedRowContent.name ||
            !modifiedRowContent.departmentheadEmail ||
            !modifiedRowContent.companyID
          }
        >
          Update
        </Button>
      </div>
    </div>
  );
};

function EditModalBody({ tabItem, row, setShowEditModal, updateTableData }) {
  const [modifiedRowContent, setModifiedRowContent] = useState(row);

  return (
    <div>
      {tabItem === "emp" && (
        <RenderEmployeeEditForm
          modifiedRowContent={modifiedRowContent}
          setShowEditModal={setShowEditModal}
          setModifiedRowContent={setModifiedRowContent}
          updateTableData={updateTableData}
        />
      )}
      {tabItem === "companyList" && (
        <RenderCompanyEditForm
          modifiedRowContent={modifiedRowContent}
          setModifiedRowContent={setModifiedRowContent}
          setShowEditModal={setShowEditModal}
          updateTableData={updateTableData}
        />
      )}

      {tabItem === "department" && (
        <RenderDepartmentEditForm
          modifiedRowContent={modifiedRowContent}
          setModifiedRowContent={setModifiedRowContent}
          setShowEditModal={setShowEditModal}
          updateTableData={updateTableData}
        />
      )}
    </div>
  );
}

export default EditModalBody;

import { Col, Row, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  AntDesignNotifcation,
  AntDesignRoundProgressBar,
  AntDesignTable,
} from "../../../../component-library";
import style from "./learningPathsList.module.css";
import { decodeHtmlEntities } from "../../../../utils/commonUtility";
import LearningPathInfoCard from "./LearningPathInfoCard";
import axios from "axios";
import config from "../../../../config";
import TimeFormaters from "../../../../utils/timeFormatter";
import { CourseContext } from "../../../../context/CourseContext";

const LearningPathDetail = ({ id, archived }) => {
  const [courseData, setCourseData] = useState([]);
  const [lpCounts, setLpCounts] = useState({});
  const { userDateFormat } = useContext(CourseContext);
  const [alert, setAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);

  const EL = config.elearningCourseList.alias;
  const IL = config.instructorLed.alias;

  const addSession = () => {
    console.log("Add session clicked");
  };

  const getRowClass = (record) => {
    const { courseType = EL, sessionCount = 0, isPrivate = false } = record;

    if (courseType === EL) {
      return style.elBg;
    }

    if (courseType === IL && sessionCount > 0) {
      return isPrivate ? style.ilSessionbg : style.ilBg;
    }
    return style.noSessionBg;
  };

  useEffect(() => {
    setAlert(null);
    setAlertType(null);
    axios
      .get(`${config.endPoint.commonService}/learningpath/${id}`, {
        headers: {
          isFromLearningPath: true,
        },
      })
      .then((response) => {
        const {
          courses,
          enrolledUserCount,
          completedUserCount,
          notCompletedUserCount,
        } = response.data.data;
        setLpCounts({
          enrolledUserCount,
          completedUserCount,
          notCompletedUserCount,
        });
        setCourseData(
          courses.map((course) => {
            const { courseType } = course;
            return {
              courseType: course.courseType,
              isPrivate: courseType === EL ? null : course?.private,
              identityId:
                courseType === EL ? course.identityId : course.identity_id,
              name: courseType === EL ? course.name : course.course_name,
              enrolledUsers:
                courseType === EL
                  ? course.usersEnrolledCount || 0
                  : course?.batch_data?.counter || 0,
              maxSize: courseType === EL ? null : course?.batch_data?.size || 0,
              waitlistCount:
                courseType === EL ? null : course.waitListedUserCount || 0,
              sessionCount:
                courseType === EL
                  ? null
                  : course?.batch_data?.length || course?.batch_data
                  ? 1
                  : 0 || 0,
              lastUpdated:
                courseType === EL
                  ? course.updatedTimestamp
                  : course.updated_timestamp,
            };
          })
        );
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message || "Something went wrong");
        setAlertType("error");
      });
  }, [id]);

  const tableColumns = [
    {
      dataIndex: "identityId",
      title: "COURSE ID",
      width: "141px",
      sorter: (a, b) => a.identityId.localeCompare(b.identityId),
      render: (cell, record) => (
        <span className={`${style.courseId}`}>{cell}</span>
      ),
    },
    {
      dataIndex: "name",
      title: "COURSE NAME",
      width: "164px",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (cell, record) => (
        <span
          className={`${style.courseTitle} ${
            record.courseType === EL
              ? style.elSession
              : record.isPrivate
              ? style.privateSession
              : style.publicSession
          } ant-table-ellipsis--2`}
        >
          {decodeHtmlEntities(cell)}
        </span>
      ),
    },
    {
      dataIndex: "courseType",
      title: "TYPE",
      align: archived && "center",
      sorter: (a, b) => a.courseType.localeCompare(b.courseType.localeCompare),
      width: "111px",
    },
    
    {
      dataIndex: "lastUpdated",
      title: "LAST UPDATE",
      sorter: (a, b) => a.lastUpdated - b.lastUpdated,
      width: "227px",
      render: (cell) =>
        TimeFormaters.FormatTableTime(cell, null, userDateFormat),
    },
  ];

  return (
    <>
      <Row gutter={24} style={{ minHeight: "100px" }}>
        <Col lg={4}>
          <Row gutter={[0, 24]}>
            <Col lg={24}>
              <LearningPathInfoCard
                text={"COMPLETED"}
                count={lpCounts?.completedUserCount || 0}
                color={"#00B900"}
              />
            </Col>
            <Col lg={24}>
              <LearningPathInfoCard
                text={"NOT COMPLETED"}
                count={lpCounts?.notCompletedUserCount || 0}
                color={"#BF0000"}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={20} className={style.rowSpacing}>
          <AntDesignTable
            data={courseData}
            rowClassName={getRowClass}
            columns={
              !archived
                ? tableColumns
                : tableColumns.filter((column) => !column.archived)
            }
            fullWidth={false}
          />
        </Col>
      </Row>
      <AntDesignNotifcation
        alert={alert}
        alertType={alertType}
        setAlert={setAlert}
        setAlertType={setAlertType}
      />
    </>
  );
};

export default LearningPathDetail;

import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import {
  Col,
  Row,
  Table,
  Button,
  Input,
  Breadcrumb,
  Alert,
  Tabs,
  Typography,
  Spin,
} from "antd";
import { DownloadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import acceptedIcon from "../../../assets/icons/accepted_icon.svg";
import NotificationConfirmationModal from "./NotificationConfirmationModal";
import config from "../../../config";
import TimeFormatter from "../../../utils/timeFormatter";
import "./notificationList.scss";
import { UserContext } from "../../../context/UserContext";
import { CourseContext } from "../../../context/CourseContext";
import { flatten, uniqBy } from "lodash";
import dayjs from "dayjs";

function NotificationList() {
  const { hasRole } = useContext(UserContext);
  const isILAdmin = hasRole([config.userRoleNames.ILAdmin]);
  const isELAdmin = hasRole([config.userRoleNames.ELAdmin]);
  const isSuperadmin = hasRole([config.userRoleNames.superAdmin]);
  const { userDateFormat } = useContext(CourseContext);
  const [searchedColumn, setSearchedColumn] = useState("");

  const [searchText, setSearchText] = useState([]);
  const [listData, setListData] = useState([]);
  const [ilData, setIlData] = useState([]);
  const [elData, setElData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [logType, setLogType] = useState(false);
  const [isAcceptModal, setIsAcceptModal] = useState(false);
  const [isDeclineModal, setIsDeclineModal] = useState(false);
  const [notificationUserData, setNotificationUserData] = useState({});
  const [modalLabel, setModalLabel] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const [bottom, setBottom] = useState("bottomCenter");
  const [value, setValue] = useState("");
  const { TabPane } = Tabs;
  const searchInput = useRef(null);
  // const { RangePicker } = DatePicker;
  const [ilCourseNameFiltr, setIlCoourseNameFiltr] = useState([]);
  const ActionOwnerRole = [
    "IL Admin",
    "EL Admin",
    "Manager",
    "User",
    "Super Admin",
  ];

  useEffect(() => {
    if (listData.length > 0) {
      const filterData = uniqBy(listData, "courseName").map((items) => ({
        text: items.courseName,
        value: items.courseName,
      }));

      setIlCoourseNameFiltr(filterData);
    }
  }, [listData]);

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
    },
    {
      title: "Notification",
    },
  ];

  const notificationColumns = [
    {
      dataIndex: "userId",
      title: "Emp ID",
      width: 110,
      ellipsis: "true",
      sorter: (a, b) => a.user_id - b.user_id,
      onFilter: (value, record) =>
        String(record.user_id).includes(String(value)),
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "user_id" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      dataIndex: "userEmail",
      title: "Emp E-Mail".toUpperCase(),
      width: 130,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
      dataIndex: "courseIdentityId",
      title: "Course ID".toUpperCase(),
      width: 100,
      sorter: (a, b) => a?.identity_id?.localeCompare(b?.identity_id),
    },
    {
      dataIndex: "courseName",
      title: "Course Name".toUpperCase(),
      width: 170,
      sorter: (a, b) => a?.course_name?.localeCompare(b?.course_name),
      render: (cell, row) => {
        return <span title={cell}>{cell}</span>;
      },
    },
    {
      dataIndex: "batchId",
      title: "Session ID".toUpperCase(),
      width: 90,
      sorter: (a, b) => a.batch_id - b.batch_id,
    },
    {
      dataIndex: "batchName",
      title: "Session Name".toUpperCase(),
      width: 120,

      sorter: (a, b) => a?.batch_name?.localeCompare(b?.batch_name),
      render: (cell, row) => {
        return <span title={cell}>{cell}</span>;
      },
    },
    {
      dataIndex: "requestedTimeStamp",
      title: "Requested date & time".toUpperCase(),
      width: 110,
      sorter: (a, b) => a.requestedTimeStamp - b.requestedTimeStamp,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "id",
      title: "ACTION",
      width: 250,
      headerAlign: "left",
      csvExport: false,
      align: "left",
      render: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                <Button
                  type="button"
                  onClick={(event) => {
                    openAcceptModal(row);
                  }}
                  className={"notification-acceptButtonStyle"}
                >
                  <span className={"notification-iconStyle"}>
                    <img src={acceptedIcon} alt="icon" />
                  </span>
                  Accept
                </Button>
                <Button
                  type="button"
                  icon={<CloseCircleOutlined />}
                  onClick={(event) => {
                    openDeclineModal(row);
                  }}
                  className={"notification-declineButtonStyle"}
                >
                  Decline
                </Button>
              </span>
            </>
          );
        }
      },
    },
  ];

  const tableSearchData = ({
    dataIndex,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
        style={{
          width: "10rem",
          fontFamily: "RakutenRoundRegular",
          marginBottom: 8,
          display: "block",
        }}
      />
      <button
        type="button"
        style={{
          width: "5.5rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          setSearchText({ [dataIndex]: selectedKeys[0] });
          confirm();
        }}
      >
        Search
      </button>
      <button
        type="button"
        style={{
          width: "4rem",
          marginLeft: "0.2rem",
          backgroundColor: " #00a0f0",
          color: "#ffffff",
          border: "none",
          borderRadius: "3px",
        }}
        onClick={() => {
          clearFilters();
          setSearchText("");
          confirm();
        }}
      >
        Reset
      </button>
    </div>
  );

  const instructorLedLogsColumns = [
    {
      dataIndex: "userId",
      title: "Emp ID".toUpperCase(),
      key: "userId",
      sorter: (a, b) => a.userId - b.userId,
      width: 110,
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "userId" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.userId).includes(String(value));
      },
    },
    {
      dataIndex: "userEmail",
      key: "userEmail".toUpperCase(),
      title: "Name & Email",
      sorter: (a, b) => a.userEmail?.localeCompare(b.userEmail),
      width: 150,
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "userName" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.userEmail.toLowerCase().includes(value.toLowerCase());
      },
      render: (cell, row) => (
        <>
          <div title={row.userName}>
            <strong
              style={{ fontFamily: "RakutenRoundSemiBold" }}
            >{`${row.userName}`}</strong>
          </div>
          <span title={row.userEmail}>{`${row.userEmail}`}</span>
        </>
      ),
    },
    {
      dataIndex: "courseName",
      title: "Course ID & Name".toUpperCase(),
      key: "courseName",
      filterMode: "tree",
      filterSearch: true,
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "courseName" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.courseName.toLowerCase().includes(value.toLowerCase());
      },
      width: 160,
      ellipsis: "true",
      sorter: (a, b) => a.courseName?.localeCompare(b.courseName),
      render: (cell, row) => (
        <>
          <div>
            <strong
              style={{ fontFamily: "RakutenRoundSemiBold", width: "19rem" }}
            >{`${row.courseIdentityId}`}</strong>
          </div>
          <span
            styel={{ width: "10rem" }}
            title={row.courseName}
          >{`${row.courseName}`}</span>
        </>
      ),
    },
    {
      dataIndex: "batchName",
      title: "Session ID & Name".toUpperCase(),
      width: 120,
      sorter: (a, b) => a.batchName?.localeCompare(b.batchName),
      render: (cell, row) => (
        <>
          {(row.batchId && (
            <>
              <div title={row.batchId}>
                <strong
                  style={{ fontFamily: "RakutenRoundSemiBold" }}
                >{`${row.batchId}`}</strong>
              </div>
              <span title={row.batchName}>{`${row.batchName}`}</span>
            </>
          )) || <div>N/A</div>}
        </>
      ),
    },
    {
      dataIndex: "createdTimeStamp",
      title: "Action Date & Time".toUpperCase(),
      width: 120,
      sorter: (a, b) => a.createdTimeStamp - b.createdTimeStamp,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => (
        <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>
      ),
    },
    {
      dataIndex: "action",
      width: 100,
      sorter: (a, b) => a.action?.localeCompare(b.action),
      title: "Action".toUpperCase(),
      render: (cell, row) => {
        return (
          <>
            {" "}
            <span title={cell}>{cell}</span>
          </>
        );
      },
    },
    {
      dataIndex: "actionByUserEmail",
      title: "Action Owner".toUpperCase(),
      width: 110,
      sorter: (a, b) => a.actionByUserEmail?.localeCompare(b.actionByUserEmail),
      render: (cell, row) => (
        <>
          {(row.actionByUserName && (
            <>
              <div title={row.actionByUserName}>
                <strong
                  style={{ fontFamily: "RakutenRoundSemiBold" }}
                >{`${row.actionByUserName}`}</strong>
              </div>
              <span
                title={row.actionByUserEmail}
              >{`${row.actionByUserEmail}`}</span>
            </>
          )) || <div>N/A</div>}
        </>
      ),
    },
    {
      dataIndex: "actionByUserRole",
      title: "Action Owner Role".toUpperCase(),
      width: 100,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.actionByUserRole).includes(String(value)),
      filters: ActionOwnerRole.map((items) => ({ text: items, value: items })),
      sorter: (a, b) =>
        a.actionByUserRole?.[0]?.localeCompare(b.actionByUserRole[0]),
      render: (cell, row) => {
        if (row.actionByUserRole != "NA") {
          const roles = row.actionByUserRole.join(" , ");
          return roles;
        } else {
          return row.actionByUserRole;
        }
      },
    },
    {
      dataIndex: "learnerStatus",
      width: 90,
      sorter: (a, b) => a.learnerStatus?.localeCompare(b.learnerStatus),
      title: "Learner Status".toUpperCase(),
      sort: true,
      classes: "wrapText",
    },
  ];

  const eLearningLogsColumn = [
    {
      dataIndex: "courseName",
      title: "Course ID & Name".toUpperCase(),
      width: 200,
      sorter: (a, b) => a.courseName?.localeCompare(b.courseName),
      filterDropdown: (props) =>
        tableSearchData({ ...props, dataIndex: "courseName" }),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return String(record.courseName).includes(String(value));
      },
      render: (cell, row) => (
        <>
          <div>
            <strong
              style={{ fontFamily: "RakutenRoundSemiBold" }}
            >{`${row.courseIdentity}`}</strong>
          </div>
          <span title={row.courseName}>{`${row.courseName}`}</span>
        </>
      ),
    },
    {
      dataIndex: "createdTimestamp",
      title: "Action Date & Time".toUpperCase(),
      sorter: (a, b) => a.createdTimestamp - b.createdTimestamp,
      width: 170,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => (
        <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>
      ),
    },
    {
      dataIndex: "auditAction",
      sorter: (a, b) => a.auditAction?.localeCompare(b.auditAction),
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.auditAction).includes(String(value)),
      width: 200,
      title: "Action".toUpperCase(),
      render: (cell, row) => (
        <>
          <span title={cell} style={{ whiteSpace: "pre-wrap" }}>
            {cell}
          </span>
        </>
      ),
    },
    {
      dataIndex: "email",
      title: "Action Taken by".toUpperCase(),
      sorter: (a, b) => a.email?.localeCompare(b.email),
      width: 200,
      ellipsis: "true",
      render: (cell, row) => (
        <>
          {(row.employeeName && (
            <>
              <div title={row.employeeName}>
                <strong
                  style={{ fontFamily: "RakutenRoundSemiBold" }}
                >{`${row.employeeName}`}</strong>
              </div>
              <span title={row.email}>{`${row.email}`}</span>
            </>
          )) || <div>N/A</div>}
        </>
      ),
    },
    {
      dataIndex: "actionByRole",
      title: "Action Owner Role".toUpperCase(),
      width: 200,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.actionByRole).includes(String(value)),
      filters: ActionOwnerRole.map((items) => ({ text: items, value: items })),
      sorter: (a, b) => a.actionByRole?.length - b.actionByRole?.length,
      render: (cell, row) => {
        if (cell && cell.length) {
          const roles = cell.join(", ");
          return roles;
        } else {
          return <>N/A</>;
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "createdTimeStamp",
      order: "desc",
    },
  ];

  const expandRow = {
    expandedRowRender: (record) => (
      <Row>
        <Col>
          <table>
            <thead className={"headerAlign"}>
              <tr>
                <th
                  className={`col-lg-5`}
                  style={{ fontFamily: "RakutenRoundRegular" }}
                >
                  DESCRIPTION
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontFamily: "RakutenRoundRegular" }}>
                  {record?.description
                    ? record.description
                    : "No detailed description required."}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    ),
    onlyOneExpanding: true,
    showExpandColumn: logType ? true : false,
    expandByColumnOnly: true,
  };

  const [tableColumns, setTableColumns] = useState(
    (isILAdmin && notificationColumns) || eLearningLogsColumn
  );

  useEffect(() => {
    isILAdmin ? getAllNotifications() : getElearningLogs();
  }, [isILAdmin]);

  useEffect(() => {
    setTableColumns((isILAdmin && notificationColumns) || eLearningLogsColumn);
  }, [userDateFormat]);

  const getAllNotifications = () => {
    setCsvFileName("User Requests");
    setLogType(false);
    setShowLoader(true);
    axios
      .get(`${config.endPoint.instructorLedNew}/notification/admin`)
      .then((response) => {
        setListData(response.data.data);
        setShowLoader(false);
        setTableData(response.data.data);
        setNotificationData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
        setShowAlert(true);
      });
  };

  const getInstructorLedLogs = () => {
    setCsvFileName("Instructor Led Logs");
    setLogType(true);
    setShowLoader(true);
    axios
      .get(
        `${config.endPoint.instructorLedNew}/notification/getNotificationLogs`
      )
      .then((response) => {
        const Data = response.data.data;
        const modifiedData = Data.map((items) => ({
          ...items,
          batchId: items.batchId === null ? "NA" : items.batchId,
          batchName: items.batchName === null ? "NA" : items.batchName,
          actionByUserRole:
            items.actionByUserRole === null ? "NA" : items.actionByUserRole,
        }));

        setListData(modifiedData);
        setDataLoaded(true);
        setShowLoader(false);
        setTableData(modifiedData);
        setIlData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const getElearningLogs = () => {
    setCsvFileName("Elearning Logs");
    setLogType(true);
    setShowLoader(true);
    axios
      .get(`${config.endPoint.elearning}/logs/`)
      .then((response) => {
        setListData(response.data.data);
        setShowLoader(false);
        setDataLoaded(true);
        setElData(response.data.data);
        setTableData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const openAcceptModal = (row) => {
    const payload = {
      batch_id: Number(row.batchId),
      user_id: row.userId,
      not_interested: row.notInterested === 1,
      reason: "",
      admin_user_flag: 1,
    };
    setNotificationUserData(payload);
    setIsAcceptModal(true);
    setIsDeclineModal(false);
    const label = (
      <>
        Accept unenroll request from {row.email} for {row.courseIdentityId} /{" "}
        {row.batchId} ?
      </>
    );
    setModalLabel(label);
  };

  const openDeclineModal = (row) => {
    setIsAcceptModal(false);
    setIsDeclineModal(true);
    const payload = {
      notification_id: Number(row.id),
    };
    setNotificationUserData(payload);
    const label = (
      <>
        Decline unenroll request from {row.email} for {row.courseIdentityId} /{" "}
        {row.batchId} ?
      </>
    );
    setModalLabel(label);
  };

  const handleAcceptModalClose = () => setIsAcceptModal(false);

  const handleDeclineModalClose = () => setIsDeclineModal(false);

  const acceptUnenrollRequest = () => {
    axios
      .post("enrollment/unenroll", notificationUserData)
      .then((res) => {
        setAlert("User Request accepted successfully!");
        setAlertType("success");
        setNotificationUserData({});
        handleAcceptModalClose();
        onPageFilterChange("1");
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleAcceptModalClose();
          setNotificationUserData({});
        }
      });
  };

  const declineUnenrollRequest = () => {
    axios
      .post("notification/admin/cancel", notificationUserData)
      .then((res) => {
        setAlert("User Request declined successfully!");
        setAlertType("success");
        setNotificationUserData({});
        handleDeclineModalClose();
        onPageFilterChange("1");
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleDeclineModalClose();
          setNotificationUserData({});
        }
      });
  };

  const onPageFilterChange = (value) => {
    setListData([]);
    setPageCount(0);
    switch (value) {
      case "1":
        getAllNotifications();
        setTableColumns(notificationColumns);
        break;
      case "2":
        getInstructorLedLogs();
        setTableColumns(instructorLedLogsColumns);
        break;
      case "3":
        getElearningLogs();
        setTableColumns(eLearningLogsColumn);
        break;
      default:
        getAllNotifications();
        setTableColumns(notificationColumns);
        break;
    }
  };

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(e) => onPageFilterChange(e)}
        className={"notification-tabs"}
      >
        {(isILAdmin || isSuperadmin) && (
          <TabPane tab="User Requests" key="1"></TabPane>
        )}
        {(isILAdmin || isSuperadmin) && (
          <TabPane tab="IL Logs" key="2"></TabPane>
        )}
        {(isELAdmin || isSuperadmin) && (
          <TabPane tab="EL Logs" key="3"></TabPane>
        )}
      </Tabs>
    );
  };

  const CSVColumns = tableColumns.map((header, index) => {
    if (header.dataIndex === "userEmail") {
      return [
        {
          key: "userName",
          label: "Name",
        },
        {
          key: header.dataIndex,
          label: "Email",
        },
      ];
    } else {
      return {
        key: header.dataIndex,
        label: header.title,
      };
    }
  });

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      requested_ts: data.requested_ts
        ? dayjs.unix(data.requested_ts).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      createdTimeStamp: data.createdTimeStamp
        ? dayjs.unix(data.createdTimeStamp).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      createdTimestamp: data.createdTimestamp
        ? dayjs.unix(data.createdTimestamp).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
    }));
  };

  const tableHeader = () => {
    return (
      <div className={"notification_header"}>
        <Row style={{ alignItems: "center" }}>
          <Col lg={6}>
            <Typography className="notification-pageTitle">
              Notification{" "}
              <span
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "RakutenRoundRegular",
                }}
              >
                ({showLoader ? <Spin /> : pageCount})
              </span>
            </Typography>
          </Col>
          <Col className="filterPage" lg={9}>
            {toggleFilter()}
          </Col>
          <Col lg={3}></Col>
          <Col lg={2}>
            <CSVLink
              filename={csvFileName}
              extension=".csv"
              wrapColumnChar=""
              headers={flatten(CSVColumns)}
              data={getDataToDownload(listData)}
            >
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadOutlined />}
                size="large"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              />
            </CSVLink>
          </Col>
          <Col lg={4}>
            <Input
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
              suffix={<SearchOutlined />}
              className={"notificationSearch"}
              style={{
                marginTop: "1rem",
                width: " 10rem",
                fontSize: "1rem",
                fontFamily: "RakutenRoundRegular",
                borderRadius: "4px",
              }}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
          />
        )}
        <Breadcrumb className={"notification-crumbs"} items={crumbs} />
        {tableHeader()}
        <div className={"notificationTable"}>
          {
            <Table
              rowKey="id"
              loading={showLoader}
              columns={tableColumns}
              dataSource={listData}
              expandable={expandRow}
              pagination={{
                position: [bottom],
                showQuickJumper: true,
              }}
            />
          }
        </div>
        {isAcceptModal && (
          <NotificationConfirmationModal
            toggle={true}
            title={modalLabel}
            show={isAcceptModal}
            leftButton="YES"
            rightButton="NO"
            handleClose={handleAcceptModalClose}
            rightButtonClick={handleAcceptModalClose}
            leftButtonClick={acceptUnenrollRequest}
          />
        )}
        {isDeclineModal && (
          <NotificationConfirmationModal
            toggle={true}
            title={modalLabel}
            show={isDeclineModal}
            leftButton="YES"
            rightButton="NO"
            handleClose={handleDeclineModalClose}
            rightButtonClick={handleDeclineModalClose}
            leftButtonClick={declineUnenrollRequest}
          />
        )}
      </div>
    </>
  );
}

export default NotificationList;

import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import style from "./ImageSelectionModal.module.css";
import {
  PaginationProgressBar,
  ImageComponent,
} from "./../../component-library";
import { InputGroup, Button } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import Slider from "react-slick";
import "./../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./../../../node_modules/slick-carousel/slick/slick.css";
import PropTypes from "prop-types";
import config from "../../config";

function ImageSelectionModal({ show, handleClose, fetchImageName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [items, setItems] = useState([]);
  const [searchString, setSearchString] = useState("");

  const _cache = {}; // Cache results for auto complete
  const PER_PAGE = 10; // Auto complete num results

  const _handleSearch = (query) => {
    if (_cache[query]) {
      setOptions(_cache[query].options);
      return;
    }

    setIsLoading(true);
    const tagsAutoCompleteUrl = `${config.endPoint.commonService}/image?tags=${query}`;
    axios.get(tagsAutoCompleteUrl).then((resp) => {
      setIsLoading(false);
      if (resp.data.success) {
        setOptions(resp.data.data);
      }
    });
  };

  async function fetchImages() {
    let searchUrl = `${config.endPoint.commonService}/image?excludeTags=false&getSmallThumbnail=false&limit=50`;

    if (searchString) {
      searchUrl = searchUrl + "&tag=" + searchString;
    }
    const res = await axios.get(searchUrl);
    if (res.data && res.data.success) {
      const localItems = [];

      for (let index = 0; index < res.data.data.length; index++) {
        localItems.push(
          <ImageComponent
            source={res.data.data[index].img_link}
            handleClick={onClickImage}
            imgName={res.data.data[index].img_name}
          />
        );
      }
      setItems(localItems);
    }
  }

  useEffect(() => {
    fetchImages(searchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 3,
    slidesPerRow: 5,
    afterChange: (current) => setCurrentSlide(current),
  };

  const imagesPerPage = settings.rows * settings.slidesPerRow;

  const onClickImage = (args) => {
    fetchImageName(args.target.name);
    // console.log(args.target.name); // TODO: Change as per course add / edit requirement
  };

  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName={style.modalWidth}>
      <Modal.Body>
        <div className={style.modalHeader}>
          <div className={style.left}>
            <span className={style.modalTitle}>
              <span className={style.modalTitleBold}> Select </span>
              and
              <span className={style.modalTitleBold}> Add image </span>
              to the Course
            </span>
          </div>
          <div>
            <InputGroup className={style.searchInput}>
              <AsyncTypeahead
                className={style.searchInput}
                query={query}
                isLoading={isLoading}
                options={options}
                id="async-pagination"
                labelKey="login"
                maxResults={PER_PAGE - 1}
                minLength={2}
                onSearch={_handleSearch}
                onChange={(selected) => setSearchString(selected[0])}
                paginate
                placeholder="Search by Image Tags"
                renderMenuItemChildren={(option) => <div>{option}</div>}
                useCache={false}
              />
              <InputGroup.Append>
                <div className={style.searchIcon}></div>
              </InputGroup.Append>
            </InputGroup>
            <Button
              variant="flat"
              className={style.closeButton}
              size="sm"
              onClick={handleClose}
            >
              {" "}
              <CloseIcon />
            </Button>
          </div>
        </div>

        <div className={style.imageContainer}>
          <Slider {...settings} ref={sliderRef}>
            {items}
          </Slider>
        </div>
        <div className={style.footer}>
          {" "}
          {items.length > 0 && (
            <>
              <PaginationProgressBar
                currentPage={currentSlide}
                maxPages={Math.ceil(items.length / imagesPerPage)}
                maxWidth={4.3125}
                prevHandler={gotoPrev}
                nextHandler={gotoNext}
                prevDisabled={currentSlide === 0}
                nextDisabled={
                  currentSlide === Math.ceil(items.length / imagesPerPage) - 1
                }
              />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

ImageSelectionModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ImageSelectionModal;

import React, { useState } from "react";
import eye_icon from "../../assets/icons/eye_icon.svg";
import private_icon from "../../assets/icons/private_icon.svg";
import public_icon from "../../assets/icons/public_icon.svg";
import InlineIconButton from "../inlineIconButton/InlineIconButton";
import style from "./tablePublicPrivateFilter.module.css";

function TablePublicPrivateFilter({
	onFilter,
	columns,
	filterState,
	setfilterState,
}) {
	const [buttonFilterState] = useState({ filterState });
	const [iconLink, seticonLink] = useState(eye_icon);
	// useEffect(() => {
	// 	switch (filterState) {
	// 		case 0:
	// 			seticonLink(eye_icon);
	// 			break;
	// 		case 1:
	// 			seticonLink(public_icon);
	// 			break;
	// 		case 2:
	// 			seticonLink(private_icon);
	// 			break;
	// 		default:
	// 			seticonLink(eye_icon);
	// 			break;
	// 	}
	// }, []);

	let onFilter1 = () => {
		if (buttonFilterState.filterState === "All") {
			setfilterState("public");
			seticonLink(public_icon);
		} else if (buttonFilterState.filterState === "public") {
			setfilterState("private");
			seticonLink(private_icon);
		} else if (buttonFilterState.filterState === "private") {
			setfilterState("All");
			seticonLink(eye_icon);
		}
	};
	return (
		<InlineIconButton
			iconURL={iconLink}
			clickHandler={onFilter1}
			otherStyle={style.iconStyle}
		/>
	);
}

export default TablePublicPrivateFilter;

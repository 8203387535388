import moment from "moment";
import TimeFormaters from "../../../utils/timeFormatter";

function validateSessionDetails(sessionDetails) {
  const errors = {};
  const {
    batch_name: batchName,
    country,
    start_time: startTime,
    end_time: endTime,
    size,
    min_size: minSize,
    tz_offset: timeZoneOffset,
    private: publicPrivate,
  } = sessionDetails;

  if (!batchName) {
    errors.sessionName = `Please input Session Name.`;
  }

  if (batchName && batchName.length < 2) {
    errors.sessionName = `Session Name should not be less than 2 characters.`;
  }

  if (!country && publicPrivate) {
    errors.subCategory = `Please select the Subcategory`;
  }

  if (!startTime) {
    errors.startDate = `Please select Start date and time`;
  }

  if (!endTime) {
    errors.endDate = `Please select End date and time`;
  }
  
  if (!size) {
    errors.maxUser = `Please select the Max User`;
  }

  if (!minSize) {
    errors.minUser = `Please select Min User`;
  }
  
  if (!timeZoneOffset) {
    errors.timeZone = `Please select the Time Zone`;
  }

  return errors;
}

function ValidateStartDate(startDate, endDate) {
  const startDateUTC = TimeFormaters.getUtcTimeStamp(startDate);
  const endDateUTC = TimeFormaters.getUtcTimeStamp(endDate);
  if (!startDate) {
    return {
      startDate: `Please select the start date`,
    };
  } else if (startDateUTC > endDateUTC) {
    return {
      startDate: `Start Date cannot be greater than End Date`,
    };
  }
  else {
    return { startDate: "" };
  }
}

function ValidateEndDate(endDate, startDate) {
  const startDateUTC = TimeFormaters.getUtcTimeStamp(startDate);
  const endDateUTC = TimeFormaters.getUtcTimeStamp(endDate);

  const tday = moment(new Date().toISOString()).format("YYYY-MM-DD");
  const today = moment(tday).valueOf() / 10000;

  if (!endDate) {
    return {
      endDate: `Please select the End date`,
    };
  } else if (endDateUTC < startDateUTC) {
    return {
      endDate: "End date should be greater than Start date",
    };
  } else if (today > endDateUTC) {
    return {
      startDate: `End Date cannot be less than Today`,
    };
  } else {
    return { endDate: "" };
  }
}

function ValidateStartTime(startDate, endDate, startTime, endTime) {
  const startDateTimeUTC = TimeFormaters.getUtcTimeStamp(startDate, startTime);
  const endDateTimeUTC = TimeFormaters.getUtcTimeStamp(endDate, endTime);

  if (!startTime) {
    return {
      startTime: `Please select the Start time`,
    };
  } else if (startDateTimeUTC > endDateTimeUTC) {
    return {
      startTime: `Start date should be smaller than End date`,
    };
  } else if (startDateTimeUTC >= endDateTimeUTC) {
    return {
      startTime: `Start time cannot be same as End time`,
    };
  } else {
    return { startTime: "" };
  }
}

function ValidateEndTime(startDate, endDate, startTime, endTime) {
  const startDateTimeUTC = TimeFormaters.getUtcTimeStamp(startDate, startTime);
  const endDateTimeUTC = TimeFormaters.getUtcTimeStamp(endDate, endTime);

  if (!endTime) {
    return {
      endTime: `Please select the End time`,
    };
  } else if (startDateTimeUTC >= endDateTimeUTC) {
    return {
      endTime: `End Date and End time cannot be greater than Start Date and Start time`,
    };
  } else if (startDateTimeUTC >= endDateTimeUTC) {
    return {
      startTime: `Start Time cannot be same as End time`,
    };
  } else {
    return { endTime: "" };
  }
}

function convertUTCDateToTimeStamp(date) {
  return moment.utc(date * 1000).format("YYYY-MM-DD");
}

export {
  validateSessionDetails,
  convertUTCDateToTimeStamp,
  ValidateStartDate,
  ValidateEndDate,
  ValidateStartTime,
  ValidateEndTime,
};

import React from "react";

import InstructorLedCourse from "../../assets/icons/InstructorLedCourse.svg";
import InstructorLedCourseWhite from "../../assets/icons/InstructorLedCourseWhite.svg";
import elearningVideo from "../../assets/icons/elearningVideo.svg";
import elearningVideoWhite from "../../assets/icons/elearningVideoWhite.svg";

import config from "../../config";

//import style
import style from "./CourseIcon.module.css";

const INSTRUCTOR_LED = config.INSTRUCTOR_LED;
const E_LEARNING = config.E_LEARNING;
function CourseIcon({ courseType, color }) {
  if (courseType == INSTRUCTOR_LED) {
    return (
      <div className={style.course}>
        <img
          className={style.imageWidth}
          src={
            color == "black" ? InstructorLedCourse : InstructorLedCourseWhite
          }
        ></img>
        <span
          className={`${style.course} ${
            color == "black" ? style.black : style.white
          }`}
        >
          COURSE
        </span>
      </div>
    );
  }
  if (courseType == E_LEARNING) {
    return (
      <div className={style.course}>
        <img
          className={style.imageWidth}
          src={color == "black" ? elearningVideo : elearningVideoWhite}
        ></img>
        <span className={` ${color == "black" ? style.black : style.white}`}>
          COURSE
        </span>
      </div>
    );
  }
}

export default CourseIcon;

import { useContext } from "react";
import { PageLoader } from "../component-library";
import { LoadingContext } from "../pages/LoadingContext";
import App from "../App";
import "../App.css";

function AppLoader() {
  const { getPageLoader } = useContext(LoadingContext);

  return (
    <>
      <App />
      <PageLoader active={getPageLoader()} />
    </>
  );
}

export default AppLoader;

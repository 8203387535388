import React from "react";

import ErrorImage from "../component-library/errorBoundary/errorImage.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "3rem",
          }}
        >
          <img
            src={ErrorImage}
            alt="While showing an error there's an error to load error image"
            height={"400px"}
          />
          <span style={{ fontSize: 32 }}>Something went wrong</span>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <span style={{ fontSize: "0.75rem", color: "red", width: 400 }}>
              {this.state.error?.message ||
                `An unexpected error occurred. Please
            try again.`}
            </span>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

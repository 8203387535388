import "./listBoxSwap.css";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { ReactComponent as Left } from "../../assets/icons/left_arrow.svg";
import { ReactComponent as Right } from "../../assets/icons/right_arrow.svg";
import React, { useEffect, useState } from "react";

function ListBoxSwap({
	enrolledUsers,
	waitlistUsers,
	tempEnrolledUsers,
	setTempEnrolledUsers,
	setTempWaitlistUsers,
	swap,
	setListBoxUsed,
	privateId,
}) {
	const allUsers = [...waitlistUsers, ...enrolledUsers];
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (enrolledUsers) {
			const values = enrolledUsers.map((current) => current.value);
			setSelected(values);
		}
	}, [enrolledUsers, waitlistUsers]);

	const onChange = (selected) => {
		setListBoxUsed(true);
		if (!(!privateId && !swap && selected.length < tempEnrolledUsers.length)) {
			setSelected(selected);
			setTempEnrolledUsers(selected);
			const newWaitlist = allUsers.filter((element) => {
				return selected.indexOf(element.value) === -1;
			});
			const onChangeWaitlist = newWaitlist.map((current) => current.value);

			setTempWaitlistUsers(onChangeWaitlist);
		}
	};

	return (
		<div>
			{swap && (
				<DualListBox
					options={allUsers}
					selected={selected}
					onChange={onChange}
					moveKeyCodes={[]}
					icons={{
						moveLeft: (
							<span className="centreIcon">
								<Left />
							</span>
						),

						moveAllLeft: [
							<span className="centreIcon">
								<Left />
							</span>,
							<span className="centreIcon">
								<Left />
							</span>,
						],
						moveRight: (
							<span className="centreIcon">
								<Right />
							</span>
						),
						moveAllRight: [
							<span className="centreIcon">
								<Right />
							</span>,
							<span className="centreIcon">
								<Right />
							</span>,
						],
					}}
				/>
			)}

			{!swap && (
				<DualListBox
					options={allUsers}
					selected={selected}
					onChange={onChange}
					moveKeyCodes={[]}
					icons={{
						moveLeft: (
							<span className="centreIcon">
								<Left />
							</span>
						),
						moveAllLeft: [
							<span className="centreIcon">
								<Left />
							</span>,
							<span className="centreIcon">
								<Left />
							</span>,
						],
						moveRight: (
							<span className="centreIcon">
								<Right />
							</span>
						),
						moveAllRight: [
							<span className="centreIcon">
								<Right />
							</span>,
							<span className="centreIcon">
								<Right />
							</span>,
						],
					}}
				/>
			)}
		</div>
	);
}
export default ListBoxSwap;

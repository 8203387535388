import moment from "moment";
import TimeFormaters from "../../../utils/timeFormatter";

function validateSessionDetails(sessionDetails) {
	const errors = {};

	const {
		session_name: sessionName,
		country,
		start_time: startTime,
		end_time: endTime,
		max_users,
		min_users: minUsers,
		tz_offset: timeZoneOffset,
		private: publicPrivate,
		attendance_file: attendanceFile,
	} = sessionDetails;

	if (!sessionName) {
		errors.sessionName = `Please input Session Name.`;
	}

	if (sessionName && sessionName.length < 2) {
		errors.sessionName = `Session Name should not be less than 2 characters.`;
	}

	if (!country && publicPrivate) {
		errors.subCategory = `Please select the Subcategory`;
	}

	if (!startTime) {
		errors.startDate = `Please select Start date and time`;
	}
	if (!endTime) {
		errors.endDate = `Please select End date and time`;
	}
	if (!max_users) {
		errors.maxUser = `Please select the Max User`;
	}
	if (!minUsers) {
		errors.minUser = `Please select Min User`;
	}

	if (!timeZoneOffset) {
		errors.timeZone = `Please select the Time Zone`;
	}

	if (!attendanceFile) {
		//commenting as we are validating from server
		//errors.attendanceFile = `No input file provided`;
	}
	return errors;
}

function ValidateStartDate(startDate, endDate) {
	const startDateUTC = TimeFormaters.getUtcTimeStamp(startDate);
	const endDateUTC = TimeFormaters.getUtcTimeStamp(endDate);

	if (!startDate) {
		return {
			startDate: `Please select the start date`,
		};
	} else if (startDateUTC > endDateUTC) {
		return {
			startDate: `Start Date cannot be greater than End Date`,
		};
	} else {
		return { startDate: "" };
	}
}

function ValidateEndDate(endDate, startDate) {
	const startDateUTC = TimeFormaters.getUtcTimeStamp(startDate);
	const endDateUTC = TimeFormaters.getUtcTimeStamp(endDate);

	if (!endDate) {
		return {
			endDate: `Please select the End date`,
		};
	} else if (endDateUTC < startDateUTC) {
		return {
			endDate: "End date should be greater than Start date",
		};
	} else {
		return { endDate: "" };
	}
}

function ValidateStartTime(startDate, endDate, startTime, endTime) {
	const startDateTimeUTC = TimeFormaters.getUtcTimeStamp(startDate, startTime);
	const endDateTimeUTC = TimeFormaters.getUtcTimeStamp(endDate, endTime);

	if (!startTime) {
		return {
			startTime: `Please select the Start time`,
		};
	} else if (startDate === endDate && startDateTimeUTC > endDateTimeUTC)  {
		return {
			startTime: `Start date time should be smaller than End date time`,
		};
	} else if (startDate === endDate && startDateTimeUTC >= endDateTimeUTC) {
		return {
			startTime: `Start time cannot be same as End time`,
		};
	} else {
		return { startTime: "" };
	}
}

function ValidateEndTime(startDate, endDate, startTime, endTime) {
	const startDateTimeUTC = TimeFormaters.getUtcTimeStamp(startDate, startTime);
	const endDateTimeUTC = TimeFormaters.getUtcTimeStamp(endDate, endTime);
	const presentDateUTC = TimeFormaters.getCurrentTimeStamp();

	if (!endTime) {
		return {
			endTime: `Please select the End time`,
		};
	} else if (startDateTimeUTC >= endDateTimeUTC) {
		return {
			endTime: `Start date time cannot be greater than End date time`,
		};
	} else if (endDateTimeUTC > presentDateUTC) {
		return {
			endTime: `Start time should be less than end time and end time should be less than current time!`,
		};
	} else {
		return { endTime: "" };
	}
}

function convertUTCDateToTimeStamp(date) {
	return moment.utc(date * 1000).format("YYYY-MM-DD");
}

export {
	validateSessionDetails,
	convertUTCDateToTimeStamp,
	ValidateStartDate,
	ValidateEndDate,
	ValidateStartTime,
	ValidateEndTime,
};

import {
  Col,
  Row,
  Image,
  Dropdown,
  Space,
  Table,
  Checkbox,
  Calendar,
  DatePicker,
  Card,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  DownOutlined,
  LockFilled,
  EyeInvisibleFilled,
  InboxOutlined,
} from "@ant-design/icons";
import moment from "moment";
// import WeekCalendar from "./WeekCalendar";

import course_filter_icon from "../../../assets/icons/course_filter_icon.svg";
import schedule_icon from "../../../assets/icons/schedule_icon.svg";
import day_icon from "../../../assets/icons/day_icon.svg";
import week_icon from "../../../assets/icons/week_icon.svg";
import month_icon from "../../../assets/icons/month_icon.svg";
import restriction_icon from "../../../assets/icons/restriction_icon.svg";
import locked_icon from "../../../assets/icons/locked_icon.svg";
import { axiosGet } from "../../../utils/axios.utility";
import "./newCalendar.css";
import { AntDesignBreadCrumb, TopContainer } from "../../../component-library";
import _ from "lodash";
import "react-week-calendar/dist/style.css";
import { WeeklyCalendar } from "./weekly-calendar/WeeklyCalendar";

async function fetchCalendarDetails() {
  return axiosGet("/calendar-event/");
}

const CALENDAR_FILTER_INITIAL_STATE = {
  SCHEDULE: false,
  DAY: false,
  MONTH: false,
  WEEK: false,
};

const EVENT_FILTER_INITIAL_STATE = {
  PUBLIC: false,
  PRIVATE: false,
  ENROLLED: false,
  LOCKED: false,
};

export default function NewCalendar() {
  const [calendarEvent, setCalendarEvent] = useState([]);
  const [initialCalendarEvent, setInitialCalendarEvent] = useState([]);
  const [showTab, setShowTab] = useState(false);
  const [showcalendar, setShowCalendar] = useState(true);
  const [isActive, setisActive] = useState(true);
  const [dailyEvents, setDailyEvents] = useState([]);
  const [monthlyEvents, setMonthlyEvents] = useState([]);
  const [weeklyEvents, setWeeklyEvents] = useState([]);

  const [calendarFilter, setCalendarFilter] = useState(
    () => CALENDAR_FILTER_INITIAL_STATE
  );
  const [eventFilter, setEventFilter] = useState(
    () => EVENT_FILTER_INITIAL_STATE
  );

  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [filter, setFilter] = useState({});
  const [events, setEvents] = useState([]);
  const [monthYearEvents, setMonthYearEvents] = useState([]);

  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(date);
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    getDailyEvents(date, events);
  };

  const handleCalendarFilter = (e) => {
    setCalendarFilter((prevFilters) => {
      return { ...CALENDAR_FILTER_INITIAL_STATE, [e.target.innerText]: true };
    });
    setShowCalendar(false);
  };

  const handleEventFilter = (filter, checked) => {
    setEventFilter({ ...eventFilter, [filter]: checked });
    // setShowCalendar(false);
  };

  const toggleCard = () => {
    setShowTab(!showTab);
  };

  const handler = () => {
    setisActive(!isActive);
  };

  const SessionCard = ({ sessions }) => {
    return sessions?.map((session) => {
      return (
        <div>
          <span
            className={`schedulesession ${
              session.private === false ? "active" : "inactive"
            }`}
            style={{
              borderBlockColor: "#7D00BE",
              borderBlockEndWidth: session.enrollmentStatus ? 3 : 0,
              borderBlockStartWidth: 0,
            }}
          >
            {session.private === true ? (
              <Image preview={false} src={restriction_icon} />
            ) : null}
            {session.locked === true ? (
              <Image preview={false} src={locked_icon} />
            ) : null}
            &nbsp; &nbsp;
            {session.courseName}
          </span>
        </div>
      );
    });
  };

  const DataCard = ({ date, day }) => {
    return (
      <div>
        <div className="date" onClick={handler}>
          {day}
        </div>
        <div className="dateicon">{date < 10 ? "0" + date : date}</div>
      </div>
    );
  };

  const DayDataCard = ({ time }) => {
    return (
      <div>
        <div className="date" onClick={handler}>
          {time}
        </div>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const res = await fetchCalendarDetails();
      setCalendarEvent(
        res.map((current) => {
          return { ...current, public: !current.private };
        })
      );
      setInitialCalendarEvent(
        res.map((current) => {
          return { ...current, public: !current.private };
        })
      );
    })();
  }, []);

  useEffect(() => {
    filterCalendarData(filter);
    // eslint-disable-next-line
  }, [filter]);

  const filterCalendarData = (currentFilter) => {
    if (currentFilter.length > 0) {
      const filteredCalendarEvent = initialCalendarEvent.filter((current) => {
        const booleanFilteredResult = currentFilter.map((filter) => {
          return current[filter];
        });
        const res = booleanFilteredResult.some((current) => !!current);
        return res;
      });
      setCalendarEvent(filteredCalendarEvent);
    } else {
      setCalendarEvent(initialCalendarEvent);
    }
  };

  const handleFilter = (currentFilter) => {
    setFilter(currentFilter);
  };
  useEffect(() => {
    handleScheduleData(events);
  }, [selectedMonth]);

  const handleScheduleData = (events) => {
    let month = "";
    let year = "";
    if (selectedMonth === null) {
      let date = new Date();
      month = date.getMonth();
      year = date.getFullYear();
    } else {
      month = selectedMonth.$M;
      year = selectedMonth.$y;
    }
    setMonthYearEvents(getEventsForMonthAndYear(month, year, events));
  };

  const getDayName = (date, locale = "en-US") => {
    return date.toLocaleDateString(locale, { weekday: "long" });
  };

  const getEventsForMonthAndYear = (month, year, events) => {
    let month_year_events = [];
    let result = [];
    events.filter((item) => {
      if (item.start.getFullYear() == year && item.start.getMonth() == month) {
        let day_name = getDayName(item.start).substring(0, 3);
        item.day_name = day_name;
        item.date = item.start.getDate();
        month_year_events.push(item);
      }
    });
    var grouped_array = _.groupBy(month_year_events, "date");
    Object.entries(grouped_array).forEach((entry) => {
      const [key, value] = entry;
      let result_object = {
        date: key,
        day_name: value[0].day_name,
        sessions: value,
      };
      result.push(result_object);
    });
    return result;
  };

  const getEvents = () => {
    let calendarEvents = [];
    calendarEvent.map((current) => {
      let event = {
        id: current.course_id,
        courseName: current.course_name,
        title: current.batch_name,
        start: new Date(current.start_time),
        private: current.private,
        locked: current.locked,
        enrollmentStatus: current.enrollment_status,
        end: new Date(current.end_time),
        backgroundColor: (current.private && "#FEF4E5") || "#E5F5FD",
      };
      calendarEvents.push(event);
    });
    setEvents(calendarEvents);
  };
  useEffect(() => {
    getEvents();
  }, [calendarEvent]);

  useEffect(() => {
    handleEventsFilterChange();
  }, [eventFilter]);

  const handleEventsFilterChange = async () => {
    const response = await fetchCalendarDetails();
    setCalendarEvent(
      response.map((current) => {
        return { ...current, public: !current.private };
      })
    );
    let res = [];
    let params = [];
    let enrolled = eventFilter.ENROLLED
      ? params.push("item.enrollmentStatus != null")
      : "";
    let locked = eventFilter.LOCKED ? params.push("item.locked") : "";
    let public_status = eventFilter.PUBLIC ? params.push("!item.private") : "";
    let private_status = eventFilter.PRIVATE ? params.push("item.private") : "";
    if (params.length > 0) {
      let condition = params.join("||");
      events.filter((item) => {
        if (eval(condition)) {
          res.push(item);
        }
      });
      setEvents(res);
      handleScheduleData(res);
      getDailyEvents(selectedDate, res);
      setWeeklyEventsData(selectedWeek, res);
    } else {
      handleScheduleData(events);
      getDailyEvents(selectedDate, events);
      setWeeklyEventsData(selectedWeek, events);
    }
  };
  const crumbs = [
    {
      title: "Home",
      to: "/home",
    },
    {
      title: "Calendar",
    },
  ];

  const courseItems = [
    {
      label: (
        <div
        // onClick={(e) => handleEventFilter("ENROLLED", !eventFilter.ENROLLED)}
        >
          <Checkbox
            className="enrolled"
            onChange={(e) => handleEventFilter("ENROLLED", e.target.checked)}
          />
          <span className="enrolledsessionname">ENROLLED SESSIONS</span>
        </div>
      ),
      key: "SCHEDULE",
    },
    {
      label: (
        <div>
          <Checkbox
            className="public"
            onChange={(e) => handleEventFilter("PUBLIC", e.target.checked)}
          />
          <span className="enrolledsessionname">PUBLIC SESSIONS</span>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div>
          <Checkbox
            className="private"
            onChange={(e) => handleEventFilter("PRIVATE", e.target.checked)}
          />
          <Image preview={false} src={restriction_icon} />
          <span className="enrolledsessionname">PRIVATE SESSIONS</span>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div>
          <Checkbox
            className="locked"
            onChange={(e) => handleEventFilter("LOCKED", e.target.checked)}
          />
          <Image preview={false} src={locked_icon} />
          <span className="enrolledsessionname">LOCKED SESSIONS</span>
        </div>
      ),
      key: "3",
    },
  ];

  const monthItems = [
    {
      label: (
        <div
          onClick={(e) => {
            handleCalendarFilter(e);
          }}
        >
          <Image preview={false} src={schedule_icon} />
          <span className="schedule">SCHEDULE</span>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          onClick={(e) => {
            handleCalendarFilter(e);
          }}
        >
          <Image preview={false} src={day_icon} />
          <span className="day">DAY</span>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={(e) => {
            handleCalendarFilter(e);
          }}
        >
          <Image preview={false} src={week_icon} />
          <span className="week">WEEK</span>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          onClick={(e) => {
            handleCalendarFilter(e);
          }}
        >
          <Image
            preview={false}
            src={month_icon}
            style={{ width: "1.125rem", height: "1.125rem" }}
          />
          <span className="month">MONTH</span>
        </div>
      ),
      key: "3",
    },
  ];
  const weekColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 10,
      render: (text, record) => (
        <DataCard date={record.date} day={record.day_name}></DataCard>
      ),
    },
    {
      title: "sessionns",
      dataIndex: "courseName",
      key: "courseName",
      width: 10,
      render: (text, record) => (
        <SessionCard sessions={record.sessions}></SessionCard>
      ),
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 10,
      render: (text, record) => (
        <DataCard date={record.date} day={record.day_name}></DataCard>
      ),
    },
    {
      title: "sessionns",
      dataIndex: "courseName",
      key: "courseName",
      width: 10,
      render: (text, record) => (
        <SessionCard sessions={record.sessions}></SessionCard>
      ),
    },
  ];
  const timeline = [
    { time: "12 AM", sessions: [] },
    { time: "1 AM", sessions: [] },
    { time: "2 AM", sessions: [] },
    { time: "3 AM", sessions: [] },
    { time: "4 AM", sessions: [] },
    { time: "5 AM", sessions: [] },
    { time: "6 AM", sessions: [] },
    { time: "7 AM", sessions: [] },
    { time: "8 AM", sessions: [] },
    { time: "9 AM", sessions: [] },
    { time: "10 AM", sessions: [] },
    { time: "11 AM", sessions: [] },
    { time: "12 PM", sessions: [] },
    { time: "13 PM", sessions: [] },
    { time: "14 PM", sessions: [] },
    { time: "15 PM", sessions: [] },
    { time: "16 PM", sessions: [] },
    { time: "17 PM", sessions: [] },
    { time: "18 PM", sessions: [] },
    { time: "19 PM", sessions: [] },
    { time: "20 PM", sessions: [] },
    { time: "21 PM", sessions: [] },
    { time: "22 PM", sessions: [] },
    { time: "23 PM", sessions: [] },
  ];
  const getDailyEvents = (date, events) => {
    let day = "";
    let month = "";
    let year = "";
    let hour = "";
    let minute = "";
    let second = "";
    let daily_events = [];
    let result = timeline;

    if (date === null) {
      date = new Date();
      day = date.getDate();
      month = date.getMonth();
      year = date.getFullYear();
      // hour = date.getHours()
      // minute = date.getMinutes()
      // second = date.getSeconds()
    } else {
      day = date.$D;
      month = date.$M;
      year = date.$y;
    }
    events.filter((item) => {
      if (
        item.start.getFullYear() == year &&
        item.start.getMonth() == month &&
        item.start.getDate() == day
      ) {
        let am_pm = item.start.getHours() > 12 ? " PM" : " AM";
        item.time = item.start.getHours() + am_pm;
        daily_events.push(item);
      }
    });
    var grouped_array = _.groupBy(daily_events, "time");
    Object.entries(grouped_array).forEach((entry) => {
      const [key, value] = entry;
      let index = result.findIndex((x) => x.time == key);
      let result_object = {
        time: key,
        sessions: value,
      };
      result[index] = result_object;
    });
    setDailyEvents(result);
  };
  const renderDayView = () => {
    const columns = [
      {
        title: "Time",
        dataIndex: "time",
        key: "time",
        width: 10,
        render: (text, record) => (
          <DayDataCard time={record.time}></DayDataCard>
        ),
      },
      {
        title: "sessions",
        dataIndex: "courseName",
        key: "courseName",
        width: 10,
        render: (text, record) => (
          <SessionCard sessions={record.sessions}></SessionCard>
        ),
      },
    ];
    return (
      <div>
        <Table
          dataSource={dailyEvents}
          columns={columns}
          showHeader={false}
          pagination={false}
          locale={locale}
        />
      </div>
    );
  };

  const getEventCardIcon = (data) => {
    if (data.private) return <EyeInvisibleFilled />;
    else if (data.locked) return <LockFilled />;
    else return null;
  };

  function getEventsData(day, month, year) {
    var result = events.find(
      (item) =>
        item.start.getDate() == day &&
        item.start.getMonth() == month &&
        item.start.getFullYear() == year
    );
    if (result !== undefined) {
      return result;
    }
  }

  const renderCalendar = () => {
    return (
      <Calendar
        headerRender={() => null}
        value={selectedMonth}
        cellRender={dateCellRender}
      />
    );
  };

  useEffect(() => {
    renderCalendar();
  }, [eventFilter]);

  const dateCellRender = (value) => {
    const data = getEventsData(value.$D, value.$M, value.$y);
    let params = [];
    let enrolled = eventFilter.ENROLLED
      ? params.push("data.enrollmentStatus != null")
      : "";
    let locked = eventFilter.LOCKED ? params.push("data.locked") : "";
    let public_status = eventFilter.PUBLIC ? params.push("!data.private") : "";
    let private_status = eventFilter.PRIVATE ? params.push("data.private") : "";
    if (params.length > 0) {
      let condition = params.join("||");
      return data !== undefined && eval(condition) ? (
        <div className="events">
          <Card
            title={getEventCardIcon(data)}
            style={{
              backgroundColor: data.private ? "#FEF4E5" : "#E5F5FD",
              borderBlockColor: "#7D00BE",
              borderBlockEndWidth: data.enrollment_status ? 3 : 0,
              borderBlockStartWidth: 0,
            }}
          >
            {data.courseName}
          </Card>
        </div>
      ) : null;
    } else {
      return data !== undefined ? (
        <div className="events">
          <Card
            title={getEventCardIcon(data)}
            style={{
              backgroundColor: data.private ? "#FEF4E5" : "#E5F5FD",
              borderBlockColor: "#7D00BE",
              borderBlockEndWidth: data.enrollment_status ? 3 : 0,
              borderBlockStartWidth: 0,
            }}
          >
            {data.courseName}
          </Card>
        </div>
      ) : null;
    }
  };
  let locale = {
    emptyText: (
      <span>
        <InboxOutlined style={{ fontSize: "40px" }} />
        <br />
        <p>No Events</p>
      </span>
    ),
  };

  /*weekly calendar */
  const renderWeeklyCalendar = () => {
    return (
      <div style={{ height: "180vh", marginBottom: "4rem" }}>
        <WeeklyCalendar
          events={weeklyEvents}
          onEventClick={(event) => console.log(event)}
          onSelectDate={(date) => handleWeekDateChange(date)}
          eventTextColor="black"
          weekends={true}
          // value={selectedDate}
        />
      </div>
    );
  };

  const handleWeekDateChange = (date) => {
    setSelectedWeek(date);
    setWeeklyEventsData(date, events);
  };

  const setWeeklyEventsData = (date, events) => {
    if (date == null) date = new Date();
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    setWeeklyEvents(getFilteredWeekData(year, month, day, events));
  };

  const setDate = (year, month, day) => {
    let appendedMonth = month < 10 ? "0" + month : month;
    return year + "-" + appendedMonth + "-" + day;
  };
  const getItemTitle = (item) => {
    return (
      <div>
        <span
          className={` ${item.private === false ? "active" : "inactive"}`}
          style={{
            borderBlockColor: "#7D00BE",
            borderBlockEndWidth: item.enrollmentStatus ? 3 : 0,
            borderBlockStartWidth: 0,
          }}
        >
          {item.private === true ? (
            <Image preview={false} src={restriction_icon} />
          ) : null}
          {item.locked === true ? (
            <Image preview={false} src={locked_icon} />
          ) : null}
          &nbsp; &nbsp;
          {item.courseName}
        </span>
      </div>
    );
  };
  const getFilteredWeekData = (year, month, day, events) => {
    let result = events.filter((item) => {
      let date = setDate(
        item.start.getFullYear(),
        item.start.getMonth(),
        item.start.getDate()
      );
      let week = getWeeklyDates(year, month, day);
      if (week.includes(date)) {
        item.startTime = item.start;
        item.endTime = item.end;
        item.title = getItemTitle(item);
        return item;
      }
    });
    return result;
  };

  let getWeeklyDates = function (_yearint, _monthint, _dayint) {
    var input = new Date(_yearint, _monthint - 1, _dayint);
    let getLastSunday = function (d) {
      var t = new Date(d);
      t.setDate(t.getDate() - t.getDay());
      return t;
    };
    let lastsunday = getLastSunday(input);
    let addDays = function (dateObj, numDays) {
      var newdate = JSON.parse(JSON.stringify(dateObj));
      for (var i = 1; i < numDays; i++) {
        newdate = moment(newdate).add(1, "days");
      }
      return newdate;
    };
    let sevendaysahead = addDays(lastsunday, 7);
    function getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopDate = moment(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
      return dateArray;
    }
    return getDates(lastsunday, sevendaysahead);
  };

  /*weekly calendar */

  return (
    <div style={{ marginTop: "2rem" }}>
      <AntDesignBreadCrumb crumbs={crumbs} />
      <Row gutter={[12, 12]} style={{ marginBottom: "27px" }}>
        <Col span={20}>
          {!calendarFilter.DAY && !calendarFilter.WEEK && (
            <DatePicker
              className="monthdropdown"
              bordered={false}
              picker="month"
              allowClear={false}
              placeholder={`${moment().format("MMMM YYYY").toUpperCase()}`}
              format={"MMMM YYYY"}
              suffixIcon={
                <DownOutlined style={{ color: "black", fontSize: "12px" }} />
              }
              onChange={handleMonthChange}
            />
          )}
          {calendarFilter.DAY && (
            <DatePicker
              className="monthdropdown"
              bordered={false}
              picker="day"
              allowClear={false}
              placeholder={`${moment().format("DD MMMM").toUpperCase()}`}
              format={"DD MMMM"}
              suffixIcon={
                <DownOutlined style={{ color: "black", fontSize: "12px" }} />
              }
              onChange={handleDateChange}
            />
          )}
        </Col>

        <Col span={2}>
          <Dropdown
            menu={{
              items: courseItems,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Image
                  src={course_filter_icon}
                  // onClick={e=>console.log(e)}
                  preview={false}
                />
              </Space>
            </a>
          </Dropdown>
        </Col>

        <Col span={2}>
          <Dropdown
            menu={{
              items: monthItems,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Image src={month_icon} onClick={toggleCard} preview={false} />
              </Space>
            </a>
          </Dropdown>
        </Col>
      </Row>

      {showcalendar && renderCalendar()}
      {calendarFilter.SCHEDULE && (
        <div>
          <Table
            dataSource={monthYearEvents}
            columns={columns}
            showHeader={false}
            pagination={false}
            className="scheduletable"
            locale={locale}
          />
        </div>
      )}
      {calendarFilter.DAY && renderDayView()}
      {/* {calendarFilter.DAY && (<DayCalendar selectedDate = {selectedDate}/>)} */}
      {calendarFilter.WEEK && renderWeeklyCalendar()}
      {calendarFilter.MONTH && renderCalendar()}
    </div>
  );
}

import { Button, Input, Space, Typography } from "antd";
import { startCase } from "lodash";
import { SearchOutlined } from "@ant-design/icons";

export const getDynamicFilterList = (filter, array = []) => {
  const items = [...new Set(array.map((item) => item[filter])), null]?.filter(
    (newItem) => newItem?.length > 0
  );

  return items.map((item) => ({
    text: item,
    value: item,
  }));
};

export const getUserCourseListColumns = (
  courseTypeFilter,
  categoryFilter,
  subCategoryFilter,
  statusFilter,
  getColumnSearchProps,
  userCourseNameInput
) => {
  return [
    {
      title: "COURSE ID",
      dataIndex: "id",
      key: "id",
      width: "10",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "COURSE NAME",
      dataIndex: "name",
      key: "name",
      width: "10",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "name", "email", "courseName"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={userCourseNameInput}
            placeholder={`Search name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 85,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                setSelectedKeys([""]);
                clearFilters && clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 75,
              }}
              disabled={selectedKeys[0] === undefined}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        return record["name"]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      width: "10",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: courseTypeFilter,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
    {
      title: "CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
      width: "10",
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
      filters: categoryFilter,
      onFilter: (value, record) => record.categoryName.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "SUB CATEGORY",
      dataIndex: "subCategoryName",
      key: "subCategoryName",
      width: "10",
      sorter: (a, b) => a.subCategoryName.localeCompare(b.subCategoryName),
      filters: subCategoryFilter,
      onFilter: (value, record) => record.subCategoryName.indexOf(value) === 0,
      filterSearch: true,
    },
    {
      title: "COMPLETION DATE",
      dataIndex: "completedDate",
      key: "completedDate",
      width: "10",
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000);

        let dateA = a.completedDate ? new Date(a.completedDate) : distantFuture;
        let dateB = b.completedDate ? new Date(b.completedDate) : distantFuture;
        return dateA.getTime() - dateB.getTime();
      },
    },
    {
      title: "COMPLETION STATUS",
      dataIndex: "status",
      key: "status",
      width: "10",
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: statusFilter,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterSearch: true,
      render: (text, record) => {
        let textToRender = startCase(record.status);
        let fontColor;
        if (text === "completed") {
          fontColor = "#00B900";
        } else if (text === "started" || text === "inProgress") {
          textToRender = "In Progress";
          fontColor = "#BF0000";
        } else if (text === "assigned") {
          textToRender = "Yet to start";
        } else {
          fontColor = "#333333";
        }

        return (
          <Typography
            style={{ color: fontColor, fontFamily: "RakutenRoundSemiBold" }}
          >
            {textToRender}
          </Typography>
        );
      },
    },
  ];
};

export const crumbs = [
  {
    title: "Admin Dashboard",
    to: "/Admin",
  },
  { title: "Learner History" },
];

export const getFormattedCourseCompletionTime = (completedDate) => {
  completedDate = new Date(completedDate * 1000);
  const time = completedDate.toLocaleTimeString("en-US");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = completedDate.getFullYear();
  const month = months[completedDate.getMonth()];
  const date = completedDate.getDate();
  return `${month} ${date}, ${year} ${time}`;
};

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { CustomSelect } from "../";
import config from "../../config";
import style from "./courseFilter.module.css";
import "./style.css";
import {
  serializeCategoryResponse,
  serializeSubCategories,
} from "../../serializer/categorySerializer";
import { serializeParentCategory } from "../../serializer/parentCategorySerializer";
import { serializeDataResponse } from "../../serializer/dataSerializer";
import { CategoryContext } from "../../context/CategoryContext";

function formatCategoryList(data) {
  return data.map((current) => {
    return {
      label: current.name.replace("&amp;", "&"),
      value: current.id,
      subCategory: current.subcategories,
      parent: current.parent,
    };
  });
}

function formatParentCategoryList(data) {
  return data.map((current) => {
    return { label: current.parent_category_name, value: current.id };
  });
}

function formatLanguageList(data) {
  return data.map((current) => {
    return { label: current.title, value: current.title, id: current.id };
  });
}

function formatSubCategoryList(subCategories) {
  return subCategories.map((current) => {
    return { label: current.name.replace("&amp;", "&"), value: current.id };
  });
}

function formatProficiencyList(data) {
  return data.map((current) => {
    return { label: current.title, value: current.title, id: current.id };
  });
}

function formatCourseProviderList(data) {
  return data.map((current) => {
    return {
      label: current.value,
      value: current.value,
      id: current.id,
    };
  });
}

const { sortBy: sortByFilters } = config.instructorLed;

export default function CourseFilter({
  upliftFilters,
  filters,
  reset,
  isInstuctorLed,
}) {
  const [language, setLanguage] = useState([]);
  const [parentCategory, setParentCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [initCategory, setInitCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [proficiency, setProficiency] = useState([]);
  const [courseProvider, setCourseProvider] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedProficiency, setSelectedProficiency] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState("");
  const [currentFilter, setCurrentFilter] = useState({});
  const { categoryResponse } = useContext(CategoryContext);

  // fetch parent category
  useEffect(() => {
    const parentCategoryEndpoint = `${config.endPoint.commonService}/parentCategory`;
    axios.get(parentCategoryEndpoint).then((response) => {
      const formattedCategoryList = formatParentCategoryList(
        serializeParentCategory(response)
      );
      setParentCategory(() => [
        {
          label: config.defaultParentCategory.label,
          value: config.defaultParentCategory.value,
        },
        ...formattedCategoryList,
      ]);
    });

    const dataEndpoint = `${config.endPoint.commonService}/data`;
    const languageEndpoint = `${dataEndpoint}?type=languages`;
    axios.get(languageEndpoint).then((response) => {
      const formatedLanguageList = formatLanguageList(
        serializeDataResponse(response)?.languages
      );
      setLanguage(() => [
        {
          label: config.defaultLanguage.label,
          value: config.defaultLanguage.value,
        },
        ...formatedLanguageList,
      ]);
    });

    const proficiencyEndpoint = `${dataEndpoint}?type=proficiency`;
    axios.get(proficiencyEndpoint).then((response) => {
      const formatedProficiencyList = formatProficiencyList(
        serializeDataResponse(response)?.proficiency
      );
      setProficiency(() => [
        {
          label: config.defaultProficiency.label,
          value: config.defaultProficiency.value,
        },
        ...formatedProficiencyList,
      ]);
    });

    axios.get(dataEndpoint).then((response) => {
      const formatedCourseProviderList = formatCourseProviderList(
        serializeDataResponse(response)?.courseProviders
      );
      setCourseProvider(formatedCourseProviderList);
      setCourseProvider(() => [
        {
          label: config.defaultCourseProvider.label,
          value: config.defaultCourseProvider.value,
        },
        ...formatedCourseProviderList,
      ]);
    });
  }, []);

  useEffect(() => {
    // const catergoryEndpoint = `${config.endPoint.commonService}/category`;
    // axios.get(catergoryEndpoint).then((response) => {
    if (categoryResponse) {
      const formattedCategoryList = formatCategoryList(
        serializeCategoryResponse(categoryResponse)
      );

      setCategory(() => [
        {
          label: config.defaultCategory.label,
          value: config.defaultCategory?.value,
          subCategory,
        },
        ...formattedCategoryList,
      ]);
      setInitCategory(formattedCategoryList);
      setSelectedCategory([]);
      setSelectedSubCategory([]);
      // });
    }
    const subCategoryEndpoint = `${config.endPoint.commonService}/subCategory`;
    // const subCategoryEndpoint = `/sub-category/`;
    axios.get(subCategoryEndpoint).then((response) => {
      const {
        data: { data },
      } = response;
      const formattedSubCategoryList = formatSubCategoryList(
        serializeSubCategories(response)
      );
      setSubCategory(() => [
        {
          label: "All",
          value: "All",
        },
        ...formattedSubCategoryList,
      ]);
      setSelectedSubCategory([]);
    });
  }, [categoryResponse]);

  useEffect(() => {
    setCurrentFilter(filters);
  }, [filters]);

  const handleLanguage = (currentLanguage) => {
    setSelectedLanguage(currentLanguage);
  };

  const handleParentCategory = (currentParentCategory) => {
    let urlBase = isInstuctorLed
      ? "/Classroom_training/"
      : "/e-learning-courses-list/";

    window.history.replaceState(
      null,
      "",
      urlBase + currentParentCategory?.value + "/all"
    );
    setSelectedCategory([]);
    setSelectedSubCategory([]);
    setSelectedParentCategory(currentParentCategory);
  };

  const handleCategory = (currentCategory) => {
    let urlBase = isInstuctorLed
      ? "/Classroom_training/"
      : "/e-learning-courses-list/";
    const currentParentCategory = parentCategory.find(
      (category) => category.label === currentCategory?.parent
    );
    window.history.replaceState(
      null,
      "",
      `${urlBase}${currentParentCategory?.value}/${currentCategory?.value}`
    );
    setSelectedSubCategory([]);
    setSelectedCategory(currentCategory);
  };

  const handleProficiency = (currentProficiency) => {
    setSelectedProficiency(currentProficiency);
  };

  const handleSubCategory = (currentSubCategory) => {
    setSelectedSubCategory(currentSubCategory);
  };

  const handleSortBy = (currentSortBy) => {
    setSelectedSortBy(currentSortBy);
  };

  const handleCourseProvider = (currentCourseProvider) => {
    setSelectedCourseProvider(currentCourseProvider);
  };

  const handleFilters = ({
    parentCategoryId,
    categoryId,
    subCategoryId,
    sortBy,
    languageId,
    proficiencyId,
  }) => {
    if (subCategoryId) {
      const [value] = subCategory.filter((current) => {
        return +current.value === +subCategoryId;
      });
      setSelectedSubCategory(value);
    } else if (categoryId) {
      const [value] = initCategory.filter((current) => {
        return +current.value === +categoryId;
      });
      setSelectedCategory(value);
    } else if (parentCategoryId) {
      const [value] = parentCategory.filter((current) => {
        return current.value.toString() === parentCategoryId.toString();
      });
      setSelectedParentCategory(value);
    }

    if (languageId) {
      const [value] = language.filter((current) => {
        return current.value === languageId;
      });
      setSelectedLanguage(value);
    }

    if (proficiencyId) {
      const [value] = proficiency.filter((current) => {
        return current.value === proficiencyId;
      });
      setSelectedProficiency(value);
    }

    if (sortBy) {
      const [value] = sortByFilters.filter((current) => {
        return current.value === sortBy;
      });
      setSelectedSortBy(value);
    } else {
      setSelectedSortBy(config.instructorLed.sortBy[0]);
    }
  };

  //set filters coming from filters
  useEffect(() => {
    if (
      currentFilter &&
      parentCategory &&
      parentCategory.length &&
      category &&
      category.length &&
      subCategory &&
      subCategory.length &&
      language &&
      language.length &&
      proficiency &&
      proficiency.length
    ) {
      handleFilters(currentFilter);
    }
    // ToDo fix eslint issue
  }, [
    currentFilter,
    parentCategory,
    category,
    subCategory,
    language,
    proficiency,
  ]);

  //fetch category for the case of manual change of category and parentCategory
  useEffect(() => {
    if (
      selectedParentCategory &&
      !Array.isArray(selectedParentCategory) &&
      selectedParentCategory?.value !== "all"
    ) {
      const catergoryEndpoint = `${config.endPoint.commonService}/category?&parentId=${selectedParentCategory?.value}`;
      axios.get(catergoryEndpoint).then((response) => {
        const formattedCategoryList = formatCategoryList(
          serializeCategoryResponse(response)
        );
        if (reset) {
          setSelectedCategory([]);
        }
        setSelectedSubCategory([]);
        setCategory(() => [
          {
            label: config.defaultCategory?.label,
            value: config.defaultCategory?.value,
            subCategory,
          },
          ...formattedCategoryList,
        ]);
      });
    }
    if (selectedParentCategory?.value === "all") {
      const catergoryEndpoint = `${config.endPoint.commonService}/category`;
      axios.get(catergoryEndpoint).then((response) => {
        const formattedCategoryList = formatCategoryList(
          serializeCategoryResponse(response)
        );
        setCategory(() => [
          {
            label: config.defaultCategory.label,
            value: config.defaultCategory?.value,
            subCategory,
          },
          ...formattedCategoryList,
        ]);
        setSelectedCategory([]);
        setSelectedSubCategory([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParentCategory]);

  // set value of subCategory when category value changes.
  useEffect(() => {
    const newSubCategory = [];
    category.map((current) => {
      return newSubCategory.push(...current.subCategory);
    });
    const newSubCat = newSubCategory.map((current) => {
      return { label: current.name, value: current.id };
    });
    setSubCategory(newSubCat);
  }, [category]);

  // fetch sub category

  useEffect(() => {
    if (selectedCategory && selectedCategory.value) {
      let subCategoryEndpoint;
      if (selectedCategory.value !== config.defaultCategory?.value) {
        subCategoryEndpoint = `${config.endPoint.commonService}/subCategory/?categoryId=${selectedCategory.value}`;
      } else {
        subCategoryEndpoint = `${config.endPoint.commonService}/subCategory`;
      }

      // const subCategoryEndpoint = `/sub-category/?category_id=${selectedCategory.value}`;
      axios.get(subCategoryEndpoint).then((response) => {
        const {
          data: { data },
        } = response;
        const formattedSubCategoryList = formatSubCategoryList(data);
        setSubCategory(() => [
          {
            label: config.defaultCategory.label,
          },
          ...formattedSubCategoryList,
        ]);
        setSelectedSubCategory([]);
      });
    }
  }, [selectedCategory, category]);

  // when subcategory changes.
  useEffect(() => {
    const result =
      selectedSubCategory &&
      category.filter((current) => {
        const val = current.subCategory.map((currentSub) => {
          return +currentSub.id === +selectedSubCategory.value;
        });
        return val.includes(true);
      });
    result && result.length && setSelectedCategory(result[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubCategory]);

  // when category changes parent category should show correct selected parent catergoy
  useEffect(() => {
    if (parentCategory && parentCategory.length) {
      const result =
        selectedCategory &&
        !Array.isArray(selectedCategory) &&
        parentCategory.filter((current) => {
          return current.label === selectedCategory.parent;
        });
      result && result.length && setSelectedParentCategory(result[0]);
    }
  }, [selectedCategory, parentCategory]);

  useEffect(() => {
    if (
      (selectedParentCategory && selectedParentCategory.label) ||
      (selectedCategory && selectedCategory.value) ||
      (selectedSubCategory && selectedSubCategory.value) ||
      (selectedSortBy && selectedSortBy.value) ||
      (selectedLanguage && selectedLanguage.value) ||
      (selectedProficiency && selectedProficiency.value)
    ) {
      const obj = {};
      if (selectedSubCategory && selectedSubCategory.value)
        obj.subCategoryId = selectedSubCategory.value;
      if (selectedCategory && selectedCategory.value)
        obj.categoryId = selectedCategory.value;
      if (selectedParentCategory && selectedParentCategory.value)
        obj.parentCategoryId = selectedParentCategory.value;
      if (selectedSortBy && selectedSortBy.value)
        obj.sortBy = selectedSortBy.value;
      if (selectedLanguage && selectedLanguage.value)
        obj.languageId = selectedLanguage.value;
      if (selectedProficiency && selectedProficiency.value)
        obj.proficiencyId = selectedProficiency.value;
      if (selectedCourseProvider && selectedCourseProvider.value) {
        obj.courseProviderId = selectedCourseProvider.id;
      }
      upliftFilters(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedParentCategory,
    selectedCategory,
    selectedSubCategory,
    selectedSortBy,
    selectedLanguage,
    selectedProficiency,
    selectedCourseProvider,
  ]);
  const inlineStyleSelect = {
    control: (base) => ({
      ...base,
      borderColor: "#000",
      borderRadius: "2px",
      height: "2.5rem",
      fontSize: ".8125rem",
    }),
  };

  return (
    <>
      {window.innerWidth > 768 ? (
        <Row gutter={[10]} className={style.courseFilterOuter}>
          <Col span={20}>
            <Row>
              <Col className={style.titleStyle}>Filter By</Col>
            </Row>

            <Row className={style.filtersRow}>
              <Col span={24}>
                <Row gutter={[16]}>
                  {isInstuctorLed ? (
                    <>
                      <Col span={4}>
                        <div className={style.styleSelectBox}>
                          <CustomSelect
                            options={parentCategory}
                            placeholder="SKILLS"
                            selected={selectedParentCategory}
                            handleDropdown={(selectedParentCategory) =>
                              handleParentCategory(selectedParentCategory)
                            }
                            inlineStyle={inlineStyleSelect}
                            customStyle={style.optionsStyle}
                          />
                        </div>
                      </Col>
                      <Col span={4}>
                        <CustomSelect
                          options={category}
                          placeholder="CATEGORIES"
                          selected={selectedCategory}
                          handleDropdown={(selectedCategory) =>
                            handleCategory(selectedCategory)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>
                      <Col span={4}>
                        <CustomSelect
                          options={subCategory}
                          placeholder="SUB CATEGORIES"
                          disable={Array.isArray(selectedCategory)}
                          selected={selectedSubCategory}
                          handleDropdown={(selectedSubCategory) =>
                            handleSubCategory(selectedSubCategory)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>

                      <Col span={4}>
                        <CustomSelect
                          options={courseProvider}
                          placeholder="Course Provider"
                          selected={selectedCourseProvider}
                          handleDropdown={(selectedCourseProvider) =>
                            handleCourseProvider(selectedCourseProvider)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>

                      <Col span={4}>
                        <CustomSelect
                          options={language}
                          placeholder="LANGUAGE"
                          selected={selectedLanguage}
                          handleDropdown={(selectedLanguage) =>
                            handleLanguage(selectedLanguage)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>

                      <Col span={4} style={{ color: "#000" }}>
                        <CustomSelect
                          options={proficiency}
                          placeholder="PROFICIENCY"
                          selected={selectedProficiency}
                          handleDropdown={(selectedProficiency) =>
                            handleProficiency(selectedProficiency)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={6}>
                        <div className={style.styleSelectBox}>
                          <CustomSelect
                            options={parentCategory}
                            placeholder="SKILLS"
                            selected={selectedParentCategory}
                            handleDropdown={(selectedParentCategory) =>
                              handleParentCategory(selectedParentCategory)
                            }
                            inlineStyle={inlineStyleSelect}
                            customStyle={style.optionsStyle}
                          />
                        </div>
                      </Col>
                      <Col span={6}>
                        <CustomSelect
                          options={category}
                          placeholder="CATEGORIES"
                          selected={selectedCategory}
                          handleDropdown={(selectedCategory) =>
                            handleCategory(selectedCategory)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomSelect
                          options={subCategory}
                          placeholder="SUB CATEGORIES"
                          disable={Array.isArray(selectedCategory)}
                          selected={selectedSubCategory}
                          handleDropdown={(selectedSubCategory) =>
                            handleSubCategory(selectedSubCategory)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>
                      <Col span={6}>
                        <CustomSelect
                          options={courseProvider}
                          placeholder="Course Provider"
                          selected={selectedCourseProvider}
                          handleDropdown={(selectedCourseProvider) =>
                            handleCourseProvider(selectedCourseProvider)
                          }
                          inlineStyle={inlineStyleSelect}
                          customStyle={style.optionsStyle}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={4}>
            <Row>
              <Col className={style.titleStyle}>Sort By</Col>
            </Row>
            <Row className={style.filtersRow}>
              <Col style={{ width: "200px" }}>
                <CustomSelect
                  options={sortByFilters}
                  selected={selectedSortBy}
                  handleDropdown={(selectedSortBy) =>
                    handleSortBy(selectedSortBy)
                  }
                  inlineStyle={inlineStyleSelect}
                  customStyle={style.optionsStyle}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <div
          style={{
            display: "flex",
            overflow: "scroll",
            flexDirection: "row",
            width: "23rem",
            margin: ".5rem",
          }}
        >
          <CustomSelect
            options={sortByFilters}
            selected={selectedSortBy}
            handleDropdown={(selectedSortBy) => handleSortBy(selectedSortBy)}
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
            placeholder="SORT BY"
          />
          <CustomSelect
            options={parentCategory}
            placeholder="SKILLS"
            selected={selectedParentCategory}
            handleDropdown={(selectedParentCategory) =>
              handleParentCategory(selectedParentCategory)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
          <CustomSelect
            options={category}
            placeholder="CATEGORIES"
            selected={selectedCategory}
            handleDropdown={(selectedCategory) =>
              handleCategory(selectedCategory)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
          <CustomSelect
            options={subCategory}
            placeholder="SUB CATEGORIES"
            disable={Array.isArray(selectedCategory)}
            selected={selectedSubCategory}
            handleDropdown={(selectedSubCategory) =>
              handleSubCategory(selectedSubCategory)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />

          <CustomSelect
            options={courseProvider}
            placeholder="Course Provider"
            selected={selectedCourseProvider}
            handleDropdown={(selectedCourseProvider) =>
              handleCourseProvider(selectedCourseProvider)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
          <CustomSelect
            options={language}
            placeholder="LANGUAGE"
            selected={selectedLanguage}
            handleDropdown={(selectedLanguage) =>
              handleLanguage(selectedLanguage)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
          <CustomSelect
            options={proficiency}
            placeholder="PROFICIENCY"
            selected={selectedProficiency}
            handleDropdown={(selectedProficiency) =>
              handleProficiency(selectedProficiency)
            }
            inlineStyle={inlineStyleSelect}
            customStyle={style.optionsStyle}
          />
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect, useContext, useReducer } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  Typography,
  Form,
  Row,
  Col,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import TimeFormaters from "../../../utils/timeFormatter";
import {
  validateSessionDetails,
  convertUTCDateToTimeStamp,
  ValidateStartDate,
  ValidateEndDate,
  ValidateStartTime,
  ValidateEndTime,
} from "./sessionAddEdit.lib.js";
import SessionAddEditDateTimePicker from "./SessionAddEditDateTimePicker";
import lockedIcon from "./../../../assets/icons/locked.svg";
import unlockedIcon from "./../../../assets/icons/openLock.svg";
import moment from "moment";
import "./SessionAddEdit.scss";
import Select from "react-select";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { Toggle, CustomToggle } from "../../../component-library";
import SessionAddEditConfirmationModal from "./SessionAddEditConfirmationModal";
import SessionAddEditNumberCounter from "./SessionAddEditNumberCounter";
import config from "../../../config";
import { SlLockOpen, SlLock } from "react-icons/sl";

const initialModuleList = [];

const MODULE_LIST_ACTIONS = {
  fetchResult: "FETCH_RESULT",
  startDateChange: "START_DATE_CHANGE",
  startTimeChange: "START_TIME_CHANGE",
  endTimeChange: "END_TIME_CHANGE",
};

const defaultTimezoneIndex = TimeFormaters.timeZoneList.indexOf(
  TimeFormaters.timeZoneList.filter(
    (d) => d.value === new Date().getTimezoneOffset() / -60
  )[0]
);
const tday = moment(new Date().toISOString()).format("YYYY-MM-DD");
const today = TimeFormaters.getUtcTimeStamp(tday);
const moduleListReducer = (state, action) => {
  switch (action.type) {
    case MODULE_LIST_ACTIONS.fetchResult:
      return action.payload;

    case MODULE_LIST_ACTIONS.startDateChange: {
      const id = action.payload.id;
      return state.map((current) => {
        if (current.id === id) {
          const startTime = TimeFormaters.FormatTime(
            current.start_date,
            action.payload.timeZone
          );
          const utcformatStartDate = TimeFormaters.getUtcTimeStamp(
            action.payload.startDate,
            startTime,
            action.payload.timeZone
          );
          // for end date
          const endTime = TimeFormaters.FormatTime(
            current.end_date,
            action.payload.timeZone
          );
          const utcformatEndDate = TimeFormaters.getUtcTimeStamp(
            action.payload.startDate,
            endTime,
            action.payload.timeZone
          );

          return {
            ...current,
            id,
            start_date: utcformatStartDate,
            end_date: utcformatEndDate,
          };
        } else {
          return current;
        }
      });
    }
    case MODULE_LIST_ACTIONS.startTimeChange: {
      const id = action.payload.id;
      return state.map((current) => {
        if (current.id === id) {
          const oldStartDateTime = current.start_date;
          const oldStartDateWithoutTime = convertUTCDateToTimeStamp(
            oldStartDateTime,
            action.payload.timeZone
          );
          const utcformatDate = TimeFormaters.getUtcTimeStamp(
            oldStartDateWithoutTime,
            action.payload.startTime,
            action.payload.timeZone
          );
          return {
            ...current,
            id,
            start_date: utcformatDate,
          };
        } else {
          return current;
        }
      });
    }
    case MODULE_LIST_ACTIONS.endTimeChange: {
      const id = action.payload.id;
      return state.map((current) => {
        if (current.id === id) {
          const oldEndDateTime = current.end_date;
          const oldEndDateWithoutTime = convertUTCDateToTimeStamp(
            oldEndDateTime,
            action.payload.timeZone
          );
          const utcformatDate = TimeFormaters.getUtcTimeStamp(
            oldEndDateWithoutTime,
            action.payload.endTime,
            action.payload.timeZone
          );
          return { ...current, id, end_date: utcformatDate };
        } else {
          return current;
        }
      });
    }

    default:
      return state;
  }
};

export default function SessionAddEdit() {
  const history = useHistory();

  const query = new URLSearchParams(history.location.search);
  const courseId = query.get("courseId");
  const sessionId = query.get("sessionId");

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
      to: "/Admin/Courses",
    },
    {
      title: (
        <a href="/Admin/Courses" className="common-breadcrumbs-color">
          Instructor-led Courses
        </a>
      ),
      to: "/Admin/Courses",
    },
    {
      title: (
        <a
          href={`/Admin/SessionList/${courseId}`}
          className="common-breadcrumbs-color"
        >
          Manage Sessions
        </a>
      ),
      to: `/Admin/SessionList/${courseId}`,
    },
    {
      title: (sessionId && "Edit Session") || "Add Session",
    },
  ];

  let redirect;

  const { userId } = useContext(UserContext);

  const [publicPrivate, setPublicPrivate] = useState(null);

  const [country, setCountry] = useState("");
  const [selectedCountiesForCourse, setSelectedCountriesForCourse] = useState(
    []
  );
  const [oldSelectedCountriesForCourse, setOldSelectedCountriesForCourse] =
    useState([]);

  const [selectedCountry, setSelectedCountry] = useState([]);

  const [timeZone, setTimeZone] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");

  const [courseTitle, setCourseTitle] = useState("");
  const [courseIdentityId, setCourseIdentityId] = useState("");

  const [minUser, setMinUser] = useState(0);
  const [maxUser, setMaxUser] = useState(0);

  const [sessionName, setSessionName] = useState("");

  const [locked, setLocked] = useState(false);

  const [errorMessage, setErrorMessage] = useState({});
  const [unformattedCountries, setUnformattedList] = useState([]);

  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");

  const [enableDateTime, setEnableDateTime] = useState(null);

  const [openModuleList, setOpenModuleList] = useState(false);

  const [oldStartDate, setOldStartDate] = useState(null);
  const [oldEndDate, setOldEndDate] = useState(null);

  const [moduleList, dispatchActionForModuleList] = useReducer(
    moduleListReducer,
    initialModuleList
  );

  const [sessionData, setSessionData] = useState({});

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const endPoint = `${config.endPoint.commonService}/data`;
    axios.get(endPoint).then((response) => {
      const {
        data: { data },
      } = response || {};
      const { countries } = data || {};
      setUnformattedList(countries);
    });
  }, []);

  useEffect(() => {
    if (courseId) {
      const endPoint = `${config.endPoint.instructorLedNew}/course/${courseId}`;
      axios.get(endPoint).then((response) => {
        const {
          data: { data },
        } = response;

        const result = data;
        const countryArray = result.countries;
        if (unformattedCountries && unformattedCountries.length) {
          const formattedSelectedCountries = countryArray
            .map((current) => {
              return unformattedCountries.find((curr) => curr.code === current);
            })
            .map((curr) => {
              return { value: curr.code, label: curr.country };
            });
          const newFormattedCountries = [
            { value: "NULL", label: "All Countries of Course" },
            ...formattedSelectedCountries,
          ];

          setSelectedCountriesForCourse(() => {
            return (publicPrivate && newFormattedCountries) || [];
          });
          setOldSelectedCountriesForCourse(newFormattedCountries);
        }
        setCourseIdentityId(result.identity_id);
        setCourseTitle(result.course_name);
      });
    }
  }, [courseId, unformattedCountries, publicPrivate]);

  useEffect(() => {
    // get selected countries . in case of edit.
    if (courseId && sessionId) {
      const endPoint = `${config.endPoint.instructorLedNew}/batch?course_id=${courseId}&batch_id=${sessionId}&need_schedule=true`;

      axios.get(endPoint).then((response) => {
        const {
          data: { data: result },
        } = response;
        setSessionData(result);
        setSessionName(result.batch_name);
        setMinUser(result.min_size);
        setMaxUser(result.size);
        setPublicPrivate(result.private);

        const previousTimezoneIndex = TimeFormaters.timeZoneList.indexOf(
          TimeFormaters.timeZoneList.filter(
            (d) => d.value === parseFloat(result.tz_offset)
          )[0]
        );
        setTimeZone(TimeFormaters.timeZoneList[previousTimezoneIndex]);

        setStartDate(
          TimeFormaters.HTMLDateInputFormat(result.start_time, result.tz_offset)
        );
        setOldStartDate(
          TimeFormaters.HTMLDateInputFormat(result.start_time, result.tz_offset)
        );
        setStartTime(
          TimeFormaters.FormatTime(result.start_time, result.tz_offset)
        );

        setEndDate(
          TimeFormaters.HTMLDateInputFormat(result.end_time, result.tz_offset)
        );
        setOldEndDate(
          TimeFormaters.HTMLDateInputFormat(result.end_time, result.tz_offset)
        );

        setEndTime(TimeFormaters.FormatTime(result.end_time, result.tz_offset));

        setLocked(result.is_locked);
        dispatchActionForModuleList({
          type: MODULE_LIST_ACTIONS.fetchResult,
          payload: result.schedule,
        });
      });
    } else {
      setStartTime("8:30 AM");
      setEndTime("5:30 PM");
      setStartDate(tday);
      setEndDate(tday);
      setTimeZone(
        TimeFormaters.timeZoneList[defaultTimezoneIndex] ||
          TimeFormaters.timeZoneList[0]
      );
    }
  }, [courseId, sessionId]);

  useEffect(() => {
    setSelectedCountry(
      selectedCountiesForCourse.filter(
        (current) => current.value === sessionData.country
      )[0]
    );
    setCountry(
      selectedCountiesForCourse.filter(
        (current) => current.value === sessionData.country
      )[0]
    );
    // eslint-disable-next-line
  }, [selectedCountiesForCourse]);

  useEffect(() => {
    return clearTimeout(redirect);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setErrorMessage({
      ...errorMessage,
      ...ValidateStartDate(startDate, endDate),
      ...ValidateEndDate(endDate, startDate),
      ...ValidateStartTime(startDate, endDate, startTime, endTime),
      ...ValidateEndTime(startDate, endDate, startTime, endTime),
    });
    // eslint-disable-next-line
  }, [startDate, endDate, startTime, endTime]);

  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    const obj = {
      batch_name: sessionName,
      private: publicPrivate || false,
      enrollment: locked,
      country: (country && country.value) || "NULL",
      course_id: parseInt(courseId),
      language: "NA",
      start_time: TimeFormaters.getUtcTimeStamp(
        startDate,
        startTime,
        timeZone.value
      ),
      end_time: TimeFormaters.getUtcTimeStamp(endDate, endTime, timeZone.value),
      size: maxUser,
      min_size: minUser,
      tz_offset: timeZone.value,
      is_locked: locked,
      user_id: parseInt(userId),
      schedules: moduleList.map((current) => {
        return {
          schedule_id: current.id,
          start_date: current.start_date,
          end_date: current.end_date,
        };
      }),
    };

    const errors = validateSessionDetails(obj);

    if (errors) {
      setErrorMessage({ ...errorMessage, ...errors });
    }

    if (sessionId) {
      const endPoint = `/batch/update-batch?batch_id=${sessionId}`;
      if (!errors || !Object.keys(errors).length) {
        axios
          .put(endPoint, obj)
          .then((response) => {
            setAlert(response.data.message);
            setShowAlert(true);
            if (response && !!response.data.success) {
              history.push({
                pathname: `/Admin/SessionList/${courseId}`,
                state: {
                  alertType: "success",
                  showAlert: true,
                  alertMessage: response.data.message,
                },
              });
            } else {
              setAlertType("error");
              setShowAlert(true);
              setAlert(response.data.message);
            }
          })
          .catch((error) => {
            setAlert(error.response.data.message);
            setAlertType("error");
            setShowAlert(true);
          });
      }
    } else {
      const endPoint = `/batch/create`;
      if (!errors || !Object.keys(errors).length) {
        axios
          .post(endPoint, obj)
          .then((response) => {
            history.push({
              pathname: `/Admin/SessionList/${courseId}`,
              state: {
                alertType: "success",
                showAlert: true,
                alertMessage: response.data.message,
              },
            });
          })
          .catch((error) => {
            setAlert(error.response.data.message);
            setShowAlert(true);
            setAlertType("error");
          });
      }
    }
  };

  const toggle = (value) => {
    if (!value) {
      setCountry("");
      setSelectedCountriesForCourse(() => []);
    } else {
      setSelectedCountriesForCourse(() => oldSelectedCountriesForCourse);
    }
    setPublicPrivate((prev) => !prev);
  };

  const handleCountry = (country) => {
    if (country) {
      setCountry(country);
      setSelectedCountry(country);
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      if (!publicPrivate) {
        const error = { country: `Please select atleast one Country.` };
        setErrorMessage({ ...errorMessage, ...error });
      }
    }
  };

  const handleSessionName = (sessionName) => {
    setSessionName(sessionName);
    if (!sessionName) {
      const error = { sessionName: `Please enter Session Name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else setSessionName(sessionName);
    if (sessionName && sessionName.length < 2) {
      const error = {
        sessionName: `Session Name should not be less than 2 characters`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (sessionName.trim() === "") {
      const error = { sessionName: "Session Name cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { sessionName: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleMinUser = (minUser) => {
    setMinUser(minUser);
    if (!minUser) {
      const error = { minUser: `Please select the minimum users` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (minUser < 3) {
      const error = { minUser: `Number of user cannot be less than 3` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { minUser: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleMaxUser = (maxUser) => {
    setMaxUser(maxUser);
    if (!maxUser) {
      const error = { maxUser: `Please select the maximum user` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (maxUser < 3) {
      const error = { maxUser: `Number of user cannot be less than 3` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { maxUser: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleTimeZone = (timeZone) => {
    setTimeZone(timeZone);
    if (!timeZone) {
      const error = { timeZone: `Please select the Time Zone` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { timeZone: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
    if (startDate > endDate) {
      setEndDate(startDate);
    }
  };

  const handleStartTime = (value) => {
    setStartTime(value);
  };

  const handleEndDate = (endDate) => {
    setEndDate(endDate);
  };

  const handleEndTime = (endTime) => {
    setEndTime(endTime);
  };

  const deleteSession = () => {
    closeConfirmationPopUp();
    const endPoint = `/batch/update-batch?batch_id=${sessionId}`;
    axios
      .delete(endPoint)
      .then((response) => {
        if (response.data.success) {
          setAlert(response.data.message);
          setShowAlert(true);
          setAlertType("success");
          redirect = setTimeout(() => {
            history.push(`/Admin/SessionList/${courseId}`);
          }, config.expectedTimeOuts.halfSecond);
        } else {
          setAlert(response.data.message);
          setShowAlert(true);
          setAlertType("error");
        }
      })
      .catch((error) => {
        setAlert(error.response.data.message);
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const oldStartDateUTC = TimeFormaters.getUtcTimeStamp(oldStartDate);
  const oldEndDateUTC = TimeFormaters.getUtcTimeStamp(oldEndDate);

  useEffect(() => {
    if (oldStartDateUTC && oldEndDateUTC) {
      if (today <= oldStartDateUTC) {
        setEnableDateTime(true);
      } else {
        setEnableDateTime(false);
      }
    }
  }, [oldStartDateUTC, oldEndDateUTC]);

  const closeConfirmationPopUp = () => {
    setShowConfirmation(false);
  };

  const minDateValue =
    (today >= oldStartDateUTC && oldStartDate) ||
    moment(new Date().toISOString()).format("YYYY-MM-DD");

  return (
    <div className="AntdSessionAddEdit-container">
      {showConfirmation && (
        <SessionAddEditConfirmationModal
          show={showConfirmation}
          handleClose={closeConfirmationPopUp}
          toggle={toggle}
          title="Are you sure want to "
          titleBold={`delete session name ${sessionName}`}
          leftButton={"Yes"}
          rightButton={"NO"}
          leftButtonClick={deleteSession}
          rightButtonClick={closeConfirmationPopUp}
        />
      )}
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          className={"admin-alert"}
        />
      )}
      <Breadcrumb items={crumbs} className={"sessionAddEdit-crumbs"} />

      <Form onSubmit={submitHandler}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "83rem",
          }}
        >
          <Typography.Title className="AntdAddsession-page-title">
            {sessionId ? `Edit Session - ${sessionId}` : "Add Session"}
          </Typography.Title>
          <Row>
            {sessionId && (
              <CustomToggle
                isImage={false}
                toggleTitle={["Open Enrollment", "Enrollment Closed"]}
                value={!locked}
                icons={[SlLockOpen, SlLock]}
                onChange={(value) => {
                  setLocked((prev) => !prev);
                }}
              />
            )}
            <Col>
              {!sessionId && ( //for course create page
                <Toggle
                  firstLabelName="PUBLIC"
                  secondLabelName="PRIVATE"
                  selected={publicPrivate}
                  toggle={toggle}
                />
              )}
              {sessionId &&
                publicPrivate !== null && ( //for course edit page
                  <Toggle
                    firstLabelName="PUBLIC"
                    secondLabelName="PRIVATE"
                    selected={publicPrivate}
                    toggle={toggle}
                  />
                )}
            </Col>
          </Row>
        </div>
        <Typography className={"sessionAddEdit-label"}>
          <span
            style={{ fontFamily: "RakutenRoundSemiBold", fontSize: "1rem" }}
          >
            {courseIdentityId} -{" "}
          </span>
          {decodeHtmlEntities(courseTitle)}
        </Typography>
        <Row fluid="true" style={{ margin: 0 }}>
          <Col lg={8}>
            <Typography className={"sessionAddEdit-label"}>
              Session Name
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <Input
              placeholder="Enter the Session name"
              pattern="^\S.*$"
              onChange={(e) => {
                handleSessionName(e.target.value);
              }}
              value={sessionName}
              className={"sessionAddEdit-input"}
            />
            {(errorMessage.sessionName && (
              <Row className={"sessionAddEdit-validation"}>
                {errorMessage.sessionName}
              </Row>
            )) ||
              ""}
          </Col>
          <Col lg={7} style={{ width: "20rem" }}>
            <Typography className={"sessionAddEdit-label"}>
              Session Country
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <Select
              placeholder="All Countries of Course"
              value={selectedCountry || null}
              options={selectedCountiesForCourse.sort((a, b) =>
                a.label.localeCompare(b.label)
              )}
              isDisabled={!publicPrivate}
              classNamePrefix={`innerclass`}
              onChange={(current) => {
                handleCountry(current);
              }}
              style={{ fontFamily: "RakutenRoundRegular" }}
            />
            {(errorMessage.country && (
              <Row className={"sessionAddEdit-validation"}>
                {errorMessage.country}
              </Row>
            )) ||
              ""}
          </Col>
          <Col lg={1}></Col>
          <Row style={{ width: "26.5625rem", margin: 0 }}>
            <Col
              bsPrefix={`col ${"topColumn"}`}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "1.4375rem",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                Min Users
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              <SessionAddEditNumberCounter
                selected={minUser}
                changeHandler={(value) => {
                  handleMinUser(value);
                }}
              />
              {(errorMessage.minUser && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.minUser}
                </Row>
              )) ||
                ""}
            </Col>

            <Col
              lg={11}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                Max Users
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              <SessionAddEditNumberCounter
                selected={maxUser}
                changeHandler={(value) => {
                  handleMaxUser(value);
                }}
              />

              {(errorMessage.maxUser && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.maxUser}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
        </Row>
        <Row fluid="true" style={{ margin: 0 }}>
          <Col lg={8} style={{ maxWidth: "26.5625rem", marginRight: "1.3rem" }}>
            <Typography className={"sessionAddEdit-label"}>
              Time Zone
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <Select
              value={timeZone}
              options={TimeFormaters.timeZoneList}
              classNamePrefix={`innerclass`}
              onChange={(value) => {
                handleTimeZone(value);
              }}
            />
            {(errorMessage.timeZone && (
              <Row className={"sessionAddEdit-validation"}>
                {errorMessage.timeZone}
              </Row>
            )) ||
              ""}
          </Col>
          <Row
            style={{
              width: "26.5625rem",
              margin: 0,
              marginRight: "1.4375rem",
            }}
          >
            <Col
              bsPrefix={`col ${"topColumn"}`}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "1.4375rem",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                Start Date
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              {/* in case of add  */}
              {enableDateTime === null && (
                <SessionAddEditDateTimePicker
                  // minValue={minDateValue}
                  allowInput={true}
                  clickOpens={true}
                  value={startDate}
                  onChange={(current) => {
                    handleStartDate(current);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {/* in case of edit  */}

              {enableDateTime !== null && (
                <SessionAddEditDateTimePicker
                  minValue={minDateValue}
                  allowInput={enableDateTime}
                  clickOpens={enableDateTime}
                  value={startDate}
                  onChange={(current) => {
                    handleStartDate(current);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {(errorMessage.startDate && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.startDate}
                </Row>
              )) ||
                ""}
            </Col>
            <Col
              bsPrefix={`col ${"topColumn"}`}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                Start Time
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              {enableDateTime === null && (
                <SessionAddEditDateTimePicker
                  value={startTime}
                  allowInput={true}
                  clickOpens={true}
                  pickTime={true}
                  onChange={(current) => {
                    handleStartTime(current);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <SessionAddEditDateTimePicker
                  value={startTime}
                  allowInput={enableDateTime}
                  clickOpens={enableDateTime}
                  pickTime={true}
                  onChange={(current) => {
                    handleStartTime(current);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {(errorMessage.startTime && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.startTime}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ width: "26.5625rem", margin: 0 }}>
            <Col
              bsPrefix={`col ${"topColumn"}`}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "1.4375rem",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                End Date
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              {enableDateTime === null && (
                <SessionAddEditDateTimePicker
                  value={endDate}
                  allowInput={true}
                  clickOpens={true}
                  minValue={startDate}
                  onChange={(endDate) => {
                    handleEndDate(endDate);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <SessionAddEditDateTimePicker
                  minValue={startDate}
                  value={endDate}
                  allowInput={enableDateTime}
                  clickOpens={enableDateTime}
                  onChange={(endDate) => {
                    handleEndDate(endDate);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {(errorMessage.endDate && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.endDate}
                </Row>
              )) ||
                ""}
            </Col>
            <Col
              bsPrefix={`col ${"topColumn"}`}
              style={{
                marginLeft: 0,
                width: "12.5625rem",
                marginRight: "0",
              }}
            >
              <Typography className={"sessionAddEdit-label"}>
                End Time
                <span className={"sessionAddEdit-required"}>*</span>
              </Typography>
              {enableDateTime === null && (
                <SessionAddEditDateTimePicker
                  allowInput={true}
                  clickOpens={true}
                  value={endTime}
                  pickTime={true}
                  onChange={(endTime) => {
                    handleEndTime(endTime);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {enableDateTime !== null && (
                <SessionAddEditDateTimePicker
                  allowInput={enableDateTime}
                  clickOpens={enableDateTime}
                  value={endTime}
                  pickTime={true}
                  onChange={(endTime) => {
                    handleEndTime(endTime);
                    setOpenModuleList(true);
                  }}
                />
              )}
              {(errorMessage.endTime && (
                <Row className={"sessionAddEdit-validation"}>
                  {errorMessage.endTime}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
        </Row>

        {(openModuleList && sessionId && moduleList && moduleList.length && (
          <Row
            fluid="true"
            style={{
              margin: 0,
              marginBottom: "5.125rem",
            }}
          >
            <p>
              * Please make sure that &nbsp;
              <span style={{ fontFamily: "RakutenRoundSemiBold" }}>
                Session start / end time and Module start / end time
              </span>
              &nbsp; should be aligned.
            </p>
          </Row>
        )) ||
          ""}

        {(openModuleList &&
          moduleList &&
          moduleList.length &&
          moduleList.map((current) => {
            const startDate = TimeFormaters.HTMLDateInputFormat(
              current.start_date,
              timeZone.value
            );
            const startTime = TimeFormaters.FormatTime(
              current.start_date,
              timeZone.value
            );
            const endTime = TimeFormaters.FormatTime(
              current.end_date,
              timeZone.value
            );

            return (
              <Row fluid="true" style={{ margin: 0 }} key={current.id}>
                <Col
                  style={{
                    marginTop: "2.375rem",
                    fontFamily: "RakutenRoundSemiBold",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                >
                  Modules #{current.id}:
                </Col>
                <Col
                  bsPrefix={`col ${"topColumn"}`}
                  style={{
                    marginLeft: 0,
                    width: "12.5625rem",
                    marginRight: "1.4375rem",
                  }}
                >
                  <Typography className={"sessionAddEdit-label"}>
                    Start Date
                    <span className={"sessionAddEdit-required"}>*</span>
                  </Typography>
                  <SessionAddEditDateTimePicker
                    value={startDate}
                    minValue={minDateValue}
                    allowInput={enableDateTime}
                    clickOpens={enableDateTime}
                    onChange={(startDate) => {
                      dispatchActionForModuleList({
                        type: MODULE_LIST_ACTIONS.startDateChange,
                        payload: {
                          id: current.id,
                          startDate,
                          session: sessionData,
                          timeZone: timeZone.value,
                        },
                      });
                    }}
                  />
                </Col>
                <Col
                  bsPrefix={`col ${"topColumn"}`}
                  style={{
                    marginLeft: 0,
                    width: "12.5625rem",
                    marginRight: "1.4375rem",
                  }}
                >
                  <Typography className={"sessionAddEdit-label"}>
                    Start Time
                    <span className={"sessionAddEdit-required"}>*</span>
                  </Typography>
                  <SessionAddEditDateTimePicker
                    pickTime={true}
                    value={startTime}
                    allowInput={enableDateTime}
                    clickOpens={enableDateTime}
                    onChange={(startTime) => {
                      dispatchActionForModuleList({
                        type: MODULE_LIST_ACTIONS.startTimeChange,
                        payload: {
                          id: current.id,
                          session: sessionData,
                          startTime,
                          timeZone: timeZone.value,
                        },
                      });
                    }}
                  />
                </Col>
                <Col
                  bsPrefix={`col ${"topColumn"}`}
                  style={{
                    marginLeft: 0,
                    width: "12.5625rem",
                    marginRight: "1.4375rem",
                  }}
                >
                  <Typography className={"sessionAddEdit-label"}>
                    End Time
                    <span className={"sessionAddEdit-required"}>*</span>
                  </Typography>
                  <SessionAddEditDateTimePicker
                    pickTime={true}
                    value={endTime}
                    allowInput={enableDateTime}
                    clickOpens={enableDateTime}
                    onChange={(endTime) => {
                      dispatchActionForModuleList({
                        type: MODULE_LIST_ACTIONS.endTimeChange,
                        payload: {
                          id: current.id,
                          endTime,
                          timeZone: timeZone.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            );
          })) ||
          ""}
        <Row style={{ marginTop: "4.15625rem" }}>
          {(sessionId && (
            <Col lg={4}>
              <Button
                type="button"
                className={"sessionAddEdit-deleteButton"}
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                Delete
              </Button>
            </Col>
          )) ||
            ""}
          <Col>
            <Button
              type="button"
              className={
                "sessionAddEdit-updateButton common-button-background-color"
              }
              onClick={submitHandler}
            >
              {(sessionId && "Update") || "Submit"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

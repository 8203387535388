import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { customFilter } from "react-bootstrap-table2-filter";
import { Row, Col, Typography, Tabs, Button, Input, Alert, Spin } from "antd";
import {
  DownloadOutlined,
  UserAddOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import TimeFormatter from "../../../utils/timeFormatter";
import privateIcon from "../../../assets/icons/private_icon.svg";
import swapIcon from "../../../assets/icons/swap_icon.svg";
import mailIcon from "../../../assets/icons/mail_icon.svg";
import "./sessionList.scss";
import countryData from "./country_list.json";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { LineProgressBar, RoundProgressBar } from "../../../component-library";
import SessionListEmailModal from "./SessionListEmailModal";
import ImportUserHelper from "../../../component-library/importAddUserModal/ImportUserHelper";
import SwapUsersHelper from "../../../component-library/swapUsersModal/SwapUsersHelper";
import AttendanceModel from "./AttendanceModal";
import { useContext } from "react";
import { CourseContext } from "../../../context/CourseContext";
import { Breadcrumb, Table } from "antd";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import config from "../../../config";
import { UserContext } from "../../../context/UserContext";
import PrivateCourseIcon from "../../../components/common/privateCourseIcon";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="common-breadcrumbs-color">
        Admin Dashboard
      </a>
    ),
  },
  {
    title: (
      <a href="/Admin/Courses" className="common-breadcrumbs-color">
        Instructor-led Courses
      </a>
    ),
  },
  {
    title: "Manage Sessions",
  },
];

export default function SessionList(props) {
  const history = useHistory();
  const location = useLocation();
  const { userDateFormat } = useContext(CourseContext);
  const { TabPane } = Tabs;
  const [courseData, setCourseData] = useState("");
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [active, setActive] = useState(true);
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [csvFileName, setCsvFileName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [showAttendaceModel, setShowAttendaceModel] = useState(false);
  const [bottom, setBottom] = useState("bottomCenter");
  const [currentSession, setCurrentSession] = useState({});
  const [approvalRequired, setApprovalRequired] = useState(false);
  const [reachedMaxSessionLimit, setReachedMaxSessionLimit] = useState(false);
  const { userConfig } = useContext(UserContext);

  const activeSessionColumns = [
    {
      dataIndex: "private",
      title: (
        <span style={{ paddingLeft: "0.8rem" }}>
          <PrivateCourseIcon color2={userConfig.secondary} />
        </span>
      ),
      csvExport: false,
      width: 50,
      width: 60,
      onFilter: (value, record) =>
        value === "Private" ? record.private : !record.private,
      filters: [
        { text: "Private Session", value: "Private" },
        { text: "Public Session", value: "Public" },
      ],
      render: (cell, row) => {
        if (row.private) {
          return (
            <Button type="button" alt="private course">
              <PrivateCourseIcon color2={userConfig.secondary} />
            </Button>
          );
        }
        return null;
      },

      filter: customFilter(),
    },
    {
      dataIndex: "country",
      title: "Country".toUpperCase(),
      sorter: (a, b) => a.country.localeCompare(b.country),
      width: 10,
      render: (cell, row) => {
        let countryCode = row.country === "NULL" ? "NULL" : row.country;
        if (!row.private) {
          if (row.country.length > 0 && row.country.length < 2) {
            countryCode = row.country[0];
          }
        }
        let iconStyle = countryCode === "NULL" ? "globeIcon" : "countryIcon";
        let countrySrc = getCountryImage(countryCode);
        let countryTitle = getCountryNames(countryCode);
        return (
          <img
            src={`${process.env.PUBLIC_URL}/${countrySrc}`}
            title={countryTitle}
            className={iconStyle}
            alt={countryTitle}
            style={{ marginLeft: "0.8rem" }}
          />
        );
      },
      csvFormatter: (cell, row, rowIndex) => {
        return row.country === "NULL"
          ? "All selected countries of the course"
          : row.country;
      },
    },
    {
      dataIndex: "id",
      title: "Session ID".toUpperCase(),
      sorter: (a, b) => a.id - b.id,
      width: 10,
    },
    {
      dataIndex: "batch_name",
      title: "Session Name".toUpperCase(),
      sorter: (a, b) => a.batch_name.localeCompare(b.batch_name),
      width: 10,
      render: (cell, row) => {
        if (row.private) {
          return (
            <Typography style={{ color: userConfig.secondary }} title={cell}>
              {decodeHtmlEntities(cell)}
            </Typography>
          );
        }
        return (
          <Typography style={{ color: userConfig.primary }} title={cell}>
            {decodeHtmlEntities(cell)}
          </Typography>
        );
      },
    },
    {
      dataIndex: "start_time",
      title: "Session start time".toUpperCase(),
      sorter: (a, b) => a.start_time - b.start_time,
      sort: true,
      width: 10,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "end_time",
      title: "session end time".toUpperCase(),
      sorter: (a, b) => a.end_time - b.end_time,
      width: 10,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "tz_offset",
      title: "Time zone".toUpperCase(),
      sorter: (a, b) =>
        a?.tz_offset?.localeCompare &&
        a?.tz_offset?.localeCompare(b?.start_time),
      width: 10,
      render: (cell, row) => {
        return <>{TimeFormatter.getTimeZoneFromOffset(cell)}</>;
      },
    },
    {
      dataIndex: "batch_data",
      title: "Enrolled Users".toUpperCase(),
      width: 10,
      csvExport: false,
      align: "center",
      headerStyle: {
        width: "6rem",
      },
      render: (cell, row) => {
        return (
          <LineProgressBar
            key={row.batch_id}
            currentCount={row.counter}
            maxCount={row.size}
            minCount={row.min_size}
            isPrivate={row.private}
            isLocked={row.is_locked}
            clickHandler={(event) => {
              openSessionUserList(row.id);
            }}
          />
        );
      },
    },
    {
      dataIndex: "total_modules",
      title: "Manage Modules".toUpperCase(),
      sort: true,
      width: 10,
      csvExport: false,
      sorter: (a, b) => a.total_modules - b.total_modules,
      render: (cell, row) => {
        if (cell) {
          return (
            <Button
              type="button"
              style={{
                marginLeft: "0.9rem",
                paddingLeft: "0.8rem",
                borderColor: "black",
                borderWidth: "3px",
                borderRadius: "1px",
                fontFamily: "RakutenRoundRegular",
                width: "0.5rem",
              }}
              count={row.total_modules}
              onClick={(event) => {
                openModuleList(row.id);
              }}
            >
              <span
                style={{
                  position: "relative",
                  right: "0.1rem",
                  bottom: "0.1rem",
                }}
              >
                {row.total_modules}
              </span>
            </Button>
          );
        } else {
          return (
            <>
              <Button
                type="button"
                icon={
                  <PlusSquareOutlined
                    style={{
                      fontSize: "2rem",
                      color: "#393a3a",
                      marginLeft: "0.9rem",
                    }}
                  />
                }
                alt="add users button"
                onClick={(event) => {
                  // openSessionList(row.id);
                  addNewModule(row.id);
                }}
                className={"sessionList-numberButton"}
              />
            </>
          );
        }
      },
    },
    {
      dataIndex: "id",
      title: "Edit Session".toUpperCase(),
      sort: false,
      width: 10,
      headerAlign: "center",
      csvExport: false,
      headerStyle: {
        width: "4.5em",
      },
      align: "center",
      render: (cell, row) => {
        return (
          <Button
            type="button"
            icon={<EditOutlined />}
            alt="edit button"
            onClick={() =>
              history.push(
                `/Admin/AddEditSession?courseId=${courseId}&sessionId=${row.id}`
              )
            }
            className={"sessionList-iconButton"}
          />
        );
      },
    },
    {
      dataIndex: "id",
      title: "Add User".toUpperCase(),
      sort: false,
      width: 10,
      headerAlign: "center",
      headerStyle: {
        width: "4.5em",
      },
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        if (row.private) {
          return (
            <Button
              type="button"
              icon={<UserAddOutlined />}
              alt="add users button"
              onClick={(event) => {
                setRowSwapMove(row);
                openAddUser(row);
              }}
              className={"sessionList-iconButton"}
            />
          );
        }
        return null;
      },
    },
    {
      dataIndex: "id",
      title: "Swap / Move".toUpperCase(),
      sort: false,
      width: 10,
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <Button
            type="button"
            alt="add users button"
            onClick={(event) => {
              //Opens swap user modal
              setRowSwapMove(row);
              handleSwapMoveUser(row);
            }}
          >
            <img src={swapIcon} />
          </Button>
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action".toUpperCase(),
      sort: false,
      headerAlign: "center",
      width: 50,
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <Button
            type="button"
            alt="add users button"
            onClick={(event) => {
              openMailModal(row.id);
            }}
          >
            <img src={mailIcon} />
          </Button>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "updated_timestamp",
      order: "desc",
    },
  ];

  const completedSessionColumns = [
    {
      dataIndex: "private",
      title: <img src={privateIcon} />,
      csvExport: false,
      width: 50,
      render: (cell, row) => {
        if (row.private) {
          return (
            <Button type="button" alt="private course">
              <img src={privateIcon} />
            </Button>
          );
        }
        return null;
      },
      filter: customFilter(),
    },
    {
      dataIndex: "country",
      title: "Country".toUpperCase(),
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "5em",
      },
      render: (cell, row) => {
        let countryCode = row.country === "NULL" ? "NULL" : row.country;
        if (!row.private) {
          if (row.country.length > 0 && row.country.length < 2) {
            countryCode = row.country[0];
          }
        }
        let iconStyle = countryCode === "NULL" ? "globeIcon" : "countryIcon";
        let countrySrc = getCountryImage(countryCode);
        let countryTitle = getCountryNames(countryCode);
        return (
          <img
            src={`${process.env.PUBLIC_URL}/${countrySrc}`}
            title={countryTitle}
            className={iconStyle}
            alt={countryTitle}
          />
        );
      },
      csvFormatter: (cell, row, rowIndex) => {
        return row.country === "NULL"
          ? "All selected countries of the course"
          : row.country;
      },
    },
    {
      dataIndex: "id",
      title: "Session ID".toUpperCase(),
      sorter: (a, b) => a.id - b.id,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataIndex: "batch_name",
      title: "Session Name".toUpperCase(),
      sorter: (a, b) => a.batch_name.localeCompare(b.batch_name),
      headerStyle: {
        width: "15rem",
      },
      render: (cell, row) => {
        if (row.private) {
          return (
            <span style={{ color: "#F59600" }} title={cell}>
              {decodeHtmlEntities(cell)}
            </span>
          );
        }
        return (
          <span style={{ color: "#00A0F0" }} title={cell}>
            {decodeHtmlEntities(cell)}
          </span>
        );
      },
    },
    {
      dataIndex: "start_time",
      title: "Session Start Time".toUpperCase(),
      sorter: (a, b) => a.start_time - b.start_time,
      headerStyle: {
        width: "8rem",
      },
      sort: true,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "end_time",
      title: "session end time".toUpperCase(),
      sorter: (a, b) => a.end_time - b.end_time,
      headerStyle: {
        width: "8rem",
      },
      sort: true,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "tz_offset",
      title: "Time zone".toUpperCase(),
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "6rem",
      },
      sorter: (a, b) => a.tz_offset.localeCompare(b.tz_offset),
      render: (cell, row) => {
        return <>{TimeFormatter.getTimeZoneFromOffset(cell)}</>;
      },
    },
    {
      dataIndex: "batch_data",
      title: "Enrolled Users".toUpperCase(),
      align: "center",
      sort: false,
      csvExport: false,
      headerAlign: "center",
      headerStyle: {
        width: "8rem",
      },
      render: (cell, row) => {
        return (
          <LineProgressBar
            style={{ justifyContent: "center" }}
            key={row.batch_id}
            currentCount={row.counter}
            maxCount={row.size}
            minCount={row.min_size}
            isPrivate={row.private}
            isLocked={row.is_locked}
            clickHandler={(event) => {
              openSessionUserList(row.id);
            }}
          />
        );
      },
    },
    {
      dataIndex: "total_modules",
      title: "Manage Modules".toUpperCase(),
      sorter: (a, b) => a.total_modules - b.total_modules,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      render: (cell, row) => {
        if (cell) {
          return (
            <Button
              type="button"
              style={{
                marginLeft: "0.9rem",
                paddingLeft: "0.8rem",
                borderColor: "black",
                borderWidth: "3px",
                borderRadius: "1px",
                fontFamily: "RakutenRoundRegular",
                width: "0.5rem",
              }}
              count={row.total_modules}
              onClick={(event) => {
                openModuleList(row.id);
              }}
            >
              <span
                style={{
                  position: "relative",
                  right: "0.1rem",
                  bottom: "0.1rem",
                }}
              >
                {row.total_modules}
              </span>
            </Button>
          );
        } else {
          return (
            <>
              <Button
                type="button"
                icon={
                  <PlusSquareOutlined
                    style={{
                      fontSize: "2rem",
                      color: "#393a3a",
                      marginLeft: "0.4rem",
                    }}
                  />
                }
                alt="add users button"
                onClick={(event) => {
                  // openSessionList(row.id);
                  addNewModule(row.id);
                }}
                className={"sessionList-numberButton"}
              />
            </>
          );
        }
      },
    },
    {
      dataIndex: "id",
      title: "Edit Session".toUpperCase(),
      sort: false,
      headerAlign: "center",
      csvExport: false,
      headerStyle: {
        width: "5.5rem",
      },
      align: "center",
      render: (cell, row) => {
        return (
          <Button
            type="button"
            icon={<EditOutlined />}
            alt="edit button"
            onClick={() =>
              history.push(
                `/Admin/AddEditSession?courseId=${courseId}&sessionId=${row.id}`
              )
            }
            className={"sessionList-iconButton"}
          />
        );
      },
    },
    {
      dataIndex: "id",
      title: "ADD ENROLLED USERS".toUpperCase(),
      isDummyField: true,
      sort: false,
      headerAlign: "center",
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <Button
            type="button"
            icon={<UserAddOutlined />}
            alt="add users button"
            onClick={(event) => {
              openAttendanceModel(row);
            }}
            className={"sessionList-iconButton"}
          />
        );
      },
    },
    {
      dataIndex: "id",
      title: "Action".toUpperCase(),
      sort: false,
      headerAlign: "center",
      width: 50,
      align: "center",
      csvExport: false,
      render: (cell, row) => {
        return (
          <Button
            type="button"
            alt="add users button"
            onClick={(event) => {
              openMailModal(row.id);
            }}
          >
            <img src={mailIcon} />
          </Button>
        );
      },
    },
  ];

  const onPageFilterChange = (value) => {
    switch (value) {
      case "1":
        // load archived course data
        getAllActiveSessions();
        setCsvFileName(courseData?.identity_id + "_session_list");
        break;
      case "2":
        // load active course data
        getAllCompletedSessions(courseData?.identity_id);
        setCsvFileName(courseData?.identity_id + "_active_session_list");

        break;
      default:
        getAllActiveSessions();
        setCsvFileName(courseData?.identity_id + "_session_list");

        break;
    }
  };

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(e) => onPageFilterChange(e)}
        className={"sessionList-tabs"}
      >
        <TabPane tab="Active" key="1"></TabPane>
        <TabPane tab="Completed" key="2"></TabPane>
      </Tabs>
    );
  };

  //code for Swap Move Modal starts here
  const [rowSwapMove, setRowSwapMove] = useState([]);
  const [showConfirmationSwapMoveModal, setShowConfirmationSwapMoveModal] =
    useState(false);
  const [showSwapUsersModal, setShowSwapUsersModal] = useState(false);
  const [openSwapMoveHelper, setOpenSwapMoveHelper] = useState(false);

  const handleCloseConfirmationModalSwap = () => {
    setShowConfirmationSwapMoveModal(false);
    setOpenSwapMoveHelper(false);
  };

  const handleCloseSwapMoveUser = () => {
    setShowSwapUsersModal(false);
    setOpenSwapMoveHelper(false);
  };

  const handleSwapMoveUser = (row) => {
    setOpenSwapMoveHelper(true);
  };
  //code for Swap Move Modal ends here

  //code for ImportUsers starts here
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const page = "session";

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAddUserModal(false);
  }

  const openAttendanceModel = (current) => {
    setCurrentSession(current);
    setShowAttendaceModel(true);
  };

  const openAddUser = (row) => {
    setShowAddUserModal(true);
  };

  //code for ImportUsers ends here

  const openModuleList = (sessionId) => {
    history.push(`/Admin/ModuleList/${sessionId}`);
  };
  const addNewModule = (sessionId) => {
    history.push(`/Admin/AddEditModule/${sessionId}`);
  };

  // Email Modal Start
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailModalForSession, setEmailModalForSession] = useState(null);
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [subject, setSubject] = useState("");
  const [note, setNote] = useState("No modules available");
  const [content, setContent] = useState("");
  const [calendarInviteAdded, setCalendarInviteAdded] = useState(true);

  const handleEmailModalClose = () => {
    setShowEmailModal(false);
  };

  const handleAttendanceModalClose = () => {
    setShowAttendaceModel(false);
  };

  const onSendEmailHandler = () => {
    const obj = {
      to,
      mail_body: content,
      subject,
      cc,
      invite_needed: calendarInviteAdded,
      batch_id: emailModalForSession,
    };
    axios
      .post("batch/send_batch_mail", obj)
      .then((response) => {
        setShowEmailModal(false);
        setAlert("Email sent!");
        setShowAlert(true);
        setAlertType("success");
      })
      .catch(function (error) {
        setShowEmailModal(false);
        setAlert("Failed to send Email!");
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const openMailModal = (sessionId) => {
    axios
      .get("batch/mailing_details", { params: { batch_id: sessionId } })
      .then((response) => {
        if (response.data.data.to) {
          setTo(response.data.data.to.join());
        }

        if (response.data.data.batch && response.data.data.course) {
          // Adding default subject line
          const defaultSubject = `${response.data.data.batch.batch_name} - ${
            response.data.data.course.course_name
          }, ${TimeFormatter.FormatDate(
            response.data.data.batch.start_time,
            response.data.data.batch.tz_offset
          )} - ${TimeFormatter.FormatDate(
            response.data.data.batch.end_time,
            response.data.data.batch.tz_offset
          )}`;
          setSubject(defaultSubject);
        }

        let modulesNote = ``;
        if (
          response.data.data.session &&
          response.data.data.session.length > 0
        ) {
          response.data.data.session.forEach((item, index) => {
            const date = TimeFormatter.FormatDate(
              item.start_date,
              response.data.data.batch.tz_offset
            );
            const startTime = TimeFormatter.FormatTime(
              item.start_date,
              response.data.data.batch.tz_offset
            );
            const endTime = TimeFormatter.FormatTime(
              item.end_date,
              response.data.data.batch.tz_offset
            );
            modulesNote += ` Module ${
              index + 1
            }: ${date} (${startTime} - ${endTime})<br/>`;
          });
          setNote(modulesNote);
        } else {
          setNote("No modules available");
        }

        const body = `
	<p style="text-align:left;">
    Hello,<br/><br/>

    Thank you for your confirmed participation in <b>${
      response.data.data.course.course_name
    }</b> scheduled to
    run from ${TimeFormatter.FormatDate(
      response.data.data.batch.start_time
    )} to ${TimeFormatter.FormatDate(
          response.data.data.batch.end_time
        )}<br/><br/>

    Before the Training Module, please ensure you prepare the following:<br/>
    • Complete the mandatory Pre-Training Survey. A separate email follows shortly.<br/>
    • Do ensure you meet <b>the below Course Pre-Requisite</b>
        <br/><br/>
            ${response.data.data.course.prerequisites}
        <br/>

    • System Requirements [PC SETUP to be entered by Admin if applicable]<br/>
    *If you don't have a laptop PC, you can request to borrow one. Please apply <a href="https://apliko.rakuten-it.com/aqua/fm000075/view?bpmview=all&exa=default">HERE </a><br/><br/>

    The <b>Agenda</b> for the Course is as follows:<br/>

    ${modulesNote}<br/>

    Please account for your time in WINES using code : 54212 [Tech] Training <br/><br/>

    If you are unable to attend this course, please visit 
    <a href="${response.data.data.course_url}" target="_blank" > ${
          response.data.data.course.course_name
        } </a>
    to unenroll from this course in the next 48 hours. <br/> Your place will be offered to a colleague on the waiting list. <br/><br/>

    Should you have any questions or concerns, please contact your local HR(Learning & Development) Team. Tech Community Employees in Japan can write to <a href="mailto: tech-learning@mail.rakuten.com">tech-learning@mail.rakuten.com</a> <br/><br/>

    We hope you find the training beneficial to your personal and career growth at Rakuten.<br/><br/>

    Regards,<br/>
    ${response.data.data.course.course_provider}</p>`;

        setContent(body);
        setCc("");
        setCalendarInviteAdded(true);
      })
      .catch(function (error) {
        setShowEmailModal(false);
        setAlert("Failed to load mailing details!");
        setShowAlert(true);
        setAlertType("error");
      });

    setShowEmailModal(true);
    setEmailModalForSession(sessionId);
  };
  // Email Modal End

  const [countryList] = useState(countryData.data);
  const [courseId] = useState(props.match.params.course_id);

  function getCountryImage(countryCode = "NULL") {
    let countryObj = countryList.countries[countryCode];
    return countryObj ? countryObj.imageURL : "";
  }

  function getCountryNames(countryCode = "NULL") {
    let countryObj = countryList.countries[countryCode];
    return countryObj ? countryObj.name : "";
  }

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line
  }, []);

  const getCourseData = () => {
    axios
      .get(`${config.endPoint.instructorLedNew}/course/${courseId}`)
      .then((response) => {
        setCourseData(response.data.data);
        setApprovalRequired(response.data.data.approval_required);
        settableColumns(activeSessionColumns);
        getAllActiveSessions(response.data.data?.identity_id);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const [tableColumns, settableColumns] = useState(activeSessionColumns);

  useEffect(() => {
    settableColumns(activeSessionColumns);
  }, [userDateFormat]);

  useEffect(() => {
    getAllActiveSessions();
    // eslint-disable-next-line
  }, []);

  const getAllActiveSessions = (identityId) => {
    settableColumns(activeSessionColumns);
    setShowLoader(true);

    setCsvFileName(identityId + "_session_list");
    setActive(true);
    axios
      .get(`${config.endPoint.instructorLedNew}/batch/`, { params: { course_id: courseId, active: 1 } })
      .then((response) => {
        setListData(response.data.data);
        setTableData(response.data.data);
        setShowLoader(false);
        setPageCount(response.data.data.length);
        setReachedMaxSessionLimit(response?.data?.reached_max_session_limit);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const getAllCompletedSessions = (identityId) => {
    settableColumns(completedSessionColumns);
    setActive(false);
    setCsvFileName(identityId + "_completed_courses");
    setShowLoader(true);
    axios
      .get(`${config.endPoint.instructorLedNew}/batch/`, { params: { course_id: courseId, completed: 1 } })
      .then((response) => {
        setListData(response.data.data);
        setShowLoader(false);
        setTableData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const onAddNewCourse = () => {
    if (active) {
      if (reachedMaxSessionLimit) {
        setAlert(
          "Active Session limit (12) reached !! Create a session after an existing session is completed"
        );
        setShowAlert(true);
        setAlertType("warning");
      } else {
        history.push(`/Admin/AddEditSession?courseId=${courseId}`);
      }
    } else history.push(`/Admin/AddPastSession/${courseId}`);
  };

  const openSessionUserList = (sessionId) => {
    history.push(`/Admin/enrolled/${sessionId}`);
  };

  const openWaitlist = () => {
    history.push(`/Admin/waitingList/${courseId}`);
  };

  // const rowStyle = active ? activeRowStyle : completedRowStyle;

  const CSVColumns = tableColumns.map((header, index) => {
    let label = header.title;
    if (header.dataIndex === "private") {
      label = header.dataIndex.toUpperCase();
    }
    return {
      key: header.dataIndex,
      label,
    };
  });

  const PublicPrivateLegend = (
    <>
      <Row className={"courseList-legendContainer"}>
        <Col lg={12}>
          <Row>
            <Col lg={9}>
              <div className={"courseList-publicIcon"}></div>
            </Col>
            <Col lg={6} style={{ fontFamily: "RakutenRoundRegular" }}>
              Public
            </Col>
          </Row>
        </Col>
        <Col lg={11}>
          <Row>
            <Col lg={10}>
              <PrivateCourseIcon color2={userConfig.secondary} />
            </Col>
            <Col lg={6} style={{ fontFamily: "RakutenRoundRegular" }}>
              Private
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      start_time: dayjs.unix(data.start_time).format("DD/MM/YYYY HH:mm:ss"),
      end_time: dayjs.unix(data.end_time).format("DD/MM/YYYY HH:mm:ss"),
      last_invite_sent: dayjs
        .unix(data.last_invite_sent)
        .format("DD/MM/YYYY HH:mm:ss"),
    }));
  };

  const tableHeader = (
    <div className={"sessionList_header"}>
      <Row style={{ alignItems: "center" }}>
        <Col lg={4}>
          <Typography className="notification-pageTitle">
            Sessions
            <span
              style={{ fontSize: "0.9rem", fontFamily: "RakutenRoundRegular" }}
            >
              ({showLoader ? <Spin size="small" /> : pageCount})
            </span>
          </Typography>
        </Col>
        <Col lg={3}>{PublicPrivateLegend}</Col>
        <Col lg={2}>
          <span className={"waitlistLabel"}>Waitlist Users</span>
        </Col>
        <Col>
          <RoundProgressBar
            isWaitlist={true}
            maxCount={courseData && courseData.waitlisted_users}
            otherStyle={"waitlistCircle"}
            clickHandler={openWaitlist}
          />
        </Col>
        <Col lg={5}>{toggleFilter()}</Col>
        <Col lg={2}>
          <CSVLink
            filename={csvFileName}
            extension=".csv"
            wrapColumnChar=""
            headers={CSVColumns}
            data={getDataToDownload(listData)}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "1.5rem",
              }}
            />
          </CSVLink>
        </Col>
        <Col lg={3}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              width: " 10rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
        <Col lg={3}>
          <Button
            type="button"
            icon={<PlusCircleOutlined style={{ fontSize: "1.2rem" }} />}
            className={
              (active && "sessionList_addNewCourse") || "primaryButtonRed"
            }
            onClick={onAddNewCourse}
          >
            {active ? "Add Session" : "PAST Session"}
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "3rem" }}>
        <Typography className={"sessionAddEdit-label"}>
          <span
            style={{
              fontFamily: "RakutenRoundSemiBold",
              fontSize: "1rem",
              paddingBottom: "1rem",
            }}
          >
            {courseData && courseData.identity_id} -{" "}
          </span>
          {courseData && decodeHtmlEntities(courseData.course_name)}
        </Typography>
      </Row>
    </div>
  );

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setShowAlert(true);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const rowClassName = (record) =>
    record.total_modules === 0 ? "table-row-dark" : 0;

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
            onCloseAlert={() => setAlert("")}
          />
        )}
        <Breadcrumb items={crumbs} className={"sessionList-crumbs"} />
        {tableHeader}
        <div className={"sessionList-table"}>
          <Table
            scroll={{
              x: 1300,
            }}
            keyField="id"
            loading={showLoader}
            dataSource={listData}
            columns={tableColumns}
            defaultSorted={defaultSorted}
            pagination={{
              position: [bottom],
              showQuickJumper: true,
            }}
            rowClassName={rowClassName}
            exportCSV={{
              fileName:
                courseData && courseData.identity_id
                  ? `${courseData.identity_id}_session_list.csv`
                  : `session_list_export.csv`,
            }}
          />
        </div>
      </div>

      {(showAddUserModal || showErrorModal) && (
        <ImportUserHelper
          showAddUserModal={showAddUserModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          handleCloseErrorModal={handleCloseErrorModal}
          page={page}
          courseId={courseId}
          sessionId={rowSwapMove.id}
          sessionName={rowSwapMove.batch_name}
          isSessionPrivate={rowSwapMove.private}
          sessionSize={rowSwapMove.size}
          enrolledUsers={rowSwapMove.counter}
          setAlert={setAlert}
          setAlertType={setAlertType}
          updatePage={getCourseData}
          openErrorCloseAddUser={openErrorCloseAddUser}
        />
      )}

      {showEmailModal && (
        <SessionListEmailModal
          to={to}
          cc={cc}
          subject={subject}
          note={note}
          content={content}
          calendarInviteAdded={calendarInviteAdded}
          showEmailModal={showEmailModal}
          sendEmailHandler={onSendEmailHandler}
          modalCloseHandler={handleEmailModalClose}
          ccHandler={setCc}
          calendarInviteAddedHandler={setCalendarInviteAdded}
          subjectHandler={setSubject}
          contentHandler={setContent}
          showCalendar={true}
          setTo={setTo}
        />
      )}

      {openSwapMoveHelper && (
        <SwapUsersHelper
          setOpenSwapMoveHelper={setOpenSwapMoveHelper}
          sessionPrivate={rowSwapMove.private}
          setAlert={setAlert}
          setAlertType={setAlertType}
          setShowAlert={setShowAlert}
          handleCloseConfirmationModalSwap={handleCloseConfirmationModalSwap}
          handleCloseSwapMoveUser={handleCloseSwapMoveUser}
          setShowSwapUsersModal={setShowSwapUsersModal}
          setshowConfirmationSwapMoveModal={setShowConfirmationSwapMoveModal}
          updatePage={getCourseData}
          showSwapUsersModal={showSwapUsersModal}
          showConfirmationSwapMoveModal={showConfirmationSwapMoveModal}
          courseId={rowSwapMove.course_id}
          batchId={rowSwapMove.id}
          batchSize={rowSwapMove.size}
          batchName={rowSwapMove.batch_name}
          approvalRequired={approvalRequired}
        />
      )}
      {showAttendaceModel && (
        <AttendanceModel
          setAlert={setAlert}
          setAlertType={setAlertType}
          courseId={courseId}
          sessionId={currentSession.id}
          sessionName={currentSession.batch_name}
          isPrivate={currentSession.private}
          enrolledUsers={currentSession.counter}
          sessionSize={currentSession.size}
          showAttendaceModel={showAttendaceModel}
          modalCloseHandler={handleAttendanceModalClose}
          callbackCompletedSession={() => {
            getAllCompletedSessions();
          }}
        />
      )}
    </>
  );
}

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import style from "./elearningCourseAddEdit.module.css";
import { useHistory, useLocation } from "react-router-dom";
import edit from "../../../../assets/icons/edit.svg";
import uploadImage from "../../../../assets/icons/upload_image.svg";
import uploadZip from "../../../../assets/icons/uploadZipIcon.svg";
import boxLogo from "../../../../assets/icons/box_logo.svg";
import archiveIcon from "../../../../assets/icons/courseArchive.svg";
import activeIcon from "../../../../assets/icons/courseActive.svg";
import config from "../../../../config";
import { modifyImageTags } from "../../../../utils/textEditor";
import {
  BreadCrumb,
  TopContainer,
  AdminPageTitle,
  CustomSelect,
  Label,
  TextEditor,
  CustomToggle,
  CustomMultiSelect,
  Validation,
  Alert,
  PrimaryButton,
  SecondaryButton,
  SubcategoryTags,
  ScrollToTop,
  CustomCreatableSelect,
  AdminConfirmationModal,
} from "../../../../component-library";
import ImageSelectionModal from "../../../../component-library/imageSelectionModal/AntdImageSelectionModal.js";
import {
  formatCountryList,
  validateCourseDetails,
} from "./elearningCourseAddEdit.lib";
import {
  Button,
  Row,
  Col,
  Image,
  Select,
  Input,
  Layout,
  InputNumber,
  Form,
  Typography,
} from "antd";
import AdminPrimaryButton from "../../../../component-library/adminPrimaryButton/AdminPrimaryButton";
import { serializeDataResponse } from "../../../../serializer/dataSerializer";
import { trim } from "lodash";
import { DurationRangePicker } from "../../courseAddEdit/DurationRangePicker";
import {
  decimalToTime,
  timeToDecimal,
} from "../assignedUsers/updateExpectedDateModal/addEditDateUtils";
import { RiInboxUnarchiveLine, RiInboxUnarchiveFill } from "react-icons/ri";

export default function ElearningCourseAddEdit() {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(history.location.search);
  const courseId = query.get("courseId");
  const textEditorRef = useRef([]);

  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    {
      title: "E-learning Courses",
      to: "/Admin/e-learning/courses",
    },
    {
      title: courseId ? "Edit Course" : "Add Course",
    },
  ];

  const [courseInitialEditData, setCourseInitialEditData] = useState();

  const [country, setCountry] = useState([]);
  const [selectedCounties, setSelectedCountries] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [courseType, setCourseType] = useState({
    label: "SCORM",
    value: "5",
  });
  const [courseTitle, setCourseTitle] = useState("");
  const [duration, setDuration] = useState(0);
  const [archived, setArchived] = useState(false);
  const [description, setDescription] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [objectives, setObjectives] = useState("");
  const [targetedAudience, setTargetedAudience] = useState("");
  const [preRequisites, setPreRequisites] = useState("");
  const [topics, setTopics] = useState("");
  const [trainingMaterial, setTrainingMaterial] = useState("");
  const [adminComment, setAdminComment] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [language, setLanguage] = useState(null);
  const [selectedlang, setSelectedLang] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [courseTypeList] = useState([{ label: "SCORM", value: "5" }]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [errorMessage, setErrorMessage] = useState({});
  const [unformattedCountries, setUnformattedList] = useState([]);

  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [courseIdentityId, setCourseIdentityId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [initialTags, setInitialTags] = useState([]);

  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [countryOriginCheck, setCountryOriginCheck] = useState(true);
  const [zipFileName, setZipFileName] = useState("");

  const [courseProviderList, setCourseProviderList] = useState([]);
  const [subCourseProviderList, setSubCourseProviderList] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState(null);
  const [createdCourseProvider, setCreatedCourseProvider] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedSubCourseProvider, setSelectedSubCourseProvider] =
    useState(null);

  const [parsedSearchTag, setParsedSearchTag] = useState([]);

  useEffect(() => {
    initializeCountryAndLanguageList();
    initializeCategoryList();
    initializeCourseProviderList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCourseProvider) {
      const subCourseProviderEndpoint = `${config.endPoint.commonService}/subCourseProvider`;
      axios
        .get(
          `${subCourseProviderEndpoint}?courseProviderId=${selectedCourseProvider.value}`
        )
        .then((response) => {
          const list = [];
          response.data.data.map((item) =>
            list.push({ label: item.name, value: item.id })
          );
          setSubCourseProviderList(list);
          if (
            selectedSubCourseProvider &&
            !list.includes(selectedSubCourseProvider)
          ) {
            setSelectedSubCourseProvider(null);
          }
        })
        .catch((error) => {
          setAlertType("danger");
          setAlert(
            error?.response?.data?.message ||
              "Something went wrong, Please try again."
          );
        });
    }
    // eslint-disable-next-line
  }, [selectedCourseProvider]);

  useEffect(() => {
    if (category && category.value) {
      const endPoint = `${config.endPoint.commonService}/category/${category.value}`;
      axios.get(endPoint).then((response) => {
        const {
          data: { data },
        } = response;
        const { subCategories } = data;
        const formattedSubCategoryList = formatSubCategoryList(subCategories);
        setSubCategoryList(formattedSubCategoryList);
      });
    }
    // eslint-disable-next-line
  }, [category.value]);

  useEffect(() => {
    let imageUrl = `${config.endPoint.commonService}/image/imageName/course/${imageName}`;

    if (imageName) {
      axios
        .get(imageUrl)
        .then((response) => {
          const { data } = response || {};
          if (data.data && data.data.imageLink)
            setImageUrl(data.data.imageLink);
        })
        .catch((error) => {
          setAlert(
            error?.response?.message ||
              error?.response?.data?.message ||
              "Something went wrong."
          );
          setAlertType("danger");
        });
    }
  }, [imageName]);

  useEffect(() => {
    if (countryList.length && languageList.length && categoryList.length) {
      if (courseId && !courseInitialEditData) {
        const endPoint = `${config.endPoint.elearning}/course/${courseId}`;
        axios
          .get(endPoint)
          .then((response) => {
            if (!response?.data?.success) {
              setAlertType("danger");

              setAlert(response.data.message);
              const timer = setTimeout(() => {
                history.push("/Admin/Courses");
              }, 10000);

              return () => clearTimeout(timer);
            }
            const {
              data: { data },
            } = response;
            const { course } = data;
            setInitialEditState(course);
          })
          .catch((error) => {
            setAlert(
              error?.response?.message ||
                error?.response?.data?.message ||
                "Something went wrong."
            );
            setAlertType("danger");
          });
      } else if (location?.state?.courseDetails) {
        setInitialEditState(location?.state?.courseDetails);
      }
    }
    // eslint-disable-next-line
  }, [courseId, countryList, languageList, categoryList, subCategoryList]);

  const initializeCountryAndLanguageList = () => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    axios.get(dataEndpoint).then((response) => {
      const { countries, languages } = serializeDataResponse(response);

      setUnformattedList(countries);

      const formattedCountryList = formatCountryList(countries);
      const formattedLanguageList = formateLangOptions(languages);

      setCountryList(formattedCountryList);
      setLanguageList(formattedLanguageList);
    });
  };

  function formatCategoryList(data) {
    return data.map((current) => {
      return { label: current.name, value: current.id };
    });
  }

  function formatSubCategoryList(subCategories) {
    return subCategories.map((current) => {
      return { label: current.name, value: current.id };
    });
  }

  const initializeCategoryList = () => {
    const catergoryEndpoint = `${config.endPoint.commonService}/category?excludeSubcategories=true`;
    axios.get(catergoryEndpoint).then((response) => {
      const formattedCategoryList = formatCategoryList(response.data.data);
      setCategoryList(formattedCategoryList);
    });
  };

  const initializeCourseProviderList = () => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;
    axios
      .get(dataEndpoint)
      .then((response) => {
        const list = [];

        serializeDataResponse(response)?.courseProviders.map((item) =>
          list.push({ label: item.value, value: item.id })
        );
        setCourseProviderList(list);
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const setInitialEditState = async (data) => {
    const result = data;
    const {
      countries = [],
      identityId,
      duration = 0,
      courseProvider = null,
      subCourseProvider = null,
      name: courseName = "",
      description = "",
      prerequisites = "",
      topics = "",
      adminComments = "",
      objectives = "",
      targetedAudience = "",
      courseMaterials: trainingMaterial = "",
      category = [],
      categoryId = "",
      subCategory: subcategory = [],
      subCategoryId = "",
      languages = [],
      courseStatus,
      search_tags = [],
      searchTags = [],
      image = null,
      recommendations = "",
      countryOriginCheck = true,
      zipFileName = "",
    } = result || {};
    const modifiedData = await modifyImageTags({
      description: description,
      targetedAudience: targetedAudience,
      objectives: objectives,
      prerequisites: prerequisites,
      recommendations: recommendations,
      topics: topics,
      adminComments: adminComments,
    });
    setCourseInitialEditData({ ...data, ...modifiedData });

    const countryArray = (result && result.countries) || [];
    if (unformattedCountries && unformattedCountries.length) {
      const formattedSelectedCountries = countryArray
        .map((current) => {
          return unformattedCountries.find((curr) => curr.code === current);
        })
        .map((curr) => {
          return { id: curr.code, value: curr.country };
        });
      setSelectedCountries(formattedSelectedCountries);
      setCountry(countries);
    }
    setCourseIdentityId(identityId);
    // setDuration(parseFloat(duration).toFixed(1));
    setDuration(duration);
    setSelectedCourseProvider(
      courseProvider
        ? { label: courseProvider.CourseProviderName, value: courseProvider.id }
        : null
    );
    setSelectedSubCourseProvider(
      subCourseProvider
        ? {
            label: subCourseProvider.subCourseProviderName,
            value: subCourseProvider.id,
          }
        : null
    );
    setCourseTitle(courseName);
    setDescription(modifiedData?.description);
    setPreRequisites(modifiedData?.prerequisites);
    setTopics(modifiedData?.topics);
    setAdminComment(modifiedData?.adminComments);
    setRecommendations(modifiedData?.recommendations);
    setObjectives(modifiedData?.objectives);
    setTargetedAudience(modifiedData?.targetedAudience);
    setTrainingMaterial(trainingMaterial);
    setCountryOriginCheck(countryOriginCheck);
    setZipFileName(zipFileName);

    const formattedSearchTags = search_tags.map((tag) => ({
      value: tag,
      label: tag,
    }));

    setInitialTags(formattedSearchTags);

    category &&
      setCategory({
        label:
          category.categoryName ||
          categoryList.find((cat) => cat.value === categoryId)?.label,
        value: category.id || categoryId,
      });
    const archiveValue = courseStatus === "archived" ? true : false;
    setArchived(archiveValue);

    if (image) setImageName(image);

    subcategory &&
      setSubCategory({
        label:
          subcategory.subCategoryName ||
          subCategoryList.find((sCat) => sCat.value === subCategoryId)?.label,
        value: subcategory.id || subCategoryId,
      });
    const lang =
      languageList &&
      languageList[0]?.options?.length &&
      languageList[0].options.filter((current) =>
        languages.includes(current.value)
      );
    setLanguage(() => lang || []);
    const selectedLang = lang?.map((current) => current.id);
    setSelectedLang(selectedLang);
  };

  const submitHandler = async (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();
    const obj = {
      courseStatus: (archived && "archived") || "active",
      categoryId: category.value,
      subCategoryId: subCategory.value,
      countries: country.filter((current) => current !== "*"),
      languages: language ? language.map((lang) => lang.value) : "",
      courseType: courseType.value,
      name: courseTitle,
      courseProviderId: selectedCourseProvider?.value,
      subCourseProviderId: selectedSubCourseProvider?.value,
      duration: duration,
      description,
      objectives,
      targetedAudience: targetedAudience,
      prerequisites: preRequisites,
      topics,
      courseMaterials: trainingMaterial,
      adminComments: adminComment,
      searchTags: initialTags.map((current) => current.value),
      recommendations,
      countryOriginCheck: countryOriginCheck,
      zipFileName,
    };

    if (imageName) {
      obj.image = imageName;
    }

    if (courseId) {
      obj.id = +courseId;

      const errors = validateCourseDetails(obj);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      const endPoint = `${config.endPoint.elearning}/course`;
      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );
        axios
          .patch(endPoint, obj)
          .then((response) => {
            history.push({
              pathname: "/Admin/e-learning/courses",
              state: {
                alertType: "success",
                alertMessage:
                  response?.data?.message ||
                  "Course has been updated successfully",
              },
            });
          })
          .catch((error) => {
            setAlert(
              error?.response?.message ||
                error?.response?.data?.message ||
                "Something went wrong."
            );

            setAlertType("danger");
          });
      }
    } else {
      obj.imageUrl = imageName;
      const errors = validateCourseDetails(obj);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );
        const courseId = Math.random().toString(36).slice(2);
        history.push({
          pathname: `/Admin/e-learning/CourseFileUpload/${courseId}`,
          state: {
            courseDetails: obj,
            editMode: false,
          },
        });
      }
    }
  };

  const handleDuration = (duration) => {
    setDuration(timeToDecimal(duration));
    if (!duration || duration == 0) {
      const error = { duration: `Please select the duration` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { duration: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCountry = (countries) => {
    const countryArray = countries.map((current) => current.id);
    setCountry(countryArray);
    if (!countryArray || countryArray.length === 0) {
      const error = { country: `Please select at least one Country.` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const formateLangOptions = (langs) => {
    if (!langs || langs.length === 0) return [];

    const options = langs.map((lang) => {
      const id = lang.id;
      const value = lang.title;
      return {
        groupId: "Select All",
        id: id,
        value: value,
      };
    });
    return [
      {
        groupId: "Select All",
        options: options,
        title: "Select All",
      },
    ];
  };
  const handleLang = (langs) => {
    const langArray = langs.map((current) => current.id);
    const lang = langs
      .filter((current) => current.id !== "*")
      .map((current) => ({ id: current.id, value: current.value }));
    setSelectedLang(langArray);
    setLanguage(lang);
    if (!langArray || langArray.length === 0) {
      const error = { language: `Please select at least one Language.` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { language: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCourseTitle = (courseTitle) => {
    if (courseTitle.length === 1) {
      courseTitle = courseTitle.trim();
    }

    setCourseTitle(courseTitle);

    if (!courseTitle) {
      const error = { courseTitle: `Please enter Course Title` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseTitle: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategory = (category) => {
    setCategory(category);
    setSubCategory([]);
    if (!category) {
      const error = { category: `Please select the Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { category: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleCourseType = (courseType) => {
    setCourseType(courseType);
    if (!courseType) {
      const error = { courseType: `Please select the Course Type` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseType: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleCourseProvider = (courseProvider) => {
    setSelectedCourseProvider(courseProvider);
    if (!courseProvider) {
      const error = {
        courseProvider: `Please enter the Course Provider`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseProvider: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleSubCourseProvider = (courseProvider) => {
    setSelectedSubCourseProvider(courseProvider);
    if (!courseProvider) {
      const error = {
        subCourseProvider: `Please enter the Sub Course Provider`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subCourseProvider: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleCreateCourseProvider = (courseProviderName) => {
    setCreatedCourseProvider(courseProviderName);
    setShowConfirmationModal(true);
  };
  const handleSubCategory = (subCategory) => {
    setSubCategory(subCategory);
    if (!subCategory) {
      const error = { subCategory: `Please select the Sub Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subCategory: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleDescription = (description) => {
    setDescription(description);
    if (!description) {
      const error = { description: `Please enter the Course Description` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { description: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleObjectives = (objective) => {
    setObjectives(objective);
    if (!objective) {
      const error = { objective: `Please enter the Course Objectives` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { objective: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleTargetedAudience = (targetedAudience) => {
    setTargetedAudience(targetedAudience);
    if (!targetedAudience) {
      const error = {
        targetedAudience: `Please enter the Course targetedAudience`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { targetedAudience: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handlePreRequisites = (preRequisites) => {
    setPreRequisites(preRequisites);
    if (!preRequisites) {
      const error = {
        preRequisites: `Please enter the Course preRequisites`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { preRequisites: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleTopics = (topics) => {
    setTopics(topics);
    if (!topics) {
      const error = {
        topics: `Please enter the Course topics`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { topics: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const handleRecommendation = (recommendations) => {
    setRecommendations(recommendations);
    if (!recommendations) {
      const error = {
        recommendations: `Please enter the Recommended Course`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { recommendations: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const resetForm = () => {
    setInitialEditState(courseInitialEditData);
  };

  const createSubCourseProvider = () => {
    axios
      .post(`${config.endPoint.commonService}/subCourseProvider`, {
        name: createdCourseProvider,
        courseProviderId: selectedCourseProvider.value,
      })
      .then((response) => {
        setSubCourseProviderList([
          ...subCourseProviderList,
          { label: createdCourseProvider, value: response.data.data.id },
        ]);
        setSelectedSubCourseProvider({
          label: createdCourseProvider,
          value: response.data.data.id,
        });
      })
      .catch((error) => {
        setAlert(
          error?.response?.message ||
            error?.response?.data?.message ||
            "Something went wrong."
        );
        setAlertType("danger");
      })
      .finally(() => {
        setCreatedCourseProvider("");
        setShowConfirmationModal(false);
      });
  };

  const modalContent = (
    <>
      <Row>
        <Col>
          <p>
            Are you sure you want to create sub course provider{" "}
            <b>{createdCourseProvider}</b> under course provider{" "}
            <b>{selectedCourseProvider?.label}</b>?
          </p>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <div className="AntdCourseAddEdit-container">
        <ScrollToTop />
        {(alert && (
          <Alert
            message={alert}
            type={alertType}
            onCloseAlert={() => setAlert("")}
          />
        )) ||
          ""}
        <BreadCrumb crumbs={crumbs} />
        <Form onSubmit={submitHandler}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AdminPageTitle
              pageTitle={
                (courseId && `Edit Course - ${courseIdentityId}`) ||
                `Create Course`
              }
            />
            <Row>
              {(courseId && (
                <CustomToggle
                  isImage={false}
                  toggleTitle={["Course Active", "Course Archived"]}
                  value={!archived}
                  icons={[RiInboxUnarchiveLine, RiInboxUnarchiveFill]}
                  onChange={(value) => {
                    setArchived((value) => !value);
                  }}
                />
              )) ||
                ""}
            </Row>
          </div>

          <Row gutter={[20]} fluid="true" style={{ margin: 0 }}>
            <Col lg={8} bsPrefix={`col ${style.topColumn}`}>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Country" required />
                <CustomMultiSelect
                  options={countryList}
                  handleCustomChange={handleCountry}
                  selected={selectedCounties}
                  disable={archived}
                  placeholder="Select Country"
                />
                {(errorMessage.country && (
                  <Validation message={errorMessage.country} />
                )) ||
                  ""}
              </Col>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Course Title" required />
                <Input
                  placeholder="Enter the name of the course"
                  pattern="^\S.*$"
                  value={courseTitle}
                  disabled={archived}
                  onChange={(e) => {
                    handleCourseTitle(e.target.value);
                  }}
                  className={"courseAddEdit-input"}
                />
                {(errorMessage.courseTitle && (
                  <Validation message={errorMessage.courseTitle} />
                )) ||
                  ""}
              </Col>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Category" required />
                <CustomSelect
                  disable={!!courseId}
                  options={categoryList}
                  selected={category}
                  handleDropdown={(value) => handleCategory(value)}
                />
                {(errorMessage.category && (
                  <Validation message={errorMessage.category} />
                )) ||
                  ""}
              </Col>
            </Col>

            <Col lg={8} bsPrefix={`col ${style.topColumn}`}>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Course Type" required />
                <CustomSelect
                  options={courseTypeList}
                  selected={courseType}
                  disable={archived}
                  handleDropdown={(value) => handleCourseType(value)}
                />
                {(errorMessage.courseType && (
                  <Validation message={errorMessage.courseType} />
                )) ||
                  ""}
              </Col>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Course Language" required />
                <CustomMultiSelect
                  options={languageList}
                  handleCustomChange={handleLang}
                  selected={selectedlang}
                  disable={archived}
                  placeholder="Select Language"
                  disableGroup={true}
                />
                {(errorMessage.language && (
                  <Validation message={errorMessage.language} />
                )) ||
                  ""}
              </Col>
              <Col bsPrefix={`col ${style.innerColumn}`}>
                <Label title="Sub Category" required />
                <CustomSelect
                  selected={subCategory}
                  disable={archived}
                  options={subCategoryList}
                  handleDropdown={handleSubCategory}
                />
                {(errorMessage.subCategory && (
                  <Validation message={errorMessage.subCategory} />
                )) ||
                  ""}
              </Col>
            </Col>

            <Col lg={8} bsPrefix={`col ${style.right}`}>
              {(imageUrl && (
                <>
                  {(!archived && (
                    <span
                      className={style.editButton}
                      onClick={() => setShowImageModal(true)}
                    >
                      <img
                        src={edit}
                        alt=""
                        style={{
                          height: "1.2rem",
                          marginLeft: "0.25rem",
                        }}
                      />
                    </span>
                  )) ||
                    ""}
                  <img className={style.image} src={imageUrl} />
                </>
              )) || (
                <Col
                  style={{ marginLeft: "0.938rem", marginRight: "0" }}
                  className={style.biggerSvgBox}
                  onClick={() => setShowImageModal(true)}
                >
                  <img src={uploadImage} alt="" className={style.biggerSvg} />
                  <p className={style.imageText}>
                    Click here to choose a course image
                  </p>
                </Col>
              )}

              {showImageModal && (
                <ImageSelectionModal
                  showModal={showImageModal}
                  handleClose={() => setShowImageModal(false)}
                  fetchImageName={(imageName) => {
                    setImageName(imageName);
                    setShowImageModal(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Row gutter={[20]} fluid="true" style={{ margin: 0 }}>
            <Col lg={8} bsPrefix={`col ${style.innerColumn}`}>
              <Label title="Course Provider" required />
              <CustomSelect
                disabled={(courseId && archived) || false}
                selected={selectedCourseProvider}
                options={courseProviderList}
                handleDropdown={(e) => handleCourseProvider(e)}
              />
              {(errorMessage.courseProvider && (
                <Validation message={errorMessage.courseProvider} />
              )) ||
                ""}
            </Col>
            <Col lg={8} bsPrefix={`col ${style.innerColumn}`}>
              <Label title="Sub Course Provider" />
              <CustomCreatableSelect
                disabled={!selectedCourseProvider}
                options={subCourseProviderList}
                selected={selectedSubCourseProvider}
                handleDropdown={(value) => handleSubCourseProvider(value)}
                handleCreate={(value) => handleCreateCourseProvider(value)}
              />
            </Col>
            <Col lg={8} bsPrefix={`col ${style.innerColumn}`}>
              <Label title="Course Duration (hours or minutes)" required />
              <DurationRangePicker
                onChange={(duration) => {
                  handleDuration(duration);
                }}
                showSelectedDuration={true}
                defaultDuration={decimalToTime(duration)}
              />
              {(errorMessage.duration && (
                <div style={{ marginTop: "2.5rem" }}>
                  <Validation message={errorMessage.duration} />
                </div>
              )) ||
                ""}
            </Col>
          </Row>

          <Row
            gutter={[20]}
            fluid="true"
            style={{ margin: 0, marginBottom: "4.375rem" }}
          >
            <Col lg={12} bsPrefix={`col ${style.topColumn}`}>
              <Label title="Course Description" required />
              <TextEditor
                readOnly={archived}
                value={description}
                ref={(el) => (textEditorRef.current[0] = el)}
                getText={(text) => {
                  handleDescription(text);
                }}
              />
              {(errorMessage.description && (
                <Validation
                  message={errorMessage.description}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
            <Col lg={12} bsPrefix={`col ${style.topColumn} ${style.right}`}>
              <Label title="Course Objectives" required />
              <TextEditor
                readOnly={archived}
                ref={(el) => (textEditorRef.current[1] = el)}
                value={objectives}
                getText={(text) => {
                  handleObjectives(text);
                }}
              />
              {(errorMessage.objective && (
                <Validation
                  message={errorMessage.objective}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            gutter={[20]}
            fluid="true"
            style={{
              margin: 0,
              marginBottom: "4.375rem",
              marginTop:
                (errorMessage && errorMessage.description && "-4.375rem") ||
                (errorMessage && errorMessage.objective && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12} bsPrefix={`col ${style.topColumn}`}>
              <Label title="Targeted Audience" required />
              <TextEditor
                ref={(el) => (textEditorRef.current[2] = el)}
                value={targetedAudience}
                readOnly={archived}
                getText={(text) => {
                  handleTargetedAudience(text);
                }}
              />
              {(errorMessage.targetedAudience && (
                <Validation
                  message={errorMessage.targetedAudience}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
            <Col lg={12} bsPrefix={`col ${style.topColumn} ${style.right}`}>
              <Label title="Prerequisites" required />
              <TextEditor
                ref={(el) => (textEditorRef.current[3] = el)}
                value={preRequisites}
                readOnly={archived}
                getText={(text) => {
                  handlePreRequisites(text);
                }}
              />
              {(errorMessage.preRequisites && (
                <Validation
                  message={errorMessage.preRequisites}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            gutter={[20]}
            fluid="true"
            style={{
              margin: 0,
              marginBottom: "4.375rem",
              marginTop:
                (errorMessage &&
                  errorMessage.targetedAudience &&
                  "-4.375rem") ||
                (errorMessage && errorMessage.preRequisites && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12} bsPrefix={`col ${style.topColumn}`}>
              <Label title="Recommended Course" required />
              <TextEditor
                ref={(el) => (textEditorRef.current[4] = el)}
                value={recommendations}
                readOnly={archived}
                getText={(text) => {
                  handleRecommendation(text);
                }}
              />
              {(errorMessage.recommendations && (
                <Validation
                  message={errorMessage.recommendations}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
            <Col lg={12} bsPrefix={`col ${style.topColumn}  ${style.right}`}>
              <Label title="Course Topics" required />
              <TextEditor
                ref={(el) => (textEditorRef.current[5] = el)}
                value={topics}
                readOnly={archived}
                getText={(text) => {
                  handleTopics(text);
                }}
              />
              {(errorMessage.topics && (
                <Validation
                  message={errorMessage.topics}
                  style={{ marginTop: "3.5rem" }}
                />
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            gutter={[20]}
            fluid="true"
            style={{
              margin: 0,
              marginBottom: "2rem",
              marginTop:
                (errorMessage && errorMessage.recommendations && "-4.375rem") ||
                (errorMessage && errorMessage.topics && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12} bsPrefix={`col ${style.topColumn}`}>
              <Label title="Course Materials" />
              <Layout
                style={{
                  backgroundColor: "#EBEBEB",
                  borderRadius: "0.375rem",
                  height: "14.188rem",
                  marginTop: "0rem",
                  paddingTop: "5rem",
                }}
              >
                <Row>
                  <Col lg={4} style={{ marginLeft: "5rem" }}>
                    <img className={style.box_image} src={boxLogo} />
                  </Col>
                  <Col lg={8} style={{ paddingLeft: "14rem" }}>
                    <Col
                      style={{
                        paddindTop: "10rem",
                        marginLeft: "-10rem",
                        padding: 0,
                      }}
                    >
                      <Typography
                        style={{ width: "14rem", paddingBottom: "1rem" }}
                      >
                        Enter the course material URL link
                      </Typography>
                    </Col>
                    <Input
                      value={trainingMaterial}
                      disabled={archived}
                      onChange={(e) => {
                        setTrainingMaterial(e.target.value);
                      }}
                      style={{ right: "10rem", width: "14rem" }}
                    />
                  </Col>
                </Row>
              </Layout>
            </Col>

            <Col lg={12} bsPrefix={`col ${style.topColumn} ${style.right}`}>
              <Label title="Search Tags" />
              <Row
                style={{
                  padding: "0.9rem",
                  border: "0.063rem solid #ced4da",
                  maxWidth: "40.438rem",
                  marginLeft: "0.125rem",
                  maxHeight: "14.313rem",
                  borderRadius: "0.375",
                }}
              >
                <Select
                  mode="tags"
                  style={{
                    width: "100%",
                  }}
                  placeholder="Add Search Tags"
                  onChange={(tags) => {
                    tags = tags.filter((tag) => tag.trim().length > 0);
                    const formattedSearchTags = tags.map((tag) => ({
                      value: tag,
                      label: tag,
                    }));
                    setInitialTags(formattedSearchTags);
                  }}
                  options={initialTags}
                  value={initialTags}
                />
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={20}>
              <Label title="Admin Notes" />
              <TextEditor
                ref={(el) => (textEditorRef.current[6] = el)}
                customClass={style.adminNotes}
                readOnly={archived}
                value={adminComment}
                getText={(text) => {
                  setAdminComment(text);
                }}
              />
            </Col>
          </Row>

          {zipFileName && (
            <Row fluid="true">
              <span style={{ marginLeft: "1rem", marginTop: "2rem" }}>
                <img src={uploadZip} />
                <span style={{ marginLeft: "1rem" }}>{zipFileName}</span>
              </span>
            </Row>
          )}

          <Row
            style={{
              marginTop: "4.15625rem",
              maxWidth: "25rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col
              style={{
                margin: 0,
                padding: 0,
                marginLeft: "0.9375rem",
                marginTop: "-2.5rem",
                position: "absolute",
                zIndex: 998,
                width: "2rem",
              }}
            >
              <SecondaryButton
                otherStyle={style.secButton}
                label="Reset"
                handleClick={resetForm}
              />
            </Col>
            <Col
              style={{
                marginLeft: "-8rem",
                marginTop: "-5.8rem",
                position: "absolute",
                zIndex: 998,
                width: "2rem",
              }}
            >
              <AdminPrimaryButton
                otherStyle={style.primryButton}
                label={(courseId && "Update") || "Continue to next step"}
                handleClick={submitHandler}
              />
            </Col>
          </Row>
        </Form>
      </div>
      {showConfirmationModal ? (
        <AdminConfirmationModal
          toggle={true}
          title="Create "
          titleBold="Course Provider"
          show={showConfirmationModal}
          leftButton="YES"
          rightButton="NO"
          handleClose={() => setShowConfirmationModal(false)}
          rightButtonClick={() => setShowConfirmationModal(false)}
          leftButtonClick={createSubCourseProvider}
          modalContent={modalContent}
        />
      ) : (
        <></>
      )}
    </>
  );
}

import React from "react";
import { DragHandle } from "./DragHandle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableItem from "./DraggableItem";
//TODO remove module.css
import style from "./draggableList.module.css";
import "./DraggableList.css";

const DraggableList = ({ list, updateSelectedCourse, disable }) => {
  const deleteSelectedCourse = (id) => {
    if (id) {
      const newArr = list.filter((e) => e.id !== id);
      updateSelectedCourse(newArr);
    }
  };
  return (
    <div className={style.draggableList}>
      <DragDropContext
        onDragEnd={(param) => {
          const srcI = param.source.index;
          const desI = param.destination?.index;
          if (desI != null) {
            list.splice(desI, 0, list.splice(srcI, 1)[0]);
            updateSelectedCourse(list);
          }
        }}
      >
        <Droppable droppableId="droppable-1">
          {(provided, _) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={style.courseItemWrapper}
            >
              {list?.length > 0 &&
                list?.map((item, i) => (
                  <div className={style.courseItem}>
                    <Draggable
                      key={item.id}
                      draggableId={"draggable-" + item.id}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: item.private
                              ? "#fef0dd"
                              : "#e5f5fd",
                          }}
                          className={`draggable-list-box ${
                            snapshot.isDragging ? "draggable-box-shadow" : " "
                          }`}
                        >
                          <DragHandle {...provided.dragHandleProps} />
                          {!disable ? (
                            <div
                              className={style.deleteButton}
                              onClick={() => deleteSelectedCourse(item.id)}
                            >
                              x
                            </div>
                          ) : (
                            ""
                          )}
                          <DraggableItem item={item} />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableList;

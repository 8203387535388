import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import CarouselPagination from "../antDesignCarouselPagination/CarouselPagination";
import style from "./sliderWithPaginationProgressBar.module.css";

const SliderWithPaginationProgressBar = ({ itemCount, children, filter }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    sliderRef.current.slickGoTo(0, true);
    setCurrentSlide(0);
  }, [itemCount, filter]);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rows: 3,
    slidesPerRow: 2,
    afterChange: (current) => setCurrentSlide(current),
  };
  const itemsPerPage = settings.rows * settings.slidesPerRow;
  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      <Slider
        {...settings}
        ref={sliderRef}
        style={{ height: "100%", width: "100%" }}
        className="courseslick"
      >
        {children}
      </Slider>
      <div className={style.footer}>
        {itemCount > itemsPerPage && (
          <>
            <CarouselPagination
              currentPage={currentSlide}
              maxPages={Math.ceil(itemCount / itemsPerPage)}
              maxWidth={4.3125}
              prevHandler={gotoPrev}
              nextHandler={gotoNext}
              prevDisabled={currentSlide === 0}
              nextDisabled={
                currentSlide === Math.ceil(itemCount / itemsPerPage) - 1
              }
            />
          </>
        )}
      </div>
    </>
  );
};
export default SliderWithPaginationProgressBar;

import React, { useEffect, useState } from "react";
import "./style.css";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";

export default function CustomCreatableSelect(props) {
  const { options, handleDropdown, handleCreate, selected, disabled } = props;
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleChange = (current) => {
    setSelectedOption(current);
    handleDropdown(current);
  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <CreatableSelect
      isDisabled={disabled}
      value={selectedOption}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      classNamePrefix={`innerclass`}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body}
    />
  );
}

CustomCreatableSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lable: PropTypes.element.string,
      value: PropTypes.element.string,
    })
  ),
  handleDropdown: PropTypes.func,
  handleCreate: PropTypes.func,
  selected: PropTypes.any,
};
import { Alert, Breadcrumb, Button, Checkbox, Tag } from "antd";
import { useContext, useEffect, useState } from "react";
import "./myLearningsNew.scss";
import { find } from "lodash";
import config from "../../../config";
import ilIcon from "../../../assets/icons/InstructorLedCourse.svg";
import elIcon from "../../../assets/icons/myLearningElIcon.svg";
import lpIcon from "../../../assets/icons/learning_path.svg";
import { Spin } from "antd";
import debounce from "lodash/debounce";
import MyLearningsCourseCard from "./myLearningsCourseCard";
import BookmarkedIcon from "../../../components/common/icons/bookmarkedIcon";
import InProgressSvgIcon from "../../../components/common/icons/InProgressIcon";
import waitListIcon from "../../../components/common/icons/waitListIcon";
import assignedIcon from "../../../components/common/icons/assignedIcon";
import scheduledIcon from "../../../components/common/icons/scheduledIcon";
import recommendationIcon from "../../../components/common/icons/recommendationIcon";
import completedIcon from "../../../components/common/icons/completedIcon";
import noAssign from "../../../assets/images/noAssigned.png";
import noBookmark from "../../../assets/images/noBookmark.png";
import noCompleted from "../../../assets/images/noCompleted.png";
import noSchedule from "../../../assets/images/noSchedule.png";
import noWaitlist from "../../../assets/images/noWaitlist.png";
import noInProgress from "../../../assets/images/noInProgress.png";
import noRecommendation from "../../../assets/images/noRecommendation.png";
import { Quotation } from "../../../component-library";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";

const crumbs = [
  {
    title: (
      <a href="/home" className="my-learnings-breadcrumb">
        Home
      </a>
    ),
  },
  { title: "My Learnings" },
];

const filters = [
  {
    key: "inProgress",
    value: "In Progress",
    icon: InProgressSvgIcon,
    image: noInProgress,
    description:
      "Indicates that a learner has started the course but has not yet completed it.",
    text: "No In Progress",
    userMessage:
      "It looks like you don't have any bookmarks yet. Start bookmarking your favorite courses,  to easily access them later.",
    subFilters: [
      {
        key: "il",
        name: "INSTRUCTOR LED",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-ilIcon" />
        ),
      },
      {
        key: "el",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "E LEARNING",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
      },
    ],
  },
  {
    key: "bookmarked",
    value: "Bookmarked",
    icon: BookmarkedIcon,
    image: noBookmark,
    description:
      "Courses that a user has marked for future reference or to indicate interest.",
    text: "No Bookmarks Found",
    userMessage:
      "It looks like you don't have any bookmarks yet. Start bookmarking your favorite courses,  to easily access them later",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-ilIcon" />
        ),
        name: "INSTRUCTOR LED",
      },
      {
        key: "el",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "E LEARNING",
      },
      {
        key: "lp",
        name: "LEARNING PATH",
        icon: (
          <img src={lpIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
      },
    ],
  },
  {
    key: "assigned",
    value: "Assigned",
    icon: assignedIcon,
    image: noAssign,
    description:
      "Courses that have been specifically assigned to a learner by an administrator or manager.",
    text: "No Assigned Courses Yet",
    userMessage:
      "You currently don't have any assigned courses. Please check back later",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-ilIcon" />
        ),
        name: "INSTRUCTOR LED",
      },
      {
        key: "el",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "E LEARNING",
      },
      {
        key: "lp",
        name: "LEARNING PATH",
        icon: (
          <img src={lpIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
      },
    ],
  },
  {
    key: "scheduled",
    value: "Scheduled",
    icon: scheduledIcon,
    image: noSchedule,
    description:
      "Courses with a specific future start date and time for which learners are enrolled.",
    text: "No Scheduled Courses",
    userMessage:
      "You currently don't have any scheduled courses Please check back later ",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "INSTRUCTOR LED",
      },
    ],
  },
  {
    key: "waitlist",
    value: "Waitlist",
    icon: waitListIcon,
    image: noWaitlist,
    description:
      "Courses that learner have expressed interest that is currently full.",
    text: "No Waitlisted Courses",
    userMessage:
      "You currently don't have any courses on your waitlist. Check back later or explore other available courses to enroll in.",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "INSTRUCTOR LED",
      },
    ],
  },
  {
    key: "recommendations",
    value: "Recommendation",
    icon: recommendationIcon,
    image: noRecommendation,
    description:
      "Courses that learner have expressed interest that is currently full.",
    text: "No Recommended Courses",
    userMessage: "There are currently no completed courses",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "INSTRUCTOR LED",
      },
      {
        key: "el",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "E LEARNING",
      },
      {
        key: "lp",
        name: "LEARNING PATH",
        icon: (
          <img src={lpIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
      },
    ],
  },
  {
    key: "completed",
    value: "Completed",
    icon: completedIcon,
    image: noCompleted,
    description:
      "Indicates that a learner has finished all required components of the course.",
    text: "No Completed Courses",
    userMessage: "There are currently no completed courses",
    subFilters: [
      {
        key: "il",
        icon: (
          <img src={ilIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "INSTRUCTOR LED",
      },
      {
        key: "el",
        icon: (
          <img src={elIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
        name: "E LEARNING",
      },
      {
        key: "lp",
        name: "LEARNING PATH",
        icon: (
          <img src={lpIcon} alt="courseIcon" className="my-learnings-icon" />
        ),
      },
    ],
  },
];

function MyLearningsNew() {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [selectedSubFilter, setSelectedSubFilter] = useState([
    filters[0].subFilters[0],
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    setSelectedSubFilter([selectedFilter.subFilters[0]]);
    setCourseList([]);
  }, [selectedFilter]);

  useEffect(() => {
    totalCourseCount();
  }, []);

  useEffect(() => {
    getCourses();
  }, [selectedSubFilter]);

  const renderBreadcrumbs = () => {
    return <Breadcrumb items={crumbs} className="my-learnings-breadcrumb" />;
  };

  const totalCourseCount = () => {
    setShowLoader(true);
    axios
      .get(`${config.endPoint.commonService}/user/myLearnings/${"count"}`)
      .then((res) => {
        setTotalCount(res.data.count);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlert(
          error?.response?.data?.errors?.message ||
            "Something went wrong, please try again"
        );
        setAlertType("error");
      });
  };

  const getCourses = () => {
    setCourseList([]);
    const subFilters = selectedSubFilter
      .map((subfilter) => subfilter.key)
      .join(",");
    axios
      .get(
        `${config.endPoint.commonService}/user/myLearnings/${selectedFilter.key}?filter=${subFilters}`
      )
      .then((res) => {
        setCourseList(res.data.data);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlert(
          error?.response?.data?.errors?.message ||
            "Something went wrong, please try again"
        );
        setAlertType("error");
      });
  };

  const renderTopPane = () => {
    const handleChange = debounce((subFilter) => {
      if (find(selectedSubFilter, (filter) => filter.key === subFilter.key)) {
        if (selectedSubFilter.length > 1) {
          const updatedSelectedSubFilter = selectedSubFilter.filter(
            (filter) => filter.key !== subFilter.key
          );
          setSelectedSubFilter(updatedSelectedSubFilter);
        }
      } else {
        const updatedSelectedSubFilter = [...selectedSubFilter];
        updatedSelectedSubFilter.push(subFilter);
        setSelectedSubFilter(updatedSelectedSubFilter);
      }
    }, 300);

    const currentFilter = filters.find(
      (filter) => filter.key === selectedFilter.key
    );

    return (
      <div className="my-learnings-container">
        <div>
          <span className="my-learnings-title">My Learnings</span>
          {currentFilter.subFilters.map((subFilter) => {
            return (
              <Checkbox
                checked={find(
                  selectedSubFilter,
                  (f) => f.key === subFilter.key
                )}
                onChange={() => handleChange(subFilter)}
                className="my-learnings-checkbox"
                style={{ marginRight: "2.5rem", fontSize: 16 }}
              >
                <span>
                  {subFilter.icon}
                  {subFilter.name}
                </span>
              </Checkbox>
            );
          })}
        </div>
        <div className="my-learnings-legend">
          <span className="my-learnings-legend-public" />
          <span style={{ marginRight: "1rem" }}>Public</span>
          <span className="my-learnings-legend-private" />
          <span style={{ marginRight: "2rem" }}>Private</span>
          <span style={{ color: userConfig.primary }}>
            <span style={{ fontFamily: "RakutenRoundBold" }}>
              {showLoader ? <Spin /> : totalCount}
            </span>{" "}
            Courses
          </span>
        </div>
      </div>
    );
  };

  const renderLeftPane = () => {
    return (
      <div className="my-learnings-leftpane">
        {filters.map((filter) => {
          const Icon = filter.icon;
          return (
            <>
              <div
                key={filter.key}
                onClick={() => setSelectedFilter(filter)}
                className="my-learnings-filter-content"
                style={{
                  background: filter.key === selectedFilter.key ? "white" : "",
                  justifyContent:
                    filter.key === selectedFilter.key
                      ? "space-between"
                      : "flex-start",
                }}
              >
                <span
                  className="my-learnings-filter"
                  onMouseEnter={() => {
                    setIsHovered(true);
                    setHoveredIcon(filter.key);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                    setHoveredIcon(null);
                  }}
                  style={{
                    color:
                      filter.key === selectedFilter.key
                        ? userConfig.primary
                        : "black",
                    fontWeight:
                      filter.key === selectedFilter.key ? "bolder" : "",
                    fontFamily:
                      filter.key === selectedFilter.key
                        ? "RakutenRoundBold"
                        : "RakutenRoundRegular",
                  }}
                >
                  {Icon && (
                    <Icon
                      fillColor={
                        filter.key === selectedFilter.key ||
                        filter.key == hoveredIcon
                          ? userConfig.primary
                          : "#f7f7f7"
                      }
                      color={
                        filter.key === selectedFilter.key ||
                        filter.key == hoveredIcon
                          ? userConfig.primary
                          : "#1F1F1F"
                      }
                    />
                  )}
                  <span
                    className="my-learnings-filter-name"
                    style={{
                      width:
                        filter.key === selectedFilter.key ? "6rem" : "10rem",
                    }}
                  >
                    {filter.value}
                  </span>
                </span>
                {selectedFilter.key === filter.key && (
                  <Tag
                    color={userConfig.primary}
                    className="my-learnings-count-tag"
                  >
                    {courseList.length}
                  </Tag>
                )}
              </div>
            </>
          );
        })}
        <div className="my-learnings-description">
          *{selectedFilter.description}
        </div>
      </div>
    );
  };

  const courseCard = (courses) => {
    const Icon = selectedFilter.icon;
    const filterName = selectedFilter.key;
    return (
      <div className="my-learnings-courseCard">
        {courses.map((course, index) => (
          <MyLearningsCourseCard
            setAlert={setAlert}
            setShowAlert={setShowAlert}
            setAlertType={setAlertType}
            filterName={filterName}
            icon={
              <Icon
                width={19}
                height={19}
                color={
                  selectedFilter.key == "completed"
                    ? "#047205"
                    : course.isPrivate
                    ? "#f59600"
                    : userConfig.primary
                }
              />
            }
            courseDetail={course}
            bookmark={() => getCourses()}
          />
        ))}
      </div>
    );
  };

  const handleBrowseClick = () => {
    window.location.href = "/home";
  };

  const noCoursesCard = () => {
    return (
      <div className="my-learnings-noCourses">
        <img
          src={selectedFilter.image}
          className="my-learnings-noCourses-image"
        />
        <span className="my-learnings-noCourses-text">
          {selectedFilter.text}
        </span>
        <span className="my-learnings-noCourses-message">
          {selectedFilter.userMessage}
        </span>
        <span>
          <Button
            type="button"
            className="my-learnings-browse-button"
            onClick={() => handleBrowseClick()}
          >
            BROWSE
          </Button>
        </span>
      </div>
    );
  };

  return (
    <div>
      {window.innerWidth > 786 ? (
        <div className="my-learnings-parent-container">
          <div className="my-learnings-top-container">
            {showAlert && (
              <Alert
                closable
                message={alert}
                type={alertType}
                className={"myLearnings--alert"}
              />
            )}
            {renderBreadcrumbs()}
            {renderTopPane()}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {renderLeftPane()}
            {courseList.length > 0 ? courseCard(courseList) : noCoursesCard()}
          </div>
          <div style={{ marginTop: "10rem" }}>
            <Quotation />
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default MyLearningsNew;

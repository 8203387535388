import React, { useState } from "react";
import axios from "axios";
import config from "../../../../config";
import "./AntdNewCategoryModal.scss";
import { Button, Col, Form, Input, Modal, Row, Select, Typography } from "antd";

function AntdNewCategoryModal({
  showModal,
  setShowModal,
  parentCategory,
  fetchData,
  setAlert,
  setAlertType,
}) {
  const [errorMessage, setErrorMessage] = useState({
    category: "",
    parentCategory: "",
    categoryAcronym: "",
  });
  const [initialTags, setInitialTags] = useState([]);
  const [category, setCategory] = useState(null);
  const [acronym, setAcronym] = useState(null);
  const [tempParent, setTempParent] = useState(null);

  const options = [];
  parentCategory.map((cat, index) => {
    return (options[index] = {
      value: cat.id,
      label: cat.parent_category_name,
    });
  });

  const onSubmit = () => {
    if (category && acronym && tempParent) {
      if (
        !errorMessage.parentCategory &&
        !errorMessage.categoryAcronym &&
        !errorMessage.category
      ) {
        let data = {
          categoryName: category,
          categoryAcronym: acronym,
          parentCategoryId: Number(tempParent) || Number(parentCategory[0].id),
        };
        if (initialTags && initialTags.length) {
          data.subCategories = initialTags;
        }
        axios
          .post(`${config.endPoint.commonService}/category`, data)
          .then((res) => {
            setAlert(res.data.message);
            setAlertType("success");
            fetchData();
          })
          .catch(function (error) {
            setAlert(error.response.data.message);
            setAlertType("error");
          });

        setShowModal(false);
      }
    } else {
      const errorSubmit = {
        parentCategory: "",
        category: "",
        categoryAcronym: "",
      };
      if (!tempParent || tempParent.length === 0) {
        errorSubmit.parentCategory = `Please select a Parent Category`;
      }
      if (!category || category.length === 0) {
        errorSubmit.category = `Please add a Category Name`;
      }
      if (!acronym || acronym.length === 0) {
        errorSubmit.categoryAcronym = `Please add a Category Acronym`;
      }
      setErrorMessage({ ...errorMessage, ...errorSubmit });
    }
  };

  const handleDropdown = (selected) => {
    if (!selected) {
      const error = { parentCategory: `Please select a Parent Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setTempParent(selected);
      const error = { parentCategory: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategory = (category) => {
    setCategory(category);
    if (!category) {
      const error = { category: `Please add a Category Name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (category && !category.trim()) {
      const error = { category: `Category cannot be blank spaces` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { category: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategoryAcronym = (categoryAcronym) => {
    if (!categoryAcronym || categoryAcronym.length === 0) {
      const error = { categoryAcronym: `Please add a Category Acronym` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (categoryAcronym && !categoryAcronym.trim()) {
      const error = {
        categoryAcronym: `Category Acronym cannot be blank spaces`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (categoryAcronym.length === 1) {
      const error = {
        categoryAcronym: `Category Acronym must be longer than or equal to 2 characters`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (
      category &&
      category.toLowerCase() === categoryAcronym.toLowerCase()
    ) {
      const error = {
        categoryAcronym: `Category Acronym cannot be same as the category`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setAcronym(categoryAcronym);
      const error = { categoryAcronym: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  return (
    <div>
      <Modal
        className="nc-modal"
        title="New Category"
        centered
        width={"50rem"}
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <Row>
          <Form layout="vertical">
            <Row>
              <Col style={{ width: "15rem" }}>
                <Form.Item
                  className="nc-form-labelstyle"
                  label="Category Name"
                  required
                  colon={false}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Input
                    className="nc-labelinput"
                    placeholder="Category"
                    onChange={(e) => {
                      handleCategory(e.target.value);
                    }}
                  />
                  <div className="nc-validation">
                    {(errorMessage.category && (
                      <div className="nc-error-validation">
                        {errorMessage.category}
                      </div>
                    )) ||
                      ""}
                  </div>
                </Form.Item>
              </Col>
              <Col
                className="nc-category-acronym"
                style={{ marginLeft: "0rem" }}
              >
                <Form.Item
                  label="Category Acronym"
                  className="nc-form-labelstyle"
                  required
                  colon={false}
                >
                  <Input
                    className="nc-labelinput"
                    placeholder="Category Acronym"
                    onChange={(e) => {
                      handleCategoryAcronym(e.target.value);
                    }}
                  />
                  <div className="nc-validation">
                    {(errorMessage.categoryAcronym && (
                      <div className="nc-error-validation">
                        {errorMessage.categoryAcronym}
                      </div>
                    )) ||
                      ""}
                  </div>
                </Form.Item>
              </Col>
              <Col
                className="nc-form-label"
                style={{ marginLeft: "3rem", width: "15rem" }}
              >
                <Form.Item
                  className="nc-form-labelstyle"
                  label="Parent Category"
                  required
                  colon={false}
                >
                  <Select
                    className="nc-modalSelect"
                    placeholder="Select..."
                    options={options}
                    onChange={handleDropdown}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Typography className="nc-subcategories">Subcategories</Typography>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder="Add Subcategory"
            onChange={(tags) => {
              setInitialTags(tags);
            }}
            value={initialTags}
          />
        </Row>

        <Row className="nc-modal-buttons">
          <Button
            className="nc-cancel-button"
            type="button"
            onClick={() => setShowModal(false)}
          >
            CANCEL
          </Button>
          <Button className="nc-submit-button" type="button" onClick={onSubmit}>
            SUBMIT
          </Button>
        </Row>
      </Modal>
    </div>
  );
}

export default AntdNewCategoryModal;

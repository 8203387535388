function convertObjectToArray(data) {
  if (!Object.keys(data).length) {
    return [];
  }
  const newArray = [];
  for (let [value, label] of Object.entries(data)) {
    newArray.push({ value, label });
  }
  return newArray;
}

function formattedDataForCustomSelect(data) {
  if (!data || data.length === 0) return [];

  return data.map((current) => {
    return { value: current.id, label: current.title };
  });
}

function formatCountries(countryGroups) {
  if (!countryGroups || Object.keys(countryGroups).length <= 0) {
    return [];
  }
  const countryGrp = [];
  for (let group in countryGroups) {
    const objArray = [];
    for (let country in countryGroups[group]) {
      const countryObject = {
        groupId: group,
        id: countryGroups[group][country],
        value: country,
      };
      objArray.push(countryObject);
    }
    const obj = {
      groupId: group,
      title: group,
      options: objArray,
    };

    countryGrp.push(obj);
  }
  return countryGrp;
}

// TODO : need to improve
function formatCountryList(countryGroups) {
  if (!countryGroups || countryGroups.length === 0) {
    return [];
  }
  const newData = groupBy(countryGroups, "region");
  const countries = [];
  for (let i in newData) {
    countries.push({
      groupId: i,
      title: i,
      options: newData[i],
    });
  }
  return countries;
}

function groupBy(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push({ groupId: obj.region, id: obj.code, value: obj.country });
    return acc;
  }, {});
}

function formatSelectedCountries(countryGroups, selectedCountryIds) {
  const selectedCountries = [];
  if (
    !countryGroups ||
    Object.keys(countryGroups).length <= 0 ||
    selectedCountryIds.length <= 0
  ) {
    return [];
  }
  for (let group in countryGroups) {
    for (let country in countryGroups[group]) {
      if (selectedCountryIds.includes(countryGroups[group][country])) {
        selectedCountries.push({
          id: countryGroups[group][country],
          value: country,
        });
      }
    }
  }
  return selectedCountries;
}

function extractContent(html) {
  const span = document.createElement("span");
  span.innerHTML = html;
  return span.textContent || span.innerText;
}

function validateLearningPathDetails(courseDetails) {
  const errors = {};
  const {
    parentCategories,
    countries,
    title,
    courses,
    description,
    objectives,
    targetedAudience,
    prerequisites,
    language,
  } = courseDetails;

  if (parentCategories?.length === 0) {
    errors.selectedParentCategory = `Please select Learning Skill`;
  }
  if (!countries || countries.length === 0) {
    errors.country = `Please select at least one Country`;
  }

  if (!courses || courses.length === 0) {
    errors.allSelectedCourses = `Please select at least one Course`;
  }

  if (!title) {
    errors.pathTitle = `Please enter Path Title`;
  }
  if (!language) {
    errors.language = "Please select a Language";
  }

  if (!description) {
    errors.description = `Please enter Path Description`;
  }
  if (!objectives) {
    errors.objectives = `Please enter Path Objectives`;
  }
  if (!targetedAudience) {
    errors.targetedAudience = `Please enter Path Targeted Audience`;
  }
  if (!prerequisites) {
    errors.preRequisites = `Please enter Path Prerequisites`;
  }

  return errors;
}

export {
  convertObjectToArray,
  formatCountries,
  formatSelectedCountries,
  formatCountryList,
  formattedDataForCustomSelect,
  validateLearningPathDetails,
  extractContent,
};

import React from "react";
import { Row, Col } from "react-bootstrap";
import private_icon from "../../assets/icons/private_icon.svg";
import PropTypes from "prop-types";
import style from "./publicPrivateLegend.module.css";

function PublicPrivateLegend({ otherStyle }) {
	return (
		<>
			<Row className={`${style.legendContainer} ${otherStyle}`}>
				<Col lg="5">
					<Row>
						<Col lg="3">
							<div className={style.publicIcon}></div>
						</Col>
						<Col lg="6">Public</Col>
					</Row>
				</Col>
				<Col lg="5">
					<Row>
						<Col lg="3">
							<img src={private_icon} alt="private" />
						</Col>
						<Col lg="6">Private</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}

PublicPrivateLegend.propTypes = {
	otherStyle: PropTypes.string,
};

export default PublicPrivateLegend;

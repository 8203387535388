import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PrimaryButton from "../primaryButton/PrimaryButton";
import { Modal, Col } from "antd";
import style from "./imageCropper.module.css";
import "./imageCropper.css";

function ImageCropper({
	ref,
	show,
	handleCloseCropper,
	fileToCropper,
	setFile,
	setBase64String,
}) {
	const [upImg, setUpImg] = useState();
	const imgRef = useRef(null);
	const [crop, setCrop] = useState({ unit: "px", width: 600, height: 350 });
	const [completedCrop, setCompletedCrop] = useState(null);
	function gotImage() {
		const reader = new FileReader();
		reader.addEventListener("load", () => setUpImg(reader.result));
		try {
			reader.readAsDataURL(ref.fileToCropper);
		} catch {
			reader.readAsDataURL(fileToCropper);
		}
	}
	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	function uploadFile() {
		setBase64String(finalImg.toDataURL("image/jpeg").split(";base64,")[1]);

		finalImg.toBlob(
			(blob) => {
				setFile(URL.createObjectURL(blob));
			},
			"image/jpeg",
			0.66
		);
		handleCloseCropper();
	}
	const [finalImg, setFinalImg] = useState(null);
	useEffect(() => {
		if (!completedCrop || !imgRef.current) {
			return;
		}
		const image = imgRef.current;
		const canvas = document.createElement("canvas");
		const crop = completedCrop;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext("2d");
		const pixelRatio = 1;
		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = "high";

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		setFinalImg(canvas);
	}, [completedCrop]);

	return (
		<div>
			{gotImage()};
			<Modal
				title={"Image Crop"}
				open={show}
				onCancel={handleCloseCropper}
				width={1100}
				footer={null}
			>

				<div className={style.functionalBox}>
					<div className={style.reactCrop}>
						<ReactCrop
							src={upImg}
							onImageLoaded={onLoad}
							crop={crop}
							onChange={(c) => setCrop(c)}
							onComplete={(c) => setCompletedCrop(c)}
							locked={true}
						/>
					</div>
				</div>

				<div className={style.submit}>
					<PrimaryButton
						label={"Submit"}
						disabled={!completedCrop?.width || !completedCrop?.height}
						handleClick={() => uploadFile()}
					/>
				</div>
			</Modal >
		</div >
	);
}

export default ImageCropper;

import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import style from "./pendingApprovalModal.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SecondaryButton from "../secondaryButton/SecondaryButton";
import MyapprovalButton from "../primaryButton/MyapprovalButton";
function PendingApprovalModal({
  show,
  handleClose,
  leftButton,
  rightButton,
  leftButtonClick,
  rightButtonClick,
  approvalCount,
}) {
  return (
    <>
      <Modal
        animation={false}
        contentClassName={style.modal}
        show={show}
        onHide={handleClose}
        style={{ overflowY: "hidden", marginTop: 0 }}
      >
        <Modal.Header className={[style.header, style.bellHeader]}>
          <img
            src={
              "https://storage.googleapis.com/rlms-public-assets/bell-icon.gif"
            }
            alt="notification icon"
            className={style.bell}
          />
        </Modal.Header>
        <Modal.Body className={style.modalbody}>
          <div className={style.modalContent}>
            You have <b> {approvalCount} course enrollment approval pending </b>
            from your side <br />
            Click on My Approval button to take action.
          </div>

          <span className={style.buttonWrapper}>
            <span className={style.leftButton}>
              <SecondaryButton
                label={leftButton}
                handleClick={leftButtonClick}
                otherStyle={style.buttonStyle}
              />
            </span>
            <span className={style.rightButton}>
              <MyapprovalButton
                label={rightButton}
                handleClick={rightButtonClick}
                otherStyle={style.buttonStyle}
              />
            </span>
          </span>
        </Modal.Body>
      </Modal>
    </>
  );
}

PendingApprovalModal.propTypes = {
  show: PropTypes.bool,
  leftButton: PropTypes.string,
  rightButton: PropTypes.string,
  handleClose: PropTypes.func,
  leftButtonClick: PropTypes.func,
  rightButtonClick: PropTypes.func,
};

export default PendingApprovalModal;

import React, { useState, useEffect } from "react";
import "./style.css";

import { MultiSelect } from "react-crane";
import PropTypes from "prop-types";

export default function CustomMultiSelect({
  options,
  handleCustomChange,
  selected,
  disable = false,
  placeholder = 'Select',
  disableGroup = false,
  customStyle = null
}) {
  const [value, setValue] = useState(selected);

  const [termTypes, setTermTypes] = useState([]);
  const [terms, setTerms] = useState([]);


  useEffect(() => {
    if (options.length) {
      setTermTypes(
        options.map((current) => {
          return { groupId: current.groupId, title: current.title };
        })
      );
      const multiSelectOptions = [];
      options.forEach((current) => {
        multiSelectOptions.push(...current.options);
      });
      setTerms(multiSelectOptions);
    }
  }, [options]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  const handleChanges = (event) => {
    handleCustomChange(event.value);
    setValue(event.value);
  };
  return (
    <MultiSelect
      allowSelectAll={true}
      autoCloseMenu={false}
      clearable={false}
      className={customStyle}
      groups= {(disableGroup)? []: termTypes} 
      groupByKey= {(disableGroup)? '': "groupId"}
      groupTitleKey= {(disableGroup)? '': "title"} 
      groupValueKey= {(disableGroup)? '': "groupId"}
      ignoreCase
      isOpen={("Is Open", false)}
      labelKey="value"
      openOnClick={true}
      options={terms}
      placeholder={("Placeholder", placeholder)}
      valueKey="id"
      value={value}
      valueLabelLimit={3}
      onChange={handleChanges}
      disabled={disable}
    />
  );
}

CustomMultiSelect.propTypes = {
  options: PropTypes.any,
  handleCustomChange: PropTypes.func,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
  disableGroup: PropTypes.bool,
  placeholder: PropTypes.any,
  customStyle: PropTypes.string
};

import React from "react";
import { Card, Col, Tooltip, OverlayTrigger } from "antd";
import PropTypes from "prop-types";
import style from "./statsTile.module.css";
import ConditionalWrap from "../validation/utility/ConditionalWrap";

const StatsTile = ({
  label,
  value,
  insight = null,
  span = 0,
  courseType,
  description,
}) => {
  return (
    <div className="statstile-container">
      <Col>
        {description ? (
          <Tooltip title={description} placement="auto">
            <div className={style.tileContainer}>
              {courseType && (
                <span
                  className={[
                    style.courseType,
                    courseType === "EL" ? style.el : style.il,
                  ].join(" ")}
                >
                  {courseType === "EL" ? "E-Learning" : "Instructor-Led"}
                </span>
              )}
              <div className={style.tileLabel}>{label}</div>
              <div className={style.tileValue}>{value}</div>
              {insight && (
                <div
                  className={style.tileInsight}
                  dangerouslySetInnerHTML={{ __html: insight }}
                ></div>
              )}
            </div>
          </Tooltip>
        ) : (
          <div className={style.tileContainer}>
            {courseType && (
              <span
                className={[
                  style.courseType,
                  courseType === "EL" ? style.el : style.il,
                ].join(" ")}
              >
                {courseType === "EL" ? "E-Learning" : "Instructor-Led"}
              </span>
            )}
            <div className={style.tileLabel}>{label}</div>
            <div className={style.tileValue}>{value}</div>
            {insight && (
              <div
                className={style.tileInsight}
                dangerouslySetInnerHTML={{ __html: insight }}
              ></div>
            )}
          </div>
        )}
      </Col>
    </div>
  );
};

StatsTile.propTypes = {
  span: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  insight: PropTypes.string,
};
export default StatsTile;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./adminDataTable.css";
import style from "./adminDataTable.module.css";
import filterFactory from "react-bootstrap-table2-filter";
import { DownloadCSVButton, AdminSearchBar } from "../../component-library";
import pageArrowIcon from "../../assets/icons/page_arrow_icon.svg";

const AdminDataTable = React.forwardRef(
  (
    {
      data,
      columns,
      keyField,
      otherElements,
      rowStyle,
      searchBarStyle,
      downloadButtonStyle,
      expandRow,
      selectRow,
      defaultSorted,
      exportCSV,
      searchFormatted,
      paginationHide,
    },
    ref
  ) => {
    const prevButton = (
      <>
        <img
          className={`${style.pageButton} ${style.leftIcon}`}
          src={pageArrowIcon}
          alt="prev"
        />
      </>
    );
    const nextButton = (
      <>
        <img
          className={`${style.pageButton} ${style.rightIcon}`}
          src={pageArrowIcon}
          alt="next"
        />
      </>
    );
    const paginationOptions = {
      hideSizePerPage: true,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: false,
      prePageText: prevButton,
      nextPageText: nextButton,
    };

    return (
      <>
        <ToolkitProvider
          keyField={keyField}
          data={data}
          columns={columns}
          search={
            searchFormatted
              ? { searchFormatted: true }
              : { searchFormatted: false }
          }
          exportCSV={exportCSV}
        >
          {(props) => (
            <>
              {otherElements}
              <AdminSearchBar
                otherStyle={searchBarStyle}
                {...props.searchProps}
              />
              <DownloadCSVButton
                otherStyle={downloadButtonStyle}
                {...props.csvProps}
              />
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                data={data}
                columns={columns}
                keyField={keyField}
                bordered={false}
                rowStyle={rowStyle}
                filter={filterFactory()}
                pagination={
                  paginationHide ? null : paginationFactory(paginationOptions)
                }
                noDataIndication="No Data Available"
                expandRow={expandRow}
                selectRow={selectRow}
                defaultSorted={defaultSorted}
                ref={ref}
              />
            </>
          )}
        </ToolkitProvider>
      </>
    );
  }
);

export default AdminDataTable;

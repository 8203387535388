import React, { useState, useEffect, useRef, useContext } from "react";
import { Col } from "antd";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import "./SessionAddEdit.scss";
import calendar from "../../../assets/icons/calendar.svg";
import clock from "../../../assets/icons/clock.svg";
import moment from "moment";
import { CourseContext } from "../../../context/CourseContext";

export default function SessionAddEditDateTimePicker({
    value,
    minValue,
    maxValue,
    allowInput = true,
    clickOpens = true,
    onChange,
    altFormat,
    pickTime = false,
    placeholder,
    time_24hr = false,
    disabled = false,
    customStyle = null,
}) {
    const { userDateFormat } = useContext(CourseContext);
    const [dateOrTime, setDateOrTime] = useState("");
    const pickerRef = useRef();

    let minDate = "today";
    if (minValue) {
        minDate = minValue;
    }
    let maxDate = "";
    if (maxValue) {
        maxDate = maxValue;
    }

    useEffect(() => {
        if (value) setDateOrTime(value);
        else {
            setDateOrTime(null);
        }
    }, [value]);

    const options = {
        noCalendar: pickTime,
        enableTime: pickTime,
        dateFormat: (!!pickTime && "h:i K") || "Y-m-d",
        minDate,
        maxDate,
        allowInput: allowInput,
        clickOpens,
        altInput: true,
        altFormat: altFormat
            ? altFormat
            : !!pickTime
                ? "h:i K"
                : userDateFormat
                    ? `${userDateFormat.split("/")[0].length == 4
                        ? userDateFormat.split("/")[0][0]
                        : userDateFormat.split("/")[0][0].toLowerCase()
                    }/${userDateFormat.split("/")[1][0].toLowerCase()}/${userDateFormat.split("/")[2].length == 4
                        ? userDateFormat.split("/")[2][0]
                        : userDateFormat.split("/")[2][0].toLowerCase()
                    }`
                    : "d/m/Y",
        time_24hr: time_24hr,
    };

    const icon = pickTime ? clock : calendar;

    const handleOnChangeDate = (date) => {
        if (date && date.length) {
            const isoValue = new Date(date[0]).toISOString();
            const val =
                (!pickTime && moment(isoValue).format("YYYY-MM-DD")) ||
                moment(isoValue).format("HH:mm");
            onChange(val);
        } else {
            onChange("");
        }
    };

    return (
        <Col style={{ padding: "0", postion: "relative" }}>
            <Flatpickr
                ref={pickerRef}
                placeholder={placeholder ? placeholder : "Select..."}
                value={dateOrTime}
                options={options}
                className={customStyle?.datetimePicker || "datetimePicker"}
                onChange={handleOnChangeDate}
                onClose={handleOnChangeDate}
                disabled={disabled}
                dateFormat="YYYY-MM-DD"
            />

            <img
                style={{ cursor: "pointer" }}
                className={customStyle?.img || "img"}
                src={icon}
                alt=""
                onClick={() => {
                    pickerRef.current.flatpickr.open();
                }}
            />
        </Col>
    );
}

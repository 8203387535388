import { Col, Modal, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import AntDesignOutlinedButton from "../antDesignButton/OutlinedButton/outlinedButton";
import AntDesignPrimaryButton from "../antDesignButton/PrimaryButton/primaryButton";
import "./modal.css"

const AntDesignModal = ({
  title,
  titleBold,
  show,
  onOk,
  onCancel,
  okButtonText,
  canceButtonText,
  disableOkButton,
  description,
  width,
  centered = false,
  hideCancelButton = true
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const getTitle = useMemo(() => {
    return <> {title} <b>{titleBold}</b>
    </>
  }, [title, titleBold])

  return (
    <Modal
      title={getTitle}
      style={{
        top: centered ? 'auto' : 100
      }}
      centered={centered}
      width={width || 1064}
      open={showModal}
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <Row gutter={16} justify={"end"}>
          <Col className="gutter-row" span={4}>
            <AntDesignPrimaryButton
              onClick={onOk}
              text={okButtonText || "SUBMIT"}
              isDisabled={disableOkButton}
            />
          </Col>
          {!hideCancelButton && <Col className="gutter-row" span={4}>
            <AntDesignOutlinedButton
              onClick={onCancel}
              text={canceButtonText || "CANCEL"}
            />
          </Col>}
        </Row>
      }
    >
      {description}
      &nbsp;
    </Modal>
  );
};

export default AntDesignModal;

import React, { useState, useEffect, useContext } from "react";
import { Accordion, Card, OverlayTrigger } from "react-bootstrap";
// import Tooltip from "react-bootstrap/Tooltip";
import { LineProgressBarCourseDetail } from "../../../../component-library";
import CourseDetailSessionUsers from "../courseDetailSessionUsers/CourseDetailSessionUsers";
import countryData from "../../../../utils/country_list.json";
import chevron from "../../../../assets/icons/toggle_down_icon_blue.svg";
// import "./courseDetailScheduleAccordian.css";
import "./accordianStyle.scss";
import moment from "moment";
import TimeFormatters from "../../../../utils/timeFormatter";
import parse from "html-react-parser";
import ConditionalWrap from "../../../../component-library/validation/utility/ConditionalWrap";
import { CourseContext } from "../../../../context/CourseContext";
import { Collapse, Divider, Tooltip } from "antd";
import MenuDivider from "antd/es/menu/MenuDivider";
import { truncate } from "lodash";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { UserContext } from "../../../../context/UserContext";

function CourseDetailScheduleAccordian({
  sessionCount,
  sessionData,
  courseData,
  courseEnrolmentStatus,
}) {
  const { userDateFormat } = useContext(CourseContext);
  const offset = moment().utcOffset() / 60;
  const [countryList] = useState(countryData.data);
  const [countryLabelSource, setCountryLabelSource] = useState([]);
  const [accIndex, setAccIndex] = useState([]);
  const [showEnrolledUsersModal, setShowEnrolledUsersModal] = useState(false);
  const [showEnrolledUsersData, setShowEnrolledUsersData] = useState({});
  const { userConfig } = useContext(UserContext);

  const accClicked = (index) => {
    if (accIndex.includes(index)) {
      setAccIndex(accIndex.filter((currentIndex) => currentIndex !== index));
    } else setAccIndex([...accIndex, index]);
  };

  const showEnrolledUsers = (sessionData) => {
    setShowEnrolledUsersModal(true);
    setShowEnrolledUsersData(sessionData);
  };

  const hideEnrolledUsersModal = () => {
    setShowEnrolledUsersModal(false);
    setShowEnrolledUsersData({});
  };

  const isMobile = window.innerWidth < 786;

  useEffect(() => {
    if (Object.keys(courseData).length) {
      sessionData.forEach((unitSchedule) => {
        let countryCode =
          unitSchedule.country === "NULL" ? "NULL" : unitSchedule.country;
        if (!unitSchedule.private) {
          // if public session and only 1 country is available, show that country flag
          if (
            courseData &&
            courseData.countries &&
            courseData.countries.length === 1
          ) {
            countryCode = courseData.countries[0];
          }
        }
        const iconStyle = countryCode === "NULL" ? "globeIcon" : "countryIcon";
        const countrySrc = getCountryImage(countryCode);
        const countryTitle = getCountryNames(countryCode);
        setCountryLabelSource((currentCountry) => [
          ...currentCountry,
          { label: countryTitle, src: countrySrc, style: iconStyle },
        ]);
      });
    }
    // eslint-disable-next-line
  }, [sessionData]);

  const getCountryImage = (countryCode = "NULL") => {
    const countryObj = countryList.countries[countryCode];
    return countryObj ? countryObj.imageURL : "";
  };

  const getCountryNames = (countryCode = "NULL") => {
    const countryObj = countryList.countries[countryCode];
    return countryObj ? countryObj.name : "";
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const items = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];
  return (
    <>
      {countryLabelSource.length > 0 && (
        <Accordion className="courseDetailAccordian">
          {sessionData.slice(0, sessionCount).map((unitSchedule, index) => (
            <div
              key={index}
              className={
                unitSchedule.private ? "sessionPrivate" : "sessionPublic"
              }
            >
              <Accordion.Toggle
                as={Card.Header}
                eventKey={unitSchedule.id}
                onClick={() => accClicked(index)}
                style={{
                  borderBottom: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <div className="headerContainer">
                  <img
                    src={`${process.env.PUBLIC_URL}/${countryLabelSource[index].src}`}
                    title={countryLabelSource[index].countryTitle}
                    className={countryLabelSource[index].style}
                    alt={countryLabelSource[index].countryTitle}
                  />
                  <div className="headerSessionLabel">
                    <Tooltip
                      placement="left"
                      color="#9c9c9c"
                      title={unitSchedule.batch_name}
                      overlayStyle={{
                        fontFamily: "RakutenRoundRegular",
                        maxWidth: "12rem",
                      }}
                    >
                      <div className="accSessionName">
                        {unitSchedule.batch_name}
                      </div>
                    </Tooltip>
                    <div>
                      {(TimeFormatters.FormatDate(
                        unitSchedule.start_time,
                        offset
                      ) ===
                        TimeFormatters.FormatDate(
                          unitSchedule.end_time,
                          offset
                        ) &&
                        TimeFormatters.FormatDate(
                          unitSchedule.start_time,
                          offset,
                          userDateFormat
                        )) ||
                        `${TimeFormatters.FormatDate(
                          unitSchedule.start_time,
                          offset,
                          userDateFormat
                        )}
                          - ${TimeFormatters.FormatDate(
                            unitSchedule.end_time,
                            offset,
                            userDateFormat
                          )}`}
                    </div>
                  </div>
                  <div>
                    <LineProgressBarCourseDetail
                      sessionObj={unitSchedule}
                      key={unitSchedule.id}
                      currentCountry={unitSchedule.counter}
                      maxCount={unitSchedule.size}
                      isLocked={unitSchedule.is_locked}
                      isPrivate={unitSchedule.private}
                      clickHandler={() => {
                        if (!isMobile) showEnrolledUsers(unitSchedule);
                      }}
                    />
                  </div>
                  {accIndex.includes(index) ? (
                    <IoIosArrowUp
                      size={20}
                      color={userConfig.primary}
                      style={{ marginLeft: 8, marginTop: 4 }}
                    />
                  ) : (
                    <IoIosArrowDown
                      size={20}
                      color={userConfig.primary}
                      style={{ marginLeft: 8, marginTop: 4 }}
                    />
                  )}
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={unitSchedule.id}>
                <div>
                  <div className="accBodyContainer">
                    <div
                      style={{ height: "1px", backgroundColor: "#B6B6B6" }}
                    ></div>
                    <label className="agendaLabel">Agenda: </label>
                    {unitSchedule.schedule.length < 1 && (
                      <div>No Modules Available</div>
                    )}
                    {unitSchedule.schedule.length > 0 &&
                      unitSchedule.schedule
                        .sort(
                          (date1, date2) =>
                            new Date(date1.start_date) -
                            new Date(date2.start_date)
                        )
                        .map((currentModule, index) => (
                          <div className="agendaInnerContainer" key={index}>
                            <label className="innerLabel">Date: &ensp;</label>
                            <span className="boldText">
                              {TimeFormatters.FormatDate(
                                currentModule.start_date,
                                offset,
                                userDateFormat
                              )}
                              &ensp;
                              {TimeFormatters.FormatTime(
                                currentModule.start_date,
                                offset,
                                userDateFormat
                              )}{" "}
                              -{" "}
                              {TimeFormatters.FormatDate(
                                currentModule.end_date,
                                offset,
                                userDateFormat
                              )}
                              &ensp;
                              {TimeFormatters.FormatTime(
                                currentModule.end_date,
                                offset,
                                userDateFormat
                              )}
                            </span>
                            <div>
                              <label className="innerLabel">
                                Trainer:&ensp;{" "}
                              </label>
                              {currentModule.trainer_details.map(
                                (currentTrainer, index, trainerList) => (
                                  <span className="boldText" key={index}>
                                    {trainerList.length - 1 === index
                                      ? currentTrainer.trainer_name
                                      : currentTrainer.trainer_name + ", "}
                                  </span>
                                )
                              )}
                            </div>
                            <div>
                              <label className="innerLabel">
                                Location:&ensp;{" "}
                              </label>
                              <span className="boldText">
                                {currentModule.location}
                                {courseEnrolmentStatus === "enrolled" &&
                                currentModule.zoom_link ? (
                                  <a
                                    href={currentModule.zoom_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    ({currentModule.zoom_link.substring(0, 30)}
                                    ...)
                                  </a>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div>{parse(currentModule.agenda)}</div>
                          </div>
                        ))}
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      )}
      {countryLabelSource.length < 1 && (
        <Accordion className="courseDetailAccordian">
          <div className={"noSession"}>
            <Accordion.Toggle
              as={Card.Header}
              style={{
                borderBottom: "none",
                backgroundColor: "transparent",
              }}
            >
              <div className="headerContainer">
                <div className="headerSessionLabel">
                  <div className={"noSessionName"}>* No Upcoming SESSIONS</div>
                </div>
                <div>
                  <LineProgressBarCourseDetail
                    sessionObj={{ counter: 0, size: 0 }}
                    maxCount={0}
                    isLocked={false}
                    isPrivate={false}
                  />
                </div>
              </div>
            </Accordion.Toggle>
          </div>
        </Accordion>
      )}
      {showEnrolledUsersModal && (
        <CourseDetailSessionUsers
          showUsersModal={showEnrolledUsersModal}
          sessionData={showEnrolledUsersData}
          handleClose={hideEnrolledUsersModal}
        />
      )}
    </>
  );
}

export default CourseDetailScheduleAccordian;

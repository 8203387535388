import React, { useState, useEffect, useRef, useContext } from "react";
import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import "./style.css";
import "./calendar.scss";

import { axiosGet } from "../../../utils/axios.utility";
import { formatEventContent } from "./calendar.lib";
import { useHistory } from "react-router-dom";
import SideBar from "./sidebar";
import prev from "../../../assets/icons/leftArrow.svg";
import next from "../../../assets/icons/rightArrow.svg";
import { Breadcrumb, Col, Row } from "antd";
import { UserContext } from "../../../context/UserContext";

async function fetchCalendarDetails() {
  return axiosGet("/calendar-event/");
}

export default function Calendar() {
  const crumbs = [
    {
      title: "Home",
      to: "/",
    },
    {
      title: "Calendar",
    },
  ];
  const history = useHistory();

  const [calendarEvent, setCalendarEvent] = useState([]);
  const [initialCalendarEvent, setInitialCalendarEvent] = useState([]);
  const [filter, setFilter] = useState({});
  const calendarRef = useRef();
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const res = await fetchCalendarDetails();
      setCalendarEvent(
        res.map((current) => {
          return { ...current, public: !current.private };
        })
      );
      setInitialCalendarEvent(
        res.map((current) => {
          return { ...current, public: !current.private };
        })
      );
    })();
  }, []);

  useEffect(() => {
    filterCalendarData(filter);
    // eslint-disable-next-line
  }, [filter]);

  const filterCalendarData = (currentFilter) => {
    if (currentFilter.length > 0) {
      const filteredCalendarEvent = initialCalendarEvent.filter((current) => {
        const booleanFilteredResult = currentFilter.map((filter) => {
          return current[filter];
        });
        const res = booleanFilteredResult.some((current) => !!current);
        return res;
      });
      setCalendarEvent(filteredCalendarEvent);
    } else {
      setCalendarEvent(initialCalendarEvent);
    }
  };

  const handleFilter = (currentFilter) => {
    setFilter(currentFilter);
  };

  const INITIAL_EVENTS = calendarEvent.map((current) => {
    return {
      id: current.course_id,
      courseName: current.course_name,
      title: current.batch_name,
      start: new Date(current.start_time),
      private: current.private,
      locked: current.locked,
      enrollmentStatus: current.enrollment_status,
      end: new Date(current.end_time),
      backgroundColor: (current.private && "#FEF4E5") || "#E5F5FD",
    };
  });

  const renderBreadcrumbs = () => (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="/" className="common-breadcrumbs-color">
          Home
        </a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span style={{ color: "black" }}>Calendar</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Col className="calendar-container">
      <Row>{renderBreadcrumbs()}</Row>
      <Row gutter={[12, 12]}>
        <Col xl={4} lg={4} md={24} sm={24}>
          <SideBar changeHandler={handleFilter} />
        </Col>
        <Col xl={20} lg={20} md={24} sm={24}>
          <FullCalendar
            ref={calendarRef}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            customButtons={{
              previousDate: {
                text: <img src={prev} alt="" />,
                click: () => {
                  calendarRef.current._calendarApi.prev();
                },
              },
              nextDate: {
                text: <img src={next} alt="" />,
                click: () => {
                  calendarRef.current._calendarApi.next();
                },
              },
              // Commenting below  -> This is affecting calendar functionality
              // dayGridMonth: {
              //   text: <p style={{ fontFamily: "RakutenRoundRegular", fontSize: "1rem" }}>Month</p>,
              // },
              // timeGridWeek: {
              //   text: <p style={{ fontFamily: "RakutenRoundRegular", fontSize: "1rem" }}>Week</p>,
              // },
              // timeGridDay: {
              //   text: <p style={{ fontFamily: "RakutenRoundRegular", fontSize: "1rem" }}>Day</p>,
              // },
              // listMonth: {
              //   text: <p style={{ fontFamily: "RakutenRoundRegular", fontSize: "1rem" }}>List</p>,
              // }
            }}
            headerToolbar={{
              left: "previousDate nextDate title",
              // center: ,
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            eventContent={(event) => formatEventContent(event, userConfig)}
            initialView={window.innerWidth > 867 ? "dayGridMonth" : "listMonth"}
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            expandRows={true}
            contentHeight={"auto"}
            events={INITIAL_EVENTS}
            eventClick={(e) => {
              history.push(`/course-details/${e.event._def.publicId}`);
            }}
          />
        </Col>
      </Row>
    </Col>
  );
}

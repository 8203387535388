import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import useDocumentTitle from "./DocumentTitle";

const RolesRoute = ({ title, roles, ...rest }) => {
  const { hasRole } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!hasRole(roles)) {
      history.push("/home");
    }
  }, [hasRole]);

  useDocumentTitle(title);
  return <Route {...rest} />;
};

RolesRoute.propTypes = {
  title: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RolesRoute;

import { useState, useEffect, useRef, useContext } from "react";
import { Breadcrumb, Typography, Button, Form, Input, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { UserAddOutlined } from "@ant-design/icons";
import zoomIcon from "../../../assets/icons/zoom_icon.svg";
import { Row, Col } from "antd";
import axios from "axios";
import {
  TextEditor,
  AdminPageSubTitle,
  TrainerDetail,
  DateTimePicker,
  AdminConfirmationModal,
} from "../../../component-library";
import TimeFormaters from "../../../utils/timeFormatter";
import style from "./moduleAddEdit.module.css";
import "./moduleAddEdit.scss";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { modifyImageTags } from "../../../utils/textEditor";
import { CourseContext } from "../../../context/CourseContext";
import config from "../../../config";

export default function ModuleAddEdit(props) {
  const textEditorRef = useRef(null);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const { userDateFormat } = useContext(CourseContext);
  const sessionId = parseInt(props.match.params.batch_id);
  const moduleId = parseInt(query.get("moduleId"));

  const [courseId, setCourseId] = useState("");
  const [courseIdentityId, setCourseIdentityId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [sessionStartTime, setSessionStartTime] = useState("");
  const [sessionEndTime, setSessionEndTime] = useState("");
  const [sessionTimeTag, setSessionTimeTag] = useState("");
  const [sessionTimezone, setSessionTimezone] = useState("");
  const [sessionTimeZoneTag, setSessionTimeZoneTag] = useState("");

  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [location, setLocation] = useState("");
  const [zoomLink, setZoomLink] = useState("");
  const [agenda, setAgenda] = useState("");
  let [trainersList, setTrainersList] = useState([
    {
      trainer_name: "",
      trainer_email: "",
      internal_trainer: true,
    },
  ]);
  const [errorMessage, setErrorMessage] = useState({});
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  let crumbs = [];
  let pageTitle;
  let actions;
  let enableDateTime = true;

  const handleLocation = (location) => {
    setLocation(location);
    if (!location) {
      const error = { location: `Please enter location` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (location.trim() === "") {
      const error = { location: "location cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { location: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleAgenda = (agenda) => {
    setAgenda(agenda);
    if (!agenda) {
      const error = { agenda: `Please enter agenda` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { agenda: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    if (!date) {
      const error = { startDate: `Please select start date` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { startDate: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleStartTime = (time) => {
    setStartTime(time);
    if (!time) {
      const error = { startTime: `Please select start time` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { startTime: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleEndTime = (time) => {
    setEndTime(time);
    if (!time) {
      const error = { endTime: `Please select end time` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { endTime: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleZoomLink = (zoomLink) => {
    setZoomLink(zoomLink);
  };

  const handleCurTrainerName = (name, index) => {
    trainersList[index].trainer_name = name;
  };

  const handleCurTrainerEmail = (email, index) => {
    trainersList[index].trainer_email = email;
  };

  const handleCurTrainerIsInternalTrainer = (isInternalTrainer, index) => {
    trainersList[index].internal_trainer = isInternalTrainer;
  };

  const handleCloseTrainerDetails = (show, email, index) => {
    trainersList.splice(index, 1);
  };

  const handleTrainerDetails = () => {
    const newTrainer = {
      trainer_name: "",
      trainer_email: "",
      internal_trainer: true,
    };
    setTrainersList((current) => [...current, newTrainer]);
  };

  const onDeleteModule = () => {
    setShowDeleteConfirmation(true);
  };

  const modalContent = (
    <>
      <Row>
        <Col>
          <p>Are you sure you want to delete this module?</p>
        </Col>
      </Row>
    </>
  );

  const handleModalClose = () => setShowDeleteConfirmation(false);

  if (moduleId) {
    crumbs = [
      {
        title: (
          <a href="/Admin" className="crumb-color">
            Admin Dashboard
          </a>
        ),
      },
      {
        title: (
          <a href="/Admin/Courses" className="crumb-color">
            Instructor-led Courses
          </a>
        ),
      },
      {
        title: (
          <a href={`/Admin/SessionList/${courseId}`} className="crumb-color">
            Manage Sessions
          </a>
        ),
      },
      {
        title: (
          <a href={`/Admin/ModuleList/${sessionId}`} className="crumb-color">
            Manage Modules
          </a>
        ),
      },
      {
        title: "Edit Module",
      },
    ];

    pageTitle = "#Module " + moduleId;

    enableDateTime = true;

    actions = (
      <Row style={{ marginTop: 50, marginBottom: 30 }}>
        <Button
          variant="secondary"
          className={style.delete}
          onClick={onDeleteModule}
        >
          Delete
        </Button>
        <Button
          variant="primary"
          type="submit"
          className={"moduleAddEdit-submit"}
          style={{ marginLeft: 20 }}
        >
          Update
        </Button>
      </Row>
    );
  } else {
    crumbs = [
      {
        title: (
          <a href="/Admin" className="crumb-color">
            Admin Dashboard
          </a>
        ),
      },
      {
        title: (
          <a href="/Admin/Courses" className="crumb-color">
            Courses
          </a>
        ),
      },
      {
        title: (
          <a href={`/Admin/SessionList/${courseId}`} className="crumb-color">
            Manage Sessions
          </a>
        ),
      },
      {
        title: (
          <a href={`/Admin/ModuleList/${sessionId}`} className="crumb-color">
            Manage Modules
          </a>
        ),
      },
      {
        title: "Add Module",
      },
    ];

    pageTitle = "Add Module";
  }

  useEffect(() => {
    if (pageLoading) {
      axios
        .get(`${config.endPoint.instructorLedNew}/batch/`, { params: { batch_id: sessionId, need_schedule: true } })
        .then(async (response) => {
          setPageLoading(false);

          setCourseId(response.data.data.course.id);
          setCourseIdentityId(response.data.data.course.identity_id);
          setCourseName(response.data.data.course.course_name);
          setSessionStartTime(response.data.data.start_time);
          setSessionEndTime(response.data.data.end_time);
          setSessionTimezone(response.data.data.tz_offset);
          setSessionTimeTag(
            TimeFormaters.FormatTableTime(
              response.data.data.start_time,
              response.data.data.tz_offset,
              userDateFormat
            ) +
              " to " +
              TimeFormaters.FormatTableTime(
                response.data.data.end_time,
                response.data.data.tz_offset,
                userDateFormat
              )
          );
          setSessionTimeZoneTag(
            TimeFormaters.getTimeZoneFromOffset(response.data.data.tz_offset)
          );

          // Setting default values
          setStartDate(
            TimeFormaters.HTMLDateInputFormat(
              response.data.data.start_time,
              response.data.data.tz_offset
            )
          );
          setStartTime(
            TimeFormaters.wickedTimePickerFormat(
              response.data.data.start_time,
              response.data.data.tz_offset
            )
          );
          setEndTime(
            TimeFormaters.wickedTimePickerFormat(
              response.data.data.end_time,
              response.data.data.tz_offset
            )
          );

          // Check for duration warning
          if (
            response.data.data.duration_warning &&
            response.data.data.duration_warning.length > 0
          ) {
            setAlert(response.data.data.duration_warning);
            setShowAlert(true);
            setAlertType("warning");
          }

          if (moduleId) {
            // Get existing data and load page in edit mode
            const moduleData = response.data.data.schedule.filter(
              // eslint-disable-next-line
              (item) => item.id == moduleId
            )[0];

            const offset = response.data.data.tz_offset;
            setStartDate(
              TimeFormaters.HTMLDateInputFormat(moduleData.start_date)
            );
            setStartTime(
              TimeFormaters.wickedTimePickerFormat(
                moduleData.start_date,
                offset
              )
            );
            setEndTime(
              TimeFormaters.wickedTimePickerFormat(moduleData.end_date, offset)
            );
            setLocation(moduleData.location);
            setZoomLink(moduleData.zoom_link);
            const modifiedData = await modifyImageTags({
              agenda: moduleData.agenda,
            });
            setAgenda(modifiedData.agenda);
            if (
              moduleData.trainer_details &&
              moduleData.trainer_details.length > 0
            ) {
              let trainersData = [];
              moduleData.trainer_details.map((item) => {
                const trainer = {
                  trainer_name: item.trainer_name,
                  trainer_email: item.trainer_email,
                  internal_trainer: item.trainer_is_internal ? true : false,
                };
                return trainersData.push(trainer);
              });
              setTrainersList([]);
              setTrainersList(trainersData);
            }
          }
        })
        .catch(function (error) {
          setAlert(error.response.data.message);
          setShowAlert(true);
          setAlertType("error");
        });
    }
  }, [userDateFormat]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const errors = validateModuleDetails();
    if (Object.keys(errors).length > 0) {
      setErrorMessage({ ...errorMessage, ...errors });
    } else {
      const startTimeCombined = TimeFormaters.getUtcTimeStamp(
        startDate,
        startTime,
        sessionTimezone
      );
      const endTimeCombined = TimeFormaters.getUtcTimeStamp(
        startDate,
        endTime,
        sessionTimezone
      );

      const obj = {
        batch_id: sessionId,
        start_date: startTimeCombined,
        end_date: endTimeCombined,
        location: location,
        zoom_link: zoomLink,
        agenda: agenda,
        trainer_details: trainersList,
      };

      await textEditorRef.current.deleteOrphanedImages();
      if (moduleId) {
        // Action in Edit mode
        const endPoint = `/batch/update-schedule`;
        axios
          .put(endPoint, obj, { params: { schedule_id: moduleId } })
          .then((response) => {
            history.push({
              pathname: `/Admin/ModuleList/${sessionId}`,
              state: {
                // location state
                alertType: "success",
                showAlert: true,
                alertMessage: response.data.message,
              },
            });
          })
          .catch((error) => {
            setAlert(error.response.data.message);
            setShowAlert(true);
            setAlertType("error");
          });
      } else {
        // Action in Create mode
        const endPoint = `/batch/schedule/create`;
        axios
          .post(endPoint, obj)
          .then((response) => {
            history.push({
              pathname: `/Admin/ModuleList/${sessionId}`,
              state: {
                // location state
                alertType: "success",
                alertMessage: response.data.message,
              },
            });
          })
          .catch((error) => {
            setAlert(error.response.data.message);
            setShowAlert(true);
            setAlertType("error");
          });
      }
    }
  };

  function validateModuleDetails() {
    const errors = {};
    if (!startDate || startDate === "") {
      errors.startDate = `Please select start date`;
    }
    if (!startTime || startTime === "") {
      errors.startTime = `Please select start time`;
    }
    if (!endTime || endTime === "") {
      errors.endTime = `Please select end time`;
    }
    if (!location || location === "") {
      errors.location = `Please enter location`;
    }
    if (!agenda) {
      errors.agenda = `Please enter agenda`;
    }
    if (trainersList.length > 0) {
      trainersList.map((trainer) => {
        if (!trainer.trainer_name || trainer.trainer_name === "") {
          errors.trainerList = `Please enter trainer details`;
        }
        if (trainer.trainer_email && trainer.trainer_email !== "") {
          const duplicate = trainersList.filter(
            (item) => item.trainer_email === trainer.trainer_email
          );
          if (duplicate.length > 1) {
            errors.trainerList = `Duplicate Email Ids for trainers are not allowed`;
          }
        }
        return null;
      });
    }

    return errors;
  }

  const onDeleteRequest = () => {
    // Action on deleting module
    axios
      .delete("/batch/update-schedule", { params: { schedule_id: moduleId } })
      .then((res) => {
        handleModalClose();
        history.push({
          pathname: `/Admin/ModuleList/${sessionId}`,
          state: {
            // location state
            alertType: "success",
            alertMessage: "Successfully Deleted!",
          },
        });
      })
      .catch(function (error) {
        handleModalClose();
        setAlert("Failed to delete module!");
        setShowAlert(true);
        setAlertType("error");
      });
  };

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
            onCloseAlert={() => setAlert("")}
          />
        )}
        <Breadcrumb items={crumbs} className={"moduleAddEdit-crumbs"} />
        <Row>
          <Typography.Title className="moduleAddEdi-pageTitle">
            {pageTitle}
          </Typography.Title>
        </Row>
        <Row className={"moduleAddEdit-subtitle"}>
          <AdminPageSubTitle
            courseId={courseIdentityId}
            courseName={decodeHtmlEntities(courseName)}
            sessionId={sessionId}
            sessionTime={sessionTimeTag}
            timeZone={sessionTimeZoneTag}
          />
        </Row>
        <Form onSubmit={submitHandler}>
          <Row gutter={[20]} className={"moduleAddEdit-form"}>
            <Col lg={16}>
              <Row gutter={[10]}>
                <Col lg={8}>
                  <Typography className={"courseAddEdit-label"}>
                    Start Date
                    <span className={"courseAddEdit-required"}>*</span>
                  </Typography>
                  <DateTimePicker
                    value={startDate}
                    minValue={TimeFormaters.HTMLDateInputFormat(
                      sessionStartTime
                    )}
                    maxValue={TimeFormaters.HTMLDateInputFormat(sessionEndTime)}
                    clickOpens={enableDateTime}
                    allowInput={enableDateTime}
                    onChange={(val) => {
                      handleStartDate(val);
                    }}
                  />
                  {(errorMessage.startDate && (
                    <Row className={"courseAddEdit-validation"}>
                      {errorMessage.startDate}
                    </Row>
                  )) ||
                    ""}
                </Col>
                <Col lg={8}>
                  <Typography className={"courseAddEdit-label"}>
                    Start Time
                    <span className={"courseAddEdit-required"}>*</span>
                  </Typography>
                  <DateTimePicker
                    value={startTime}
                    pickTime={true}
                    clickOpens={enableDateTime}
                    allowInput={enableDateTime}
                    onChange={(val) => {
                      handleStartTime(val);
                    }}
                  />
                  {(errorMessage.startTime && (
                    <Row className={"courseAddEdit-validation"}>
                      {errorMessage.startTime}
                    </Row>
                  )) ||
                    ""}
                </Col>
                <Col lg={8}>
                  <Typography className={"courseAddEdit-label"}>
                    End Time
                    <span className={"courseAddEdit-required"}>*</span>
                  </Typography>
                  <DateTimePicker
                    value={endTime}
                    pickTime={true}
                    clickOpens={enableDateTime}
                    allowInput={enableDateTime}
                    onChange={(val) => {
                      handleEndTime(val);
                    }}
                  />
                  {(errorMessage.endTime && (
                    <Row className={"courseAddEdit-validation"}>
                      {errorMessage.endTime}
                    </Row>
                  )) ||
                    ""}
                </Col>
              </Row>
              <Row gutter={[10]} style={{ marginTop: 25 }}>
                <Col lg={8}>
                  <Typography className={"courseAddEdit-label"}>
                    Location
                    <span className={"courseAddEdit-required"}>*</span>
                  </Typography>
                  <Input
                    placeholder="Location"
                    pattern="^\S.*$"
                    value={location}
                    onChange={(e) => {
                      handleLocation(e.target.value);
                    }}
                    className={"moduleAddEdit-input"}
                  />
                  {(errorMessage.location && (
                    <Row className={"courseAddEdit-validation"}>
                      {errorMessage.location}
                    </Row>
                  )) ||
                    ""}
                </Col>
                <Col lg={16}>
                  <Typography className={"courseAddEdit-label"}>
                    Zoom Link
                  </Typography>
                  <Input
                    value={zoomLink}
                    suffix={<img src={zoomIcon} />}
                    onChange={(e) => {
                      handleZoomLink(e.target.value);
                    }}
                    className={"moduleAddEdit-zoomInput"}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 25 }}>
                <Col lg={24}>
                  <Typography className={"courseAddEdit-label"}>
                    Agenda
                    <span className={"courseAddEdit-required"}>*</span>
                  </Typography>
                  <TextEditor
                    value={agenda}
                    className={style.agenda}
                    ref={textEditorRef}
                    getText={(text) => {
                      handleAgenda(text);
                    }}
                  />
                  {(errorMessage.agenda && (
                    <Row className={"courseAddEdit-validation"}>
                      {errorMessage.agenda}
                    </Row>
                  )) ||
                    ""}
                </Col>
              </Row>
            </Col>
            <Col lg={7} className={style.trainerDetailsBox}>
              <Row>
                <Col lg={14}>
                  <Typography className={"courseAddEdit-label"}>
                    TRAINER DETAIL
                  </Typography>
                </Col>
                <Col lg={5}>
                  <Button
                    type="button"
                    className={"moduleAddEdit-addTrainerButton"}
                    icon={<UserAddOutlined />}
                    onClick={(event) => {
                      handleTrainerDetails();
                    }}
                  >
                    Add Trainer
                  </Button>
                </Col>
              </Row>
              {trainersList &&
                trainersList.map((item, index) => (
                  <Row>
                    <Col>
                      <TrainerDetail
                        handleCurTrainerName={(name, index) => {
                          handleCurTrainerName(name, index);
                        }}
                        handleCurTrainerEmail={(email, index) => {
                          handleCurTrainerEmail(email, index);
                        }}
                        handleCurTrainerIsInternalTrainer={(
                          isInternalTrainer,
                          index
                        ) => {
                          handleCurTrainerIsInternalTrainer(
                            isInternalTrainer,
                            index
                          );
                        }}
                        handleClose={(show, email, index) => {
                          handleCloseTrainerDetails(show, email, index);
                        }}
                        data={item}
                        curIndex={index}
                        // eslint-disable-next-line
                        showClose={index == 0 ? false : true}
                      />
                    </Col>
                  </Row>
                ))}
              {(errorMessage.trainerList && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.trainerList}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          {(moduleId && (
            <Row style={{ marginTop: 50, marginBottom: 30 }}>
              <Button
                variant="secondary"
                className={"moduleAddEdit-delete"}
                onClick={onDeleteModule}
              >
                Delete
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={submitHandler}
                className={"moduleAddEdit-submit"}
                style={{ marginLeft: 20 }}
              >
                Update
              </Button>
            </Row>
          )) || (
            <Row style={{ marginTop: 50, marginBottom: 30 }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={submitHandler}
                className={"moduleAddEdit-submit"}
              >
                Submit
              </Button>
            </Row>
          )}
        </Form>
      </div>
      {showDeleteConfirmation && (
        <AdminConfirmationModal
          toggle={true}
          title="Delete "
          show={showDeleteConfirmation}
          leftButton="YES"
          rightButton="NO"
          handleClose={handleModalClose}
          rightButtonClick={handleModalClose}
          leftButtonClick={onDeleteRequest}
          modalContent={modalContent}
        />
      )}
    </>
  );
}

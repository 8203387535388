import style from "./waitlistUsersList.module.css";
export default function CountCard({ title, count, titleColor, countColor }) {
  return (
    <div className={style.cardCountContainer}>
      <div className={style.cardCountInnerContainer}>
        <div style={{ color: titleColor }}>{title}</div>
        <div style={{ color: countColor }} className={style.statusCount}>
          {count}
        </div>
      </div>
    </div>
  );
}

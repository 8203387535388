import axios from "axios";
import { useEffect, useState } from "react";
// import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  Alert,
  BreadCrumb,
  CourseTile,
  LearningBySkills,
  Quotation,
  SearchFilter,
  TopContainer,
} from "../../../component-library";
import config from "../../../config";
import "./SearchResultDetails.scss";
import style from "./SearchResultDetails.module.css";
import ElearningCourseTile from "../home/elearningCourseTile/ElearningCourseTile";
import LearningPathCard from "../../../component-library/antDesignLearningPathCard/LearningPathCard";
import { Spin } from "antd";

const crumbs = [
  {
    title: "Home",
    to: "/home",
  },
  { title: "Search" },
];

function SearchResultDetails() {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [ilCourseList, setIlCourseList] = useState([]);
  const [elCourseList, setElCourseList] = useState([]);
  const [learningPathList, setLearningPathList] = useState([]);
  const [hasMoreCourses, setHasMoreCourses] = useState(true);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [filters, setFilters] = useState({});
  const [queryString, setQueryString] = useState("");
  const [courseType, setCourseType] = useState("");

  const course_name = query.get("course_name");

  useEffect(() => {
    fetchLearningPathDetails();
    // eslint-disable-next-line
  }, [course_name]);

  useEffect(() => {
    let newFilter = ``;
    let filtersToStringify = {};
    if (filters && Object.keys(filters).length > 0) {
      if (filters && filters.courseType && filters.courseType !== "all") {
        filtersToStringify.courseType = filters.courseType;
        newFilter += `&courseType=${filters.courseType}`;
      }
      if (
        filters &&
        filters.languageId &&
        filters.languageId !== config.defaultLanguage.value
      ) {
        filtersToStringify.languageId = filters.languageId;
        newFilter += `&language=${filters.languageId}`;
      }
      if (
        filters &&
        filters.proficiencyId &&
        filters.proficiencyId !== config.defaultProficiency.value
      ) {
        filtersToStringify.proficiencyId = filters.proficiencyId;
        newFilter += `&proficiency=${filters.proficiencyId}`;
      }
      if (filters && filters.sortBy) {
        filtersToStringify.sortBy = filters.sortBy;
        newFilter += `&sort_by=${filters.sortBy === "newest" ? "desc" : "asc"}`;
      }
    }
    setQueryString(newFilter);
  }, [filters]);

  const handleFilter = (current) => {
    setFilters(current);
  };

  useEffect(() => {
    if (queryString) {
      setIlCourseList([]);
      setElCourseList([]);
      setLearningPathList([]);
      setHasMoreCourses(true);
    }
    fetchLearningPathDetails();
    setCourseType(new URLSearchParams(queryString).get("courseType"));
    // eslint-disable-next-line
  }, [queryString]);

  async function fetchLearningPathDetails() {
    const endPoint = `/course/search?course_name=${course_name}`;
    try {
      const searchResult = await (
        await axios.get(
          (queryString && `${endPoint}${queryString}`) || endPoint
        )
      ).data;
      const ilCoursesIds = [];
      const elCoursesIds = [];
      const learningPathIds = [];
      searchResult.data.forEach((current) => {
        if (current.type === config.courseType.instructorLed) {
          ilCoursesIds.push(current.id);
        } else if (current.type === config.courseType.eLearning) {
          elCoursesIds.push(current.id);
        } else if (current.type === config.courseType.learningPath) {
          learningPathIds.push(current.id);
        }
      });
      const ilCourses = await (
        await axios.post("/course/courseDetailsByIds", { ids: ilCoursesIds })
      ).data.data;
      const elCourses = await (
        await axios.post(
          `${config.endPoint.elearning}/course/coureseDetailsByIds`,
          { ids: elCoursesIds }
        )
      ).data.data;
      const learningPaths = await (
        await axios.post(
          `${config.endPoint.commonService}/learningpath/learningPathDetailsByIds`,
          { ids: learningPathIds }
        )
      ).data.data.learningPathDetails;
      setIlCourseList(ilCourses);
      setElCourseList(elCourses);
      setLearningPathList(learningPaths);
      setTotalResults(searchResult.size);
      setHasMoreCourses(false);
    } catch (error) {
      setAlertType("danger");
      setAlert(
        error?.data?.response || "Something went wrong, please try again"
      );
    }
  }

  return (
    <>
      <div className={"searchResult-innerContainer"}>
        <BreadCrumb crumbs={crumbs} />
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <div style={{ marginBottom: "2rem", marginTop: "2.5rem" }}>
          <span className={style.countText}>
            Showing <span style={{ color: "#4BA9F0" }}> {totalResults} </span>
            total results for{" "}
            {courseType === "all"
              ? "All"
              : courseType === "instructor-led"
                ? "INSTRUCTOR LED"
                : courseType === "e-learning"
                  ? "E-LEARNING"
                  : "All"}{" "}
            Courses
          </span>
        </div>
        <div>
          <SearchFilter upliftFilters={handleFilter} filters={filters} />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {ilCourseList && ilCourseList.length > 0 && (
            <div
              style={{
                paddingBottom: "10px",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              INSTRUCTOR LED COURSES
            </div>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}  >
            {hasMoreCourses && <Spin size="large" style={{ position: "relative", left: "40rem" }} />}
            {ilCourseList && ilCourseList.length ? (
              ilCourseList.map((current, index) => {
                return (
                  <div
                    className="il-grid"
                    style={{
                      width: "19.5rem",
                      marginBottom: "1.5rem",
                      marginRight: "0.7rem",
                    }}
                    key={index}
                  >
                    <CourseTile
                      index={index}
                      dataElement={current}
                      coursePath={`/course-details/${current.id}`}
                    />
                  </div>
                );
              })
            ) : courseType === "instructor-led" && !hasMoreCourses ? (
              <div>No instructor led courses found.</div>
            ) : (
              <></>
            )}
          </div>
          {elCourseList && elCourseList.length > 0 && (
            <div
              style={{
                paddingBottom: "10px",
                marginTop: "10px",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              ELEARNING COURSES
            </div>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {elCourseList && elCourseList.length ? (
              elCourseList.map((current, index) => {
                return (
                  <div
                    className="il-grid"
                    style={{
                      width: "19.5rem",
                      marginBottom: "1.5rem",
                      marginRight: "0.7rem",
                    }}
                    key={index}
                  >
                    <ElearningCourseTile
                      index={index}
                      dataElement={current}
                      isSearchResultPage={true}
                      coursePath={`/e-learning-courses/${current.id}`}
                    />
                  </div>
                );
              })
            ) : courseType === "e-learning" && !hasMoreCourses ? (
              <div>No elearning courses found.</div>
            ) : (
              <></>
            )}
          </div>
          {learningPathList && learningPathList.length > 0 && (
            <div
              style={{
                paddingBottom: "10px",
                marginTop: "10px",
                width: "100%",
                fontWeight: "bold",
              }}
            >
              LEARNING PATHS
            </div>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {learningPathList && learningPathList.length ? (
              learningPathList.map((current, index) => {
                return (
                  <div
                    style={{
                      width: "40rem",
                      marginBottom: "1.5rem",
                      marginRight: (index + 1) % 2 === 0 ? 0 : "1rem",
                    }}
                    key={index}
                  >
                    <LearningPathCard
                      currentLearningPath={current}
                      color="#FFFFFF"
                    ></LearningPathCard>
                  </div>
                );
              })
            ) : courseType === "learning-path" && !hasMoreCourses ? (
              <div>No learning paths found.</div>
            ) : (
              <></>
            )}
          </div>
          {courseType !== "e-learning" &&
            courseType !== "instructor-led" &&
            courseType !== "learning-path" &&
            ilCourseList &&
            ilCourseList.length === 0 &&
            elCourseList &&
            elCourseList.length === 0 &&
            learningPathList &&
            learningPathList.length === 0 &&
            !hasMoreCourses ? (
            <div>No results found</div>
          ) : (
            <></>
          )}
        </div>
      </div >

      <div style={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center"
      }}>
        <div className={style.secondContainer}>
          <LearningBySkills isFromHome={true} />
          <Quotation />
        </div>
      </div >
    </>
  );
}

export default SearchResultDetails;

import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "antd";
import style from "./importAddUserModal.module.css";
import PropTypes from "prop-types";
import { ReactComponent as AttachmentIcon } from "../../assets/icons/icon-attachment.svg";
import { ReactComponent as CloseIcon } from "./../../assets/icons/close.svg";
import { CSVLink } from "react-csv";
import axios from "axios";
import { ReactComponent as Restriction } from "../../assets/icons/private_icon.svg";
import UtilityFunctions from "../../utils/utility";
import ManualInputEmailBox from "../manualInputEmailBox/ManualInputEmailBox";
import AntDesignModal from "../antDesignModal/modal";
import config from "../../config";
import FileUploader from "../fileUploader/FileUploader";
import Validation from "../validation/Validation";
import Label from "../label/Label";
import DateTimePicker from "../dateTimePicker/DateTimePicker";
import TimeFormaters from "../../utils/timeFormatter";
import { Tooltip } from "antd";
import { truncate } from "lodash";

function ImportAddUserModal({
  page,
  showAddUserModal,
  handleCloseAddUserModal,
  setSessionData,
  setEmailIds,
  emailIds,
  sessionData,
  fileUploaded,
  courseId,
  setFileUploaded,
  sessionId,
  sessionName,
  enrolledUsers,
  sessionSize,
  setResponseSessionId,
  setShowAlert,
  setAlert,
  setAlertType,
  updatePage,
  openErrorCloseAddUser,
  learningPathData,
  showDatePicker,
}) {
  const [gotData, setGotData] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const defaultDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const [expectedCompletion, setExpectedCompletion] = useState(null);

  const fileUploaderRef = useRef();

  const headerAssignUser = [{ label: "Email", key: "email" }];
  const dataAssignUser = [
    {
      email: "abc@rakuten.com",
    },
    {
      email: "xyz@rakuten.com",
    },
  ];

  const csvReport = {
    data: dataAssignUser,
    headers: headerAssignUser,
    filename: "bulk-upload.csv",
  };

  const hint = (
    <div>
      Hint : Drag and Drop here to upload &nbsp;
      <CSVLink {...csvReport}>Spreadsheet</CSVLink> sample
    </div>
  );

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const handleExpectedCompletion = (value) => {
    setExpectedCompletion(TimeFormaters.getUtcTimeStamp(value));
  };

  const onSubmit = () => {
    // handleCloseAddUserModal();
    setDisableSubmitButton(true);
    //
    if (!(fileUploaded && emailIds)) {
      const formData = new FormData();
      if (page === "course") {
        if (fileUploaded) {
          formData.append("file", fileUploaded);
          formData.append("id", courseId);
        } else if (emailIds) {
          formData.append("email_list", emailIds);
          formData.append("id", courseId);
        }
        if (formData) {
          axios
            .post("admin/bulk/course/start", formData)
            .then((res) => {
              const filename = "bulk-upload.csv";
              UtilityFunctions.downloadCSVFile(res, filename);
              setFileUploaded(null);
              updatePage();
              setShowAlert(true);
              setAlert(
                "Upload successful, please check the CSV file for status of the users"
              );
              setAlertType("success");
              handleCloseAddUserModal();
            })
            .catch((error) => {
              if (
                error.response.data.message ===
                "There are errors in input email ids"
              ) {
                setResponseSessionId(error.response.data.session_id);
                openErrorCloseAddUser();
              } else {
                setAlert(error.response.data.message);
                setShowAlert(true);
                setAlertType("error");
                handleCloseAddUserModal();
              }
            });
        }
      } else if (page === "session") {
        if (fileUploaded) {
          formData.append("file", fileUploaded);
          formData.append("id", sessionId);
        } else if (emailIds) {
          formData.append("email_list", emailIds);
          formData.append("id", sessionId);
        }
        if (formData) {
          axios
            .post("admin/bulk/batch/start", formData)
            .then((res) => {
              const filename = "bulk-upload.csv";
              UtilityFunctions.downloadCSVFile(res, filename);
              setFileUploaded(null);
              updatePage();
              setShowAlert(true);
              setAlert(
                "Upload successful, please check the CSV file for status of the users"
              );
              setAlertType("success");

              handleCloseAddUserModal();
            })
            .catch((error) => {
              if (
                error.response.data.message ===
                "There are errors in input email ids"
              ) {
                const responseSessionId = error.response.data.session_id;
                setResponseSessionId(responseSessionId);
                openErrorCloseAddUser();
              } else {
                if (error && error.response.data.message) {
                  setAlert(error.response.data.message);
                } else {
                  setAlert("Unable to Upload Email Ids");
                }
                setAlertType("error");
                setShowAlert(true);
                handleCloseAddUserModal();
              }
            });
        }
      } else if (page === "learningPath") {
        if (fileUploaded) {
          formData.append("bulkUpload", fileUploaded);
        } else if (emailIds) {
          let formattedEmailListArray = [["Email"]];
          emailIds.split(",").forEach((email) => {
            if (email) {
              if (expectedCompletion !== null) {
                formattedEmailListArray.push([
                  email,
                  expectedCompletion +
                    config.expectedCompletionTimestamp.halfDay,
                ]);
              } else {
                //  FIXME check the logic to sendit witout expected completetion  time
                formattedEmailListArray.push([email, 0]);
              }
            }
          });
          let formattedEmailList = formattedEmailListArray
            .map((item) => {
              return item.join(",");
            })
            .join("\r\n");
          formData.append("bulkUpload", formattedEmailList);
        }
        if (formData) {
          axios
            .post(
              `${config.endPoint.commonService}/learningpath/${learningPathData?.id}/bulkEnrollment`,
              formData
            )
            .then((res) => {
              const filename = "bulk-upload.csv";
              UtilityFunctions.downloadCSVFile(res.data, filename);
              setFileUploaded(null);
              updatePage();
              setShowAlert(true);
              setAlert(
                "Upload successful, please check the CSV file for status of the users"
              );
              setAlertType("success");
              handleCloseAddUserModal();
            })
            .catch((error) => {
              if (
                error?.response?.data?.message ===
                "There are errors in input email ids"
              ) {
                const responseSessionId = error.response.data.session_id;
                setResponseSessionId(responseSessionId);
                openErrorCloseAddUser();
              } else {
                setAlertType("error");
                setShowAlert(true);
                if (error && error.response.data.message) {
                  setAlert(error.response.data.message);
                } else {
                  setAlert("Unable to Upload Email Ids");
                }
                handleCloseAddUserModal();
              }
            });
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      axios
        .get(`${config.endPoint.instructorLedNew}/batch`, {
          params: {
            course_id: courseId,
            upcoming_available_public_batches: true,
          },
        })
        .then((res) => {
          setSessionData(res.data.data);
          setGotData(true);
        })
        .catch(function (error) {});
    };

    if (page === "course") {
      fetchData();
    }
  }, []);

  function conditionRender() {
    if (fileUploaded) {
      return (
        <div className={style.fileBox}>
          <span>
            <AttachmentIcon />
          </span>
          <span className={style.fileText}>{fileUploaded.name}</span>
          <span
            style={{ cursor: "pointer", color: "black" }}
            onClick={() => fileUploaderRef?.current?.clearFile()}
          >
            <CloseIcon className={style.removeFile} />
          </span>
        </div>
      );
    }
  }

  function disclaimerMessage() {
    if (page === "course") {
      return (
        <div className={style.disclaimerMessage}>
          *If public session seats are available, user will be auto enrolled by
          order of this course. If no seats available then users are put in
          course waitlist. Course origin country will affect enrolment of users
          directly to available public session.
        </div>
      );
    } else if (page === "session") {
      return (
        <div className={style.disclaimerMessage}>
          *Users will automatically be enrolled by order in this session if
          seats are available. If no seats available, then user is put in the
          course waitlist.
        </div>
      );
    }
  }
  let validation = "";
  function validationFileString() {
    if (fileUploaded && emailIds) {
      validation = ` You have added both file and email id input. Remove one of them to submit`;
      return true;
    } else {
      validation = "";
      return false;
    }
  }

  function titleDynamic() {
    if (page === "course") {
      if (gotData && sessionData.length === 0) {
        return (
          <div className={style.textBox}>No public sessions available</div>
        );
      } else {
        if (gotData && sessionData) {
          const publicCourses = sessionData.filter(
            (course) => course.private === false
          );

          if (publicCourses.length === 0) {
            return (
              <div className={style.textBox}>No public sessions available </div>
            );
          } else {
            const currData = sessionData.filter(
              (data) => data["private"] === false
            );
            return (
              <div className={style.textBox}>
                {currData.map((session, index) => (
                  <div>
                    <span>
                      {" "}
                      Public Session {index + 1}:
                      <span className={style.boldSubtitle}>
                        {" "}
                        {session["id"]}
                      </span>{" "}
                      -{" "}
                      <Tooltip title={session["batch_name"]}>
                        {truncate(session["batch_name"], { length: 30 })}
                      </Tooltip>{" "}
                      (Enrolled user:{" "}
                      <span className={style.boldSubtitle}>
                        {session["counter"]}/{session["size"]}
                      </span>
                      )*
                    </span>
                  </div>
                ))}
              </div>
            );
          }
        }
      }
    } else if (page === "session") {
      return (
        <div className={style.textBox}>
          <span>
            <Restriction />{" "}
            <span className={style.boldSubtitle}>{sessionId} </span>-{" "}
            {sessionName} (Enrolled user:{" "}
            <span className={style.boldSubtitle}>
              {enrolledUsers}/{sessionSize}
            </span>
            )*
          </span>
        </div>
      );
    } else if (page === "learningPath") {
      return (
        <div
          className={`${style.textBox} ${
            !learningPathData.isPublic
              ? style.privateSession
              : style.publicSession
          }`}
        >
          {learningPathData?.title}
        </div>
      );
    }
  }

  function titleModal() {
    if (page === "course") {
      return <span>Course</span>;
    } else if (page === "learningPath") {
      return <span>Learning Path</span>;
    } else {
      return <span>Session</span>;
    }
  }

  const modalTitle = () => {
    return (
      <>
        <Row className={style.header} closeButton>
          <Col>
            <div className={style.title}>
              <span className={style.boldTitle}>Import </span>or{" "}
              <span className={style.boldTitle}>Add</span> Users to the{" "}
              {titleModal()}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const getContent = () => {
    return (
      <>
        <div className={style.functionalBox}>
          {titleDynamic()}
          <Row className={style.innerBox}>
            <Col>
              <Row className={style.leftTitle}>Import and Excel/CSV file</Row>
              <Row className={style.leftBox}>
                <FileUploader
                  ref={fileUploaderRef}
                  fName=""
                  upliftFileDetails={({ fileData, error }) => {
                    if (error) {
                      setErrorMessage(error);
                    } else {
                      setErrorMessage(null);
                      setFileUploaded(fileData);
                    }
                  }}
                  showFileName={false}
                  hint={hint}
                />
                {(errorMessage && (
                  <Validation
                    message={errorMessage}
                    st={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 0,
                    }}
                  />
                )) ||
                  ""}
                {conditionRender()}
              </Row>
            </Col>

            <span className={style.circleBox}>OR</span>
            <Col>
              <Row className={style.leftTitle}>
                Add user email ids separated by ","
              </Row>
              <Row className={style.rightBox}>
                <Col className={style.inputArea}>
                  <Form className={style.p24}>
                    <ManualInputEmailBox
                      upliftEmails={({ fileData }) => {
                        setEmailIds(fileData);
                      }}
                    />
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {disclaimerMessage()}
        {validationFileString() && (
          <div className={style.alertText}>{validation}</div>
        )}
        {showDatePicker ? ( //
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "1rem",
            }}
          >
            <span style={{ marginLeft: "2rem", padding: "0.7rem" }}>
              <Label title="Expected Completion Date" />
            </span>
            <span style={{ marginLeft: "1rem", width: "12.5rem" }}>
              <DateTimePicker
                minValue={tomorrow}
                allowInput={true}
                clickOpens={true}
                onChange={(expectedCompletion) => {
                  handleExpectedCompletion(expectedCompletion);
                }}
              />
            </span>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <AntDesignModal
      title={modalTitle()}
      show={showAddUserModal}
      okButtonText="SUBMIT"
      canceButtonText="CANCEL"
      onCancel={handleCloseAddUserModal}
      onOk={onSubmit}
      disableOkButton={
        (!fileUploaded && !emailIds) ||
        (fileUploaded && emailIds) ||
        disableSubmitButton
      }
      description={getContent()}
      width={1096}
    />
  );
}

ImportAddUserModal.propTypes = {
  page: PropTypes.string,
  showAddUserModal: PropTypes.bool,
  handleCloseAddUserModal: PropTypes.func,
  setSessionData: PropTypes.func,
  setEmailIds: PropTypes.func,
  setFileUploaded: PropTypes.func,
  emailIds: PropTypes.string,
  sessionData: PropTypes.func,
  onSubmit: PropTypes.func,
  fileUploaded: PropTypes.object,
  courseId: PropTypes.number,
  sessionId: PropTypes.string,
  sessionName: PropTypes.string,
  enrolledUsers: PropTypes.number,
  sessionSize: PropTypes.number,
  setResponseSessionId: PropTypes.func,
  setAlert: PropTypes.func,
  setAlertType: PropTypes.func,
  updatePage: PropTypes.func,
  openErrorCloseAddUser: PropTypes.func,
  hiddenFileInput: PropTypes.shape({
    component: PropTypes.instanceOf(React.Component),
  }),
};

export default ImportAddUserModal;

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import style from "./roundProgressBarInstructorLed.module.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { UserContext } from "../../context/UserContext";

function RoundProgressBar({
  index,
  userStatus,
  occupiedSeats,
  batchSize,
  isPrivate,
  otherStyle,
  statusCode,
}) {
  const [progressPercent, setprogressPercent] = useState(0);
  const { userConfig } = useContext(UserContext);
  const { primary, secondary } = userConfig;

  let backgroundColor = primary;
  let trailColor = "#eee";

  if (isPrivate) {
    backgroundColor = secondary;
  }
  const blackColor = "#000000";
  const textInside = `booked`;

  let status = userStatus;
  if (status === "completed") {
    trailColor = "#00B900";
    backgroundColor = "#00B900";
  }
  if (!userStatus && !occupiedSeats && !batchSize) {
    status = "Join Waiting List!";
  }

  if (status === "Completed") {
    backgroundColor = "#00B900";
  } else if (status === "In Progress") {
    backgroundColor = secondary;
  } else if (status === "Get Started") {
    backgroundColor = "#D1D1D1";
  }
  useEffect(() => {
    let progress = 100;

    if (userStatus === "Not Enrolled" || (batchSize && occupiedSeats)) {
      progress = (occupiedSeats / batchSize) * 100;
    }
    if (!occupiedSeats) progress = 0;

    if (
      ["enrolled", "waitlist", "interested", "Completed"].includes(userStatus)
    ) {
      progress = 100;
    }

    if (status === "Join Waiting List!") progress = 100;
    setprogressPercent(progress);
  }, [userStatus, occupiedSeats, batchSize, status]);

  return (
    <div>
      {!status || (statusCode && ![1, 2, 3, 4, 5].includes(statusCode)) ? (
        <div className={`${style.circleProgressBar} ${otherStyle}`}>
          <CircularProgressbarWithChildren
            key={index}
            value={progressPercent}
            styles={buildStyles({
              textColor: backgroundColor,
              pathColor: backgroundColor,
              trailColor,
            })}
            strokeWidth={5}
          >
            <div>
              <span className={style.occupiedSeats}>{occupiedSeats}</span>
              <span style={{ color: blackColor }}>/</span>
              <span className={style.batchSize}>{batchSize}</span>
            </div>
            <div className={style.textInside}>{textInside}</div>
          </CircularProgressbarWithChildren>
        </div>
      ) : (
        <div className={`${style.circleProgressBar} ${otherStyle}`}>
          <CircularProgressbarWithChildren
            key={index}
            value={progressPercent}
            strokeWidth={5}
            styles={buildStyles({
              textColor: backgroundColor,
              pathColor: backgroundColor,
              trailColor,
            })}
          >
            <div className={style.userStatus}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </div>
          </CircularProgressbarWithChildren>
        </div>
      )}
    </div>
  );
}

RoundProgressBar.propTypes = {
  occupiedSeats: PropTypes.any,
  batchSize: PropTypes.any,
  isPrivate: PropTypes.bool,
  index: PropTypes.number,
  userStatus: PropTypes.string,
  otherStyle: PropTypes.object,
};

export default RoundProgressBar;

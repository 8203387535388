import privateIcon from "../../../assets/icons/private_icon.svg";
import locked from "../../../assets/icons/lock_icon.svg";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import style from "./calendar.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function formatEventContent(eventInfo, userConfig) {
  console.log(userConfig, "...userConfig");
  const startTime = moment(eventInfo.event.start).format("hh:mm A");
  const endTime = moment(eventInfo.event.end).format("hh:mm A");

  return (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-disabled">
          {eventInfo.event.extendedProps.courseName}
        </Tooltip>
      }
    >
      <Row
        fluid="true"
        className={`${style.outer} calendarOuter`}
        style={{
          backgroundColor:
            (eventInfo.event.extendedProps.private &&
              `${userConfig.secondary}30`) ||
            `${userConfig.primary}30`,
          cursor: "pointer",
        }}
      >
        <Col ref={eventInfo.event.extendedProps.target}>
          <Row fluid="true">
            {eventInfo &&
              eventInfo.event &&
              eventInfo.event.extendedProps &&
              eventInfo.event.extendedProps.private && (
                <Col className={style.privateIcon}>
                  <img src={privateIcon} alt="" className={style.image} />
                </Col>
              )}
            <Col
              className={style.text}
              style={{
                color:
                  (eventInfo.event.extendedProps.private &&
                    userConfig.secondary) ||
                  userConfig.primary,
              }}
            >
              {eventInfo.event.title}
            </Col>
          </Row>
          <Row fluid="true">
            {eventInfo &&
              eventInfo.event &&
              eventInfo.event.extendedProps &&
              eventInfo.event.extendedProps.locked && (
                <Col className={style.imageContainer}>
                  <img
                    className={style.image}
                    src={locked}
                    alt=""
                    style={{ marginLeft: "0.313rem" }}
                  />
                </Col>
              )}
            <Col
              className={style.date}
              style={{
                color:
                  (eventInfo.event.extendedProps.private &&
                    userConfig.secondary) ||
                  userConfig.primary,
              }}
            >
              {startTime}-{endTime}
            </Col>
          </Row>
        </Col>

        {eventInfo &&
          eventInfo.event &&
          eventInfo.event.extendedProps &&
          eventInfo.event.extendedProps.enrollmentStatus && (
            <div className={`${style.line} calendarLine`}></div>
          )}
      </Row>
    </OverlayTrigger>
  );
}

export { formatEventContent };

import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./primaryOutlineButton.scss";

function PrimaryOutlineButton({
  label,
  handleClick,
  icon,
  otherStyle,
  isDisabled,
}) {
  return (
    <>
      {isDisabled && (
        <Button
          bsPrefix={`${"primaryOutlineButton"} ${otherStyle}`}
          className={"primaryOutlineButton"}
          type="button"
          onClick={handleClick}
          disabled
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon && (
            <>
              <span className={"iconStyle"}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}
      {!isDisabled && (
        <Button
          bsPrefix={`${"primaryOutlineButton"} ${otherStyle}`}
          className={"primaryOutlineButton"}
          type="button"
          onClick={handleClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>
            <span style={{ paddingBottom: "5px" }}>{label}</span>{" "}
            {icon && (
              <>
                <span className={"iconStyle"}>
                  <img src={icon} alt="icon" />
                </span>
              </>
            )}
          </span>
        </Button>
      )}
    </>
  );
}

PrimaryOutlineButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  otherStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default PrimaryOutlineButton;

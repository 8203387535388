import { useContext } from "react";
// import { KeycloakContext } from "../../context/KeycloakContext";
import { Redirect } from "react-router-dom";
import { LoginContext } from "../../context/LoginContext";

const RenderOnAuthenticated = ({ children }) => {
  const { tokenData } = useContext(LoginContext);
  return tokenData ? children : <Redirect to={{ pathname: "/" }} />;
};

export default RenderOnAuthenticated;

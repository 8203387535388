import axios from "axios";
import { useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Alert, Breadcrumb, Tabs, Button, Input } from "antd";
import config from "../../../config";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";
import ElearningCourseTile from "../../user/home/elearningCourseTile/ElearningCourseTile";
import "./AntdSelectCourses.scss";
import { UserContext } from "../../../context/UserContext";
import GetMyTeamModal from "../viewYourRecommendation/AntdGetMyTeamModal";
import { CourseFilter, CourseTile } from "../../../component-library";
import LearningPathCard from "../../../component-library/antDesignLearningPathCard/LearningPathCard";
import TabbedFilter from "../../../component-library/antDesignTabbedFilter/antDesignTabbedFilter";
import { CourseContext } from "../../../context/CourseContext";

const { elearningCourseList, instructorLed } = config;

function SelectCourses() {
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [ilPageNumber, setIlPageNumber] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const [ilCourseList, setIlCourseList] = useState([]);
  const { TabPane } = Tabs;
  const [hasMoreCourses, setHasMoreCourses] = useState(true);
  const [hasMoreIlCourses, setHasMoreIlCourses] = useState(true);
  const [hasMoreLearningPath, setHasMoreLearningPath] = useState(true);
  const [learningPathTotalCount, setLearningPathTotalCount] = useState(0);
  const [learningPathPageNumber, setLearningPathPageNumber] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [searchString, setSearchString] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [checkedState, setCheckedState] = useState([]);
  const [checkedILState, setCheckedILState] = useState([]);
  const [checkedLPState, setCheckedLPState] = useState([]);
  const [showSelectUserModal, setShowSelectUserModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [recommendedData, setRecommendedData] = useState([]);
  const { userId, userConfig } = useContext(UserContext);
  const [teamData, setTeamData] = useState([]);
  const [pathList, setPathList] = useState([]);
  const [sortByColumn, setSortByColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const isMobile = window.innerWidth < 786;
  const [filters, setFilters] = useState({});
  const [initialFilter, setInitialFilter] = useState({});
  const [queryString, setQueryString] = useState("");
  const [elQueryString, setElQueryString] = useState("");
  const [reset, setReset] = useState(false);
  const { parentCatagoryList } = useContext(CourseContext);
  const [selectedCatagory, setselectedCatagory] = useState("0");
  const _ = require("lodash");

  const crumbs = [
    {
      title: (
        <a href="/Manager" style={{ color: userConfig.primary }}>
          Manager Dashboard
        </a>
      ),
    },
    {
      title: (
        <a
          href="/Manager/ViewYourRecommendation"
          style={{ color: userConfig.primary }}
        >
          View Your Recommendation
        </a>
      ),
    },
    {
      title: "Select Courses",
    },
  ];

  const handelCardClick = (cardIndex) => {
    setAlert("");
    setAlertType("");
    if (checkedState && checkedState.length) {
      if (checkedState.includes(cardIndex)) {
        const checkedArray = [...checkedState];
        checkedArray.splice(checkedArray.indexOf(cardIndex), 1);
        setCheckedState(checkedArray);
      } else {
        setCheckedState([...checkedState, cardIndex]);
      }
    } else {
      setCheckedState([cardIndex]);
    }
  };

  const handelILCardClick = (cardIndex) => {
    setAlert("");
    setAlertType("");
    if (checkedILState && checkedILState.length) {
      if (checkedILState.includes(cardIndex)) {
        const checkedArray = [...checkedILState];
        checkedArray.splice(checkedArray.indexOf(cardIndex), 1);
        setCheckedILState(checkedArray);
      } else {
        setCheckedILState([...checkedILState, cardIndex]);
      }
    } else {
      setCheckedILState([cardIndex]);
    }
  };

  const handleLPCardClick = (cardIndex) => {
    setAlert("");
    setAlertType("");
    if (checkedLPState && checkedLPState.length) {
      if (checkedLPState.includes(cardIndex)) {
        const checkedArray = [...checkedLPState];
        checkedArray.splice(checkedArray.indexOf(cardIndex), 1);
        setCheckedLPState(checkedArray);
      } else {
        setCheckedLPState([...checkedLPState, cardIndex]);
      }
    } else {
      setCheckedLPState([cardIndex]);
    }
  };

  const prepareRecommendedData = () => {
    let iLData = [];
    let eLData = [];
    let lpData = [];
    if (checkedILState?.length) {
      iLData =
        checkedILState &&
        checkedILState?.map((id) => ({
          courseId: id,
          courseType: "IL",
          recommendedBy: userId,
        }));
    }
    if (checkedState?.length) {
      eLData =
        checkedState &&
        checkedState?.map((id) => ({
          courseId: id,
          courseType: "EL",
          recommendedBy: userId,
        }));
    }
    if (checkedLPState?.length) {
      lpData =
        checkedLPState &&
        checkedLPState?.map((id) => ({
          courseId: id,
          courseType: "LP",
          recommendedBy: userId,
        }));
    }
    setRecommendedData([...iLData, ...eLData, ...lpData]);
  };
  useEffect(() => {
    setFilters({});
    setInitialFilter({});
  }, [currentTab]);

  useEffect(() => {
    prepareRecommendedData();
    // eslint-disable-next-line
  }, [checkedILState, checkedState, checkedLPState]);

  useEffect(() => {
    let newFilter = ``;
    let filtersToStringify = {};
    if (filters && Object.keys(filters).length > 0) {
      if (
        filters &&
        filters.parentCategoryId &&
        filters.parentCategoryId !== config.defaultParentCategory.value
      ) {
        filtersToStringify.parentCategoryId = filters.parentCategoryId;
        newFilter +=
          currentTab == 0
            ? `&parent_category_id=${filters.parentCategoryId}`
            : `&parentCategoryId=${filters.parentCategoryId}`;
      }
      if (
        filters &&
        filters.categoryId &&
        filters.categoryId !== config.defaultCategory.value
      ) {
        filtersToStringify.categoryId = filters.categoryId;
        newFilter +=
          currentTab == 0
            ? `&category_id=${filters.categoryId}`
            : `&categoryId=${filters.categoryId}`;
      }
      if (filters && filters.subCategoryId) {
        filtersToStringify.subCategoryId = filters.subCategoryId;
        newFilter +=
          currentTab == 0
            ? `&subcategory_id=${filters.subCategoryId}`
            : `&subcategoryId=${filters.subCategoryId}`;
      }
      if (filters && filters.sortBy) {
        filtersToStringify.sortBy = filters.sortBy;
        newFilter += `&sort_by=${filters.sortBy}`;
      }
      if (
        filters &&
        filters.languageId &&
        filters.languageId !== config.defaultLanguage.value
      ) {
        filtersToStringify.languageId = filters.languageId;
        newFilter += `&language=${filters.languageId}`;
      }
      if (filters && filters.courseProviderId) {
        filtersToStringify.courseProviderId = filters.courseProviderId;
        newFilter +=
          currentTab == 0
            ? `&courseProvider=${filters.courseProviderId}`
            : `&courseProviderId=${filters.courseProviderId}`;
      }
      if (
        filters &&
        filters.proficiencyId &&
        filters.proficiencyId !== config.defaultProficiency.value
      ) {
        filtersToStringify.proficiencyId = filters.proficiencyId;
        newFilter += `&proficiency=${filters.proficiencyId}`;
      }
    }
    if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
      currentTab == 0 ? setIlPageNumber(1) : setPageNumber(1);
    }
    currentTab == 0 ? setQueryString(newFilter) : setElQueryString(newFilter);
  }, [initialFilter, filters]);

  const handleFilter = (current) => {
    setFilters(current);
    // fetchCourseDetails(false)
  };

  useEffect(() => {
    if (queryString) {
      setIlPageNumber(1);
      setIlCourseList([]);
      setHasMoreIlCourses(true);
    }
    fetchILCourses();
  }, [queryString]);

  useEffect(() => {
    if (currentTab == 0) {
      fetchILCourses();
    } else if (currentTab == 1) {
      fetchCourseDetails();
    } else {
      fetchLearningPaths();
    }
  }, [searchString]);

  useEffect(() => {
    if (elQueryString) {
      setPageNumber(1);
      setCourseList([]);
      setHasMoreCourses(true);
      fetchCourseDetails();
    }
  }, [elQueryString]);

  const fetchCourseDetails = () => {
    setHasMoreCourses(true);
    const endPoint = `${config.endPoint.elearning}/course?pageSize=${elearningCourseList.pagination.pageSize}&pageNumber=${pageNumber}&courseStatus=active`;
    const elConfiguration = {
      params: {
        courseName: searchString,
      },
    };
    axios
      .get(
        elQueryString ? `${endPoint}${elQueryString}` : endPoint,
        elConfiguration
      )
      .then((response) => {
        if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
          setPageNumber(1);
          setCourseList([]);
          setInitialFilter(filters);
        }
        setCourseList((current) => [...current, ...response.data.data]);
        setPageNumber((pageNumber) => pageNumber + 1);
        if (!response.data.data.length) {
          setHasMoreCourses(false);
        }
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
      });
  };

  const fetchILCourses = () => {
    setHasMoreCourses(true);
    const endPoint = `/course/list?page_size=${instructorLed.pagination.pageSize}&page_number=${ilPageNumber}`;
    const ilConfiguration = {
      params: {
        searchString: searchString,
      },
    };
    axios
      .get(
        queryString ? `${endPoint}${queryString}` : endPoint,
        searchString && ilConfiguration
      )
      .then((response) => {
        if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
          setIlPageNumber(1);
          setIlCourseList([]);
          setInitialFilter(filters);
        }
        setIlCourseList((current) => [...current, ...response.data.data]);
        setIlPageNumber((ilPageNumber) => ilPageNumber + 1);
        if (!response.data.data.length) {
          setHasMoreIlCourses(false);
        }
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("error");
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
      });
  };

  const fetchLearningPaths = (update) => {
    const configuration = {
      headers: { isadminpage: false },
      params: {
        learningPathStatus: "active",
        pageNumber: update ? learningPathPageNumber : 1,
        pageSize: 20,
        searchString: searchString,
        sortBy: sortByColumn,
        sort: sortOrder,
        parentCategoryId: String(selectedCatagory) !== "0" && selectedCatagory,
      },
    };
    setHasMoreLearningPath(true);
    axios
      .get(`${config.endPoint.commonService}` + "/learningpath", configuration)
      .then((response) => {
        const data = response?.data?.data;
        const size = response?.data?.data.length;
        setLearningPathTotalCount(size);
        if (update) {
          setPathList((currentLearningPaths) => [
            ...currentLearningPaths,
            ...data,
          ]);
        } else {
          setPathList(data);
        }
        setLearningPathPageNumber(
          (learningPathPageNumber) => learningPathPageNumber + 1
        );
        if (data?.length === 0) {
          setHasMoreLearningPath(false);
        }
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertType("danger");
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
      });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { columnKey, order } = sorter;
    if (order) {
      setSortByColumn(columnKey);
      setSortOrder(order === "ascend" ? "asc" : "desc");
    }
  };

  useEffect(() => {
    handleTableChange(0, 0, {
      order: "descend",
      columnKey: "updatedTimestamp",
    });
  }, []);

  const handleClick = () => {
    if (
      checkedState?.length > 0 ||
      checkedILState?.length > 0 ||
      checkedLPState?.length > 0
    ) {
      getAllTeamMembers();
      setShowSelectUserModal(true);
    } else {
      setShowAlert(true);
      setAlertType("error");
      setAlert("Please select at least one course to recommend");
    }
  };

  const handleCloseAddUserModal = () => {
    setShowSelectUserModal(false);
  };

  const onPageFilterChange = (value) => {
    setIlCourseList([]);
    setCourseList([]);
    setPathList([]);
    setSearchString("");
    setPageNumber(1);
    setIlPageNumber(1);
    setLearningPathPageNumber(1);
    switch (value) {
      case "1":
        setCurrentTab(0);
        setIlPageNumber(1);
        fetchILCourses();
        break;
      case "2":
        setCurrentTab(1);
        setPageNumber(1);
        fetchCourseDetails();
        break;
      case "3":
        setCurrentTab(2);
        setLearningPathPageNumber(1);
        fetchLearningPaths();
        break;
      default:
        setCurrentTab(0);
        setIlPageNumber(1);
        fetchILCourses();
        break;
    }
  };

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setShowAlert(true);
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const getAllTeamMembers = () => {
    axios
      .get(`${config.endPoint.userService}/api/user/getMyTeam`)
      .then((response) => {
        setTeamData(response.data.data);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setAlertType("error");
      });
  };

  const toggleFilter = () => {
    const tabItems = [
      {
        key: `1`,
        label: "Instructor Led",
      },
      {
        key: `2`,
        label: "E-Learning",
      },
      {
        key: `3`,
        label: "Learning Path",
      },
    ];

    return (
      <Tabs
        onChange={(e) => onPageFilterChange(e)}
        className={"selectcourses-tabs"}
        items={tabItems}
        tabBarGutter={92}
      >
        {/* <TabPane tab="Instructor Led" key="1"></TabPane>
        <TabPane
          style={{ marginLeft: "3rem" }}
          tab="E-Learning"
          key="2"
        ></TabPane>
        <TabPane
          style={{ marginLeft: "3rem" }}
          tab="Learning Path"
          key="3"
        ></TabPane> */}
      </Tabs>
    );
  };

  const handleCatagoryChange = (key) => {
    setPathList([]);
    setLearningPathPageNumber(0);
    setselectedCatagory(key);
    fetchLearningPaths(true);
  };

  const handleSearch = debounce((searchText) => {
    setPageNumber(1);
    setCourseList([]);
    setIlCourseList([]);
    setIlPageNumber(1);
    setSearchString(searchText);
  }, 1000);

  const renderLearningPathList = () => {
    return (
      <div className="lp-courses-container">
        <div className="lp-filter-container">
          <TabbedFilter
            handleCatagoryChange={handleCatagoryChange}
            catagories={parentCatagoryList}
            defaultTab={selectedCatagory}
            tabBarGutter={92}
          />
        </div>
        {!isMobile ? (
          <Row
            gutter={[40, 24]}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <InfiniteScroll
              dataLength={pathList.length}
              next={() => fetchLearningPaths(true)}
              hasMore={hasMoreLearningPath}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                overflow: "hidden",
                width: "100%",
              }}
              scrollThreshold={0.7}
              // scrollableTarget="scrollableDiv"
            >
              {pathList?.length ? (
                pathList.map((currentLearningPath) => {
                  return (
                    <Col
                      span={12}
                      key={`learning - path - ${currentLearningPath.id} `}
                      style={{ marginBottom: "1rem" }}
                    >
                      <LearningPathCard
                        currentLearningPath={currentLearningPath}
                        color="#FFFFFF"
                        showCheckIcon={true}
                        isNewTab={true}
                        cardClicked={handleLPCardClick}
                        checkedLPState={checkedLPState}
                      />
                    </Col>
                  );
                })
              ) : (
                <div
                  style={{
                    fontFamily: "RakutenRoundSemiBold",
                    fontSize: "1rem",
                    width: "17rem",
                    textAlign: "center",
                  }}
                >
                  No learning paths available{" "}
                </div>
              )}
            </InfiniteScroll>
            {hasMoreLearningPath ? (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  textAlign: "center",
                }}
              >
                <b>Loading...</b>
              </div>
            ) : (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  margin: "1rem",
                  textAlign: "center",
                }}
              >
                <b>
                  You've reached the end of the list. All the learning paths are
                  listed above.
                </b>
              </div>
            )}
          </Row>
        ) : (
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
              marginLeft: "31rem",
            }}
          >
            {pathList?.length ? (
              pathList.map((currentLearningPath) => {
                return (
                  <Col
                    className="lp-card-listing-page"
                    key={`learning-path-${currentLearningPath.id}`}
                  >
                    <LearningPathCard
                      currentLearningPath={currentLearningPath}
                      isNewTab={true}
                      color="#FFFFFF"
                      showCheckIcon={true}
                      cardClicked={handleLPCardClick}
                      onChange={handleTableChange}
                      checkedLPState={checkedLPState}
                    />
                  </Col>
                );
              })
            ) : (
              <div style={{ fontFamily: "RakutenRoundRegular" }}>
                No learning paths available{" "}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="selectcourses-container">
      {showAlert && (
        <Alert
          className={"selectcourses-alert"}
          message={alert}
          type={alertType}
          closable
          style={{ marginTop: "2rem" }}
        />
      )}
      <Breadcrumb
        className="selectcourses-crumbs"
        items={crumbs}
        style={{ marginTop: 80 }}
      />
      <div className={"selectcourse-toggle"}>
        {toggleFilter()}
        <div
          className="selectcourses-search"
          style={{ display: "flex", flexDirection: "row", marginTop: 0 }}
        >
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              height: "2.7rem",
              width: "10rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
              marginRight: 16,
            }}
          />
          <div>
            <Button
              type="button"
              className="recommendto-button"
              onClick={handleClick}
            >
              Recommend To
            </Button>
          </div>
        </div>
      </div>
      <div ilCourseList={ilCourseList}>
        {currentTab === 0 ? (
          <div className="course-list">
            <div style={{ width: "81rem", margin: "auto" }}>
              <CourseFilter
                upliftFilters={handleFilter}
                filters={filters}
                reset={reset}
                isInstuctorLed={true}
                isRecommendation={true}
              />
            </div>
            <InfiniteScroll
              dataLength={ilCourseList.length}
              next={() => fetchILCourses()}
              hasMore={hasMoreIlCourses}
              scrollThreshold={0.4}
              style={{
                display: "grid",
                gridTemplateColumns: isMobile
                  ? "repeat(1,1fr)"
                  : "repeat(4,1fr)",
                flexWrap: "wrap",
                justifyContent: "space-between",
                overflow: "unset",
              }}
            >
              {ilCourseList && ilCourseList.length ? (
                _.uniqBy(ilCourseList, "id").map((current, index) => {
                  return (
                    <div
                      style={{
                        width: "19.5rem",
                        marginBottom: "1.5rem",
                        marginRight: (index + 1) % 4 === 0 ? 0 : "1rem",
                      }}
                      key={`IL ${current.id}`}
                    >
                      <CourseTile
                        dataElement={current}
                        index={index}
                        coursePath={`/course-details/${current.id}`}
                        tileType="recommendation"
                        checkedILState={checkedILState}
                        cardClicked={handelILCardClick}
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "90rem",
                    textAlign: "center",
                    paddingBottom: "1rem",
                    paddingLeft: "2rem",
                  }}
                >
                  No course available in your region for the category selected.
                </div>
              )}
            </InfiniteScroll>
            {hasMoreIlCourses && (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  margin: isMobile ? "1rem" : "",
                  textAlign: "center",
                }}
              >
                <b>Loading...</b>
              </div>
            )}
            {!hasMoreIlCourses && (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  margin: isMobile ? "1rem" : "",
                  textAlign: "center",
                }}
              >
                <b>
                  You've reached the end of the list. All the courses are listed
                  above.
                </b>
              </div>
            )}
          </div>
        ) : currentTab === 1 ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "81rem", margin: "auto" }}>
              <CourseFilter
                upliftFilters={handleFilter}
                filters={filters}
                reset={reset}
                isInstuctorLed={false}
                isRecommendation={true}
              />
            </div>
            <InfiniteScroll
              dataLength={courseList.length}
              next={fetchCourseDetails}
              hasMore={hasMoreCourses}
              scrollThreshold={0.4}
              style={{
                display: "grid",
                marginLeft: isMobile ? "30rem" : "0rem",
                gridTemplateColumns: isMobile
                  ? "repeat(1,1fr)"
                  : "repeat(4,1fr)",
                flexWrap: "wrap",
                justifyContent: "space-between",
                overflow: "unset",
              }}
            >
              {courseList && courseList.length ? (
                courseList.map((current, index) => {
                  return (
                    <div
                      style={{
                        width: "19.5rem",
                        marginBottom: "1.5rem",
                        marginRight: (index + 1) % 4 === 0 ? 0 : "1rem",
                      }}
                      key={`EL ${current.id}`}
                    >
                      <ElearningCourseTile
                        dataElement={current}
                        index={index}
                        coursePath={`/e-learning-courses/${current.id}`}
                        tileType="learningPath"
                        checkedState={checkedState}
                        cardClicked={handelCardClick}
                      />
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "90rem",
                    textAlign: "center",
                    paddingBottom: "1rem",
                    paddingLeft: "2rem",
                  }}
                >
                  No course available in your region for the category selected.
                </div>
              )}
            </InfiniteScroll>
            {hasMoreCourses && (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  margin: isMobile ? "1rem" : "",
                  textAlign: "center",
                }}
              >
                <b>Loading...</b>
              </div>
            )}
            {!hasMoreCourses && (
              <div
                style={{
                  fontFamily: "RakutenRoundRegular",
                  fontSize: 24,
                  margin: isMobile ? "1rem" : "",
                  textAlign: "center",
                }}
              >
                <b>
                  You've reached the end of the list. All the courses are listed
                  above.
                </b>
              </div>
            )}
          </div>
        ) : (
          renderLearningPathList()
        )}
      </div>

      {showSelectUserModal && (
        <GetMyTeamModal
          showModal={showSelectUserModal}
          handleClose={handleCloseAddUserModal}
          recommendedData={recommendedData}
          teamData={teamData}
          setAlert={setAlert}
          setAlertType={setAlertType}
        />
      )}
    </div>
  );
}
export default SelectCourses;

const decodeHtmlEntities = (text) => {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['#34', '"']
    ];
    
    entities.map((e,i)=>(
        text = text?.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1])
    ))

    return text;
}

export {decodeHtmlEntities}
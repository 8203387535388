import React, { useContext } from "react";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { CourseContext } from "../../context/CourseContext";

function convertToUTF(date) {
  return parseInt(Date.parse(date) / 1000);
}

export default function AntDesignRangePicker({
  minValue,
  maxValue,
  allowInput = true,
  onChange,
  disabled = false,
  placeholder,
  size,
  showPresets,
}) {
  const { userDateFormat } = useContext(CourseContext);
  const { RangePicker } = DatePicker;

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  const disabledDate = (current) => {
    const minDate = moment(minValue).startOf("day");
    const maxDate = moment(maxValue || "2050").endOf("day");
    return current.isAfter(maxDate) || current.isBefore(minDate);
  };

  const handleChange = (range) => {
    let currentStartDate = null;
    let currentEndDate = null;
    if (range) {
      const startDate = range[0].startOf("day");
      const endDate = range[1].endOf("day");
      currentStartDate = convertToUTF(startDate);
      currentEndDate = convertToUTF(endDate);
    }
    onChange(currentStartDate, currentEndDate);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <RangePicker
        size={size || "large"}
        disabledDate={disabledDate}
        disabled={[disabled, disabled]}
        inputReadOnly={!allowInput}
        onChange={handleChange}
        placeholder={placeholder}
        presets={showPresets ? rangePresets : null}
        format={userDateFormat || "DD/MM/YYYY"}
      />
    </Space>
  );
}

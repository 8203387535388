import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { Layout } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  Alert,
  CourseFilter,
  HeroBannerCousre,
  LearningBySkills,
  Quotation,
} from "../../../component-library";
import config from "../../../config";
import UtilityFunctions from "../../../utils/utility";
import ElearningCourseTile from "../home/elearningCourseTile/ElearningCourseTile";
import ELearningPlatform from "../home/eLearningPlatform/ELearningPlatform";
import "./ElearningUserCourseList.scss";
import PageFooter from "../common/PageFooter";
import { UserContext } from "../../../context/UserContext";

const crumbs = [
  {
    title: "Home",
    to: "/home",
  },
  { title: "E-Learning Courses" },
];
const { elearningCourseList } = config;

function ElearningUserCourseList(props) {
  const { userConfig } = useContext(UserContext);
  const { primary } = userConfig;

  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [pageNumber, setPageNumber] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const [hasMoreCourses, setHasMoreCourses] = useState(true);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [filters, setFilters] = useState({});
  const [reset, setReset] = useState(false);
  const [initialFilter, setInitialFilter] = useState({});
  const [queryString, setQueryString] = useState("");
  const [fullCourseList, setfullCourseList] = useState([]);
  const [fullCourseListCount, setFullCourseListCount] = useState(null);
  const isMobile = window.innerWidth < 786;
  // first time when filter passed from url.
  const parentCategoryId =
    props.match.params.parentCategoryId === "all"
      ? undefined
      : props.match.params.parentCategoryId;
  const categoryId =
    props.match.params.categoryId === "all"
      ? undefined
      : props.match.params.categoryId;

  const queryFilter = async function () {
    const obj = {};
    if (parentCategoryId) {
      obj.parentCategoryId = parentCategoryId;
    }
    if (categoryId) {
      obj.categoryId = categoryId;
    }
    obj.sortBy = "newest";
    setFilters(obj);
  };

  // eslint-disable-next-line
  const optimizedSearch = useCallback(
    UtilityFunctions.debounce(queryFilter, 500),
    []
  );

  useEffect(() => {
    optimizedSearch();
  }, [parentCategoryId, categoryId, optimizedSearch]);

  // when data comes from courseFilter and first load.
  useEffect(() => {
    let newFilter = ``;
    let filtersToStringify = {};

    if (filters && Object.keys(filters).length > 0) {
      if (
        filters &&
        filters.parentCategoryId &&
        filters.parentCategoryId !== config.defaultParentCategory.value
      ) {
        filtersToStringify.parentCategoryId = filters.parentCategoryId;
        newFilter += `&parentCategoryId=${filters.parentCategoryId}`;
      }
      if (
        filters &&
        filters.categoryId &&
        filters.categoryId !== config.defaultCategory.value
      ) {
        filtersToStringify.categoryId = filters.categoryId;
        newFilter += `&categoryId=${filters.categoryId}`;
      }
      if (filters && filters.subCategoryId) {
        filtersToStringify.subCategoryId = filters.subCategoryId;
        newFilter += `&subcategoryId=${filters.subCategoryId}`;
      }
      if (filters && filters.courseProviderId) {
        filtersToStringify.courseProviderId = filters.courseProviderId;
        newFilter += `&courseProviderId=${filters.courseProviderId}`;
      }
      if (filters && filters.sortBy) {
        filtersToStringify.sortBy = filters.sortBy;
        newFilter += `&sort=${filters.sortBy === "oldest" ? "asc" : "desc"}`;
      }
    }
    if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
      setPageNumber(1);
    }
    setQueryString(newFilter);
  }, [initialFilter, filters]);

  const handleFilter = (current) => {
    setFilters(current);
  };

  const updateSuperCatergory = (id) => {
    setReset(true);
    setFilters({ parentCategoryId: id, sortBy: "newest" });
  };

  const getParentCategoryById = (id) => {
    const categories = [
      {
        id: 1,
        parentCategoryName: "Professional",
        parentCategoryAcronym: "PROF",
      },
      {
        id: 2,
        parentCategoryName: "Soft Skills",
        parentCategoryAcronym: "SOSK",
      },
      {
        id: 3,
        parentCategoryName: "Management & Leadership",
        parentCategoryAcronym: "MGLS",
      },
      {
        id: 4,
        parentCategoryName: "Technical",
        parentCategoryAcronym: "TECH",
      },
      {
        id: "all",
        parentCategoryName: "All",
        parentCategoryAcronym: "All",
      },
    ];

    return categories.find((c) => c.id === id);
  };

  useEffect(() => {
    if (courseList.length === totalResults && courseList.length > 0) {
      setfullCourseList(courseList);
      setFullCourseListCount(totalResults);
    }
  }, [courseList]);

  useEffect(() => {
    if (queryString) {
      setPageNumber(1);
      setCourseList([]);
      setHasMoreCourses(true);
    }

    fetchCourseDetails();

    // eslint-disable-next-line
  }, [queryString]);

  const fetchCourseDetails = () => {
    const endPoint = `${config.endPoint.elearning}/course?pageSize=${elearningCourseList.pagination.pageSize}&pageNumber=${pageNumber}&courseStatus=active`;
    axios
      .get((queryString && `${endPoint}${queryString}`) || endPoint)
      .then((response) => {
        if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
          setPageNumber(1);
          setCourseList([]);
          setInitialFilter(filters);
        }
        setCourseList((current) => [...current, ...response.data.data]);
        setPageNumber(pageNumber + 1);
        setTotalResults(response?.data?.total || 0);

        if (!response.data.data.length) {
          setHasMoreCourses(false);
        }
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
      });
  };

  const mobileStyles =
    window.innerWidth < 786
      ? {
          justifyContent: "center",
          alignItems: "center",
          marginLeft: ".8rem",
          marginTop: "1rem",
          display: "flex",
          flexWrap: "wrap",
          width: "",
        }
      : {};

  const totalCount =
    filters &&
    filters.parentCategoryId &&
    filters.parentCategoryId !== config.defaultParentCategory.value
      ? courseList.length
      : totalResults;

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroBannerCousre
        crumbs={crumbs}
        heroBanner={config.elearningCourseList.heroBanner}
        title={config.elearningCourseList.title}
        description={config.elearningCourseList.description}
      />
      <div className="elearning-common-container">
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <div style={{ marginBottom: "2rem" }} className="el-filter-container">
          <div style={{ marginBottom: "2rem", paddingTop: "1rem" }}>
            <span className={"countText"}>
              Showing <span style={{ color: primary }}> {totalCount} </span>
              total results for E-Learning Courses
            </span>
          </div>
          <div>
            <CourseFilter
              upliftFilters={handleFilter}
              filters={filters}
              reset={reset}
              isInstuctorLed={false}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "84rem",
            margin: "auto",
            ...mobileStyles,
          }}
        >
          <InfiniteScroll
            className={"el-list-InfiniteScroll"}
            dataLength={courseList.length}
            next={fetchCourseDetails}
            hasMore={hasMoreCourses}
            style={{
              ...mobileStyles,
            }}
            scrollThreshold={0.4}
          >
            {courseList && courseList.length ? (
              courseList.map((current, index) => {
                return (
                  <div
                    style={{
                      width: "19.5rem",
                      marginBottom: "1.5rem",
                      marginRight: (index + 1) % 4 === 0 ? 0 : "1.2rem",
                    }}
                    key={index}
                  >
                    <ElearningCourseTile
                      dataElement={current}
                      index={index}
                      coursePath={`/e-learning-courses/${current.id}`}
                    />
                  </div>
                );
              })
            ) : (
              <div>
                No course available in your region for the category selected.
              </div>
            )}
          </InfiniteScroll>
          {hasMoreCourses && (
            <div
              style={{
                fontFamily: "RakutenRoundRegular",
                fontSize: 24,
                margin: isMobile ? "1rem" : "",
                textAlign: "center",
              }}
            >
              <b>Loading...</b>
            </div>
          )}
          {!hasMoreCourses && (
            <span
              style={{
                fontFamily: "RakutenRoundRegular",
                fontSize: 24,
                textAlign: "center",
              }}
            >
              <b>
                You've reached the end of the list. All the courses are listed
                above.
              </b>
            </span>
          )}
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          margintop: "120px",
        }}
      >
        <Layout
          bsPrefix={`container ${"container"}`}
          style={{
            backgroundColor: "white",
            marginLeft: window.innerWidth > 786 ? "3.5rem" : "0.5rem",
          }}
        >
          <div
            className={"el-secondContainer"}
            style={{
              marginTop: "120px",
            }}
          >
            <LearningBySkills
              changeSuperCatergory={(id) => updateSuperCatergory(id)}
            />
            <div className={"el-quotation"}>
              <Quotation />
            </div>
          </div>
        </Layout>
      </div>
      {window.innerWidth < 786 && <PageFooter />}
    </div>
  );
}

export default ElearningUserCourseList;

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import "./breadCrumb.scss";

export default function AntDesignBreadCrumb({ crumbs, customStyle }) {
  return (
    <>
      {(crumbs && crumbs.length && (
        <Breadcrumb className={`${"breadcrumb"} ${customStyle}`}>
          {crumbs.map((crumb, index) => {
            const active = (crumbs.length - 1 === index && true) || false;
            return (
              <Breadcrumb.Item key={`${index}-${crumb.title}`}>
                {crumb?.to ? (
                  <Link
                    to={crumb.to}
                    className={`${(active && "active") || ""}`}
                  >
                    <span className={"breadcrumbTitle"}>{crumb.title}</span>
                  </Link>
                ) : (
                  <span className={`${(active && "active") || ""}`}>
                    {crumb.title}
                  </span>
                )}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      )) ||
        ""}
    </>
  );
}
AntDesignBreadCrumb.propTypes = {
  customStyle: PropTypes.string,
  hasBackground: PropTypes.bool,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

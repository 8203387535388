import React, { useState, useContext, useEffect } from "react";
import { Card, Tooltip } from "antd";
import style from "./courseTile.module.css";
import { ReactComponent as InstructorLedCourse } from "../../assets/icons/InstructorLedCourseWhite.svg";
import { ReactComponent as CourseProvider } from "../../assets/icons/courseProviderIcon.svg";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import TimeFormatter from "../../utils/timeFormatter";
import RoundProgressBarInstructorLed from "../roundProgressBarInstructorLed/RoundProgressBarInstructorLed";
import { Link } from "react-router-dom";
import { decodeHtmlEntities } from "../../utils/commonUtility";
import BookmarkIcon from "../../assets/icons/bookmark_icon_white.png";
import WhiteBookmarkIcon from "../../assets/icons/bookmark_icon_white_filled.png";
import { UserContext } from "../../context/UserContext";
import config from "../../config";
import axios from "axios";
import add_course from "../../assets/icons/add_course.svg";
import selected from "../../assets/icons/selected.svg";
import { CourseContext } from "../../context/CourseContext";
import "./courseTile.scss";
import AddCourseIcon from "../../components/common/AddCourseIcon";
import SelectedCourseIcon from "../../components/common/SelectedCourseIcon";

function CourseTile({
  dataElement,
  index,
  coursePath,
  isFavorite,
  tileType,
  checkedILState,
  cardClicked,
  reload,
  courseTileAlert,
}) {
  const {
    name,
    course_name: courseName,
    private: isPrivate,
    id,
    is_favourite: isFav,
    language,
    duration,
    batch_data: batchData,
    course_provider: courseProvider,
    user_data: userData,
    courseProvider: courseProviderDetails,
  } = dataElement || {};

  useEffect(() => {
    setIsFavourite(dataElement.is_favourite || isFavorite);
  }, [dataElement, isFavorite]);

  const { userId, userConfig } = useContext(UserContext);
  const { userDateFormat } = useContext(CourseContext);
  const [isFavourite, setIsFavourite] = useState(isFav || isFavorite);

  const imageUrl = !dataElement.image ? "/default.png" : dataElement.image;
  const {
    batch_start: batchStart,
    batch_end: batchEnd,
    start_time: batchStartTime,
    end_time: batchEndTime,
    occupied_seats,
    batch_size,
    counter,
    size,
  } = batchData || {};
  const occupiedSeats = occupied_seats || counter;
  const batchSize = batch_size || size;
  const {
    status,
    status_text: statusText,
    status_type_id: statusCode,
  } = userData || {};

  /**
   * if there is statusText show status text else show batch data else show coming soon.
   */

  const userStatus = status || statusText;
  let fromTo = batchStart || batchStartTime;
  const showDate = fromTo;

  if (fromTo !== "Coming Soon") {
    let startDate = TimeFormatter.FormatDate(
      new Date(batchStart || batchStartTime),
      null,
      userDateFormat
    );
    let endDate = TimeFormatter.FormatDate(
      new Date(batchEnd || batchEndTime),
      null,
      userDateFormat
    );
    if (startDate === endDate) {
      fromTo = startDate;
    } else {
      fromTo = `${startDate} - ${endDate}`;
    }
  }

  const addFavourite = async (userId, courseId) => {
    //ToDo calling APIs from a separate API file
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: config.instructorLed.alias,
          entityId: courseId,
        },
      ])
      .catch((error) => {
        const alertMessage =
          error?.response?.data?.message ||
          "Something went wrong, please try again";

        courseTileAlert(alertMessage, "warning");
      })
      .finally(() => {
        if (reload) reload();
      });
  };

  const deleteFavourite = async (userId, courseId) => {
    //ToDo calling APIs from a separate API file
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=IL&courseId=${courseId}`;
    await axios
      .delete(endPoint)
      .catch((error) => {
        const alertMessage =
          error?.response?.data?.message ||
          "Something went wrong, please try again";
        courseTileAlert(alertMessage, "warning");
      })
      .finally(() => {
        if (reload) reload();
      });
  };

  const changeStatus = () => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, id);
    } else {
      setIsFavourite(true);
      addFavourite(userId, id);
    }
  };

  const renderCardImageOverLay = () => {
    return (
      <Card.ImgOverlay className="overlay">
        <span className="instructorledIcon">
          <InstructorLedCourse />
        </span>
        <span className="overlayText">COURSE</span>
        <span
          onClick={(e) => {
            e.preventDefault();
            changeStatus();
          }}
          className="wishlistImage"
        >
          <img
            src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
            alt={isFavourite ? "Bookmarked" : "Not bookmarked"}
          />
        </span>
      </Card.ImgOverlay>
    );
  };

  return (
    <div>
      {tileType &&
      (tileType === "recommendation" || tileType === "learningPath") ? (
        <div>
          <Card
            className={style.cardLP}
            key={index}
            cover={
              <div className={style.imageDiv}>
                <img src={imageUrl} className={style.image} />
                <div
                  style={{
                    position: "absolute",
                    top: "-1px",
                    left: "-5px",
                  }}
                >
                  <span className={style.instructorledIcon}>
                    <InstructorLedCourse />
                  </span>
                  <span className={style.overlayText}>COURSE</span>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      changeStatus();
                    }}
                    className={style.wishlistImage}
                  >
                    <img
                      style={{ borderRadius: "0px" }}
                      src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
                      alt={isFavourite ? "Bookmarked" : "Not bookmarked"}
                    />
                  </span>
                </div>
              </div>
            }
          >
            <div className={style.cardTitle}>
              {decodeHtmlEntities(name || courseName)}
            </div>
            <Row>
              <Col span={17}>
                {(showDate && (
                  <div className={style.textData}>{fromTo}</div>
                )) || <div className={style.textData}>Coming Soon...</div>}

                <span className={style.spanData}>
                  <div className={style.duration}>
                    {duration &&
                      TimeFormatter.decimalHrsToHrsMin(duration, "detailed")}
                    / {language}
                  </div>
                  <div className="il-courseProvider">
                    <span>
                      <CourseProvider className="il-courseProviderIcon" />
                    </span>
                    <span>
                      {courseProviderDetails?.name?.toUpperCase() ||
                        courseProvider?.toUpperCase()}
                    </span>
                  </div>
                </span>
              </Col>
              <Col span={7}>
                <div className={`${style.circleProgressBar}`}>
                  <div className={style.addSelectCourse}>
                    {checkedILState?.length &&
                    checkedILState?.includes(dataElement.id) ? (
                      <span
                        className="plusCircleStyle"
                        onClick={() => cardClicked(dataElement.id)}
                      >
                        <SelectedCourseIcon
                          strokeColor={userConfig.primary}
                          fillColor={userConfig.primary}
                        />
                      </span>
                    ) : (
                      <span
                        onClick={() => cardClicked(dataElement.id)}
                        className="plusCircleStyle"
                      >
                        <AddCourseIcon
                          strokeColor={userConfig.primary}
                          fillColor={userConfig.primary}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      ) : (
        <Link to={coursePath}>
          <Card
            className={style.card}
            key={index}
            cover={
              <div className={style.imageDiv}>
                <img src={imageUrl} className={style.image} />
                <div
                  style={{
                    position: "absolute",
                    top: "5px",
                    left: "-5px",
                  }}
                >
                  <span className={style.instructorledIcon}>
                    <InstructorLedCourse />
                  </span>

                  <span className={style.overlayText}>COURSE</span>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      changeStatus();
                    }}
                    className={style.wishlistImage}
                  >
                    <img
                      style={{ borderRadius: "0px" }}
                      src={isFavourite ? WhiteBookmarkIcon : BookmarkIcon}
                      alt={isFavourite ? "Bookmarked" : "Not bookmarked"}
                    />
                  </span>
                </div>
              </div>
            }
          >
            <Row>
              <Tooltip placement="topLeft" title={name || courseName}>
                <div className={style.cardTitle}>
                  {decodeHtmlEntities(name || courseName)}
                </div>
              </Tooltip>
            </Row>
            <Row>
              <Col span={17}>
                {(showDate && (
                  <div className={style.textData}>{fromTo}</div>
                )) || <div className={style.textData}>Coming Soon...</div>}

                <span className={style.spanData}>
                  <div className={style.duration}>
                    {duration &&
                      TimeFormatter.decimalHrsToHrsMin(
                        duration,
                        "detailed"
                      )}{" "}
                    / {language}
                  </div>
                  <div className="il-courseProvider">
                    <span className="il-courseProviderIcon-container">
                      <CourseProvider className="il-courseProviderIcon" />
                    </span>
                    <span
                      style={{
                        width: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {courseProviderDetails?.name?.toUpperCase() ||
                        courseProvider?.toUpperCase()}
                    </span>
                  </div>
                </span>
              </Col>
              <Col span={7}>
                <span className={style.roundProgressBar}>
                  <RoundProgressBarInstructorLed
                    index={index}
                    userStatus={userStatus}
                    occupiedSeats={occupiedSeats}
                    batchSize={batchSize}
                    isPrivate={isPrivate}
                    statusCode={statusCode}
                  />
                </span>
              </Col>
            </Row>
          </Card>
        </Link>
      )}
    </div>
  );
}

CourseTile.propTypes = {
  dataElement: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  index: PropTypes.number,
};

export default CourseTile;

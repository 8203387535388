import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  AdminPageTitle,
  InlineIconButton,
  CustomSelectAttendance,
} from "../../../component-library";
import editIcon from "../../../assets/icons/edit_icon.svg";
import { UserContext } from "../../../context/UserContext";
import style from "./adminPrivileges.module.css";
import axios from "axios";
import {
  AutoComplete,
  Breadcrumb,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import {
  DownloadOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import CsvDownloader from "react-csv-downloader";
import { CSVLink } from "react-csv";
import "./antdAdminPrivileges.scss";

function AntdAdminPrivileges() {
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [options, setOptions] = useState([]);
  const [query] = useState("");
  const [searchString, setSearchString] = useState("");
  const _cache = {}; // Cache results for auto complete
  const [showPrivilegeModal, setShowPrivilegeModal] = useState(false);
  const [visibleData, setVisibleData] = useState(null);
  const [searchResponse, setSearchResponse] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPrivilegeEdit, setIsPrivilegeEdit] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [visibleRoles, setVisibleRoles] = useState([]);
  const { userId } = useContext(UserContext);
  const [roleEditable, setRoleEditable] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [val, setVal] = useState("");

  const handleOk = () => {
    setIsModalOpen(false);
    setRoleEditable(true);
    setVisibleData(null);
    setIsPrivilegeEdit(false);
    setVal(null);
  };
  const handleCancel = () => {
    setVisibleData(null);
    setIsPrivilegeEdit(false);
    setVal(null);
    setIsModalOpen(false);
    setRoleEditable(true);
  };

  const privilegeUserTable = [
    {
      dataIndex: "firstName",
      sorter: (a, b) => {
        return a.firstName.localeCompare(b.firstName);
      },
      title: "EMP Name".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "12rem",
      },
    },
    {
      dataIndex: "primaryEmail",
      sorter: (a, b) => {
        return a.primaryEmail.localeCompare(b.primaryEmail);
      },
      title: "Email ID".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "18rem",
      },
    },
    {
      dataIndex: "Roles",
      // sorter: (a, b) => {
      //   return a.roles.localeCompare(b.roles);
      // },
      title: "Assigned Roles".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "16rem",
      },
      render: (cell, row) => {
        const mapStringValue = (val) => {
          switch (val.id) {
            case 1:
              return "User";
            case 2:
              return "IL Admin";
            case 3:
              return "EL Admin";
            case 4:
              return "Manager";
            case 99:
              return "Super Admin";
            default:
              return "User";
          }
        };
        const stringVal = cell?.map((val) => mapStringValue(val));
        return stringVal?.join(", ");
      },
      csvrender: (cell, row) => {
        const mapStringValue = (val) => {
          switch (val.id) {
            case 1:
              return "User";
            case 2:
              return "IL Admin";
            case 3:
              return "EL Admin";
            case 4:
              return "Manager";
            case 99:
              return "Super Admin";
            default:
              return "User";
          }
        };
        const stringVal = cell?.map((val) => mapStringValue(val));
        return stringVal.join(", ");
      },
    },
    {
      dataIndex: "Department",
      sorter: (a, b) => {
        return (
          a.Department.name?.localeCompare(b.Department.name) ||
          b.Department.name
        );
      },
      title: "Department".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "15rem",
      },
      render: (cell, row) => {
        return (<div>
          {row.Department.name}
        </div>)
      }
    },
    {
      dataIndex: "country",
      sorter: (a, b) => {
        return a.country.localeCompare(b.country);
      },
      title: "country".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "6rem",
      },
    },
    {
      dataIndex: "id",
      isDummyField: true,
      title: "Edit Role".toUpperCase(),
      sort: false,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      render: (cell, row) => {
        const roleIds = row?.Roles.map((rid) => rid.id);
        return roleIds.includes(99) ? (
          "Can't edit super admin's role"
        ) : (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              setIsPrivilegeEdit(true);
              openEditUserRole(row);
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    getAdminPrivileges();
  }, []);
  const getAdminPrivileges = () => {
    axios
      .get(`/api/user/privileges`, {
        baseURL: process.env.REACT_APP_USER_INFO_URL,
      })
      .then((response) => {
        setListData(response?.data?.data);
        setTableData(response?.data?.data);
        setPageCount(response?.data?.data?.length);
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const mapStringValue = (val) => {
    switch (val) {
      case 1:
        return "User";
      case 2:
        return "IL Admin";
      case 3:
        return "EL Admin";
      case 4:
        return "Manager";
      case 99:
        return "Super Admin";
      default:
        return "User";
    }
  };

  const getUserRoles = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_USER_INFO_URL}/api/role/fetch`)
      .then((response) => {
        setRoleOptions(
          response?.data?.data
            ?.filter((x) => x.id === 2 || x.id === 3)
            .map((item) => ({ label: mapStringValue(item.id), value: item.id }))
        );
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  useEffect(() => {
    getAdminPrivileges();
  }, []);

  const resetRoleOptions = () =>
    roleOptions.length > 0
      ? setRoleOptions(roleOptions.map((op) => ({ ...op, isDisabled: false })))
      : getUserRoles();

  const fetchUserRoleOptions = () => {
    return axios.get(`${process.env.REACT_APP_USER_INFO_URL}/api/role/fetch`);
  };

  const selectUserRoles = async (roles) => {
    const result = await fetchUserRoleOptions();
    const roleOptions = result?.data?.data?.map((item) => ({
      label: mapStringValue(item.id),
      value: item.id,
    }));
    const roleArr = roleOptions?.filter((role) => roles?.includes(role.value));
    if (roleArr.find((x) => x.label === "Manager")) {
      setVisibleRoles([]);
    } else {
      setVisibleRoles(roleArr);
    }
  };

  const openEditUserRole = (row) => {
    resetRoleOptions();
    setIsModalOpen(true);
    if (row && row.Roles) {
      const roleIds = row.Roles.map((rid) => rid.id);
      setVisibleData(row);
      setOptions([{ value: row.primaryEmail, label: row.primaryEmail }]);
      selectUserRoles(roleIds);
      const visibleRoles =roleIds.map((role) => {
        return { label: mapStringValue(role), value: role };
      });
      setVisibleRoles(visibleRoles);
    } else {
      setVal(null);
      setVisibleData(null);
      setOptions(null);
      setVisibleRoles([]);
    }
  };

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="privilege-crumb">
          Admin Dashboard
        </a>
      ),
    },
    { title: "Privileges" },
  ];

  const renderTitle = () => {
    return <AdminPageTitle pageTitle="Privileges" pageCount={pageCount} />;
  };

  const renderDownloadButton = () => {
    const columns = Object.keys(listData[0] || {}).map((key) => ({
      key: key,
      label: key,
    }));

    return (
      <CSVLink
        headers={columns}
        data={listData}
        filename="Privileges"
        extension=".csv"
      >
        <div
          style={{
            fontSize: 20,
            background: "white",
            padding: ".425rem .7rem",
            borderRadius: "50%",
            marginRight: "1rem",
            height: "2.75rem",
          }}
        >
          <DownloadOutlined />
        </div>
      </CSVLink>
    );
  };

  const handleSearch = (searchText) => {
    const filteredData = listData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );

    if (searchText === "") {
      setTableData(listData);
    } else {
      setTableData(filteredData);
    }
  };

  const renderSearchbar = () => {
    return (
      <Input
        placeholder="Search"
        suffix={<SearchOutlined style={{ fontSize: "16px" }} />}
        style={{
          width: "15rem",
          padding: ".725rem",
          fontSize: "16px",
          height: "3rem",
          marginRight: "1rem",
        }}
        onChange={(e) => handleSearch(e.target.value)}
      />
    );
  };

  const renderAddPrivilegesButton = () => {
    return (
      <div>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={openEditUserRole}
          className="primary-button-learning-course-list"
        >
          Add Privilege
        </Button>
      </div>
    );
  };

  const renderMenu = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{renderTitle()}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {renderDownloadButton()}
          {renderSearchbar()}
          {renderAddPrivilegesButton()}
        </div>
      </div>
    );
  };

  const renderTableContent = () => {
    return (
      <Table
        columns={privilegeUserTable}
        dataSource={tableData}
        pagination={{
          position: ["bottomCenter"],
          showQuickJumper: true,
          showSizeChanger: true,
        }}
      />
    );
  };

  const _handleSearch = (query) => {
    if (_cache[query]) {
      setOptions(_cache[query].options);
      return;
    }
    // setIsLoading(true);

    const tagsAutoCompleteUrl = `/api/user/privileges/search/email?matchBy=${query}`;
    const instance = axios.create();
    instance
      .get(tagsAutoCompleteUrl, {
        baseURL: process.env.REACT_APP_USER_INFO_URL,
      })
      .then((response) => {
        // setIsLoading(false);
        if (response.data.success) {
          setSearchResponse(response.data.emailList);
          const userDetails = response.data.emailList;
          const userEmailOptions = userDetails.map((user) => ({
            value: user.primaryEmail,
            label: user.primaryEmail,
          }));
          setOptions(userEmailOptions);
        }
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
      });
  };

  const submitUserRole = () => {
    let rolesArray = [];
    let isValid = true;

    if (selectedRole && selectedRole.length !== 0) {
      rolesArray = selectedRole.map((roleId) => roleId.value);
    } else {
      rolesArray = [1];
    }

    if (!searchString && !selectedRole && !isPrivilegeEdit) {
      isValid = false;
      setErrorMessage("Please select User and User Role");
    }

    if (userId === visibleData.id) {
      isValid = false;
      setErrorMessage("You can not modify own roles");
    }

    if (rolesArray[0] === 99) {
      isValid = false;
      setErrorMessage("You can not modify Super Admin's role");
    }
    if (isValid) {
      const payload = {
        userId: visibleData.id,
        roles: rolesArray,
      };
      setAlert("");

      axios
        .post("/api/role/assign", payload, {
          baseURL: process.env.REACT_APP_USER_INFO_URL,
        })
        .then((response) => {
          setAlert("User Role updated successfully!");
          setAlertType("success");
          setIsModalOpen(false);
          getAdminPrivileges();
          setIsPrivilegeEdit(false);
          setVal(null);
        })
        .catch((error) => {
          try {
            setAlert(error.response.data.message);
          } catch (e) {
            setAlert("Error while updating user role");
          } finally {
            setAlertType("danger");
            setIsModalOpen(false);
            setIsPrivilegeEdit(false);
            setVal(null);
          }
        });
    }
  };

  const searchSelected = (selected) => {
    setErrorMessage("");
    setRoleOptions(roleOptions.map((op) => ({ ...op, isDisabled: false })));
    const selectedUser = searchResponse.filter((user) => {
      return user.primaryEmail === selected;
    });
    setVisibleData(selectedUser[0]);
    if (selectedUser[0]?.roles?.includes(99)) {
      setRoleEditable(false);
    } else {
      setRoleEditable(true);
    }
    selectUserRoles(
      selectedUser[0]?.roles?.filter((item) => item === 3 || item === 2)
    );
    setSearchString(selected);
  };

  const handleUserRole = (selectedRole, actionMeta, onChange) => {
    setSelectedRole(selectedRole);
  };

  useEffect(() => {
    setVal(visibleData?.primaryEmail || "");
  }, [isPrivilegeEdit]);

  const renderAddEditPrivilageModal = () => {
    return (
      <Modal
        title="Role Assignment"
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancel}
        width={"60rem"}
      >
        <span
          style={{
            marginLeft: "2rem",
          }}
        >
          Employee Email Id
          <span style={{ color: "red" }}> *</span>
        </span>
        <AutoComplete
          style={{
            width: 400,
            marginLeft: "2rem",
          }}
          onSearch={_handleSearch}
          onSelect={(selected) => searchSelected(selected)}
          placeholder="Search User Email ID"
          options={options}
          value={val}
          onChange={(e) => setVal(e)}
          // defaultValue={isPrivilegeEdit ? options.slice(0, 1) : []}
          defaultValue={isPrivilegeEdit && options ? options[0].value : []}
          disabled={isPrivilegeEdit ? true : false}
        />
        <Row className={style.body}>
          {!visibleData && <div className={style.emptyContainer}></div>}
          {visibleData && (
            <div className={style.emptyContainer}>
              <Row span={[12, 12]}>
                <Col
                  lg="10"
                  style={{
                    margin: "1rem",
                    marginRight: "2rem",
                    width: "30rem",
                  }}
                >
                  <Row>
                    <Col lg="3">
                      <Typography className={"courseAddEdit-label"}>
                        Name:
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        className={"courseAddEdit-label"}
                        style={{ marginLeft: ".5rem" }}
                      >
                        {visibleData.name}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Typography className={"courseAddEdit-label"}>
                        Email:
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        className={"courseAddEdit-label"}
                        style={{ marginLeft: ".5rem" }}
                      >
                        {visibleData.primaryEmail}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Typography className={"courseAddEdit-label"}>
                        Country:
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        className={"courseAddEdit-label"}
                        style={{ marginLeft: ".5rem" }}
                      >
                        {visibleData.country}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="3">
                      <Typography className={"courseAddEdit-label"}>
                        Department:
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        className={"courseAddEdit-label"}
                        style={{ marginLeft: ".5rem" }}
                      >
                        {visibleData.department}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                {roleEditable ? (
                  <Col
                    lg="5"
                    style={{
                      margin: "1rem 0",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "18rem",
                    }}
                  >
                    <Typography className={"courseAddEdit-label"}>
                      Edit Role:
                    </Typography>
                    <CustomSelectAttendance
                      placeholder="Select and Add Privilege"
                      options={roleOptions}
                      selected={visibleRoles}
                      handleDropdown={(value, actionMeta, onChange) =>
                        handleUserRole(value, actionMeta, onChange)
                      }
                      isMulti={true}
                      components={{
                        ClearIndicator: () => null,
                      }}
                    />
                    <p className={style.note}>
                      {" "}
                      - Click [X] to remove <br /> - Select from dropdown to add
                    </p>
                  </Col>
                ) : (
                  <Col
                    lg="5"
                    style={{ margin: "2rem 0", width: "17rem" }}
                    className={style.errorMessage}
                  >
                    {visibleData.name} is a Super Admin, and one cannot edit
                    super admin's role.
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Row>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ margin: "1rem" }}
            type="button"
            className="privilege-secondary-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>

          <Button
            style={{ margin: "1rem" }}
            type="button"
            onClick={submitUserRole}
            disabled={!roleEditable || errorMessage}
            className="privilege-primary-button"
          >
            Submit
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <div style={{ padding: "3rem", marginTop: "3rem" }}>
      <Breadcrumb
        items={crumbs}
        style={{ fontFamily: "RakutenRoundRegular" }}
      />
      {renderMenu()}
      {renderTableContent()}
      {renderAddEditPrivilageModal()}
    </div>
  );
}

export default AntdAdminPrivileges;

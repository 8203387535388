import Breadcrumb from "react-bootstrap/Breadcrumb";
import PropTypes from "prop-types";
import "./breadCrumb.scss";
import { Link } from "react-router-dom";

export default function BreadCrumb({ crumbs, customStyle, hasBackground }) {
  return (
    <>
      {(crumbs && crumbs.length && (
        <Breadcrumb
          bsPrefix={`breadcrumb ${"breadcrumb"} ${customStyle}`}
          className={"breadcrumb"}
        >
          {crumbs.map((crumb, index) => {
            const active = (crumbs.length - 1 === index && true) || false;
            return (
              <li
                className="breadcrumb-item"
                key={index}
                active={active.toString()}
              >
                {crumb?.to ? (
                  <Link
                    to={crumb.to}
                    className={
                      hasBackground
                        ? "breadcrumbBackground"
                        : `${(active && "active") || ""}`
                    }
                  >
                    <span className={"breadcrumbTitle"}>{crumb.title}</span>
                  </Link>
                ) : (
                  <span
                    className={
                      hasBackground
                        ? "breadcrumbBackground"
                        : `${(active && "active") || ""}`
                    }
                    style={{ textTransform: "capitalize" }}
                  >
                    {crumb.title.length > 13
                      ? `${crumb.title}`
                      : crumb.title}
                  </span>
                )}
              </li>
            );
          })}
        </Breadcrumb>
      )) ||
        ""}
    </>
  );
}
BreadCrumb.propTypes = {
  customStyle: PropTypes.string,
  hasBackground: PropTypes.bool,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

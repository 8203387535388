import React from "react";
import { Row } from "antd";
import { BreadCrumb } from "../";
import style from "./heroBanner.module.css";
import "./heroBannerCourse.scss";

export default function HeroBanner(props) {
  const { heroBanner, title, description } = props;
  const isMobile = window.innerWidth < 786;

  return (
    <div
      style={{
        height: "27.875rem",
        marginTop: "5rem",
        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${heroBanner})`,
        justifyContent: isMobile ? "flex-start" : "center",
        display: "flex",
      }}
      className="mobile-hero-banner"
    >
      <div
        style={{ paddingTop: "1rem" }}
        className="hero-banner-responsive"
      >
        <BreadCrumb
          crumbs={props.crumbs}
          customStyle={style.breadCrumb}
          hasBackground={true}
        />
        <div>
          <div
            className="hero-banner-responsive-title"
            style={{ color: "white" }}
          >
            {title}
          </div>
          <Row className="hero-banner-responsive-description">
            {description}
          </Row>
        </div>
      </div>
    </div >
  );
}

import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.scss";
import { LoadingContextProvider } from "./pages/LoadingContext";
import reportWebVitals from "./reportWebVitals";
import AppLoader from "./pages/AppLoader";

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <LoadingContextProvider>
        <AppLoader />
      </LoadingContextProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

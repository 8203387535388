const UtilityFunctions = {
  async downloadCSVFile(response, name) {
    const fileName = name
      ? name
      : response.headers["content-disposition"].split("filename=")[1];
    const data = response.data;
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const blobURL = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = fileName;
    anchor.href = blobURL;
    anchor.dataset.downloadurl = [
      "text/csv",
      anchor.download,
      anchor.href,
    ].join(":");
    anchor.click();
  },
  async downloadXLSXFile(response, name) {
    const fileName = name
      ? name
      : response.headers["content-disposition"].split("filename=")[1];
    const data = response.data;
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  },
  debounce(func, delay) {
    let timer;
    return function (...args) {
      let context = this;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  },
  appendUrl(url, ...subUrl) {
    return `/${[...url.split("/"), ...subUrl].filter(Boolean).join("/")}`;
  },
  compareArraysByKey(array1, array2, key) {
    let same = true;
    for (let value1 of array1) {
      if (!same) break;
      same =
        same &&
        array2.find((value) => value._id === value1._id)[key] === value1[key];
    }
    return same;
  },
  arrayToQueryString(array, arrayName) {
    return array.map((item) => `${arrayName}=${item}`).join("&");
  },
  formatParentCategoryList(data) {
    return data.map((current) => {
      return {
        label: current.parent_category_name.toUpperCase(),
        value: current.id,
      };
    });
  },
  convertQueryParamsToObject(queryParams) {
    const params = {};
    if (queryParams.charAt(0) === "?") {
      queryParams = queryParams.substr(1);
    }
    const paramPairs = queryParams.split("&");
    paramPairs.forEach((paramPair) => {
      const [key, value] = paramPair.split("=");
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);
      if (params.hasOwnProperty(decodedKey)) {
        if (Array.isArray(params[decodedKey])) {
          params[decodedKey].push(decodedValue);
        } else {
          params[decodedKey] = [params[decodedKey], decodedValue];
        }
      } else {
        params[decodedKey] = decodedValue;
      }
    });
    return params;
  },
  deleteTheQueryParamsFromURL(queryParams , paramList){
    const urlSearchParams = new URLSearchParams(queryParams);
    paramList.forEach(param => {
      urlSearchParams.delete(param);
    });
    return urlSearchParams.toString();;
  }
};

export default UtilityFunctions;

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import style from "./customToggle.module.css";
import { UserContext } from "../../context/UserContext";

/**
 * toggleTitle - array [title1,title2];
 * onChange = ()=> void
 * selectedValue = 0|1
 */
export default function CustomToggle({
  toggleTitle,
  onChange,
  value,
  icons,
  svgs,
  customToggleCss,
  iconBoxCss,
  toggleTitleCss,
  disabled = false,
  isImage = true,
}) {
  const [current, setCurrent] = useState(false);
  const title = current ? toggleTitle[0] : toggleTitle[1];
  const customToggleStyle = customToggleCss
    ? customToggleCss
    : style.custom_toggle;
  const iconBoxStyle = iconBoxCss ? iconBoxCss : style.icon_box;
  const toggleTitleStyle = toggleTitleCss ? toggleTitleCss : style.toggle_title;
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    setCurrent(value);
  }, [value]);

  const handleClick = (curr) => {
    const val = !current;
    setCurrent(val);
    onChange(val);
  };
  const Open = icons[0];
  const Close = icons[1];

  return (
    <Col
      className={customToggleStyle}
      style={{ backgroundColor: userConfig.primary + "20" }}
      onClick={() => {
        if (!disabled) {
          handleClick();
        }
      }}
    >
      <Row>
        {!isImage ? (
          <span
            className={iconBoxStyle}
            style={{
              backgroundColor: current ? "white" : userConfig.primary,
              width: 32,
              color: current ? userConfig.primary : "white",
              fontSize: 20,
            }}
          >
            {current ? <Open /> : <Close />}
          </span>
        ) : (
          <Col
            className={iconBoxStyle}
            style={{ backgroundColor: current ? "white" : userConfig.primary }}
          >
            {current ? (
              <img src={icons[0]} alt="" />
            ) : (
              <img src={icons[1]} alt="" />
            )}
          </Col>
        )}
        <Col className={toggleTitleStyle}>
          <span
            style={{
              color: userConfig.primary,
              fontFamily: "RakutenRoundRegular",
              fontSize: 14,
            }}
          >
            {title}
          </span>
        </Col>
      </Row>
    </Col>
  );
}

CustomToggle.propTypes = {
  toggleTitle: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selected: PropTypes.any,
  customToggleCss: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconBoxCss: PropTypes.object,
  toggleTitleCss: PropTypes.object,
  icons: PropTypes.any,
};

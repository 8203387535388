import { Breadcrumb, Col, Input, Row, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../../../context/CourseContext";

import axios from "axios";
import config from "../../../../config";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  AdminPageSubTitle,
  AdminPageTitle,
} from "../../../../component-library";
import CsvDownloader from "react-csv-downloader";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import TimeFormatter from "../../../../utils/timeFormatter";
import "./completedUsers.scss";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="common-breadcrumbs-color">
        Admin Dashboard
      </a>
    ),
  },
  {
    title: (
      <a href="/Admin/e-learning/courses" className="common-breadcrumbs-color">
        E-Learning Courses
      </a>
    ),
  },
  { title: "Completed" },
];

function AntdCompletedUsers(props) {
  const { userDateFormat } = useContext(CourseContext);

  const [courseId] = useState(props.match.params.courseId);
  const [courseData, setCourseData] = useState("");
  const [listData, setListData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const employeeStatus = ["Active", "Inactive"];

  const sessionUsersColumns = [
    {
      dataIndex: "empId",
      title: "EMP ID".toUpperCase(),
      sorter: (a, b) => {
        return a.empId - b.empId;
      },
      sort: true,
      headerStyle: {
        width: "6rem",
      },
    },
    {
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      title: "EMP Name".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "12rem",
      },
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.name}`}</strong>
            </div>
            <div>{`${row.primaryEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "primaryEmail",
      sorter: (a, b) => {
        return a.primaryEmail.localeCompare(b.primaryEmail);
      },
      title: "Email".toUpperCase(),
      hidden: true,
    },
    {
      dataIndex: "postTitle",
      sorter: (a, b) => {
        return a.postTitle.localeCompare(b.postTitle);
      },
      title: "Role".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "10rem",
      },
    },
    {
      dataIndex: "currentDptName",
      sorter: (a, b) => {
        return a.currentDptName.localeCompare(b.currentDptName);
      },
      title: "Department".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "10rem",
      },
    },
    {
      dataIndex: "mainWorkingCountryName",
      sorter: (a, b) => {
        return a.mainWorkingCountryName.localeCompare(b.mainWorkingCountryName);
      },
      title: "country".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataIndex: "completedDate",
      sorter: (a, b) => {
        return a.completedDate - b.completedDate;
      },
      title: "Completed Date".toUpperCase(),
      sort: true,
      headerStyle: {
        width: "8rem",
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "status",
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      },
      title: "Employment Status".toUpperCase(),
      sort: true,
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.status).startsWith(String(value)),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      align: "center",
      headerStyle: {
        width: "8rem",
      },
    },
  ];

  const [tableColumns, setTableColumns] = useState(sessionUsersColumns);

  useEffect(() => {
    setTableColumns(sessionUsersColumns);
  }, [userDateFormat]);

  useEffect(() => {
    getCourseData();
    // eslint-disable-next-line
  }, []);

  const getCourseData = () => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}`)
      .then((response) => {
        setCourseData(response.data.data.course);
        getCompletedUsersData();
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
        }
      });
  };
  const getCompletedUsersData = () => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}/user`, {
        params: { courseStatus: "completed" },
      })
      .then((response) => {
        setListData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("danger");
        }
      });
  };

  const handleSearch = (searchText) => {
    const filteredData = listData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );

    if (searchText === "") {
      setListData(listData);
    } else {
      setListData(filteredData);
    }
  };
  const renderSearchInput = () => {
    return (
      <Input
        placeholder="Search"
        suffix={<SearchOutlined style={{ fontSize: "16px" }} />}
        style={{
          width: "15rem",
          padding: ".725rem",
          fontSize: "16px",
          height: "3rem",
        }}
        onChange={(e) => handleSearch(e.target.value)}
      />
    );
  };

  const renderTitle = () => {
    return (
      <div style={{ width: "20rem" }}>
        <Typography
          style={{
            fontFamily: "RakutenRoundSemiBold",
            fontSize: "2.5rem",
          }}
        >
          Completed
          <span
            style={{
              fontSize: "0.9rem",
              fontFamily: "RakutenRoundRegular",
            }}
          >
            ({pageCount})
          </span>
        </Typography>
      </div>
    );
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      completedDate: dayjs
        .unix(data.completedDate)
        .format("DD/MM/YYYY HH:mm:ss"),
      expectedCompletionDate: dayjs
        .unix(data.expectedCompletionDate)
        .format("DD/MM/YYYY HH:mm:ss"),
      viewedDate: data.viewedDate
        ? dayjs.unix(data.viewedDate).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
    }));
  };

  const renderDownloadButton = () => {
    const columns = tableColumns.map((column) => ({
      key: column.dataIndex,
      label: column.title,
    }));

    return (
      <CSVLink
        headers={columns}
        data={getDataToDownload(listData)}
        filename="completed_user_list"
        extension=".csv"
      >
        <div
          style={{
            fontSize: 20,
            background: "white",
            padding: ".425rem .7rem",
            borderRadius: "50%",
            marginRight: "1rem",
          }}
        >
          <DownloadOutlined />
        </div>
      </CSVLink>
    );
  };

  const renderMenu = () => {
    return (
      <div className={"elCompleted-header"}>
        <Row style={{ alignItems: "center" }}>
          <Col lg={8}>{renderTitle()}</Col>
          <Col lg={10}></Col>
          <Col lg={1}>{renderDownloadButton()}</Col>
          <Col lg={5}>{renderSearchInput()}</Col>
        </Row>
      </div>
    );
  };

  const renderTableContent = () => {
    return (
      <Table
        columns={tableColumns}
        dataSource={listData}
        pagination={{
          position: ["bottomCenter"],
          showQuickJumper: true,
          showSizeChanger: true,
        }}
      />
    );
  };

  const renderCourseNameAndId = () => {
    return (
      <div style={{ width: "83rem", marginBottom: 8 }}>
        <span style={{ fontFamily: "RakutenRoundBold" }}>
          {courseData?.identityId}
        </span>
        <span>{` - `}</span>
        <span>{courseData?.name}</span>
      </div>
    );
  };
  return (
    <div
      style={{
        marginTop: "7rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Breadcrumb items={crumbs} className={"elCompleted-crumbs"} />
      {renderMenu()}
      {renderCourseNameAndId()}
      <div className={"elCompleted-table"}>{renderTableContent()}</div>
    </div>
  );
}

export default AntdCompletedUsers;

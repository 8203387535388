import React, { useState, useContext } from "react";
import "./AntdEditSubCategoryModal.scss";
import { UserContext } from "../../../../context/UserContext";
import { Button, Modal, Select, Col } from "antd";

function EditSubCategoryModal({
  handleClose,
  show,
  showModal,
  setShowModal,
  category,
  parent,
  acronym,
  updateSubCategory,
  switchModal,
  propTags,
}) {
  const { hasRole } = useContext(UserContext);
  const isSuperAdmin = hasRole(["superAdmin"]);
  const [initialTags, setInitialTags] = useState(propTags);
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  let catAcronym = "";
  let parentCategory = "";
  if (acronym) {
    catAcronym = `${acronym} - `;
  }

  if (parent) {
    parentCategory = ` /${parent}`;
  }
  const onSubmit = () => {
    updateSubCategory(initialTags);
    handleClose();
  };

  const submitDisable = () => {
    setSubmitButtonDisable(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(showModal);

  return (
    <div>
      <Modal
        className="esc-modal"
        title="Edit Sub Categories"
        centered
        width={"50rem"}
        open={isModalOpen}
        footer={null}
        onCancel={handleClose}
      >
        <div>
          {isSuperAdmin && (
            <div
              style={{
                marginTop: "-2.7rem",
                marginLeft: "28rem",
                marginBottom: "3rem",
              }}
            >
              <Button className="esc-switch-button" onClick={switchModal}>
                CHANGE TO EDIT CATEGORY
              </Button>
            </div>
          )}
        </div>
        <div>
          <div className="esc-modal-select_title">
            <Col className="d-flex justify-content-center">
              <p className="esc-subTitlemodal">
                {catAcronym.toUpperCase()}
                {category.toUpperCase()}
              </p>{" "}
              <p className="esc-parentcategorymodal">
                {parentCategory.toUpperCase()}
              </p>
            </Col>
          </div>
          <div>
            <Select
              mode="tags"
              style={{
                width: "100%",
              }}
              placeholder="Add Subcategory"
              onChange={(tags) => {
                setInitialTags(tags);
              }}
              value={initialTags}
              options={[]}
            />
          </div>
        </div>
        <div className="esc-modal-buttons">
          <Button
            className="esc-cancel-button"
            type="button"
            onClick={handleClose}
          >
            CANCEL
          </Button>
          <Button
            className="esc-submit-button"
            type="button"
            onClick={onSubmit}
          >
            SUBMIT
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default EditSubCategoryModal;

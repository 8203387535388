import React, { useEffect, useState } from "react";
import { AutoComplete, Button, Input, Select, notification } from "antd";
import "./addDept.scss";
import config from "../../../config";
import axios from "axios";
import { getUserList } from "./employeeManagementApis";
import { uniqBy } from "lodash";

function AddDepartment({ setIsModalOpen, updateTableData }) {
  const [departmentDetails, setDepartmentDetails] = useState({
    name: null,
    id: null,
    departmentHead: null,
    company: null,
    deptStatus: null,
  });
  const [managerOptions, setManagerOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getUserList().then((newUserList) => {
      setUserList(newUserList.data);
    });
  }, []);

  useEffect(() => {
    const endpoint = `${config.endPoint.userService}/api/companies?pageSize=1000`;

    axios
      .get(endpoint)
      .then((res) => {
        const { data } = res.data;

        const companyOptions = data.map(({ name, id }) => ({
          value: name,
          label: name,
          id,
        }));

        setCompanyOptions(uniqBy(companyOptions, "value"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (key, value) => {
    setDepartmentDetails({ ...departmentDetails, [key]: value });
  };

  const openNotification = () => {
    api.info({
      message: (
        <strong style={{ color: "black" }}>
          Department Added Successfully
        </strong>
      ),
    });
  };

  const renderInpuField = (label, key, placeholder) => {
    return (
      <div
        style={{
          width: "70%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={placeholder}
          value={departmentDetails[key]}
          onChange={(e) => handleInputChange(key, e.target.value)}
          style={{ borderRadius: 0 }}
        />
      </div>
    );
  };

  const onAddDepartment = async () => {
    const payload = {
      name: departmentDetails.name,
      departmentheadId: departmentDetails.departmentHead.id,
      companyID: departmentDetails.company.id,
      statusCode: departmentDetails.deptStatus,
    };

    const endpoint = `${config.endPoint.userService}/api/departments`;
    const addDeptResponse = await axios.post(endpoint, payload);
    if (addDeptResponse.data.status) {
      openNotification();
      setDepartmentDetails({
        name: null,
        id: null,
        deptHeadName: null,
        companyName: null,
        deptStatus: null,
      });
      updateTableData();
    }
    setIsModalOpen(false);
  };

  const filterManagerSuggestion = (text) => {
    const tempUserList = [...userlist];

    const filteredUserList = tempUserList.filter(({ email = "" }) => {
      return email.toLowerCase().includes(text.toLowerCase());
    });

    const mappedManagerOptions = filteredUserList.map((user) => ({
      ...user,
      value: user?.email,
    }));
    setManagerOptions(mappedManagerOptions);
  };

  const renderSelectField = (
    label,
    key,
    defaultValue,
    options,
    disabled = false
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          defaultValue={defaultValue}
          placeholder="Select..."
          disabled={disabled}
          style={{
            width: 120,
            borderRadius: 0,
          }}
          onChange={(value, row) => {
            let updatedDeptDetails = { ...departmentDetails, [key]: row };

            setDepartmentDetails(updatedDeptDetails);
          }}
          value={departmentDetails[key]}
          options={options}
        />
      </div>
    );
  };

  return (
    <div>
      {contextHolder}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
          }}
        >
          {renderInpuField("Dept Name", "name", "Enter Dept name...")}

          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 8 }}
          >
            <span style={{ fontSize: 12, color: "gray" }}>
              Dept Head Email
              <span style={{ color: "red" }}>*</span>
            </span>
            <AutoComplete
              options={uniqBy(managerOptions, "email")}
              style={{
                width: "70%",
                borderRadius: 0,
              }}
              onSelect={(data, row) => {
                setDepartmentDetails({
                  ...departmentDetails,
                  departmentHead: row,
                });
              }}
              onSearch={(text) => filterManagerSuggestion(text)}
              filterOption={(text, option) => {
                return option.email.toLowerCase().includes(text.toLowerCase());
              }}
              placeholder="Enter Dept Head Email.."
              value={departmentDetails?.departmentHead?.email}
              onChange={(data, row) => {
                setDepartmentDetails({
                  ...departmentDetails,
                  departmentHead: row,
                });
              }}
            />
          </div>

          {renderSelectField("Company", "company", undefined, companyOptions)}
          <span style={{ fontSize: 12, color: "gray", marginTop: 8 }}>
            Dept Status<span style={{ color: "red" }}>*</span>
          </span>

          <Select
            placeholder="Select Dept Status..."
            style={{
              width: "70%",
            }}
            className="add-dept-select"
            onChange={(value) =>
              setDepartmentDetails({ ...departmentDetails, deptStatus: value })
            }
            options={[
              {
                value: "1",
                label: "Active",
              },
              {
                value: "0",
                label: "Inactive",
              },
            ]}
            value={departmentDetails.deptStatus}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}
      >
        <Button
          style={{
            margin: 8,
            borderRadius: 0,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{
            margin: 8,
            borderRadius: 0,
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
          type="primary"
          onClick={onAddDepartment}
          className="em-tab-button"
          disabled={
            !departmentDetails.name ||
            !departmentDetails.departmentHead ||
            !departmentDetails.company ||
            !departmentDetails.deptStatus
          }
        >
          Add Department
        </Button>
      </div>
    </div>
  );
}

export default AddDepartment;

import React, { useEffect, useState } from "react";
import "./style.css";
import Select from "react-select";
import PropTypes from "prop-types";

export default function CustomSelect(props) {
  const { options, handleDropdown, selected, disable, placeholder, inlineStyle } = props;
  const [selectedOption, setSelectedOption] = useState(selected);

  const handleChange = (current) => {
    setSelectedOption(current);
    handleDropdown(current);

  };

  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <Select
      isDisabled={disable}
      placeholder={placeholder}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      classNamePrefix={(props.customStyle && props.customStyle) || `innerclass`}
      className={props.customStyle}
      styles={{ menuPortal: base => ({ ...base, ...inlineStyle, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      lable: PropTypes.element.string,
      value: PropTypes.element.string,
    })
  ),
  handleDropdown: PropTypes.func,
  placeholder: PropTypes.any,
  customStyle: PropTypes.string,
};
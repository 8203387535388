import { useContext } from "react";
import TimeFormatter from "../../../utils/timeFormatter";
import { CourseContext } from "../../../context/CourseContext";

function ElCourseMoreDetails({ data }) {
  const { userDateFormat } = useContext(CourseContext);

  return (
    <table>
      <thead>
        <tr>
          <th className={`col-lg-2`}>OVERDUE/ONTIME</th>
          <th className={`col-lg-4`}>EXPECTED COMPLETION DATE & TIME</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div>{data?.courseDueStatus}</div>
          </td>
          <td>
            <div>
              {data.expectedCompletionTimestamp
                ? TimeFormatter.FormatTableTime(
                    data?.expectedCompletionTimestamp,
                    null,
                    userDateFormat
                  )
                : "-"}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ElCourseMoreDetails;

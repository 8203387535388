function convertObjectToArray(data) {
	if (!Object.keys(data).length) {
		return [];
	}
	const newArray = [];
	for (let [value, label] of Object.entries(data)) {
		newArray.push({ value, label });
	}
	return newArray;
}

function formattedDataForCustomSelect(data) {
	if (!data || data.length === 0) return [];

	return data.map((current) => {
		return { value: current.id, label: current.title };
	});
}

function formatCountries(countryGroups) {
	if (!countryGroups || Object.keys(countryGroups).length <= 0) {
		return [];
	}
	const countryGrp = [];
	for (let group in countryGroups) {
		const objArray = [];
		for (let country in countryGroups[group]) {
			const countryObject = {
				groupId: group,
				id: countryGroups[group][country],
				value: country,
			};
			objArray.push(countryObject);
		}
		const obj = {
			groupId: group,
			title: group,
			options: objArray,
		};

		countryGrp.push(obj);
	}
	return countryGrp;
}

// TODO : need to improve
function formatCountryList(countryGroups) {
	if (!countryGroups || countryGroups.length === 0) {
		return [];
	}
	const newData = groupBy(countryGroups, "region");
	const countries = [];
	for (let i in newData) {
		countries.push({
			groupId: i,
			title: i,
			options: newData[i],
		});
	}
	return countries;
}

function groupBy(objectArray, property) {
	return objectArray.reduce((acc, obj) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push({ groupId: obj.region, id: obj.code, value: obj.country });
		return acc;
	}, {});
}

function formatSelectedCountries(countryGroups, selectedCountryIds) {
	const selectedCountries = [];
	if (
		!countryGroups ||
		Object.keys(countryGroups).length <= 0 ||
		selectedCountryIds.length <= 0
	) {
		return [];
	}
	for (let group in countryGroups) {
		for (let country in countryGroups[group]) {
			if (selectedCountryIds.includes(countryGroups[group][country])) {
				selectedCountries.push({
					id: countryGroups[group][country],
					value: country,
				});
			}
		}
	}
	return selectedCountries;
}

function extractContent(html) {
	const span = document.createElement("span");
	span.innerHTML = html;
	return span.textContent || span.innerText;
}

function validateCourseDetails(courseDetails) {
	const errors = {};
	const {
		categoryId,
		subCategoryId,
		countries,
		languages,
		courseType,
		name,
		courseProviderId,
		duration,
		description,
		objectives,
		targetedAudience,
		prerequisites,
		topics,
		recommendations,
	} = courseDetails;
	
	if (!categoryId) {
		errors.category = `Please select Category.`;
	}
	if (!courseType) {
		errors.courseType = `Please select Course Type`;
	}
	if (!subCategoryId) {
		errors.subCategory = `Please select Sub Category`;
	}

	if (!countries || countries.length === 0) {
		errors.country = `Please select atleast one Country`;
	}
	if (!languages || languages.length === 0) {
		errors.language = `Please select at least one Language.`;
	}
	if (!name) {
		errors.courseTitle = `Please enter Course Title`;
	}
	if (!courseProviderId) {
		errors.courseProvider = `Please enter Course Provider`;
	}
	if (!duration) {
		errors.duration = `Please enter Course Duration`;
	}

	if (!(description)) {
		errors.description = `Please enter Course Description`;
	}
	if (!(objectives)) {
		errors.objective = `Please enter Course Objectives`;
	}
	if (!(targetedAudience)) {
		errors.targetedAudience = `Please enter Targeted Audience`;
	}
	if (!(prerequisites)) {
		errors.preRequisites = `Please enter PreRequisites`;
	}
	if (!topics) {
		errors.topics = `Please enter  Topics`;
	}
	if (!(recommendations)) {
		errors.recommendations = `Please enter Recommended Course`;
	}
	return errors;
}

export {
	convertObjectToArray,
	formatCountries,
	formatSelectedCountries,
	formatCountryList,
	formattedDataForCustomSelect,
	validateCourseDetails,
	extractContent,
};

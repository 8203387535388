import { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Row, Tooltip, Typography } from "antd";

import { ReactComponent as LearningPath } from "../../../../assets/icons/learning_path.svg";
import {
  addFavorite,
  deleteFavorite,
} from "../../../../api/courseDescriptionBanner";
import "./courseDescriptionBanner.scss";
import truncate from "lodash/truncate";
import round from "lodash/round";
import { UserContext } from "../../../../context/UserContext";
import CourseDetailsSVGBackground from "../../../../components/common/CourseDetailsSvgBackground";

const CourseDescriptionBanner = (props) => {
  const { pathDetail, userId, setAlert, setAlertType } = props;

  const { userConfig } = useContext(UserContext);

  const {
    courses = [],
    totalDuration = 0,
    completedUserCount = 0,
    language,
    id: pathId,
  } = pathDetail;

  const [isFavorite, setIsFavorite] = useState(pathDetail?.isFavourite);

  useEffect(() => {
    setIsFavorite(pathDetail?.isFavourite);
  }, [pathDetail?.isFavourite]);

  const addFavourite = async (userId, learningPathId) => {
    try {
      await addFavorite(userId, learningPathId);
    } catch (error) {
      setAlert(
        error?.response?.data || "Something went wrong, please try again"
      );
      setAlertType("warning");
    }
  };

  const deleteFavourite = async (userId, learningPathId) => {
    try {
      await deleteFavorite(userId, learningPathId);
    } catch (error) {
      setAlert(
        error?.response?.data || "Something went wrong, please try again"
      );
      setAlertType("warning");
    }
  };

  const renderBreadcrumbs = () => (
    <div className="custom-bread-crumb">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a
            href="/"
            style={{ fontFamily: "RakutenRoundRegular" }}
            className="common-breadcrumbs-color"
          >
            Home
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {/* Todo: Move inline to .css */}
          <a
            href="/LearningPath"
            style={{ fontFamily: "RakutenRoundRegular" }}
            className="common-breadcrumbs-color"
          >
            Learning Paths
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Tooltip placement="bottom" title={pathDetail.title}>
            <span style={{ color: "black" }}>
              {pathDetail?.title
                ? truncate(pathDetail.title, { length: 23 })
                : `Path Detail`}
            </span>
          </Tooltip>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const renderTitle = () => {
    return (
      <div className="title-container">
        <Col>
          <span>
            <LearningPath />
          </span>
          <span
            className="title-heading"
            style={{ fontFamily: "RakutenRoundRegular" }}
          >
            LEARNING PATHS
          </span>
        </Col>
        <Col>
          <Typography
            className="title-value"
            style={{ fontFamily: "RakutenRoundRegular" }}
          >
            {pathDetail.title}
          </Typography>
        </Col>
      </div>
    );
  };

  const renderLanguageAndGroup = () => {
    return (
      <Col className="language-header-container">
        <span
          className="language"
          style={{ fontFamily: "RakutenRoundRegular" }}
        >
          {(language?.name || "NA")?.toUpperCase()}
        </span>
      </Col>
    );
  };

  const renderCoursesAndHours = () => {
    const durationWithPrecision = round(Number(totalDuration), 2) || "NA";
    return (
      <Col className="course-hours-container">
        <span
          className="course-container"
          style={{ fontFamily: "RakutenRoundRegular" }}
        >
          <span className="course">{courses.length}</span> COURSES
        </span>

        <span
          className="hours-container"
          style={{ fontFamily: "RakutenRoundRegular" }}
        >
          <span className="hours">{durationWithPrecision}</span> HOURS
        </span>
      </Col>
    );
  };

  const renderCompletedUsersCount = () => {
    const { userCountry, countries, learningpathStatus } = pathDetail;
    const isCourseAvailableInUserRegion = countries?.includes(userCountry);
    const isLearningPathActive = learningpathStatus === "active";

    return (
      <Row className="completed-user-count-container" span={24}>
        <Col span={window.innerWidth > 867 ? 18 : 24}>
          {!isLearningPathActive ? (
            <span style={{ fontFamily: "RakutenRoundRegular" }}>
              Learning path is <strong>not active</strong>
            </span>
          ) : isCourseAvailableInUserRegion ? (
            <span style={{ fontFamily: "RakutenRoundRegular" }}>
              <strong>{completedUserCount}</strong> already completed
            </span>
          ) : (
            <pre className="region-error-text">
              {`The Learning path is currently NOT AVAILABLE \nin your region`}
            </pre>
          )}
        </Col>
        {renderBookmarkContainer()}
      </Row>
    );
  };

  const renderBookmarkContainer = () => {
    return (
      <Col span={window.innerWidth > 867 ? 6 : 24}>
        {isFavorite ? (
          <Button
            type="default"
            className="bookmark-button"
            style={{ fontFamily: "RakutenRoundRegular", background: "none" }}
            onClick={() => {
              setIsFavorite(false);
              deleteFavourite(userId, pathId);
            }}
          >
            BOOKMARKED
            <i className="fa fa-bookmark bookmark-icon"></i>
          </Button>
        ) : (
          <Button
            type="default"
            onClick={() => {
              setIsFavorite(true);
              addFavourite(userId, pathId);
            }}
            style={{ fontFamily: "RakutenRoundRegular", background: "none" }}
            className="bookmark-button"
          >
            ADD BOOKMARK
            <i className="fa fa-bookmark-o bookmark-icon"></i>
          </Button>
        )}
      </Col>
    );
  };

  return (
    <>
      <CourseDetailsSVGBackground
        color1={
          pathDetail.isPublic ? userConfig?.primary : userConfig?.secondary
        }
      >
        <Row span={24}>
          <Col
            span={window.innerWidth > 867 ? 15 : 24}
            className="course-description-banner-content"
          >
            <Row>{renderBreadcrumbs()}</Row>
            <Row>{renderTitle()}</Row>
            <Row>{renderLanguageAndGroup()}</Row>
            <Row>{renderCoursesAndHours()}</Row>
            <Row>{renderCompletedUsersCount()}</Row>
          </Col>
          <Col
            span={window.innerWidth > 867 ? 9 : 24}
            className="banner-image-container"
          >
            <img
              src={pathDetail.image || `${process.env.PUBLIC_URL}/default.png`}
              alt="courseIcon"
              className="banner-image"
            />
          </Col>
        </Row>
      </CourseDetailsSVGBackground>
    </>
  );
};

export default CourseDescriptionBanner;

import React, { useState, useEffect, useContext, useId } from "react";
import {
  Row,
  Col,
  Alert,
  Breadcrumb,
  Button,
  Typography,
  Tabs,
  Table,
  Input,
  Tooltip,
} from "antd";
import TimeFormatter from "../../../utils/timeFormatter";
import editIcon from "../../../assets/icons/edit_icon.svg";
import deleteIcon from "../../../assets/icons/delete_outline.svg";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { CourseContext } from "../../../context/CourseContext";
import config from "../../../config";
import "./AntdAdminPromotionalBanner.scss";
import {
  AdminConfirmationModal,
  AdminDataTable,
  AdminToggleFilter,
  InlineIconButton,
} from "../../../component-library";
import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="promotional-banner-crumbs">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  { title: "Promotional Banner" },
];
const { TabPane } = Tabs;
const tableSearch = {
  top: "-10.5rem",
  left: "52rem",
  width: "12.5rem",
};

const defaultSorted = [
  {
    dataField: "updated_timestamp",
    order: "desc",
  },
];

const filterMenu = [
  { name: "All", value: "1" },
  { name: "Yet to start", value: "2" },
  { name: "Running", value: "3" },
  { name: "Completed", value: "4" },
];

const getTableColMeta = (
  countryMap,
  history,
  userId,
  userCountry,
  isSuperAdmin,
  deleteHandler,
  dateFormat
) => {
  const tableMeta = [
    {
      dataIndex: "imageUrl",
      title: "Image Preview",
      // sorter: (a, b) => a.image_url.localeCompare(b.image_url),
      width: 200,
      render: (cell, row) => {
        return (
          <img src={cell} width="80" height="60" alt="promotional banner" />
        );
      },
    },

    {
      dataIndex: "startDate",
      title: "Start Date",
      width: 120,
      sorter: (a, b) => a.startDate - b.startDate,
      render: (cell, row) => {
        return <>{TimeFormatter.FormatDate(cell, null, dateFormat)}</>;
      },
    },
    {
      dataIndex: "updated_timestamp",
      title: "Updated Timestamp",
      width: 200,
      // TODO make it updatedated time
      sorter: (a, b) => a.createdTimestamp - b.createdTimestamp,
      hidden: true,
      render: (cell, row) => {
        return <>{TimeFormatter.FormatDate(cell, null, dateFormat)}</>;
      },
    },
    {
      dataIndex: "endDate",
      title: "End Date",
      width: 120,
      sorter: (a, b) => a.endDate - b.endDate,
      render: (cell, row) => {
        return <>{TimeFormatter.FormatDate(cell, null, dateFormat)}</>;
      },
    },
    {
      dataIndex: "priority",
      title: "Priority",
      width: 100,
    },
    {
      dataIndex: "redirectionUrl",
      title: "Reference URL",
      width: 200,
      render: (cell, row) => {
        const link = !(
          row.redirectionUrl.indexOf("http://") === 0 ||
          row.redirectionUrl.indexOf("https://") === 0
        )
          ? `//${row.redirectionUrl}`
          : row.redirectionUrl;
        return (
          <>
            {" "}
            <a href={link} target="_blank" rel="noreferrer">
              {row.redirectionUrl.length < 50
                ? row.redirectionUrl.substring(0, 50)
                : row.redirectionUrl.substring(0, 50) + "..."}{" "}
            </a>
          </>
        );
      },
    },
    {
      dataIndex: "countries",
      title: "Regions",
      width: 150,
      ellipsis: true,
      formatExtraData: countryMap,
      render: (countries) => (
        <Tooltip placement="topLeft" title={countries.join(", ")}>
          {countries.join(", ")}
        </Tooltip>
      ),
    },
    {
      dataIndex: "id",
      title: "Action",
      sort: false,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      headerStyle: {
        width: "6rem",
      },
      formatExtraData: history,
      render: (cell, row, i, historyObj) => {
        return (
          <>
            {row.createdBy === userId ||
            (isSuperAdmin && row.countries.includes(userCountry)) ? (
              <>
                <span>
                  <InlineIconButton
                    iconURL={editIcon}
                    alt="edit button"
                    clickHandler={(event) => {
                      openBanner(history, "edit", row.id);
                    }}
                  />
                </span>
                <span>
                  <InlineIconButton
                    iconURL={deleteIcon}
                    alt="delete button"
                    clickHandler={(event) => {
                      deleteHandler({ id: row.id, image: row.imageUrl });
                    }}
                  />
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];

  return tableMeta;
};

const openBanner = (history, type, bannerId = null) => {
  let url = "";
  if (type === "add") url = "/Admin/AddEditBanner";
  if (type === "edit") url = `/Admin/AddEditBanner?bannerId=${bannerId}`;
  history.push(url);
};

export default function AdminPromotionalBanner() {
  const history = useHistory();
  const location = useLocation();
  const { countryList, userDateFormat } = useContext(CourseContext);
  const [listData, setListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [countryMap, setCountryMap] = useState({});
  const [tableColumns, setTableColumns] = useState(
    getTableColMeta({}, history)
  );
  const [showAlert, setShowAlert] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [bottom, setBottom] = useState("bottomCenter");
  const [showLoader, setShowLoader] = useState(true);
  const [showDeleteConfimation, setShowDeleteConfirmation] = useState(false);
  const [deleteBannerId, setDeleteBannerId] = useState(null);
  const [deleteBannerImage, setDeleteBannerImage] = useState(null);
  const { userId, userCountry, hasRole } = useContext(UserContext);
  const [currentFilter, setCurrentFilter] = useState(1);
  const [inputSearchText, setInputSearchText] = useState(null);

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setAlertType(location.state.alertType);
        setShowAlert(true);
        setPageLoading(false);
        history.push({ state: {} });
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  useEffect(() => {
    let mapData = {};
    countryList &&
      countryList.forEach((element) => {
        let { code, country } = element;
        mapData[code] = country;
      });
    setCountryMap(mapData);
  }, [countryList]);

  useEffect(() => {
    if (
      countryMap &&
      Object.keys(countryMap).length &&
      history &&
      Object.keys(history).length
    ) {
      let tableColMeta = getTableColMeta(
        countryMap,
        history,
        userId,
        userCountry,
        hasRole(["superAdmin"]),
        handleDeleteBanner,
        userDateFormat
      );
      setTableColumns(tableColMeta);
    }
    // eslint-disable-next-line
  }, [countryMap, history, userDateFormat, userId, userCountry]);

  const filterData = (data, targetValue) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    let filteredData = [];
    switch (Number(targetValue)) {
      case 1:
        filteredData = [...data];
        setFilteredListData(filteredData);
        break;
      case 2:
        filteredData = data.filter((item) => {
          return item.start_date_time > currentTimestamp ? true : false;
        });
        setFilteredListData(filteredData);
        break;
      case 3:
        filteredData = data.filter((item) => {
          return currentTimestamp > item.startDate &&
            currentTimestamp < item.endDate
            ? true
            : false;
        });
        setFilteredListData(filteredData);
        break;
      case 4:
        filteredData = data.filter((item) => {
          return currentTimestamp > item.endDate ? true : false;
        });
        setFilteredListData(filteredData);
        break;
      default:
        filteredData = [...data];
        setFilteredListData(filteredData);
        break;
    }
    // setFilteredListData(filteredData);
    setPageCount(filteredData.length);
  };

  const fetchData = () => {
    axios
      .get(`${config.endPoint.commonService}/promotionalBanners`)
      .then((response) => {
        const { promotional = [] } = response.data.data;
        setListData(promotional);
        setTableData(promotional);
        setShowLoader(false);
        filterData(promotional, currentFilter);
      })
      .catch((error) => {
        setAlertType("error");
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
        setShowAlert(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPageFilterChange = (targetValue) => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    let filteredData = [];
    switch (Number(targetValue)) {
      case 1:
        filteredData = [...listData];
        setFilteredListData(filteredData);
        break;
      case 2:
        filteredData = listData.filter((item) => {
          return item.startDate > currentTimestamp ? true : false;
        });
        setFilteredListData(filteredData);
        break;
      case 3:
        filteredData = listData.filter((item) => {
          return currentTimestamp > item.startDate &&
            currentTimestamp < item.endDate
            ? true
            : false;
        });
        setFilteredListData(filteredData);
        break;
      case 4:
        filteredData = listData.filter((item) => {
          return currentTimestamp > item.endDate ? true : false;
        });
        setFilteredListData(filteredData);
        break;
      default:
        filteredData = [...listData];
        setFilteredListData(filteredData);
        break;
    }
    // setFilteredListData(filteredData);
    setTableData(filteredData);
    setPageCount(filteredData.length);
  };

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(e) => {
          setInputSearchText(null);
          setCurrentFilter(Number(e));
          onPageFilterChange(e);
        }}
        className={"promotionalbanner-tabs"}
      >
        <TabPane tab="All" key="1"></TabPane>
        <TabPane tab="Yet to start" key="2"></TabPane>
        <TabPane tab="Running" key="3"></TabPane>
        <TabPane tab="Completed" key="4"></TabPane>
      </Tabs>
    );
  };

  const handleSearch = (searchText) => {
    setInputSearchText(searchText);
    const copyFilteredData = [...tableData];
    setTableData(copyFilteredData);
    const filteredList = filteredListData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setFilteredListData(tableData);
    } else {
      setFilteredListData(filteredList);
    }
  };

  const tableHeader = (
    <>
      <Row style={{ marginTop: "2rem" }}>
        <Col lg={9} className="promotionalbanner-pagetitle">
          <Typography className="promotionalbanner-pagetitle">
            Promotional Banner
          </Typography>
          <Typography className="promotionalbanner-pageCount">
            ({pageCount})
          </Typography>
        </Col>
        <Col lg={9}>{toggleFilter()}</Col>
        <Col lg={1} className="promotionalbanner-searchbar">
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className="promotionalbanner-searchstyle"
            value={inputSearchText}
          />
        </Col>
        <Col lg={2} className="promotionalbanner-addNewBannerbutton">
          <div>
            <Button
              type="button"
              className="promotionalbanner-addNewBanner"
              icon={<PlusCircleOutlined />}
              onClick={() => openBanner(history, "add")}
            >
              Add New Banner
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );

  const modalContent = (
    <>
      <Row>
        <Col className="modalContentStyle">
          <p>
            Are you sure you want to delete the following promotional banner?
          </p>
          <p>
            <img
              src={deleteBannerImage}
              width="80"
              height="60"
              alt="promotional banner"
            />
          </p>
        </Col>
      </Row>
    </>
  );

  const onDeleteRequest = () => {
    axios
      .delete(
        `${config.endPoint.commonService}/promotionalBanners/deleteBanner`,
        {
          data: { id: deleteBannerId },
        }
      )
      .then((res) => {
        setAlertType("success");
        setAlert("Deleted promotional banner");
        setShowAlert(true);
        setDeleteBannerId(null);
        setDeleteBannerImage(null);
        setShowDeleteConfirmation(false);
        fetchData();
      })
      .catch((error) => {
        try {
          setAlert(error.response.data.message);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          setShowAlert(true);
          setShowDeleteConfirmation(false);
        }
      });
    setShowDeleteConfirmation(false);
  };

  const handleDeleteBanner = (data) => {
    setDeleteBannerId(data.id);
    setDeleteBannerImage(data.image);
    setShowDeleteConfirmation(true);
  };

  return (
    <div className="antd-adminpromotionalbanner-container">
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          className={"adminpromotionalbanner-alert"}
          onCloseAlert={() => setAlert("")}
        />
      )}
      <Breadcrumb
        className="promotionalbanner-breadcrumb"
        style={{ fontFamily: "RakutenRoundRegular", color: "#00A0F0" }}
        items={crumbs}
      />
      {countryMap && Object.keys(countryMap).length && (
        <div style={{ width: "95%" }}>
          {tableHeader}
          <div className="promotionalbanner-table">
            <Table
              rowKey="id"
              columns={tableColumns}
              dataSource={filteredListData}
              loading={showLoader}
              pagination={{
                position: [bottom],
                showQuickJumper: true,
              }}
            />
          </div>
          {showDeleteConfimation ? (
            <AdminConfirmationModal
              toggle={true}
              title="Delete "
              titleBold="Promotinal Banner"
              show={showDeleteConfimation}
              leftButton="YES"
              rightButton="NO"
              handleClose={() => setShowDeleteConfirmation(false)}
              rightButtonClick={() => setShowDeleteConfirmation(false)}
              leftButtonClick={onDeleteRequest}
              modalContent={modalContent}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}

import GrowRoutes from "./GrowRoutes";
import { useContext, useEffect } from "react";
import React, {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import RenderOnAuthenticated from "./Router/RenderOnAuthenticated";
import PageFooter from "../component-library/pageFooter/PageFooter";
import PageHeader from "../component-library/pageHeader/PageHeader";
// import PageHeader from "../component-library/pageHeader/AntdPageHeader";
import ScrollToTop from "./../component-library/scrollToTop/ScrollToTop";
import { LoadingContext } from "../pages/LoadingContext";
import { notification } from "antd";
import "./appRouter.scss";

function AppRouter() {
  const { getPageHeader } = useContext(LoadingContext);
  const isStagingEnv =
    window.location.hostname === "stg-ext-grow-knowledge.tech.rakuten-it.com";

  const [api, contextHolder] = notification.useNotification({
    stack: false,
    maxCount: 1,
  });

  const openNotification = (placement) => {
    api.warning({
      message: (
        <strong style={{ color: "black" }}>
          This is staging environment not production.
        </strong>
      ),
      placement,
      duration: null,
      style: { opacity: "70%", backgroundColor: "#00a0ef" },
    });
  };

  useEffect(() => {
    openNotification("bottomRight");
  }, [window.location.href]);

  return (
    <Router>
      <Route path="/" exact />
      <RenderOnAuthenticated>
        {isStagingEnv && contextHolder}
        <ScrollToTop />
        <PageHeader active={getPageHeader()} />
        <Switch>
          {GrowRoutes}
          <Redirect to="/home" />
        </Switch>
        <PageFooter />
      </RenderOnAuthenticated>
    </Router>
  );
}

export default AppRouter;

import axios from "axios";
import { getFormattedCourseCompletionTime } from "../pages/admin/learnerHistory/learnerHistoryUtils";

const getEndpoint = (search, pageNumber, pageSize, sortBy, sort) => {
  let baseUrl = `api/user/userList?`;

  if (search) {
    if (typeof search === "string") {
      baseUrl += `search=${search}`;
    } else {
      baseUrl += `search=${JSON.stringify(search)}`;
    }
  }

  if (pageNumber) baseUrl += `&pageNumber=${pageNumber}`;
  if (pageSize) baseUrl += `&pageSize=${pageSize}`;
  if (sort !== undefined && sort !== "default") {
    if (sort) baseUrl += `&sort=${sort}`;
    if (sortBy) baseUrl += `&sortBy=${sortBy}`;
  }

  return baseUrl;
};

export const getUserList = ({
  search = {},
  pageNumber = 1,
  pageSize = 1000,
  sortBy,
  sort,
}) => {
  const endpoint = getEndpoint(search, pageNumber, pageSize, sortBy, sort);

  return axios
    .get(endpoint, {
      baseURL: process.env.REACT_APP_USER_INFO_URL,
    })
    .then(({ data }) => {
      const { data: userListData, size } = data;
      const updatedUserList = userListData.map((user) => ({
        ...user,
        key: user.id,
      }));

      return { userList: updatedUserList, userSize: size };
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getEmployeeCourseDetails = (selectedEmployee) => {
  const url = `/user/userHistory/${selectedEmployee}`;

  return axios
    .get(url, {
      baseURL: process.env.REACT_APP_COMMON_SERVICE,
    })
    .then(({ data }) => {
      const { data: userCourseDetails } = data;
      const formattedCourseDetails = userCourseDetails.map((course) => {
        let { completedDate } = course;
        let completedDateTime = null;

        if (completedDate) {
          completedDateTime = getFormattedCourseCompletionTime(completedDate);
        }

        return { ...course, completedDate: completedDateTime };
      });
      return formattedCourseDetails;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

export const getDepartments = () => {
  let endpoint = `api/user/departmentDetails`;
  return axios
    .get(endpoint, {
      baseURL: process.env.REACT_APP_USER_INFO_URL,
    })
    .then(({ data }) => {
      return data.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};

import "./CourseImageList.scss";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Tag, Tooltip } from "antd";
import { truncate } from "lodash";
import { UserContext } from "../../../context/UserContext";
import { HiOutlinePencilSquare } from "react-icons/hi2";

function CourseImageTagComponent({
  imageSrc,
  imgName,
  handleClick,
  tags,
  otherStyle,
}) {
  const [tagItems, setTagItems] = useState([]);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    for (let index = 0; index < tags.length; index++) {
      const localTagItem = (
        <Tag className={"tagItem"} key={index}>
          {tags[index]}
        </Tag>
      );

      setTagItems((tagItems) => [...tagItems, localTagItem]);
    }
  }, [tags, imgName]);

  const renderMoreTags = (tags) => {
    const [_, ...otherTags] = tags;

    return otherTags.map((tag) => (
      <Tag
        style={{
          backgroundColor: "white",
          margin: ".3rem",
          fontFamily: "RakutenRoundRegular",
          fontSize: "0.9rem",
        }}
      >
        {" "}
        {tag}
      </Tag>
    ));
  };

  return (
    <>
      <div style={otherStyle} className={"basicStyle"}>
        <span className={"editButton"} onClick={handleClick}>
          <HiOutlinePencilSquare className={"editButtonImage"} />
        </span>
        <img src={imageSrc} className={"mainImage"} alt="" />
        <Tooltip title={tags[0]}>
          <Tag
            style={{ fontFamily: "RakutenRoundRegular", fontSize: "0.9rem" }}
          >
            {truncate(tags[0], {
              length: 15,
            })}
          </Tag>
        </Tooltip>
        <Tooltip title={renderMoreTags(tags)}>
          {tags.length > 1 && (
            <Tag
              color={userConfig.primary}
              style={{ fontFamily: "RakutenRoundRegular", fontSize: "0.8rem" }}
            >
              + {tags.length - 1} more
            </Tag>
          )}
        </Tooltip>
      </div>
    </>
  );
}

CourseImageTagComponent.propTypes = {
  imageSrc: PropTypes.string,
  imgName: PropTypes.string,
  handleClick: PropTypes.func,
  tags: PropTypes.array,
  otherStyle: PropTypes.object,
};

export default CourseImageTagComponent;

import axios from "axios";
import config from "../config";

export const addFavorite = async (userId, learningPathId) => {
  const endPoint = `${config.endPoint.commonService}/favourite`;

  return await axios.post(endPoint, [
    {
      userId,
      entityType: "LP",
      entityId: learningPathId,
    },
  ]);
};

export const deleteFavorite = async (userId, learningPathId) => {
  const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=LP&courseId=${learningPathId}`;
  return await axios.delete(endPoint);
};

import React, { useState, useEffect } from "react";
import InstructorLedCourse from "../../../../assets/icons/InstructorLedCourse.svg";
import style from "./CoursesInProgress.module.css";
import TimeFormatter from "./../../../../utils/timeFormatter";
import { Link } from "react-router-dom";
import "./courseInProgress.scss";

function CoursesInProgress({ courseList = [], userConfig }) {
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const { primary } = userConfig;

  useEffect(() => {
    if (courseList.length === 0) {
    } else {
      courseList.forEach((course) => {
        const courseImage =
          course.image || `${process.env.PUBLIC_URL}/default.png`;
        const courseDetailUrl = `course-details/${course.id}`;
        const courseItem = (
          <Link className={style.courseTile} to={courseDetailUrl}>
            <div className={style.subheading}>
              <img
                className={style.subheadingImage}
                src={InstructorLedCourse}
                alt="Intructor Led"
              />
              <span className={style.subheadingText}>COURSE</span>
            </div>
            <div className={"courseName"}>{course.courseName}</div>
            <div className={style.seesionStart}>
              Starts at {TimeFormatter.getLongDateTime(course.sessionStart)}
            </div>
            <div
              className={style.courseImage}
              style={{
                backgroundImage: `url(${courseImage})`,
              }}
            ></div>
          </Link>
        );
        if (upcomingSessions.length < 2) {
          setUpcomingSessions([...upcomingSessions, courseItem]);
        }
      });
    }
    // eslint-disable-next-line
  }, [courseList]);

  return (
    <div>
      {courseList.length === 0 && (
        <div className="parent-container">
          <div className="enrollment-container">
            <p className="enrollment-status">
              You are not enrolled to any course yet!
            </p>
            <p className="enrollment-sub-heading">
              Choose from the most popular industry trends to keep yourself upto
              date with the ever changing landscape. Learning Management System
              platform will help you connect the dots with the best in className
              instructors.
            </p>
          </div>
          <div>
            <div className="placeholderFlowContainer">
              <div className="placeholderFlowFirstCircle">
                <span
                  className="placeholderFlowFirstText"
                  style={{ color: primary }}
                >
                  Explore & Enroll
                </span>
              </div>
              <div className="placeholderFlowSecondCircle">
                <span
                  className="placeholderFlowSecondText"
                  style={{ color: primary }}
                >
                  Get Confirmation via Email
                </span>
              </div>
              <div className="placeholderFlowSecondCircle">
                <span
                  className="placeholderFlowThirdText"
                  style={{ color: primary }}
                >
                  Attend the Course and Learn
                </span>
              </div>
              <div className="placeholderLineContainer">
                <hr className="placeholderLineHr" />
                <span className="placeholderLineText">
                  LEARN. ENGAGE. EXPLORE.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {courseList.length > 0 && (
        <div className={"headerContainer1"}>
          <div
            className={"headerContainer"}
            style={{ display: "flex", justifyContent: "space-between" }}
            // Added as a hotfix., need to this as a part of code cleanup
          >
            <div className={"courses-taking-header"}>
              Courses You’re Taking!
            </div>
            <a className={"myLearning"} href="/MyLearnings">
              MY LEARNINGS
            </a>
          </div>
          <div className={"mobileScroll upcoming-session-lg-screen"}>
            {upcomingSessions}
          </div>
        </div>
      )}
    </div>
  );
}

export default CoursesInProgress;

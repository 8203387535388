import { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import UtilityFunctions from "../../../utils/utility";
import "./attendanceHome.scss"

const { Search } = Input;

export const AttendanceHomeSearchInput = ({
    value,
    onSearch,
    placeHolder,
    size,
}) => {
    const [localValue, setLocalValue] = useState(null);

    useEffect(() => {
        setLocalValue(value?.trim());
    }, [value]);

    const handleSearch = useCallback(
        (text) => {
            onSearch(text);
        },
        [onSearch]
    );

    const optimizedSearch = useCallback(
        UtilityFunctions.debounce((text) => handleSearch(text), 500),
        [handleSearch]
    );

    const handleLocalSearch = (e, isNativeEvent) => {
        let text = isNativeEvent ? e.target.value : e;
        text = text || null;
        setLocalValue(text);
        optimizedSearch(text);
    };

    return (
        <Search
            placeholder={placeHolder || "input search text"}
            allowClear
            onChange={(e) => handleLocalSearch(e, true)}
            onSearch={(e) => handleLocalSearch(e)}
            size={size || "large"}
            style={{
                width: "100%",
            }}
            value={localValue}
        />
    );
};

export default AttendanceHomeSearchInput;
import React, { useState, createContext } from "react";

const LoadingContext = createContext();

function LoadingContextProvider({ children }) {
  const [showLoading, setShowLoading] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const setPageLoader = (val) => {
    setShowLoading(val);
  };
  const getPageLoader = () => {
    return showLoading;
  };
  const setPageHeader = (val) => {
    setShowHeader(val);
  };
  const getPageHeader = () => {
    return showHeader;
  };

  return (
    <LoadingContext.Provider
      value={{ setPageLoader, getPageLoader, setPageHeader, getPageHeader }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export { LoadingContext, LoadingContextProvider };

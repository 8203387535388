import { useState, createContext, useEffect } from "react";
import config from "../config";
import axios from "axios";
const CategoryContext = createContext();

function CategoryContextProvider(props) {
  const [categoryResponse, setCategoryResponse] = useState();

  useEffect(() => {
    const catergoryEndpoint = `${config.endPoint.commonService}/category`;

    axios
      .get(catergoryEndpoint)
      .then((res) => {
        setCategoryResponse(res);
      })
      .catch((err) => {
        // handle error
      });
  }, []);

  return (
    <CategoryContext.Provider value={{ categoryResponse }}>
      {props.children}
    </CategoryContext.Provider>
  );
}

export { CategoryContext, CategoryContextProvider };

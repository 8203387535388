import {
  Breadcrumb,
  Button,
  Card,
  ColorPicker,
  Input,
  Select,
  Switch,
  notification,
} from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import config from "../../../config";
import "./settings.scss";

const crumbs = [
  {
    title: (
      <a href="/Admin" className="promotional-banner-crumbs">
        Admin Dashboard
      </a>
    ),
    to: "/Admin",
  },
  { title: "Settings" },
];

const defaultLearningPlatformOptions = [
  {
    value: "udemy",
    label: "Udemy",
  },
  {
    value: "pluralsight",
    label: "Pluralsight",
  },
  {
    value: "coursera",
    label: "Coursera",
  },
  {
    value: "linkedinLearning",
    label: "LinkedIn Learning",
  },
  // {
  //   value: "udacity",
  //   label: "Udacity",
  // },
  // {
  //   value: "skillshare",
  //   label: "SkillShare",
  // },
  // {
  //   value: "edx",
  //   label: "Edx",
  // },
];

function Settings() {
  const { userConfig, tenantID  } = useContext(UserContext);
  const [isIntegrationEnabled, setIntegrationEnabled] = useState()
  const [colorConfig, setColorConfig] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [showQuotation, setShowQuotation] = useState(true);
  const [quotation, setQuotation] = useState({});
  const [learningPlatforms, setLearningPlatforms] = useState([]);
  const [learningPlatformOptions, setLearningPlatformOptions] = useState(
    defaultLearningPlatformOptions
  );

  console.log(learningPlatformOptions, "...learningPlatformOptions");
  useEffect(() => {
    if (userConfig.primary || userConfig.secondary || userConfig.tertiary) {
      setColorConfig({
        primary: userConfig.primary,
        secondary: userConfig.secondary,
        tertiary: userConfig.tertiary,
      });
    }
    if (userConfig.quotationTitle && userConfig.quotationAuthor) {
      setQuotation({
        showQuotation: userConfig.showQuotation,
        title: userConfig.quotationTitle,
        author: userConfig.quotationAuthor,
        designation: userConfig.quotationAuthorDesignation,
      });
    }

    if (userConfig?.learningPlatforms?.length > 0) {
      const formattedLearningPlatforms = userConfig?.learningPlatforms.map(
        (lp) => {
          return { ...lp, value: lp.name, id: Math.random() * 10000000 };
        }
      );
      setLearningPlatforms(formattedLearningPlatforms);
    }
  }, [userConfig]);

  useEffect(() => {
    const selectedPlatforms = learningPlatforms.map(
      (platform) => platform.value
    );

    const filteredLearningPlatformOptions =
      defaultLearningPlatformOptions.filter((platformOption) => {
        return !selectedPlatforms.includes(platformOption.value);
      });

    setLearningPlatformOptions(filteredLearningPlatformOptions);
  }, [learningPlatforms]);

  const openNotification = (placement) => {
    api.info({
      message: (
        <strong style={{ color: "black" }}>
          Settings updated successfully!
        </strong>
      ),
    });
  };

  function validateURL(urlString) {
    try {
      // Attempt to create a new URL object
      const url = new URL(urlString);
      return true; // If no error is thrown, the URL is valid
    } catch (err) {
      return false; // If an error is thrown, the URL is invalid
    }
  }

  const isValidFields = () => {
    let isValid = true;
    learningPlatforms.forEach((platform) => {
      if (!validateURL(platform.url)) {
        isValid = false;
      }
    });
    return isValid;
  };

  return (
    <div style={{ marginTop: "8rem" }}>
      {contextHolder}
      <div style={{ marginLeft: "4rem", marginBottom: "1rem" }}>
        <Breadcrumb items={crumbs} />
      </div>
      <div
        style={{
          marginLeft: "4rem",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <span
          style={{ fontFamily: "RakutenRoundSemiBold", fontSize: "2.5rem" }}
        >
          Settings
        </span>
        <Button
          type="primary"
          onClick={async () => {
            // add axios to update user config to backend settings
            // adding local storage for testing purposes
            if (!isValidFields()) {
              api.error({
                message: (
                  <strong style={{ color: "black" }}>
                    Failed to update settings. Invalid urls present in learning
                    platforms.
                  </strong>
                ),
              });
            } else {
              const uiConfig = await axios.get(
                `${config.endPoint.configManager}/api/tenants/${tenantID}?attributes=frontend`
              );

              const frontendConfig = {
                ...uiConfig.data.data.frontendConfig,
              };

            const hrmC = {
              ...uiConfig.data.data.hrmConfig
            }

              await axios.patch(
                `${config.endPoint.configManager}/api/tenants/${tenantID}`,
                {
                  frontendConfig: {
                    ...frontendConfig,
                    colors: {
                      ...frontendConfig.colors,
                      primary: colorConfig.primary,
                      secondary: colorConfig.secondary,
                    },
                    quotation,
                    learningPlatforms,
                  },
                }
              );

              localStorage.setItem("colorConfig", JSON.stringify(colorConfig));
              openNotification("bottomTop");
            }
          }}
          style={{
            background: userConfig?.primary,
            borderRadius: 0,
            marginRight: "2rem",
            padding: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          Update Config
        </Button>
      </div>
      <div
        style={{
          background: "white",
          margin: "1rem 2rem",
          paddingTop: "1rem",
        }}
      >
        <span
          style={{
            fontFamily: "RakutenRoundSemiBold",
            fontSize: "2.5rem",
            margin: "4rem 2rem",
          }}
        >
          Colors
        </span>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "8rem",
              margin: "3rem 2rem",
            }}
          >
            <span>Primary Color</span>
            <ColorPicker
              defaultValue={userConfig.primary}
              size="large"
              showText
              onChange={(value, hexString) => {
                setColorConfig({ ...colorConfig, primary: hexString });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "8rem",
              margin: "3rem 2rem",
            }}
          >
            <span>Secondary Color</span>
            <ColorPicker
              defaultValue={userConfig.secondary || "#808080"}
              size="large"
              showText
              onChange={(value, hexString) => {
                setColorConfig({ ...colorConfig, secondary: hexString });
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          background: "white",
          margin: "1rem 2rem",
          paddingTop: "1rem",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <span
          style={{
            fontFamily: "RakutenRoundSemiBold",
            fontSize: "2.5rem",
            margin: "4rem 2rem",
          }}
        >
          Quotation
        </span>
        <div>
          <span
            style={{
              fontSize: "1rem",
              margin: "4rem 2rem",
              fontFamily: "RakutenRoundRegular",
            }}
          >
            Show Quotation
          </span>
          <Switch
            value={quotation.showQuotation}
            onChange={(value) =>
              setQuotation({ ...quotation, showQuotation: value })
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 2rem",
            width: "40%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 16 }}
          >
            <span style={{ color: quotation.showQuotation ? "black" : "gray" }}>
              Title
            </span>
            <Input
              placeholder="Enter Title..."
              style={{ borderRadius: 0 }}
              disabled={!quotation.showQuotation}
              value={quotation.title}
              onChange={(e) =>
                setQuotation({ ...quotation, title: e.target.value })
              }
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 16 }}
          >
            <span style={{ color: quotation.showQuotation ? "black" : "gray" }}>
              Author
            </span>
            <Input
              placeholder="Enter Author..."
              style={{ borderRadius: 0 }}
              disabled={!quotation.showQuotation}
              value={quotation.author}
              onChange={(e) =>
                setQuotation({ ...quotation, author: e.target.value })
              }
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 16 }}
          >
            <span style={{ color: quotation.showQuotation ? "black" : "gray" }}>
              Designation
            </span>
            <Input
              placeholder="Enter Designation..."
              style={{ borderRadius: 0 }}
              disabled={!quotation.showQuotation}
              value={quotation.designation}
              onChange={(e) =>
                setQuotation({ ...quotation, designation: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div
        style={{
          background: "white",
          margin: "1rem 2rem",
          paddingTop: "1rem",
          paddingBottom: "2rem",
          marginBottom: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 2rem",
          }}
        >
          <span
            style={{
              fontFamily: "RakutenRoundSemiBold",
              fontSize: "2.5rem",
            }}
          >
            Learning Platforms
          </span>
          <Button
            style={{
              borderRadius: "0px",
              // width: "8rem",
              cursor: "pointer",
            }}
            className="settings-add-platform-button"
            onClick={() => {
              if (learningPlatforms.length < 4) {
                const updatedLearningPlatforms = [...learningPlatforms];
                updatedLearningPlatforms.push({
                  name: "",
                  value: "",
                  url: "",
                  id: Math.random() * 10000000,
                });
                setLearningPlatforms(updatedLearningPlatforms);
              } else {
                api.info({
                  message: (
                    <strong style={{ color: "black" }}>
                      The maximum limit for learning platforms is four. Please
                      contact admin for further details.
                    </strong>
                  ),
                });
              }
            }}
            // className="setting-add-platform"
          >
            Add Learning Platform
          </Button>
        </div>

        <div>
          {learningPlatforms.map((platform) => {
            const { name, url, id } = platform;

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // width: "50%",
                  // justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: 16,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 16,
                  }}
                >
                  <span>
                    Platform<span style={{ color: "red" }}>*</span>
                  </span>
                  <Select
                    defaultValue={null}
                    className="settings-learnings-platform-select"
                    placeholder="Select the learning platform..."
                    style={{
                      width: 160,
                    }}
                    value={platform.name}
                    onChange={(selectedValue) => {
                      const updatedLearningPlatforms = learningPlatforms.map(
                        (learningPlatform) => {
                          if (learningPlatform.id === platform.id) {
                            return {
                              ...learningPlatform,
                              name: selectedValue,
                              value: selectedValue.toLowerCase(),
                            };
                          }
                          return learningPlatform;
                        }
                      );

                      setLearningPlatforms(updatedLearningPlatforms);
                    }}
                    options={learningPlatformOptions}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 16,
                  }}
                >
                  <span>
                    Learning Platform Url<span style={{ color: "red" }}>*</span>
                  </span>
                  <Input
                    type="text"
                    placeholder="Enter url..."
                    value={platform.url}
                    onChange={(event) => {
                      const updatedLearningPlatforms = learningPlatforms.map(
                        (learningPlatform) => {
                          if (learningPlatform.id === platform.id) {
                            return {
                              ...learningPlatform,
                              url: event.target.value,
                            };
                          }
                          return learningPlatform;
                        }
                      );

                      setLearningPlatforms(updatedLearningPlatforms);
                    }}
                    style={{ borderRadius: 0, width: 240 }}
                  />
                </div>
                {/* <span
                  style={{
                    padding: "8px 8px",

                    border: "1px solid gray",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  <ImCross />
                </span> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    margin: 16,
                    marginTop: 32,
                  }}
                >
                  <Button
                    style={{
                      borderRadius: "0px",
                      width: "8rem",
                      cursor: "pointer",
                    }}
                    className="settings-add-platform-button"
                    onClick={() => {
                      const filteredPlatforms = learningPlatforms.filter(
                        (learningPlatform) =>
                          platform.id !== learningPlatform.id
                      );
                      setLearningPlatforms(filteredPlatforms);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
}

export default Settings;

import { Tooltip, Typography } from "antd";
import { capitalize, startCase, truncate } from "lodash";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { getDynamicFilterList } from "../learnerHistory/learnerHistoryUtils";

export const employeeTableColumns = (
  onEdit,
  onDelete,
  userList,
  updateTableData
) => [
  {
    title: "Emp ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Email & Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    sortDirections: ["ascend", "descend"],
    render: (_, row) => {
      return (
        <div style={{ width: 160 }}>
          <Typography>
            {startCase(truncate(row.name, { length: 25 }))}
          </Typography>
          <Typography>{row.email}</Typography>
        </div>
      );
    },
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
    filters: getDynamicFilterList("country", userList),
    sorter: (a, b) => a?.country?.localeCompare(b?.country),
    sortDirections: ["ascend", "descend"],
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.country?.toLowerCase();
    },
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    filters: getDynamicFilterList("company", userList),
    sorter: (a, b) => a?.company?.localeCompare(b?.company),
    sortDirections: ["ascend", "descend"],
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.company?.toLowerCase();
    },
  },
  {
    title: "Dept",
    dataIndex: "department",
    key: "department",
    sorter: (a, b) => a?.department?.localeCompare(b?.department),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("department", userList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.department?.toLowerCase();
    },
    render: (_, row) => {
      return (
        <div>
          <Typography>{row.department}</Typography>
        </div>
      );
    },
  },
  {
    title: "Hire Date",
    dataIndex: "joinedDate",
    key: "joinedDate",
    render: (value, record) => {
      return <span>{value?.split("T")?.[0]}</span>;
    },
    sorter: (a, b) => a?.joinedDate?.localeCompare(b?.joinedDate),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Employee Status",
    dataIndex: "empStatus",
    key: "empStatus",
    sorter: (a, b) => a?.empStatus?.localeCompare(b?.empStatus),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("empStatus", userList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.empStatus?.toLowerCase();
    },
    render: (_, row) => {
      return (
        <div>
          <p>{capitalize(row.empStatus)}</p>
        </div>
      );
    },
  },
  {
    title: "Role",
    dataIndex: "designation",
    key: "designation",
    sorter: (a, b) => a?.designation?.localeCompare(b?.designation),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("designation", userList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.designation?.toLowerCase();
    },
    render: (_, row) => {
      return (
        <div>
          <Typography>{row.designation}</Typography>
        </div>
      );
    },
  },
  {
    title: "Manager",
    dataIndex: "managerName",
    key: "managerName",
    sorter: (a, b) => a?.managerName?.localeCompare(b?.managerName),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("managerName", userList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.managerName?.toLowerCase();
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    isDummyField: true,
    render: (_, row) => {
      return (
        <div>
          <AiFillEdit
            size={24}
            style={{ marginRight: 16 }}
            onClick={() => {
              onEdit(row);
            }}
            cursor="pointer"
          />
          <AiFillDelete
            size={24}
            onClick={() => {
              onDelete(row, "user");
            }}
            cursor="pointer"
          />
        </div>
      );
    },
  },
];

export const departmentTableColumns = (
  onEdit,
  onDelete,
  departmentList,
  updateTableData
) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Dept Head",
    dataIndex: "departmentheadName",
    key: "departmentheadName",
    sorter: (a, b) =>
      a?.departmentheadName?.localeCompare(b?.departmentheadName),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("departmentheadName", departmentList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.departmentheadName?.toLowerCase();
    },
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    sorter: (a, b) => a?.company?.localeCompare(b?.company),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("company", departmentList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.company?.toLowerCase();
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a?.status?.localeCompare(b?.status),
    sortDirections: ["ascend", "descend"],
    render: (_, row) => {
      return <p>{capitalize(row?.status)}</p>;
    },
    filters: getDynamicFilterList("status", departmentList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.status?.toLowerCase();
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return (
        <div>
          <AiFillEdit
            size={24}
            style={{ marginRight: 16 }}
            onClick={() => {
              onEdit(row);
            }}
            cursor="pointer"
          />
          <AiFillDelete
            size={24}
            onClick={() => {
              onDelete(row, "dept");
            }}
            cursor="pointer"
          />
        </div>
      );
    },
  },
];

export const companyTableColumns = (
  onEdit,
  onDelete,
  companyList,
  updateTableData
) => [
  {
    title: "Company ID",
    dataIndex: "id",
    key: "id",
    // sorter: (a, b) => a?.id < b?.id,
    // sortDirections: ["ascend", "descend"],
  },
  {
    title: "Company Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("name", companyList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.name?.toLowerCase();
    },
  },
  {
    title: "Departments",
    dataIndex: "departments",
    key: "departments",
    render: (cell, row) => {
      return (
        <Tooltip
          title={() => {
            return (
              <div>
                <table style={{ background: "black" }}>
                  <tr style={{ background: "black" }}>
                    <th style={{ background: "black" }}>Department Name</th>
                    <th style={{ background: "black" }}>Status</th>
                  </tr>
                  {row?.departments?.map(({ name, status }) => (
                    <tr style={{ background: "black" }}>
                      <td
                        style={{
                          background: "black",
                        }}
                        width={600}
                      >
                        {name}
                      </td>
                      <td
                        style={{
                          background: "black",
                        }}
                        width={300}
                      >
                        {capitalize(status)}
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            );
          }}
        >
          <span>
            {truncate(row?.departments?.map((dept) => dept.name).join(", "), {
              length: 35,
            })}
          </span>
        </Tooltip>
      );
    },
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    sorter: (a, b) => a.country.localeCompare(b.country),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Company Status",
    dataIndex: "status",
    key: "status",
    render: (val) => <span>{capitalize(val)}</span>,
    sorter: (a, b) => a?.status?.localeCompare(b?.status),
    sortDirections: ["ascend", "descend"],
    filters: getDynamicFilterList("status", companyList),
    onFilter: (value, record) => {
      return value?.toLowerCase() === record?.status?.toLowerCase();
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return (
        <div>
          <AiFillEdit
            size={24}
            style={{ marginRight: 16 }}
            onClick={() => onEdit(row)}
            cursor="pointer"
          />
          <AiFillDelete
            size={24}
            onClick={() => onDelete(row, "company")}
            cursor="pointer"
          />
        </div>
      );
    },
  },
];

import { Row, Col, Typography, Input } from "antd";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Toggle,
  InlineIconButton,
} from "./../../component-library";
import style from "./trainerDetail.module.css";
import closeIcon from "../../assets/icons/close.svg";

export default function TrainerDetail(props) {
  const {
    handleCurTrainerName,
    handleCurTrainerEmail,
    handleCurTrainerIsInternalTrainer,
    handleClose,
    data,
    curIndex,
    showClose
  } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isInternalTrainer, setIsInternalTrainer] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [show, setShow] = useState(true);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (data) {
      setName(data.trainer_name);
      setEmail(data.trainer_email);
      setIsInternalTrainer(data.internal_trainer);
    }
    if (curIndex) {
      setIndex(curIndex);
    }
    // eslint-disable-next-line
  }, []);

  const toggle = (value) => {
    setIsInternalTrainer(!value);
    handleCurTrainerIsInternalTrainer(!value, index);
  };

  const handleName = (name) => {
    setName(name);
    handleCurTrainerName(name, index);
    if (!name) {
      const error = { name: `Please enter trainer name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (name.trim() === "") {
      const error = { name: " trainer name cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { name: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleEmail = (email) => {
    setEmail(email);
    handleCurTrainerEmail(email, index);
  };

  const handleShow = (value, email) => {
    setShow(value);
    handleClose(value, email, index);
  };

  return (
    <div>
      {show === true && (
        <Col className={style.trainerDetailsInputBox}>
          <Row>
            <Col lg={22}></Col>
            <Col lg={2}>
              {showClose === true ? (
                <InlineIconButton
                  alt="Close"
                  iconURL={closeIcon}
                  clickHandler={(e) => {
                    handleShow(false, email);
                  }}
                  imageStyle={style.closeIcon}
                />
              ) : (
                <br />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={22} style={{ paddingLeft: "1rem" }} >
              <Typography className={"courseAddEdit-label"}>
                Trainer Name
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <Input
                value={name}
                placeholder="Enter Name"
                onChange={(e) => {
                  handleName(e.target.value);
                }}
                className={"moduleAddEdit-zoomInput"}
              />
              {(errorMessage.name && (
                <Row className={"trainer-validation"}>
                  {errorMessage.name}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Col lg={22} style={{ paddingLeft: "1rem" }}>
              <Typography className={"courseAddEdit-label"}>
                Trainer Mail
              </Typography>
              <Input
                type="email"
                value={email}
                placeholder="Enter Email"
                onChange={(e) => {
                  handleEmail(e.target.value);
                }}
                className={"moduleAddEdit-zoomInput"}
              />
              {(errorMessage.email && (
                <Row>
                  {errorMessage.email}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Col lg={22} style={{ paddingLeft: "1rem" }}>
              <Typography className={"courseAddEdit-label"}>
                Trainer From
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              {!data && (
                <Toggle
                  firstLabelName="INTERNAL"
                  secondLabelName="EXTERNAL"
                  className={style.toggle}
                  toggle={toggle}
                  selected={!isInternalTrainer}
                  customStyle={{ margin: "0rem" }}
                />
              )}
              {data && isInternalTrainer !== null && (
                <Toggle
                  firstLabelName="INTERNAL"
                  secondLabelName="EXTERNAL"
                  className={style.toggle}
                  toggle={toggle}
                  selected={!isInternalTrainer}
                  customStyle={{ margin: "0rem" }}
                />
              )}
            </Col>
          </Row>
        </Col >
      )
      }
    </div >
  );
}

TrainerDetail.propTypes = {
  handleCurTrainerName: PropTypes.func,
  handleCurTrainerEmail: PropTypes.func,
  handleCurTrainerIsInternalTrainer: PropTypes.func,
  handleClose: PropTypes.func,
  data: PropTypes.object,
  curIndex: PropTypes.number,
  showClose: PropTypes.bool
};

import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import style from "./numberCounter.module.css";
import plusIcon from "../../assets/icons/add_icon.svg";
import minusIcon from "../../assets/icons/minusIcon.svg";

export default function NumberCounter({ selected, changeHandler }) {
  const [current, setCurrent] = useState(3);
  const [selectedValue, setSelectedValue] = useState(3);

  useEffect(() => {
    setSelectedValue((prev) => {
      return (prev === 3 && selected) || prev;
    });
  }, [selected]);

  useEffect(() => {
    setCurrent(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    changeHandler(current);
    // eslint-disable-next-line
  }, [current]);

  return (
    <Col className={style.custom_toggle}>
      <Row fluid="true" className={style.numberContainer}>
        <Col className={style.firstColumn}>
          <span
            className={style.counter}
            onClick={() => {
              if (current > 3) {
                setCurrent((current) => current - 1);
              }
            }}
          >
            <img src={minusIcon} alt="" />
          </span>
        </Col>
        <Col>
          <span>
            <Form.Control
              value={current}
              className={style.text_field}
              type="text"
              onChange={(e) => {
                const currentValue = parseInt(e.target.value);
                setCurrent(() => (currentValue > 1 && currentValue) || 1);
              }}
            />
          </span>
        </Col>
        <Col>
          <span
            className={style.counter}
            onClick={() => {
              setCurrent((current) => current + 1);
            }}
          >
            <img src={plusIcon} alt="" style={{ height: "0.875rem" }} />
          </span>
        </Col>
      </Row>
    </Col>
  );
}

NumberCounter.propTypes = {
  toggleTitle: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selected: PropTypes.number,
};

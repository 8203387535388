import { Button, Input, Modal } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import TextEditor from "../textEditor/TextEditor";
import Validation from "../validation/Validation";
import "./antdEmailActionModal.scss";

function AntdEmailActionModal(props) {
  const {
    to,
    cc,
    bcc,
    subject,
    note,
    content,
    calendarInviteAdded,
    showEmailModal,
    sendEmailHandler,
    modalCloseHandler,
    ccHandler,
    bccHandler,
    calendarInviteAddedHandler,
    subjectHandler,
    contentHandler,
    showCalendar,
    setTo,
    validateDlm,
  } = props;
  const [errorMessage, setErrorMessage] = useState({});
  const textEditorRef = useRef(null);

  const isValidEmailAddress = useCallback((email) => {
    let flag = true;
    if (!email) {
      return flag;
    } else {
      if (!Array.isArray(email)) {
        return email?.trim()?.match(/^[^@]+@[^@]+\.[^@]{2,4}$/);
      } else {
        email.map((e) => (!isValidEmailAddress(e) ? (flag = false) : ""));
        return flag;
      }
    }
  }, []);

  const isValidContent = useCallback((text) => {
    const content = text.replace(/<[^>]*>?/gm, "");
    return content === "" ? false : true;
  }, []);

  const validateEmailDetails = useCallback(() => {
    const errors = {};
    if (!to) {
      errors.to = `Receivers email ids can not be empty`;
    } else if (validateDlm) {
      const toFields = to?.split(",");

      toFields.forEach((toField) => {
        if (!toField.includes("@mail.rakuten.com") && toField) {
          errors.to = `Please enter a valid DLM`;
        }
      });
    }

    if (!isValidEmailAddress(cc)) {
      errors.cc = `Please provide valid email address`;
    }
    if (!isValidEmailAddress(bcc)) {
      errors.bcc = `Please provide valid email address`;
    }
    if (!subject) {
      errors.subject = `Please provide subject`;
    }
    if (!isValidContent(content)) {
      errors.content = `Please provide content`;
    }
    return errors;
  }, [to, cc, bcc, subject, content, isValidEmailAddress, isValidContent]);

  useEffect(() => {
    const error = validateEmailDetails();
    setErrorMessage(error);
  }, [validateEmailDetails]);

  const ccChangeHandler = (text) => {
    const emailArr = text.split(",");
    ccHandler(emailArr);
  };

  const bccChangeHandler = (text) => {
    const emailArr = text.split(",");
    bccHandler(emailArr);
  };

  const subjectChangeHandler = (text) => {
    subjectHandler(text);
    if (!text) {
      const error = { subject: `Please provide subject` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subject: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const contentChangeHandler = (text) => {
    contentHandler(text);
    if (!isValidContent(text)) {
      const error = { content: `Please provide content` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { content: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const toChangeHandler = (text) => {
    setTo(text.toLowerCase());
  };

  const sendEmail = async () => {
    const errors = validateEmailDetails();
    await textEditorRef.current.deleteOrphanedImages();
    if (Object.keys(errors).length > 0) {
      setErrorMessage({ ...errorMessage, ...errors });
    } else {
      sendEmailHandler();
    }
  };

  const renderErrorMessage = (fieldName) => {
    return (
      <span style={{ color: "red" }}>
        {errorMessage[fieldName.toLowerCase()]}
      </span>
    );
  };

  const renderField = (
    fieldName,
    placeholder,
    fieldValue,
    handler,
    requiredField
  ) => {
    return (
      <div style={{ margin: "1rem" }}>
        <span style={{ fontFamily: "RakutenRoundRegular" }}>
          {fieldName}
          {requiredField && <span style={{ color: "red" }}> *</span>}
        </span>
        <Input
          type="text"
          placeholder={placeholder}
          style={{
            padding: "0.5rem 1rem",
            marginTop: ".375rem",
            marginBottom: 8,
          }}
          value={fieldValue}
          onChange={(e) => handler(e.target.value)}
        />
        {renderErrorMessage(fieldName)}
      </div>
    );
  };

  const renderMailToUserForm = () => {
    return (
      <div>
        <div>
          {renderField("To", "Receivers Email Ids", to, toChangeHandler, true)}
          {renderField("CC", "CC Email Ids", cc, ccChangeHandler)}
          {renderField("BCC", "BCC Email Ids", bcc, bccChangeHandler)}
          {renderField(
            "Subject",
            "Subject",
            subject,
            subjectChangeHandler,
            true
          )}
          <div style={{ margin: "1rem" }}>
            <span>
              Content <span style={{ color: "red" }}> *</span>
            </span>
            <TextEditor
              value={content}
              ref={textEditorRef}
              getText={(text) => {
                contentChangeHandler(text);
              }}
            />
            {(errorMessage.content && (
              <Validation
                message={errorMessage.content}
                style={{ marginTop: " 3.5rem" }}
              />
            )) ||
              ""}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="cancel-button-email-action-modal"
            onClick={() => {
              modalCloseHandler();
              setTo("");
            }}
            style={{ margin: "1rem" }}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: "1rem" }}
            onClick={() => {
              sendEmail();
              setTo("");
              modalCloseHandler();
            }}
            type="button"
            className="send-button-email-action-modal"
          >
            SEND
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        // width="auto"
        footer={null}
        open={showEmailModal}
        onCancel={modalCloseHandler}
        title="Mail to user"
      >
        {renderMailToUserForm()}
      </Modal>
    </div>
  );
}

export default AntdEmailActionModal;

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Card, Typography } from "antd";
import Icon from "@ant-design/icons";
import config from "../../config";
import axios from "axios";

import Bookmark from "../antDesignBookmark/Bookmark";
import CourseIcon from "../antDesignCourseIcon/CourseIcon";
import { ReactComponent as CourseProvider } from "./../../assets/icons/courseProviderIcon.svg";
import RoundProgressBarInstructorLed from "../roundProgressBarInstructorLed/RoundProgressBarInstructorLed";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import tech_learning_group from "../../assets/icons/tech_learning_group.svg";
import { ReactComponent as add_course } from "../../assets/icons/add_course.svg";
import { ReactComponent as select_course } from "../../assets/icons/selected.svg";

import { ReactComponent as add_course_orange } from "../../assets/icons/add_course_orange.svg";
import { ReactComponent as select_course_orange } from "../../assets/icons/selected_orange.svg";

import { CourseContext } from "../../context/CourseContext";
import { UserContext } from "../../context/UserContext";
import TimeFormatter from "../../utils/timeFormatter";
import TimeFormaters from "../../utils/timeFormatter";
import { getEllipsis } from "../../utils/stringFormatter";

import style from "./CourseCard.module.css";
import "./CourseCard.css";
import InstructorLed from "../../pages/user/instructorLed/InstructorLed";

const INSTRUCTOR_LED = config.INSTRUCTOR_LED;
const E_LEARNING = config.E_LEARNING;
const LEARNINGPATH = config.LEARNINGPATH;
const RECOMMENDATION = config.RECOMMENDATION;

function CourseCard({
  dataElement,
  index,
  isFavorite,
  tileType,
  CoursePath,
  checkedILState,
  checkedELState,
  cardClicked,
  reload,
  courseType,
}) {
  let {
    name,
    course_name: courseName,
    private: isPrivate,
    courseProvider,
    courseProviderDetails,
    id,
    courseId,
    isFavourite: isFav,
    duration,
    language,
    languages,
    usersCompleted,
    usersStarted,
    userCourseData,
    courseStatus,
    batch_data: batchData,
    course_provider: iLcourseProvider,
    user_data: userData,
    isSearchResultPage,
  } = dataElement || {};
  const {
    batch_start: batchStart,
    batch_end: batchEnd,
    start_time: batchStartTime,
    end_time: batchEndTime,
    occupied_seats,
    batch_size,
    counter,
    size,
  } = batchData || {};

  const occupiedSeats = occupied_seats || counter;
  const batchSize = batch_size || size;
  const {
    status,
    status_text: statusText,
    status_type_id: statusCode,
  } = userData || {};

  const { userId } = useContext(UserContext);
  const { userDateFormat } = useContext(CourseContext);
  const [isFavourite, setIsFavourite] = useState(isFav || isFavorite);
  const history = useHistory();

  usersStarted = usersStarted ? usersStarted : 0;
  usersCompleted = usersCompleted ? usersCompleted : 0;
  const totalUsers = usersStarted + usersCompleted;

  let languagesSearchResult = isSearchResultPage
    ? languages?.map((language) => language.name)
    : [];

  let ElLanguages = "";
  if (isSearchResultPage) {
    ElLanguages =
      languagesSearchResult &&
      getEllipsis(languagesSearchResult?.join(","), 20);
  } else {
    ElLanguages = languages && getEllipsis(languages?.join(","), 20);
  }

  let imageUrl = !dataElement.image
    ? !dataElement.imageUrl
      ? "/default.png"
      : dataElement.imageUrl
    : dataElement.image;

  const userStatus = status || statusText;
  let fromTo = batchStart || batchStartTime;
  const showDate = fromTo;

  if (fromTo !== "Coming Soon") {
    let startDate = TimeFormatter.FormatDate(
      new Date(batchStart || batchStartTime),
      null,
      userDateFormat
    );
    let endDate = TimeFormatter.FormatDate(
      new Date(batchEnd || batchEndTime),
      null,
      userDateFormat
    );
    if (startDate === endDate) {
      fromTo = startDate;
    } else {
      fromTo = `${startDate} - ${endDate}`;
    }
  }

  let showCourseStatus = userCourseData?.courseStatus
    ? userCourseData.courseStatus.toLowerCase()
    : courseStatus
    ? courseStatus
    : "";

  let roundProgressBarColor = "#D1D1D1";
  if (showCourseStatus === "completed") {
    showCourseStatus = "Completed";
    roundProgressBarColor = "#00B900";
  } else if (showCourseStatus === "started") {
    showCourseStatus = "In Progress";
    roundProgressBarColor = "#F59600";
  } else {
    showCourseStatus = "Get Started";
    roundProgressBarColor = "#D1D1D1";
  }

  useEffect(() => {
    setIsFavourite(dataElement.is_favourite || isFavorite);
  }, [dataElement, isFavorite]);

  const addFavourite = async (userId, courseId, type) => {
    const endPoint = `${config.endPoint.commonService}/favourite`;
    await axios
      .post(endPoint, [
        {
          userId: userId,
          entityType: type,
          entityId: courseId,
        },
      ])
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const deleteFavourite = async (userId, type, courseId) => {
    const endPoint = `${config.endPoint.commonService}/favourite?userId=${userId}&courseType=${type}&courseId=${courseId}`;
    await axios
      .delete(endPoint)
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (reload) reload();
      });
  };
  const changeStatus = (type) => {
    if (isFavourite) {
      setIsFavourite(false);
      deleteFavourite(userId, type, id || courseId);
    } else {
      setIsFavourite(true);
      addFavourite(userId, id || courseId, type);
    }
  };

  const handleCardClick = (e) => {
    if (tileType == LEARNINGPATH || tileType == RECOMMENDATION) {
      e.preventDefault();
      cardClicked(dataElement);
      return;
    }
    if (courseType == INSTRUCTOR_LED && tileType != LEARNINGPATH) {
      history.push(CoursePath);
      return;
    }
    if (courseType == E_LEARNING && tileType != LEARNINGPATH) {
      history.push(CoursePath);
      return;
    }
  };
  function TeamTag({ team }) {
    return (
      <div>
        <img width="16px" height="16px" src={tech_learning_group}></img>
        <span style={{ marginLeft: "8px" }}>{team}</span>
      </div>
    );
  }
  function CourseChecked({ checked, color }) {
    if (!checked) {
      return (
        <div>
          {color == "BLUE" && <Icon component={add_course} />}
          {color == "ORANGE" && (
            <Icon
              style={{ backgroundColor: "transparent" }}
              component={add_course_orange}
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          {color == "BLUE" && <Icon component={select_course} />}
          {color == "ORANGE" && (
            <Icon
              style={{ backgroundColor: "transparent" }}
              component={select_course_orange}
            />
          )}
        </div>
      );
    }
  }
  const renderCourseChecked = (checkedState) => {
    // console.log(checkedState);
    return checkedState?.length &&
      checkedState?.find((checkedItem) => checkedItem.id == dataElement.id) ? (
      <CourseChecked
        className={style.courseCheckIcon}
        checked={true}
        color={isPrivate ? "ORANGE" : "BLUE"}
      ></CourseChecked>
    ) : (
      <CourseChecked
        className={style.courseCheckIcon}
        checked={false}
        color={isPrivate ? "ORANGE" : "BLUE"}
      ></CourseChecked>
    );
  };

  const isSelected =
    checkedILState?.find((checkedItem) => checkedItem.id === dataElement.id)
      ?.id === dataElement.id;

  return (
    <Card
      hoverable
      cover={
        <img
          src={imageUrl}
          className={style.courseCardImage}
          style={{
            border: isSelected ? "2px solid #00a0f0" : "",
            borderBottom: "",
          }}
          alt="/img"
        ></img>
      }
      className={style.courseCard}
      onClick={handleCardClick}
      style={{
        border: isSelected ? "2px solid #00a0f0" : "",
      }}
    >
      <div className={`${style.courseCardCover}`}>
        <CourseIcon courseType={courseType} color={"white"} />

        {tileType != LEARNINGPATH && tileType != RECOMMENDATION ? (
          <Bookmark
            isBookmarked={isFavourite}
            color={"white"}
            onClick={(e) => {
              e.preventDefault();
              if (courseType == INSTRUCTOR_LED) {
                changeStatus();
              } else {
                changeStatus(config.elearningCourseList.alias);
              }
            }}
          ></Bookmark>
        ) : (
          " "
        )}
      </div>
      <Typography.Paragraph
        className={style.courseTitle}
        ellipsis={{ rows: 2 }}
      >
        {name || courseName}
      </Typography.Paragraph>
      {/* TODO --if courseType is EL then show IL course Details */}

      {/* TODO --if courseType is IL then show IL course Details */}

      {/* if titlepath is learning path then show the selection row */}
      {tileType == LEARNINGPATH && (
        <div className={style.courseCardContent}>
          <div className={style.courseDetailsFlex}>
            <div className={style.courseDetails}>
              {courseType == INSTRUCTOR_LED && (
                <div className={style.courseDetail}>
                  <div>
                    {duration &&
                      TimeFormatter.decimalHrsToHrsMin(
                        duration,
                        "detailed"
                      )}{" "}
                    / {language}
                  </div>
                </div>
              )}
              {courseType == E_LEARNING && (
                <div className={style.courseDetail}>
                  <div>
                    {duration}hrs / {ElLanguages}{" "}
                  </div>
                </div>
              )}
              <div>
                <TeamTag
                  team={
                    courseType == INSTRUCTOR_LED
                      ? courseProvider?.name || iLcourseProvider
                      : courseProviderDetails?.CourseProviderName ||
                        courseProvider
                  }
                ></TeamTag>
              </div>
            </div>
          </div>
          <div className={style.courseCheckIcon}>
            {/* //checked box for IL  Icon*/}
            {courseType == INSTRUCTOR_LED &&
              renderCourseChecked(checkedILState)}
            {courseType == E_LEARNING && renderCourseChecked(checkedELState)}
          </div>
        </div>
      )}
    </Card>
  );
}

export default CourseCard;

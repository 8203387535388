import { Button, Col, Divider, Row, Typography } from "antd";
import DOMPurify from "dompurify";
import ShowMoreText from "react-show-more-text";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import ClassroomSchedules from "./ClassroomSchedules";
import CourseAdditionalDetails from "./CourseAdditionalDetails";
import "./pathCourseDetails.css";
import {
  ADDITIONAL_RESOURCES,
  READ_LESS,
  READ_MORE,
  RECOMMENDATIONS,
  TOPICS,
} from "../../../../constants";
import EmptyClassroomSchedules from "./EmptyClassroomSchedules";

const PathCourseDetails = ({ course, sessionDetails }) => {
  const { courseType } = course;

  const courseDescriptionSpanWidth = courseType === "IL" ? 12 : 18;

  const renderCourseDescription = () => {
    return (
      <Col>
        <Row>
          <Typography className="course-description">
            Course Description
          </Typography>
        </Row>
        <Row>
          <ShowMoreText
            lines={3}
            more={
              <div className="read-more-less-container">
                <span className="show-more-less-clickable">{READ_MORE}</span>
                <DownOutlined />
              </div>
            }
            less={
              <div className="read-more-less-container">
                <span className="show-more-less-clickable">{READ_LESS}</span>
                <UpOutlined />
              </div>
            }
            expanded={false}
            anchorClass="show-more-less-clickable"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course?.description),
              }}
            />
          </ShowMoreText>
        </Row>
        {course?.training_material && (
          <Row style={{ marginTop: 16 }}>
            <Button
              className="course-materials"
              onClick={() => {
                window.open(course?.training_material, "_blank");
                return null;
              }}
            >
              Course Materials
            </Button>
          </Row>
        )}
        {course?.courseType === "IL" && (
          <Row>
            <Typography className="timezone-alert">
              *Date & Time is set to automatically adjust to your PC's local
              date & time
            </Typography>
          </Row>
        )}
      </Col>
    );
  };

  const renderCourseDetail = (title, content) => {
    return (
      <Col className="render-course-detail">
        <Typography className="course-details-heading">{title}</Typography>
        {content ? (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          />
        ) : (
          "N/A"
        )}
      </Col>
    );
  };

  return (
    <div className="path-course-details">
      <Row>
        <Col
          span={courseDescriptionSpanWidth}
          className="path-course-details-pane"
        >
          {renderCourseDescription()}
        </Col>
        <Col
          span={24 - courseDescriptionSpanWidth}
          className="path-course-details-pane"
          id={course?.id}
        >
          {courseType === "IL" &&
            (sessionDetails.length > 0 ? (
              <ClassroomSchedules
                course={course}
                sessionDetails={sessionDetails}
              />
            ) : (
              <EmptyClassroomSchedules />
            ))}
        </Col>
      </Row>
      <Divider />
      <CourseAdditionalDetails
        course={course}
        overrideParentContainer="classroom-schedules-footer"
      />
      <Row gutter={(12, 12)} className={"path-course-additional-details"}>
        <Col span={12} className="course-details-child-container">
          {renderCourseDetail(TOPICS, course?.topics)}
        </Col>
        <Col span={12} className="course-details-child-container">
          {renderCourseDetail(ADDITIONAL_RESOURCES, course?.recommendations)}
        </Col>
      </Row>
    </div>
  );
};
export default PathCourseDetails;

import React, { useState, useRef, useContext, useEffect } from "react";
import { Row, Col, Image } from "antd";
import { useHistory, useLocation } from "react-router-dom";

import {
  BreadCrumb,
  TopContainer,
  Alert,
  AdminPageTitle,
  PrimaryButton,
  SecondaryButton,
  AdminPageSubTitle,
  Validation,
} from "../../../../component-library";
import { LoadingContext } from "../../../LoadingContext";
import axios, { CancelToken, isCancel } from "axios";
import style from "./elearningCourseFileUpload.module.css";
import uploadImage from "../../../../assets/icons/uploadFile.svg";
import uploadZip from "../../../../assets/icons/uploadZipIcon.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import config from "../../../../config";
import AdminTopContainer from "../../../../component-library/antDesignAdminTopContainer/AdminTopContainer";
import { Button } from "antd";
import "./elFileUpload.scss";
import { UserContext } from "../../../../context/UserContext";

function ElearningCourseFileUpload(props) {
  const inputFile = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { setPageLoader } = useContext(LoadingContext);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const courseId = props.match.params.courseId;
  const [editMode, setEditMode] = useState(false);
  const [filename, setFilename] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [showCourseZipFileName, setShowCourseZipFileName] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isBackDisabled, setIsBackDisabled] = useState(false);
  const [isAbortDisabled, setIsAbortDisabled] = useState(true);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const { userConfig } = useContext(UserContext);

  const cancelFileUpload = useRef(null);

  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    {
      title: "E-learning courses",
      to: "/Admin/e-learning/courses",
    },
    {
      title: `${
        (courseDetails?.zipFileName && "Update") || "Upload"
      } SCORM Package`,
    },
  ];

  const onButtonClick = () => {
    inputFile.current.click();
  };

  async function handleUpload(e) {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        setFilename(file.name);
        setUploadFile(file);
        setErrorMessage({ uploadedFile: "" });
        setShowCourseZipFileName(false);
        setIsUploadDisabled(true);
      }
    } catch (error) {
      setIsUploadDisabled(false);
    } finally {
      setIsUploadDisabled(false);
    }
  }

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.name.endsWith(".zip")) {
      setFilename(file.name);
      setUploadFile(file);
      setShowCourseZipFileName(false);
      setIsUploadDisabled(true);
    } else {
      setAlert("Only .zip file uploads allowed!");
      setAlertType("danger");
      setUploadFile(null);
      setShowCourseZipFileName(true);
      setIsUploadDisabled(false);
    }
  };

  const uploadCoursePackage = () => {
    if (uploadFile == null) {
      setErrorMessage({ uploadedFile: `Please upload a .zip file...!` });
      setShowCourseZipFileName(true);
      return;
    } else {
      if (!uploadFile.name.endsWith(".zip")) {
        setAlert("Only .zip file uploads allowed!");
        setAlertType("danger");
        return;
      }
    }
    const formData = new FormData();
    formData.append("scromZip", uploadFile);
    if (!(location.state && location.state.editMode)) {
      formData.append("courseDetails", JSON.stringify(courseDetails));
      formData.append("createCourse", true);
    }

    const instance = axios.create();

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 99) {
          setProgress(percent);
          isAbortDisabled && setIsAbortDisabled(false);
          !isBackDisabled && setIsBackDisabled(true);
        } else {
          setProgress(percent);
          setIsAbortDisabled(true);
          isBackDisabled && setIsBackDisabled(false);
        }
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };

    instance
      .post(
        `${config.endPoint.elearning}/course/${courseId}/upload`,
        formData,
        options
      )
      .then((res) => {
        setProgress(100);
        setTimeout(() => {
          setProgress(0);
        }, 1000);

        if (editMode) {
          history.push({
            pathname: `/Admin/e-learning/courses`,
            state: {
              alertType: "success",
              alertMessage: `SCORM ZIP File Updated Successfully for Course ${courseDetails.identityId}!`,
            },
          });
        } else {
          history.push({
            pathname: `/Admin/e-learning/courses`,
            state: {
              alertType: "success",
              alertMessage: `SCORM ZIP File uploaded Successfully. Course ${courseDetails.name} is now Active.`,
            },
          });
        }
      })
      .catch((error) => {
        if (isCancel(error)) {
          setAlert(error.message);
        }
        setAlert(error?.response?.data?.message || error.message);
        setProgress(0);
        setPageLoader(false);
        setUploadFile(null);
        setShowCourseZipFileName(true);
        setAlertType("danger");
      });
  };

  const abortUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("User has canceled the file upload.");
    }
    setIsUploadDisabled(false);
    setUploadFile(null);
    setShowCourseZipFileName(true);
    setProgress(0);
    setIsAbortDisabled(true);
    setIsBackDisabled(false);
  };

  const getCourseDetails = () => {
    if (location.state && location.state.editMode) {
      setEditMode(location.state.editMode);
      axios
        .get(`${config.endPoint.elearning}/course/${courseId}`)
        .then((response) => {
          if (response.data.success) {
            setCourseDetails(response.data.data.course);
          }
        })
        .catch((error) => {
          setAlert("Failed to load course details!");
          setAlertType("danger");
        });
    } else {
      setCourseDetails(location?.state?.courseDetails);
    }
  };

  useEffect(() => {
    getCourseDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !location.state ||
      (!location.state.editMode && !location.state.courseDetails)
    ) {
      history.push({
        pathname: `/Admin/e-learning/courses`,
        state: {
          alertType: "warning",
          alertMessage: `You cannot access that page directly.`,
        },
      });
    }
    const unloadCallback = (event) => {
      event.preventDefault();
      abortUpload();
      event.returnValue = "";
      return "";
    };
    if (!location?.state?.editMode) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
      window.addEventListener("beforeunload", unloadCallback);
    }
    return () => {
      if (!location?.state?.editMode) {
        window.removeEventListener("popstate", onBackButtonEvent);
        window.removeEventListener("beforeunload", unloadCallback);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (uploadFile) {
      uploadCoursePackage();
    }
    // eslint-disable-next-line
  }, [uploadFile]);

  const cancelUpload = (e) => {
    if (!editMode) {
      onBackButtonEvent(e);
      return;
    }
    const alertState = (courseDetails?.zipFileName && {}) || {
      alertType: "warning",
      alertMessage:
        "The Course created is in PENDING state. Upload of SCORM ZIP File is pending",
    };

    history.push({
      pathname: `/Admin/e-learning/courses`,
      state: alertState,
    });
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "The course is not saved and the data will be lost. Do you still want to go back?"
      )
    ) {
      abortUpload();
      props.history.push("/Admin/e-learning/AddEditCourse", {
        courseDetails: location?.state?.courseDetails,
      });
    } else {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  return (
    <div
      style={{
        marginTop: "5rem",
        marginLeft: "3.5rem",
        width: "92%",
        marginBottom: "3rem",
      }}
    >
      <AdminTopContainer>
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <BreadCrumb crumbs={crumbs} />
        <AdminPageTitle
          pageTitle={`${
            (courseDetails?.zipFileName && "Update") || "Upload"
          } SCORM Package`}
        />
        {courseDetails && (
          <AdminPageSubTitle
            courseId={courseDetails.identityId || ""}
            courseName={courseDetails.name}
          />
        )}
        <Row>
          <div className={style.outerBox}>
            <div
              onDragOver={isUploadDisabled ? null : dragOver}
              onDragEnter={isUploadDisabled ? null : dragEnter}
              onDragLeave={isUploadDisabled ? null : dragLeave}
              onDrop={isUploadDisabled ? null : fileDrop}
            >
              <div className={"innerBox"}>
                <img
                  src={uploadImage}
                  alt=""
                  className={`${style.uploadImage} ${
                    isUploadDisabled ? style.cursorNotAllowed : null
                  }`}
                  onClick={onButtonClick}
                  id="uploadZip"
                />
                <input
                  type="file"
                  id="file"
                  accept="application/zip"
                  ref={inputFile}
                  className={style.inputButton}
                  onChange={handleUpload}
                  onClick={handleClick}
                  disabled={isUploadDisabled}
                />
                <p className={style.imageText}>Drag and Drop here to upload</p>
                <p className={`browseText`} onClick={onButtonClick}>
                  or BROWSE
                </p>
                <p className={style.imageText}>{filename}</p>
              </div>
            </div>
            {(showCourseZipFileName && courseDetails?.zipFileName && (
              <div style={{ display: "flex" }}>
                <span>
                  <img
                    src={uploadZip}
                    alt=""
                    className={style.uploadImage}
                    style={{ width: "1.563rem", marginLeft: "2.5rem" }}
                  />
                </span>
                <span style={{ marginLeft: "0.625rem", marginTop: "0.75rem" }}>
                  {courseDetails.zipFileName}
                </span>
              </div>
            )) ||
              ""}
            {progress > 0 && (
              <div className={style.ProgressBarLength}>
                <ProgressBar
                  animated
                  now={progress}
                  label={`${progress}%`}
                ></ProgressBar>
              </div>
            )}
          </div>
        </Row>

        {(errorMessage.uploadedFile && (
          <Validation
            message={errorMessage.uploadedFile}
            style={{ marginTop: " 0.5rem" }}
          />
        )) ||
          ""}

        <Row>
          <div className={style.noteText}>
            <p style={{ fontFamily: "RakutenRoundSemiBold" }}>Note:</p>
            <p>
              ZIP file being uploaded should comply to SCORM 1.2 or SCORM 2004
              Standards
            </p>
            <p>
              Unzip file on your local computer and ensure that the zip contains
              "imsmanifest.xml"
            </p>
          </div>
        </Row>

        <Row style={{ marginTop: "3.125rem" }}>
          <Col style={{ marginRight: "1rem" }}>
            <Button
              disabled={isBackDisabled}
              onClick={cancelUpload}
              className="secButton"
            >
              Go Back
            </Button>
            <Button
              type="primary"
              className={style.primryAbortButton}
              isDisabled={isAbortDisabled}
              onClick={() => abortUpload()}
              style={{
                width: "11rem",
                height: "2.7rem",
                FontFamily: "RakutenRoundRegular",
                background: userConfig.primary,
              }}
            >
              Abort
            </Button>
          </Col>
        </Row>
      </AdminTopContainer>
    </div>
  );
}
export default ElearningCourseFileUpload;

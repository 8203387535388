import axios from "axios";
import config from "../../../config";

export const getUserList = async () => {
  const userList = await axios.get(
    `${config.endPoint.userService}/api/user/list?pageSize=1000`
  );
  return userList.data;
};

export const getDepartmentList = async () => {
  const departmentList = await axios.get(
    `${config.endPoint.userService}/api/departments?pageSize=1000`
  );
  return departmentList.data;
};

export const getCompanyList = async () => {
  const companyList = await axios.get(
    `${config.endPoint.userService}/api/companies?pageSize=1000`
  );
  return companyList.data;
};

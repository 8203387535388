import { CourseContext } from "../../../context/CourseContext";
import config from "../../../config";
import TimeFormatter from "../../../utils/timeFormatter";
import style from "./waitlistUsersList.module.css";

export default function CourseDetailTable({ data }) {
  return (
    <CourseContext.Consumer>
      {(contextData) => {
        return (
          <table className={style.expandRowTable}>
            <thead>
              <tr>
                <th className={`${style.courseIdName} ${style.courseIdNameTh}`}>
                  COURSE ID & NAME
                </th>
                <th className={style.expandTableTh}>CATEGORY</th>
                <th className={style.expandTableTh}>ENROLLMENT DATE</th>
                <th className={style.expandTableTh}>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {(data &&
                [
                  ...data.instructorLedCourseDetails.courseDetails,
                  ...data.elearningCourseDetails.courseDetails,
                ].map((current) => {
                  return (
                    <tr>
                      <td className={style.courseIdName}>
                        <div style={{ fontFamily: "RakutenRoundSemiBold" }}>
                          {current.identityId}
                        </div>
                        <div className={style.courseName}>
                          {current.courseName}
                        </div>
                      </td>
                      <td className={style.expandTableTh}>
                        {current.parentCategoryName}
                      </td>
                      <td className={style.expandTableTh}>
                        {(current?.courseStartTime &&
                          TimeFormatter.FormatTableTime(
                            current.courseStartTime,
                            null,
                            contextData.userDateFormat
                          )) ||
                          (current?.oldestSessionStartDate &&
                            TimeFormatter.FormatTableTime(
                              current.oldestSessionStartDate,
                              null,
                              contextData.userDateFormat
                            )) ||
                          "--"}
                      </td>
                      <td
                        className={`${style.status} ${
                          (current.status === config.courseStatus.pending &&
                            style.pending) ||
                          (current.status === config.courseStatus.completed &&
                            style.completed) ||
                          style.notCompleted
                        } ${style.expandTableTh}`}
                      >
                        {current?.status.toUpperCase()}
                      </td>
                    </tr>
                  );
                })) ||
                ""}
            </tbody>
          </table>
        );
      }}
    </CourseContext.Consumer>
  );
}

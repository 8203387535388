import React, { useState, createContext, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "./UserContext";
import UtilityFunctions from "../utils/utility";
import config from "../config";
import { serializeParentCategory } from "../serializer/parentCategorySerializer";
import { serializeDataResponse } from "../serializer/dataSerializer";
const CourseContext = createContext();

function CourseContextProvider(props) {
  const { userCountry } = useContext(UserContext);
  const [countryList, setCountryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [proficiencyList, setProficiencyList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [userDateFormat, setUserDateFormat] = useState(null);
  const [parentCatagoryList, setParentCatagoryList] = useState([]);

  useEffect(() => {
    (async () => {
      const parentCategoryEndpoint = `${config.endPoint.commonService}/parentCategory`;
      const parentCategoryResponse = await axios.get(parentCategoryEndpoint);

      const formattedCategoryList = [
        { label: "ALL", value: 0 },
        ...UtilityFunctions.formatParentCategoryList(
          serializeParentCategory(parentCategoryResponse)
        ),
      ];
      setParentCatagoryList(formattedCategoryList);
      const dataEndpoint = `${config.endPoint.commonService}/data`;
      const dataResponse = await axios.get(dataEndpoint);
      const serializedDataResponse = serializeDataResponse(dataResponse);
      const {
        countries,
        proficiency,
        course_types: courseTypes,
        languages,
      } = serializedDataResponse || {};

      const dateFormat = countries.find(
        (data) => data.code === userCountry
      )?.dateFormat;
      setCountryList(countries);
      setLanguageList(languages);
      setProficiencyList(proficiency);
      setCourseTypeList(courseTypes);
      setUserDateFormat(dateFormat);
    })();
  }, [userCountry]);

  return (
    <CourseContext.Provider
      value={{
        countryList,
        languageList,
        proficiencyList,
        parentCatagoryList,
        courseTypeList,
        userDateFormat,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  );
}

export { CourseContext, CourseContextProvider };

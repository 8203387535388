import React, { useState, useEffect } from "react";
import style from "./paginationProgressBar.module.css";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ReactComponent as LeftArrow } from "./../../assets/icons/left_arrow_blue.svg";
import { ReactComponent as RightArrow } from "./../../assets/icons/right_arrow_blue.svg";

function PaginationProgressBar({
  currentPage,
  maxPages,
  maxWidth,
  prevHandler,
  nextHandler,
  prevDisabled,
  nextDisabled,
  primary,
}) {
  const ACTIVE_WIDTH = "2.0625";
  const ACTIVE_HEIGHT = "0.2";
  const [start, setStart] = useState(0);

  useEffect(() => {
    const divisor = maxPages === 1 ? 1 : maxPages - 1;
    const STEP_SIZE = (maxWidth - ACTIVE_WIDTH) / divisor;
    setStart(currentPage * STEP_SIZE);
  }, [currentPage, maxPages, maxWidth]);

  const lineStyle = {
    backgroundColor: primary || "#00A0F0",
    width: `${ACTIVE_WIDTH}rem`,
    marginLeft: `${start}rem`,
    height: `${ACTIVE_HEIGHT}rem`,
  };

  const baseStyle = {
    width: `${maxWidth}rem`,
    height: `${ACTIVE_HEIGHT}rem`,
  };

  return (
    <>
      <Button
        variant="flat"
        size="sm"
        onClick={prevHandler}
        disabled={prevDisabled}
      >
        <LeftArrow
          className={style.pencilIcon}
          fill={primary}
          stroke={primary}
        />
      </Button>
      <div className={style.baseLine} style={baseStyle}>
        <div className={style.progressLine} style={lineStyle}></div>
      </div>
      <Button
        variant="flat"
        size="sm"
        onClick={nextHandler}
        disabled={nextDisabled}
      >
        <RightArrow
          className={style.pencilIcon}
          fill={primary}
          stroke={primary}
        />
      </Button>
    </>
  );
}

PaginationProgressBar.propTypes = {
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  maxWidth: PropTypes.number,
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
};

export default PaginationProgressBar;

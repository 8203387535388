import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { CourseContext } from "../../../context/CourseContext";
import {
  AdminConfirmationModal,
  AdminPageSubTitle,
} from "../../../component-library";
import ImportUserHelper from "../../../component-library/importAddUserModal/ImportUserHelper";
import TimeFormatter from "../../../utils/timeFormatter";
import {
  Alert,
  Breadcrumb,
  Button,
  Col,
  Input,
  Row,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import style from "./sessionUsersList.module.css";
import "./AntdSessionUserList.scss";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import flatten from "lodash/flatten";
import config from "../../../config";

function AntdSessionUsersList(props) {
  const { userDateFormat } = useContext(CourseContext);
  const [sessionId] = useState(props.match.params.batch_id);
  const [sessionName, setSessionName] = useState("");
  const [courseData, setCourseData] = useState("");
  const [isSessionPrivate, setIsSessionPrivate] = useState(true);
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showDeleteConfimation, setShowDeleteConfirmation] = useState(false);
  const [showReminderConfimation, setShowReminderConfirmation] =
    useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [pendingSelected, setPendingSelected] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [showEnrollConfimation, setShowEnrollConfirmation] = useState(false);
  const [showBulkApprovalModel, setShowBulkApprovalModel] = useState(false);
  const [rowForPendingUser, setRowForPendingUser] = useState({});
  const [declineUser, setDeclineUser] = useState(false);
  const [sessionSize, setSessionSize] = useState(0);
  const [enrolledUsers, setEnrolledUsers] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isDisableRemider, setIsDisableReminder] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isBulkOperation, setIsBulkOperation] = useState(false);
  const { TabPane } = Tabs;
  const employeeStatus = ["Active", "Inactive"];

  const sessionUsersColumns = [
    {
      dataIndex: "userId",
      title: "EMP ID",
      sort: true,
    },
    {
      dataIndex: "userName",
      title: "Name & Email",
      sort: true,
      width: 150,
      csvText: "Name",
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>{" "}
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "role",
      title: "Role",
      sort: true,
      headerStyle: {
        width: "10rem",
      },
    },
    {
      dataIndex: "country",
      title: "Country",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataIndex: "department",
      title: "Department",
      sort: true,
      headerStyle: {
        width: "12rem",
      },
    },
    {
      dataIndex: "enrolmentDate",
      title: "Enrolment Date",
      sort: true,
      headerStyle: {
        width: "12rem",
      },
      csvrender: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Employment Status",
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.status).startsWith(String(value)),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      sort: true,
      headerStyle: {
        width: "7rem",
      },
    },
  ];

  const pendingApprovalColumns = [
    {
      dataIndex: "userId",
      title: "EMP ID",
      sort: true,
      width: 100,
    },
    {
      dataIndex: "userName",
      title: "Name & Email",
      sort: true,
      width: 200,
      csvText: "Name",
      render: (cell, row) => {
        return (
          <>
            <div>
              <strong
                style={{ fontFamily: "RakutenRoundSemiBold" }}
              >{`${row.userName}`}</strong>
            </div>{" "}
            <div>{`${row.userEmail}`}</div>
          </>
        );
      },
    },
    {
      dataIndex: "role",
      title: "Role",
      sort: true,
      width: 100,
    },
    {
      dataIndex: "country",
      title: "Country",
      sort: true,
      width: 100,
    },
    {
      dataIndex: "department",
      title: "Department",
      sort: true,
      width: 250,
    },
    {
      dataIndex: "enrolmentDate",
      title: "Enrolment Date",
      sort: true,
      width: 200,
      csvrender: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "status",
      title: "Employment Status",
      filterSearch: true,
      onFilter: (value, record) =>
        String(record.status).startsWith(String(value)),
      filters: employeeStatus.map((items) => ({ text: items, value: items })),
      sort: true,
      align: "center",
      width: 200,
    },
    {
      dataIndex: "userEmail",
      title: "Action",
      width: 260,
      render: (cell, row) => {
        return (
          <div style={{ display: "flex" }}>
            <Button
              type="button"
              icon={
                <CheckCircleOutlined
                  style={{ color: "#047205", fontSize: "1.4rem" }}
                />
              }
              onClick={(event) => {
                setRowForPendingUser(row);
                setShowEnrollConfirmation(true);
                setDeclineUser(false);
              }}
            />
            <span>
              <Button
                type="button"
                icon={
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: "1.4rem" }}
                  />
                }
                onClick={(event) => {
                  setRowForPendingUser(row);
                  setShowEnrollConfirmation(true);
                  setDeclineUser(true);
                }}
              />
            </span>
          </div>
        );
      },
    },
  ];

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
      to: "/Admin",
    },
    {
      title: (
        <a href="/Admin/Courses" className="common-breadcrumbs-color">
          Instructor-led Courses
        </a>
      ),
      to: "/Admin/Courses",
    },
    {
      title: (
        <a
          href={`/Admin/SessionList/${courseData.id}`}
          className="common-breadcrumbs-color"
        >
          Manage Sessions
        </a>
      ),
    },
    { title: "Enrolled Users" },
  ];

  const [tableColumns, settableColumns] = useState(sessionUsersColumns);
  useEffect(() => {
    settableColumns(sessionUsersColumns);
  }, [userDateFormat]);

  useEffect(() => {
    const tday = moment(new Date().toISOString()).format("YYYY-MM-DD");
    const today = TimeFormatter.getUtcTimeStamp(tday);
    const sessionStartDate = TimeFormatter.FormatDate(startTime, offset);
    const sessionStartDateInTimeStamp =
      new Date(sessionStartDate).getTime() / 1000;
    if (
      sessionStartDateInTimeStamp > 0 &&
      sessionStartDateInTimeStamp <= today
    ) {
      setIsDisableReminder(true);
    }
  }, [startTime, isDisableRemider, offset]);

  useEffect(() => {
    getSessionData();
    // eslint-disable-next-line
  }, []);

  const getSessionData = () => {
    setShowLoader(true);
    axios
      .get(`${config.endPoint.instructorLedNew}/batch/`, { params: { batch_id: sessionId } })
      .then((response) => {
        setCourseData(response.data.data.course);
        setShowLoader(false);
        setSessionName(response.data.data.batch_name);
        setIsSessionPrivate(response.data.data.private);
        setSessionSize(response.data.data.size);
        setEnrolledUsers(response.data.data.counter);
        settableColumns(sessionUsersColumns);
        setIsPrivate(response.data.data.private);
        setStartTime(response.data.data.start_time);
        setOffset(response.data.data.tz_offset);
        getEnrolledUsersData(
          response.data.data.course.id,
          response.data.data.course.identity_id
        );
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const getEnrolledUsersData = async (courseID, identityId) => {
    settableColumns(sessionUsersColumns);
    setShowLoader(true);
    setFileName(identityId + "_enrolled_list");
    const url = `${config.endPoint.instructorLedNew}/admin/view?session_id=${sessionId}&status_type=enrolled`;
    axios
      .get(url)
      .then((response) => {
        setListData(response.data.data);
        setShowLoader(false);
        setTableData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const getPendingUsersData = (courseID) => {
    settableColumns(pendingApprovalColumns);
    setShowLoader(true);
    const url = `${config.endPoint.instructorLedNew}/admin/view?session_id=${sessionId}&status_type=approvalPending`;
    axios
      .get(url)
      .then((response) => {
        setListData(response.data.data);
        setShowLoader(false);
        setTableData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
        }
      });
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAddUserModal(false);
  }

  const onAddUsers = () => {
    setShowAddUserModal(true);
  };

  const onDeleteUsers = () => {
    if (selectedUsers.length > 0) {
      setShowDeleteConfirmation(true);
      setSelectedRowKeys([]);
    } else {
      setAlert("Please select users who have to be deleted from session");
      setShowAlert(true);
      setAlertType("error");
    }
  };

  const reminder = () => {
    setShowReminderConfirmation(true);
  };

  const onDeleteRequest = () => {
    const deletedUsers = selectedUsers.map((user) => user.userEmail);
    axios
      .post("admin/bulk/unenroll/batch/", {
        id: Number(sessionId),
        email_list: deletedUsers,
      })
      .then(() => {
        setAlert("Selected Users deleted successfully from the session!");
        setShowAlert(true);
        setAlertType("success");
        handleClose();
        getEnrolledUsersData(courseData.id);
        setSelectedUsers([]);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleClose();
        }
      });
  };

  const reminderRequest = () => {
    axios
      .get(`/email-notification/approval/${sessionId}`)
      .then((res) => {
        if (res.status === 200) {
          setAlert(res?.message || "Reminder Email sent Successfully");
          setAlertType("success");
          setShowReminderConfirmation(false);
        } else {
          setAlert(res?.message || "Failed to send Reminder Email");
          setAlertType("error");
          setShowAlert(true);
          setShowReminderConfirmation(false);
        }
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
        setShowReminderConfirmation(false);
      });
  };

  const handleClose = () => {
    setShowDeleteConfirmation(false);
  };

  const onPageFilterChange = (value) => {
    switch (value) {
      case "1":
        setFileName(courseData?.identity_id + "_enrolled_list");
        getEnrolledUsersData(courseData.id);
        setPendingSelected(false);
        break;
      case "2":
        setFileName(courseData?.identity_id + "_pending_list");
        getPendingUsersData(courseData.id);
        setPendingSelected(!pendingSelected);
        break;
      default:
        setFileName(courseData?.identity_id + "_enrolled_list");
        getEnrolledUsersData(courseData.id);
        setPendingSelected(false);
        break;
    }
  };

  const modalContent = (
    <>
      <Row>
        <Col className={style.modalContentStyle}>
          <p>
            Are you sure you want to delete following enrolled users from the
            session?
          </p>
          <ul>
            {selectedUsers.map((user) => {
              return <li>{user.userEmail}</li>;
            })}
          </ul>
        </Col>
      </Row>
    </>
  );

  const reminderContent = (
    <>
      <Row>
        <Col>
          <p>
            Are you sure you want to send the approval reminder email
            notification ?
          </p>
        </Col>
      </Row>
    </>
  );

  const handleCloseEnrollConfirmation = () => {
    setShowEnrollConfirmation(false);
    setShowBulkApprovalModel(false);
    setIsBulkOperation(false);
  };

  const handleEnrollUserAccepted = () => {
    axios
      .post(
        `/approvals/course/${courseData.id}`,
        {
          action: "approve",
          user_id: isBulkOperation
            ? selectedUsers.map((user) => user.userId).join(',')
            : rowForPendingUser.userId + ',',
          session_id: Number(sessionId),
        }
      )
      .then(() => {
        if (isBulkOperation) {
          setAlert(`Enrollment request accepted!`);
        } else {
          setAlert(
            `${rowForPendingUser.userName}'s enrollment request accepted!`
          );
        }
        setAlertType("success");
        setShowAlert(true);
        handleCloseEnrollConfirmation();
        getPendingUsersData(courseData.id);
        setSelectedUsers([]);
        handleClose();
        setIsBulkOperation(false);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const handleRejectUserNextSession = () => {
    axios
      .post(
        `/approvals/course/${courseData.id}`,
        {
          action: "nextSession",
          user_id: isBulkOperation
            ? selectedUsers.map((user) => user.userId).join(',')
            : rowForPendingUser.userId + ',',
          session_id: Number(sessionId),
        }
      )
      .then(() => {
        if (isBulkOperation) {
          setAlert(
            `Enrollment request rejected and requested for next session!`
          );
        } else {
          setAlert(
            `${rowForPendingUser.userName}'s enrollment request rejected and requested for next session!`
          );
        }
        setAlertType("success");
        handleCloseEnrollConfirmation();
        getPendingUsersData(courseData.id);
        setIsBulkOperation(false);
        setShowAlert(true);
        setSelectedUsers([]);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const handleRejectUserRequest = () => {
    axios
      .post(
        `/approvals/course/${courseData.id}`,
        {
          action: "removeCourse",
          user_id: isBulkOperation
            ? selectedUsers.map((user) => user.userId).join(',')
            : rowForPendingUser.userId + ',',
            session_id: Number(sessionId),
        }
      )
      .then(() => {
        if (isBulkOperation) {
          setAlert(`Enrollment request rejected and removed from course!`);
        } else {
          setAlert(
            `${rowForPendingUser.userName}'s enrollment request rejected and removed from course!`
          );
        }
        setAlertType("success");
        setShowAlert(true);
        handleCloseEnrollConfirmation();
        getPendingUsersData(courseData.id);
        setSelectedUsers([]);
        setIsBulkOperation(false);
      })
      .catch((error) => {
        try {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
        } catch (e) {
          setAlert("Something went wrong, Please try again.");
        } finally {
          setAlertType("error");
          handleCloseEnrollConfirmation();
        }
      });
  };

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const CSVColumns = tableColumns.map((header, index) => {
    if (header.dataIndex === "userName") {
      return [
        {
          key: "name",
          label: "Name",
        },
        { key: "email", label: "Email" },
      ];
    }
    return {
      key: header.dataIndex,
      label: header.title,
    };
  });

  const toggleFilter = () => {
    return (
      <Tabs
        onChange={(e) => {
          onPageFilterChange(e);
          setSelectedUsers([]);
          setSelectedRowKeys([]);
        }}
        className={"sessionUserList-tabs"}
      >
        <TabPane tab="Confirmed" key="1"></TabPane>
        <TabPane tab="Pending Approval" key="2"></TabPane>
      </Tabs>
    );
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      enrolmentDate: data.enrolmentDate
        ? dayjs.unix(data.enrolmentDate).format("DD/MM/YYYY HH:mm:ss")
        : "N/A",
      name: data.userName,
      email: data.userEmail,
    }));
  };

  const tableHeader = (
    <div className="enrolledusers-header">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isSessionPrivate ? (
          <Col lg={6}>
            <Typography className="enrolledusers-page-title">
              Enrolled Users
              <span className={"enrolledusers-pageCount"}>
                ({showLoader ? <Spin /> : pageCount})
              </span>
            </Typography>
          </Col>
        ) : (
          <Col lg={9}>
            <Typography className="enrolledusers-page-title">
              Enrolled Users
              <span className={"enrolledusers-pageCount"}>
                ({showLoader ? <Spin /> : pageCount})
              </span>
            </Typography>
          </Col>
        )}
        <Col lg={5} className="enrolledusers-togglefilter">
          {toggleFilter()}
        </Col>
        <Col lg={2}>
          <CSVLink
            filename={fileName}
            extension=".csv"
            wrapColumnChar=""
            headers={flatten(CSVColumns)}
            data={getDataToDownload(listData)}
          >
            <Button
              type="primary"
              shape="circle"
              icon={<DownloadOutlined />}
              size="large"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "1.5rem",
              }}
            />
          </CSVLink>
        </Col>
        <Col lg={4}>
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search"
            suffix={<SearchOutlined />}
            className={"notificationSearch"}
            style={{
              width: " 10rem",
              height: "2.5rem",
              fontSize: "1rem",
              fontFamily: "RakutenRoundRegular",
              borderRadius: "4px",
            }}
          />
        </Col>
        {(pendingSelected &&
          (!selectedUsers.length ? (
            <Col lg={3}>
              <Button
                isDisabled={isDisableRemider}
                className="user-button"
                onClick={reminder}
              >
                Reminder
              </Button>
            </Col>
          ) : (
            <>
              <Col lg={1}>
                <Button
                  isDisabled={!selectedUsers.length}
                  type="button"
                  icon={
                    <CheckCircleOutlined
                      style={{ color: "#047205", fontSize: "1.4rem" }}
                    />
                  }
                  onClick={() => {
                    setShowBulkApprovalModel(true);
                    setDeclineUser(false);
                    setIsBulkOperation(true);
                  }}
                />
              </Col>
              <Col lg={1}>
                <Button
                  isDisabled={!selectedUsers.length}
                  type="button"
                  icon={
                    <CloseCircleOutlined
                      style={{ color: "red", fontSize: "1.4rem" }}
                    />
                  }
                  onClick={() => {
                    setShowBulkApprovalModel(true);
                    setDeclineUser(true);
                    setIsBulkOperation(true);
                  }}
                />
              </Col>
            </>
          ))) || (
          <Col lg={3}>
            <Button
              isDisabled={selectedUsers.length === 0}
              className="user-button"
              onClick={onDeleteUsers}
            >
              Delete
            </Button>
          </Col>
        )}
        {isSessionPrivate && (
          <Col lg={3}>
            <Button
              type="button"
              isDisabled={selectedUsers.length === 0}
              className="addNewCourseButton"
              icon={<PlusCircleOutlined />}
              onClick={onAddUsers}
            >
              Add Users
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <AdminPageSubTitle
          courseId={courseData && courseData.identity_id}
          courseName={courseData && courseData.course_name}
          sessionId={sessionId}
          sessionName={sessionName}
          otherStyle={style.subTitle}
        />
      </Row>
    </div>
  );

  const acceptUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Approve </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>{" "}
      ?
    </h3>
  );

  const declineUserModal = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Reject </b>
      <span>course enrollment request for </span>{" "}
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
        {rowForPendingUser.userName}
      </b>{" "}
      ?
    </h3>
  );

  const acceptUserModalBulk = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Approve </b>
      <span>course enrollment request for following users?</span>
    </h3>
  );

  const declineUserModalBulk = (
    <h3 style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <b style={{ fontFamily: "RakutenRoundSemiBold" }}>Reject </b>
      <span>course enrollment request for following users?</span>
    </h3>
  );

  const acceptUserModalFooter = (
    <p style={{ marginLeft: "2.2rem", fontFamily: "RakutenRoundRegular" }}>
      <br></br>* Once approved user will get a confirmed seat in the session.
      For any changes to enrollment you will have to reach out to the L&D team.
    </p>
  );

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedUsers(selectedRows);
    },
  };

  return (
    <>
      <div>
        <div className="sessionuserlist-container">
          {showAlert && (
            <Alert
              closable
              message={alert}
              type={alertType}
              onCloseAlert={() => setAlert("")}
              className={"admin-alert"}
            />
          )}
          <Breadcrumb
            className="enrolledusers-breadcrumb"
            style={{ fontFamily: "RakutenRoundRegular", color: "#00A0F0" }}
            items={crumbs}
          />
          <div className="enrolledusers-table_header">{tableHeader}</div>
          <div className="enrolledusers-table">
            <Table
              rowKey="userEmail"
              columns={tableColumns}
              dataSource={listData}
              loading={showLoader}
              rowSelection={rowSelection}
              pagination={{
                position: ["bottomCenter"],
                showQuickJumper: true,
              }}
            />
          </div>
        </div>
      </div>
      {showDeleteConfimation && (
        <AdminConfirmationModal
          toggle={true}
          title="Delete "
          titleBold=" Enrolled Session Users"
          show={showDeleteConfimation}
          leftButton="YES"
          rightButton="NO"
          handleClose={handleClose}
          rightButtonClick={handleClose}
          leftButtonClick={onDeleteRequest}
          modalContent={modalContent}
        />
      )}

      {showReminderConfimation && (
        <AdminConfirmationModal
          toggle={true}
          title=""
          titleBold="Approval Reminder"
          show={showReminderConfimation}
          leftButton="YES"
          rightButton="NO"
          handleClose={() => setShowReminderConfirmation(false)}
          rightButtonClick={() => setShowReminderConfirmation(false)}
          leftButtonClick={reminderRequest}
          modalContent={reminderContent}
        />
      )}

      {showEnrollConfimation && (
        <AdminConfirmationModal
          toggle={true}
          show={showEnrollConfimation}
          leftButton={declineUser ? "REQUEST FOR NEXT SESSION" : "YES"}
          rightButton={declineUser ? "REJECT ENROLLMENT REQUEST" : "NO"}
          handleClose={handleCloseEnrollConfirmation}
          rightButtonClick={
            declineUser
              ? handleRejectUserRequest
              : handleCloseEnrollConfirmation
          }
          leftButtonClick={
            declineUser ? handleRejectUserNextSession : handleEnrollUserAccepted
          }
          title={declineUser ? declineUserModal : acceptUserModal}
          modalFooterContent={declineUser ? "" : acceptUserModalFooter}
        />
      )}

      {showBulkApprovalModel && (
        <AdminConfirmationModal
          toggle={true}
          show={showBulkApprovalModel}
          handleClose={handleCloseEnrollConfirmation}
          leftButton={declineUser ? "REQUEST FOR NEXT SESSION" : "YES"}
          rightButton={declineUser ? "REJECT ENROLLMENT REQUEST" : "NO"}
          rightButtonClick={
            declineUser
              ? handleRejectUserRequest
              : handleCloseEnrollConfirmation
          }
          leftButtonClick={
            declineUser ? handleRejectUserNextSession : handleEnrollUserAccepted
          }
          title={declineUser ? declineUserModalBulk : acceptUserModalBulk}
          modalFooterContent={declineUser ? "" : acceptUserModalFooter}
          modalContent={
            selectedUsers.length && (
              <ul>
                {selectedUsers.map((user) => (
                  <li>
                    {user.userName}({user.userEmail})
                  </li>
                ))}
              </ul>
            )
          }
        />
      )}

      {(showAddUserModal || showErrorModal) && (
        <ImportUserHelper
          showAddUserModal={showAddUserModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          handleCloseErrorModal={handleCloseErrorModal}
          page={"session"}
          courseId={courseData.id}
          sessionId={sessionId}
          sessionName={sessionName}
          isSessionPrivate={isSessionPrivate}
          sessionSize={sessionSize}
          enrolledUsers={enrolledUsers}
          setAlert={setAlert}
          setAlertType={setAlertType}
          updatePage={() => {
            if (pendingSelected) {
              getPendingUsersData(courseData.id);
            } else {
              setPendingSelected(true);
              getPendingUsersData(courseData.id);
            }
          }}
          openErrorCloseAddUser={openErrorCloseAddUser}
        />
      )}
    </>
  );
}
export default AntdSessionUsersList;

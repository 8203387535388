import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Button } from "antd";
import style from "./pageFooter.scss";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { serializeCategoryResponse } from "../../serializer/categorySerializer";
import { truncate } from "lodash";
import { CategoryContext } from "../../context/CategoryContext";

function PageFooter() {
  const [categoriesList, setCategoriesList] = useState([]);
  const [showFullIlList, setShowFullIlList] = useState(false);
  const { categoryResponse } = useContext(CategoryContext);

  const isMobile = window.innerWidth < 786;
  let firstList, secondList, thirdList;

  // Call to GET Categories list API
  useEffect(() => {
    setCategoriesList(serializeCategoryResponse(categoryResponse));
  }, [categoryResponse]);

  if (categoriesList && categoriesList.length) {
    // Sort list
    categoriesList.sort((cat1, cat2) => (cat1.name > cat2.name ? 1 : -1));

    // Split array into 3 parts
    const size = Math.ceil(categoriesList.length / 3);
    firstList = categoriesList.slice(0, size);
    secondList = categoriesList.slice(size, size + size);
    thirdList = categoriesList.slice(size + size);
  }

  const handleScroll = () => {
    window.scrollTo({
      top: 600,
      behavior: "smooth",
    });
  };

  const renderIlMobileList = () => {
    const listToShown = showFullIlList
      ? categoriesList
      : categoriesList?.slice(0, 5);

    return (
      <div>
        {listToShown?.map((item, index) => {
          return (
            <a
              key={index}
              href={`/Classroom_training/all/${item.id}`}
              style={{ color: "black" }}
              onClick={handleScroll}
            >
              <p>{truncate(item.name.toUpperCase(), { length: 30 })}</p>
            </a>
          );
        })}
        {showFullIlList ? (
          <Button
            onClick={() => setShowFullIlList(false)}
            type="link"
            style={{ fontFamily: "RakutenRoundBold", padding: 0 }}
            className="primary-color"
          >
            <span
              style={{ textDecoration: "underline" }}
              className="primary-color"
            >
              SHOW LESS
            </span>{" "}
            <UpOutlined />
          </Button>
        ) : (
          <Button
            onClick={() => setShowFullIlList(true)}
            type="link"
            style={{
              fontFamily: "RakutenRoundBold",
              padding: 0,
            }}
            className="primary-color"
          >
            <span
              style={{ textDecoration: "underline" }}
              className="primary-color"
            >
              SHOW MORE
            </span>{" "}
            <DownOutlined />
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={style.pageFooter}>
      <Layout style={{ backgroundColor: "white" }}>
        <Row
          style={{
            justifyContent: isMobile ? "flex-start" : "center",
            margin: "auto",
          }}
        >
          <Col lg={18} sm={24} className={"footerPadding"}>
            <Row>
              <Col>
                <p className={"instructorLedHeading"}>INSTRUCTOR-LED</p>
              </Col>
            </Row>
            {window.innerWidth > 786 ? (
              <Row gutter={[40]}>
                <Col lg={8} sm={24}>
                  <div className={"instructorLedContents"}>
                    {firstList &&
                      firstList.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={`/Classroom_training/all/${item.id}`}
                            style={{ color: "black" }}
                            onClick={handleScroll}
                          >
                            <p style={{ width: "20rem" }}>
                              {item.name.toUpperCase()}
                            </p>
                          </a>
                        );
                      })}
                  </div>
                </Col>
                <Col lg={8} sm={24}>
                  <div className={"instructorLedContents"}>
                    {secondList &&
                      secondList.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={`/Classroom_training/all/${item.id}`}
                            style={{ color: "black" }}
                            onClick={handleScroll}
                          >
                            <p style={{ width: "20rem" }}>
                              {item.name.toUpperCase()}
                            </p>
                          </a>
                        );
                      })}
                  </div>
                </Col>
                <Col lg={8} sm={24}>
                  <div className={"instructorLedContents"}>
                    {thirdList &&
                      thirdList.map((item, index) => {
                        return (
                          <a
                            key={index}
                            href={`/Classroom_training/all/${item.id}`}
                            style={{ color: "black" }}
                            onClick={handleScroll}
                          >
                            <p style={{ width: "20rem" }}>
                              {item.name.toUpperCase()}{" "}
                            </p>
                          </a>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            ) : (
              renderIlMobileList()
            )}
          </Col>
          <Col className={"elearningPadding"}>
            <Row gutter={[10]} className={"footerPadding"}>
              <Col sm={24}>
                <p className={"eLearningHeading"}>E-LEARNING</p>
                <div className={"eLearningContents"}>
                  <p>
                    <Link
                      to={{
                        pathname: "/e-learning-courses-list/all/all",
                      }}
                      style={{ color: "black" }}
                    >
                      RLMS E-LEARNING
                    </Link>
                  </p>
                  <p>
                    <a
                      style={{ color: "black" }}
                      target="_blank"
                      href="https://www.udemy.com/"
                      rel="noopener noreferrer"
                    >
                      UDEMY
                    </a>
                  </p>
                  <p>
                    <a
                      style={{ color: "black" }}
                      target="_blank"
                      href="https://www.pluralsight.com/"
                      rel="noopener noreferrer"
                    >
                      PLURALSIGHT
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={"eLearningHeading"}>SUPPORT</p>

                <span
                  style={{
                    fontFamily: "RakutenRoundRegular",
                    fontSize: 14,
                    color: "black",
                  }}
                >
                  support-LMS-India@mail.rakuten.com
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={24} sm={8}>
            <p className={"rakutenAllRight"}>
              © Rakuten India Enterprise Private Limited. All rights reserved.
            </p>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

export default PageFooter;

export function compareObjects(obj1, obj2) {
  // Function to convert all numbers to strings in an object
  function convertNumbersToStrings(obj) {
    const newObj = {};
    for (const key in obj) {
      if (typeof obj[key] === "number") {
        newObj[key] = obj[key].toString();
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  // Convert numbers to strings in both objects
  const convertedObj1 = convertNumbersToStrings(obj1);
  const convertedObj2 = convertNumbersToStrings(obj2);

  // Compare the two objects
  const keys1 = Object.keys(convertedObj1);
  const keys2 = Object.keys(convertedObj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (convertedObj1[key] !== convertedObj2[key]) {
      return false;
    }
  }

  return true;
}

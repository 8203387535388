import React from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import style from "./statsTile.module.css";
import ConditionalWrap from "../validation/utility/ConditionalWrap";

const StatsTile = ({
  label,
  value,
  insight = null,
  span = 0,
  courseType,
  description,
}) => {
  return (
    <>
      <Col md={span || 3}>
        <ConditionalWrap
          condition={description}
          wrap={(children) => (
            <OverlayTrigger
              overlay={<Tooltip>{description}</Tooltip>}
              placement="auto"
            >
              {children}
            </OverlayTrigger>
          )}
        >
          <div className={style.tileContainer}>
            {courseType && (
              <span
                className={[
                  style.courseType,
                  courseType === "EL" ? style.el : style.il,
                ].join(" ")}
              >
                {courseType === "EL" ? "E-Learning" : "Instructor-Led"}
              </span>
            )}
            <div className={style.tileLabel}>{label}</div>
            <div className={style.tileValue}>{value}</div>
            {insight && (
              <div
                className={style.tileInsight}
                dangerouslySetInnerHTML={{ __html: insight }}
              ></div>
            )}
          </div>
        </ConditionalWrap>
      </Col>
    </>
  );
};

StatsTile.propTypes = {
  span: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  insight: PropTypes.string,
};
export default StatsTile;

import React from "react";
import { Row, Col, Typography, Breadcrumb } from "antd";
import "./antDesignHeroBanner.css";

export default function HeroBanner(props) {
  const { heroBanner, title, description } = props;

  return (
    <Row
      className="hero-row"
      style={{
        height: "448px",
        backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${heroBanner})`,
      }}
    >
      <Col className="hero-column">
        <Row>
          <Col>
            <Breadcrumb
              separator={<span className="breadcrumb-title">/</span>}
              items={props.crumbs}
              className="breadCrumb"
            ></Breadcrumb>
          </Col>
        </Row>
        <Row className="title-row">
          <Col span={window.innerWidth > 786 ? 14 : 22}>
            <Typography.Text className="title">{title}</Typography.Text>
          </Col>
        </Row>
        <Row style={{ paddingLeft: window.innerWidth > 786 ? "" : "1rem" }}>
          <Col span={window.innerWidth > 786 ? 14 : 20}>
            <Typography.Text className="description">
              {description}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Layout } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  Alert,
  CourseFilter,
  CourseTile,
  HeroBannerCousre,
  LearningBySkills,
  Quotation,
} from "../../../component-library";
import config from "../../../config";
import UtilityFunctions from "../../../utils/utility";
import "./instructorLed.scss";
import PageFooter from "../common/PageFooter";
import { compareObjects } from "./ilUtils";

const crumbs = [
  {
    title: "Home",
    to: "/home",
  },
  { title: "Instructor Led" },
];
const { instructorLed } = config;
function InstructorLed(props) {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({});
  const [initialFilter, setInitialFilter] = useState({});
  const [courseList, setCourseList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMoreCourses, setHasMoreCourses] = useState(true);
  const [courseCount, setCourseCount] = useState(0);
  const [queryString, setQueryString] = useState("");
  const [reset, setReset] = useState(false);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const isMobile = window.innerWidth < 786;
  const isDesktop = window.innerWidth < 1400;

  // first time when filter passed from url.
  // TODO temp fix need to check the logic
  const parentCategoryId =
    props.match.params.parentCategoryId === "all"
      ? undefined
      : props.match.params.parentCategoryId;
  const categoryId =
    props.match.params.categoryId === "all"
      ? undefined
      : props.match.params.categoryId;

  const queryFilter = async function () {
    const obj = {};
    if (parentCategoryId) {
      obj.parentCategoryId = parentCategoryId;
    }
    if (categoryId) {
      obj.categoryId = categoryId;
    }
    obj.sortBy = "newest";
    setFilters(obj);
  };

  // eslint-disable-next-line
  const optimizedSearch = useCallback(
    UtilityFunctions.debounce(queryFilter, 500),
    []
  );

  useEffect(() => {
    optimizedSearch();
  }, [parentCategoryId, optimizedSearch, categoryId]);

  // when data comes from courseFilter and first load.
  useEffect(() => {
    let newFilter = ``;
    let filtersToStringify = {};
    if (filters && Object.keys(filters).length > 0) {
      if (
        filters &&
        filters.parentCategoryId &&
        filters.parentCategoryId !== config.defaultParentCategory.value
      ) {
        filtersToStringify.parentCategoryId = filters.parentCategoryId;
        newFilter += `&parent_category_id=${filters.parentCategoryId}`;
      }
      if (
        filters &&
        filters.categoryId &&
        filters.categoryId !== config.defaultCategory.value
      ) {
        filtersToStringify.categoryId = filters.categoryId;
        newFilter += `&category_id=${filters.categoryId}`;
      }
      if (filters && filters.subCategoryId) {
        filtersToStringify.subCategoryId = filters.subCategoryId;
        newFilter += `&subcategory_id=${filters.subCategoryId}`;
      }
      if (filters && filters.sortBy) {
        filtersToStringify.sortBy = filters.sortBy;
        newFilter += `&sort_by=${filters.sortBy}`;
      }
      if (
        filters &&
        filters.languageId &&
        filters.languageId !== config.defaultLanguage.value
      ) {
        filtersToStringify.languageId = filters.languageId;
        newFilter += `&language=${filters.languageId}`;
      }
      if (filters && filters.courseProviderId) {
        filtersToStringify.courseProviderId = filters.courseProviderId;
        newFilter += `&courseProvider=${filters.courseProviderId}`;
      }
      if (
        filters &&
        filters.proficiencyId &&
        filters.proficiencyId !== config.defaultProficiency.value
      ) {
        filtersToStringify.proficiencyId = filters.proficiencyId;
        newFilter += `&proficiency=${filters.proficiencyId}`;
      }
    }
    if (JSON.stringify(filters) !== JSON.stringify(initialFilter)) {
      setPageNumber(1);
    }
    setQueryString(newFilter);
  }, [initialFilter, filters]);

  useEffect(() => {
    if (queryString) {
      setPageNumber(1);
      setCourseList([]);
      setHasMoreCourses(true);
    }
    fetchCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  const fetchCourseDetails = () => {
    const endPoint = `${config.endPoint.instructorLedNew}/course/list?page_size=${instructorLed.pagination.pageSize}&page_number=${pageNumber}`;
    axios
      .get((queryString && `${endPoint}${queryString}`) || endPoint)
      .then((response) => {
        if (!compareObjects(filters, initialFilter)) {
          setPageNumber(1);
          setCourseList([]);
          setInitialFilter(filters);
        }
        setCourseList((current) => [...current, ...response.data.data]);
        setPageNumber(pageNumber + 1);
        setCourseCount(response.data.total_count || 0);
        if (!response.data.data.length) {
          setHasMoreCourses(false);
        }
      })
      .catch((error) => {
        setAlertType("danger");
        setAlert(
          error?.data?.response || "Something went wrong, please try again"
        );
      });
  };

  const courseTileAlert = (alert, alertType) => {
    setAlertType(alertType);
    setAlert(alert);
  };

  // when filter passed from courseFilter -> add that to filter params and make api calls.
  const handleFilter = (current) => {
    setFilters(current);
  };

  //when filter passed from skill tile.
  const updateSuperCatergory = (id) => {
    setReset(true);
    setFilters({ parentCategoryId: id, sortBy: "newest" });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroBannerCousre
        crumbs={crumbs}
        heroBanner={config.instructorLed.heroBanner}
        title={config.instructorLed.title}
        description={config.instructorLed.description}
      />
      <div className="elearning-common-container">
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <div
          fluid="true"
          style={{
            marginTop: "0.813rem",
            display: "flex",
            justifyContent: "space-evenly",
          }}
          className="il-course-count"
        >
          <span className={"countText"}>
            Showing <span className="span-course-count"> {courseCount} </span>
            total results for Instructor-Led Courses
          </span>
        </div>
        <div className="il-course-filter">
          <CourseFilter
            upliftFilters={handleFilter}
            filters={filters}
            reset={reset}
            isInstuctorLed={true}
          />
        </div>
        <div
          style={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}
          className="infinite-scroll-container"
        >
          <InfiniteScroll
            dataLength={courseList.length}
            next={fetchCourseDetails}
            hasMore={hasMoreCourses}
            className="il-grid"
            scrollThreshold={0.25}
          >
            {courseList && courseList.length ? (
              courseList.map((current, index) => {
                return (
                  <div
                    style={{
                      width: "19.5rem",
                      marginBottom: "1.5rem",
                      marginRight: (index + 1) % 4 === 0 ? 0 : "1rem",
                    }}
                    key={index}
                    className="course-list"
                  >
                    <CourseTile
                      dataElement={current}
                      index={index}
                      coursePath={`/course-details/${current.id}`}
                      courseTileAlert={(alert, alertType) =>
                        courseTileAlert(alert, alertType)
                      }
                    />
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  width: isMobile ? "17rem" : "29rem",
                  textAlign: "center",
                  paddingBottom: "1rem",
                }}
              >
                No course available in your region for the category selected.
              </div>
            )}
          </InfiniteScroll>
          {hasMoreCourses && (
            <div
              style={{
                fontFamily: "RakutenRoundRegular",
                fontSize: 24,
                margin: isMobile ? "1rem" : "",
                textAlign: "center",
              }}
            >
              <b>Loading...</b>
            </div>
          )}
          {!hasMoreCourses && (
            <div
              style={{
                fontFamily: "RakutenRoundRegular",
                fontSize: 24,
                margin: isMobile ? "1rem" : "",
                textAlign: "center",
              }}
            >
              <b>
                You've reached the end of the list. All the courses are listed
                above.
              </b>
            </div>
          )}
        </div>
      </div>

      <div style={{ backgroundColor: "white" }}>
        <Layout
          bsPrefix={`container ${"container"}`}
          style={{
            backgroundColor: "white",
          }}
        >
          <div
            className={"il-second-container"}
            style={{
              marginTop: isMobile ? 0 : "120px",
            }}
          >
            <LearningBySkills
              changeSuperCatergory={(id) => updateSuperCatergory(id)}
            />
            <div className={"il-quotation"}>
              <Quotation />
            </div>
          </div>
        </Layout>
      </div>
      {window.innerWidth < 786 && <PageFooter />}
    </div>
  );
}

export default InstructorLed;

import { Button } from "antd";
import * as style from "./outlinedButton.module.css";

const AntDesignOutlinedButton = ({ text, size, onClick, isDisabled, icon }) => {
  return (
    <Button
      type="primary"
      size={size || "large"}
      onClick={onClick}
      disabled={isDisabled}
      style={{
        width: "100%",
      }}
      className={style.outlined}
    >
      {icon && (
        <>
          <span className={style.iconStyle}>
            <img src={icon} alt="icon" />
          </span>&nbsp;
        </>
      )}
      {text || ""}
    </Button>
  );
};

export default AntDesignOutlinedButton;

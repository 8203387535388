export const timeToDecimal = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":").map(Number);

  // Convert hours and minutes to decimal representation
  const decimalTime = hours + minutes / 60;

  // Round to two decimal places
  const roundedDecimalTime = Math.round(decimalTime * 100) / 100;

  return roundedDecimalTime;
};

export const decimalToTime = (decimalTime) => {
  // Extract hours and minutes
  const hours = Math.floor(decimalTime);
  const minutes = Math.round((decimalTime % 1) * 60);

  // Format hours and minutes as "HH:mm"
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;

  return formattedTime;
};

import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { ReactComponent as LeftArrow } from "./../../assets/icons/left_arrow_blue.svg";
import { ReactComponent as RightArrow } from "./../../assets/icons/right_arrow_blue.svg";

import "./antDesignCarouselPagination.css";
import { UserContext } from "../../context/UserContext";
function CarouselPagination({
  currentPage,
  maxPages,
  maxWidth,
  prevHandler,
  nextHandler,
  prevDisabled,
  nextDisabled,
}) {
  const ACTIVE_WIDTH = "2.0625";
  const ACTIVE_HEIGHT = "0.2";
  const [start, setStart] = useState(0);
  const { userConfig } = useContext(UserContext);

  useEffect(() => {
    const divisor = maxPages === 1 ? 1 : maxPages - 1;
    const STEP_SIZE = (maxWidth - ACTIVE_WIDTH) / divisor;
    setStart(currentPage * STEP_SIZE);
  }, [currentPage, maxPages, maxWidth]);

  const lineStyle = {
    backgroundColor: userConfig.primary,
    width: `${ACTIVE_WIDTH}rem`,
    marginLeft: `${start}rem`,
    height: `${ACTIVE_HEIGHT}rem`,
  };

  const baseStyle = {
    width: `${maxWidth}rem`,
    height: `${ACTIVE_HEIGHT}rem`,
  };

  return (
    <>
      <Button
        shape="circle"
        size="large"
        onClick={prevHandler}
        disabled={prevDisabled}
        className={"carousel-btn"}
      >
        <LeftArrow />
      </Button>
      <div className={"carousel-baseLine"} style={baseStyle}>
        <div className={"carousel-progressLine"} style={lineStyle}></div>{" "}
      </div>
      <Button
        shape="circle"
        size="large"
        onClick={nextHandler}
        disabled={nextDisabled}
        className={"carousel-btn"}
      >
        <RightArrow />
      </Button>
    </>
  );
}

CarouselPagination.propTypes = {
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  maxWidth: PropTypes.number,
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  prevDisabled: PropTypes.bool,
  nextDisabled: PropTypes.bool,
};

export default CarouselPagination;

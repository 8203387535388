import Storage from "./storage";
import errorDefinition from "./errors";
import axios from "axios";
import config from "../../config";
export default class SCORM2004Adapter {
  constructor(userId, courseId, pathId) {
    this.userId = userId;
    this.courseId = courseId;
    this.pathId = pathId;
  }

  Initialize() {
    Storage.setItem("Initialized", true);
    return true;
  }

  Terminate() {
    if (!this.isInitialized()) {
      this.setError(301);
      return false;
    }
    let result = this.Commit();
    Storage.setItem("Initialized", false);
    Storage.clearAll();
    return result;
  }

  GetValue(element) {
    if (!this.isInitialized()) {
      this.setError(301);
      return false;
    }
    let value = Storage.getItem(element);
    if (!value) {
      this.setError(201);
      return "";
    }
    return value;
  }

  SetValue(element, value) {
    if (!this.isInitialized()) {
      this.setError(301);
      return false;
    }
    Storage.setItem(element, value);
    return Storage.getItem(element);
  }

  Commit() {
    let data = Storage.getAll();
    delete data["errors"];
    delete data["Initialized"];

    let reqBody = {
      attemptResult: data,
    };
    const instance = axios.create();
    const customParams = this.pathId ? { learningpathId: this.pathId } : {};
    instance
      .post(
        `${config.endPoint.elearning}/course/${this.courseId}/user-scrom-set`,
        reqBody,
        {
          params: customParams,
        }
      )
      .then((response) => {
        //TODO - Temporary fix for scrom issue
        if (response.data.success) {
          if (response.data?.data?.status === "completed") {
            alert("You have completed the course please click on go back");
          }
        }
        return true;
      })
      .catch(() => {
        this.setError(101);
        return false;
      });
  }

  GetLastError() {
    let errors = Storage.getItem("errors");
    errors = JSON.parse(errors);
    if (errors && errors.length > 0) {
      return errors.pop();
    }
    return "";
  }

  GetErrorString(errorCode) {
    errorCode = errorCode.toString();
    let error = errorDefinition[errorCode];
    if (!error) return "";
    return error["errorString"];
  }

  GetDiagnostic(errorCode) {
    errorCode = errorCode.toString();
    let error = errorDefinition[errorCode];
    if (!error) return "";
    return error["diagnostic"];
  }

  isInitialized() {
    let initialized = Storage.getItem("Initialized");
    return initialized;
  }

  setError(errorCode) {
    errorCode = errorCode.toString();
    let errors = Storage.getItem("errors");
    if (!errors) errors = "[]";
    errors = JSON.parse(errors);
    errors.push(errorCode);
    errors = JSON.stringify(errors);
    Storage.setItem("errors", errors);
  }
}

export function Capitalize(name) {
  return name
    ?.toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export function getEllipsis(sentence, length) {
  if (sentence.length < length) return sentence;
  return sentence.substring(0, length) + "...";
}

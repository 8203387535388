import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Input, Select, notification } from "antd";
import config from "../../../config";
import axios from "axios";

function AddCompany({ setIsModalOpen, updateTableData }) {
  const [companyDetails, setCompanyDetails] = useState({
    name: null,
    id: null,
    statusCode: null,
    country: null,
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const dataEndpoint = `${config.endPoint.commonService}/data`;

    axios
      .get(dataEndpoint)
      .then((res) => {
        const { data } = res.data;
        const { countries } = data;

        if (countries.length > 0) {
          setCountryOptions(countries);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleInputChange = (key, value) => {
    setCompanyDetails({ ...companyDetails, [key]: value });
  };
  const getCountryOptions = () =>
    countryOptions.map((countryData) => ({
      value: countryData?.code,
      label: countryData?.country,
    }));

  const renderInpuField = (label, key, placeholder) => {
    return (
      <div
        style={{
          width: "75%",
          display: "flex",
          flexDirection: "column",
          marginTop: 8,
        }}
      >
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Input
          type="text"
          placeholder={placeholder}
          value={companyDetails[key]}
          onChange={(e) => handleInputChange(key, e.target.value)}
          style={{ borderRadius: 0 }}
        />
      </div>
    );
  };

  const renderSelectField = (
    label,
    key,
    defaultValue,
    options,
    disabled = false
  ) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
        <span style={{ fontSize: 12, color: "gray" }}>
          {label}
          <span style={{ color: "red" }}>*</span>
        </span>
        <Select
          defaultValue={defaultValue}
          placeholder="Select..."
          disabled={disabled}
          style={{
            width: "75%",
            borderRadius: 0,
          }}
          onChange={(value) => {
            let updatedCompanyDetails = { ...companyDetails, [key]: value };
            setCompanyDetails(updatedCompanyDetails);
          }}
          value={companyDetails[key]}
          options={options}
        />
      </div>
    );
  };

  const onAddCompany = async () => {
    const payload = {
      name: companyDetails.name,
      statusCode: companyDetails.statusCode,
      country: companyDetails.country,
    };

    const endpoint = `${config.endPoint.userService}/api/companies`;
    const addCompanyResponse = await axios.post(endpoint, payload);
    if (addCompanyResponse.data.status) {
      setCompanyDetails({
        name: null,
        id: null,
        statusCode: null,
        country: null,
      });
      openNotification();
      updateTableData();
    }

    setIsModalOpen(false);
  };

  const openNotification = () => {
    api.info({
      message: (
        <strong style={{ color: "black" }}>Company Added Successfully</strong>
      ),
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {contextHolder}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
          }}
        >
          {renderInpuField("Company Name", "name", "Enter company name...")}

          <span style={{ fontSize: 12, color: "gray", marginTop: 8 }}>
            Company Status
            <span style={{ color: "red" }}>*</span>
          </span>

          <Select
            placeholder="Select Company Status..."
            style={{
              width: "75%",
            }}
            className="add-dept-select"
            onChange={(value) =>
              setCompanyDetails({ ...companyDetails, statusCode: value })
            }
            options={[
              {
                value: "1",
                label: "Active",
              },
              {
                value: "0",
                label: "Inactive",
              },
            ]}
            value={companyDetails.statusCode}
          />
          {renderSelectField("Country", "country", "JPN", getCountryOptions())}
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button
          style={{ margin: 16, borderRadius: 0 }}
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          style={{ margin: 16, borderRadius: 0 }}
          type="primary"
          onClick={onAddCompany}
          className="em-tab-button"
          disabled={
            !companyDetails.name ||
            !companyDetails.statusCode ||
            !companyDetails.country
          }
        >
          Add Company
        </Button>
      </div>
    </div>
  );
}

export default AddCompany;

import PropTypes from "prop-types";
import { useState, useRef, useEffect, useCallback } from "react";
import addCalendar from "./../../../assets/icons/add_calender_invite.svg";
import noCalendar from "./../../../assets/icons/no_calender_invite.svg";
import { SendOutlined } from "@ant-design/icons";
import {
  CustomToggle,
  TextEditor,
  Validation,
} from "./../../../component-library";
import { Button, Col, Row, Modal, Form, Input, Typography } from "antd";

function SessionListEmailModal(props) {
  const {
    to,
    cc,
    bcc,
    subject,
    note,
    content,
    calendarInviteAdded,
    showEmailModal,
    sendEmailHandler,
    modalCloseHandler,
    ccHandler,
    bccHandler,
    calendarInviteAddedHandler,
    subjectHandler,
    contentHandler,
    showCalendar,
    setTo,
    validateDlm,
  } = props;
  const [errorMessage, setErrorMessage] = useState({});
  const textEditorRef = useRef(null);

  const isValidEmailAddress = useCallback((email) => {
    let flag = true;
    if (!email) {
      return flag;
    } else {
      if (!Array.isArray(email)) {
        return email?.trim()?.match(/^[^@]+@[^@]+\.[^@]{2,4}$/);
      } else {
        email.map((e) => (!isValidEmailAddress(e) ? (flag = false) : ""));
        return flag;
      }
    }
  }, []);

  const isValidContent = useCallback((text) => {
    const content = text.replace(/<[^>]*>?/gm, "");
    return content === "" ? false : true;
  }, []);

  const validateEmailDetails = useCallback(() => {
    const errors = {};
    if (!to) {
      errors.to = `Receivers email ids can not be empty`;
    } else if (validateDlm) {
      const toFields = to?.split(",");

      toFields.forEach((toField) => {
        if (!toField.includes("@mail.rakuten.com") && toField) {
          errors.to = `Please enter a valid DLM`;
        }
      });
    }

    if (!isValidEmailAddress(cc)) {
      errors.cc = `Please provide valid email address`;
    }
    if (!isValidEmailAddress(bcc)) {
      errors.bcc = `Please provide valid email address`;
    }
    if (!subject) {
      errors.subject = `Please provide subject`;
    }
    if (!isValidContent(content)) {
      errors.content = `Please provide content`;
    }
    return errors;
  }, [to, cc, bcc, subject, content, isValidEmailAddress, isValidContent]);

  useEffect(() => {
    const error = validateEmailDetails();
    setErrorMessage(error);
  }, [validateEmailDetails]);

  const ccChangeHandler = (text) => {
    const emailArr = text.split(",");
    ccHandler(emailArr);
  };

  const bccChangeHandler = (text) => {
    const emailArr = text.split(",");
    bccHandler(emailArr);
  };

  const subjectChangeHandler = (text) => {
    subjectHandler(text);
    if (!text) {
      const error = { subject: `Please provide subject` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subject: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const contentChangeHandler = (text) => {
    contentHandler(text);
    if (!isValidContent(text)) {
      const error = { content: `Please provide content` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { content: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const sendEmail = async () => {
    const errors = validateEmailDetails();
    await textEditorRef.current.deleteOrphanedImages();
    if (Object.keys(errors).length > 0) {
      setErrorMessage({ ...errorMessage, ...errors });
    } else {
      sendEmailHandler();
    }
  };

  return (
    <>
      <Modal
        title={"Mail to user"}
        width={1000}
        footer={false}
        open={showEmailModal}
        onCancel={modalCloseHandler}
      >
        <Row>
          <Col>
            {showCalendar && (
              <Col
                lg={{ offset: 5 }}
                style={{ position: "relative", top: "-3rem", left: "40rem" }}
              >
                <CustomToggle
                  toggleTitle={[
                    "Calendar invite added",
                    "Click to add calendar invite",
                  ]}
                  value={calendarInviteAdded}
                  customToggleCss={"custom_toggle"}
                  iconBoxCss={"icon_box"}
                  toggleTitleCss={"toggle_title"}
                  icons={[addCalendar, noCalendar]}
                  onChange={(value) => {
                    calendarInviteAddedHandler(value);
                  }}
                />
              </Col>
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ marginLeft: "1rem" }}>
            <Typography className={"sessionAddEdit-label"}>
              To
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <Input
              placeholder="recievers email ids"
              onChange={(e) => setTo(e.target.value.toLowerCase())}
              style={{
                fontFamily: "RakutenRoundRegular",
                width: 900,
                height: 40,
              }}
            />
            {(errorMessage.to && (
              <Validation
                message={errorMessage.to}
                style={{ marginTop: " 0.5rem" }}
              />
            )) ||
              ""}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col style={{ marginLeft: "1rem" }}>
            <Typography className={"sessionAddEdit-label"}>CC</Typography>
            <Input
              placeholder="CC email ids"
              onChange={(e) => {
                ccChangeHandler(e.target.value);
              }}
              style={{
                fontFamily: "RakutenRoundRegular",
                width: 905,
                height: 40,
              }}
            />
            {(errorMessage.cc && (
              <Validation
                message={errorMessage.cc}
                style={{ marginTop: " 0.5rem" }}
              />
            )) ||
              ""}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col style={{ marginLeft: "1rem" }}>
            <Typography className={"sessionAddEdit-label"}>BCC</Typography>
            <Input
              placeholder="BCC email ids"
              onChange={(e) => {
                bccChangeHandler(e.target.value);
              }}
              style={{
                fontFamily: "RakutenRoundRegular",
                width: 900,
                height: 40,
              }}
            />
            {(errorMessage.bcc && (
              <Validation
                message={errorMessage.bcc}
                style={{ marginTop: " 0.5rem" }}
              />
            )) ||
              ""}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col style={{ marginLeft: "1rem" }}>
            <Typography className={"sessionAddEdit-label"}>
              Subject
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <Input
              style={{
                fontFamily: "RakutenRoundRegular",
                width: 905,
                height: 40,
              }}
              onChange={(e) => {
                subjectChangeHandler(e.target.value);
              }}
              placeholder="Subject"
              value={subject.toString()}
            />
            {(errorMessage.subject && (
              <Validation
                message={errorMessage.subject}
                style={{ marginTop: " 0.5rem" }}
              />
            )) ||
              ""}
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col style={{ marginLeft: "1rem" }}>
            <div
              dangerouslySetInnerHTML={{ __html: note }}
              className={"note"}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col style={{ marginLeft: "1rem", marginBottom: "1.875rem" }}>
            <Typography className={"sessionAddEdit-label"}>
              Content
              <span className={"sessionAddEdit-required"}>*</span>
            </Typography>
            <TextEditor
              customClass={"quillEmail"}
              value={content}
              ref={textEditorRef}
              getText={(text) => {
                contentChangeHandler(text);
              }}
            />
            {(errorMessage.content && (
              <Validation
                message={errorMessage.content}
                style={{ marginTop: " 3.5rem" }}
              />
            )) ||
              ""}
          </Col>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <Col lg={{ offset: 7 }}>
              <Button
                type="button"
                className={"sessionEmail-cancelButton"}
                onClick={() => {
                  modalCloseHandler();
                  setTo("");
                }}
              >
                CANCEL
              </Button>
            </Col>
          </Col>
          <Col>
            <Button
              type="button"
              className={"sessionEmail-submitButton"}
              icon={<SendOutlined />}
              onClick={() => {
                sendEmail();
                setTo("");
              }}
              isDisabled={
                calendarInviteAdded && note === "No modules available"
                  ? true
                  : false
              }
            >
              SEND
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

SessionListEmailModal.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  cc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  bcc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subject: PropTypes.string,
  note: PropTypes.string,
  content: PropTypes.string,
  calendarInviteAdded: PropTypes.bool,
  showEmailModal: PropTypes.bool,
  sendEmailHandler: PropTypes.func,
  modalCloseHandler: PropTypes.func,
  ccHandler: PropTypes.func,
  calendarInviteAddedHandler: PropTypes.func,
  subjectHandler: PropTypes.func,
  contentHandler: PropTypes.func,
};

export default SessionListEmailModal;

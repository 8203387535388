import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import style from "./secondaryButton.module.css";
import { UserContext } from "../../context/UserContext";

function SecondaryButton({ label, handleClick, icon, otherStyle, isDisabled }) {
  const { userConfig } = useContext(UserContext);

  return (
    <>
      {isDisabled && (
        <Button
          style={{ color: userConfig.primary, borderColor: userConfig.primary }}
          bsPrefix={`${style.secondaryButton} ${otherStyle}`}
          variant="primary"
          onClick={handleClick}
          disabled
        >
          {icon && (
            <>
              <span className={style.iconStyle}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}
      {!isDisabled && (
        <Button
          style={{ color: userConfig.primary, borderColor: userConfig.primary }}
          bsPrefix={`${style.secondaryButton} ${otherStyle}`}
          variant="primary"
          onClick={() => {
            handleClick();
          }}
        >
          {icon && (
            <>
              <span className={style.iconStyle}>
                <img src={icon} alt="icon" />
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}
    </>
  );
}

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  otherStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDisabled: PropTypes.bool,
};

export default SecondaryButton;

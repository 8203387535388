import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Row, Typography } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { PlusCircleOutlined } from "@ant-design/icons";
import { TopContainer } from "../../../../component-library";
import { AntDesignBreadCrumb } from "../../../../component-library";
import AntDesignSearchInput from "../../../../component-library/antDesignSearchInput/antDesignSearchInput";
import AntDesignPrimaryButton from "../../../../component-library/antDesignButton/PrimaryButton/primaryButton";
import CourseFilter from "../../../../component-library/antDesignCourseFilter/CourseFilter";
import CourseCard from "../../../../component-library/antDesignCourseCard/CourseCard";
import ScrollToTop from "../../../../component-library/scrollToTop/ScrollToTop";

import config from "../../../../config";

import style from "./SelectCourseFromList.module.css";
import "./SelectCourseFromList.scss";

const INSTRUCTOR_LED = config.INSTRUCTOR_LED;
const E_LEARNING = config.E_LEARNING;
const PAGESIZE = config.PAGESIZE;

function SelectCourseFromList({
  handleClose,
  onELCourseSelect,
  onILCourseSelect,
  selectedELCourseNos,
  selectedILCourseNos,
  selectedContries,
  onlyPublicCourses,
  pathId,
}) {
  const filterItems = [
    { label: "INSTRUCTOR-LED", value: "INSTRUCTOR-LED" },
    { label: "E-LEARNING", value: "E-LEARNING" },
  ];
  const [filter, setFilter] = useState(filterItems[0].value);
  const [courseList, setCourseList] = useState([]);
  const [ilCourseList, setIlCourseList] = useState([]);
  const [query] = useState("");
  const [checkedILState, setCheckedILState] = useState([]);
  const [checkedELState, setcheckedELState] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [ilPageNumber, setIlPageNumber] = useState(1);
  const [elPageNumber, setElPageNumber] = useState(1);
  const [ilTotalPages, setIlTotalPages] = useState(0);
  const [elTotalPages, setElTotalPages] = useState(0);

  const [hasMoreIlCourses, setHasMoreIlCourses] = useState(false);
  const [hasMoreELCourses, sethasMoreELCourses] = useState(false);

  const handleFilter = (filter) => {
    setFilter(filter);
  };

  //TODO - handle search
  const handleSearch = (text) => {
    setSearchString(text);
    setElPageNumber(1);
    setIlPageNumber(1);
  };

  const handelILCardClick = (checkedILCourse) => {
    if (checkedILState?.length) {
      if (
        checkedILState.find((ilCourse) => ilCourse.id === checkedILCourse.id)
      ) {
        setCheckedILState(
          checkedILState.filter((ilCourse) => ilCourse.id != checkedILCourse.id)
        );
      } else {
        setCheckedILState([...checkedILState, checkedILCourse]);
      }
    } else {
      setCheckedILState([checkedILCourse]);
    }
  };
  const handelCardClick = (checkedELCourse) => {
    if (checkedELState?.length) {
      if (
        checkedELState.find((elCourse) => elCourse.id === checkedELCourse.id)
      ) {
        setcheckedELState(
          checkedELState.filter((elCourse) => elCourse.id != checkedELCourse.id)
        );
      } else {
        setcheckedELState([...checkedELState, checkedELCourse]);
      }
    } else {
      setcheckedELState([checkedELCourse]);
    }
  };
  const onAddSelectedCoursesToPath = () => {
    onELCourseSelect(checkedELState);
    onILCourseSelect(checkedILState);
    handleClose();
  };

  const fetchElCourse = useCallback(() => {
    axios
      //ToDo calling APIs from a separate API file
      .get(`${config.endPoint.elearning}/course`, {
        params: {
          courseStatus: "active",
          country: String(selectedContries.filter((country) => country !== "selectAll")),
          turnOffGlobal: true,
          pageSize: PAGESIZE,
          pageNumber: elPageNumber,
          courseName: searchString || "",
        },
      })
      .then((response) => {
        setCourseList((current) => [...current, ...response.data.data]);
        setElTotalPages(response.data.total || 0);
        if (!hasMoreELCourses) {
          sethasMoreELCourses(true);
        }
        if (response.data.data.length == 0) {
          sethasMoreELCourses(false);
        }
      })
      .catch((error) => {
        //TODO use notifications
      });
  }, [searchString, elPageNumber]);

  const fetchILCourse = useCallback(() => {
    const params = {
      page_number: ilPageNumber,
      page_size: PAGESIZE,
      is_global: true,
      onlyPublicCourses,
      countries: String(selectedContries.filter((country) => country !== "selectAll")),
    };
    if (searchString?.length > 0) {
      params.searchString = searchString;
    }
    axios
      //ToDo calling APIs from a separate API file
      .get(`${config.endPoint.instructorLedNew}/course/list_2`, {
        params,
      })
      .then((response) => {
        setIlCourseList((current) => [...current, ...response.data.data]);
        setIlTotalPages(response.data.count || 0);
        if (!hasMoreIlCourses) {
          setHasMoreIlCourses(true);
        }
        if (response.data.data.length == 0) {
          setHasMoreIlCourses(false);
        }
      })
      .catch((error) => {
        //TODO use notification
      });
  }, [searchString, ilPageNumber]);

  const fetchMoreElCourses = () => {
    setElPageNumber((elPageNumber) => elPageNumber + 1);
  };

  const fetchMoreIlCourses = () => {
    setIlPageNumber((ilPageNumber) => ilPageNumber + 1);
  };

  useEffect(() => {
    fetchElCourse();
  }, [fetchElCourse]);

  useEffect(() => {
    fetchILCourse();
  }, [fetchILCourse]);

  useEffect(() => {
    setcheckedELState(selectedELCourseNos);
    setCheckedILState(selectedILCourseNos);
  }, []);

  useEffect(() => {
    setIlCourseList(() => []);
    setHasMoreIlCourses(() => true);
    setCourseList(() => []);
    sethasMoreELCourses(() => true);
  }, [searchString]);

  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    {
      title: "Learning Paths",
      to: "/admin/learning-path/paths",
    },
    {
      title: `${pathId ? "Edit" : "Add"} Learning Path`,
    },
  ];

  return (
    <div className="sc-container">
      <ScrollToTop></ScrollToTop>
      <Row className={style.modal}>
        {/* breadcrumbs row */}
        <Col span={24}>
          <AntDesignBreadCrumb crumbs={crumbs} />
        </Col>
      </Row>
      <Row gutter={24} className={style.selectCourseHeader}>
        {/* header components row */}
        <Col span={10}>
          <Typography.Title
            style={{ fontFamily: "RakutenRoundRegular", display: "inline" }}
          >
            Select Courses{" "}
            <Typography.Text>
              ({filter == [filterItems[0].value] ? ilTotalPages : elTotalPages}{" "}
              courses)
            </Typography.Text>
          </Typography.Title>
        </Col>
        <Col
          style={{
            marginLeft: "-10rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CourseFilter
            filterItems={filterItems}
            handleFilter={handleFilter}
            filter={filter}
          ></CourseFilter>
        </Col>
        <Col>
          <AntDesignSearchInput
            placeHolder="Search"
            onSearch={handleSearch}
          ></AntDesignSearchInput>
        </Col>
        <Col style={{ width: "13rem" }}>
          <AntDesignPrimaryButton
            text="ADD TO PATH"
            icon={<PlusCircleOutlined />}
            onClick={onAddSelectedCoursesToPath}
            isDisabled={
              checkedELState.length == 0 && checkedILState.length == 0
            }
          ></AntDesignPrimaryButton>
        </Col>
        <Col style={{ paddingLeft: "1rem" }}>
          <Button
            type="button"
            className="sc-cancel-button"
            onClick={handleClose}
          >
            CANCEL
          </Button>
        </Col>
      </Row>
      {filter == filterItems[0].value && (
        <InfiniteScroll
          dataLength={ilCourseList?.length}
          next={fetchMoreIlCourses}
          hasMore={hasMoreIlCourses}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <Row gutter={[24, 40]} style={{ width: "100%" }}>
            {ilCourseList && ilCourseList.length ? (
              ilCourseList.map((current, index) => {
                return (
                  <Col span={6}>
                    <div key={current.id}>
                      <CourseCard
                        dataElement={current}
                        index={index}
                        coursePath={`/course-details/${current.id}`}
                        tileType="learningPath"
                        checkedILState={checkedILState}
                        cardClicked={handelILCardClick}
                        courseType={INSTRUCTOR_LED}
                        key={current.id}
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col>
                No course available in your region for the category selected.
              </Col>
            )}
          </Row>
        </InfiniteScroll>
      )}
      {filter == filterItems[1].value && (
        <InfiniteScroll
          dataLength={courseList?.length}
          next={fetchMoreElCourses}
          hasMore={hasMoreELCourses}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <Row gutter={[24, 40]} style={{ width: "100%" }}>
            {courseList && courseList.length ? (
              courseList.map((current, index) => {
                return (
                  <Col span={6}>
                    <div key={current.id}>
                      <CourseCard
                        dataElement={current}
                        index={index}
                        coursePath={`/e-learning-courses/${current.id}`}
                        tileType="learningPath"
                        checkedELState={checkedELState}
                        cardClicked={handelCardClick}
                        courseType={E_LEARNING}
                        key={current.id}
                      />
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col>
                No course available in your region for the category selected.
              </Col>
            )}
          </Row>
        </InfiniteScroll>
      )}
    </div>
  );
}

export default SelectCourseFromList;

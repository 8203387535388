import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Layout, Modal, Button } from "antd";
import Iframe from "react-iframe";
import { Alert, Quotation, PrimaryButton } from "../../../../component-library";
import { LoadingContext } from "../../../../pages/LoadingContext";
import SCORMAdapter from "../../../../utils/scorm-adapter/scormAdapter";
import SCORM2004Adapter from "../../../../utils/scorm-adapter/scorm2004Adaptor";
import axios from "axios";
import { CloseOutlined } from "@ant-design/icons";
import config from "../../../../config";
import { UserContext } from "../../../../context/UserContext";
import style from "./scormPlayer.scss";

function ScormPlayer(props) {
  const history = useHistory();
  const courseId = props.match.params.courseId;
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseLaunchPage, setCourseLaunchPage] = useState(null);
  const { setPageHeader } = useContext(LoadingContext);
  const { userId } = useContext(UserContext);
  const [isFocused, setIsFocused] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getCourseDetails = () => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}`)
      .then((response) => {
        if (response.data.success) {
          setCourseDetails(response.data.data.course);
          document.title = `${response.data.data.course.name} | LMS`;
        }
      })
      .catch((error) => {
        setAlert("Failed to fetch course details!");
        setAlertType("danger");
      });
  };
  const customParams = history?.location?.state?.learningPath
    ? {
        learningpathId: history?.location?.state?.learningPath,
      }
    : {};
  const launchCourse = () => {
    axios
      .get(`${config.endPoint.elearning}/course/launch/${courseId}`, {
        params: {
          pathId: customParams?.learningpathId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setCourseLaunchPage(
            `${process.env.REACT_APP_ELEARNING_SCORM_URL}${response.data.data}`
          );
        }
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message || "Failed to launch course!");
        setAlertType("danger");
      });
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getUserCourseScormProgress = () => {
    axios
      .get(`${config.endPoint.elearning}/course/${courseId}/user-scrom-get`)
      .then((response) => {
        // If results available, then load into localStorage
        if (
          response.data.success &&
          response.data.data.courseAttemptResult &&
          // eslint-disable-next-line
          response.data.data.courseAttemptResult.courseStatusJson != ""
        ) {
          let scormData = JSON.parse(
            response.data.data.courseAttemptResult.courseStatusJson
          );
          scormData["Initialized"] = true;
          window.localStorage.setItem("scormData", JSON.stringify(scormData));
        }
      })
      .catch((error) => {
        setAlert("Failed to fetch course progress!");
        setAlertType("danger");
      });
  };

  useEffect(() => {
    setPageHeader(false);
    // SET scorm 2004 adapter
    window.API_1484_11 = new SCORM2004Adapter(
      userId,
      courseId,
      customParams?.learningpathId
    );
    // Set SCORM Adapter
    window.API = new SCORMAdapter(
      userId,
      courseId,
      customParams?.learningpathId
    );
    // Get all required information
    getCourseDetails();
    getUserCourseScormProgress();
    launchCourse();
    return () => {
      setPageHeader(true);
    };
    // eslint-disable-next-line
  }, []);

  const backToCourseDetail = () => {
    if (customParams.learningpathId) {
      history.push(`/learning-paths/${customParams.learningpathId}`);
    } else {
      history.push(`/e-learning-courses/${courseId}`);
    }
  };

  return (
    <Layout
      className={"scromPlayer-componentContainer"}
      bsPrefix={`container ${style.componentContainer}`}
    >
      <Alert
        message={alert}
        type={alertType}
        onCloseAlert={() => setAlert("")}
      />
      {courseDetails ? (
        window.innerWidth > 858 ? (
          <div>
            <div
              style={{ margin: "20px", marginLeft: "1rem", marginTop: "-11px" }}
            >
              <div id="goBack" className={"scromPlayer-Back"}>
                <div className={"scromPlayer-BackButton"}>
                  <PrimaryButton
                    label="Go Back"
                    handleClick={backToCourseDetail}
                    otherStyle={"scromPlayer-buttonStyle"}
                  />
                </div>
              </div>
              <div className={"scromPlayer-courseNameWrapper"}>
                <span className={"scromPlayer-title"}>
                  {courseDetails.name}
                </span>
              </div>
            </div>
            <Row>
              <Iframe
                styles={{ marginTop: "-8px" }}
                url={courseLaunchPage}
                width={window.innerWidth}
                height={window.innerHeight - 76}
                id="scormplayer"
              />
            </Row>

            <div>
              <Layout
                className={"scromPlayer-container"}
                bsPrefix={`container ${style.container}`}
              >
                <div className={"scromPlayer-secondContainer"}>
                  <Quotation />
                </div>
              </Layout>
            </div>
          </div>
        ) : (
          <div
            className={`video-player ${isFocused ? "focused" : ""}`}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onTouchStart={handleFocus}
            onClick={handleFocus}
          >
            <div
              style={{
                position: "absolute",
                top: "15%",
                left: "2%",
                padding: "0.3rem",
                background: "#00a0f0",
                border: "1px solid",
                paddingTop: "0",
                paddingBottom: "0rem",
                color: "white",
                fontFamily: "RakutenRoundSemiBold",
              }}
            >
              <CloseOutlined onClick={() => setShowExitModal(true)} />
            </div>
            <Iframe
              url={courseLaunchPage}
              width={windowWidth}
              height={windowHeight}
              id="scormplayer"
              allow="fullscreen"
            />
          </div>
        )
      ) : null}
      {showExitModal && (
        <div>
          <Modal
            title="Exit"
            open={showExitModal}
            onOk={backToCourseDetail}
            onCancel={() => setShowExitModal(false)}
            footer={[
              <Button
                key="back"
                onClick={backToCourseDetail}
                style={{
                  background: "#00a0f0",
                  color: "white",
                  borderColor: "#00a0f0",
                  fontFamily: "RakutenRoundSemiBold",
                }}
              >
                Yes
              </Button>,
              <Button
                key="back"
                onClick={() => setShowExitModal(false)}
                style={{
                  background: "#828282",
                  borderColor: "#828282",
                  color: "white",
                  fontFamily: "RakutenRoundSemiBold",
                }}
              >
                No
              </Button>,
            ]}
          >
            <p>Do you want to exit course player? </p>
          </Modal>
        </div>
      )}
    </Layout>
  );
}

export default ScormPlayer;

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import {
  TopContainer,
  BreadCrumb,
  InlineIconButton,
  TablePublicPrivateFilter,
  AdminPageTitle,
  PublicPrivateLegend,
  AdminPageSubTitle,
  AdminDataTable,
  Alert,
} from "../../../component-library";
import privateIcon from "../../../assets/icons/private_icon.svg";
import { customFilter } from "react-bootstrap-table2-filter";
import countryData from "../../../utils/country_list.json";
import style from "./manageSessionList.module.css";
import TimeFormatter from "../../../utils/timeFormatter";
import { Row, Col } from "react-bootstrap";
import { useParams, useLocation } from "react-router-dom";
import { CourseContext } from "../../../context/CourseContext";

function ManagerSessionList() {
  const { userDateFormat } = useContext(CourseContext);
  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [listData, setListData] = useState([]);
  const { batchId } = useParams();

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const userId = query.get("userId");
  const name = query.get("name");
  const crumbs = [
    {
      title: "Manager Dashboard",
      to: "/Manager",
    },
    { title: "My Team", to: "/Manager/MyTeam" },
    {
      title: "Course Details",
      to: `/Manager/myTeam/CourseDetails/${userId}?firstName=${name}`,
    },
    { title: "Session" },
  ];

  let tableSearch = {
    position: "relative",
    top: "-6rem",
    left: "70rem",
    width: "12.5rem",
    visibility: "hidden",
  };

  let downloadButtonStyle = {
    position: "relative",
    top: "-10rem",
    left: "54rem",
    visibility: "hidden",
  };

  function getCountryImage(countryCode = "NULL") {
    let countryObj = countryData.data.countries[countryCode];
    return countryObj ? countryObj.imageURL : "";
  }

  function getCountryNames(countryCode = "NULL") {
    let countryObj = countryData.data.countries[countryCode];
    return countryObj ? countryObj.name : "";
  }

  const SessionColumns = [
    {
      dataField: "private",
      // text: "Is Private",
      headerAttrs: { title: "Is Private" },
      headerStyle: {
        width: "4rem",
      },
      csvExport: false,
      formatter: (cell, row) => {
        if (row.private) {
          return (
            <InlineIconButton iconURL={privateIcon} alt="private course" />
          );
        }
        return null;
      },
      filter: customFilter(),
      filterRenderer: (onFilter, column) => {
        return <TablePublicPrivateFilter />;
      },
    },
    {
      dataField: "country",
      text: "Country",
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "4rem",
      },
      formatter: (cell, row) => {
        let countryCode = row.country === "NULL" ? "NULL" : row.country;
        if (!row.private) {
          if (row.country.length > 0 && row.country.length < 2) {
            countryCode = row.country[0];
          }
        }
        let iconStyle =
          countryCode === "NULL" ? style.globeIcon : style.countryIcon;
        let countrySrc = getCountryImage(countryCode);
        let countryTitle = getCountryNames(countryCode);
        return (
          <img
            src={`${process.env.PUBLIC_URL}/${countrySrc}`}
            title={countryTitle}
            className={iconStyle}
            alt={countryTitle}
          />
        );
      },
      csvFormatter: (cell, row, rowIndex) => {
        return row.country === "NULL"
          ? "All selected countries of the course"
          : row.country;
      },
    },
    {
      dataField: "id",
      text: "Session ID",
      sort: true,
      headerStyle: {
        width: "10rem",
        paddingLeft: "1.5rem",
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "batch_name",
      text: "Session Name",
      classes: style.wrapText,
      sort: true,
      headerStyle: {
        width: "9rem",
      },
      formatter: (cell, row) => {
        if (row.private) {
          return (
            <span style={{ color: "#F59600" }} title={cell}>
              {cell}
            </span>
          );
        }
        return (
          <span style={{ color: "#00A0F0" }} title={cell}>
            {cell}
          </span>
        );
      },
    },
    {
      dataField: "start_time",
      text: "Session start time",
      sort: true,
      headerStyle: {
        width: "10rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataField: "end_time",
      text: "session end time",
      sort: true,
      headerStyle: {
        width: "10rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataField: "tz_offset",
      text: "Time zone",
      sort: true,
      headerStyle: {
        width: "10rem",
      },
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      formatter: (cell, row) => {
        return (
          <>{TimeFormatter.getTimeZoneByOffset(cell, null, userDateFormat)}</>
        );
      },
    },
  ];

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, []);

  const getSession = () => {
    settableColumns(SessionColumns);
    axios
      .get(`batch/sessions/${batchId}`)
      .then((response) => {
        setListData(response.data.data);
        setPageCount(response.data.data.length);
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setAlertType("danger");
      });
  };

  const [tableColumns, settableColumns] = useState(SessionColumns);

  useEffect(() => {
    settableColumns(SessionColumns);
  }, [userDateFormat]);

  const tableHeader = (
    <>
      <Row>
        <Col lg="2">
          <AdminPageTitle pageTitle="Session" pageCount={pageCount} />
        </Col>
        <Col lg="2">
          <PublicPrivateLegend otherStyle={style.legendStyle} />
        </Col>
      </Row>
      <Row>
        <AdminPageSubTitle otherStyle={style.subTitle} />
      </Row>
    </>
  );

  return (
    <>
      <TopContainer>
        <Alert
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
        />
        <BreadCrumb crumbs={crumbs} />
        <AdminDataTable
          otherElements={tableHeader}
          keyField="id"
          data={listData}
          columns={tableColumns}
          searchBarStyle={tableSearch}
          downloadButtonStyle={downloadButtonStyle}
          paginationHide={true}
        />
      </TopContainer>
    </>
  );
}

export default ManagerSessionList;

import { Breadcrumb, Button, Input, Alert, AutoComplete, Spin } from "antd";
import AddCircle from "../../../assets/icons/plus_circle.svg";
import { SearchOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import CourseImageTagComponent from "./CourseImageTagComponent";
import { uniqBy } from "lodash";
import config from "../../../config";
import { UserContext } from "../../../context/UserContext";

function CourseImageList() {
  const { userConfig } = useContext(UserContext);

  const crumbs = [
    {
      title: (
        <a href="/Admin" style={{ color: userConfig.primary }}>
          Admin Dashboard
        </a>
      ),
    },
    {
      title: "Course Images",
    },
  ];

  const [items, setItems] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [hasMoreImages, setHasMoreImages] = useState(true);
  const [offset, setOffset] = useState(1);
  const [showNotifications, setShowNotifications] = useState(true);
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [availableSearchValues, setAvailableSearchValues] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line
  }, [searchString]);

  useEffect(() => {
    if (showNotifications) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setShowAlert(true);
        setAlertType(location.state.alertType);
        setShowNotifications(false);
      }
    }
    // eslint-disable-next-line
  }, [showNotifications]);

  // After selecting option from search
  const searchOptionSeleted = (e) => {
    const inputValue = e.currentTarget.value;
    setOffset(1);
    setItems([]);
    setHasMoreImages(true);
    setSearchString(inputValue);
  };

  // Common function to fetch images for infinite loader and search
  async function fetchImages() {
    setShowLoader(true);
    // let searchUrl = `${config.endPoint.commonService}/image?excludeTags=false&getSmallThumbnail=false&limit=12&offset=${offset}`;
    let searchUrl = `${config.endPoint.commonService}/image?excludeTags=false&getSmallThumbnail=false&limit=50`;

    if (searchString) {
      searchUrl = searchUrl + "&tags=" + searchString;
    }
    axios
      .get(searchUrl)
      .then((res) => {
        if (res.data && res.data.success) {
          setShowLoader(false);
          setImageCount(res.data.data.count);
          if (res.data.data.length === 0) {
            // setHasMoreImages(false);
            return;
          }
          const imageTags = [];
          const newItems = res.data.data.data.map((imageData) => {
            imageData.tags &&
              imageData.tags.length &&
              imageData.tags.map((tag) => {
                imageTags.push(tag);
              });
            return (
              <CourseImageTagComponent
                imageSrc={imageData.imageLink}
                handleClick={onClickImage}
                otherStyle={imageComponentStyle}
                imgName={imageData.imageName}
                tags={imageData.tags || []}
              />
            );
          });
          setAvailableSearchValues([
            ...new Set([...availableSearchValues, ...imageTags]),
          ]);
          setItems([...new Set([...items, ...newItems])]);
          // setOffset(offset + 1);
        }
      })
      .catch(() => {
        setShowLoader(false);
        setHasMoreImages(false);
      });
  }

  const imageComponentStyle = {
    width: "19.5rem",
    marginRight: "1.275rem",
    marginBottom: "2.5rem",
  };

  // Image edit button
  const onClickImage = (args) => {
    history.push(
      `/Admin/Course/AddEditCourseImage?image_name=${args.target.name}`
    );
  };

  const onAddNewImage = () => {
    history.push("/Admin/Course/AddEditCourseImage");
  };

  const filterOptions = (inputValue) => {
    return availableSearchValues.filter((value) =>
      value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#ffffff",
        marginTop: "5rem",
      }}
    >
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          className={"admin-alert"}
          onCloseAlert={() => setAlert("")}
        />
      )}
      <Breadcrumb className={"courseImageListCrumbs"} items={crumbs} />
      <div className={"pageHeader"}>
        <div className={"courseImageListPageTitle"}>
          Course Images
          {imageCount > 0 && (
            <span className={"pageCount"}>
              ({showLoader ? <Spin /> : imageCount})
            </span>
          )}
        </div>
        <div className={"right"}>
          <AutoComplete
            options={filterOptions(searchString).map((value) => ({ value }))}
            style={{ width: "15rem" }}
          >
            <Input
              onPressEnter={searchOptionSeleted}
              placeholder="Filter by Image Tags"
              suffix={<SearchOutlined />}
              className={"courseImagesSearch"}
            />
          </AutoComplete>
          <div style={{ paddingLeft: "0.3rem" }}>
            <Button
              type="button"
              onClick={onAddNewImage}
              className={"courseList-buttonStyle"}
            >
              <span className={"courseList-iconStyle"}>
                <img src={AddCircle} alt="icon" />
              </span>
              Add Image
            </Button>
          </div>
        </div>
      </div>
      <div className={"imageContainer"}>
        {/* <InfiniteScroll
          dataLength={items.length}
          next={fetchImages}
          hasMore={hasMoreImages}
        >
          {items}
        </InfiniteScroll> */}
        {items.map((item) => item)}
        {/* {!hasMoreImages && (
          <div
            style={{
              fontFamily: "RakutenRoundRegular",
              fontSize: 24,
              margin: "1rem",
              textAlign: "center",
            }}
          >
            <b>
              You've reached the end of the list. All the tags are listed above.
            </b>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default CourseImageList;

import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
import "./AntdCourseAddEdit.scss";
import style from "./addEdit.module.css";
import {
  Form,
  Row,
  Col,
  Image,
  Layout,
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  InputNumber,
  Typography,
  Tag,
  Alert,
  Select as AntdSelect,
  TimePicker,
  Dropdown,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import edit from "../../../assets/icons/edit.svg";
import uploadImage from "../../../assets/icons/upload_image.svg";
import boxLogo from "../../../assets/icons/box_logo.svg";
import archiveIcon from "./../../../assets/icons/courseArchive.svg";
import activeIcon from "./../../../assets/icons/courseActive.svg";
import { ReactComponent as ActiveIcon } from "./../../../assets/icons/courseActive.svg";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Toggle,
  TextEditor,
  CustomToggle,
  CustomMultiSelect,
  ScrollToTop,
  AdminConfirmationModal,
} from "../../../component-library";
import ImageSelectionModal from "../../../component-library/imageSelectionModal/AntdImageSelectionModal.js";
import {
  formatCountryList,
  formattedDataForCustomSelect,
  validateCourseDetails,
} from "./addEdit.lib";
import { modifyImageTags } from "../../../utils/textEditor";
import {
  serializeCategoryById,
  serializeCategoryResponse,
} from "../../../serializer/categorySerializer";
import config from "../../../config";
import dayjs from "dayjs";
import {
  decimalToTime,
  timeToDecimal,
} from "../eLearning/assignedUsers/updateExpectedDateModal/addEditDateUtils";
import { DurationRangePicker } from "./DurationRangePicker";

export default function CourseAddEdit() {
  const history = useHistory();

  const query = new URLSearchParams(history.location.search);
  const courseId = query.get("courseId");

  const format = "HH:mm";

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="common-breadcrumbs-color">
          Admin Dashboard
        </a>
      ),
      to: "/Admin",
    },
    {
      title: (
        <a href="/Admin/Courses" className="common-breadcrumbs-color">
          Instructor-led Courses
        </a>
      ),
      to: "/Admin/Courses",
    },
    {
      title: courseId ? "Edit Course" : "Add Course",
    },
  ];
  const textEditorRef = useRef([]);
  const [courseInitialEditData, setCourseInitialEditData] = useState();

  const [publicPrivate, setPublicPrivate] = useState(null);

  const [country, setCountry] = useState([]);
  const [selectedCounties, setSelectedCountries] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [language, setLanguage] = useState("");
  const [courseType, setCourseType] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [duration, setDuration] = useState(0);
  const [proficiency, setProficiency] = useState(0);
  const [archived, setArchived] = useState(false);
  const [description, setDescription] = useState("");
  const [recommendations, setRecommendations] = useState("");
  const [objectives, setObjectives] = useState("");
  const [targetedAudience, setTargetedAudience] = useState("");
  const [preRequisites, setPreRequisites] = useState("");
  const [topics, setTopics] = useState("");
  const [trainingMaterial, setTrainingMaterial] = useState("");
  const [adminComment, setAdminComment] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [proficiencyList, setProficiencyList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [errorMessage, setErrorMessage] = useState({});
  const [unformattedCountries, setUnformattedList] = useState([]);

  const [alert, setAlert] = useState("");
  const [alertType, setAlertType] = useState("");
  const [courseIdentityId, setCourseIdentityId] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentTags, setCurrentTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);

  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [countryOriginCheck, setCountryOriginCheck] = useState(true);

  const [approvalCheck, setApprovalCheck] = useState(false);
  const [managerApproval, setManagerApproval] = useState(true);
  const [approverDesignation, setApproverDesignation] = useState("Manager");
  const [courseData, setCourseData] = useState([]);
  const [initCategory] = useState([]);
  const [initSubCategory, setInitSubCategory] = useState([]);

  const [initCourseType, setInitCourseType] = useState([]);

  const [initLanguage, setInitLanguage] = useState([]);

  const [courseProviderList, setCourseProviderList] = useState([]);
  const [subCourseProviderList, setSubCourseProviderList] = useState([]);
  const [selectedCourseProvider, setSelectedCourseProvider] = useState(null);
  const [createdCourseProvider, setCreatedCourseProvider] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedSubCourseProvider, setSelectedSubCourseProvider] =
    useState(null);

  let redirect;

  useEffect(() => {
    setCurrentTags(initialTags);
  }, [initialTags]);

  useEffect(() => {
    const endPoint = `${config.endPoint.commonService}/data`;
    axios
      .get(endPoint)
      .then((response) => {
        const {
          data: { data },
        } = response || {};
        const {
          countries,
          proficiency,
          course_types: courseTypes,
          languages,
          courseProviders,
        } = data || {};

        setUnformattedList(countries);

        const formattedCountryList = formatCountryList(countries);
        const formattedLanguageList = formattedDataForCustomSelect(languages);
        setCountryList(formattedCountryList);
        setLanguageList(formattedLanguageList);
        setProficiencyList(formattedDataForCustomSelect(proficiency));
        setCourseTypeList(formattedDataForCustomSelect(courseTypes));

        const list = [];
        courseProviders.map((item) =>
          list.push({ label: item.name ? item.name : item.value, value: item.id })
        );
        setCourseProviderList(list);
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(
          error?.response?.data?.message ||
            "Something went wrong, Please try again."
        );
      });
  }, []);

  useEffect(() => {
    let imageUrl = `${config.endPoint.commonService}/image/imageName/course/${imageName}`;

    if (imageName) {
      axios
        .get(imageUrl)
        .then((response) => {
          const { data } = response || {};
          if (data.data && data.data.imageLink)
            setImageUrl(data.data.imageLink);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, [imageName]);

  useEffect(() => {
    if (selectedCourseProvider) {
      axios
        .get(
          `${config.endPoint.instructorLedNew}/subCourseProvider/?courseProviderId=${selectedCourseProvider.value}`
        )
        .then((response) => {
          const list = [];
          response.data.data.map((item) =>
            list.push({ label: item.name, value: item.id })
          );
          setSubCourseProviderList(list);
          if (
            selectedSubCourseProvider &&
            !list.includes(selectedSubCourseProvider)
          ) {
            setSelectedSubCourseProvider(null);
          }
        })
        .catch((error) => {
          setAlertType("error");
          setShowAlert(true);
          setAlert(
            error?.response?.data?.message ||
              "Something went wrong, Please try again."
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourseProvider]);

  const setInitialEditState = async (data) => {
    const result = data;
    const {
      countries = [],
      identity_id,
      duration = 0,
      courseProvider = null,
      subCourseProvider = null,
      course_name = "",
      description = "",
      prerequisites = "",
      topics = "",
      admin_comments = "",
      objectives = "",
      targeted_audience = "",
      training_material = "",
      category = [],
      subcategory = [],
      course_type = null,
      proficiency = 0,
      language = "",
      private: privateCourse = false,
      archived = false,
      search_tags = [],
      image = null,
      recommendations = "",
      country_origin_check = true,
      approval_required = false,
      approver = "",
    } = result || {};
    const modifiedData = await modifyImageTags({
      description: description,
      targeted_audience: targeted_audience,
      objectives: objectives,
      prerequisites: prerequisites,
      recommendations: recommendations,
      topics: topics,
      admin_comments: admin_comments,
    });
    setCourseInitialEditData({ ...data, ...modifiedData });

    const countryArray = (result && result.countries) || [];
    if (unformattedCountries && unformattedCountries.length) {
      const formattedSelectedCountries = countryArray
        .map((current) => {
          return unformattedCountries.find((curr) => curr.code === current);
        })
        .map((curr) => {
          return { id: curr.code, value: curr.country };
        });
      setSelectedCountries(formattedSelectedCountries);
      setCountry(countries);
    }
    setCourseIdentityId(identity_id);
    setDuration(duration);
    setSelectedCourseProvider(
      courseProvider
        ? { label: courseProvider.name, value: courseProvider.id }
        : null
    );
    setSelectedSubCourseProvider(
      subCourseProvider
        ? { label: subCourseProvider.name, value: subCourseProvider.id }
        : null
    );
    setCourseTitle(course_name);
    setDescription(modifiedData?.description || "");
    setPreRequisites(modifiedData?.prerequisites || "");
    setTopics(modifiedData?.topics || "");
    setAdminComment(modifiedData?.admin_comments || "");
    setRecommendations(modifiedData?.recommendations || "");
    setObjectives(modifiedData?.objectives || "");
    setTargetedAudience(modifiedData?.targeted_audience || "");
    setTrainingMaterial(training_material || "");
    setCountryOriginCheck(country_origin_check);
    setApprovalCheck(approval_required);

    // setInitialTags(
    //   search_tags &&
    //     search_tags.map((current, i) => {
    //       return { name: current };
    //     })
    // );

    const formattedSearchTags = search_tags.map((current, i) => {
      return { value: current, label: current };
    });

    setInitialTags(formattedSearchTags);
    category &&
      setCategory({
        label: category.category_name,
        value: category.id,
      });
    setPublicPrivate(privateCourse);
    setArchived(archived);

    if (image) setImageUrl(image);
    setInitLanguage(language);
    setInitCourseType(course_type);
    // setCourseType(course_type);

    subcategory &&
      setSubCategory({
        label: subcategory.sub_category_name,
        value: subcategory.id,
      });

    setProficiency(() => {
      return (
        proficiencyList &&
        proficiencyList.length &&
        proficiencyList.find((current) => {
          return current.label === proficiency;
        })
      );
    });

    setApproverDesignation(() =>
      approver === "Approver.Manager" || approver === null
        ? setApproverDesignation(() => {
            setManagerApproval(true);
            return "Manager";
          })
        : setApproverDesignation(() => {
            setManagerApproval(false);
            return "DepartmentHead";
          })
    );
  };

  useEffect(() => {
    if (courseId) {
      const endPoint = `${config.endPoint.instructorLedNew}/course/${courseId}?edit=true`;

      axios
        .get(endPoint)
        .then((response) => {
          if (!response.data.success) {
            setAlertType("error");
            setShowAlert(true);
            setAlert(response.data.message);
            const timer = setTimeout(() => {
              history.push("/Admin/Courses");
            }, 10000);

            return () => clearTimeout(timer);
          }
          const {
            data: { data },
          } = response;

          setCourseData(data);
        })
        .catch((error) => {
          setAlert(
            (error && error.response && error.response.message) ||
              "Something went wrong."
          );
          setShowAlert(true);
          setAlertType("error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  useEffect(() => {
    setCourseType(() => {
      return (
        courseTypeList &&
        courseTypeList.length &&
        courseTypeList.find((current) => {
          return current.label === initCourseType;
        })
      );
    });

    setLanguage(
      () =>
        languageList &&
        languageList.length &&
        languageList.find((current) => {
          return current.label === initLanguage;
        })
    );
  }, [
    initCategory,
    initSubCategory,
    initLanguage,
    initCourseType,
    courseTypeList,
    languageList,
  ]);

  function formatCategoryList(data) {
    return data.map((current) => {
      return { label: current.name, value: current.id };
    });
  }

  function formatSubCategoryList(subCategories) {
    return subCategories.map((current) => {
      return { label: current.name, value: current.id };
    });
  }

  useEffect(() => {
    const catergoryEndpoint = `${config.endPoint.commonService}/category?exclude_subcategories=true`;
    axios.get(catergoryEndpoint).then((response) => {
      const formattedCategoryList = formatCategoryList(
        serializeCategoryResponse(response)
      );
      setCategoryList(formattedCategoryList);
    });
  }, []);

  useEffect(() => {
    if (category && category.value) {
      const catergoryEndpoint = `${config.endPoint.commonService}/category/${category.value}`;

      const endPoint = `/category/${category.value}`;
      axios.get(catergoryEndpoint).then((response) => {
        const {
          data: { data },
        } = response;
        const { subcategories: subCategories } =
          serializeCategoryById(response);
        const formattedSubCategoryList = formatSubCategoryList(subCategories);
        setSubCategoryList(formattedSubCategoryList);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.value]);

  useEffect(() => {
    if (courseData && Object.keys(courseData).length) {
      setInitialEditState(courseData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseData, unformattedCountries]);

  useEffect(() => {
    return clearTimeout(redirect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async (e) => {
    window.scrollTo(0, 0);
    e.preventDefault();

    const obj = {
      private: publicPrivate || false,
      archived: archived,
      category_id: category.value,
      sub_category_id: subCategory.value,
      country: country.filter((current) => current !== "*"),
      course_provider_id: selectedCourseProvider?.value,
      sub_course_provider_id: selectedSubCourseProvider?.value,
      language: language ? language.value : "",
      course_type: courseType ? courseType.value : "",
      name: courseTitle,
      duration: duration,
      proficiency: (proficiency && proficiency.value) || 0,
      description,
      objectives,
      targeted_audience: targetedAudience,
      prerequisites: preRequisites,
      topics,
      training_material: trainingMaterial,
      admin_comments: adminComment,
      search_tags: initialTags.map((current) => current.value),
      recommendations,
      country_origin_check: countryOriginCheck,
      approval_required: approvalCheck,
      approver: approverDesignation,
    };
    if (imageName) {
      obj.image_url = imageName;
    }

    if (courseId) {
      obj.id = +courseId;

      const errors = validateCourseDetails(obj);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }

      const endPoint = `/course`;
      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );

        axios
          .put(endPoint, obj)
          .then((response) => {
            history.push({
              pathname: "/Admin/Courses/",
              state: {
                alertType: "success",
                alertMessage: response.data.message,
                showAlert: true,
              },
            });
          })
          .catch((error) => {
            setAlert(
              (error &&
                error.response &&
                error.response.data &&
                error.response.data.message) ||
                "Something went wrong."
            );
            setShowAlert(true);
            setAlertType("error");
          });
      }
    } else {
      obj.image_url = imageName;
      const errors = validateCourseDetails(obj);
      if (errors) {
        setErrorMessage({ ...errorMessage, ...errors });
      }
      const endPoint = `${config.endPoint.instructorLedNew}/course`;
      if (!errors || Object.keys(errors).length === 0) {
        await Promise.all(
          textEditorRef.current.map(
            async (ref) => await ref.deleteOrphanedImages()
          )
        );
        axios
          .post(endPoint, obj)
          .then((response) => {
            history.push({
              pathname: "/Admin/Courses/",
              state: {
                alertType: "success",
                alertMessage: response.data.message,
              },
            });
          })
          .catch((error) => {
            setAlert(error?.response?.data?.message || "Something went wrong.");
            setShowAlert(true);
            setAlertType("error");
          });
      }
    }
  };

  const toggle = (value) => {
    setPublicPrivate(value);
  };

  const handleCountry = (countries) => {
    const countryArray = countries.map((current) => current.id);
    setCountry(countryArray);
    if (!countryArray || countryArray.length === 0) {
      const error = { country: `Please select atleast one Country.` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { country: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCourseTitle = (courseTitle) => {
    setCourseTitle(courseTitle);
    if (!courseTitle) {
      const error = { courseTitle: `Please enter Course Title` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (courseTitle.trim() === "") {
      const error = { courseTitle: "Title cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseTitle: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategory = (category) => {
    setCategory(category);
    setSubCategory([]);
    if (!category) {
      const error = { category: `Please select the Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { category: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCourseType = (courseType) => {
    setCourseType(courseType);
    setInitCourseType(courseType.label);
    if (!courseType) {
      const error = { courseType: `Please select the Course Type` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseType: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleLanguage = (language) => {
    setLanguage(language);
    setInitLanguage(language.label);
    if (!language) {
      const error = { language: `Please select the Language` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { language: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleDuration = (duration) => {
    setDuration(timeToDecimal(duration));
    if (!duration || duration == 0) {
      const error = { duration: `Please select the duration` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { duration: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleSubCategory = (subCategory) => {
    setSubCategory(subCategory);
    setInitSubCategory(subCategory.label);
    if (!subCategory) {
      const error = { subCategory: `Please select the Sub Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subCategory: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCourseProvider = (courseProvider) => {
    setSelectedCourseProvider(courseProvider);
    if (!courseProvider) {
      const error = {
        courseProvider: `Please enter the Course Provider`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { courseProvider: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleSubCourseProvider = (courseProvider) => {
    setSelectedSubCourseProvider(courseProvider);
    if (!courseProvider) {
      const error = {
        subCourseProvider: `Please enter the Sub Course Provider`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { subCourseProvider: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCreateCourseProvider = (courseProviderName) => {
    setCreatedCourseProvider(courseProviderName);
    setShowConfirmationModal(true);
  };

  const createSubCourseProvider = () => {
    const dataEndpoint = `${config.endPoint.commonService}/subCourseProvider`;
    axios
      .post(dataEndpoint, {
        name: createdCourseProvider,
        courseProviderId: selectedCourseProvider.value,
      })
      .then((response) => {
        setSubCourseProviderList([
          ...subCourseProviderList,
          { label: createdCourseProvider, value: response.data.data.id },
        ]);
        setSelectedSubCourseProvider({
          label: createdCourseProvider,
          value: response.data.data.id,
        });
      })
      .catch((error) => {
        setAlert(
          error?.response?.message ||
            error?.response?.data?.message ||
            "Something went wrong."
        );
        setShowAlert(true);
        setAlertType("error");
      })
      .finally(() => {
        setCreatedCourseProvider("");
        setShowConfirmationModal(false);
      });
  };

  const handleDescription = (description) => {
    setDescription(description);
    if (!description) {
      const error = { description: `Please enter the Course Description` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (description.trim() === "") {
      const error = { description: "Course Description cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { description: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleProficiency = (proficiency) => {
    setProficiency(proficiency);
    if (!proficiency && courseType.value === 1) {
      const error = { proficiency: `Please select the Proficiency` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { proficiency: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleObjectives = (objective) => {
    setObjectives(objective);
    if (!objective) {
      const error = { objective: `Please enter the Course Objectives` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (objective.trim() === "") {
      const error = { objective: "objective cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { objective: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleTargetedAudience = (targetedAudience) => {
    setTargetedAudience(targetedAudience);
    if (!targetedAudience) {
      const error = {
        targetedAudience: `Please enter the Course targetedAudience`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (targetedAudience.trim() === "") {
      const error = { targetedAudience: "targetedAudience cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { targetedAudience: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handlePreRequisites = (preRequisites) => {
    setPreRequisites(preRequisites);
    if (!preRequisites) {
      const error = {
        preRequisites: `Please enter the Course preRequisites`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (preRequisites.trim() === "") {
      const error = { preRequisites: "preRequisites cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { preRequisites: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleTopics = (topics) => {
    setTopics(topics);
    if (!topics) {
      const error = {
        topics: `Please enter the Course topics`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { topics: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleRecommendation = (recommendations) => {
    setRecommendations(recommendations);
    if (!recommendations) {
      const error = {
        recommendations: `Please enter the Recommended Course`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (recommendations.trim() === "") {
      const error = { recommendations: "recommendations cannot be empty" };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      const error = { recommendations: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const resetForm = () => {
    setInitialEditState(courseInitialEditData);
  };

  const handleManagerApproval = () => {
    setApproverDesignation("Manager");
    setManagerApproval(!managerApproval);
  };

  const handleDepartmentHeadApproval = () => {
    setApproverDesignation("DepartmentHead");
    setManagerApproval(!managerApproval);
  };
  const deleteInitialTags = (tagName) => {
    const updatedTags = initialTags.filter((tag) => tag.name !== tagName);
    setInitialTags(updatedTags);
  };

  let disableProficiency = courseType && courseType.value === 1 ? false : true;

  if (archived) disableProficiency = true;

  const modalContent = (
    <>
      <Row>
        <Col>
          <p>
            Are you sure you want to create sub course provider{" "}
            <b>{createdCourseProvider}</b> under course provider{" "}
            <b>{selectedCourseProvider?.label}</b>?
          </p>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <div className="AntdCourseAddEdit-container">
        <ScrollToTop />
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
            onCloseAlert={() => setAlert("")}
          />
        )}
        <Breadcrumb items={crumbs} className={"courseAddEdit-crumbs"} />
        <Form onSubmit={submitHandler}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title className="AntdCreateAddEdit-page-title">
              {courseId ? `Edit Course - ${courseIdentityId}` : "Create Course"}
            </Typography.Title>
            <Row>
              {(courseId && (
                <CustomToggle
                  toggleTitle={["Course Active", "Course Archived"]}
                  value={!archived}
                  icons={[activeIcon, archiveIcon]}
                  onChange={(value) => {
                    setArchived((value) => !value);
                  }}
                />
              )) ||
                ""}
              <Col>
                {!courseId && ( //for course create page
                  <Toggle
                    firstLabelName="PUBLIC"
                    secondLabelName="PRIVATE"
                    selected={publicPrivate}
                    toggle={toggle}
                  />
                )}
                {courseId &&
                  publicPrivate !== null && ( //for course edit page
                    <Toggle
                      firstLabelName="PUBLIC"
                      secondLabelName="PRIVATE"
                      selected={publicPrivate}
                      toggle={toggle}
                    />
                  )}
              </Col>
            </Row>
          </div>

          <Row fluid="true" gutter={20}>
            <Col lg={8}>
              <Col>
                <Typography className={"courseAddEdit-label"}>
                  Country
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <CustomMultiSelect
                  options={countryList}
                  handleCustomChange={handleCountry}
                  selected={selectedCounties}
                  disable={archived}
                />
                {(errorMessage.country && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.country}
                  </Row>
                )) ||
                  ""}
              </Col>
              <Col>
                <Typography className={"courseAddEdit-label"}>
                  Course Title
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <Input
                  placeholder="Enter the name of the course"
                  pattern="^\S.*$"
                  value={courseTitle}
                  disabled={archived}
                  onChange={(e) => {
                    handleCourseTitle(e.target.value);
                  }}
                  className={"courseAddEdit-input"}
                />
                {(errorMessage.courseTitle && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.courseTitle}
                  </Row>
                )) ||
                  ""}
              </Col>
              <Col style={{ zIndex: 800 }}>
                <Typography className={"courseAddEdit-label"}>
                  Category
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <Select
                  isDisabled={!!courseId}
                  options={categoryList}
                  classNamePrefix={`innerclass`}
                  value={category}
                  onChange={(value) => handleCategory(value)}
                />
                {(errorMessage.category && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.category}
                  </Row>
                )) ||
                  ""}
              </Col>
            </Col>

            <Col lg={8}>
              <Col style={{ zIndex: 818 }}>
                <Typography className={"courseAddEdit-label"}>
                  Course Type
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <Select
                  isDisabled={archived}
                  options={courseTypeList}
                  classNamePrefix={`innerclass`}
                  value={initCourseType ? courseType : []}
                  onChange={(value) => handleCourseType(value)}
                />
                {(errorMessage.courseType && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.courseType}
                  </Row>
                )) ||
                  ""}
              </Col>
              <Col style={{ zIndex: 815 }}>
                <Typography className={"courseAddEdit-label"}>
                  Course Language
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <Select
                  isDisabled={archived}
                  options={languageList}
                  classNamePrefix={`innerclass`}
                  value={initLanguage ? language : []}
                  onChange={(language) => handleLanguage(language)}
                />
                {(errorMessage.language && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.language}
                  </Row>
                )) ||
                  ""}
              </Col>
              <Col style={{ zIndex: 810 }}>
                <Typography className={"courseAddEdit-label"}>
                  Sub Category
                  <span className={"courseAddEdit-required"}>*</span>
                </Typography>
                <Select
                  isDisabled={archived}
                  options={subCategoryList}
                  classNamePrefix={`innerclass`}
                  value={subCategory}
                  onChange={handleSubCategory}
                />
                {(errorMessage.subCategory && (
                  <Row
                    className={"courseAddEdit-validation"}
                    style={{ marginTop: "-2rem" }}
                  >
                    {errorMessage.subCategory}
                  </Row>
                )) ||
                  ""}
              </Col>
            </Col>

            <Col lg={8}>
              {(imageUrl && (
                <>
                  {(!archived && (
                    <span
                      className={style.editButton}
                      onClick={() => setShowImageModal(true)}
                    >
                      <img
                        src={edit}
                        alt=""
                        style={{
                          height: "1.2rem",
                          marginLeft: "0.25rem",
                        }}
                      />
                    </span>
                  )) ||
                    ""}
                  <img className={style.image} src={imageUrl} />
                </>
              )) || (
                <Col style={{ paddingRight: "0px" }}>
                  <Col
                    className={"courseAddEdit-biggerSvgBox"}
                    onClick={() => setShowImageModal(true)}
                  >
                    <img
                      src={uploadImage}
                      alt=""
                      className={"courseAddEdit-biggerSvg"}
                    />
                    <p className={style.imageText}>
                      Click here to choose a course image
                    </p>
                  </Col>
                </Col>
              )}

              {showImageModal && (
                <ImageSelectionModal
                  showModal={showImageModal}
                  handleClose={() => setShowImageModal(false)}
                  fetchImageName={(imageName) => {
                    setImageName(imageName);
                    setShowImageModal(false);
                  }}
                />
              )}
            </Col>
          </Row>

          <Row fluid="true" gutter={20} style={{ margin: 0 }}>
            <Col lg={8} style={{ zIndex: 700 }}>
              <Typography className={"courseAddEdit-label"}>
                Course Provider
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <Select
                isDisabled={(courseId && archived) || false}
                options={courseProviderList}
                classNamePrefix={`innerclass`}
                value={selectedCourseProvider}
                onChange={(e) => handleCourseProvider(e)}
              />
              {(errorMessage.courseProvider && (
                <Row
                  className={"courseAddEdit-validation"}
                  style={{ marginTop: "-2rem" }}
                >
                  {errorMessage.courseProvider}
                </Row>
              )) ||
                ""}
            </Col>
            <Col lg={8} style={{ zIndex: 800 }}>
              <Typography className={"courseAddEdit-label"}>
                Sub Course Provider
              </Typography>
              <CreatableSelect
                isDisabled={!selectedCourseProvider}
                options={subCourseProviderList}
                classNamePrefix={`innerclass`}
                value={selectedSubCourseProvider}
                onChange={(value) => handleSubCourseProvider(value)}
                onCreateOption={(value) => handleCreateCourseProvider(value)}
              />
            </Col>
            <Col lg={8} style={{ zIndex: 800 }}>
              <Typography className={"courseAddEdit-label"}>
                Course Duration (HH:MM)
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <DurationRangePicker
                onChange={(duration) => {
                  handleDuration(duration);
                }}
                showSelectedDuration={true}
                defaultDuration={decimalToTime(duration)}
              />
              {(errorMessage.duration && (
                <Row
                  className={"courseAddEdit-validation"}
                  style={{ marginTop: "1rem" }}
                >
                  {errorMessage.duration}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row fluid="true" style={{ margin: 0 }}>
            <Col
              lg={8}
              style={{ margin: 0, zIndex: 600, paddingLeft: "0.7rem" }}
            >
              <Typography className={"courseAddEdit-label"}>
                Proficiency
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <Select
                isDisabled={disableProficiency}
                options={proficiencyList}
                classNamePrefix={`innerclass`}
                value={proficiency || proficiencyList[0]}
                onChange={(value) => {
                  handleProficiency(value);
                }}
              />
              {(errorMessage.proficiency && (
                <Row
                  className={"courseAddEdit-validation"}
                  style={{ marginTop: "-2rem" }}
                >
                  {errorMessage.proficiency}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            fluid="true"
            gutter={20}
            style={{ margin: 0, marginBottom: "4.375rem" }}
          >
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Course Description
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                readOnly={archived}
                value={description}
                getText={(text) => {
                  handleDescription(text);
                }}
                ref={(el) => (textEditorRef.current[0] = el)}
              />
              {(errorMessage.description && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.description}
                </Row>
              )) ||
                ""}
            </Col>
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Course Objectives
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                readOnly={archived}
                value={objectives}
                getText={(text) => {
                  handleObjectives(text);
                }}
                ref={(el) => (textEditorRef.current[1] = el)}
              />
              {(errorMessage.objective && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.objective}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            fluid="true"
            gutter={20}
            style={{
              margin: 0,
              marginBottom: "4.375rem",
              marginTop:
                (errorMessage && errorMessage.description && "-4.375rem") ||
                (errorMessage && errorMessage.objective && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Targeted Audience
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                value={targetedAudience}
                readOnly={archived}
                getText={(text) => {
                  handleTargetedAudience(text);
                }}
                ref={(el) => (textEditorRef.current[2] = el)}
              />
              {(errorMessage.targetedAudience && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.targetedAudience}
                </Row>
              )) ||
                ""}
            </Col>
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Prerequisites
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                value={preRequisites}
                readOnly={archived}
                getText={(text) => {
                  handlePreRequisites(text);
                }}
                ref={(el) => (textEditorRef.current[3] = el)}
              />
              {(errorMessage.preRequisites && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.preRequisites}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            fluid="true"
            gutter={20}
            style={{
              margin: 0,
              marginBottom: "4.375rem",
              marginTop:
                (errorMessage &&
                  errorMessage.targetedAudience &&
                  "-4.375rem") ||
                (errorMessage && errorMessage.preRequisites && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Recommended Course
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                pattern="^\S.*$"
                value={recommendations}
                readOnly={archived}
                getText={(text) => {
                  handleRecommendation(text);
                }}
                ref={(el) => (textEditorRef.current[4] = el)}
              />
              {(errorMessage.recommendations && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.recommendations}
                </Row>
              )) ||
                ""}
            </Col>
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Course Topics
                <span className={"courseAddEdit-required"}>*</span>
              </Typography>
              <TextEditor
                value={topics}
                readOnly={archived}
                getText={(text) => {
                  handleTopics(text);
                }}
                ref={(el) => (textEditorRef.current[5] = el)}
              />
              {(errorMessage.topics && (
                <Row className={"courseAddEdit-validation"}>
                  {errorMessage.topics}
                </Row>
              )) ||
                ""}
            </Col>
          </Row>
          <Row
            fluid="true"
            gutter={20}
            style={{
              margin: 0,
              marginBottom: "2rem",
              marginTop:
                (errorMessage && errorMessage.recommendations && "-4.375rem") ||
                (errorMessage && errorMessage.topics && "-4.375rem") ||
                "0",
            }}
          >
            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Course Materials
              </Typography>
              <Layout
                style={{
                  paddingTop: "2.4rem",
                  paddingLeft: "7rem",
                  backgroundColor: "#EBEBEB",
                  borderRadius: "0.375rem",
                  height: "10.8rem",
                }}
              >
                <Row>
                  <Col lg={6} style={{ marginTop: "0rem" }}>
                    <img className={style.box_image} src={boxLogo} />
                  </Col>
                  <Col>
                    <Col>
                      <Typography className={"courseAddEdit-label"}>
                        Enter the course material URL link
                      </Typography>
                    </Col>
                    <Input
                      value={trainingMaterial}
                      disabled={archived}
                      onChange={(e) => {
                        setTrainingMaterial(e.target.value);
                      }}
                      className={"courseAddEdit-input"}
                    />
                  </Col>
                </Row>
              </Layout>
            </Col>

            <Col lg={12}>
              <Typography className={"courseAddEdit-label"}>
                Search Tags
              </Typography>
              <AntdSelect
                mode="tags"
                style={{
                  width: "100%",
                }}
                placeholder="Add Search Tags"
                onChange={(tags) => {
                  const formattedTags = tags.map((tag) => {
                    return {
                      value: tag,
                      label: tag,
                    };
                  });
                  setInitialTags(formattedTags);
                }}
                value={initialTags}
                options={initialTags}
                autoFocus={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <Typography className={"courseAddEdit-label"}>
                Admin Notes
              </Typography>
              <TextEditor
                customClass={style.adminNotes}
                value={adminComment}
                readOnly={archived}
                getText={(text) => {
                  setAdminComment(text);
                }}
                ref={(el) => (textEditorRef.current[6] = el)}
              />
            </Col>
          </Row>
          {(courseType && courseType.value === 1 && (
            <Row style={{ marginTop: "3rem" }}>
              <Col>
                <Typography className={"courseAddEdit-label"}>
                  Course Enrollment Settings
                </Typography>
                <Row style={{ marginTop: "1.688rem" }}>
                  <Col>
                    <Checkbox
                      disabled={archived}
                      defaultValue={approvalCheck}
                      className={"courseAddEdit-approvalCheckBox"}
                      onChange={(e) => setApprovalCheck(e.target.checked)}
                      checked={approvalCheck}
                    >
                      <span className={"courseAddEdit-approvalTitle"}>
                        ENROLLMENT APPROVAL BY
                      </span>
                    </Checkbox>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                  {approvalCheck && (
                    <Row>
                      <span className={style.courseOriginTitle}>
                        Manager Approval
                      </span>
                      <span style={{ marginLeft: "1rem" }}>
                        <Switch
                          onChange={handleManagerApproval}
                          disabled={archived}
                          checked={managerApproval}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor={"#38A0F0"}
                          offColor={"#9C9C9C"}
                          width={46}
                          height={25.75}
                        />
                      </span>
                      {/* TODO Need to check the Department Head  */}
                      {/* <span
                        className={style.courseOriginTitle}
                        style={{ marginLeft: "5rem" }}
                      >
                        Department Head Approval
                      </span>
                      <span style={{ marginLeft: "1rem" }}>
                        <Switch
                          onChange={handleDepartmentHeadApproval}
                          disabled={archived}
                          checked={!managerApproval}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor={"#38A0F0"}
                          offColor={"#9C9C9C"}
                          width={46}
                          height={25.75}
                        />
                      </span> */}
                    </Row>
                  )}
                </Row>
                <Row style={{ marginTop: "1.688rem" }}>
                  <Col
                    lg={8}
                    className={"courseAddEdit-courseOriginTitleOuterBox"}
                  >
                    <span className={"courseAddEdit-courseOriginTitle"}>
                      Course origin country check
                    </span>
                  </Col>
                  <Col>
                    <span>
                      <Switch
                        onChange={() => setCountryOriginCheck((prev) => !prev)}
                        disabled={archived}
                        checked={countryOriginCheck}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor={"#38A0F0"}
                        offColor={"#9C9C9C"}
                        width={46}
                        height={25.75}
                      />
                    </span>
                  </Col>
                </Row>
                <Row fluid="true">
                  <Col className={style.countryOriginCheckNote}>
                    <div>
                      On: Only course origin country users will be added to the
                      enrolled list, other users added to the waitlist.
                    </div>
                    <div>
                      Off: All checked countries’ users will be added to the
                      enrolled list, other users added to the waitlist.
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )) ||
            ""}

          <Row gutter={20} style={{ marginTop: "3rem" }}>
            <Col>
              <Button
                type="button"
                className={"courseAddEdit-resetButton"}
                onClick={resetForm}
              >
                Reset
              </Button>
            </Col>
            <Col>
              <Button
                type="button"
                className={
                  "courseAddEdit-updateButton common-button-background-color"
                }
                onClick={submitHandler}
              >
                {(courseId && "Update") || "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      {showConfirmationModal ? (
        <AdminConfirmationModal
          toggle={true}
          title="Create "
          titleBold="Course Provider"
          show={showConfirmationModal}
          leftButton="YES"
          rightButton="NO"
          handleClose={() => setShowConfirmationModal(false)}
          rightButtonClick={() => setShowConfirmationModal(false)}
          leftButtonClick={createSubCourseProvider}
          modalContent={modalContent}
        />
      ) : (
        <></>
      )}
    </>
  );
}

const formatChildren = (countries = []) => {
  return countries.map((country) => {
    return {
      value: country.id,
      title: country.value,
    };
  });
};

const formatCountryList = (countryList) => {
  const formattedCountryList = [];
  countryList.forEach((countryGroup) => {
    const formattedCountryObject = {
      value: countryGroup.groupId,
      title: countryGroup.title,
      children: formatChildren(countryGroup?.options),
    };
    formattedCountryList.push(formattedCountryObject);
  });

  return formattedCountryList;
};
export default formatCountryList;

import React from "react";

import * as style from "./learningPathsList.module.css";

const LearningPathInfoCard = ({ text, count, color }) => {
  return (
    <div className={style.infoCard} style={{ color: color }}>
      <div className={style.infoCardTitle}>{text}</div>
      <div className={style.infoCardData}>{count}</div>
    </div>
  );
};

export default LearningPathInfoCard;

const completedIcon = ({ color = "black", width = 19, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3929 9.20143C17.3537 8.15158 17.108 7.11974 16.6701 6.16482C16.2321 5.2099 15.6103 4.35059 14.8402 3.63596C14.0702 2.92134 13.1669 2.36539 12.1819 1.99985C11.197 1.63432 10.1497 1.46636 9.09986 1.50557C8.05002 1.54478 7.01818 1.79039 6.06326 2.22837C5.10833 2.66635 4.24903 3.28813 3.5344 4.05821C2.81978 4.82829 2.26382 5.73158 1.89829 6.71651C1.53276 7.70144 1.3648 8.74873 1.40401 9.79857C1.44322 10.8484 1.68883 11.8803 2.12681 12.8352C2.56479 13.7901 3.18657 14.6494 3.95665 15.364C4.72672 16.0787 5.63002 16.6346 6.61495 17.0001C7.59988 17.3657 8.64717 17.5336 9.69701 17.4944C10.7469 17.4552 11.7787 17.2096 12.7336 16.7716C13.6885 16.3336 14.5478 15.7119 15.2625 14.9418C15.9771 14.1717 16.5331 13.2684 16.8986 12.2835C17.2641 11.2986 17.4321 10.2513 17.3929 9.20143L17.3929 9.20143Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.2362 11.4838C8.09548 11.4838 7.96017 11.4282 7.85424 11.3269C7.32536 10.8181 6.79958 10.2861 6.29158 9.77041L6.13849 9.61499C6.04416 9.51911 5.99776 9.39153 6.00008 9.23612C6.00859 8.97091 6.11916 8.79926 6.34803 8.69951C6.43849 8.66008 6.52586 8.63997 6.60782 8.63997C6.74855 8.63997 6.88154 8.69951 7.00371 8.81627C7.3501 9.14797 7.65475 9.45339 7.9362 9.74953C7.99805 9.81448 8.09548 9.90108 8.23465 9.90108C8.38543 9.90108 8.48749 9.79747 8.53698 9.74798C9.45864 8.81859 10.2922 7.98429 11.0847 7.19871C11.2138 7.07036 11.377 7 11.544 7C11.8007 7 12.0156 7.16392 12.0914 7.41753C12.1587 7.64254 12.1138 7.83584 11.9592 7.99125C10.903 9.05364 9.7687 10.191 8.63208 11.316C8.52306 11.4235 8.38234 11.4831 8.2362 11.4831V11.4838Z"
      fill={color}
    />
  </svg>
);

export default completedIcon;

import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import "./primaryFilledOutlineButton.scss";

function PrimaryFilledOutlineButton({
  label,
  handleClick,
  icon,
  otherStyle,
  isDisabled,
  iconAsImage = true,
  opacity,
}) {
  return (
    <>
      {/* disabled property when added is always true. Hence it has to be conditionally added to the button */}
      {isDisabled && (
        <Button
          type="button"
          bsPrefix={`${"primaryFilledOutlineButton"} ${otherStyle}`}
          onClick={handleClick}
          disabled
          style={{ backgroundColor: opacity ? "none" : "" }}
        >
          {icon && (
            <>
              <span className={"iconStyle"}>
                {iconAsImage ? <img src={icon} alt="icon" /> : icon}
              </span>
            </>
          )}{" "}
          {label}
        </Button>
      )}{" "}
      {!isDisabled && (
        <Button
          bsPrefix={`${"primaryFilledOutlineButtonButton"} ${otherStyle}`}
          type="button"
          onClick={handleClick}
          className={"primaryFilledOutlineButtonButton"}
          style={{ background: opacity ? "none" : "" }}
        >
          {label}{" "}
          {icon && (
            <>
              <span className={"primaryFilledOutlineiconStyle"}>
                {iconAsImage ? <img src={icon} alt="icon" /> : icon}
              </span>
            </>
          )}
        </Button>
      )}
    </>
  );
}

PrimaryFilledOutlineButton.propTypes = {
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  icon: PropTypes.string,
  otherStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default PrimaryFilledOutlineButton;

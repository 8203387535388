import { Button, Col, Form, Modal, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import TimeFormaters from "../../../../utils/timeFormatter";
import config from "../../../../config";
import axios from "axios";
import UtilityFunctions from "../../../../utils/utility";
import { CSVLink } from "react-csv";
import {
  DateTimePicker,
  FileUploader,
  Label,
  ManualInputEmailBox,
  Validation,
} from "../../../../component-library";
import style from "./assignUserModal.module.css";
import { UserContext } from "../../../../context/UserContext";

function AntdAssignUserModal(props) {
  const {
    showAssignUserModal,
    courseId,
    setShowAlert,
    setAlert,
    setAlertType,
    callbackCompletedSession,
    handleCloseAssignUserModal,
  } = props;

  const [fileWithDate, setFileWithDate] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [bulkUploadFile, setBulkUploadFile] = useState("");
  const [textBulkUploadFile, setTextBulkUploadFile] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [emailIds, setEmailIds] = useState(null);
  const { userConfig } = useContext(UserContext);

  const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const defaultDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const [expectedCompletion, setExpectedCompletion] = useState(
    TimeFormaters.getUtcTimeStamp(defaultDate, true)
  );

  const validation =
    "You have added both file and email id input. Remove one of them to submit";
  const validationCheck =
    bulkUploadFile && emailIds && emailIds.length !== 0 ? true : false;

  let disable = true;
  if (bulkUploadFile && !errorMessage.bulkUploadFile) {
    disable = validationCheck;
  } else if (emailIds && emailIds.length) {
    disable = validationCheck;
  }

  const handleExpectedCompletion = (value) => {
    setExpectedCompletion(TimeFormaters.getUtcTimeStamp(value));
  };

  useEffect(() => {
    if (!bulkUploadFile && emailIds) {
      const emailList = emailIds.trim().split(",");
      let formattedEmailListArray = [["Email", "Expected Completion Date"]];
      emailList.forEach((email) => {
        if (email)
          formattedEmailListArray.push([
            email,
            expectedCompletion + config.expectedCompletionTimestamp.halfDay,
          ]);
      });
      let formattedEmailList = formattedEmailListArray
        .map((item) => {
          return item.join(",");
        })
        .join("\r\n");
      setTextBulkUploadFile(formattedEmailList);
      setFileWithDate(false);
      setShowDatePicker(true);
    } else if (bulkUploadFile && !emailIds) {
      (async () => {
        const fileData = (await bulkUploadFile.text()).split("\r\n");
        const firstrow = fileData.shift();
        let isFileWithDate = false;
        if (
          firstrow.split(",")[1] &&
          firstrow.split(",")[1] === "Expected Completion Date"
        ) {
          isFileWithDate = true;
          setShowDatePicker(false);
        } else {
          setShowDatePicker(true);
        }
        setFileWithDate(isFileWithDate);
        let formattedEmailListArray = [["Email", "Expected Completion Date"]];
        fileData.forEach((data) => {
          if (data)
            formattedEmailListArray.push([
              data.split(",")[0],
              (isFileWithDate
                ? TimeFormaters.getUtcTimeStamp(
                    data.split(",")[1],
                    null,
                    null,
                    "DD-MM-YYYY, hh:mm A"
                  )
                : expectedCompletion) +
                config.expectedCompletionTimestamp.halfDay,
            ]);
        });
        let formattedEmailList = formattedEmailListArray
          .map((item) => {
            return item.join(",");
          })
          .join("\r\n");
        setTextBulkUploadFile(formattedEmailList);
      })();
    } else {
      setShowDatePicker(true);
    }
  }, [bulkUploadFile, emailIds, expectedCompletion]);

  const submitHandler = () => {
    const formData = new FormData();
    formData.append("bulkUpload", textBulkUploadFile);
    formData.append("fileWithDate", fileWithDate);
    const errors = {};
    if (!bulkUploadFile && !emailIds) {
      errors.bulkUploadFile =
        "Please select a file bulk upload file or the enrollment emails!";
    }
    if (!expectedCompletion) {
      errors.expectedCompletion = "Please enter Expected Completion Time!";
    }
    if (errors) {
      setErrorMessage({ ...errorMessage, ...errors });
    }

    const endPoint = `${config.endPoint.elearning}/course/${courseId.id}/bulkEnrollment`;

    if (!errors || !Object.keys(errors).length) {
      axios
        .post(endPoint, formData)
        .then((response) => {
          const filename = `${courseId.identityId} bulk-upload.csv`;
          UtilityFunctions.downloadCSVFile(response.data, filename);
          callbackCompletedSession();
          handleCloseAssignUserModal();
          setAlertType("success");
          setShowAlert(true);
          setAlert(
            "Upload successful, please check the CSV file for status of the users!"
          );
        })
        .catch((error) => {
          setAlert(error?.response?.data?.message);
          setShowAlert(true);
          setAlertType("error");
          handleCloseAssignUserModal();
        });
    }
  };

  const headerEmail = [{ label: "Email", key: "email" }];
  const headerEmailAndDate = [
    { label: "Email", key: "email" },
    { label: "Expected Completion Date", key: "date" },
  ];
  const dataEmail = [
    {
      email: "abc@rakuten.com",
    },
    {
      email: "xyz@rakuten.com",
    },
  ];
  const dataEmailAndDate = [
    {
      email: "abc@rakuten.com",
      date: "30/12/2022",
    },
    {
      email: "xyz@rakuten.com",
      date: "30/12/2022",
    },
  ];
  const sampleCsvWithEmail = {
    data: dataEmail,
    headers: headerEmail,
    filename: "Assign users by email.csv",
  };
  const sampleCsvWithEmailAndDate = {
    data: dataEmailAndDate,
    headers: headerEmailAndDate,
    filename: "Assign users by email and expected completion date.csv",
  };
  const hint = (
    <div>
      Hint : Drag and Drop here to upload
      <br />
      <CSVLink {...sampleCsvWithEmail}>Spreadsheet</CSVLink> sample file with
      email.
      <br />
      <CSVLink {...sampleCsvWithEmailAndDate}>Spreadsheet</CSVLink> sample file
      with email and completion date.
    </div>
  );

  const renderImportCsvContainer = () => {
    return (
      <div
        className="assigned-modal-left-box"
        style={{ width: "50%", margin: "1rem" }}
      >
        <span>Import CSV file</span>
        <FileUploader
          fName=""
          upliftFileDetails={({ fileData, error }) => {
            if (error) {
              setErrorMessage({ bulkUploadFile: error });
            } else {
              setErrorMessage({ bulkUploadFile: "" });
              setBulkUploadFile(fileData);
            }
          }}
          customStyle={{
            width: "90%",
            marginTop: "2rem",
            height: "10rem",
          }}
          onlyCSV={true}
          hint={hint}
        />
        {(errorMessage.bulkUploadFile && (
          <Validation
            message={errorMessage.bulkUploadFile}
            st={{
              display: "flex",
              justifyContent: "center",
              marginTop: 0,
            }}
          />
        )) ||
          ""}
      </div>
    );
  };

  const renderAddUserContainer = () => {
    return (
      <div style={{ width: "50%", margin: "1rem" }}>
        <span className="assign-modal-left-title">Add user email ids:</span>
        <div className=".assign-modal-right-box">
          <div className="inputArea">
            <ManualInputEmailBox
              upliftEmails={({ fileData }) => {
                setEmailIds(fileData);
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        width="auto"
        title={
          <span>
            <span style={{ fontFamily: "RakutenRoundSemiBold" }}>Assign </span>{" "}
            users to the Course.
          </span>
        }
        open={showAssignUserModal}
        // onOk={handleOk}
        onCancel={handleCloseAssignUserModal}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f7f7f7",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            &nbsp;
            <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
              {courseId.identityId}
            </b>
            &nbsp; - &nbsp;
            <b style={{ fontFamily: "RakutenRoundSemiBold" }}>
              {courseId.name} &nbsp;
            </b>
            <br />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: "#f7f7f7",
            }}
          >
            {renderImportCsvContainer()}
            <span className={style.circleBox}>OR</span>
            {renderAddUserContainer()}
          </div>

          {showDatePicker ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              <span style={{ marginLeft: "2rem", padding: "0.7rem" }}>
                <Label title="Expected Completion Date" required />
              </span>
              <span style={{ marginLeft: "1rem", width: "12.5rem" }}>
                <DateTimePicker
                  minValue={tomorrow}
                  value={defaultDate}
                  allowInput={true}
                  clickOpens={true}
                  onChange={(expectedCompletion) => {
                    handleExpectedCompletion(expectedCompletion);
                  }}
                />
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          <Button
            type="primary"
            onClick={submitHandler}
            className={style.assignButton}
            disabled={disable}
            style={{
              width: "11rem",
              height: "2.7rem",
              background: userConfig.primary,
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AntdAssignUserModal;

import { useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

import { CustomSelect } from "../../../component-library";
import style from "./teamReport.module.css";

const pieoptions = {
  pieHole: 0.5,
  showLables: "true",
  elements: {
    center: {
      text: "90%",
      color: "#FF6384",
      fontStyle: "Arial",
      sidePadding: 20,
    },
  },
};

const filterOptions = [
  { label: "ELearning Courses", value: "EL" },
  { label: "Instructor-Led Courses", value: "IL" },
];

const transformToCharData = (data) => {
  let transformedData = [["Category", "Value"]];

  data.forEach((d) => {
    transformedData.push([d.categoryName, d.categoryCourseCount]);
  });
  return transformedData;
};

const CategoryStats = ({ ELData, ILData }) => {
  const [curFilterData, setCurFilterData] = useState({
    label: "ELearning Courses",
    value: "EL",
  });
  const [categoryCourseCount, setCategoryCourseCount] = useState(0);
  const [ELChartData, setELChartData] = useState([]);
  const [ILChartData, setILChartData] = useState([]);

  useEffect(() => {
    if (ELData?.data && ELData?.data) {
      let data = transformToCharData(ELData?.data);
      setELChartData(data);
      setCategoryCourseCount(ELData?.totalCourseCategoryCount || 0);
    } else {
      setELChartData([]);
    }
  }, [ELData]);

  useEffect(() => {
    if (ILData?.categoryData) {
      let data = transformToCharData(ILData?.categoryData);
      setILChartData(data);
      setCategoryCourseCount(ILData?.totalCategoryCourseCount || 0);
    } else {
      setILChartData([]);
    }
  }, [ILData]);

  useEffect(() => {
    if (curFilterData?.value === "EL") {
      setCategoryCourseCount(ELData?.totalCourseCategoryCount || 0);
    }
    if (curFilterData?.value === "IL") {
      setCategoryCourseCount(ILData?.totalCategoryCourseCount || 0);
    }
  }, [curFilterData, ELData, ILData]);

  const getChart = (type) => {
    let chartData = null;
    if (type === "EL") chartData = ELChartData;
    if (type === "IL") chartData = ILChartData;

    return (
      (chartData && chartData.length && (
        <Chart
          chartType="PieChart"
          width="40rem"
          height="400px"
          data={chartData}
          options={pieoptions}
        />
      )) ||
      null
    );
  };
  const handleDropdownChange = (data) => {
    if (data == "IL") {
      setCurFilterData({ label: "Instructor-Led Courses", value: "IL" });
    } else {
      setCurFilterData({ label: "ELearning Courses", value: "EL" });
    }
  };

  return (
    <Col className={style.categoryStatsContainer}>
      <Row>
        <Col>
          <Row className={style.categoryStatsHeaderContainer}>
            <div>
              <p className={style.chartLabel}>ENROLLMENT PER CATEGORY</p>
            </div>
            <div style={{ width: "20rem", marginLeft: "3rem" }}>
              <Select
                className="myteam-tilecourseFilter"
                options={filterOptions}
                value={curFilterData}
                placeholder="Course Type"
                onChange={handleDropdownChange}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        {categoryCourseCount && (
          <Col>
            {getChart(curFilterData?.value)}

            <div className={style.donutWrapper}>
              <div className="text-center">{categoryCourseCount}</div>
              <div className="text-center"> Entries </div>
            </div>
          </Col>
        )}
      </Row>
    </Col>
  );
};

CategoryStats.propTypes = {
  ELData: PropTypes.object,
  ILData: PropTypes.object,
};

export default CategoryStats;

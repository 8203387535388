import React from "react";
import downloadIcon from "../../assets/icons/download_icon.svg";
import PropTypes from "prop-types";
import style from "./downloadCSVButton.module.css";

function DownloadCSVButton(props, { clickHandler, otherStyle }) {
	let downloadClicked = () => {
		if (props.clickHandler) {
			props.clickHandler();
		} else {
			props.onExport();
		}
	};
	return (
		<>
			<button
				style={props.otherStyle}
				className={`${style.buttonStyle}`}
				onClick={downloadClicked}>
				<img src={downloadIcon} alt="download CSV" />
			</button>
		</>
	);
}

DownloadCSVButton.propTypes = {
	clickHandler: PropTypes.func,
	otherStyle: PropTypes.object,
};

export default DownloadCSVButton;

import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { Card, Image, Typography } from "antd";
import Icon from "@ant-design/icons";

import { UserContext } from "../../context/UserContext";
import config from "../../config";

import "./LearningPathCard.css";
import { ReactComponent as bookmark_icon } from "../../assets/icons/bookmark_icon.svg";
import { ReactComponent as bookmark_black } from "../../assets/icons/bookmark_black.svg";
import round from "lodash/round";
import add_course from "../../assets/icons/add_course.svg";
import selected from "../../assets/icons/selected.svg";

const LearningPathCard = ({
  currentLearningPath,
  color,
  reload,
  showCheckIcon,
  isChecked,
  cardClicked,
  checkedLPState,
}) => {
  const {
    id: learningPathId,
    title,
    image,
    ilCourseCount,
    elCourseCount,
    duration,
    totalDuration,
    isFavourite,
  } = currentLearningPath;

  const { userId } = useContext(UserContext);
  const [isBookmark, setIsBookmark] = useState(isFavourite);

  const handleBookmark = (event) => {
    event.preventDefault();

    const LEARNING_PATH_FAVORITE_API_ENDPOINT =
      config.endPoint.commonService + "/favourite";
    const ENTITY_TYPE = "LP";

    if (!isBookmark) {
      axios
        .post(LEARNING_PATH_FAVORITE_API_ENDPOINT, [
          {
            userId: userId,
            entityType: ENTITY_TYPE,
            entityId: learningPathId,
          },
        ])
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmark(true);
          }
        })
        .catch((error) => {
          //TODO handle via toast
        })
        .finally(() => {
          if (reload) reload();
        });
    } else {
      axios
        .delete(LEARNING_PATH_FAVORITE_API_ENDPOINT, {
          params: {
            userId: userId,
            courseType: ENTITY_TYPE,
            courseId: learningPathId,
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            setIsBookmark(false);
          }
        })
        .catch((err) => {
          //TODO handle via toast
        })
        .finally(() => {
          if (reload) reload();
        });
    }
  };

  const renderLearningPathImage = (image) => (
    <div className={"learningpath-cardImage"}>
      {/* //TODO -- need to use className instead of inline css */}
      <Image
        preview={false}
        src={image}
        style={{ borderRadius: "50%", width: 64, height: 64 }}
      ></Image>
    </div>
  );

  const renderCourseDetailItem = (count, text) => (
    <div>
      <Typography.Text
        className={"learningpath-detailItem learningpath-detailCount"}
      >
        {count}
      </Typography.Text>
      <Typography.Text
        className={"learningpath-detailItem learningpath-detailText"}
      >
        {text}
      </Typography.Text>
    </div>
  );

  const renderLearningPathTitle = (title) => (
    <Typography.Text className={"learningpath-cardTitle"} ellipsis>
      {title}
    </Typography.Text>
  );

  const renderBookMark = () => (
    <span>
      <Icon
        component={isBookmark ? bookmark_black : bookmark_icon}
        className={"learningpath-bookmark-icon"}
        onClick={handleBookmark}
      />
    </span>
  );

  const style = { backgroundColor: color };

  return (
    <Link to={`/learning-paths/${learningPathId}`}>
      <Card className={`learningpath-card`} style={style} hoverable>
        <div className={"learningpath-cardContainer"}>
          {renderLearningPathImage(image || "/default.png")}
          <div className={"learningpath-cardDetails"}>
            <div>{renderLearningPathTitle(title)}</div>
            <div className={"learningpath-courseDetailItems"}>
              {renderCourseDetailItem(elCourseCount, "EL COURSE")}
              {renderCourseDetailItem(ilCourseCount, "IL COURSE")}
              {renderCourseDetailItem(
                round(duration || totalDuration, 2),
                "Hrs"
              )}
            </div>
          </div>
          <div className={"learningpath-bookMark"}>
            <div>{renderBookMark()}</div>
            {showCheckIcon ? (
              checkedLPState &&
              checkedLPState?.length &&
              checkedLPState?.includes(learningPathId) ? (
                <img
                  src={selected}
                  alt="Selected course"
                  className={"learningpath-plus-circle"}
                  onClick={(event) => {
                    event.preventDefault();
                    cardClicked(learningPathId);
                  }}
                />
              ) : (
                <img
                  src={add_course}
                  alt="Add course"
                  className={"learningpath-plus-circle"}
                  onClick={(event) => {
                    event.preventDefault();
                    cardClicked(learningPathId);
                  }}
                />
              )
            ) : null}
          </div>
        </div>
      </Card>
    </Link>
  );
};
export default LearningPathCard;

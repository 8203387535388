import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import AttendanceStats from "./AttendenceStats";
import StatsTile from "../../../component-library/StatsTile/AntdStatsTile";

const CourseStats = ({ overAllStats, ILStats, ELStats }) => {
  const [stats, setStats] = useState({});
  useEffect(() => {
    let statsData = {};
    statsData["TOTAL TEAM MEMBERS"] = [ILStats?.team_count || "--"];
    // statsData["TOTAL COURSES ACCESSED (IL + EL)"] = [overAllStats?.total || "--"];
    // statsData["TOTAL COURSES Completed (IL + EL)"] = [overAllStats?.completed || "--"];
    // statsData["IL COURSES ACCESSED"] =
    //   [(ILStats?.total &&
    //     overAllStats?.total && [
    //       ILStats.total,
    //       `<span>${Number((ILStats.total / overAllStats.total) * 100).toFixed(2)}%</span> of total courses accessed`
    //     ]) ||
    //   "--", 'IL'];
    statsData["ENROLLMENT COUNT"] = [
      ILStats?.enrolled || "--",
      "IL",
      "All enrollments by same or different users into same or different courses",
    ];
    statsData["WAITLISTED COUNT"] = [
      ILStats?.waitlist || "--",
      "IL",
      "All waitlisting by same or different users into same or different courses",
    ];
    statsData["COMPLETED COUNT"] = [
      ILStats?.completed || "--",
      "IL",
      "All completions by same or different users into same or different courses",
    ];
    // statsData["EL COURSES ACCESSED"] =
    //   [(ELStats?.total &&
    //     overAllStats?.total && [
    //       ELStats.total,
    //       `<span>${Number((ELStats.total / overAllStats.total) * 100).toFixed(2)}%</span> of total courses accessed`
    //     ]) ||
    //   "--", 'EL'];
    statsData["ASSIGNED COUNT"] = [
      ELStats?.assigned || "--",
      "EL",
      "All assignments by same or different users into same or different courses",
    ];
    statsData["IN PROGRESS COUNT"] = [
      ELStats?.viewed || "--",
      "EL",
      "All viewed/started by same or different users into same or different courses",
    ];
    statsData["COMPLETED COUNT "] = [
      ELStats?.completed || "--",
      "EL",
      "All completions by same or different users into same or different courses",
    ];
    statsData["TOTAL RECOMMENDATIONS"] = [
      overAllStats?.recommended || "--",
      "",
      "All recommedations for same or different users into same or different courses",
    ];
    statsData["RECOMMENDATIONS COMPLETED"] = [
      overAllStats?.recommendedCompleted || "--",
      "",
      "All recommedations completed by same or different users into same or different courses",
    ];
    setStats(statsData);
  }, [overAllStats, ELStats, ILStats]);

  const getStatsTileComponents = () => {
    let statsComponents =
      stats &&
      Object.entries(stats).map((val, i) => {
        let insightHtml = null;
        let data = null;
        if (Array.isArray(val[1][0]) && val[1][0].length === 2) {
          insightHtml = val[1][0][1];
          data = val[1][0][0];
        }
        return (
          <>
            <StatsTile
              label={val[0]}
              value={data || val[1][0]}
              insight={insightHtml}
              key={i}
              courseType={val[1][1]}
              description={val[1][2] || null}
            />
          </>
        );
      });
    return statsComponents;
  };

  return (
    <Row className="reports-container">
      {getStatsTileComponents()}{" "}
      <AttendanceStats
        attendence={ILStats?.attendanceCompleted}
        attendencePercentage={ILStats?.attendancePercentage}
      />{" "}
    </Row>
  );
};

CourseStats.propTypes = {
  overAllStats: PropTypes.object,
  ILStats: PropTypes.object,
  ELStats: PropTypes.object,
};
export default CourseStats;

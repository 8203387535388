import axios from "axios";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./courseImageAddEdit.scss";
import CourseImageAddEditModal from "./CourseImageAddEditModal";
import { Form, Row, Col, Breadcrumb, Button, Select, Alert } from "antd";
import uploadImage from "../../../assets/icons/upload_image.svg";
import { ScrollToTop, ImageCropper } from "../../../component-library";
import config from "../../../config";
import { UserContext } from "../../../context/UserContext";

export default function CourseImageAddEdit() {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const imageName = query.get("image_name");
  const { userConfig } = useContext(UserContext);

  const crumbs = [
    {
      title: (
        <a href="/Admin" style={{ color: userConfig.primary }}>
          Admin Dashboard
        </a>
      ),
    },
    {
      title: (
        <a
          href="/Admin/Course/ImageList/"
          style={{ color: userConfig.primary }}
        >
          Course Images
        </a>
      ),
    },
    {
      title: (imageName && "Edit Course Image") || "Add Course Image",
    },
  ];
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [base64String, setBase64String] = useState(null);
  const [initialTags, setTags] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emptyTags, setEmptyTags] = useState(false);
  const [emptyImage, setEmptyImage] = useState(false);
  const [cropper, setCropper] = useState(false);
  const [fileToCropper, setFileToCropper] = useState(null);

  useEffect(() => {
    if (imageName) {
      const endPoint = `${config.endPoint.commonService}/image/imageName/course/${imageName}`;
      axios.get(endPoint).then((response) => {
        if (response.data.success) {
          setFile(response.data.data.imageLink);
          setTags(response.data.data.tags || []);
        }
      });
    }
  }, [imageName]);

  const handleCloseCropper = () => {
    setCropper(false);
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  async function handleUpload(e) {
    if (e.target.files && e.target.files.length > 0) {
      setFileToCropper(e.target.files[0]);
      setCropper(true);
    }
  }

  const resetForm = () => {
    setTags([]);
    setFile(null);
  };

  const submitHandler = (e) => {
    window.scrollTo(0, 0);
    const imageTags = [];
    for (let tag of initialTags) {
      imageTags.push(tag);
    }
    setEmptyImage(base64String ? false : true);
    setEmptyTags(imageTags.length > 0 ? false : true);
    if (base64String && imageTags.length > 0) {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/octet-stream" });
      const file = new File([blob], "file.jpg", { type: "image/jpeg" });
      const formData = new FormData();
      formData.append("file", file);
      const formattedTags = imageTags.map((tag) => tag?.toLowerCase());
      formData.append("tags", JSON.stringify(formattedTags));
      axios
        .post(`${config.endPoint.commonService}/image/`, formData)
        .then((res) => {
          if (!res.data.success) {
            setAlertType("error");
            setShowAlert(true);
            setAlert(res.data.message || "Something went wrong.");
          } else {
            history.push({
              pathname: "/Admin/Course/ImageList",
              state: {
                alertType: "success",
                showAlert: true,
                alertMessage: "Image and Tags added successfully.",
              },
            });
          }
        })
        .catch((error) => {
          setAlertType("danger");
          setAlert(error?.response?.data?.message || "Something went wrong.");
        });
    }
  };

  const isButtonDisabled = initialTags.length === 0 || file === null;

  const closeConfirmationPopUp = () => {
    setShowConfirmation(false);
  };

  const deleteImage = () => {
    closeConfirmationPopUp();
    const endPoint = `${config.endPoint.commonService}/image/course/${imageName}`;
    axios
      .delete(endPoint)
      .then((response) => {
        if (response.data.success) {
          history.push({
            pathname: "/Admin/Course/ImageList",
            state: {
              alertType: "success",
              alertMessage: "Image deleted successfully.",
            },
          });
        } else {
          setAlert(response.data.message);
          setShowAlert(true);
          setAlertType("error");
        }
      })
      .catch((error) => {
        setAlert(error.response.data.message || "Something went wrong.");
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const updateHandler = () => {
    window.scrollTo(0, 0);
    const imageTags = [];
    for (let tag of initialTags) {
      imageTags.push(tag);
    }

    const toSubmit = imageTags.length === 0 ? false : true;
    if (!toSubmit) {
      setEmptyTags(true);
      return;
    }

    const data = {
      tags: imageTags,
    };

    const endPoint = `${config.endPoint.commonService}/image/${imageName}`;
    axios
      .patch(endPoint, data)
      .then((res) => {
        if (!res.data.success) {
          setAlertType("error");
          setShowAlert(true);
          setAlert(res.response.data.message || "Something went wrong.");
        } else {
          history.push({
            pathname: "/Admin/Course/ImageList",
            state: {
              alertType: "success",
              showAlert: true,
              alertMessage: "Tags updated successfully.",
            },
          });
        }
      })
      .catch((error) => {
        setAlertType("error");
        setShowAlert(true);
        setAlert(error.response.data.message || "Something went wrong.");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#ffffff",
        marginTop: "5rem",
      }}
    >
      <ScrollToTop />
      {showConfirmation && (
        <CourseImageAddEditModal
          show={showConfirmation}
          handleClose={closeConfirmationPopUp}
          toggle={true}
          title="Are you sure want to delete this image?"
          leftButton={"Yes"}
          rightButton={"NO"}
          leftButtonClick={deleteImage}
          rightButtonClick={closeConfirmationPopUp}
        />
      )}
      {showAlert && (
        <Alert
          closable
          message={alert}
          type={alertType}
          onCloseAlert={() => setAlert("")}
          className={"admin-alert"}
        />
      )}
      <Breadcrumb className={"courseImageCrumbs"} items={crumbs} />
      <div className={"courseImagePageTitle"}>
        {(imageName && "Edit Course Image") || "Add Course Image"}
      </div>

      <Form onSubmit={submitHandler} className={"courseImageForm"}>
        <Row>
          <Col className={"formImageAdd"}>
            <Row className={"rowAddImage"}>
              <Col className={"colAddImage"} sm={4} lg={8}>
                <Col>
                  {!file && (
                    <>
                      <Col className={"biggerSvgBox"}>
                        <img
                          src={uploadImage}
                          alt=""
                          className={"biggerSvg"}
                          onClick={onButtonClick}
                          id="uploadImg"
                          Preview="false"
                        />
                        <input
                          type="file"
                          id="file"
                          accept=".jpg, .jpeg, .png"
                          ref={inputFile}
                          className={"inputButton"}
                          onChange={handleUpload}
                        />
                        <p className={"imageText"}>Select Image to upload</p>
                      </Col>
                      <p className={"imageGuideline"}>
                        png, jpg or jpeg image with minimum of 600 x 350
                        resolution
                      </p>
                    </>
                  )}
                  {cropper && fileToCropper && (
                    <ImageCropper
                      ref={inputFile}
                      show={cropper}
                      handleCloseCropper={handleCloseCropper}
                      fileToCropper={fileToCropper}
                      setFile={setFile}
                      setBase64String={setBase64String}
                      setAlert={setAlert}
                      setAlertType={setAlertType}
                    />
                  )}
                  {file && (
                    <img
                      src={file}
                      alt={imageName}
                      className={"biggerSvgBox"}
                    />
                  )}
                </Col>
              </Col>
              <Col className={"colAddImage"} sm={8} lg={16}>
                <Select
                  mode="tags"
                  autoFocus="true"
                  listHeight="40rem"
                  style={{
                    width: "40rem",
                  }}
                  placeholder="Add Image Tags"
                  onChange={(items) => {
                    setTags(items);
                  }}
                  value={initialTags}
                  options={[]}
                />
              </Col>
            </Row>
            <Row style={{ backgroundColor: "#ffffff" }}>
              <Col sm={4} lg={10}>
                {emptyImage && (
                  <div className={"errorMessage"}>*Please select an image</div>
                )}{" "}
              </Col>
              <Col>
                {emptyTags && (
                  <div className={"errorMessage"}>*Tags cannot be empty</div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={"buttonGroup"}>
          {!imageName && (
            <>
              <Col className={"secButtonContainer"} lg={4}>
                <Button
                  type="button"
                  onClick={resetForm}
                  className={"courseImage-ButtonStyle"}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  onClick={submitHandler}
                  className={"courseImage-ButtonStyle"}
                  disabled={isButtonDisabled}
                >
                  Add
                </Button>
              </Col>
            </>
          )}
          {imageName && (
            <>
              <Col className={"secButtonContainer"} lg={4}>
                <Button
                  type="button"
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                  className={"courseImage-ButtonStyle"}
                >
                  Delete
                </Button>
              </Col>
              <Col>
                <Button
                  type="button"
                  onClick={updateHandler}
                  className={"courseImage-ButtonStyle"}
                >
                  Update
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
}

import React from "react";
import "./AdminTopContainer.scss";

import { Layout } from "antd";

export default function AdminTopContainer(props) {
  return (
    <Layout
      bsPrefix={`adminContainer ${"adminContainer"}`}
    >
      {props.children}
    </Layout>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  AdminPageTitle,
  AdminPageSubTitle,
  InlineIconButton,
} from "../../../component-library";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Typography, Spin, Button, Alert } from "antd";
import TimeFormatter from "../../../utils/timeFormatter";
import editIcon from "../../../assets/icons/edit_icon.svg";
import zoomIcon from "../../../assets/icons/zoom_icon.svg";
import toggleIcon from "../../../assets/icons/toggle_down_icon.svg";
import "./moduleList.scss";
import TimeFormaters from "../../../utils/timeFormatter";
import { decodeHtmlEntities } from "../../../utils/commonUtility";
import { useContext } from "react";
import { CourseContext } from "../../../context/CourseContext";
import { Breadcrumb, Table } from "antd";
import Input from "antd/es/input/Input";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import config from "../../../config";

function ModuleList(props) {
  const history = useHistory();
  const location = useLocation();
  const { userDateFormat } = useContext(CourseContext);

  const [sessionId] = useState(props.match.params.batch_id);
  const [sessionTimeTag, setSessionTimeTag] = useState("");
  const [bottom, setBottom] = useState("bottomCenter");
  const [sessionTimeZoneTag, setSessionTimeZoneTag] = useState("");
  const [courseData, setCourseData] = useState("");
  const [listData, setListData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showLoader, setShowLoader] = useState(true);
  const [alert, setAlert] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (pageLoading) {
      if (
        location.state &&
        location.state.alertType &&
        location.state.alertMessage
      ) {
        setAlert(location.state.alertMessage);
        setShowAlert(true);
        setAlertType(location.state.alertType);
        setPageLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [pageLoading]);

  const onAddNewModule = () => {
    history.push(`/Admin/AddEditModule/${sessionId}`);
  };

  const onEditModule = (moduleId) => {
    history.push(`/Admin/AddEditModule/${sessionId}?moduleId=${moduleId}`);
  };

  const getText = (html) => {
    const divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  };

  const moduleColumns = [
    {
      dataIndex: "id",
      title: "Module ID",
      sort: true,
      headerStyle: {
        width: "8rem",
      },
    },
    {
      dataIndex: "start_date",
      title: "Module start time",
      sort: true,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "end_date",
      title: "Module end time",
      sort: true,
      csvFormatter: (cell, row) => {
        return TimeFormatter.FormatTableTime(cell, null, userDateFormat);
      },
      render: (cell, row) => {
        return <>{TimeFormatter.FormatTableTime(cell, null, userDateFormat)}</>;
      },
    },
    {
      dataIndex: "location",
      title: "Location",
      sort: true,
      headerStyle: {
        width: "15rem",
      },
    },
    {
      dataIndex: "trainer_details[0][trainer_name]",
      title: "trainer name",
      sort: true,
      render: (cell, row) => {
        if (row.trainer_details.length > 1) {
          return (
            <>
              {row.trainer_details[0].trainer_name}
              <span className={"moreDataColor"}>
                {" "}
                +{row.trainer_details.length - 1}more
              </span>
            </>
          );
        }
        return <>{row.trainer_details[0].trainer_name}</>;
      },
    },
    {
      dataIndex: "trainer_details[0][trainer_is_internal]",
      title: "INT/EXT",
      sort: false,
      headerStyle: {
        width: "5rem",
      },
      render: (cell, row) => {
        if (row?.trainer_details[0]?.trainer_is_internal) {
          return <>Internal</>;
        }
        return <>External</>;
      },
      csvFormatter: (cell, row, rowIndex) => {
        return cell ? "Internal" : "External";
      },
    },
    {
      dataIndex: "id",
      title: "Edit Module",
      sort: false,
      headerAlign: "center",
      csvExport: false,
      align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              onEditModule(row.id);
            }}
          />
        );
      },
    },
  ];

  const crumbs = [
    {
      title: (
        <a href="/Admin" className="crumbs-module-list">
          Admin Dashboard
        </a>
      ),
    },
    {
      title: (
        <a href="/Admin/Courses" className="crumbs-module-list">
          Courses
        </a>
      ),
    },
    {
      title: (
        <a
          href={`/Admin/SessionList/${courseData.id}`}
          className="crumbs-module-list"
        >
          Manage Sessions
        </a>
      ),
    },
    {
      title: "Manage Modules",
    },
  ];

  const defaultSorted = [
    {
      dataField: "start_date",
      order: "asc",
    },
  ];

  const [tableColumns, settableColumns] = useState(moduleColumns);

  useEffect(() => {
    getModuleData();
    settableColumns(moduleColumns);
  }, [userDateFormat]);

  const getModuleData = () => {
    setShowLoader(true);
    axios
      .get(`${config.endPoint.instructorLedNew}/batch/`, { params: { batch_id: sessionId, need_schedule: true } })
      .then((response) => {
        setCourseData(response.data.data.course);
        setListData(response.data.data.schedule);
        setShowLoader(false);
        setTableData(response.data.data.schedule);
        setPageCount(response.data.data.schedule.length);
        setSessionTimeTag(`${TimeFormaters.FormatTableTime(
          response.data.data.start_time,
          response.data.data.tz_offset,
          userDateFormat
        )} to ${TimeFormaters.FormatTableTime(
          response.data.data.end_time,
          response.data.data.tz_offset,
          userDateFormat
        )}
      `);
        setSessionTimeZoneTag(
          TimeFormaters.getTimeZoneFromOffset(response.data.data.tz_offset)
        );
      })
      .catch((error) => {
        setAlert(
          error.response.data.message ||
            "Something went wrong, Please try again."
        );
        setShowAlert(true);
        setAlertType("error");
      });
  };

  const CSVColumns = tableColumns.map((header, index) => ({
    key: header.dataIndex,
    label: header.title,
  }));

  const handleSearch = (searchText) => {
    const filteredData = tableData.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
    );
    if (searchText === "") {
      setListData(tableData);
    } else {
      setListData(filteredData);
    }
  };

  const getDataToDownload = (listData) => {
    return listData.map((data) => ({
      ...data,
      start_date: dayjs.unix(data.start_date).format("DD/MM/YYYY HH:mm:ss"),
      end_date: dayjs.unix(data.end_date).format("DD/MM/YYYY HH:mm:ss"),
    }));
  };

  const tableHeader = () => {
    return (
      <div className={"notification_header"}>
        <Row style={{ alignItems: "center", marginBottom: "2rem" }}>
          <Col lg={6}>
            <Typography className="notification-pageTitle">
              Modules
              <span
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "RakutenRoundRegular",
                }}
              >
                ({showLoader ? <Spin size="small" /> : pageCount})
              </span>
            </Typography>
          </Col>

          <Col lg={8}></Col>
          <Col lg={2}>
            <CSVLink
              filename={"ModuleList"}
              extension=".csv"
              wrapColumnChar=""
              headers={CSVColumns}
              data={getDataToDownload(listData)}
            >
              <Button
                type="primary"
                shape="circle"
                icon={<DownloadOutlined />}
                size="large"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              />
            </CSVLink>
          </Col>
          <Col lg={4}>
            <Input
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
              suffix={<SearchOutlined />}
              className={"notificationSearch"}
              style={{
                marginTop: "1rem",
                width: " 10rem",
                fontSize: "1rem",
                fontFamily: "RakutenRoundRegular",
                borderRadius: "4px",
              }}
            />
          </Col>
          <Col lg="2">
            <Button
              type="button"
              className={"moduleList-addNewCourse"}
              icon={<PlusCircleOutlined />}
              label="Add Module"
              onClick={onAddNewModule}
            >
              Add Module
            </Button>
          </Col>
        </Row>
        <Row>
          <AdminPageSubTitle
            courseId={courseData && courseData.identity_id}
            courseName={
              courseData && decodeHtmlEntities(courseData.course_name)
            }
            sessionId={sessionId}
            sessionTime={sessionTimeTag}
            timeZone={sessionTimeZoneTag}
            otherStyle={"subTitle"}
          />
        </Row>
      </div>
    );
  };

  const expandRow = {
    expandedRowRender: (row) => {
      const htmlString = row.agenda;
      const zoom_link = row.zoom_link ? row.zoom_link : "Not available";
      const trainerDetails = row.trainer_details.map((trainer, index) => {
        const trainer_internal = trainer.trainer_is_internal
          ? "Internal"
          : "External";
        return (
          <Row>
            <Col lg={1}>{index + 1}.</Col>
            <Col lg={2}>{trainer.trainer_name}</Col>
            <Col lg={3}>{trainer.trainer_email}</Col>
            <Col lg={1}>{trainer_internal}</Col>
          </Row>
        );
      });
      return (
        <>
          <p className={"rowExpandTitle"}>Trainer & Agenda Details</p>
          <div className={"rowExpandStyle"}>
            {trainerDetails}
            {/* <p>{row.agenda}</p> */}
            <Row className={"rowPadding"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: htmlString,
                }}
              />
            </Row>
            <Row className={"rowPadding"}>
              <div className={"zoomContainer"}>
                <span>
                  <img src={zoomIcon} alt="zoom icon" />
                </span>
                <span className={"zoomValueContainer"}>
                  <span>Zoom Link: </span>
                  <span className={"zoomValue"}>{zoom_link}</span>
                </span>
              </div>
            </Row>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandColumnPosition: "right",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      return null;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <img
            className={"toggleExpandButton"}
            src={toggleIcon}
            alt="zoom icon"
          />
        );
      }
      return <img src={toggleIcon} alt="zoom icon" />;
    },
  };

  return (
    <>
      <div
        style={{
          marginTop: "7rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showAlert && (
          <Alert
            closable
            message={alert}
            type={alertType}
            className={"admin-alert"}
          />
        )}
        <Breadcrumb items={crumbs} className={"moduleList-crumbs"} />
        {tableHeader()}
        <div className={"moduleList-table"}>
          {
            <Table
              pagination={{
                position: [bottom],
                showQuickJumper: true,
              }}
              rowKey="id"
              loading={showLoader}
              dataSource={listData}
              columns={tableColumns}
              expandable={expandRow}
              defaultSorted={defaultSorted}
              exportCSV={{
                fileName:
                  courseData && courseData.identity_id
                    ? `${courseData.identity_id}_${sessionId}_module_list.csv`
                    : `module_list_export.csv`,
              }}
            />
          }
        </div>
      </div>
    </>
  );
}

export default ModuleList;

import React, { useState } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { Row, Col } from "antd";
import "./AntdEditCategoryModal.scss";

function AntdEditCategoryModal({
  show,
  showModal,
  setShowModal,
  category,
  parentCategory,
  acronym,
  handleClose,
  switchModal,
  updateCard,
  isUsed,
  parent,
  fetchCategoryData,
  fetchData,
  parentId,
}) {
  const [catAcronym, setCatAcronym] = useState(acronym);
  const [tempCat, setTempCat] = useState(category);
  const [tempParentCat, setTempParentCat] = useState(parent);
  const [tempParentCatId, setTempParentCatId] = useState(parentId);

  const [errorMessage, setErrorMessage] = useState({
    category: "",
    parentCategory: "",
    categoryAcronym: "",
  });
  const options = [];

  parentCategory.map((cat, index) => {
    return (options[index] = {
      value: cat.id,
      label: cat.parent_category_name,
    });
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isUsed) {
      if (
        !errorMessage ||
        (!errorMessage.parentCategory &&
          !errorMessage.category &&
          !errorMessage.categoryAcronym)
      ) {
        console.log(tempParentCatId, ".....tempParentCatId");
        updateCard(tempCat, tempParentCat, catAcronym, tempParentCatId);
        handleClose();
      }
    } else {
      if (!errorMessage || !errorMessage.category) {
        updateCard(tempCat, tempParentCat, catAcronym, tempParentCatId);
        handleClose();
      }
    }
  };

  const handleDropdown = (selected) => {
    console.log(selected, "....seletged....");
    if (!selected) {
      const error = { parentCategory: `Please select a Parent Category` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setTempParentCat(selected.label);
      setTempParentCatId(selected);
      const error = { parentCategory: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategory = (category) => {
    if (!category) {
      const error = { category: `Please add a Category Name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (category && !category.trim()) {
      const error = { category: `Please add a Category Name` };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setTempCat(category.trim());
      const error = { category: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };

  const handleCategoryAcronym = (categoryAcronym) => {
    if (!categoryAcronym) {
      const error = { categoryAcronym: `Please add a Category Acronym` };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (categoryAcronym && !categoryAcronym.trim()) {
      const error = {
        categoryAcronym: `Category Acronym cannot be blank spaces`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (categoryAcronym.length === 1) {
      const error = {
        categoryAcronym: `Category Acronym must be longer than or equal to 2 characters`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else if (tempCat.toLowerCase() === categoryAcronym.toLowerCase()) {
      const error = {
        categoryAcronym: `Category Acronym cannot be same as the category`,
      };
      setErrorMessage({ ...errorMessage, ...error });
    } else {
      setCatAcronym(categoryAcronym.trim());
      const error = { categoryAcronym: "" };
      setErrorMessage({ ...errorMessage, ...error });
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(showModal);

  return (
    <div>
      <Modal
        className="ec-modal"
        title="Edit Category"
        centered
        width={"50rem"}
        open={isModalOpen}
        footer={null}
        onCancel={handleClose}
      >
        <Col style={{ marginTop: "-4.1rem", marginLeft: "35rem" }}>
          <Button className="ec-switch-button" onClick={switchModal}>
            CHANGE TO EDIT SUBCATEGORY
          </Button>
        </Col>
        <Col style={{ marginTop: "2rem" }}>
          <Form>
            <Col>
              <Row style={{ width: "15rem" }}>
                <Form.Item
                  className="ec-form-labelstyle"
                  label="Category Name"
                  required
                  colon={false}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Input
                    className="ec-labelinput"
                    placeholder="Category"
                    defaultValue={category}
                    onChange={(e) => {
                      handleCategory(e.target.value);
                    }}
                  />
                  <div className="ec-validation">
                    {(errorMessage.category && (
                      <div className="ec-error-validation">
                        {errorMessage.category}
                      </div>
                    )) ||
                      ""}
                  </div>
                </Form.Item>
              </Row>
              <Row
                className="ec-category-acronym"
                style={{ marginLeft: "0rem" }}
              >
                <Form.Item
                  label="Category Acronym"
                  className="ec-form-labelstyle"
                  required
                  colon={false}
                >
                  <Input
                    className="ec-labelinput"
                    placeholder="Category Acronym"
                    defaultValue={catAcronym}
                    onChange={(e) => {
                      handleCategoryAcronym(e.target.value);
                    }}
                  />
                  <div className="ec-validation">
                    {(errorMessage.categoryAcronym && (
                      <div className="ec-error-validation">
                        {errorMessage.categoryAcronym}
                      </div>
                    )) ||
                      ""}
                  </div>
                </Form.Item>
              </Row>
              <Row className="ec-form-label" style={{ width: "15rem" }}>
                <Form.Item
                  className="ec-form-labelstyle"
                  label="Parent Category"
                  required
                  colon={false}
                >
                  <Select
                    style={{ width: "15rem", marginLeft: "1rem" }}
                    className="ec-modalSelect"
                    placeholder="Select..."
                    defaultValue={parentId}
                    options={options}
                    onChange={handleDropdown}
                  />
                  <div>
                    {(!isUsed && errorMessage.parentCategory && (
                      <div className="ec-error-validation">
                        {errorMessage.parentCategory}
                      </div>
                    )) ||
                      ""}
                  </div>
                </Form.Item>
              </Row>
            </Col>
            <Row className="ec-modal-buttons">
              <Button
                className="ec-cancel-button"
                type="button"
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                className="ec-submit-button"
                type="button"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Row>
          </Form>
        </Col>
      </Modal>
    </div>
  );
}

// AntdEditCategoryModal.propTypes = {
//   // show: PropTypes.bool,
//   id: PropTypes.string,
//   category: PropTypes.string,
//   parentCategory: PropTypes.array,
//   handleClose: PropTypes.func,
//   setParentCategory: PropTypes.func,
//   switchModal: PropTypes.func,
//   updateCard: PropTypes.func,
//   isUsed: PropTypes.bool,
//   parent: PropTypes.string,
//   fetchCategoryData: PropTypes.func,
//   fetchData: PropTypes.func,
//   parentId: PropTypes.number,
// };

export default AntdEditCategoryModal;

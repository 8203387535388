import axios from "axios";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Tag, Input } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import editIcon from "../../../../assets/icons/edit_icon.svg";
import addUser from "../../../../assets/icons/add_user.svg";
// import style from "./learningPathsList.module.css";
import "./learningPathsList.scss";
import config from "../../../../config";
import {
  AdminPageTitle,
  InlineIconButton,
  AntDesignBreadCrumb,
  AntDesignPrimaryButton,
  AntDesignDownloadIconButton,
  AntDesignTable,
  AntDesignNotifcation,
} from "../../../../component-library";
import AntDesignContainer from "../../../../component-library/antDesignContainer/Container";
import { decodeHtmlEntities } from "../../../../utils/commonUtility";
import eyeIcon from "../../../../assets/icons/eye_icon.svg";
import TimeFormatter from "./../../../../utils/timeFormatter";
import { CourseContext } from "../../../../context/CourseContext";
import LearningPathDetail from "./LearningPathDetail";
import ImportUserHelper from "../../../../component-library/importAddUserModal/ImportUserHelper";
import CourseFilter from "../../../../component-library/antDesignCourseFilter/CourseFilter";
import { UserContext } from "../../../../context/UserContext";
import PrivateCourseIcon from "../../../../components/common/privateCourseIcon";

export default function LearningPathsList({ location = {} }) {
  const { userConfig } = useContext(UserContext);

  const { state } = location;
  const filterItems = [
    { label: "Active", value: "active" },
    { label: "Archived", value: "archived" },
  ];
  const columns = [
    {
      dataIndex: "isPublic",
      title: <InlineIconButton iconURL={eyeIcon} alt="Private public filter" />,
      width: "60px",
      render: (cell) => {
        if (!cell) {
          return <PrivateCourseIcon color2={userConfig.secondary} />;
        }
        return null;
      },
    },
    {
      dataIndex: "identityId",
      title: "PATH ID",
      sorter: true,
      key: "identityId",
      width: "124px",
    },
    {
      dataIndex: "title",
      title: "PATH NAME",
      sortDirections: ["ascend", "descend", "ascend"],
      filterResetToDefaultFilteredValue: true,
      sorter: true,
      key: "name",
      width: "166px",
      ellipsis: true,
      render: (cell, record) => (
        <Link to={`/learning-paths/${record.id}`} target="_blank">
          <span
            className={`${
              record.isPublic ? "publicSession" : "privateSession"
            } ant-table-ellipsis--2`}
          >
            {decodeHtmlEntities(cell)}
          </span>
        </Link>
      ),
    },
    {
      dataIndex: "updatedTimestamp",
      title: "LAST UPDATE",
      sorter: true,
      width: "220px",
      key: "updatedTimestamp",
      render: (cell) =>
        TimeFormatter.FormatTableTime(cell, null, userDateFormat),
    },
    {
      dataIndex: "ilCourseCount",
      title: "IL COURSES",
      sorter: true,
      width: "150px",
      align: "center",
      key: "ilCourseCount",
      render: (cell) => <Tag>{cell}</Tag>,
    },
    {
      dataIndex: "elCourseCount",
      title: "EL COURSES",
      sorter: true,
      width: "150px",
      align: "center",
      key: "elCourseCount",
      render: (cell) => <Tag>{cell}</Tag>,
    },
    {
      title: "EDIT PATHS",
      width: "150px",
      align: "center",
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={editIcon}
            alt="edit button"
            clickHandler={(event) => {
              openEditPath(row.id);
            }}
          />
        );
      },
    },
    {
      isDummyField: true,
      title: "ADD USER",
      headerAlign: "center",
      width: "130px",
      align: "center",
      archived: true,
      render: (cell, row) => {
        return (
          <InlineIconButton
            iconURL={addUser}
            alt="edit button"
            clickHandler={(e) => {
              openAddUserModal(row);
            }}
          />
        );
      },
    },
  ];

  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState("active");
  const [courseCount, setCourseCount] = useState(false);
  const [tableColumns, setTableColumns] = useState(columns);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(0);
  const [sortByColumn, setSortByColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [alert, setAlert] = useState(state?.alertMessage || null);
  const [alertType, setAlertType] = useState(state?.alertType || null);
  const history = useHistory();
  const { userDateFormat } = useContext(CourseContext);
  const crumbs = [
    {
      title: "Admin Dashboard",
      to: "/Admin",
    },
    { title: "Learning Paths" },
  ];

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  function openErrorCloseAddUser() {
    setShowErrorModal(true);
    setShowAddUserModal(false);
  }

  const openAddUserModal = (row) => {
    setCurrentRow(row);
    setShowAddUserModal(true);
  };

  const onAddNewPath = () => {
    history.push("/Admin/learningPath/AddEditLearningPath");
  };

  const openEditPath = (pathId) => {
    history.push(`/Admin/learningPath/AddEditLearningPath?pathId=${pathId}`);
  };

  const onPageChange = (pageNumber) => {
    setPageNumber(pageNumber || 1);
  };

  const onPageSizeChange = (_, pageSize) => {
    setPageSize(pageSize);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { columnKey, order } = sorter;
    if (order) {
      setSortByColumn(columnKey);
      setSortOrder(order === "ascend" ? "asc" : "desc");
    }
  };

  const handleSearch = (text) => {
    setPageNumber(1);
    setSearchString(text);
  };

  useEffect(() => {
    handleTableChange(0, 0, {
      order: "descend",
      columnKey: "updatedTimestamp",
    });
  }, []);

  useEffect(() => {
    if (status === "active") {
      setTableColumns(columns);
    } else {
      const modifiedCols = columns.filter((column) => !column.archived);
      setTableColumns(modifiedCols);
    }
  }, [status]);

  const getAllLearningPaths = useCallback(() => {
    setAlert(null);
    setAlertType(null);
    axios
      .get(`${config.endPoint.commonService}/learningpath`, {
        params: {
          learningPathStatus: status,
          pageNumber: pageNumber,
          pageSize: pageSize,
          searchString: searchString,
          sortBy: sortByColumn,
          sort: sortOrder,
        },
        headers: {
          isadminpage: true,
        },
      })
      .then((response) => {
        setListData(response.data.data);
        setTotal(response.data.size);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        setAlert(error?.response?.data?.message || "Something went wrong");
        setAlertType("error");
      });
  }, [pageNumber, pageSize, searchString, sortByColumn, sortOrder, status]);

  useEffect(() => {
    getAllLearningPaths();
  }, [getAllLearningPaths]);

  const handleFilter = (filter) => {
    setPageNumber(1);
    setStatus(filter);
  };

  return (
    <AntDesignContainer>
      <Row gutter={24}>
        <Col className={"learningPath-crumbs"}>
          <AntDesignBreadCrumb crumbs={crumbs} />
        </Col>
      </Row>
      <Row gutter={24} className={"learningPath-header"}>
        <Col lg={8}>
          <AdminPageTitle
            pageTitle="Learning Path"
            pageCount={total}
            extraMargin={false}
            overrideFont={true}
          />
        </Col>
        <Col></Col>
        <Col lg={15}>
          <Row gutter={24}>
            <Col lg={12}>
              <Row>
                <Col lg={20} className={"centerAlign"}>
                  <CourseFilter
                    filterItems={filterItems}
                    handleFilter={handleFilter}
                    filter={status}
                  ></CourseFilter>
                </Col>
                <Col lg={2}>
                  <AntDesignDownloadIconButton size={"large"} />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Input
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                suffix={<SearchOutlined />}
                className={"notificationSearch"}
                style={{
                  width: " 10rem",
                  fontSize: "1rem",
                  fontFamily: "RakutenRoundRegular",
                  borderRadius: "4px",
                }}
              />
            </Col>
            <Col lg={6}>
              <AntDesignPrimaryButton
                size={"large"}
                text="NEW PATH"
                onClick={onAddNewPath}
                icon={<PlusCircleOutlined />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row> */}
      <div className={"learningPath-table"}>
        <AntDesignTable
          fullWidth={false}
          data={listData}
          rowKey={(record) => record.id}
          columns={tableColumns}
          isExpandable={true}
          expandedRowContent={(record) => (
            <LearningPathDetail
              id={record.id}
              archived={status === "archived"}
            />
          )}
          onChange={handleTableChange}
          pagination={{
            total: total,
            defaultCurrent: pageNumber,
            current: pageNumber,
            pageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: onPageChange,
            onShowSizeChange: onPageSizeChange,
            showTotal: (total) => `Total ${total} items`,
          }}
        />
      </div>
      {/* </Row> */}
      {(showAddUserModal || showErrorModal) && (
        <ImportUserHelper
          showAddUserModal={showAddUserModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          handleCloseAddUserModal={handleCloseAddUserModal}
          handleCloseErrorModal={handleCloseErrorModal}
          page={"learningPath"}
          updatePage={getAllLearningPaths}
          openErrorCloseAddUser={openErrorCloseAddUser}
          learningPathData={currentRow}
          setAlert={setAlert}
          setAlertType={setAlertType}
          showDatePicker={
            currentRow.elCourseCount > 0 && currentRow.ilCourseCount == 0
          }
        />
      )}
      <AntDesignNotifcation
        alert={alert}
        alertType={alertType}
        setAlert={setAlert}
        setAlertType={setAlertType}
      />
    </AntDesignContainer>
  );
}

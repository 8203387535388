import React from "react";

const PrivateCourseIcon = ({ color1 = "#000000", color2 = "#F59600" }) => (
  <svg
    width="25px"
    height="22px"
    viewBox="0 0 25 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-372.000000, -653.000000)"
        fillRule="nonzero"
      >
        <g
          id="restriction-copy-4"
          transform="translate(372.000000, 653.000000)"
        >
          <path
            d="M23.8505714,9.56071429 C22.4400476,7.92419048 20.7577619,6.38814286 18.9749524,5.226 L22.9420476,1.25890476 C23.2210952,0.979904762 23.2210952,0.527428571 22.9420476,0.248428571 C22.6630476,-0.0305714286 22.2105714,-0.0305714286 21.9315714,0.248428571 L17.706381,4.47361905 C15.8392381,3.47495238 13.9904286,2.96961905 12.1904762,2.96961905 C7.69947619,2.96961905 3.45466667,6.16785714 0.530380952,9.56071429 C-0.176761905,10.3812381 -0.176761905,11.6187143 0.530380952,12.4392381 C1.94090476,14.0757619 3.62319048,15.6118095 5.406,16.7739524 L1.43890476,20.7410476 C1.15985714,21.0200476 1.15985714,21.4725238 1.43890476,21.7515238 C1.718,22.030619 2.17038095,22.0305714 2.44938095,21.7515238 L6.67457143,17.5263333 C8.54171429,18.525 10.3904762,19.0303333 12.1904286,19.0303333 C16.6814286,19.0303333 20.9262381,15.8320952 23.8505238,12.4392381 C24.5577619,11.6187619 24.5577619,10.3812381 23.8505714,9.56071429 Z"
            id="Path"
            fill={color1}
          />
          <path
            d="M1.61285714,10.4937143 C3.19233333,8.66114286 7.38490476,4.39871429 12.1904762,4.39871429 C13.764381,4.39871429 15.2725238,4.856 16.6446667,5.53533333 L14.685,7.495 C13.9808571,6.99242857 13.119619,6.69638095 12.1904762,6.69638095 C9.81747619,6.69638095 7.88685714,8.627 7.88685714,11 C7.88685714,11.9290952 8.18285714,12.7903333 8.68542857,13.4945238 L6.44161905,15.7383333 C4.23704762,14.3578571 2.50657143,12.5431905 1.61285714,11.5062381 C1.36004762,11.2129048 1.36004762,10.7870476 1.61285714,10.4937143 Z"
            id="Path"
            fill={color2}
          />
          <path
            d="M15.065,11 C15.065,12.585 13.7754762,13.8745238 12.1904762,13.8745238 C11.656619,13.8745238 11.156619,13.7277619 10.7278095,13.4731905 L12.6957143,11.5052857 L14.663619,9.53738095 C14.9182381,9.96614286 15.065,10.4661429 15.065,11 Z"
            id="Path"
            fill={color2}
          />
          <path
            d="M12.1904762,8.12547619 C12.7243333,8.12547619 13.2243333,8.2722381 13.6531429,8.52685714 L11.6852381,10.4947619 L9.71733333,12.4626667 C9.46271429,12.0338571 9.31595238,11.5338571 9.31595238,11 C9.31595238,9.415 10.6054762,8.12547619 12.1904762,8.12547619 Z"
            id="Path"
            fill={color2}
          />
          <path
            d="M12.1904762,17.6012857 C10.6165714,17.6012857 9.10842857,17.144 7.73628571,16.4646667 L9.69595238,14.505 C10.4000952,15.0075714 11.2613333,15.303619 12.1904762,15.303619 C14.5634762,15.303619 16.4940952,13.373 16.4940952,11 C16.4940952,10.0709048 16.1980952,9.20966667 15.6954762,8.50547619 L17.9392857,6.26166667 C20.1438571,7.64214286 21.8743333,9.45680952 22.7680476,10.4937143 C23.0209048,10.7870476 23.0209048,11.2129048 22.7680952,11.5062381 C21.188619,13.3388571 16.9960476,17.6012857 12.1904762,17.6012857 Z"
            id="Path"
            fill={color2}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PrivateCourseIcon;

import React, { useRef } from "react";

function CustomSubmenu ( props ) {
  const refSubMenuContent = useRef(null);
  const { id, href, title, className } = props;
  const customclass = className? className + ' dropdown-submenu-container' : ' dropdown-submenu-container';
  
    const handleMouseOver = event => {
    event.preventDefault();
    event.stopPropagation();
    if (refSubMenuContent.current) {
      let show = false;
      if (refSubMenuContent.current.classList.contains('show')) {
        hideChildren(refSubMenuContent.current);
      } else {
        show = true;
        hideSibblings();
      }
      refSubMenuContent.current.classList.toggle('show');
      refSubMenuContent.current.parentNode.classList.toggle('show');
      if (typeof props.onToggle === 'function') {
        props.onToggle(show, event, { source: 'select' });
      }
    }
  };
  
  const hideChildren =(parent)  => {
    const children = parent.querySelectorAll('.dropdown-menu.show');
    const menuList = parent.querySelectorAll('.dropdown-submenu-container.show');
    for (const child of [...children, ...menuList]) {
      child.classList.remove('show');
    }
  }
  
  const hideSibblings = () => {
    if (refSubMenuContent && refSubMenuContent.current) {
      const parents = getParents(
         refSubMenuContent.current,
        '.dropdown-menu.show'
      );
      if (parents.length > 1) {
        hideChildren(parents[1]);
      }
    }
  }

  const getParents = (elem, selector)  => {
    const nodes = [];
    let element = elem;
    nodes.push(element);
    while (element.parentNode) {
      if (
        typeof element.parentNode.matches === 'function' &&
        element.parentNode.matches(selector)
      ) {
        nodes.push(element.parentNode);
      }
      element = element.parentNode;
    }
    return nodes;
  }

  return (
    <div className={customclass} id={id}>
      <a
        href={href}
        className="dropdown-item dropdown-submenu dropdown-toggle"
        onMouseOver={handleMouseOver}
      >
        {title}
      </a>
      <div
        className="dropdown-menu"
        ref= {refSubMenuContent}
      >
        {props.children}
      </div>
    </div>
  );
 
}

export default CustomSubmenu;
import React, { useState, useEffect } from "react";
import { AdminPageTitle } from "../../../component-library";
import { Row, Col } from "react-bootstrap";
import style from "./calendar.module.css";

import privateIcon from "../../../assets/icons/private_icon.svg";
import locked from "../../../assets/icons/lock_icon.svg";

export default function SideBar({ changeHandler }) {
  const [filter, setFilter] = useState({
    enrolledSession: false,
    publicSession: false,
    privateSession: false,
    lockedSession: false,
  });

  useEffect(() => {
    const filterArray = [];
    if (filter.enrolledSession) filterArray.push("enrollment_status");
    if (filter.lockedSession) filterArray.push("locked");
    if (filter.privateSession) filterArray.push("private");
    if (filter.publicSession) filterArray.push("public");

    changeHandler(filterArray);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      {/* <AdminPageTitle pageTitle="Calendar" /> */}
      <span style={{ fontFamily: "RakutenRoundSemiBold", fontSize: "2.5rem" }}>
        Calendar
      </span>
      <Row fluid="true">
        <Col>
          <input
            type="checkbox"
            className={style.enrolledCheckBox}
            id="1"
            onChange={(e) => {
              setFilter((prev) => {
                return { ...prev, enrolledSession: !prev.enrolledSession };
              });
            }}
          />
          <label for="1">
            <span style={{ marginLeft: "0.938rem" }}>ENROLLED SESSIONS</span>
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            className={style.publicCheckBox}
            type="checkbox"
            id="2"
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, publicSession: !prev.publicSession };
              })
            }
          />
          <label for="2">
            <span style={{ marginLeft: "0.938rem" }}>PUBLIC SESSIONS</span>
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            className={style.privateSession}
            type="checkbox"
            id="3"
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, privateSession: !prev.privateSession };
              })
            }
          />
          <label for="3">
            <span style={{ marginLeft: "0.938rem" }}>
              <img
                className={style.image}
                src={privateIcon}
                alt=""
                style={{ marginLeft: "0.313rem" }}
              />
              <span style={{ marginLeft: "0.403rem" }}>PRIVATE SESSIONS</span>
            </span>
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <input
            className={style.lockedSession}
            type="checkbox"
            id="4"
            label="LOCKED SESSIONS"
            onChange={(e) =>
              setFilter((prev) => {
                return { ...prev, lockedSession: !prev.lockedSession };
              })
            }
          />
          <label for="4">
            <span style={{ marginLeft: "0.938rem" }}>
              <img
                className={style.image}
                src={locked}
                alt=""
                style={{ marginLeft: "0.313rem" }}
              />
              <span style={{ marginLeft: "0.403rem" }}>LOCKED SESSIONS</span>
            </span>
          </label>
        </Col>
      </Row>
      <Row style={{ marginTop: "2.625rem" }}>
        <Col>
          <span className={style.sideBarText}>
            *Date and time is set to automatically <br />
            adjust to your PC's local date & time
          </span>
        </Col>
      </Row>
    </>
  );
}

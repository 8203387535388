import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import style from "./antdAdminTableButtonWithIcon.module.css";

function AdminTableButtonWithIcon({
	variant,
	label,
	handleClick,
	icon,
	otherStyle,
	iconStyle,
	iconExtraStyle,
}) {
	let variantStyle = "";
	if (variant === "success") {
		variantStyle = style.successVariant;
	} else if (variant === "error") {
		variantStyle = style.errorVariant;
	} else {
		variantStyle = style.normalVariant;
	}
	return (
		<>
			<Button
				bsPrefix={`${style.primaryButton} ${otherStyle} ${variantStyle}`}
				className={`${style.primaryButton} ${otherStyle} ${variantStyle}`}
				type="button"
				onClick={handleClick}>
				{icon && (
					<>
						<span className={style.iconStyle}>
							<img
								src={icon}
								className={`${iconStyle} ${iconExtraStyle}`}
								alt="icon"
							/>
						</span>
					</>
				)}{" "}
				{label}
			</Button>
		</>
	);
}

AdminTableButtonWithIcon.propTypes = {
	label: PropTypes.string.isRequired,
	handleClick: PropTypes.func,
	icon: PropTypes.string,
	otherStyle: PropTypes.string,
	variant: PropTypes.string,
	iconStyle: PropTypes.string,
};

export default AdminTableButtonWithIcon;

import axios from "axios";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import config from "../../config";
import UtilityFunctions from "../../utils/utility";
import style from "./manualInputEmailBox.module.css";
export default function ManualInputEmailBox({
  upliftEmails,
  upliftUsers = () => null,
}) {
  const [autoSuggestedList, setAutoSuggestedList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    upliftEmailsToParent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmails, selectedUserIds]);
  const autoSuggest = async function (text) {
    const instance = axios.create();
    instance
      .get(
        `${config.endPoint.userService}/api/user/search/email?matchBy=${text}`
      )
      .then((response) => {
        setAutoSuggestedList(response.data.emailList);
      });
  };
  // eslint-disable-next-line
  const optimizedSearch = useCallback(
    UtilityFunctions.debounce(autoSuggest, 1000),
    []
  );
  const autoSuggestEmails = (text) => {
    if (text) optimizedSearch(text);
    else setAutoSuggestedList([]);
  };
  const fetchSelectedValues = (selectedValue) => {
    if (selectedValue?.length) {
      const userIds = selectedValue.map((value) => value?.id);
      setSelectedUserIds(userIds);
    }
    setSelectedEmails(selectedValue);
    setAutoSuggestedList([]);
  };

  const processEnteredValue = (text) => {
    const emails = text
      .toLowerCase()
      .replace(/[ :;?!~,`"&|()<>{}[\]\r\n/\\]+/g, ",")
      .split(",")
      .filter((email) => email.length > 0 && config.isCorrectEmail(email));

    const displayEmails = emails.map((email) => {
      return { email: email, name: email };
    });
    return displayEmails;
  };
  const upliftEmailsToParent = () => {
    let data = "";
    selectedEmails.forEach((email) => {
      data += `${email.primaryEmail},`;
    });
    upliftEmails({ fileData: data });
    upliftUsers(selectedUserIds);
  };
  const handleClearAll = () => {
    setSelectedEmails([]);
  };
  const styles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      maxHeight: "13rem",
      overflowX: "hidden",
      overflowY: "scroll",
      wordBreak: "break-all",
      marginTop: "1rem",
      cursor: "text",
    }),
    multiValueRemove: (base) => ({
      ...base,
      cursor: "pointer",
    }),
  };

  return (
    <>
      {selectedEmails.length > 0 && (
        <div className={style.clearAll} onClick={handleClearAll}>
          CLEAR ALL
        </div>
      )}
      <Select
        id="manualInput"
        styles={styles}
        closeMenuOnSelect={false}
        isMulti
        options={autoSuggestedList}
        placeholder="Eg. user1@rakuten.com"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        noOptionsMessage={() => null}
        getOptionLabel={(autoSuggestedList) => autoSuggestedList.primaryEmail}
        getOptionValue={(autoSuggestedList) => autoSuggestedList.firstName}
        onInputChange={(e) => {
          if (e !== " ") {
            setInputValue(e);
            autoSuggestEmails(e);
          }
        }}
        onChange={fetchSelectedValues}
        value={selectedEmails}
        inputValue={inputValue}
        onKeyDown={(e) => {
          if (e.key === " ") {
            const processedEmails = processEnteredValue(inputValue);
            setSelectedEmails([...selectedEmails, ...processedEmails]);
            setInputValue("");
          }
        }}
      />
    </>
  );
}
ManualInputEmailBox.propTypes = {
  upliftEmails: PropTypes.func,
  upliftUsers: PropTypes.func,
};
